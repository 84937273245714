import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { isFunctionalAnnotationAnalysisParameters } from '@models/AnalysisParametersHelpers';
import React from 'react';
import FunctionalAnnotationAnalysisMethodsSection from '../analyses/FunctionalAnnotationAnalysisMethodsSection';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const StackedBarPlotMethods = ({ plot, analysisParameters }: Props) => {
    if (
        plot.analysis_type === 'functional_annotation' &&
        isFunctionalAnnotationAnalysisParameters(analysisParameters)
    ) {
        return <FunctionalAnnotationAnalysisMethodsSection plot={plot} analysisParameters={analysisParameters} />;
    }
    return <p>Unsupported analysis type for stacked barplot, we&apos;re unable to find any methods.</p>;
};

export default StackedBarPlotMethods;
