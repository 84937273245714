import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import TextInputField from '@components/forms/TextInputField';
import TextAreaField from '@components/forms/TextAreaField';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';

type Props = { plot: Plot; experiment: Experiment };
const SpreadsheetPlotDisplayFields = ({ plot }: Props) => {
    return (
        <>
            <FullWidthToggleField />
            <TextInputField name="plot_title" label="Title" placeholder={plot.analysis?.name ?? ''} />
            <TextAreaField name="caption" label="Caption" minRows={2} />
            <TextInputField name="spreadsheet_source" label="Spreadsheet source" />
            <TextInputField name="spreadsheet_source_url" label="Spreadsheet source url" />
        </>
    );
};

export default SpreadsheetPlotDisplayFields;
