import cn from 'classnames';
import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

const SampleCorrelationAnalysisIcon = ({ className = defaultTextLightClass, height = 24, width = 24 }: IconProps) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
            <rect x="5" y="5" width="5.79521" height="5.79521" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect
                x="12.7789"
                y="4.5"
                width="6.79521"
                height="6.79521"
                rx="1.5"
                fill="currentColor"
                stroke="currentColor"
            />
            <rect
                x="4.5"
                y="12.7789"
                width="6.79521"
                height="6.79521"
                rx="1.5"
                fill="currentColor"
                stroke="currentColor"
            />
            <rect
                x="13.2789"
                y="13.2789"
                width="5.79521"
                height="5.79521"
                rx="1"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    );
};

export default SampleCorrelationAnalysisIcon;
