import Experiment from '@models/Experiment';
import PipelineRun from '@models/PipelineRun';
import Bold from '@components/elements/Bold';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Button from '@components/Button';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import React, { useRef } from 'react';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import PipelineRunItem from '@components/experiments/PipelineRunItem';
import Italic from '@components/elements/Italic';
import Mono from '@components/elements/Mono';
import DoiLink from '@components/experiments/methods/DoiLink';

type Props = { experiment: Experiment; pipelineRun: PipelineRun };
const RNASeqPipelineMethods = ({ pipelineRun }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });
    const library_type = (pipelineRun.input_parameters?.library_type as string) ?? '';
    const strandedness = pipelineRun.input_parameters?.strandedness;
    const pipeline_name = pipelineRun.pipeline_version.pipeline.pipeline_repo;
    const version = pipelineRun.pipeline_version.version;
    const genome = pipelineRun.genome?.display_name;

    return (
        <div className="space-y-8">
            <div className="space-y-8" ref={ref}>
                <section>
                    <MethodsSectionTitle>RNA-seq pipeline</MethodsSectionTitle>
                    <p>
                        <Bold>{library_type}</Bold> FASTQ files were processed using the <Bold>{pipeline_name}</Bold>{' '}
                        pipeline (v{version})<sup>1,2</sup>
                        {strandedness ? ` (${strandedness} strandedness)` : null}. Adapter sequences were removed with
                        <Mono>Trim Galore</Mono>
                        <sup>3</sup>. Reads were aligned with <Mono>STAR</Mono>
                        <sup>4</sup> to <Bold>{genome}</Bold> and quantified to gene counts using <Mono>RSEM</Mono>
                        <sup>5</sup>.
                    </p>
                </section>
                <section>
                    <MethodsSectionTitle>References</MethodsSectionTitle>
                    <ol className="ml-4 list-decimal space-y-4">
                        <li>
                            Ewels PA, Peltzer A, Fillinger S, Patel H, Alneberg J, Wilm A, Garcia MU, Di Tommaso P,
                            Nahnsen S. The nf-core framework for community-curated bioinformatics pipelines.{' '}
                            <Italic>Nat Biotechnol</Italic> (2020). <DoiLink doi="10.1038/s41587-020-0439-x" />.
                        </li>
                        <li>
                            nf-core/rnaseq (latest version).{' '}
                            <a href="https://nf-co.re/rnaseq" target="_blank" rel="nofollow noopener noreferrer">
                                https://nf-co.re/rnaseq
                            </a>
                            . <DoiLink doi="10.5281/zenodo.1400710" />.
                        </li>
                        <li>
                            FelixKrueger/TrimGalore (latest version).{' '}
                            <a
                                href="https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                className="break-words"
                            >
                                https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/
                            </a>
                            . <DoiLink doi="10.5281/zenodo.5127898" />.
                        </li>
                        <li>
                            Dobin A, Davis CA, Schlesinger F, Drenkow J, Zaleski C, Jha S, Batut P, Chaisson M, Gingeras
                            TR. STAR: ultrafast universal RNA-seq aligner. <Italic>Bioinformatics</Italic> (2013).{' '}
                            <DoiLink doi="10.1093/bioinformatics/bts635" />.
                        </li>
                        <li>
                            Li B, Dewey CN. RSEM: accurate transcript quantification from RNA-Seq data with or without a
                            reference genome. <Italic>BMC Bioinformatics</Italic> (2011).{' '}
                            <DoiLink doi="10.1186/1471-2105-12-323" />.
                        </li>
                    </ol>
                </section>
            </div>
            <div>
                <Button
                    startIcon={<CopyIcon height={16} width={16} />}
                    color="primary"
                    variant="contained"
                    onClick={() => copyText()}
                >
                    {showCopySuccess ? 'Copied!' : 'Copy'}
                </Button>
            </div>
            <div className="space-y-2">
                <MethodsSectionTitle>Pipeline run details</MethodsSectionTitle>
                <PipelineRunItem pipelineRun={pipelineRun} active noRing />
            </div>
        </div>
    );
};

export default RNASeqPipelineMethods;
