import { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'react-use';
import { Node, NodeChange, OnNodesChange, XYPosition, applyNodeChanges, useReactFlow, useStoreApi } from 'reactflow';
import { getHelperLines, getId, getNodePositionInsideParent, sortNodes } from '@components/plutoflow/utils';
import useApi from '@hooks/useApi';
import Plot from '@models/Plot';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { StateSetter } from '../contexts/ContextTypes';

export const getMaxNodeIndex = (nodes) => {
    const maxId = Math.max(
        ...nodes
            .map((node) => {
                const match = node.id.match(/canvas-node-(\d+)/);
                return match ? parseInt(match[1], 10) : NaN;
            })
            .filter((number) => !isNaN(number)),
    );
    return maxId === -Infinity ? 0 : maxId;
};

export const DEFAULT_MULTI_PANEL_FIGURE_DIMENSIONS = {
    width: 1300,
    height: 700,
    sqLandscapePlotsDefault: 2, // Default number of plots supported by the width & height defined above in the basic landscape figure (square)
    rectLandscapePlotsDefault: 3, // Default number of plots supported by the width & height defined above in the basic landscape figure (rect)
    sqPortraitPlotsDefault: 3, // Default number of plots supported by the width & height defined above in the basic portrait figure (square)
};
type Props = {
    experiment_id?: string;
    exportMode?: boolean;
    canvasLoaded?: boolean;
    canvasNodes: Node[];
    setCanvasNodes: StateSetter<Node[]>;
};

const logger = Logger.make('CanvasNodes');
const useCanvasNodes = ({ experiment_id, canvasLoaded, canvasNodes, setCanvasNodes }: Props) => {
    const [newNodeLocation, setNewNodeLocation] = useState<XYPosition | null>(null);
    const [textNodeToEdit, setTextNodeToEdit] = useState<Node | null>(null);
    const [selectedTargetAnalysisNode, setSelectedTargetAnalysisNode] = useState<Node | null>(null);
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const [canvasNodesError, setCanvasNodesError] = useState<string>('');
    const [newNodeType, setNewNodeType] = useState<string | null>(null);
    const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined);
    const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined);
    const [activePlot, setActivePlot] = useState<Plot | null>(null);
    const [furthestX, setFurthestX] = useState<number>(0);
    const { screenToFlowPosition, getIntersectingNodes } = useReactFlow();
    const store = useStoreApi();
    const api = useApi();

    const {
        height,
        width,
        transform: [transformX, transformY, zoomLevel],
    } = store.getState();
    const zoomMultiplier = 1 / zoomLevel;

    // Figure out the center of the current viewport
    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2 - 100;
    const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 2 - 100;

    useEffect(() => {
        if (newNodeType === 'group') {
            postNewNode({
                nodeType: 'group',
                nodeStyle: { height: 700, width: 1000, border: '1px solid black', backgroundColor: 'white' },
                // nodeData: { label: 'New group'}
            });
            setNewNodeType(null);
        }

        if (newNodeType === 'multiPanelFigure') {
            postNewNode({
                nodeType: 'multiPanelFigure',
                nodeStyle: {
                    height: DEFAULT_MULTI_PANEL_FIGURE_DIMENSIONS.width,
                    width: DEFAULT_MULTI_PANEL_FIGURE_DIMENSIONS.width,
                    backgroundColor: 'white',
                },
                nodeData: {
                    arrangement: null,
                    label: 'New figure',
                    lastAdjustedIndex: 0,
                    orientation: 'square',
                    plotIds: [],
                    spannedCells: [],
                },
            });
            setNewNodeType(null);
        }
        if (newNodeType === 'text') {
            const newTextNode = {
                nodeType: 'text',
                nodeData: { label: 'Your content goes here' },
                nodeStyle: {
                    backgroundColor: '#fff',
                    fontSize: 20,
                    color: '#6B7180',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 8,
                },
            };
            postNewNode(newTextNode);
            setNewNodeType(null);
        }
    }, [newNodeType]);

    useDebounce(
        () => {
            if (!canvasLoaded || !experiment_id) return;
            handleSetFurthestX();
            saveNodes(canvasNodes);
        },
        500,
        [canvasNodes],
    );

    const saveNodes = async (newNodes: Node[]) => {
        if (!experiment_id || !canvasLoaded) return;
        try {
            await api.put(Endpoints.lab.experiment.canvasFlow(experiment_id), { nodes: newNodes });
        } catch (error) {
            logger.error(new Error('Failed to save new canvas nodes'));
            setCanvasNodesError('Failed to save nodes to canvas');
            // Notify user of save failure
            alert('Failed to save nodes. Please try again.');
        }
    };

    const handleSelectTextNodeToEdit = (id: string) => {
        const nodeToEdit = canvasNodes.find((node) => node.id === id);
        if (!!nodeToEdit) setTextNodeToEdit(nodeToEdit);
    };

    const onSelectTargetAnalysisNode = (id: string) => {
        const selectedNode = canvasNodes.find((node) => node.id === id);
        if (!!selectedNode) setSelectedTargetAnalysisNode(selectedNode);
    };

    const handleSetFurthestX = () => {
        if (!canvasNodes || !canvasNodes.length) return setFurthestX(0);
        let newXValue = 0;
        canvasNodes.forEach((node) => {
            const nodeXValue = node.position.x + (node.width ?? 0);
            if (nodeXValue > newXValue) newXValue = nodeXValue;
            return;
        });
        setFurthestX(newXValue);
    };

    const customApplyNodeChanges = useCallback((changes: NodeChange[], nodes: Node[]): Node[] => {
        // reset the helper lines (clear existing lines, if any)
        setHelperLineHorizontal(undefined);
        setHelperLineVertical(undefined);

        // this will be true if it's a single node being dragged
        // inside we calculate the helper lines and snap position for the position where the node is being moved to
        if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging && changes[0].position) {
            const helperLines = getHelperLines(changes[0], nodes);

            // if we have a helper line, we snap the node to the helper line position
            // this is being done by manipulating the node position inside the change object
            changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
            changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

            // if helper lines are returned, we set them so that they can be displayed
            setHelperLineHorizontal(helperLines.horizontal);
            setHelperLineVertical(helperLines.vertical);
        }
        return applyNodeChanges(changes, nodes);
    }, []);

    const postNewNode = async ({
        nodeData,
        nodeType,
        nodeStyle,
    }: {
        nodeData?: Node['data'];
        nodeType?: Node['type'];
        nodeStyle?: Node['style'];
    }) => {
        const position: XYPosition = newNodeLocation
            ? newNodeLocation
            : {
                  x: nodeType === 'group' ? furthestX + 100 : centerX,
                  y: centerY,
              };
        const maxId = getMaxNodeIndex(canvasNodes);

        const newNode: Node = {
            id: 'canvas-node-' + (maxId + 1),
            type: nodeType ?? 'ResizableNodeSelected',
            data: { ...nodeData, exportMode: false },
            style: nodeStyle ?? {},
            position,
        };
        setCanvasNodes([...canvasNodes, newNode]);
        setNewNodeLocation(null);
        setSelectedNode(newNode);
    };

    const onNodeDragStop = useCallback(
        (_: React.MouseEvent, node: Node) => {
            if (node.type === 'group') return;
            if (node.type === 'multiPanelFigure') return;

            const intersections = getIntersectingNodes(node).filter((n) => n.type === 'group');
            const groupNode = intersections[0];

            // if the node is being dragged is the same as the selected node, update the selected node state
            if (node.id === selectedNode?.id) {
                setSelectedNode(node);
            }

            // when there is an intersection on drag stop, we want to attach the node to its new parent
            if (intersections.length && node.parentNode !== groupNode?.id) {
                const nextNodes: Node[] = store
                    .getState()
                    .getNodes()
                    .map((n) => {
                        if (n.id === groupNode.id) {
                            return {
                                ...n,
                                className: '',
                            };
                        } else if (n.id === node.id) {
                            const position = getNodePositionInsideParent(n, groupNode) ?? {
                                x: 0,
                                y: 0,
                            };

                            return {
                                ...n,
                                position,
                                parentNode: groupNode.id,
                                extent: 'parent',
                            } as Node;
                        }

                        return n;
                    })
                    .sort(sortNodes);

                setCanvasNodes(nextNodes);
            }
        },
        [getIntersectingNodes, setCanvasNodes, store, selectedNode?.id],
    );
    const onNodeDrag = useCallback(
        (_: React.MouseEvent, node: Node) => {
            if (node.type !== 'node' && !node.parentNode) {
                return;
            }

            const intersections = getIntersectingNodes(node).filter((n) => n.type === 'group');
            const groupClassName = intersections.length && node.parentNode !== intersections[0]?.id ? 'active' : '';

            setCanvasNodes((nds) => {
                return nds.map((n) => {
                    if (n.type === 'group') {
                        return {
                            ...n,
                            className: groupClassName,
                        };
                    } else if (n.id === node.id) {
                        return {
                            ...n,
                            position: node.position,
                        };
                    }

                    return { ...n };
                });
            });
        },
        [getIntersectingNodes, setCanvasNodes],
    );

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => {
            setCanvasNodes((nodes) => customApplyNodeChanges(changes, nodes));
        },
        [setCanvasNodes, customApplyNodeChanges],
    );

    const onDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event: React.DragEvent) => {
        event.preventDefault();

        const type = event.dataTransfer.getData('application/reactflow');
        const position = screenToFlowPosition({
            x: event.clientX - 20,
            y: event.clientY - 20,
        });
        const nodeStyle = type === 'group' ? { width: 400, height: 200 } : undefined;

        const groupIntersections = getIntersectingNodes({
            x: position.x,
            y: position.y,
            width: 40,
            height: 40,
        }).filter((n) => n.type === 'group');
        const groupNode = groupIntersections[0];

        const newNode: Node = {
            id: getId(),
            type,
            position,
            data: { label: `${type}` },
            style: nodeStyle,
        };

        if (groupNode) {
            // if we drop a node on a group node, we want to position the node inside the group
            newNode.position = getNodePositionInsideParent(
                {
                    position,
                    width: 40,
                    height: 40,
                },
                groupNode,
            ) ?? { x: 0, y: 0 };
            newNode.parentNode = groupNode?.id;
            newNode.expandParent = true;
        }

        // we need to make sure that the parents are sorted before the children
        // to make sure that the children are rendered on top of the parents
        const sortedNodes = store.getState().getNodes().concat(newNode).sort(sortNodes);
        setCanvasNodes(sortedNodes);
    };

    const handleUpdateNode = (node: Node) => {
        setSelectedNode(node);
        setCanvasNodes((prev) => prev.map((oldNode) => (oldNode.id === node.id ? node : oldNode)));
    };

    return {
        activePlot,
        canvasNodes,
        canvasNodesError,
        furthestX,
        handleSelectTextNodeToEdit,
        handleUpdateNode,
        helperLineHorizontal,
        helperLineVertical,
        newNodeType,
        onDragOver,
        onDrop,
        onNodeDrag,
        onNodeDragStop,
        onNodesChange,
        onSelectTargetAnalysisNode,
        postNewNode,
        saveNodes,
        selectedNode,
        selectedTargetAnalysisNode,
        setActivePlot,
        setCanvasNodes,
        setNewNodeLocation,
        setNewNodeType,
        setSelectedNode,
        setSelectedTargetAnalysisNode,
        setTextNodeToEdit,
        textNodeToEdit,
    };
};

export default useCanvasNodes;
