import { DocumentTextIcon, DownloadIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import useInlineFileDownload from '@hooks/useInlineFileDownload';
import Bold from '@components/elements/Bold';
import FileTypeIcon from '@components/FileTypeIcon';
import { FileFormat } from '@models/Attachment';

export type IGVFile = { filename: string; url: string; type: string; id: string; track_name: string };
type Props = { file: IGVFile };
const PeakAnalysisFileItem = ({ file }: Props) => {
    const [downloading, setDownloading] = useState(false);
    const { triggerFileDownload } = useInlineFileDownload();

    const startDownload = () => {
        setDownloading(true);
        triggerFileDownload({ filename: file.filename, url: file.url, newTab: false });
        setTimeout(() => {
            setDownloading(false);
        }, 1000);
    };

    // const extension = file.url.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim() ?? '';

    return (
        <div className="flex items-center justify-between overflow-auto rounded-md bg-indigo-50 p-2">
            <div className="flex items-center">
                <div className="mt-1 self-start">
                    <FileTypeIcon fileType={FileFormat.file} width={24} className="text-indigo-500" />
                </div>
                <div className="flex items-center space-x-2 ">
                    <div>
                        <DocumentTextIcon className="mt-1 text-indigo-600" />
                    </div>
                    <div>
                        <p className="text-dark text-indigo-500">
                            <Bold>{file.track_name}</Bold>
                        </p>
                        <p className=" max-w-full space-x-1 whitespace-normal break-all pr-2 ">{file.filename}</p>
                    </div>
                </div>
            </div>
            <div className="mt-1">
                <IconButton
                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                    onClick={() => startDownload()}
                    disabled={downloading}
                    size="large"
                >
                    {downloading ? (
                        <CircularProgress size={12} variant="indeterminate" color="primary" />
                    ) : (
                        <DownloadIcon className="text-indigo-600" width={18} />
                    )}
                </IconButton>
            </div>
        </div>
    );
};

export default PeakAnalysisFileItem;
