import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';

const InstructionsAndTipsView = () => {
    const { selectedPreprocess } = useExperimentWorkflowContext();
    const preprocessType = selectedPreprocess?.preprocess_type?.shortname;

    const renderAboutThisStep = () => {
        if (preprocessType === 'seurat_prepare_data') {
            return (
                <>
                    <p>
                        This step gathers the Cell Ranger output from the nf-core/scrnaseq pipeline you ran previously
                        to collect the gene counts and prepare cell metadata in order to create an initial, raw Seurat
                        object in the next preprocess. Once you complete the full workflow of various preprocessing
                        steps, the finalized Seurat object will be available for you to download.
                    </p>

                    <p>
                        This step computes useful metrics (e.g. number of doublets), which will be available for you to
                        assess as a part of the next step in this workflow.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_create_object') {
            return (
                <>
                    <p>
                        This step creates an initial, raw Seurat object. Seurat is a powerful R package designed to
                        analyze, explore, and visualize single cell data. The Seurat object is the backbone of your
                        single cell analysis, and stores all the information you need for downstream analysis including
                        gene expression data, cell metadata, and more.
                    </p>

                    <p>
                        To create the Seurat object, this step is using the gene counts and cell metadata that were
                        prepared in the previous step, Prepare data. The cell metadata contains information about each
                        cell in your dataset.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_filter_object') {
            return (
                <p>
                    Filtering is an essential quality control step in single cell analysis to ensure the reliability and
                    accuracy of your data. During this step, low-quality cells will be removed from the data. You will
                    also have the option to remove doublets and even remove entire samples if necessary. Filtering
                    thresholds can be set for the entire dataset, or on a per-sample basis.
                </p>
            );
        }
        if (preprocessType === 'seurat_normalize_object') {
            return (
                <>
                    <p>
                        During this step of the workflow, you will normalize your single cell data. Normalization is
                        done to ensure that gene expression from individual cells is comparable and that potential
                        technical biases are minimized. We offer two different approaches to normalize your data:{' '}
                        <span className="font-semibold">Global-scaling (log normalized)</span> and{' '}
                        <span className="font-semibold">SCTransform</span>.
                    </p>
                    <p>
                        <span className="font-semibold">Global-scaling (log normalized)</span> normalization scales the
                        gene expression values of individual cells to make them more comparable. Global-scaling is
                        considered “global” because it takes into account all genes and cells in the dataset. By
                        applying a logarithmic transformation and scale factor calculation, the global-scaling approach
                        reduces the impact of extremely high or low expression values, making the data easier to
                        interpret.
                    </p>
                    <p>
                        <span className="font-semibold">SCTransform</span> normalization not only scales gene expression
                        but also accounts for other sources of technical variability. It uses statistical modeling to
                        identify and correct these variations, which can be particularly important when working with
                        complex or heterogeneous datasets. Note that SCTransform is only used for driving the clustering
                        of cells into dimensionality reduction embeddings. It is not used for any downstream analysis
                        outside of clustering. When selecting SCTransform as a normalization method, the counts data
                        will also be log normalized for downstream analysis purposes.
                    </p>
                    <p>
                        This step also returns several visualizations to help you determine what normalization approach
                        is best for your data, including dimensionality reduction plots.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_integrate_object') {
            return (
                <>
                    <p>
                        The choice of whether to integrate your data is an important decision, as integration will
                        impact how you interpret your data. The goal of integration is to adjust and merge data to
                        enable the analysis of multiple samples collectively. Integration can be useful when working
                        with multiple samples, batches, or experiments. However, integration may not be necessary if
                        samples and experimental covariates are already evenly distributed in low-dimensional space,
                        which can be visualized during the previous normalization step.
                    </p>
                    <p>
                        We offer three different approaches to integrate your data:{' '}
                        <span className="font-semibold">Harmony</span>,{' '}
                        <span className="font-semibold">RPCA (Reciprocal Principal Component Analysis)</span>, and{' '}
                        <span className="font-semibold">CCA (Canonical Correlation Analysis)</span>.
                    </p>
                    <p>
                        <span className="font-semibold">Harmony</span> is an integration method designed specifically
                        for scRNA-seq, and essentially pushes cells that share similar gene expression profiles closer
                        together to improve clustering results. Harmony identifies and adjusts for differences between
                        batches or groups by learning a shared representation of the data and aligning it, while
                        retaining the underlying biological variation. This can help make identifying cell types across
                        different samples or experiments easier.
                    </p>
                    <p>
                        <span className="font-semibold">RPCA (Reciprocal Principal Component Analysis)</span>,
                        implemented in Seurat, is a method for decomposing data matrices into low-rank and sparse
                        components. The low-rank component represents the underlying structure of the data (biological
                        signal), and the sparse component represents noise or outliers. This can help in identifying
                        unwanted variation in the data as a means to improve clustering.
                    </p>
                    <p>
                        <span className="font-semibold">CCA (Canonical Correlation Analysis)</span>, also implemented in
                        Seurat, aligns and integrates the data by finding common sources of variation across samples. It
                        identifies linear combinations of genes (canonical variables) that are highly correlated between
                        datasets and uses these to align the cells, allowing for comparative analysis across
                        experimental conditions.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_cluster_object') {
            return (
                <>
                    <p>
                        During this step of the workflow, you will cluster your Seurat object. Clustering is the process
                        of grouping similar cells together based on their gene expression patterns. Clusters are defined
                        by cluster resolutions, which are different levels of magnification with which you can examine
                        your data. You can cluster your cells at different levels of detail: a lower resolution will
                        create larger, global clusters (fewer clusters overall) with more cells in each cluster, and a
                        higher resolution will create smaller, more granular clusters (more clusters overall) with less
                        cells in each cluster.
                    </p>
                    <p>
                        Lower cluster resolutions give a broad view of cell types or major cell groups in the data, and
                        are useful when you want to quickly identify the main cell populations you are working with.
                        Higher cluster resolutions provide a more detailed view, helping you to identify subtypes of
                        cells or finer distributions within a broad cell type. This can be important when you want to
                        understand the heterogeneity within a cell population.
                    </p>
                    <p>
                        For each resolution you define in this workflow step, you will be able to explore the top marker
                        genes for each cluster. Marker genes are crucial in identifying and characterizing cell clusters
                        in single cell data. Marker genes are genes that are specifically expressed in particular cell
                        types or clusters, making them an excellent starting point for distinguishing and characterizing
                        these cell populations.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_refine_object') {
            return (
                <p>
                    This is the final step of the workflow, and is your opportunity to refine your Seurat object before
                    finalizing your workflow and proceeding to cluster annotation and downstream analysis. During this
                    step, you have the option to remove a cluster(s) from your data.
                </p>
            );
        }
        return null;
    };
    const renderTipsAndRec = () => {
        if (preprocessType === 'seurat_prepare_data') {
            return (
                <p>
                    No action is needed from you on this step. If this step is still running, please come back in ~30
                    minutes to move on to the next step in the workflow.
                </p>
            );
        }
        if (preprocessType === 'seurat_create_object') {
            return (
                <>
                    <p>
                        Setting appropriate thresholds for minimum cells and minimum features is essential for quality
                        control and filtering your data effectively.
                    </p>
                    <p>
                        <span className="font-semibold">Minimum cells</span> refers to the minimum number of cells in
                        which a gene must be detected to be considered in the analysis. Setting this threshold too low
                        may result in the inclusion of noisy or low-quality genes. Setting this threshold too high may
                        exclude genes important for your analysis. We recommend initially setting{' '}
                        <span className="font-semibold">Minimum cells</span> to a low value, such as 3-5, to be
                        inclusive and capture a wide range of genes.
                    </p>
                    <p>
                        <span className="font-semibold">Minimum features</span> is used to set the minimum number of
                        genes that a cell must express to be considered in the analysis. Setting this threshold too low
                        may result in the inclusion of low-quality or empty droplets. Setting this threshold too high
                        may exclude potentially interesting cells, such as rare cell types or transitional states. We
                        recommend initially setting <span className="font-semibold">Minimum features</span> to a
                        moderate value, such as 200-500.
                    </p>
                    <p>
                        For both <span className="font-semibold">Minimum cells</span> and{' '}
                        <span className="font-semibold">Minimum features</span>, it is important to consider the
                        characteristics of your dataset. For example, in very heterogeneous datasets, you might use
                        lower <span className="font-semibold">Minimum cells</span> and{' '}
                        <span className="font-semibold">Minimum features</span> thresholds to capture rare cell types or
                        states. However, if your analysis is focused on a specific cell type, you might use a higher{' '}
                        <span className="font-semibold">Minimum features</span> threshold to increase cell-type
                        specificity and a higher <span className="font-semibold">Minimum cells</span> threshold to
                        capture genes that are consistently expressed in that cell type.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_filter_object') {
            return (
                <>
                    <p>
                        For all filtering thresholds, we strongly recommend assessing the QC plots! These plots can help
                        determine the best cut-offs to use for your data, and identify any potential outlier samples.
                    </p>
                    <p>
                        Additionally, with any filtering threshold, it is important to consider the characteristics of
                        your dataset. For example, if you are working with a dataset that has been sequenced deeply you
                        might be more stringent in setting a{' '}
                        <span className="font-semibold">Minimum number of UMIs per cell</span> threshold than someone
                        working with a dataset that has been sequenced shallowly. Some cell types, such as muscle cells,
                        naturally express a higher percentage of mitochondrial genes as part of their biological
                        function, which might impact your{' '}
                        <span className="font-semibold">Maximum % mito UMIs per cell</span> threshold. On the other
                        hand, red blood cells naturally express far fewer genes and have a lower minimum novelty score
                        and lower minimum number of features per cell compared to other cell types.
                    </p>
                    <p>
                        <span className="font-semibold">Minimum number of UMIs per cell</span> and{' '}
                        <span className="font-semibold">Maximum number of UMIs per cell</span> are used to set the
                        thresholds for the number of UMIs (Unique Molecular Identifiers; short, random nucleotide
                        sequences designed to tag each unique RNA molecule in a dataset) per cell. Setting a minimum
                        threshold can help ensure that a cell has enough RNA to be considered reliable. All filtering
                        thresholds are dataset dependent which is why assessing the QC plots is important, but low UMI
                        thresholds are frequently set around 500 UMIs. Setting a maximum threshold can help remove
                        potential doublets or overly noisy cells.
                    </p>
                    <p>
                        <span className="font-semibold">Minimum number of features per cell</span> and{' '}
                        <span className="font-semibold">Maximum number of features per cell</span> are used to set
                        thresholds for the number of different genes expressed in a cell. Setting a minimum threshold
                        can help ensure that cells express enough genes to be considered biologically relevant. Setting
                        a maximum threshold can help filter cells with excessive background noise, technical artifacts,
                        and doublets.
                    </p>
                    <p>
                        The novelty score is used to assess how complex the RNA species is in a given cell. For
                        instance, if a cell contains many captured transcripts (high number of UMI counts) and a low
                        number of genes, this likely means that only a low number of genes were captured and sequenced
                        over and over again. These low complexity cells (i.e., cells with a low novelty score) could
                        represent a specific cell type or could be due to an artifact or contamination. We recommend
                        setting an initial <span className="font-semibold">Minimum novelty score per cell</span>{' '}
                        threshold of 0.8.
                    </p>
                    <p>
                        The <span className="font-semibold">Maximum % mito UMIs per cell</span> threshold is used to
                        filter out cells that might be stressed, damaged, or dying. Frequently, the threshold of{' '}
                        <span className="font-semibold">Maximum % mito UMIs per cell</span> is set to 5-20%.
                    </p>
                    <p>
                        <span className="font-semibold">Doublets</span> occur when two cells are captured in the same
                        droplet, resulting in mixed gene expression profiles (if more than two cells are captured, then
                        this is called a multiplet). Doublets can incorrectly suggest the existence of intermediate cell
                        populations or transitory states that do not actually exist, so removing doublets can help
                        ensure the interpretation of your data. You can optionally remove predicted doublets/multiplets
                        from your dataset during this step, or wait to see if you have a cluster dominated by
                        doublets/multiplets in your dataset and remove that cluster at a later point in this workflow.
                    </p>
                    <p>
                        In addition to filtering out poor quality cells, you might also need to consider excluding
                        entire samples from your dataset before proceeding to downstream analysis. Samples might need to
                        be removed if they are extreme outliers or have unusual/poor patterns in any of the QC metrics
                        presented in this step or the previous step in this workflow.
                    </p>
                </>
            );
        }

        if (preprocessType === 'seurat_normalize_object') {
            return (
                <>
                    <p>
                        We recommend selecting <span className="font-semibold">Global-scaling (log normalized)</span>{' '}
                        normalization as an initial starting point as you begin to explore your data. For either
                        normalization approach, there are a variety of parameters and options that you can set depending
                        on your analysis goals and unique dataset.
                    </p>
                    <p>
                        Variable features are genes that exhibit significant variability in expression across cells in a
                        dataset, and often represent genes that are biologically relevant and contribute to differences
                        between cell types or states. We can select a{' '}
                        <span className="font-semibold">Number of top variable features</span> as a way to reduce the
                        dimensionality of the data and focus on the most informative genes during normalization and
                        downstream analysis. We recommend setting the{' '}
                        <span className="font-semibold">Number of top variable features</span> option to 3,000 as a
                        starting point.
                    </p>
                    <p>
                        As part of normalizing your dataset, you might also want to regress out sources of unwanted
                        variation in your top variable features list, which involves removing or mitigating the impact
                        of technical or confounding factors that can bias the clustering of cells. You can opt to{' '}
                        <span className="font-semibold">Regress out UMI count effect</span> (remove variation due to UMI
                        counts), <span className="font-semibold">Regress out % mito effect</span> (remove variation due
                        to mitochondrial gene expression),{' '}
                        <span className="font-semibold">Regress out cell cycle effect (difference)</span> (regress out
                        the difference between G2M and S phase scores while maintaining signals separating non-cycling
                        and cycling cells), and/or{' '}
                        <span className="font-semibold">Regress out cell cycle effect (score)</span> (remove all signal
                        associated with cell cycle). We recommend not including any regression during an initial
                        analysis, and to evaluate whether or not regression is necessary as you continue exploring your
                        dataset.
                    </p>
                    <p>
                        In order to obtain UMAP embeddings, principal component analysis (PCA) is applied to the dataset
                        to reduce its dimensionality. PCA identifies a set of orthogonal axes (the principal components)
                        that capture the most significant sources of variation in the data. You can set the{' '}
                        <span className="font-semibold">Number of principal components</span> to use, which determines
                        the dimensionality of the reduced data. A higher number of principal components retains more
                        information, but can be noisy. A lower number of principal components may simplify the data but
                        could lose some detail. We recommend setting the{' '}
                        <span className="font-semibold">Number of principal components</span> to 40 as an initial
                        starting point, and then evaluating the provided Elbow Plot.
                    </p>
                    <p>
                        Additionally, you can also set the{' '}
                        <span className="font-semibold">Number of neighboring points (n.neighbors)</span>, which
                        determines the local neighborhood size considered when constructing the UMAP embeddings. A
                        smaller n.neighbors value results in a more fine-grained UMAP representation where cells are
                        embedded based on very local relationships. This can capture fine-scale structure but may be
                        sensitive to noise. A larger n.neighbors value results in a more generalized UMAP
                        representation, emphasizing global structures and robustness to noise, providing a broader view
                        of the data. We recommend setting the{' '}
                        <span className="font-semibold">Number of neighboring points (n.neighbors)</span> to 20 as an
                        initial starting point.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_integrate_object') {
            return (
                <>
                    <p>
                        Integration may be necessary if the data shows unexpected variability across samples or groups
                        of cells, which may be caused by using multiple datasets, batches, or by differences in sample
                        source or processing. If you noticed during the normalization step that your cells were grouping
                        by sample or by experimental covariate, then integrating your data can enable more interpretable
                        results during downstream analyses.
                    </p>
                    <p>
                        You might not need to perform integration if your samples and experimental covariates were
                        evenly distributed in low-dimensional space during the normalization step, or if your data is
                        from a single batch and there are minimal technical variations. Additionally, your research
                        goals might not require data integration. For example, if you are primarily interested in
                        characterizing a specific cell type or studying the effects of a single condition, integrating
                        the data from unrelated samples might not be relevant.
                    </p>
                    <p>
                        If you decide to perform integration on your data, we recommend selecting{' '}
                        <span className="font-semibold">Harmony</span> integration as an initial starting point. Harmony
                        was developed specifically for single cell data, is user-friendly, and focuses on retaining
                        biological variation while adjusting cell embeddings for technical noise. It also has a
                        considerably faster runtime in-app. CCA and RPCA are considered more advanced approaches. If
                        using Harmony for integration, you&apos;ll be prompted to provide the name(s) of{' '}
                        <span className="font-semibold">Covariate(s)</span> that define the groups or batches in your
                        single cell data for which you want to integrate over. If you decide to use{' '}
                        <span className="font-semibold">RPCA</span> or <span className="font-semibold">CCA</span> for
                        integration, you will also be prompted to provide the name of the{' '}
                        <span className="font-semibold">Covariate</span> that defines the groups or batches in your
                        single cell data.
                    </p>
                    <p>
                        Regardless of the integration method you select, you will be able to set the same dimensionality
                        reduction parameters that were available during the Normalization step:{' '}
                        <span className="font-semibold">Number of principal components</span> and{' '}
                        <span className="font-semibold">Number of neighboring points (n.neighbors)</span>. You can use
                        the same parameter values that you set during the Normalization step, or set the parameters to
                        new values depending on your data.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_cluster_object') {
            return (
                <>
                    <p>
                        The <span className="font-semibold">k for the k-nearest neighbors algorithm</span> lets you
                        control the granularity or level of detail when defining which cells are closely related in your
                        data. Depending on your analysis goals, you might choose a smaller{' '}
                        <span className="font-semibold">k for the k-nearest neighbors algorithm</span> for a more
                        fine-grained view or a larger{' '}
                        <span className="font-semibold">k for the k-nearest neighbors algorithm</span> for a broader
                        perspective of cell relationships. We recommend setting{' '}
                        <span className="font-semibold">k for the k-nearest neighbors algorithm</span> to the same value
                        that you used for{' '}
                        <span className="font-semibold">Number of neighboring points (n.neighbors)</span> in the
                        Normalization and Integration steps of this workflow as a starting point. Though not a strict
                        requirement, setting{' '}
                        <span className="font-semibold">k for the k-nearest neighbors algorithm</span> equal to the{' '}
                        <span className="font-semibold">Number of neighboring points (n.neighbors)</span> can help
                        maintain consistency in your workflow and ensure that the cells&apos; neighborhood relationships
                        used in dimensionality reduction match the relationships established during neighborhood
                        calculations.
                    </p>
                    <p>
                        The <span className="font-semibold">Number of principal components</span> when clustering
                        specifies the number of dimensions to use for calculating cell-to-cell relationships, and
                        represent the most significant sources of variation in your data. Similar to the above, as a
                        starting point we recommend setting the{' '}
                        <span className="font-semibold">Number of principal components</span> to the same{' '}
                        <span className="font-semibold">Number of principal components</span> you used during the
                        dimensionality reduction component of the Normalization and Integration steps of this workflow.
                        This can help maintain alignment between the neighborhood calculations and the dimensionality
                        reduction, and make it easier to interpret dimensionality reduction plots.
                    </p>
                    <p>
                        For <span className="font-semibold">Cluster resolutions</span>, we recommend choosing a wide
                        range of resolutions that will be appropriate for your data and your analysis. Low resolutions
                        (less than 1.0) will yield large, high-level clusters that group cells with similar big-picture
                        characteristics, and is helpful when you want a broad view of different cell types. High
                        resolutions (greater than or equal to 1.0) will yield smaller, more detailed clusters that
                        capture subtle differences among cells, and is helpful to detect subtypes of cells and rare cell
                        types.
                    </p>
                    <p>
                        In addition to the above clustering parameters, you can also set some statistics parameters for
                        identifying marker genes. When identifying differentially expressed marker genes, the analysis
                        is performing a differential expression analysis on all cells in a cluster of interest compared
                        to all other cells that do not belong to that cluster. This is iterated across all possible
                        clusters in your Seurat object. The <span className="font-semibold">Test to use</span> parameter
                        allows you to specify the statistical test that should be used to identify differentially
                        expressed genes when searching for marker genes in clusters. We recommend setting{' '}
                        <span className="font-semibold">Test to use</span> to{' '}
                        <span className="font-semibold">Wilcoxon rank sum test</span> as a starting point.
                    </p>
                    <p>
                        The <span className="font-semibold">Log2 fold change threshold</span> allows you to control the
                        sensitivity of the marker gene identification process based on log2 fold change values. Log2
                        fold change is a commonly used scale for measuring the magnitude of gene expression changes
                        between two groups, like between two groups of cells. For example, setting the{' '}
                        <span className="font-semibold">Log2 fold change threshold</span> to 0.25 means that only genes
                        with a minimum log2 fold change of 0.25 will be considered as differentially expressed and
                        potential marker genes for a given cluster.
                    </p>
                </>
            );
        }
        if (preprocessType === 'seurat_refine_object') {
            return (
                <>
                    <p>
                        There are several reasons why you might consider removing a cluster(s) from your data. You might
                        have identified a cluster that appears to be primarily driven by technical artifacts, noise, or
                        low-quality cells. You might want to simplify your analysis to focus on the most biologically
                        relevant cell populations or states and remove less informative clusters.
                    </p>
                    <p>
                        However, it is important to exercise caution when removing clusters, especially if they could
                        represent rare cell populations or subtypes that are biologically important. Always make sure
                        your decisions are supported by strong evidence from your data analysis, and document your
                        decisions and reasons for removing clusters so that others can understand and replicate your
                        analysis.
                    </p>
                </>
            );
        }
        return null;
    };
    const renderWhatsNext = () => {
        if (preprocessType === 'seurat_prepare_data') {
            return (
                <p>
                    In the next step you will create an initial, raw Seurat object using the gene counts and cell
                    metadata generated from this step. This will include some initial filtering where you will select
                    thresholds to determine the minimum number of cells in which a gene must be detected to be
                    considered in the analysis and the minimum number of genes that a cell must express to be considered
                    in the analysis.
                </p>
            );
        }
        if (preprocessType === 'seurat_create_object') {
            return (
                <p>
                    In the next step you will continue filtering your Seurat object by removing low-quality and outlier
                    cells based on several QC metrics.
                </p>
            );
        }
        if (preprocessType === 'seurat_filter_object') {
            return (
                <p>
                    In the next step you will normalize your data to ensure that gene expression from individual cells
                    is comparable and that potential technical biases are minimized.
                </p>
            );
        }

        if (preprocessType === 'seurat_normalize_object') {
            return (
                <p>
                    In the next step you can optionally integrate your data. Whether or not to integrate your data is an
                    important decision. Integration can be useful when working with multiple samples, batches, or
                    experiments where variation clearly exists in low-dimensional space. However, integration is not
                    always necessary.
                </p>
            );
        }
        if (preprocessType === 'seurat_integrate_object') {
            return (
                <p>
                    In the next step you will cluster your Seurat object. Clustering is the process of grouping similar
                    cells together based on their gene expression patterns, and helps to identify different cell types
                    and phenotypes that are present in the data.
                </p>
            );
        }
        if (preprocessType === 'seurat_cluster_object') {
            return (
                <p>
                    In the next step you can optionally refine your data and remove a cluster(s) from your Seurat object
                    before finalizing your workflow and proceeding to downstream analysis.
                </p>
            );
        }
        if (preprocessType === 'seurat_refine_object') {
            return (
                <p>
                    If you are happy with your preprocess workflow, the next step is to finalize the workflow. This will
                    allow you to proceed to cluster annotation and downstream analysis!
                </p>
            );
        }
        return null;
    };

    return (
        <div className="m-auto w-[80%] max-w-3xl space-y-4">
            <h2 className="text-lg font-semibold">About this step</h2>
            {renderAboutThisStep()}
            <h2 className="text-lg font-semibold">Tips & Recommendations</h2>
            {renderTipsAndRec()}
            <h2 className="text-lg font-semibold">What&apos;s next?</h2>
            {renderWhatsNext()}
        </div>
    );
};

export default InstructionsAndTipsView;
