import React from 'react';
import Experiment from '@models/Experiment';
import AsyncTargetPicker from '@/src/components/AsyncTargetPicker';
import TextInput from '@/src/components/forms/TextInput';
import { useFormikContext } from 'formik';
import { SeuratModuleScoreAnalysisFormValues } from '../AnalysisFormTypes';

type Props = {
    experiment: Experiment;
};
const SeuratModuleScoreAnalysisFormFields = ({ experiment }: Props) => {
    const { values, handleChange } = useFormikContext<SeuratModuleScoreAnalysisFormValues>();
    return (
        <div className="space-y-8">
            <AsyncTargetPicker
                experiment={experiment}
                name="targets"
                maxItems={2000}
                useTargetDataEndpoint
                label="Targets"
            />
            <TextInput
                label="Module score name"
                name="module_score_name"
                value={values.module_score_name ?? ''}
                onChange={handleChange}
                useFormikError
            />
        </div>
    );
};

export default SeuratModuleScoreAnalysisFormFields;
