import TextInput from '@components/forms/TextInput';
import React from 'react';
import { useField } from 'formik';

type Props = { name?: string; placeholder?: string | null };
const ChartSubTitleField = ({ name = 'plot_subtitle', placeholder }: Props) => {
    const [{ onChange }, { value }] = useField<string>(name);
    return (
        <TextInput
            label="Plot subtitle"
            name={name}
            value={value ?? ''}
            placeholder={placeholder ?? ''}
            onChange={onChange}
            useFormikError
        />
    );
};

export default ChartSubTitleField;
