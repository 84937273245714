import { YAxisConfig } from '@models/PlotConfigs';
import React from 'react';

const ScaleMethods = ({ y_axis_scale }: Pick<YAxisConfig, 'y_axis_scale'>) => {
    switch (y_axis_scale) {
        case 'symlog':
            return (
                <span>
                    Values on the <span className="italic">y</span>-axis are displayed on a symmetrical log<sub>10</sub>{' '}
                    scale.
                </span>
            );
        default:
            return null;
    }
};

export default ScaleMethods;
