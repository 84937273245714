import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import GeneSet, { GeneSetField } from '@models/GeneSet';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import useAnalysisGeneSets from '@hooks/useAnalysisGeneSets';
import { blankToUndefined, isNotBlank } from '@util/StringUtil';
import TextInput from '@components/forms/TextInput';
import { IconButton, Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CheckIcon, SearchIcon } from '@heroicons/react/outline';
import LoadingMessage from '@components/LoadingMessage';
import DataTable from '@components/dataTable/DataTable';
import Logger from '@util/Logger';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { CopyIcon } from '@/src/components/icons/custom/CopyIcon';

const logger = Logger.make('SearchableGeneSetTable');

type HeaderName = keyof GeneSet;
type Props = {
    experiment: Experiment;
    plot: Plot;
    onSelected: (value: GeneSet) => void;
    hideSelectColumn?: boolean;
};
const SearchableGeneSetTable = ({ plot, experiment, onSelected, hideSelectColumn = false }: Props) => {
    const experimentId = experiment.uuid;
    const analysisId = plot.analysis?.uuid;
    const [searchInput, setSearchInput] = useState('');
    const [copyStates, setCopyStates] = useState<Record<string, boolean>>({});
    const [copyStateUpdateCounter, setCopyStateUpdateCounter] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const { copyText } = useCopyToClipboard({ ref });

    const {
        loading,
        setSearch,
        search: searchQuery,
        tabularData,
        sortBy,
        setSortBy,
        sortByDesc,
        setSortByDesc,
        appendValueIfNotPresent,
    } = useAnalysisGeneSets({
        analysisId,
        experimentId,
    });

    const headers = useMemo<string[]>(() => {
        return tabularData?.headers.filter((h) => h !== 'Gene_Set_Shortname') ?? [];
    }, [tabularData]);

    const handleSearchSubmit = () => {
        logger.info('submitting form value', searchInput);
        setSearch(blankToUndefined(searchInput));
    };

    const handleSearchCleared = () => {
        setSearchInput('');
        setSearch(undefined);
    };

    const handleRowSelected = (item: GeneSet) => {
        logger.info('handling row selected....', item);
        appendValueIfNotPresent(item);
        onSelected(item);
    };

    const handleSortChange = (header: GeneSetField, desc?: boolean) => {
        setSortBy(header);
        setSortByDesc(desc ?? false);
    };

    const headerRenderer = useCallback(
        (name: HeaderName | string): string | ReactElement => {
            let formattedName: string | ReactElement;
            switch (name) {
                case 'Gene_Set_Display_Name':
                    formattedName = 'Gene set';
                    break;
                case 'Adj_P_Value':
                    formattedName = (
                        <span className="whitespace-nowrap">
                            Adj <i>p</i>-value
                        </span>
                    );
                    break;
                default:
                    formattedName = name as string;
                    break;
            }

            const columnData = tabularData?.items.map((item: any) => item[name]).join('\n');

            const handleCopy = () => {
                copyText({ textAsString: columnData, highlight: false });
                setCopyStates((prev) => ({ ...prev, [name]: true }));
                setCopyStateUpdateCounter((prev) => prev + 1);
                setTimeout(() => {
                    setCopyStates((prev) => ({ ...prev, [name]: false }));
                    setCopyStateUpdateCounter((prev) => prev + 1);
                }, 2000);
            };

            return (
                <div className="flex items-center justify-between">
                    <span>{formattedName}</span>
                    <Tooltip title="Copy full column" enterDelay={300} enterNextDelay={300}>
                        <IconButton
                            sx={{
                                padding: '4px',
                                marginLeft: '2px',
                                '& .MuiIconButton-label': { lineHeight: 1 },
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy();
                            }}
                            size="small"
                        >
                            {copyStates[name] ? (
                                <CheckIcon className="h-4 w-4 text-green-500" />
                            ) : (
                                <CopyIcon className="h-4 w-4 text-cyan-700" />
                            )}
                        </IconButton>
                    </Tooltip>
                </div>
            );
        },
        [copyStates, ref, tabularData],
    );

    return (
        <div>
            <form
                className="pt-1"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchSubmit();
                }}
            >
                <TextInput
                    className="mx-auto max-w-lg"
                    disableFormik
                    name="search"
                    value={searchInput ?? ''}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search by name"
                    iconRight={
                        <div className="flex items-center">
                            {searchInput && (
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    size="small"
                                    onClick={() => handleSearchCleared()}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            )}
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                onClick={() => handleSearchSubmit()}
                                size="small"
                            >
                                <SearchIcon className="h-5 w-5 text-indigo-600" />
                            </IconButton>
                        </div>
                    }
                />
            </form>
            <div className="h-full space-y-8">
                {loading && (
                    <div>
                        <LoadingMessage immediate message="Loading..." />
                    </div>
                )}
                {tabularData?.data_hash && (
                    <div ref={ref}>
                        <DataTable
                            data={{
                                ...tabularData,
                                headers,
                            }}
                            key={tabularData?.data_hash ?? `no_data_hash-${copyStateUpdateCounter}`}
                            headerRenderer={headerRenderer}
                            onRowSelected={hideSelectColumn ? undefined : handleRowSelected}
                            sortable
                            tableColor="bg-cyan-100 text-cyan-900"
                            sortBy={sortBy ?? undefined}
                            sortDesc={sortByDesc}
                            onSortedChange={handleSortChange}
                            manualSortBy
                        />
                        {tabularData?.items?.length === 0 && (
                            <div className="py-8 text-center">
                                No results found{' '}
                                {isNotBlank(searchQuery) && (
                                    <span>
                                        for <span className="font-semibold">&quot;{searchQuery}&quot;</span>
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchableGeneSetTable;
