import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import useSWR from 'swr';
import { ExperimentData } from '@models/ExperimentData';
import Endpoints from '@services/Endpoints';
import { isEmptyList } from '@util/ObjectUtil';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import LoadingMessage from '@components/LoadingMessage';
import ProseList from '@components/ProseList';
import React from 'react';
import { TSNEAnalysis } from '@models/analysis/TSNEAnalysis';

type Props = { plot: Plot; experiment: Experiment };
const TSNEMethodsSection = ({ plot, experiment }: Props) => {
    const { data: assayData, error: assayDataError } = useSWR<ExperimentData>(() =>
        Endpoints.lab.experiment.assayData({
            experimentId: experiment.uuid,
            limit: 250,
        }),
    );
    const assayDataLoading = !assayData && !assayDataError;
    const targetCount = assayData?.count ?? 0;
    const analysis = plot.analysis as TSNEAnalysis | null;
    const groupNames = analysis?.groups?.map((g) => g.display_name) ?? [];
    const allSamples = isEmptyList(analysis?.groups);
    const loading = assayDataLoading;
    const isCPMNormalized = analysis?.cpm_normalize ?? false;

    return (
        <section>
            <MethodsSectionTitle>
                <span className="italic">t</span>-distributed stochastic neighbor embedding analysis
            </MethodsSectionTitle>
            {loading ? (
                <LoadingMessage inline message="Loading methods..." className="mt-2" />
            ) : (
                <div className="space-y-4">
                    Barnes-Hut <span className="italic">t</span>-Distributed Stochastic Neighbor Embedding (
                    <span className="italic">t</span>-SNE)<sup>1, 2</sup> was performed by applying the{' '}
                    <span className="font-pre">Rtsne()</span>
                    <sup>3</sup> R function to{' '}
                    <span className="font-semibold">
                        {isCPMNormalized ? 'counts per million (CPM)-normalized values' : 'raw values'}
                    </span>{' '}
                    for all <span className="font-semibold">{targetCount.toLocaleString()}</span> targets in the
                    experiment and{' '}
                    {allSamples ? <span className="font-semibold">all samples</span> : <ProseList items={groupNames} />}
                    .
                    {analysis?.run_pre_pca && (
                        <span>
                            {' '}
                            An initial principal components analysis (PCA) step was run prior to{' '}
                            <span className="italic">t</span>-SNE.
                        </span>
                    )}{' '}
                    The perplexity parameter used was{' '}
                    <span className="font-semibold">{analysis?.perplexity ?? '0.1'}</span>.
                </div>
            )}
        </section>
    );
};

export default TSNEMethodsSection;
