import { ExperimentAnalysis, PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import AnalysisType from '@models/analysis/AnalysisType';
import copy from '@copy/Copy';
import { isPipelineUpgradeAvailable } from '@components/plots/util/PipelineVersionUtil';
import { isDefined } from '@util/TypeGuards';

type Props = { analysis: ExperimentAnalysis | null | undefined; className?: string; analysisInfo: AnalysisType };
const AnalysisPipelineUpgradeBanner = ({ analysis, analysisInfo, className }: Props) => {
    const latestVersion = analysisInfo?.pipeline_version;
    const isUpgradeAvailable = isPipelineUpgradeAvailable({ analysis, analysisType: analysisInfo });

    if (!isDefined(latestVersion) || !isUpgradeAvailable) {
        return null;
    }
    const currentVersion = (analysis as PipelineStatusAnalysis | undefined)?.pipeline_version ?? '1.0';
    return (
        <div className={cn('flex items-start space-x-2 rounded-lg bg-success  p-2 text-success', className)}>
            <div className="flex-shrink-0">
                <ArrowCircleUpIcon width={20} className="mt-0.5" />
            </div>
            <div>
                <p className="font-semibold">A new version of this pipeline is available (v{latestVersion})</p>
                <p>
                    {`Clicking "Run Analysis" will update your analysis from `}
                    <span className="font-semibold">v{currentVersion}</span>
                    {' to the latest version. '}
                    <a
                        href={copy.analysisChangeLogUrl}
                        className="link whitespace-nowrap"
                        target="_blank"
                        rel="nofollow noreferrer"
                    >
                        See what changed
                    </a>
                </p>
            </div>
        </div>
    );
};

export default AnalysisPipelineUpgradeBanner;
