import { FormControl, MenuItem, Select } from '@mui/material';
import FieldError from '@components/forms/FieldError';
import React from 'react';
import { getProjectColorClasses, ProjectColor } from '@models/Project';
import cn, { Argument as ClassValue } from 'classnames';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

type ColorInfo = {
    color: ProjectColor;
    name: string;
};

const colors: ColorInfo[] = [
    {
        color: ProjectColor.blue,
        name: 'Blue',
    },
    {
        color: ProjectColor.blueGray,
        name: 'Blue Gray',
    },
    {
        color: ProjectColor.cyan,
        name: 'Cyan',
    },
    {
        color: ProjectColor.violet,
        name: 'Violet',
    },
    {
        color: ProjectColor.lightGreen,
        name: 'Light Green',
    },
    {
        color: ProjectColor.rose,
        name: 'Rose',
    },
    {
        color: ProjectColor.gold,
        name: 'Gold',
    },
];

export type Props = {
    name: string;
    loading?: boolean;
    value?: ProjectColor | null;
    error?: string | null | boolean;
    label?: string | null;
    onChange: (value: ProjectColor | null) => void;
    className?: string;
};

export const ProjectColorDot = ({ color, className }: { color?: ProjectColor | null; className?: ClassValue }) => {
    const classes = getProjectColorClasses(color);
    return <span className={cn(classes.bg, className, 'mr-4 h-4 w-4 rounded-full')} />;
};

const ProjectColorSelect = ({ name, loading, value, error, onChange, label, className = 'form-field' }: Props) => {
    return (
        <div className={className}>
            {label && <span className="field-label">{label}</span>}
            <FormControl variant="outlined" fullWidth error={!!error}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    name={name}
                    value={loading ? 'loading' : value}
                    onChange={({ target }) => onChange((target.value as ProjectColor | null) ?? null)}
                >
                    {loading && (
                        <MenuItem disabled value="loading">
                            Loading...
                        </MenuItem>
                    )}
                    {!loading &&
                        colors.map((info) => {
                            return (
                                <MenuItem key={info.name} value={info.color}>
                                    <div className="flex flex-row items-center justify-start">
                                        <ProjectColorDot color={info.color} className="mr-4" />
                                        {info.name}
                                    </div>
                                </MenuItem>
                            );
                        })}
                </Select>
                {error && <FieldError>{error}</FieldError>}
            </FormControl>
        </div>
    );
};

export default ProjectColorSelect;
