import { SimpleBasePlotDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';
import { ComparativeHeatmapColorScale } from '@models/PlotConfigs';

export default interface ImageHeatmapDisplayOption extends SimpleBasePlotDisplayOption {
    is_full_width: boolean;
    cluster_samples: boolean;
    cluster_targets: boolean;
    color_by_variables: number[];
    heatmap_scale_color?: ComparativeHeatmapColorScale;
}

export function getClusteringNames(display?: ImageHeatmapDisplayOption | null): string[] | null {
    if (!display) {
        return null;
    }
    if (!display.cluster_targets && !display.cluster_samples) {
        return null;
    }

    const names: string[] = [];
    if (display.cluster_samples) {
        names.push('samples');
    }
    if (display.cluster_targets) {
        names.push('targets');
    }
    return names;
}
