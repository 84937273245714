import React from 'react';
import { useFormikContext } from 'formik';
import SimpleSelectField, { ChoiceItem } from './SimpleSelectField';
import { PeakSetConfig } from '@/src/models/PlotConfigs';

type Props = { options: ChoiceItem[] };
const PeakSetSelectField = ({ options }: Props) => {
    const { values, handleChange } = useFormikContext<PeakSetConfig>();

    return (
        <div className="form-field">
            <SimpleSelectField
                name="peak_set"
                label="Peak set"
                placeholder="Select a peak set..."
                value={values.peak_set}
                onChange={handleChange}
                items={options}
            />
        </div>
    );
};

export default PeakSetSelectField;
