import React, { PropsWithChildren, ReactNode } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, createTheme } from '@mui/material';

const theme = createTheme();
const styles = {
    accordion: {
        boxShadow: 'none',
        borderRadius: '10px !important',
        overflow: 'hidden',
    },
    accordionSummary: {
        minHeight: 'auto !important',
    },
    accordionDetails: {
        padding: theme.spacing(0, 2, 2),
    },
};
type ExpandableGroupProps = PropsWithChildren<{
    title: string;
    Icon: ReactNode;
}>;
const DisclosureAccordion = ({ Icon, title, children }: ExpandableGroupProps) => {
    return (
        <Accordion sx={styles.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMore className="w-5" />}
                sx={styles.accordionSummary}
                className="bg-amber-100"
            >
                <div className="flex items-center">
                    <div className="mr-2">{Icon}</div>
                    <p className="text-md font-semibold">{title}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails className="bg-amber-100" sx={styles.accordionDetails}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default DisclosureAccordion;
