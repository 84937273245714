import { Cell, TableInstance } from 'react-table';
import React, { ReactNode } from 'react';
import { isDefined } from '@util/TypeGuards';
import { programmaticColumns } from '@components/dataTable/DataTable';

export const DataTableBody = ({ tableInstance }: { tableInstance: TableInstance }) => {
    const { getTableBodyProps, page, rows, prepareRow } = tableInstance;

    const renderCellContent = (cell: Cell): ReactNode => {
        if (!isDefined(cell.value) && !programmaticColumns.includes(cell.column.id)) {
            return <i className="text-sm text-gray-300">N/A</i>;
        }
        return cell.render('Cell');
    };

    const currentRows = page ?? rows;
    return (
        <tbody {...getTableBodyProps()}>
            {currentRows.map((row) => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                    <tr {...rowProps} key={rowKey}>
                        {row.cells.map((cell) => {
                            const { key: cellKey, ...cellProps } = cell.getCellProps();
                            return (
                                <td
                                    {...cellProps}
                                    key={cellKey}
                                    className="whitespace-nowrap border-b border-l-0 border-r border-gray-200 bg-white px-4 py-2 first:border-l"
                                >
                                    {renderCellContent(cell)}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default DataTableBody;
