import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const KaplanMeierPlotIcon = ({
    height = 31,
    width = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            style={style}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            viewBox="0 0 31 32"
        >
            <path d="M1 1v29.5M1 12h7m0 4h6m10 8h6m-16-5h10m-4-2v4M12 6v4m5-4v4m7 9v5m-10-8v3m-6-7v4M1 5h6m0 0v3m0 0h13m10 23H1" />
        </svg>
    );
};
