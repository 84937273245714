import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import LoadingMessage from '@components/LoadingMessage';
import React, { useMemo } from 'react';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import KaplanMeierCurveDisplayOption from '@models/plotDisplayOption/KaplanMeierCurveDisplayOption';
import SurvivalAnalysisMethodsSection from '@components/experiments/methods/analyses/SurvivalAnalysisMethodsSection';
import KaplanMeierCurvePlotBuilder from '@components/analysisCategories/clinicalOutcomes/plots/builders/KaplanMeierCurvePlotBuilder';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { experiment: Experiment; plot: Plot; analysisParameters: AnalysisParameters | null };
const KaplanMeierCurveMethods = ({ experiment, plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as SurvivalAnalysis;
    const variables = plot.analysis?.variables ?? [];
    const { loading } = useExperimentPlotGroups({ experiment, variables, plot });

    const axisTitles = useMemo(() => {
        return KaplanMeierCurvePlotBuilder.getAxisTitles(plot as Plot<SurvivalAnalysis, KaplanMeierCurveDisplayOption>);
    }, [plot]);

    return (
        <div className="space-y-6">
            <SurvivalAnalysisMethodsSection plot={plot} analysisParameters={analysisParameters} />
            <section>
                <MethodsSectionTitle>Kaplan-Meier curve</MethodsSectionTitle>
                {loading && <LoadingMessage inline message="Loading..." className="mt-2" />}
                {!loading && (
                    <div>
                        <p>
                            Kaplan-Meier curve showing <span className="font-semibold">{axisTitles.yLabel}</span> on the{' '}
                            <span className="italic">y</span>-axis and{' '}
                            <span className="font-semibold">{axisTitles.xLabel}</span> in {analysis.time_to_event_units}{' '}
                            units on the <span className="italic">x</span>-axis. The curve is based on a tabulation of
                            the number at risk and number of events<sup>3</sup>.
                        </p>
                    </div>
                )}
            </section>
        </div>
    );
};

export default KaplanMeierCurveMethods;
