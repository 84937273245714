import { useField } from 'formik';
import TextInput from '@components/forms/TextInput';
import React, { ReactNode } from 'react';

type Props = { placeholder?: string | null | undefined; label?: ReactNode; name?: string; noMargin?: boolean };
const YAxisTitleField = ({ placeholder, label, name = 'y_axis_title', noMargin }: Props) => {
    const [inputProps] = useField<string>(name);
    return (
        <TextInput
            label={
                label ?? (
                    <span>
                        <span className="italic">y</span>-axis title
                    </span>
                )
            }
            value={inputProps.value ?? ''}
            name={name}
            onChange={inputProps.onChange}
            useFormikError
            placeholder={placeholder ?? undefined}
            noMargin={noMargin}
        />
    );
};

export default YAxisTitleField;
