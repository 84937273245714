import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import React from 'react';

const NormalizationCPMMethods = ({ analysis_type }: Pick<AssaySummaryAnalysis, 'analysis_type'>) => {
    switch (analysis_type) {
        case 'assay_summary_cpm_normalized':
            return (
                <span>
                    CPM values were calculated using the <span className="font-mono">cpm()</span> function in the{' '}
                    <span className="font-mono">edgeR</span> R package<sup>1</sup> with{' '}
                    <span className="font-mono">log=F</span>.
                </span>
            );
        default:
            return null;
    }
};

export default NormalizationCPMMethods;
