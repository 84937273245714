import Plot from '@models/Plot';
import { SurvivalData } from '@models/ExperimentData';
import React, { useMemo } from 'react';
import KaplanMeierCurveDisplayOption from '@models/plotDisplayOption/KaplanMeierCurveDisplayOption';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import KaplanMeierCurvePlotBuilder from '@components/analysisCategories/clinicalOutcomes/plots/builders/KaplanMeierCurvePlotBuilder';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = { customPlotStylingOptions?: CustomPlotStylingOptions | null };

const KaplanMeierCurvePlotView = ({ customPlotStylingOptions = null }: Props) => {
    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: svg, size, context, tooltipId }) => {
                const { plotData, experiment, plot } = context;
                const builder = KaplanMeierCurvePlotBuilder.make({
                    experiment,
                    plot: plot as Plot<SurvivalAnalysis, KaplanMeierCurveDisplayOption>,
                    data: plotData as SurvivalData,
                    width: size.width,
                    height: size.height,
                    publicationMode: context.publicationMode,
                    svg,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions,
                });

                builder.draw();
            },
        [customPlotStylingOptions],
    );

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default KaplanMeierCurvePlotView;
