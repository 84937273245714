import { useState, useEffect } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import useAuth from '@hooks/useAuth';
import Workflow, { AcceptedWorkflow, PreprocessParameters, WorkflowPreprocessParameters } from '@models/Workflow';
import Experiment, { CopyExperimentFromWorkflowParams } from '../models/Experiment';
import { usePollingEffect } from './usePollingEffect';

const useWorkflows = (experiment: Experiment | null | undefined, selectedWorkflow?: Workflow | null) => {
    const { authReady } = useAuth();
    const api = useApi();
    const [acceptWorkflowError, setAcceptWorkflowError] = useState<string | null>(null);
    const [acceptWorkflowLoading, setAcceptWorkflowLoading] = useState<boolean>(false);
    const [archiveError, setArchiveError] = useState<string | null>(null);
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
    const [createWorkflowError, setCreateWorkflowError] = useState<string | null>(null);
    const [createWorkflowLoading, setCreateWorkflowLoading] = useState<boolean>(false);
    const [forkError, setForkError] = useState<string | null>(null);
    const [forkLoading, setForkLoading] = useState<boolean>(false);
    const [preprocessParameters, setPreprocessParameters] = useState<WorkflowPreprocessParameters[] | null>(null);
    const [rollbackWorkflowError, setRollbackWorkflowError] = useState<string | null>(null);
    const [rollbackWorkflowLoading, setRollbackWorkflowLoading] = useState<boolean>(false);
    const [saveWorkflowError, setSaveWorkflowError] = useState<string | null>(null);
    const [saveWorkflowLoading, setSaveWorkflowLoading] = useState<boolean>(false);
    const [workflows, setWorkflows] = useState<Workflow[] | null>(null);
    const [workflowsError, setWorkflowsError] = useState<string | null>(null);
    const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(false);
    const [copyExperimentLoading, setCopyExperimentLoading] = useState<boolean>(false);
    const [copyExperimentError, setCopyExperimentError] = useState<string | null>(null);
    const workflowId = selectedWorkflow?.uuid ?? '';
    const experimentId = experiment?.uuid ?? '';

    const getPreprocessParameters = async (workflowId: string) => {
        const preprocessParams = await api.get<PreprocessParameters>(
            Endpoints.lab.experiment.workflow.preprocessParameters({
                experimentId,
                workflowId,
            }),
        );
        return preprocessParams;
    };

    const getWorkflowPreprocessParameters = async (
        workflowId: string,
        requireResolutionClusters = false,
    ): Promise<WorkflowPreprocessParameters> => {
        const params: PreprocessParameters = await getPreprocessParameters(workflowId);
        const workflowPreprocessParameters = {
            workflowId: workflowId,
            preprocessParameters: params,
        };
        if (!requireResolutionClusters) {
            setPreprocessParameters([workflowPreprocessParameters]);
            return workflowPreprocessParameters;
        }
        if (params.resolution_clusters.length > 0) {
            setPreprocessParameters([workflowPreprocessParameters]);
            return workflowPreprocessParameters;
        } else {
            return getWorkflowPreprocessParameters(workflowId);
        }
    };

    const getWorkflows = async (getParameters = false) => {
        setWorkflowsError(null);
        try {
            const data = await api.get<Workflow[]>(Endpoints.lab.experiment.workflows.base({ experimentId }));

            setWorkflows(data);
            setWorkflowsLoading(false);
            if (getParameters) {
                const newPreprocessParams: WorkflowPreprocessParameters[] = await Promise.all(
                    data.map(async (workflow) => {
                        const params: PreprocessParameters = await getPreprocessParameters(workflow.uuid);
                        return {
                            workflowId: workflow.uuid,
                            preprocessParameters: params,
                        };
                    }),
                );
                setPreprocessParameters(newPreprocessParams);
            }
        } catch (e) {
            setWorkflowsError(e);
            setWorkflowsLoading(false);
        }
    };

    const archiveWorkflow = async () => {
        setArchiveLoading(true);
        setArchiveError(null);
        try {
            const archivedWorkflow = await api.post<Workflow>(
                Endpoints.lab.experiment.workflow.archive({ experimentId, workflowId }),
            );
            await getWorkflows();
            setArchiveLoading(false);
            return archivedWorkflow;
        } catch (e) {
            setArchiveError(e);
            setArchiveLoading(false);
        }
    };
    const createWorkflow = async (name: string) => {
        setCreateWorkflowLoading(true);
        setCreateWorkflowError(null);
        try {
            const createdWorkflow = await api.post<Workflow>(
                Endpoints.lab.experiment.workflows.base({ experimentId }),
                {
                    template_id: '1.0',
                    name,
                },
            );
            await getWorkflows(true);
            setCreateWorkflowLoading(false);
            return createdWorkflow;
        } catch (e) {
            setCreateWorkflowError(e);
            setCreateWorkflowLoading(false);
        }
    };
    const forkWorkflow = async (name: string, copy_to_preprocess_id: string) => {
        setForkLoading(true);
        setForkError(null);
        try {
            const forkedWorkflow = await api.post<Workflow>(
                Endpoints.lab.experiment.workflow.copy({ experimentId, workflowId }),
                {
                    copy_to_preprocess_id,
                    name,
                },
            );
            setForkLoading(false);
            await getWorkflows(true);
            return forkedWorkflow;
        } catch (e) {
            setForkError(e);
            setForkLoading(false);
        }
    };
    const saveWorkflow = async (values: any) => {
        setSaveWorkflowLoading(true);
        setSaveWorkflowError(null);
        try {
            const updatedWorkflow = await api.put<Workflow>(
                Endpoints.lab.experiment.workflow.base({ experimentId, workflowId }),
                {
                    ...values,
                },
            );
            setSaveWorkflowLoading(false);
            await getWorkflows();
            return updatedWorkflow;
        } catch (e) {
            setSaveWorkflowLoading(false);
            setSaveWorkflowError(e);
        }
    };
    const acceptWorkflow = async () => {
        setAcceptWorkflowLoading(true);
        setAcceptWorkflowError(null);
        try {
            const acceptedWorkflow = await api.post<AcceptedWorkflow>(
                Endpoints.lab.experiment.workflow.accept({ experimentId, workflowId }),
            );
            await getWorkflows(true);
            setAcceptWorkflowLoading(false);
            return acceptedWorkflow;
        } catch (e) {
            setAcceptWorkflowError(e);
            setAcceptWorkflowLoading(false);
        }
    };
    const updateWorkflowsOrder = async (newLayout: string[]) => {
        try {
            const workflowOrder = await api.put<Workflow>(Endpoints.lab.experiment.base(experimentId), {
                organism: experiment?.organism?.shortname ?? '',
                name: experiment?.name,
                project_id: experiment?.project?.uuid,
                workflow_order: newLayout,
            });
            return workflowOrder;
        } catch (e) {}
    };
    const rollbackWorkflow = async () => {
        setRollbackWorkflowLoading(true);
        setRollbackWorkflowError(null);
        try {
            const rolledBackWorkflow = await api.doDelete<Workflow>(
                Endpoints.lab.experiment.workflow.accept({ experimentId, workflowId }),
            );
            setRollbackWorkflowLoading(false);
            await getWorkflows();
            return rolledBackWorkflow;
        } catch (e) {
            setRollbackWorkflowLoading(false);
            setRollbackWorkflowError(e);
        }
    };
    const copyExperimentFromWorkflow = async ({
        name,
        external_url,
        external_url_display_name,
        description,
    }: CopyExperimentFromWorkflowParams) => {
        setCopyExperimentLoading(true);
        setCopyExperimentError(null);
        try {
            const newExperiment = await api.post<Experiment>(
                Endpoints.lab.experiment.workflow.copyExperiment({ experimentId, workflowId }),
                {
                    name,
                    external_url,
                    external_url_display_name,
                    description,
                },
            );
            setCopyExperimentLoading(false);
            await getWorkflows(true);
            return newExperiment;
        } catch (e) {
            setCopyExperimentError(e);
            setCopyExperimentLoading(false);
        }
    };

    useEffect(() => {
        if (!authReady) return;

        if (experimentId) {
            setWorkflows(null);
            setWorkflowsLoading(true);
            getWorkflows(true);
        }
    }, [experimentId, authReady]);

    usePollingEffect(
        async () => {
            getWorkflows();
        },
        [experimentId],
        {
            trigger: Boolean(experimentId),
            interval: 20_000,
        },
    );

    return {
        acceptWorkflow,
        acceptWorkflowError,
        acceptWorkflowLoading,
        archiveError,
        archiveLoading,
        archiveWorkflow,
        copyExperimentError,
        copyExperimentFromWorkflow,
        copyExperimentLoading,
        createWorkflow,
        createWorkflowError,
        createWorkflowLoading,
        forkError,
        forkLoading,
        forkWorkflow,
        getPreprocessParameters,
        getWorkflowPreprocessParameters,
        getWorkflows,
        preprocessParameters,
        rollbackWorkflow,
        rollbackWorkflowError,
        rollbackWorkflowLoading,
        saveWorkflow,
        saveWorkflowError,
        saveWorkflowLoading,
        setPreprocessParameters,
        setWorkflows,
        updateWorkflowsOrder,
        workflows,
        workflowsError,
        workflowsLoading,
    };
};

export default useWorkflows;
