import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';

export type SessionStatus = 'in_progress' | 'completed' | 'invalid' | 'expired';
export default interface ImportSession {
    basespace_file?: BaseSpaceFile | null;
    uuid: string;
    status: SessionStatus;
    completed_at: string | null;
    updated_at: string | null;
    created_at: string | null;
}

export const getSessionStatusDisplayName = (status: SessionStatus) => {
    switch (status) {
        case 'in_progress':
            return 'Importing';
        case 'completed':
            return 'Import completed';
        case 'invalid':
            return 'Import failed';
        case 'expired':
            return 'Import expired';
    }
};
