import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const ProfilePlotIcon = ({
    width = 41,
    height = 25,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            className={className}
            fill="none"
            height={height}
            strokeWidth={strokeWidth ?? 2}
            style={style}
            viewBox="0 0 41 25"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.863363 22.5094C0.316258 22.5848 -0.0660839 23.0895 0.00937885 23.6366C0.0848416 24.1837 0.589532 24.5661 1.13664 24.4906L0.863363 22.5094ZM39.4288 23.4975C39.9796 23.5368 40.4581 23.1221 40.4975 22.5712C40.5368 22.0204 40.1221 21.5419 39.5712 21.5025L39.4288 23.4975ZM20 1L20.9769 0.786303C20.8746 0.31886 20.4556 -0.0106442 19.9772 0.000259697C19.4988 0.0111635 19.0952 0.359419 19.0144 0.831036L20 1ZM17.8944 18.9472C18.1414 18.4532 17.9412 17.8526 17.4472 17.6056C16.9532 17.3586 16.3526 17.5588 16.1056 18.0528L17.8944 18.9472ZM30.4119 21.8509L30.4832 20.8534L30.4119 21.8509ZM23.5 17L22.5231 17.2137L22.5449 17.3131L22.5862 17.4061L23.5 17ZM12.1774 21.9583L12.0407 20.9677L12.1774 21.9583ZM17 18.5L17.8944 18.9472L17.9606 18.8148L17.9856 18.669L17 18.5ZM1.13664 24.4906L12.314 22.9489L12.0407 20.9677L0.863363 22.5094L1.13664 24.4906ZM30.3407 22.8483L39.4288 23.4975L39.5712 21.5025L30.4832 20.8534L30.3407 22.8483ZM17.9856 18.669L20.9856 1.16896L19.0144 0.831036L16.0144 18.331L17.9856 18.669ZM19.0231 1.2137L22.5231 17.2137L24.4769 16.7863L20.9769 0.786303L19.0231 1.2137ZM30.4832 20.8534C27.8285 20.6638 25.4947 19.0259 24.4138 16.5939L22.5862 17.4061C23.9672 20.5134 26.949 22.606 30.3407 22.8483L30.4832 20.8534ZM12.314 22.9489C14.7203 22.617 16.8081 21.1198 17.8944 18.9472L16.1056 18.0528C15.3143 19.6354 13.7935 20.7259 12.0407 20.9677L12.314 22.9489Z"
                fill="currentColor"
            />
        </svg>
    );
};
