import { defaultTextClass, IconProps } from '@components/icons/CustomIcons';
import cn from 'classnames';
import useUUID from '@hooks/useUUID';

const BenchlingIcon = ({ className = defaultTextClass, height = 32, width = 32, ...props }: IconProps) => {
    const { uuid: id } = useUUID();
    return (
        <svg
            viewBox="0 0 272.38 368.71"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            className={cn(className)}
            height={height}
            width={width}
            {...props}
        >
            <defs>
                <clipPath transform="translate(652 -186)" id={id}>
                    <path
                        d="M-540.78,352.21c-18.18-5.2-45.19-12-93.57-16.56a8.39,8.39,0,0,1-7.44-6.55,8.41,8.41,0,0,1,4-9.14c.09-.09,9-8.61,16.09-26.48.8-2.05,1.74-5.66,2.81-9.8,6.69-26.26,22.47-87.68,103.11-87.68s96.42,61.43,103.15,87.68c1.07,4.15,2,7.75,2.81,9.8,7,17.88,16,26.39,16.14,26.48a8.42,8.42,0,0,1,3.88,9.14,8.33,8.33,0,0,1-7.44,6.55c-49.92,4.66-77.1,11.81-95.28,17.06v0a204.8,204.8,0,0,0-22,7.69l-1.16.47c-19.31,7.16-44.89,20.33-44.89,51.67s25.46,44.46,44.75,51.64c19.31-7.18,44.77-20.36,44.77-51.64,0-18.38-8.62-32.46-26.38-43l-1.43-.85,1.58-.53c6-2,12.89-4,20.57-5.83l.38-.09.31.24c15.53,13.19,23.39,30,23.39,50.1,0,26.89-13.93,47.78-41.41,62.12a143.91,143.91,0,0,1-18.71,8q-1.37.48-2.79,1c-19.34,7.16-45,20.31-45,51.73a9.23,9.23,0,0,1-9.22,9.23,9.23,9.23,0,0,1-9.2-9.23c0-26.45,13.5-47.11,40.13-61.43-26.63-14.31-40.13-35-40.13-61.45,0-25.8,12.84-46.11,38.2-60.38Zm78.94,192.5a9.23,9.23,0,0,1-9.2-9.23c0-18.39-8.64-32.47-26.42-43.06l-1.28-.76,1.36-.59a134.58,134.58,0,0,0,16.88-8.68l.44-.27.41.32c17.93,13.59,27,31.43,27,53.05a9.23,9.23,0,0,1-9.22,9.23Zm-83-19.12a32.78,32.78,0,0,1,10.17-14.75H-497a32.78,32.78,0,0,1,10.18,14.75Zm0-122.88A32.82,32.82,0,0,1-534.67,388H-497a32.82,32.82,0,0,1,10.18,14.75Zm58.07,21.3a32.79,32.79,0,0,1-10.17,14.75h-37.72A32.76,32.76,0,0,1-544.84,424Zm12-135A10.8,10.8,0,0,0-464,299.82,10.8,10.8,0,0,0-453.23,289,10.8,10.8,0,0,0-464,278.24,10.8,10.8,0,0,0-474.81,289Zm-103.55,0a10.77,10.77,0,0,0,10.79,10.79A10.76,10.76,0,0,0-556.83,289a10.76,10.76,0,0,0-10.74-10.79A10.77,10.77,0,0,0-578.36,289Z"
                        className="fill-none"
                        style={{ clipRule: 'evenodd' }}
                    ></path>
                </clipPath>
            </defs>
            <title>Navy JeffyP</title>
            <g className="fill-none" clipPath={`url(#${id})`}>
                <rect height="368.71" width="272.38" className="fill-current"></rect>
            </g>
        </svg>
    );
};

export default BenchlingIcon;
