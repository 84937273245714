import { IconProps } from '@components/icons/CustomIcons';

export const ConditionIcon = (props?: IconProps) => {
    const { width = 20, height = 18 } = { ...props };
    return (
        <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <circle cx="10" cy="3" r="3" fill="currentColor" />
            <circle cx="3" cy="15" r="3" fill="currentColor" />
            <circle cx="17" cy="15" r="3" fill="currentColor" />
            <path
                d="M2 15.5C2 16.0523 2.44772 16.5 3 16.5C3.55228 16.5 4 16.0523 4 15.5H2ZM3 9V8C2.44772 8 2 8.44772 2 9H3ZM17 9H18C18 8.44772 17.5523 8 17 8V9ZM16 15.5C16 16.0523 16.4477 16.5 17 16.5C17.5523 16.5 18 16.0523 18 15.5H16ZM4 15.5V9H2V15.5H4ZM3 10H17V8H3V10ZM16 9V15.5H18V9H16Z"
                fill="currentColor"
            />
            <line x1="10" y1="9" x2="10" y2="4" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
};
