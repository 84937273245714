import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { IntegrateSeuratObjectFormValues, PreprocessFormValues } from '../../preprocesses/PreprocessFormTypes';
import CodeBlockInline from '@components/CodeBlockInline';
import ProseList from '@components/ProseList';
import { formatStringToNumberWithSeparator } from '@/src/util/StringUtil';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string };
const IntegrateSeuratMethods = ({ preprocessFormData, displayName }: Props) => {
    const preprocess = preprocessFormData as IntegrateSeuratObjectFormValues;
    const normMethodLog = preprocess.norm_method === 'lognormalize';
    const normMethodSct = preprocess.norm_method === 'sctransform';
    const intMethodRpca = preprocess.int_method === 'rpca';
    const intMethodCca = preprocess.int_method === 'cca';
    const intMethodHarmony = preprocess.int_method === 'harmony';
    const intMethodSkipped = preprocess.int_method === 'skip';

    const getIntMethodString = () => {
        if (intMethodRpca) {
            return 'Reciprocal Principal Component Analysis (RPCA)';
        }
        if (intMethodCca) {
            return 'Canonical Correlation Analysis (CCA)';
        }
        return null;
    };
    const getNormMethodStringHarmony = () => {
        if (normMethodLog) {
            return 'RNA assay';
        }
        if (normMethodSct) {
            return 'SCT assay';
        }
        return null;
    };
    const getNormMethodStringNonHarmony = () => {
        if (normMethodLog) {
            return 'log normalization';
        }
        if (normMethodSct) {
            return 'SCTransform normalization';
        }
        return null;
    };

    return (
        <div className="space-y-6">
            <section>
                {intMethodSkipped ? (
                    <p>Integration was skipped. No methods are available.</p>
                ) : (
                    <>
                        <MethodsSectionTitle>{displayName}</MethodsSectionTitle>
                        <div className="space-y-4">
                            {preprocess.int_method === 'harmony' && (
                                <p>
                                    Data was integrated using the Harmony function{' '}
                                    <CodeBlockInline>RunHarmony()</CodeBlockInline> and the{' '}
                                    {getNormMethodStringHarmony()} over the following covariate(s):{' '}
                                    <ProseList items={preprocess?.covariates ?? []} />.
                                </p>
                            )}
                            {(intMethodRpca || intMethodCca) && (
                                <>
                                    <p>
                                        Integration was performed using Seurat&apos;s {getIntMethodString()} method,
                                        with {getNormMethodStringNonHarmony()} and{' '}
                                        {formatStringToNumberWithSeparator(preprocess.n_features)} variable genes over
                                        the following covariate: <ProseList items={preprocess?.covariates ?? []} />.
                                    </p>
                                    <p>
                                        Note that if the number of cells in the smallest subset of data is less than any
                                        of the following parameters, the minimum cell number minus 1 was used instead as
                                        the following parameters cannot exceed the number of cells:
                                    </p>
                                    <ul className="ml-6 list-disc space-y-2">
                                        {intMethodRpca ? (
                                            <li>
                                                <CodeBlockInline>npcs = 50</CodeBlockInline> (
                                                <CodeBlockInline>RunPCA()</CodeBlockInline>)
                                            </li>
                                        ) : null}
                                        <li>
                                            <CodeBlockInline>k.anchor = 5</CodeBlockInline> (
                                            <CodeBlockInline>FindIntegrationAnchors()</CodeBlockInline>)
                                        </li>
                                        <li>
                                            <CodeBlockInline>k.filter = 200</CodeBlockInline> (
                                            <CodeBlockInline>FindIntegrationAnchors()</CodeBlockInline>)
                                        </li>
                                        <li>
                                            <CodeBlockInline>dims = 30</CodeBlockInline> (
                                            <CodeBlockInline>FindIntegrationAnchors()</CodeBlockInline>,{' '}
                                            <CodeBlockInline>IngrateData()</CodeBlockInline>)
                                        </li>
                                        <li>
                                            <CodeBlockInline>k.weight = 100</CodeBlockInline> (
                                            <CodeBlockInline>IntegrateData()</CodeBlockInline>)
                                        </li>
                                    </ul>
                                </>
                            )}

                            <div>
                                <MethodsSectionTitle>Dimensionality reduction</MethodsSectionTitle>
                                {intMethodHarmony && (
                                    <p>
                                        Uniform manifold approximation and projection (UMAP) and t-distributed stochasic
                                        neighbor embedding (t-SNE) dimensional reductions were re-computed on the
                                        integrated Seurat object using the Harmony embeddings. For UMAP embeddings,{' '}
                                        {formatStringToNumberWithSeparator(preprocess.n_neighbors)} neighboring points
                                        and the first {formatStringToNumberWithSeparator(preprocess.n_dims)} principal
                                        components were passed as parameters to the{' '}
                                        <CodeBlockInline>RunUMAP()</CodeBlockInline> function. For tSNE embeddings, the
                                        first {formatStringToNumberWithSeparator(preprocess.n_dims)} principal
                                        components were passed as parameters to the{' '}
                                        <CodeBlockInline>RunTSNE()</CodeBlockInline> function.
                                    </p>
                                )}
                                {(intMethodRpca || intMethodCca) && (
                                    <p>
                                        Dimensionality reduction was performed using the top{' '}
                                        {formatStringToNumberWithSeparator(preprocess.n_features)} variable features on
                                        the integrated Seurat object. Principal component analysis (PCA) (
                                        <CodeBlockInline>RunPCA()</CodeBlockInline>) was applied to capture major
                                        sources of variability. Uniform manifold approximation and projection (UMAP) (
                                        <CodeBlockInline>RunUMAP()</CodeBlockInline>) was utilized for non-linear
                                        dimensionality reduction, using{' '}
                                        {formatStringToNumberWithSeparator(preprocess.n_neighbors)} neighboring points
                                        and the first {formatStringToNumberWithSeparator(preprocess.n_dims)} principal
                                        components. t-distributed stochastic neighbor embedding (t-SNE) (
                                        <CodeBlockInline>RunTSNE()</CodeBlockInline>) was used for further
                                        visualization, using the first{' '}
                                        {formatStringToNumberWithSeparator(preprocess.n_dims)} principal components.
                                    </p>
                                )}
                            </div>

                            <div>
                                <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                                <p>
                                    R v4.3.1
                                    <br />
                                    Seurat v4.3.0
                                    <br />
                                    {intMethodHarmony ? 'Harmony v0.1.1' : null}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </section>
        </div>
    );
};

export default IntegrateSeuratMethods;
