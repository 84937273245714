import TextInput from '@components/forms/TextInput';
import React, { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

type Props = { placeholder?: string | null | undefined; label?: ReactNode };
const ChartTitleField = ({ placeholder, label = 'Plot title' }: Props) => {
    const { values, handleChange } = useFormikContext<BasePlotDisplayOptionFormValues>();
    return (
        <>
            <TextInput
                label={label}
                name="plot_title"
                value={values.plot_title ?? ''}
                placeholder={placeholder ?? ''}
                onChange={handleChange}
                useFormikError
            />
        </>
    );
};
export default ChartTitleField;
