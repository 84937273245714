import { usePlotContext } from '@contexts/PlotContext';
import LoadingMessage from '@components/LoadingMessage';
import dynamic from 'next/dynamic';
import { PlotParams } from 'react-plotly.js';
import { ArrowPlotData, GenericCellData } from '@models/ExperimentData';
import React, { useEffect, useMemo, useRef } from 'react';
import useOnScreen from '@hooks/useOnScreen';
import { useResizableContainerContext } from '@contexts/ResizableContainerContext';
import Mono from '@components/elements/Mono';
import { useFeatureToggleContext } from '@contexts/FeatureToggleContext';
import Logger from '@util/Logger';
import { buildPlotlyLayout, prepareData } from '@components/analysisCategories/genome/plots/pie/PlotlyPieChartUtil';
import Button from '@components/Button';
import { TimeoutValue } from '@util/ObjectUtil';
import PieChartDisplayOption from '@models/plotDisplayOption/PieChartDisplayOption';
import { PlotDataHeaderAnalysis } from '@/src/models/analysis/PlotDataHeaderAnalysis';
import { PieChartIcon } from '@/src/components/icons/custom/PieChartIcon';

const logger = Logger.make('PlotlyPieChart');
const Plotly = dynamic(() => import('react-plotly.js'), { ssr: false });

const PlotlyPieChart = () => {
    const { size, containerRef: resizeRef } = useResizableContainerContext();
    const timeoutRef = useRef<TimeoutValue | null>(null);
    const featureToggles = useFeatureToggleContext();
    const { onScreen, forceShow } = useOnScreen({ ref: resizeRef, initialOnScreen: true });
    const { plotData: pd, plotDataLoading, plot, publicationMode, isDragging } = usePlotContext();

    const plotData = pd as ArrowPlotData<GenericCellData> | null | undefined;
    const display = plot?.display as PieChartDisplayOption;
    const analysis = plot?.analysis as PlotDataHeaderAnalysis;

    // this is a bit of a hack to ensure the plot stays visible after dragging has finished.
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            forceShow();
        }, 10);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isDragging]);

    if (plotDataLoading) {
        return <LoadingMessage immediate />;
    }

    if (!display?.peak_set) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>Select a peak set from the Plot tab to view your pie</div>
            </div>
        );
    }

    if (!plotData) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>No plot data is available</div>
            </div>
        );
    }

    if (!plot || !display) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>Unable to load plot: no plot was found</div>
            </div>
        );
    }

    const showPercentages = display.show_percentages;

    const { data } = useMemo<{
        data: PlotParams['data'] | null;
    }>(() => {
        try {
            const items = plotData.items as GenericCellData[];
            const themeColor = display?.theme_color;
            const customColors = display?.custom_color_json ?? {};
            const customLegend = display?.custom_legend_json ?? {};
            const legendDisplayOrder = display?.legend_display_order ?? [];
            const plotDataHeaders = analysis?.plot_data_headers ?? [];
            const xAxisIdentifier = plotDataHeaders?.[0] ?? 'peak_set';

            const selectedItem = (items.find((i) => i[xAxisIdentifier] === display.peak_set) ?? []) as GenericCellData;
            const { [xAxisIdentifier]: _, ...rest } = selectedItem;

            const { preparedItems } = prepareData({
                analysisType: plot.analysis_type,
                customColors,
                customLegend,
                legendDisplayOrder,
                selectedItem: rest,
                showPercentages,
                themeColor,
            });

            // Define traces
            const data: PlotParams['data'] = preparedItems;

            return { data };
        } catch (error) {
            logger.error(error);
            return { data: null };
        }
    }, [display, plotData, publicationMode]);

    if (!data) {
        logger.warn('no processed data found', data, plotData);
        return null;
    }

    const layoutCfg = buildPlotlyLayout({
        size,
    });
    const layout = { ...layoutCfg };
    return (
        <>
            <div className="relative flex h-full w-full items-center justify-center">
                {onScreen || isDragging ? (
                    <Plotly
                        data={data}
                        layout={layout}
                        useResizeHandler={false}
                        config={{
                            displayModeBar: false, // TODO: use export mode to conditionally render this
                            autosizable: false,
                        }}
                    />
                ) : (
                    <div className="h-full w-full p-4">
                        <div className="flex h-full w-full flex-col items-center justify-center space-y-4 rounded-lg border border-indigo-100">
                            <div className="flex items-center">
                                <div className="rounded-full bg-indigo-100 p-3 text-indigo-600">
                                    <PieChartIcon width={32} height={32} />
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => forceShow()} variant="outlined" size="small" color="primary">
                                    Reload plot
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {featureToggles.isEnabled('plot_size_debug') && (
                    <div className="pointer-events-none absolute z-50 flex h-full w-full flex-col items-start justify-start border-2 border-dashed border-emerald-300">
                        <div className="z-50 bg-emerald-300/50 p-2 text-xs">
                            <p>
                                <Mono>PlotlyPieChart.tsx</Mono>
                            </p>
                            <p>height: {size?.height}px</p>
                            <p>width: {size?.width}px</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PlotlyPieChart;
