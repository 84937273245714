import { useFormikContext } from 'formik';
import { ErrorBarsConfig } from '@models/PlotConfigs';
import { Collapse, Switch } from '@mui/material';
import React from 'react';
import ErrorBarLocationField from '@components/experiments/plotDisplay/fields/ErrorBarLocationField';
import ErrorBarOptionField from '@components/experiments/plotDisplay/fields/ErrorBarOptionField';
import { useSwitchStyles } from '@components/SwitchStyles';

const ErrorBarsFieldGroup = () => {
    const switchStyles = useSwitchStyles();
    const { values, setFieldValue } = useFormikContext<ErrorBarsConfig>();
    return (
        <>
            <h4 className="flex flex-row justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark">
                <span>Error Bars</span>
                <Switch
                    sx={switchStyles}
                    checked={values.show_error_bars ?? false}
                    name="show_error_bars"
                    onChange={(e) => {
                        const checked = e.target.checked;
                        if (!values.show_error_bars && checked) {
                            setFieldValue('error_bars_locations', ['top', 'bottom']);
                        }
                        setFieldValue('show_error_bars', checked);
                    }}
                />
            </h4>
            <Collapse in={values.show_error_bars}>
                <ErrorBarLocationField />
                <ErrorBarOptionField />
            </Collapse>
        </>
    );
};

export default ErrorBarsFieldGroup;
