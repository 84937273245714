import { IconProps } from '@components/icons/CustomIcons';

export const GenderIcon = (props?: IconProps) => {
    const { height = 23, width = 20 } = { ...props };
    return (
        <svg height={height} width={width} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0312 0H18.9453C18.5273 0 18.3203 0.503906 18.6133 0.800781L19.2734 1.46094L17.3711 3.36328C16.5039 2.81641 15.4766 2.5 14.375 2.5C13.0586 2.5 11.8516 2.95312 10.8906 3.70703C11.4375 4.35938 11.8672 5.11328 12.1445 5.9375C12.7109 5.35938 13.5 5 14.375 5C16.0977 5 17.5 6.40234 17.5 8.125C17.5 9.84766 16.0977 11.25 14.375 11.25C13.5039 11.25 12.7109 10.8906 12.1445 10.3125C11.8672 11.1367 11.4414 11.8906 10.8906 12.543C11.8477 13.3008 13.0586 13.75 14.375 13.75C17.4805 13.75 20 11.2305 20 8.125C20 7.02344 19.6836 5.99609 19.1367 5.12891L21.0391 3.22656L21.6992 3.88672C21.793 3.98047 21.9102 4.02344 22.0273 4.02344C22.2695 4.02344 22.5 3.83594 22.5 3.55469V0.46875C22.5 0.210938 22.2891 0 22.0312 0ZM5.625 2.5C2.51953 2.5 0 5.01953 0 8.125C0 10.8008 1.87109 13.043 4.375 13.6094V15.625H2.96875C2.71094 15.625 2.5 15.8359 2.5 16.0938V17.6562C2.5 17.9141 2.71094 18.125 2.96875 18.125H4.375V19.5312C4.375 19.7891 4.58594 20 4.84375 20H6.40625C6.66406 20 6.875 19.7891 6.875 19.5312V18.125H8.28125C8.53906 18.125 8.75 17.9141 8.75 17.6562V16.0938C8.75 15.8359 8.53906 15.625 8.28125 15.625H6.875V13.6094C9.37891 13.0391 11.25 10.8008 11.25 8.125C11.25 5.01953 8.73047 2.5 5.625 2.5ZM5.625 11.25C3.90234 11.25 2.5 9.84766 2.5 8.125C2.5 6.40234 3.90234 5 5.625 5C7.34766 5 8.75 6.40234 8.75 8.125C8.75 9.84766 7.34766 11.25 5.625 11.25Z"
                fill="currentColor"
            />
        </svg>
    );
};
