import useInlineFileDownload from '@hooks/useInlineFileDownload';
import useApi from '@hooks/useApi';
import { useState } from 'react';
import Logger from '@util/Logger';

const logger = Logger.make('hooks/useDownloadData');

const useDownloadData = ({
    endpoint,
    baseFilename,
    url,
    newTab,
}: {
    /**
     * Endpoint getter to fetch the signed data url. Required if `url` is not provided.
     * @param {string} filename
     * @return {string}
     */
    endpoint?: (filename: string) => string;
    /**
     * The filename without extension. The extension will be added based on the file format selected
     */
    baseFilename: string;
    /**
     *  The URL to download the file, if available. This should be the same as the value returned from fetching data using
     *  endpoint param. Used if we already have the download URL. Required if `endpoint` is not provided.
     */
    url?: string;

    /**
     * Should the generated anchor tag use a new tab to download the file
     */
    newTab?: boolean;
}) => {
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const { get } = useApi();

    const { triggerFileDownload } = useInlineFileDownload();
    const startDownload = async ({ extension }: { extension: string }) => {
        setCompleted(false);
        setLoading(true);
        const filename = `${baseFilename}${extension}`;
        logger.debug(`starting download for ${filename}`);
        try {
            if (url) {
                logger.debug(`downloading from url: ${url}`);
                triggerFileDownload({ url, filename: filename, newTab });
            } else if (endpoint) {
                const downloadEndpoint = endpoint(filename);
                logger.debug(`download from endpoint: ${downloadEndpoint}`);
                logger.debug('download endpoint', downloadEndpoint);
                const response = await get<{ url: string }>(downloadEndpoint);
                if (response.url) {
                    triggerFileDownload({ url: response.url, filename: filename, newTab });
                }
            } else {
                logger.debug(`NO DOWNLOAD URL OR ENDPOINT AVAILABLE for filename: ${filename}`);
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setLoading(false);
            setCompleted(true);
        }
    };

    return { loading, completed, startDownload };
};

export default useDownloadData;
