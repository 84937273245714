import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const ViolinPlotIcon = ({ height = 40, width = 40, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            fill="currentColor"
            viewBox="0 0 40 40"
        >
            <path
                d="M30.9622 23.8303C30.3863 24.5414 29.6256 25.0844 29.3077 25.8462V38.0744C29.3077 38.2089 29.1986 38.318 29.0641 38.318C28.9296 38.318 28.8205 38.2089 28.8205 38.0744V25.8462C28.5026 25.0844 27.7494 24.5414 27.1736 23.8303C26.6532 23.1878 25.4862 22.2941 25.4862 22.2941L23.1238 20.566C23.1238 20.566 22.0841 19.6769 21.7739 19.0298C21.3578 18.1616 21.501 17.6119 21.7739 16.7255C21.9395 16.1878 22.4489 15.3814 22.4489 15.3814L23.7988 13.8452C23.7988 13.8452 25.211 12.873 25.4862 12.117C25.6733 11.603 25.4862 10.7729 25.4862 10.7729C25.4862 10.7729 25.2033 9.35396 25.4862 8.46861C25.7084 7.77308 25.9959 7.39744 26.4892 6.7527L26.4892 6.75269L26.4986 6.74042C27.0402 6.03254 27.6673 5.72566 28.186 5.01223C28.5046 4.57397 28.8609 3.89261 28.8609 3.8601V1.98639C28.8609 1.8721 28.9536 1.77948 29.0679 1.77948C29.1821 1.77948 29.2748 1.87175 29.2748 1.98603V3.8601C29.2748 3.89261 29.6311 4.57397 29.9497 5.01223C30.4684 5.72566 31.0955 6.03254 31.6371 6.74042L31.6465 6.75269L31.6465 6.75271C32.1399 7.39744 32.4273 7.77309 32.6495 8.46861C32.9325 9.35396 32.6495 10.7729 32.6495 10.7729C32.6495 10.7729 32.4625 11.603 32.6495 12.117C32.9247 12.873 34.3369 13.8452 34.3369 13.8452L35.6868 15.3814C35.6868 15.3814 36.1962 16.1878 36.3618 16.7255C36.6347 17.6119 36.7779 18.1616 36.3618 19.0298C36.0516 19.6769 35.0119 20.566 35.0119 20.566L32.6495 22.2941C32.6495 22.2941 31.4825 23.1878 30.9622 23.8303Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.0992 4.9914L11.2821 1.43845L11.3845 4.9914C11.3845 4.9914 11.3975 5.46233 11.3845 5.76393C11.3652 6.21245 11.3213 6.6392 11.2583 7.04031C11.3802 7.60924 11.5606 8.1384 11.7741 8.85404C12.0003 9.61223 12.1855 10.0311 12.4491 10.7854C12.7126 11.5396 12.8978 11.9585 13.124 12.7167L13.1353 12.7547C13.486 13.9299 13.6874 14.6052 13.7989 15.8068C13.8828 16.7106 13.6362 17.2241 13.7989 18.1244C14.0226 19.3617 14.266 20.0778 15.1488 21.2145C15.773 22.0182 16.4304 22.3759 17.1736 23.1458C18.0286 24.0315 18.8148 24.4756 19.1984 25.4634C19.5414 26.3469 19.6145 26.9078 19.1984 27.781C18.8882 28.4318 18.4632 28.7504 17.8485 29.326C17.1613 29.9696 16.6144 30.2677 15.8237 30.8711C15.033 31.4744 14.5552 31.7985 13.7989 32.4161C13.2535 32.8615 12.8591 33.0843 12.4491 33.5749C12.0894 34.0052 12.0112 34.2764 11.7741 34.7337C11.6657 34.9429 11.6004 35.2307 11.5437 35.5037C11.5024 35.7028 11.4657 35.894 11.4202 36.0414C11.4091 36.1126 11.3973 36.1916 11.3845 36.2788C11.3686 36.388 11.3363 36.3764 11.2916 36.2888C11.1646 36.0395 10.9384 35.175 10.7096 34.7337C10.4725 34.2764 10.3943 34.0052 10.0347 33.5749C9.62464 33.0843 9.2302 32.8615 8.68481 32.4161C7.92848 31.7985 7.45074 31.4744 6.66001 30.8711C5.86928 30.2677 5.32243 29.9696 4.63521 29.326C4.02055 28.7504 3.5955 28.4318 3.28535 27.781C2.86924 26.9078 2.94228 26.3469 3.28535 25.4634C3.66891 24.4756 4.45514 24.0315 5.31015 23.1458C6.05334 22.3759 6.71075 22.0182 7.33494 21.2145C8.21769 20.0778 8.46115 19.3617 8.68481 18.1244C8.84754 17.2241 8.60095 16.7106 8.68481 15.8068C8.79628 14.6053 8.99777 13.9299 9.34838 12.7547L9.34839 12.7547L9.35974 12.7167C9.58593 11.9585 9.7711 11.5396 10.0347 10.7854C10.2983 10.0311 10.4335 9.82582 10.7949 8.85404C10.9869 8.33754 11.1505 7.72647 11.2583 7.04031C11.176 6.65619 11.1203 6.25394 11.0992 5.76393C11.0862 5.46233 11.0992 4.9914 11.0992 4.9914Z"
                fill="currentColor"
            />
            <path
                d="M11.2821 38.6103C11.2821 38.6103 10.9387 36.1664 11.0992 36.2788C11.5065 36.5641 11.465 35.33 11.7741 34.7337C12.0112 34.2764 12.0894 34.0052 12.4491 33.5749C12.8591 33.0843 13.2535 32.8615 13.7989 32.4161C14.5552 31.7985 15.033 31.4744 15.8237 30.8711C16.6144 30.2677 17.1613 29.9696 17.8485 29.326C18.4632 28.7504 18.8882 28.4318 19.1984 27.781C19.6145 26.9078 19.5414 26.3469 19.1984 25.4634C18.8148 24.4756 18.0286 24.0315 17.1736 23.1458C16.4304 22.3759 15.773 22.0182 15.1488 21.2145C14.266 20.0778 14.0226 19.3617 13.7989 18.1244C13.6362 17.2241 13.8828 16.7106 13.7989 15.8068C13.6874 14.6052 13.486 13.9299 13.1353 12.7547L13.124 12.7167C12.8978 11.9585 12.7126 11.5396 12.4491 10.7854C12.1855 10.0311 12.0003 9.61223 11.7741 8.85404C11.4165 7.65523 11.1516 6.97971 11.0992 5.76393C11.0862 5.46233 11.0992 4.9914 11.0992 4.9914L11.2821 1.43845L11.3845 4.9914C11.3845 4.9914 11.3975 5.46233 11.3845 5.76393C11.3321 6.97971 11.0992 8.03562 10.7949 8.85404C10.4335 9.82582 10.2983 10.0311 10.0347 10.7854C9.7711 11.5396 9.58593 11.9585 9.35974 12.7167L9.34839 12.7547C8.99777 13.9299 8.79628 14.6052 8.68481 15.8068C8.60095 16.7106 8.84754 17.2241 8.68481 18.1244C8.46115 19.3617 8.21769 20.0778 7.33494 21.2145C6.71075 22.0182 6.05334 22.3759 5.31015 23.1458C4.45514 24.0315 3.66891 24.4756 3.28535 25.4634C2.94228 26.3469 2.86924 26.9078 3.28535 27.781C3.5955 28.4318 4.02055 28.7504 4.63521 29.326C5.32243 29.9696 5.86928 30.2677 6.66001 30.8711C7.45074 31.4744 7.92848 31.7985 8.68481 32.4161C9.2302 32.8615 9.62464 33.0843 10.0347 33.5749C10.3943 34.0052 10.4725 34.2764 10.7096 34.7337C11.0187 35.33 11.3232 36.699 11.3845 36.2788C11.7692 33.641 11.3845 38.5128 11.3845 38.5128"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M12.2721 8.74506V2.10123C12.2721 1.7253 12.0563 1.38098 11.719 1.215C11.4448 1.08007 11.1216 1.08025 10.8483 1.21692C10.517 1.38255 10.3077 1.72114 10.3077 2.09151L10.3077 8.75086C10.3077 8.98369 10.3906 9.20892 10.5415 9.38623C10.9309 9.84377 11.6387 9.84403 12.0317 9.38957C12.1863 9.21076 12.2721 8.98146 12.2721 8.74506Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M11.2821 39V39C11.8202 39 12.2564 38.5638 12.2564 38.0256V34.1282C12.2564 33.5901 11.8202 33.1538 11.2821 33.1538V33.1538C10.7439 33.1538 10.3077 33.5901 10.3077 34.1282V38.0256C10.3077 38.5638 10.7439 39 11.2821 39V39ZM11.2821 39L11.7159 38.3492C11.947 38.0026 11.6986 37.5385 11.2821 37.5385V37.5385C10.8656 37.5385 10.6172 38.0026 10.8482 38.3492L11.2821 39Z"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path d="M11.7692 10.7436H9.82053V11.2308H11.7692" stroke="currentColor" strokeWidth="0.5" />
        </svg>
    );
};
