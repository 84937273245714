import { CellProps } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import React from 'react';
import GeneInfoPopover from '@components/GeneInfoPopover';
import { OrganismID } from '@models/Organism';

export const MouseGeneLinkCell = ({ value }: CellProps<GenericCellData>) => {
    return (
        <div className="">
            <GeneInfoPopover gene={value} organism={OrganismID.mouse} />
        </div>
    );
};

export default MouseGeneLinkCell;
