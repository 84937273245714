import { useField } from 'formik';
import TextInput from '@components/forms/TextInput';
import React, { ReactNode } from 'react';

type Props = { placeholder?: string | null | undefined; name?: string; label?: ReactNode; noMargin?: boolean };
const XAxisTitleField = ({ placeholder, name = 'x_axis_title', label, noMargin }: Props) => {
    const [inputProps] = useField<string>(name);

    return (
        <>
            <TextInput
                label={
                    label ?? (
                        <span>
                            <span className="italic">x</span>-axis title
                        </span>
                    )
                }
                value={inputProps.value ?? ''}
                name={name}
                onChange={inputProps.onChange}
                useFormikError
                placeholder={placeholder ?? undefined}
                noMargin={noMargin}
            />
        </>
    );
};

export default XAxisTitleField;
