import Experiment from '@models/Experiment';
import AssayDataTableView from '@components/experiments/AssayDataTableView';
import AssayFileList from '@components/experiments/wizard/AssayFileList';
import React, { useState } from 'react';
import ArchiveExperimentFileConfirmModal from '@components/ArchiveExperimentFileConfirmModal';
import PlutoFile, { FileDataType } from '@models/PlutoFile';
import { FormControl, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import useExperimentFiles from '@hooks/useExperimentFiles';

type Tab = 'table' | FileDataType;

const getInitialTab = (experiment: Experiment): Tab => {
    if (experiment.assay_data_uploaded) {
        return 'table';
    }
    if (experiment.fastqs_uploaded) {
        return 'fastq';
    }
    if (experiment.seurats_uploaded) {
        return 'seurat';
    }
    return 'table';
};
type Props = { experiment: Experiment; viewDataOnly?: boolean };
const ExperimentDetailAssayDataTabView = ({ experiment, viewDataOnly }: Props) => {
    const { data: experimentFiles } = useExperimentFiles({ experiment });
    const [fastqFileToArchive, setFastqFileToArchive] = useState<PlutoFile | null>(null);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [tab, setTab] = useState<Tab>(getInitialTab(experiment));
    const showSeurat = experiment.type.shortname === 'scrnaseq';
    const unitName =
        experiment?.assay_data_units?.units_display_name ??
        experiment?.assay_data_units?.units?.display_name ??
        'Data table';

    const showFormatPicker = experiment.fastqs_uploaded || experiment.seurats_uploaded || showSeurat;
    const fastqFiles = experimentFiles?.fastq?.items ?? [];
    const seuratFiles = experimentFiles?.seurat?.items ?? [];

    const renderData = () => {
        return (
            <div className="">
                {tab === 'table' && (
                    <AssayDataTableView experiment={experiment} showUnits={true} viewDataOnly={viewDataOnly} />
                )}
                {tab === 'fastq' && (
                    <div className="">
                        <AssayFileList
                            experiment={experiment}
                            files={fastqFiles}
                            title="FASTQ files"
                            onRemove={(file) => {
                                setFastqFileToArchive(file);
                                setArchiveModalOpen(true);
                            }}
                        />
                        {fastqFileToArchive && (
                            <ArchiveExperimentFileConfirmModal
                                file={fastqFileToArchive}
                                experiment={experiment}
                                open={archiveModalOpen}
                                setOpen={setArchiveModalOpen}
                            />
                        )}
                    </div>
                )}
                {tab === 'seurat' && (
                    <div className="">
                        <AssayFileList experiment={experiment} files={seuratFiles} title="Seurat object" hideCount />
                    </div>
                )}
            </div>
        );
    };

    if (viewDataOnly) {
        return renderData();
    }

    return (
        <div className="space-y-4">
            {showFormatPicker && (
                <div className="form-field no-margin">
                    <span className="field-label">Assay file format</span>
                    <FormControl variant="outlined">
                        <Select
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            margin="dense"
                            onChange={(e) => setTab(e.target.value as Tab)}
                            value={tab}
                            renderValue={(value) => {
                                switch (value as Tab) {
                                    case 'table':
                                        return <span className="whitespace-pre">{unitName}</span>;
                                    case 'fastq':
                                        return 'FASTQ files';
                                    case 'seurat':
                                        return 'Seurat object';
                                }
                            }}
                        >
                            <MenuItem value={'table'}>
                                <div className="flex flex-row items-center justify-start">{unitName}</div>
                            </MenuItem>
                            {experiment.fastqs_uploaded && (
                                <MenuItem value={'fastq'}>
                                    <div className="flex flex-row items-center justify-start">FASTQ files</div>
                                </MenuItem>
                            )}
                            {(experiment.seurats_uploaded || showSeurat) && (
                                <MenuItem value={'seurat'}>
                                    <div className="flex flex-row items-center justify-start">Seurat object</div>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            )}
            {renderData()}
        </div>
    );
};

export default ExperimentDetailAssayDataTabView;
