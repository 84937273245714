import Plot from '@models/Plot';
import React from 'react';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import ProseList from '@components/ProseList';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const SurvivalAnalysisMethodsSection = ({ plot, analysisParameters }: Props) => {
    const { getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as SurvivalAnalysis;

    const groupNames = analysis?.groups.map((g) => g.id) ?? [];
    return (
        <section>
            <MethodsSectionTitle>Survival analysis</MethodsSectionTitle>
            <div className="space-y-4">
                <p className="mb-4">
                    Survival analysis was performed using the <span className="font-mono italic">Surv()</span> function
                    in the <span className="font-mono italic">survival</span> R package<sup>1,2</sup> using{' '}
                    <span className="font-semibold">
                        {getVariableById(analysis.time_to_event_variable_id)?.display_name ?? '(unknown)'}
                    </span>{' '}
                    and{' '}
                    <span className="font-semibold">
                        {getVariableById(analysis.status_variable_id)?.display_name ?? '(unknown)'}
                    </span>{' '}
                    to compare differences across the groups: <ProseList items={groupNames} />.
                </p>
            </div>
        </section>
    );
};

export default SurvivalAnalysisMethodsSection;
