import { IconProps } from '@components/icons/CustomIcons';

export const CellLineIcon = (props?: IconProps) => {
    const { width = 21, height = 18 } = { ...props };
    return (
        <svg width={width} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="10.5" cy="6.5" rx="9.5" ry="5.5" stroke="currentColor" strokeWidth="2" />
            <path
                d="M2 8.696C3.578 6.499 6.746 5 10.397 5 14.214 5 17.504 6.64 19 9"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle cx="12.227" cy="7.909" r=".864" fill="currentColor" />
            <circle cx="8.772" cy="8.773" r=".864" fill="currentColor" />
            <path
                d="M10.5 18c5.799 0 10.5-2.686 10.5-6V6l-2.21 3.842-8.29 2.316-7.184-1.737L0 6v6c0 3.314 4.701 6 10.5 6z"
                fill="currentColor"
            />
        </svg>
    );
};
