import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React from 'react';
import AssaySummaryLegendFieldGroup from '@components/experiments/plotDisplay/groups/AssaySummaryLegendFieldGroup';
import SampleScatterPlotAxisSelectFieldGroup from '@components/experiments/plotDisplay/groups/SampleScatterPlotAxisSelectFieldGroup';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import { AnalysisParameters, DifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { useFormikContext } from 'formik';
import { DisplayFormValue } from '@models/PlotDisplayOption';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import { PrincipalComponentsAnalysis } from '@models/analysis/PrincipalComponentsAnalysis';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import { isDisplayOptionFieldEnabled } from '@components/plots/util/PipelineVersionUtil';
import SummaryMeasuresDialogButton from '@components/experiments/analyses/summary/pca/SummaryMeasuresDialogButton';
import { UMAPAnalysis } from '@models/analysis/UMAPAnalysis';
import { TSNEAnalysis } from '@models/analysis/TSNEAnalysis';

const getColumnChoices = (analysis: PrincipalComponentsAnalysis | UMAPAnalysis | TSNEAnalysis | null) => {
    switch (analysis?.analysis_type) {
        case 'principal_components':
            return (analysis as PrincipalComponentsAnalysis).principal_components_options;
        case 'umap':
            return (analysis as UMAPAnalysis).umap_options;
        case 'tsne':
            return (analysis as TSNEAnalysis).tsne_options;
        default:
            return null;
    }
};

const getLabelName = (analysis: PrincipalComponentsAnalysis | UMAPAnalysis | null) => {
    switch (analysis?.analysis_type) {
        case 'principal_components':
            return 'principal component';
        case 'umap':
        case 'tsne':
            return 'projection';
        default:
            return null;
    }
};

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const SampleScatterPlotDisplayFields = ({ plot, experiment, analysisParameters }: Props) => {
    // TODO: make a utility method to figure out the columns and loading status for any type of analysis
    const analysis = plot.analysis as PrincipalComponentsAnalysis | UMAPAnalysis | null;
    const pipelineStatus = analysis?.pipeline_status;
    const columnsLoading = pipelineStatus === 'in_progress';
    const columnChoices = getColumnChoices(analysis);

    const variables = (analysisParameters as DifferentialExpressionAnalysisParameters | null)?.variables;
    const { values, handleChange } = useFormikContext<DisplayFormValue<SampleScatterPlotDisplayOption>>();

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={analysis?.name} />
            <section>
                <h4 className="tet-dark mb-2 text-lg font-semibold tracking-tight">Axes</h4>
                {analysis?.analysis_type === 'principal_components' &&
                    isDisplayOptionFieldEnabled({
                        version: analysis?.pipeline_version,
                        field: 'show_proportion_of_variance',
                    }) && (
                        <div className="mb-4">
                            <label className="block pt-1">
                                <input
                                    type="checkbox"
                                    className="rounded text-indigo-500"
                                    name="show_proportion_of_variance"
                                    checked={values.show_proportion_of_variance ?? false}
                                    onChange={handleChange}
                                />
                                <span className="ml-2">Show proportion of variance</span>
                            </label>

                            {analysis && (
                                <SummaryMeasuresDialogButton
                                    className="ml-5"
                                    experiment={experiment}
                                    analysis={analysis}
                                />
                            )}
                        </div>
                    )}

                {analysis?.analysis_type === 'umap' && (
                    <div className="mb-4">
                        <SummaryMeasuresDialogButton className="-ml-2" experiment={experiment} analysis={analysis} />
                    </div>
                )}

                <SampleScatterPlotAxisSelectFieldGroup
                    columnChoices={columnChoices}
                    loading={columnsLoading}
                    labelName={getLabelName(analysis)}
                />
            </section>
            <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Points</h4>
            <AnalysisVariablesMultiSelectField
                variables={variables ?? []}
                variablesName="color_points_by"
                groupsName="group_display_order"
            />

            <ThemeFieldGroup hideStyle />

            {/*Ensure we save the legend group order on the plot_display_option object instead of the analysis like we do on summary analysis */}
            <AssaySummaryLegendFieldGroup
                plot={plot}
                experiment={experiment}
                orderedGroupsFieldName="group_display_order"
                variables={values.color_points_by}
                ignoreEmpty
                description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
                isSortable={true}
            />
        </>
    );
};

export default SampleScatterPlotDisplayFields;
