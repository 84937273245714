import { Dialog } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Experiment from '@models/Experiment';
import { TimeoutValue } from '@util/ObjectUtil';
import SyncWithBenchlingView from '@components/experiments/benchling/SyncWithBenchlingView';
import DialogCloseButton from '../../DialogCloseButton';

export type BenchlingPlotData = {
    plot_id: string;
    file_exported: boolean;
    dataset_exported: boolean;
};
export type BenchlingExportParams = {
    qc_report_exported: boolean;
    plots_exported: BenchlingPlotData[];
};

type SubmitResult = { success?: boolean; error?: null | ReactNode };
type Props = { open: boolean; onClose: () => void; experiment: Experiment };
const BenchlingExperimentMultiExportDialog = ({ open, onClose, experiment }: Props) => {
    const [benchlingExportData, setBenchlingExportData] = useState<BenchlingExportParams | null>(null);
    const [qcExported, setQcExported] = useState<boolean>(false);
    const [stepKey, setStepKey] = useState<string>('');
    const [submitResult, setSubmitResult] = useState<SubmitResult | null>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const successTimeoutRef = useRef<TimeoutValue | null>(null);

    const handleClose = () => {
        onClose();
        setStepKey('');
        setBenchlingExportData(null);
        setQcExported(false);
    };

    // Reset the form state when opening
    useEffect(() => {
        if (open) {
            setSubmitResult(null);
        }
        return () => {
            if (successTimeoutRef.current) {
                clearTimeout(successTimeoutRef.current);
            }
        };
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" data-cy={'benchling-multi-export-dialog'}>
            <DialogCloseButton onClose={handleClose} />
            <SyncWithBenchlingView
                benchlingExportData={benchlingExportData}
                experiment={experiment}
                handleClose={handleClose}
                qcExported={qcExported}
                setBenchlingExportData={setBenchlingExportData}
                setQcExported={setQcExported}
                setStepKey={setStepKey}
                setSubmitResult={setSubmitResult}
                setSubmitting={setSubmitting}
                stepKey={stepKey}
                submitResult={submitResult}
                submitting={submitting}
                successTimeoutRef={successTimeoutRef}
            />
        </Dialog>
    );
};

export default BenchlingExperimentMultiExportDialog;
