import { Popover, Transition } from '@headlessui/react';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import Button from '@components/Button';
import TextInputField from '../../forms/TextInputField';

type Props = PropsWithChildren<{
    onSave: ({ groupId, customName }: { groupId: string | number; customName: string | null | undefined }) => void;
    id: string | number;
    defaultName: string;
    currentName: string;
    title?: string;
}>;
const EditLegendPopover = ({ children, onSave, id, currentName, defaultName, title = 'Group display name' }: Props) => {
    const [name, setName] = useState<string>(currentName);

    return (
        <Popover className="relative z-50">
            {({ close }) => (
                <>
                    <Popover.Button className="text-medium flex items-center" as="span">
                        {children}
                    </Popover.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Popover.Panel className="absolute -left-56 bottom-6 z-10 rounded-xl border border-gray-200 bg-white p-4 shadow-lg">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <p className=" font-semibold tracking-tight text-gray-500">{title}</p>
                                    <TextInputField
                                        name="name"
                                        label=""
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>
                                <div className="flex space-x-2">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="small"
                                        onClick={() => {
                                            setName(defaultName);
                                            onSave({ groupId: id, customName: defaultName });
                                        }}
                                        disabled={name === defaultName}
                                    >
                                        <span className="text-error">Reset</span>
                                    </Button>
                                    <div className="flex justify-end space-x-4 ">
                                        <Button
                                            color="primary"
                                            variant="text"
                                            size="small"
                                            onClick={() => {
                                                setName(currentName);
                                                close();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                onSave({ groupId: id, customName: name });
                                                close();
                                            }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default EditLegendPopover;
