import BasePlotBuilder, {
    ConstructorParams as BaseParams,
    PlotMargin,
} from '@components/plots/builders/BasePlotBuilder';
import { ScorePlotData } from '@models/ExperimentData';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import { TranscriptionFactorEnrichmentAnalysis } from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import ScoreBarPlotDisplayOption from '@models/plotDisplayOption/ScoreBarPlotDisplayOption';
import {
    BAR_CORNER_RADIUS,
    getVolcanoPlotThemeColors,
    MAX_BAR_WIDTH,
    rotateXAxisLabels as drawRotatedXAxisLabels,
} from '@components/plots/PlotUtil';
import { isDefined } from '@util/TypeGuards';
import * as d3 from 'd3';
import { axisBottom, axisLeft, BaseType, scaleBand, ScaleBand, ScaleLinear, Selection } from 'd3';
import {
    AXIS_LABEL_CLASSNAMES,
    AXIS_LABEL_PUBLICATION_CLASSNAMES,
    AXIS_TITLE_CLASSNAMES,
    AXIS_TITLE_PUBLICATION_CLASSNAMES,
    ThemeColor,
} from '@models/PlotConfigs';
import ScorePlotDatum from '@models/GeneSet';
import { roundToDecimal } from '@util/StringUtil';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const getTooltipContents = (
    d: ScorePlotDatum,
    xAxisColumn = 'Gene_Set_Display_Name',
    yAxisColumn = 'NES',
    pValueColumn = 'Adj_P_Value',
): string => {
    return `
<p class="font-semibold text-md text-dark">${d[xAxisColumn]}</p>
<p class="text-sm">NES: ${roundToDecimal(<number>d[yAxisColumn], { decimals: 5 })}</p>
<p class="text-sm">Adj. <i>p</i>-value: ${roundToDecimal(<number>d[pValueColumn], { decimals: 5 })}</p>
`;
};

export type ConstructorParams = BaseParams<ScorePlotData> & {
    stylingOptions: CustomPlotStylingOptions | null;
    xAxisTruncated: boolean;
};
export type ScorePlotAnalysisType = GeneSetEnrichmentAnalysis & TranscriptionFactorEnrichmentAnalysis;
const MAX_X_HEIGHT = 123;
export default class ScoreBarPlotBuilder extends BasePlotBuilder<ScorePlotData> {
    stylingOptions: CustomPlotStylingOptions | null;
    maxXAxisLabelHeightPx = MAX_X_HEIGHT;
    sortedGeneSets: string[] = [];
    items: ScorePlotDatum[];
    scales!: { yScale: ScaleLinear<number, number>; xScale: ScaleBand<string> };
    isExportMode: boolean;
    xAxisTruncated: boolean;
    plotMetadata = this.plot.analysis ? this.plot.analysis['plot_metadata'] : {};

    constructor(params: ConstructorParams) {
        super(params);
        this.stylingOptions = params.stylingOptions;
        this.xAxisTruncated = params.xAxisTruncated;
    }

    makeScales = (): { yScale: ScaleLinear<number, number>; xScale: ScaleBand<string> } => {
        const { yMin, yMax } = this.yDomain;
        const margin = this.margin;
        const height = this.height;
        const width = this.width;
        const yScale = d3
            .scaleLinear()
            .domain([yMin * 1.1, yMax * 1.1])
            .rangeRound([height - margin.bottom, margin.top]);

        const xScale = scaleBand()
            .domain(this.sortedGeneSets)
            .range([margin.left, width - margin.right])
            .padding(0.2);

        return { yScale, xScale };
    };

    get yDomain(): { yMin: number; yMax: number } {
        const yAxisColumn = this.plotMetadata.y_axis_column || 'NES';
        const values = this.items.map((item) => <number>item[yAxisColumn]).filter(isDefined);
        const yMin = Math.min(...values, 0);
        const yMax = Math.max(...values, 0);
        return { yMax, yMin };
    }

    get analysis(): ScorePlotAnalysisType {
        return this.plot.analysis as ScorePlotAnalysisType;
    }

    get display(): ScoreBarPlotDisplayOption {
        return this.plot.display as ScoreBarPlotDisplayOption;
    }

    get yAxisTitle() {
        if (this.plot.analysis_type === 'transcription_factor_enrichment') {
            return 'Score';
        }
        return 'Normalized enrichment score';
    }

    get yAxisFormat() {
        const { yMax } = this.yDomain;
        return yMax > 10000 ? '.1e' : ',f';
    }

    get colors(): { positive: string; negative: string } {
        const customColors = this.display.custom_color_json ?? {};
        const { positive, negative } = getVolcanoPlotThemeColors(this.themeColor ?? ThemeColor.cool);

        return {
            positive: customColors['positively'] ?? positive.color,
            negative: customColors['negatively'] ?? negative.color,
        };
    }

    calculateMargins(): PlotMargin {
        return { top: 20, left: 0, right: 0, bottom: MAX_X_HEIGHT };
    }

    appendYAxis = () => {
        const styles = this.stylingOptions?.yaxis;
        const { yScale } = this.scales;
        // const { yMax } = this.yDomain;
        const height = this.height;
        const margin = this.margin;
        const yAxisTitle = this.yAxisTitle;
        const yAxisFormat = this.yAxisFormat;
        const publicationMdoe = this.publicationMode;
        this.svg.selectAll('.y-axis').remove();
        const drawYAxis = (g) => {
            const yAxisConfig = axisLeft(yScale).ticks(null, yAxisFormat).tickSizeOuter(0);

            return g
                .call((g) => g.select('.domain').remove())
                .attr('transform', `translate(${margin.left},0)`)
                .attr('class', `y-axis ${publicationMdoe ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES}`)
                .call(yAxisConfig)
                .call((g) =>
                    g
                        .append('text')
                        .attr('x', -height / 2)
                        .attr('y', -margin.left + 20)
                        .attr('fill', styles ? styles.fontColor : 'currentColor')
                        .style('font-size', styles ? styles.fontSize : '18')
                        .style('font-family', styles ? styles.fontFamily : 'Arial')
                        .attr('text-anchor', 'middle')
                        .attr('transform', 'rotate(-90)')
                        .attr('class', publicationMdoe ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES)
                        .text(`${yAxisTitle ?? ''}`),
                );
        };

        this.svg.append('g').call(drawYAxis);
    };

    drawYZeroLine = () => {
        const { yScale, xScale } = this.scales;
        const [xMin, xMax] = xScale.range();
        this.svg
            .append('g')
            .append('line')
            .attr('stroke', '#333')
            .attr('stroke-width', 1)
            .attr('x1', xMin)
            .attr('x2', xMax)
            .attr('y1', yScale(0) + 0.5)
            .attr('y2', yScale(0) + 0.5);
    };

    appendXAxis = () => {
        const { xScale } = this.scales;
        const height = this.height;
        const margin = this.margin;
        const publicationMode = this.publicationMode;
        const xAxisTruncated = this.xAxisTruncated;
        const labelRotation = publicationMode ? 45 : 90;
        this.svg.selectAll('.x-axis').remove();
        const drawXAxis = (g: Selection<SVGGElement, unknown, BaseType, unknown>) => {
            g.attr('transform', `translate(0,${height - margin.bottom})`)
                .attr('class', `x-axis ${publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES}`)
                .call(
                    axisBottom(xScale)
                        .tickSize(12)
                        .tickSizeOuter(0)
                        .tickFormat((value) => {
                            const maxLength = 24;
                            let label = value;

                            label = label.replace(/^HALLMARK /, '');

                            if (label.length > maxLength && (!!xAxisTruncated || !this.isExportMode)) {
                                return `${label.substring(0, maxLength - 3)}...`;
                            }
                            return label;
                        }),
                );

            if (labelRotation) {
                drawRotatedXAxisLabels(g, labelRotation);
            }
            return g;
        };

        this.svg.append('g').call(drawXAxis);
    };

    getBarWidth = () => {
        return Math.min(this.scales.xScale.bandwidth(), MAX_BAR_WIDTH);
    };

    getBarX = (item: ScorePlotDatum) => {
        const { xScale } = this.scales;
        const xAxisColumn = this.plotMetadata.x_axis_column || 'Gene_Set_Display_Name';
        const xAxis = <string>item[xAxisColumn];
        return (xScale(xAxis) ?? 0) + (xScale.bandwidth() - this.getBarWidth()) / 2;
    };

    appendBars = () => {
        const { yScale } = this.scales;
        const items = this.items;
        const colors = this.colors;
        const tooltipContainer = this.tooltip;

        const xAxisColumn = this.plotMetadata.x_axis_column || 'Gene_Set_Display_Name';
        const yAxisColumn = this.plotMetadata.y_axis_column || 'NES';
        const pValueColumn = this.plotMetadata.p_value_column || 'Adj_P_Value';

        // Draw Bars
        this.svg
            .append('g')
            .attr('class', 'bar-container')
            .selectAll('rect')
            .data(items)
            .enter()
            .append('rect')
            .attr('fill', (d) => ((<number>d[yAxisColumn] ?? 0) >= 0 ? colors.positive : colors.negative))
            .attr('x', this.getBarX)
            .attr('width', this.getBarWidth)
            .attr('y', (d) => Math.min(yScale(0), yScale(<number>d[yAxisColumn] ?? 0)))
            .attr('height', (d) => Math.abs(yScale(0) - yScale(<number>d[yAxisColumn] ?? 0)))
            .attr('rx', BAR_CORNER_RADIUS)
            .on('mousemove', (event, d) => {
                const bar = d3.select(event.target);
                const pageWidth = window.innerWidth;
                const isRightSide = event.pageX > pageWidth / 2 + pageWidth * 0.1;
                bar.style('opacity', 0.6);
                tooltipContainer.transition().duration(50).style('opacity', 1);
                tooltipContainer
                    .html(getTooltipContents(d, xAxisColumn, yAxisColumn, pValueColumn))
                    .style('top', `${event.pageY - 10}px`);
                if (isRightSide) {
                    tooltipContainer.style('right', `${pageWidth - event.pageX + 10}px`);
                    tooltipContainer.style('left', 'unset');
                } else {
                    tooltipContainer.style('left', `${event.pageX + 10}px`);
                    tooltipContainer.style('right', 'unset');
                }
            })
            .on('mouseout', (event) => {
                tooltipContainer.transition().delay(25).duration(100).style('opacity', 0);
                const bar = d3.select(event.target);
                bar.style('opacity', 1);
            });
    };

    draw() {
        const xAxisColumn = this.plotMetadata.x_axis_column || 'Gene_Set_Display_Name';
        const yAxisColumn = this.plotMetadata.y_axis_column || 'NES';
        const pValueColumn = this.plotMetadata.p_value_column || 'Adj_P_Value';
        const hasPValue =
            this.plot.analysis &&
            this.plot.analysis['tf_target_collection'] &&
            this.plot.analysis['tf_target_collection']['has_p_value'];

        {
            hasPValue
                ? (this.items = [...this.data.items]
                      .filter(
                          (item) =>
                              isDefined(item[pValueColumn]) &&
                              <number>item[pValueColumn] <= (this.display.adj_p_value_filter ?? 0.001),
                      )
                      .filter((item) => isDefined(item[yAxisColumn]))
                      .sort((i1, i2) => (<number>i2[yAxisColumn] ?? 0) - <number>(i1[yAxisColumn] ?? 0)))
                : (this.items = [...this.data.items]
                      .filter((item) => isDefined(item[yAxisColumn]))
                      .sort((i1, i2) => (<number>i2[yAxisColumn] ?? 0) - (<number>i1[yAxisColumn] ?? 0)));
        }
        this.sortedGeneSets = this.items.map((item) => <string>item[xAxisColumn]);
        this.scales = this.makeScales();

        this.margin.bottom = this.maxXAxisLabelHeightPx;
        this.appendYAxis();
        this.appendXAxis();

        const yAxisWidth = this.svg.select<SVGGElement>('.y-axis')?.node()?.getBoundingClientRect().width ?? 0;
        const xAxisHeight = this.svg.select<SVGGElement>('.x-axis')?.node()?.getBoundingClientRect().height ?? 0;

        this.margin.left = yAxisWidth + 10;
        this.margin.bottom = xAxisHeight + 20;

        this.scales = this.makeScales();

        this.appendYAxis();
        this.appendXAxis();

        this.drawYZeroLine();

        this.appendBars();
        return;
    }
}
