import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import TextInputField from '@components/forms/TextInputField';
import TextAreaField from '@components/forms/TextAreaField';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import PrismGraphPickerField from '@components/experiments/plotDisplay/fields/PrismGraphPickerField';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import { prismFriendlyFilename } from '@components/plots/PlotUtil';

type Props = { plot: Plot; experiment: Experiment };

const PrismGraphsetDisplayFields = ({ plot, experiment }: Props) => {
    const placeholderTitle = prismFriendlyFilename((plot?.analysis as PrismAnalysis)?.prism_file.filename);
    return (
        <>
            <FullWidthToggleField />
            <TextInputField name="plot_title" label="Title" placeholder={placeholderTitle ?? 'Prism file'} />
            <TextAreaField name="caption" label="Caption" minRows={2} />
            <PrismGraphPickerField
                name="selected_graph"
                label="Graph to show"
                plot={plot}
                experiment={experiment}
                tooltip={{
                    title: 'Graphs you have created in Prism will appear in this list. \nTap to learn more.',
                    href: 'https://help.pluto.bio/en/articles/graphpad-prism',
                }}
            />
        </>
    );
};

export default PrismGraphsetDisplayFields;
