import PipelineRun from '@models/PipelineRun';
import { DateFormat, formatDate } from '@util/DateUtil';
import cn from 'classnames';
import { CheckIcon, DotsHorizontalIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { PipelineStatus } from '@models/ExperimentData';
import { ChevronRight } from '@mui/icons-material';
import { useState } from 'react';
import { Collapse } from '@mui/material';
import { blankToUndefined } from '@util/StringUtil';

const thClasses = 'bg-secondary px-2 py-1.5 text-left font-semibold text-white last:-mr-1 last:rounded-tr-xl';
const allowedParams = {
    scrnaseq: ['genome'],
};

const knownStatuses: PipelineStatus[] = ['in_progress', 'failed', 'completed'];
type Props = { pipelineRun: PipelineRun; active?: boolean; noRing?: boolean };
const PipelineRunItem = ({ pipelineRun, active, noRing }: Props) => {
    const [open, setOpen] = useState(false);
    const status = pipelineRun.pipeline_status.status;
    const pipelineType = pipelineRun.pipeline_version.pipeline.pipeline_type;
    let paramKeys = Object.keys(pipelineRun.input_parameters ?? {});
    const hasParams = paramKeys.length > 0;

    if (allowedParams[pipelineType]) {
        paramKeys = paramKeys.filter((key) => allowedParams[pipelineType].includes(key));
    }

    return (
        <div
            className={cn('rounded-lg border border-indigo-200 bg-white ', {
                'border-[3px] border-indigo-400': active && !noRing,
            })}
        >
            <div
                className={cn(
                    'flex cursor-pointer items-center justify-between space-x-4 rounded-t-lg px-4 py-2 hover:bg-indigo-50/50',
                )}
                onClick={() => {
                    setOpen((current) => !current);
                }}
            >
                <div>
                    <p>
                        <span className="font-semibold text-dark">{pipelineRun.pipeline_status.workflow_id}</span>
                        {' - '}
                        <span className="">{pipelineType}</span> <span>(v{pipelineRun.pipeline_version.version})</span>
                    </p>

                    <span className="text-xs">
                        Started at {formatDate(new Date(pipelineRun.created_at), DateFormat.DATE_TIME)}
                    </span>
                </div>
                <div className="flex items-center space-x-2">
                    {active && (
                        <span className="flex items-center space-x-1 rounded-lg bg-amber-100 px-2 py-0.5 text-xs text-amber-700">
                            Active
                        </span>
                    )}
                    <span
                        className={cn('flex items-center space-x-1 rounded-lg px-2 py-0.5 text-xs capitalize', {
                            'bg-success text-success': status === 'completed',
                            'bg-indigo-50 text-indigo-500': status === 'in_progress',
                            'bg-error text-error': status === 'failed',
                            'bg-gray-100 text-dark': !knownStatuses.includes(status),
                        })}
                    >
                        {status === 'in_progress' && (
                            <DotsHorizontalIcon className="-mx-0/5 text-indigo-500" width={12} />
                        )}
                        {status === 'completed' && <CheckIcon className="-mx-0.5 text-success" width={12} />}
                        {status === 'failed' && <XCircleIcon className="-mx-0.5 text-error" width={12} />}
                        <span className="">{pipelineRun.pipeline_status.status?.replace(/_/g, ' ')}</span>
                    </span>
                    <span
                        className={cn('transition duration-200 transform ', {
                            'rotate-90 ': open,
                        })}
                    >
                        <ChevronRight key="chevron" width={24} />
                    </span>
                </div>
            </div>
            <Collapse in={open}>
                <div className="border-t border-gray-200">
                    <div className="px-4 pb-4 pt-2">
                        <p className="mb-2 text-sm font-semibold text-default">Pipeline parameters</p>

                        {hasParams ? (
                            <table className="rounded-corners-md table-auto border-separate text-xs">
                                <thead>
                                    <tr>
                                        <th className={thClasses}>Name</th>
                                        <th className={thClasses}>Value</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {paramKeys.map((paramName) => {
                                        const param = pipelineRun.input_parameters?.[paramName];
                                        return (
                                            <tr key={paramName} className="">
                                                <td
                                                    className={cn(
                                                        'border-b border-l-0 border-r border-gray-200 bg-white px-2 py-1 font-semibold first:border-l',
                                                    )}
                                                >
                                                    {paramName}
                                                </td>
                                                <td className="border-b border-l-0 border-r border-gray-200 bg-white px-2 py-1 font-mono first:border-l">{`${
                                                    blankToUndefined(
                                                        Array.isArray(param) ? param.join(', ') : (param as string),
                                                    ) ?? '—'
                                                }`}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="flex space-x-2 rounded-lg bg-indigo-100 p-4">
                                <div className="mt-1 flex-shrink-0 text-indigo-500">
                                    <InformationCircleIcon width={18} />
                                </div>
                                <div>
                                    Pipeline parameters are not available for this run of the pipeline.{' '}
                                    <a
                                        href="https://help.pluto.bio"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        Contact us
                                    </a>{' '}
                                    to get the latest parameters.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default PipelineRunItem;
