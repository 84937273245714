import { ChevronRightIcon } from '@heroicons/react/solid';
import ColorPicker from '@components/plots/ColorPicker';
import cn from 'classnames';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Button from '@components/Button';
import { TrashIcon } from '@heroicons/react/outline';
import { ThemeColor } from '@models/PlotConfigs';
import DialogCloseButton from '../../DialogCloseButton';
import { Dialog, DialogContent } from '@mui/material';
import PlutoDialogTitle from '../../PlutoDialogTitle';
import { StateSetter } from '@/src/contexts/ContextTypes';

type Props = PropsWithChildren<{
    title?: ReactNode;
    onChange?: (newColor: string) => void;
    color?: string;
    showRemove?: boolean;
    onRemove?: () => void;
    customColors?: string[];
    themeColor?: ThemeColor;
    saveText?: ReactNode;
    cancelText?: ReactNode;
    removeText?: ReactNode;
    hideThemes?: boolean;
    offsetLeft?: boolean;
    labelName?: string;
    leftOffsetClassName?: string;
    bottomOffsetClassname?: string;
    customColorsLabel?: string;
    secondaryCustomColors?: string[];
    secondaryCustomColorsLabel?: string;
}>;
type ModalProps = Props & {
    onClose: () => void;
    open: boolean;
    setColor: StateSetter<string>;
};
const Modal = ({
    cancelText = 'Cancel',
    color,
    customColors,
    customColorsLabel,
    hideThemes = false,
    onChange,
    onClose,
    onRemove,
    open,
    removeText,
    saveText,
    secondaryCustomColors,
    secondaryCustomColorsLabel,
    setColor,
    showRemove = false,
    themeColor,
    title,
}: ModalProps) => {
    const typedColor = color as string;
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogCloseButton onClose={() => onClose()} />

            <PlutoDialogTitle title={title} titleClassName="!break-normal" />

            <DialogContent>
                <div className="space-y-4">
                    <div className="space-y-2">
                        <ColorPicker
                            color={typedColor}
                            setColor={setColor}
                            customColors={customColors}
                            hideThemes={hideThemes}
                            themeColor={themeColor}
                            customColorsLabel={customColorsLabel}
                            secondaryCustomColors={secondaryCustomColors}
                            secondaryCustomColorsLabel={secondaryCustomColorsLabel}
                        />
                    </div>
                    <div
                        className={cn('flex space-x-2', {
                            'justify-between': showRemove,
                            'justify-end': !showRemove,
                        })}
                    >
                        {showRemove && (
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => {
                                    onRemove?.();
                                    onClose();
                                }}
                                startIcon={<TrashIcon width={14} className="text-error" />}
                            >
                                <span className="text-error">{removeText}</span>
                            </Button>
                        )}
                        <div className="flex justify-end space-x-4 ">
                            <Button color="primary" variant="text" size="small" onClick={() => onClose()}>
                                {cancelText}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    onChange?.(typedColor);
                                    onClose();
                                }}
                            >
                                {saveText}
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const GroupColorPickerModal = ({
    children,
    title,
    onChange,
    color: initialColor,
    showRemove = false,
    onRemove,
    customColors,
    themeColor,
    saveText = 'Apply',
    cancelText = 'Cancel',
    removeText = 'Clear',
    hideThemes = false,
    offsetLeft = true,
    labelName = 'Group name',
    leftOffsetClassName,
    bottomOffsetClassname,
    customColorsLabel,
    secondaryCustomColors,
    secondaryCustomColorsLabel,
}: Props) => {
    const [color, setColor] = useState<string>(initialColor ?? '#ffffff');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (initialColor) {
            setColor(initialColor);
        }
    }, [initialColor]);

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button className="text-medium m-0 flex items-center p-0" onClick={() => setOpen(true)}>
                {children ?? (
                    <>
                        <span>Choose Color</span>{' '}
                        <ChevronRightIcon
                            className={cn('transition', { 'rotate-90 transform': open })}
                            width={18}
                            aria-hidden="true"
                        />
                    </>
                )}
            </Button>
            <Modal
                open={open}
                onClose={onClose}
                title={title}
                onChange={onChange}
                color={color}
                showRemove={showRemove}
                onRemove={onRemove}
                customColors={customColors}
                themeColor={themeColor}
                saveText={saveText}
                cancelText={cancelText}
                removeText={removeText}
                hideThemes={hideThemes}
                offsetLeft={offsetLeft}
                labelName={labelName}
                leftOffsetClassName={leftOffsetClassName}
                bottomOffsetClassname={bottomOffsetClassname}
                customColorsLabel={customColorsLabel}
                secondaryCustomColors={secondaryCustomColors}
                secondaryCustomColorsLabel={secondaryCustomColorsLabel}
                setColor={setColor}
            />
        </>
    );
};

export default GroupColorPickerModal;
