import Plot from '@models/Plot';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import React from 'react';
import { AnalysisParameters } from '@models/AnalysisParameters';
import HeatmapDisplayOption, { getSummaryTypeDisplayName } from '@models/plotDisplayOption/HeatmapDisplayOption';
import { usePlotContext } from '@contexts/PlotContext';
import { ComparativeAnalysisPlotData } from '@models/ExperimentData';
import ComparativeAnalysisMethodsSection from '@components/experiments/methods/analyses/ComparativeAnalysisMethodsSection';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const ComparativeHeatmapMethods = ({ plot, analysisParameters }: Props) => {
    const { plotData } = usePlotContext();
    const data = plotData as ComparativeAnalysisPlotData;
    const display = plot.display as HeatmapDisplayOption;
    const pValueThreshold = display.adj_p_value_threshold ?? 0.001;
    const numberOfGenes = (data?.items ?? []).filter((d) => d.Adj_P_Value <= pValueThreshold).length;
    const summaryName = getSummaryTypeDisplayName(display.summarize_values_by);
    return (
        <div className="space-y-6">
            <ComparativeAnalysisMethodsSection plot={plot} analysisParameters={analysisParameters} />
            <section>
                <MethodsSectionTitle>Heatmap</MethodsSectionTitle>
                <div>
                    {plot.analysis_type === 'differential_expression_continuous' ? (
                        <p>
                            Heatmap shows the <span className="font-semibold">{`${summaryName}`}</span> z-scored,
                            normalized intensity value for each gene. The false discovery rate (FDR) method was applied
                            for multiple testing correction<sup>2</sup> and used for filtering. Heatmap shows{' '}
                            <span className="font-semibold">{numberOfGenes}</span> genes with an adjusted{' '}
                            <span className="italic">p</span>-value ≤{' '}
                            <span className="font-semibold">{pValueThreshold}</span>.
                        </p>
                    ) : (
                        <p>
                            Heatmap shows the <span className="font-semibold">{`${summaryName}`}</span> z-scored, log
                            <sub>2</sub>-transformed, counts per million (CPM)-normalized value for each gene. The false
                            discovery rate (FDR) method was applied for multiple testing correction<sup>2</sup> and used
                            for filtering. Heatmap shows <span className="font-semibold">{numberOfGenes}</span> genes
                            with an adjusted <span className="italic">p</span>-value ≤{' '}
                            <span className="font-semibold">{pValueThreshold}</span>.
                        </p>
                    )}
                </div>
            </section>
        </div>
    );
};

export default ComparativeHeatmapMethods;
