import Button from '@components/Button';
import React from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@mui/material';

/**
 * Props for the AnalysisInputFormSubmitButton component.
 */
type Props = {
    /**
     * Specifies if the button should be disabled.
     */
    disabled?: boolean;
    /**
     * Specifies if the button should take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * Callback function to be called before the form is submitted.
     */
    beforeFormSubmit?: () => void;
    /**
     * Callback function to be called when the button is clicked in place of the form submitting.
     */
    onSubmit?: () => void;
    /**
     * The size of the button.
     */
    size?: 'small' | 'medium' | 'large' | undefined;
    /**
     * The text to be displayed on the button.
     */
    text?: string;
    /**
     * The variant of the button.
     */
    variant?: 'text' | 'contained' | 'outlined' | undefined;
    /**
     * The text to be displayed when the button is submitting.
     */
    submittingText?: string;
    className?: string;
};

const AnalysisInputFormSubmitButton = ({
    disabled = false,
    fullWidth = false,
    beforeFormSubmit,
    onSubmit,
    size = 'medium',
    submittingText = 'Saving...',
    text = 'Save',
    variant = 'contained',
    className,
}: Props) => {
    const { submitForm, isSubmitting, setStatus } = useFormikContext();
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
        } else {
            beforeFormSubmit?.();
            setStatus(null);
            submitForm();
        }
    };
    const submitting = isSubmitting || disabled;

    return (
        <Button
            color="primary"
            disabled={submitting}
            fullWidth={fullWidth}
            onClick={handleSubmit}
            type="submit"
            variant={variant}
            size={size}
            className={className}
        >
            {submitting ? (
                <>
                    {submittingText} <CircularProgress color="inherit" className="ml-2 text-slate-500" size={14} />
                </>
            ) : (
                text
            )}
        </Button>
    );
};

export default AnalysisInputFormSubmitButton;
