import { getFullName, SharingMember } from '@models/User';
import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@components/Button';
import { AvatarCircle } from '@components/AvatarCircle';
import { ListItem } from '@mui/material';
import LoadingMessage from '@components/LoadingMessage';
import { UserPlusIcon } from '@components/icons/custom/UserPlusIcon';
import { ChevronDownIcon } from '@heroicons/react/solid';
import SharingIcon from './icons/custom/SharingIcon';

type Props = {
    users: SharingMember[];
    onManage?: MouseEventHandler;
    loading?: boolean;
    error?: string;
    totalMembers?: number;
};

const SharingDropdownButtonV2 = ({ users, loading = false, onManage, error }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleManageClicked = async (event: MouseEvent<HTMLElement>) => {
        onManage?.(event);
        handleClose();
    };

    return (
        <>
            <div
                className="flex h-8 cursor-pointer items-center text-white/70 hover:text-white"
                onClick={handleOpenMenu}
            >
                <SharingIcon height={20} width={20} className="text-inherit" />
                <p className="ml-2 hidden text-xs tracking-tight xl:block">{users.length} users</p>
                <ChevronDownIcon height={16} width={16} className="ml-1 text-white/70" />
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    '& .MuiMenu-list': {
                        paddingTop: 0,
                    },
                    '& .MuiMenu-paper': {
                        backgroundColor: '#333333',
                        border: 'none',
                        maxHeight: 360,
                        width: '250px',
                        overflow: 'auto',
                    },
                }}
            >
                <div className="p-4">
                    <Button
                        onClick={handleManageClicked}
                        variant="outlined"
                        color="white"
                        fullWidth
                        className="shrink-0 opacity-70"
                        size="small"
                    >
                        <UserPlusIcon width={16} className="mr-2" />
                        Manage&nbsp;Sharing
                    </Button>
                </div>
                {users.map((user) => (
                    <ListItem key={`user_${user.uuid}`}>
                        <AvatarCircle
                            imageUrl={user.avatar_url}
                            size={'xs'}
                            classes="mr-2"
                            userId={user.uuid}
                            title={getFullName(user)}
                        />

                        <div className="flex flex-col">
                            <span className="font-semibold text-white/70">{getFullName(user)}</span>
                            <span className="text-sm text-white/70">{user.role_displayname}</span>
                        </div>
                    </ListItem>
                ))}
                {loading && (
                    <ListItem>
                        <LoadingMessage />
                    </ListItem>
                )}
                {error && (
                    <ListItem>
                        <span className="text-error">{error}</span>
                    </ListItem>
                )}
            </Menu>
        </>
    );
};
export default SharingDropdownButtonV2;
