import Plot from '@models/Plot';
import {
    AnyExperimentData,
    DEGData,
    ExperimentData,
    GeneSetData,
    IGVData,
    PCAData,
    PCASample,
    PipelineStatus,
    SurvivalData,
    SurvivalSample,
} from '@models/ExperimentData';
import { LegendItem } from '@components/plots/PlotLegendView';
import { getTargetSymbol, SummaryAnalysisSample } from '@components/plots/PlotTypes';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import {
    CustomizablePlotChartDisplayOption,
    ThemeablePlotChartDisplayOption,
} from '@models/plotDisplayOption/BasePlotDisplayOption';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { ThemeStyle } from '@models/PlotConfigs';
import { getVolcanoPlotThemeColors, processSummaryPlotData } from '@components/plots/PlotUtil';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import { useMemo } from 'react';
import { makeIGVColorUtil } from '@models/igv/IGVUtil';
import HeatmapDisplayOption from '@models/plotDisplayOption/HeatmapDisplayOption';
import KaplanMeierCurveDisplayOption from '@models/plotDisplayOption/KaplanMeierCurveDisplayOption';
import IGVPlotDisplayOption from '@models/plotDisplayOption/IGVPlotDisplayOption';
import { LongitudinalAnalysis } from '@models/analysis/LongitudinalAnalysis';
import Experiment from '@models/Experiment';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import { capitalizeWordsAndReplaceUnderscores } from '../util/StringUtil';
import StackedBarPlotDisplayOption from '../models/plotDisplayOption/StackedBarPlotDisplayOption';
import {
    PlotDataHeaderAnalysis,
    functionalAnnotationAnalysisHeaderMapping,
} from '../models/analysis/PlotDataHeaderAnalysis';
import { NetworkEdgeOptions } from '../components/experiments/plotDisplay/forms/NetworkGraphDisplayFields';
import { OverlapAnalysis, OverlapLists } from '../models/analysis/OverlapAnalysis';
import { validateAnalysisInputsPlotDataReady } from '../components/experiments/analyses/inputs/AnalysisInputFormUtil';
import RidgePlotDisplayOption from '../models/plotDisplayOption/RidgePlotDisplayOption';

const failedStatuses: PipelineStatus[] = ['in_progress', 'failed'];
type Props = {
    plot: Plot | null | undefined;
    plotData: AnyExperimentData | undefined | null;
    experiment: Experiment;
    publicKey?: string | null;
};
const usePlotLegendItems = ({ plot, plotData, experiment, publicKey }: Props) => {
    const { getTargetById } = useAnalysisParameters({ plot, experiment, publicKey });
    return useMemo(() => {
        const legendItems: LegendItem[] = [];
        let horizontalOnly = false;
        let style: ThemeStyle | undefined = (plot?.display as ThemeablePlotChartDisplayOption)?.theme_style;
        if (!plot || !plotData) {
            return { legendItems, style };
        }

        const simplePlotData = processSummaryPlotData(plotData as ExperimentData<SummaryAnalysisSample> | undefined);

        // TODO: Refactor how the plot and data are built up so that it's not this messy switch statement.
        const display = plot.display;
        const analysis_group_display_order = (plot.analysis as AssaySummaryAnalysis)?.group_display_order ?? [];

        const theme_color = (display as ThemeablePlotChartDisplayOption)?.theme_color;
        const plotPalette = getPlotPalette(theme_color);
        const paletteColors = plotPalette.colors;
        const customColorConfig = display.custom_color_json ?? {};
        const customOptionsConfig = (display as CustomizablePlotChartDisplayOption).custom_options_json ?? {};
        const customLegendConfig = display.custom_legend_json ?? {};

        const customNameConfig = display.custom_legend_json ?? {};
        const sortedSummaryAnalysisGroups = [...(simplePlotData?.target_groups[0]?.groups ?? [])].sort((g1, g2) => {
            return (
                analysis_group_display_order.indexOf(g1.group_id) - analysis_group_display_order.indexOf(g2.group_id)
            );
        });

        switch (display.display_type) {
            case 'image':
                if (!plot.analysis) {
                    break;
                }
                break;
            case 'bar_plot':
                if (!simplePlotData) {
                    break;
                }

                sortedSummaryAnalysisGroups.forEach((group, i) => {
                    legendItems.push({
                        label: group.group_name,
                        color: customColorConfig[group.group_id],
                        displayName: customNameConfig[group.group_id],
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });
                break;
            case 'box_plot':
                if (!simplePlotData) {
                    break;
                }
                sortedSummaryAnalysisGroups.forEach((group, i) => {
                    legendItems.push({
                        label: group.group_name,
                        color: customColorConfig[group.group_id],
                        displayName: customNameConfig[group.group_id],
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });

                break;

            case 'line_plot':
                if (!analysis_group_display_order) {
                    break;
                }
                const longitudinalAnalysis = plot.analysis as LongitudinalAnalysis | null;
                const sortedLineGroups = [...(longitudinalAnalysis?.groups ?? [])].sort((g1, g2) => {
                    return analysis_group_display_order.indexOf(g1.id) - analysis_group_display_order.indexOf(g2.id);
                });
                sortedLineGroups.forEach((group, i) => {
                    legendItems.push({
                        label: group.display_name,
                        color: customColorConfig[group.id],
                        displayName: customNameConfig[group.id],
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });

                if (sortedLineGroups.length === 0) {
                    legendItems.push({
                        label: 'All samples',
                        color: customColorConfig['all'],
                        palette_color: paletteColors[0],
                    });
                }

                const targetDisplayOrder = longitudinalAnalysis?.target_display_order ?? [];
                const sortedTargets = [...(longitudinalAnalysis?.targets ?? [])].sort((t1, t2) => {
                    return targetDisplayOrder.indexOf(t1) - targetDisplayOrder.indexOf(t2);
                });

                sortedTargets.forEach((targetId) => {
                    legendItems.push({
                        symbol: getTargetSymbol({ sortedTargets, target_id: targetId }),
                        label: getTargetById(targetId)?.display_name ?? targetId,
                    });
                });

                break;
            case 'volcano_plot':
            case 'volcano_plot_v2':
                const volcanoData = plotData as DEGData;
                const volcanoWord = plot.analysis_type === 'differential_binding' ? 'peaks' : 'genes';
                if (!volcanoData) {
                    break;
                }
                if (failedStatuses.includes(volcanoData.pipeline_status)) {
                    break;
                }

                style = ThemeStyle.medium;
                const volcanoColors = getVolcanoPlotThemeColors(theme_color);

                legendItems.push(
                    {
                        label: customLegendConfig['significantDecreased'] ?? `Significantly decreased ${volcanoWord}`,
                        color: customOptionsConfig['significantDecreased']?.fill_color,
                        stroke_color:
                            customOptionsConfig['significantDecreased']?.line_color ??
                            volcanoColors.significantNegative.color,
                        palette_color: volcanoColors.significantNegative,
                    },
                    {
                        label: customLegendConfig['significantIncreased'] ?? `Significantly increased ${volcanoWord}`,
                        color: customOptionsConfig['significantIncreased']?.fill_color,
                        stroke_color:
                            customOptionsConfig['significantIncreased']?.line_color ??
                            volcanoColors.significantPositive.color,
                        palette_color: volcanoColors.significantPositive,
                    },
                    {
                        label: customLegendConfig['insignificantDecreased'] ?? `Decreased ${volcanoWord}`,
                        color: customOptionsConfig['insignificantDecreased']?.fill_color,
                        stroke_color:
                            customOptionsConfig['insignificantDecreased']?.line_color ??
                            volcanoColors.significantNegative.color,
                        palette_color: volcanoColors.insignificantNegative,
                    },
                    {
                        label: customLegendConfig['insignificantIncreased'] ?? `Increased ${volcanoWord}`,
                        color: customOptionsConfig['insignificantIncreased']?.fill_color,
                        stroke_color:
                            customOptionsConfig['insignificantIncreased']?.line_color ??
                            volcanoColors.significantPositive.color,
                        palette_color: volcanoColors.insignificantPositive,
                    },
                );
                break;
            case 'sample_scatter_plot':
                const pcaData = plotData as PCAData;
                if (!pcaData) {
                    break;
                }
                if (pcaData.pipeline_status === 'in_progress') {
                    break;
                }

                const scatter_group_order = (display as SampleScatterPlotDisplayOption)?.group_display_order;
                const pcaGroupsById: Record<number, Pick<PCASample, 'group_id' | 'group_name'>> = {};
                (pcaData?.items ?? []).forEach((item) => {
                    pcaGroupsById[Number(item.group_id)] = item;
                });

                const sortedScatterGroups = Object.values(pcaGroupsById).sort((g1, g2) => {
                    const sortedIndex1 = scatter_group_order?.indexOf(Number(g1.group_id)) ?? -1;
                    const sortedIndex2 = scatter_group_order?.indexOf(Number(g2.group_id)) ?? -1;
                    return sortedIndex1 - sortedIndex2;
                });

                sortedScatterGroups.forEach((group) => {
                    const sortedIndex = scatter_group_order?.indexOf(Number(group.group_id)) ?? -1;

                    if (sortedIndex >= 0) {
                        legendItems.push({
                            label: group.group_name,
                            color: customColorConfig[group.group_id],
                            displayName: customNameConfig[group.group_id],
                            palette_color: paletteColors[sortedIndex % paletteColors.length],
                        });
                    }
                });
                break;
            case 'igv_plot':
                const igvData = plotData as IGVData;

                const { sortedGroups, getGroupPaletteColor } = makeIGVColorUtil({
                    themeColor: theme_color,
                    data: igvData,
                    group_display_order: (display as IGVPlotDisplayOption).group_display_order ?? [],
                });
                sortedGroups.forEach((group) => {
                    const color =
                        display.custom_color_json?.[`${group.group_id}`] ?? getGroupPaletteColor(group.group_id).color;
                    const displayName = display.custom_legend_json?.[group.group_id];

                    legendItems.push({ label: group.group_name, color: color, displayName });
                });
                style = ThemeStyle.dark;
                horizontalOnly = true;
                break;
            case 'enrichment_plot':
                break;
            case 'heatmap':
                const heatmapOptions = plot.display as HeatmapDisplayOption;
                if (!heatmapOptions) break;

                const heatmapColors = plotPalette.colors;
                sortedSummaryAnalysisGroups.forEach((g, i) => {
                    legendItems.push({
                        label: g.group_name,
                        color: display.custom_color_json?.[g.group_id] ?? heatmapColors[i % heatmapColors.length].color,
                        displayName: customNameConfig[g.group_id],
                    });
                });

                break;
            case 'kaplan_meier_curve':
                const kaplanOptions = plot.display as KaplanMeierCurveDisplayOption;
                const survivalData = plotData as SurvivalData | null;
                if (!kaplanOptions || !survivalData) {
                    break;
                }

                const kaplan_meier_group_order = (display as KaplanMeierCurveDisplayOption)?.group_display_order;
                const survivalGroupsById: Record<
                    number,
                    Pick<SurvivalSample, 'sample_group_id' | 'sample_group_name'>
                > = {};
                (survivalData?.items ?? []).forEach((item) => {
                    survivalGroupsById[Number(item.sample_group_id)] = item;
                });
                const sortedSurvivalGroups = Object.values(survivalGroupsById).sort((g1, g2) => {
                    const sortedIndex1 = kaplan_meier_group_order?.indexOf(Number(g1.sample_group_id)) ?? -1;
                    const sortedIndex2 = kaplan_meier_group_order?.indexOf(Number(g2.sample_group_id)) ?? -1;
                    return sortedIndex1 - sortedIndex2;
                });

                const kaplanColors = plotPalette.colors;
                sortedSurvivalGroups.forEach((group, i) => {
                    legendItems.push({
                        label: group.sample_group_name,
                        color: customColorConfig[group.sample_group_id],
                        displayName: customNameConfig[group.sample_group_id],
                        palette_color: kaplanColors[i % kaplanColors.length],
                    });
                });

                break;
            case 'score_bar_plot':
                const scorePlotData = plotData as GeneSetData;
                if (
                    !scorePlotData ||
                    scorePlotData.pipeline_status === 'in_progress' ||
                    scorePlotData.items?.length === 0
                ) {
                    break;
                }

                if (failedStatuses.includes(scorePlotData.pipeline_status)) {
                    break;
                }

                style = ThemeStyle.dark;
                const scorePlotColors = getVolcanoPlotThemeColors(theme_color);
                const customScoreColors = display.custom_color_json ?? {};

                if (plot.analysis_type === 'transcription_factor_enrichment') {
                    legendItems.push({
                        label: 'Enriched',
                        color: customScoreColors['positively'],
                        palette_color: scorePlotColors.positive,
                    });
                } else {
                    legendItems.push(
                        {
                            label: 'Positively enriched',
                            color: customScoreColors['positively'],
                            palette_color: scorePlotColors.positive,
                        },
                        {
                            label: 'Negatively enriched',
                            color: customScoreColors['negatively'],
                            palette_color: scorePlotColors.negative,
                        },
                    );
                }
                break;
            case 'stacked_bar_plot':
            case 'pie_chart':
                const legendOrder = (display as StackedBarPlotDisplayOption)?.legend_display_order;
                const ids = (plot?.analysis as PlotDataHeaderAnalysis)?.plot_data_headers?.slice(1) ?? [];
                const isFunctionalAnnotationAnalysis = plot.analysis_type === 'functional_annotation';
                const mappedIds = ids?.map((peakSetId) => {
                    if (isFunctionalAnnotationAnalysis) {
                        return { id: peakSetId, display_name: functionalAnnotationAnalysisHeaderMapping[peakSetId] };
                    }
                    return { id: peakSetId, display_name: capitalizeWordsAndReplaceUnderscores(peakSetId) };
                });
                const sortedIds = legendOrder?.length
                    ? Object.values(mappedIds).sort((g1, g2) => {
                          const sortedIndex1 = legendOrder?.indexOf(g1.id) ?? -1;
                          const sortedIndex2 = legendOrder?.indexOf(g2.id) ?? -1;
                          return sortedIndex1 - sortedIndex2;
                      })
                    : mappedIds;
                sortedIds.forEach((id, i) => {
                    legendItems.push({
                        label: id.display_name,
                        color: customColorConfig[id.id],
                        displayName: customNameConfig[id.id],
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });
                break;
            case 'network_graph':
                const networkGraphlegendOrder = (display as StackedBarPlotDisplayOption)?.legend_display_order;
                const networkGraphIds = NetworkEdgeOptions.map((o) => o.id) ?? [];
                const mappedNetworkIds = networkGraphIds?.map((typeId) => {
                    return { id: typeId, display_name: capitalizeWordsAndReplaceUnderscores(typeId) };
                });
                const sortedNetworkIds = networkGraphlegendOrder?.length
                    ? Object.values(mappedNetworkIds).sort((g1, g2) => {
                          const sortedIndex1 = networkGraphlegendOrder?.indexOf(g1.id) ?? -1;
                          const sortedIndex2 = networkGraphlegendOrder?.indexOf(g2.id) ?? -1;
                          return sortedIndex1 - sortedIndex2;
                      })
                    : mappedNetworkIds;
                sortedNetworkIds.forEach((id, i) => {
                    legendItems.push({
                        label: id.display_name,
                        color: customColorConfig[id.id],
                        displayName: customNameConfig[id.id],
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });
                break;

            case 'venn_diagram':
                const plotDataIsReady = validateAnalysisInputsPlotDataReady(plot.analysis);
                if (!plotDataIsReady) {
                    break;
                }

                const analysisInputLists = OverlapLists.map((list) => (plot.analysis as OverlapAnalysis)[list]).filter(
                    (list) => list,
                );

                analysisInputLists.forEach((input, i) => {
                    const listName = `${input?.name ? input?.name : `List ${i + 1}`} (${i + 1})`;
                    legendItems.push({
                        label: input?.uuid,
                        color: customOptionsConfig[input?.uuid]?.circle_color,
                        displayName: customNameConfig[input?.uuid] ?? listName,
                        palette_color: paletteColors[i % paletteColors.length],
                    });
                });
                break;
            case 'ridge_plot':
            case 'violin_plot':
                const areaData = plotData as PCAData;
                const groupVisibility = (display as RidgePlotDisplayOption).groups ?? {};
                if (!areaData) {
                    break;
                }
                if (areaData.pipeline_status === 'in_progress') {
                    break;
                }

                const display_group_order = (display as RidgePlotDisplayOption)?.group_display_order;
                const ridgeGroupsById: Record<number, Pick<PCASample, 'group_id' | 'group_name'>> = {};
                (areaData?.items ?? []).forEach((item) => {
                    ridgeGroupsById[item.group_id] = item;
                });

                const sortedRidgeGroups = Object.values(ridgeGroupsById).sort((g1, g2) => {
                    const sortedIndex1 = display_group_order?.indexOf(g1.group_id) ?? -1;
                    const sortedIndex2 = display_group_order?.indexOf(g2.group_id) ?? -1;
                    return sortedIndex1 - sortedIndex2;
                });

                sortedRidgeGroups.forEach((group) => {
                    if (!groupVisibility[group.group_id]) return;

                    const sortedIndex = display_group_order?.indexOf(group.group_id) ?? -1;
                    if (sortedIndex >= 0) {
                        legendItems.push({
                            label: group.group_name,
                            color: customColorConfig[group.group_id],
                            displayName: customNameConfig[group.group_id],
                            palette_color: paletteColors[sortedIndex % paletteColors.length],
                        });
                    }
                });
        }

        return { legendItems, style, horizontalOnly };
    }, [plotData, plot]);
};

export default usePlotLegendItems;
