import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import useAuth from '@hooks/useAuth';
import PreprocessStep, { PreprocessPlot, ResultData } from '@models/PreprocessStep';
import Workflow from '@models/Workflow';
import { usePollingEffect } from './usePollingEffect';
import Experiment from '../models/Experiment';
import { PreprocessFormValues } from '../components/experiments/preprocesses/PreprocessFormTypes';

const usePreprocessStep = (
    experiment: Experiment | null | undefined,
    getWorkflows: (getParameters?: boolean) => void,
    preprocessReadOnly: boolean,
    selectedPreprocess: PreprocessStep | null,
    selectedWorkflow: Workflow | null,
    setPreprocessReadOnly: Dispatch<SetStateAction<boolean>>,
    setSelectedPreprocess: any,
) => {
    const { authReady } = useAuth();
    const api = useApi();
    const [updatePreprocessError, setUpdatePreprocessError] = useState<string | null>(null);
    const [updatePreprocessLoading, setUpdatePreprocessLoading] = useState<boolean>(false);
    const [acceptPreprocessLoading, setAcceptPreprocessLoading] = useState<boolean>(false);
    const [acceptPreprocessError, setAcceptPreprocessError] = useState<string | null>(null);
    const [rollbackPreprocessLoading, setRollbackPreprocessLoading] = useState<boolean>(false);
    const [rollbackPreprocessError, setRollbackPreprocessError] = useState<string | null>(null);
    const [plots, setPlots] = useState<PreprocessPlot[] | null>(null);
    const [plotsError, setPlotsError] = useState<string | null>(null);
    const [plotsLoading, setPlotsLoading] = useState<boolean>(false);
    const [plotSignedUrl, setPlotSignedUrl] = useState<string | null>(null);
    const [plotSignedUrlError, setPlotSignedUrlError] = useState<string | null>(null);
    const [plotSignedUrlLoading, setPlotSignedUrlLoading] = useState<boolean>(false);
    const [plotData, setPlotData] = useState<ResultData | null>(null);
    const [plotDataError, setPlotDataError] = useState<{ code?: string | undefined } | null | undefined>(null);
    const [plotDataLoading, setPlotDataLoading] = useState<boolean>(false);
    const [preprocessFormData, setPreprocessFormData] = useState<PreprocessFormValues | null>(null);
    const [summaryData, setSummaryData] = useState<ResultData | null>(null);
    const [summaryDataLoading, setSummaryDataLoading] = useState<boolean>(false);
    const [summaryDataError, setSummaryDataError] = useState<{ code?: string | undefined } | null | undefined>(null);
    const experimentId = experiment?.uuid ?? '';

    usePollingEffect(
        async () => {
            if (!selectedWorkflow || !selectedPreprocess) return;

            const getResponse = await api.get<PreprocessStep>(
                Endpoints.lab.experiment.workflow.preprocess.base({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                    preprocessId: selectedPreprocess.uuid as string,
                }),
            );
            if (getResponse?.pipeline_status !== 'in_progress') {
                setSelectedPreprocess({
                    ...selectedPreprocess,
                    pipeline_status: getResponse.pipeline_status,
                    pipeline_ran: getResponse.pipeline_ran,
                });
                if (selectedPreprocess?.uuid) {
                    getWorkflows();
                }
                if (selectedPreprocess?.preprocess_type.has_summary) {
                    await getSummaryData();
                }
                setPreprocessReadOnly(false);
            } else if (!Boolean(experiment?.accepted_workflow)) {
                setPreprocessReadOnly(true);
            }
        },
        [experiment?.accepted_workflow, selectedPreprocess],
        {
            trigger: selectedPreprocess?.pipeline_status === 'in_progress',
            interval: 20_000,
        },
    );

    const updatePreprocess = async (formValues: any) => {
        if (!selectedWorkflow) return;

        setUpdatePreprocessLoading(true);
        setUpdatePreprocessError(null);
        try {
            setPreprocessFormData(formValues);
            const preprocessResponse = await api.post<PreprocessStep>(
                Endpoints.lab.experiment.workflow.preprocesses({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                }),
                formValues,
            );

            setSelectedPreprocess({
                ...selectedPreprocess,
                pipeline_status: preprocessResponse.pipeline_status,
                uuid: preprocessResponse.uuid,
            });
            setUpdatePreprocessLoading(false);
            await getWorkflows();

            return preprocessResponse;
        } catch (e) {
            setUpdatePreprocessLoading(false);
            setUpdatePreprocessError(e);
        }
    };
    const getPlots = async () => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setPlotsLoading(true);
        setPlotsError(null);
        try {
            const preprocessStepPlots = await api.get<PreprocessPlot[]>(
                Endpoints.lab.experiment.workflow.preprocess.plots({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                    preprocessId: selectedPreprocess.uuid as string,
                }),
            );
            setPlots(preprocessStepPlots);
            setPlotsLoading(false);
            return preprocessStepPlots;
        } catch (e) {
            setPlotsLoading(false);
            setPlotsError(e);
        }
    };
    const getPlotSignedUrl = async (plotId: string) => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setPlotSignedUrlLoading(true);
        setPlotSignedUrlError(null);
        try {
            if (plotId) {
                const plotSignedUrl = await api.get<{ url: string }>(
                    Endpoints.lab.experiment.workflow.preprocess.plot.signedUrl({
                        experimentId,
                        workflowId: selectedWorkflow.uuid as string,
                        preprocessId: selectedPreprocess.uuid as string,
                        plotId,
                    }),
                );
                setPlotSignedUrl(plotSignedUrl.url);
                setPlotSignedUrlLoading(false);
                return plotSignedUrl.url;
            }
        } catch (e) {
            setPlotSignedUrlLoading(false);
            setPlotSignedUrlError(e);
        }
    };
    const getPlotData = async (plotId: string) => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setPlotDataLoading(true);
        setPlotDataError(null);
        try {
            if (plotId) {
                const plotData = await api.get<ResultData>(
                    Endpoints.lab.experiment.workflow.preprocess.plot.data({
                        experimentId,
                        workflowId: selectedWorkflow.uuid as string,
                        preprocessId: selectedPreprocess.uuid as string,
                        plotId,
                    }),
                );
                setPlotData(plotData);
                setPlotDataLoading(false);
                return plotData;
            }
        } catch (e) {
            setPlotDataLoading(false);
            setPlotDataError(e);
        }
    };
    const getFormData = async () => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        const preprocessFormData = await api.get<PreprocessFormValues>(
            Endpoints.lab.experiment.workflow.preprocess.base({
                experimentId,
                workflowId: selectedWorkflow.uuid as string,
                preprocessId: selectedPreprocess.uuid as string,
            }),
        );
        setPreprocessFormData(preprocessFormData);
    };
    const acceptPreprocess = async () => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setAcceptPreprocessLoading(true);
        setAcceptPreprocessError(null);
        try {
            const acceptedStep = await api.post<PreprocessStep>(
                Endpoints.lab.experiment.workflow.preprocess.accept({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                    preprocessId: selectedPreprocess.uuid as string,
                }),
            );
            setAcceptPreprocessLoading(false);
            const refetchParameters = selectedPreprocess?.preprocess_type.shortname === 'seurat_cluster_object';
            getWorkflows(refetchParameters);
            return acceptedStep;
        } catch (e) {
            setAcceptPreprocessLoading(false);
            setAcceptPreprocessError(e);
        }
    };
    const rollbackPreprocess = async () => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setRollbackPreprocessLoading(true);
        setRollbackPreprocessError(null);
        try {
            const rolledBackStep = await api.doDelete<PreprocessStep>(
                Endpoints.lab.experiment.workflow.preprocess.accept({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                    preprocessId: selectedPreprocess.uuid as string,
                }),
            );
            setRollbackPreprocessLoading(false);
            await getWorkflows();
            return rolledBackStep;
        } catch (e) {
            setRollbackPreprocessLoading(false);
            setRollbackPreprocessError(e);
        }
    };

    const getSummaryData = async () => {
        if (!selectedWorkflow || !selectedPreprocess) return;

        setSummaryDataLoading(true);
        setSummaryDataError(null);
        try {
            const data = await api.get<ResultData>(
                Endpoints.lab.experiment.workflow.preprocess.summaryData({
                    experimentId,
                    workflowId: selectedWorkflow.uuid as string,
                    preprocessId: selectedPreprocess.uuid as string,
                }),
            );
            setSummaryData(data);
            setSummaryDataLoading(false);
            return data;
        } catch (e) {
            setSummaryDataLoading(false);
            setSummaryDataError(e);
        }
    };

    useEffect(() => {
        if (!authReady) return;
        if (!selectedWorkflow || !selectedPreprocess) return;

        if (
            experimentId &&
            selectedPreprocess &&
            selectedPreprocess.uuid &&
            selectedPreprocess.pipeline_status === 'completed'
        ) {
            getFormData();
            getPlots();
            if (selectedPreprocess.preprocess_type.has_summary) {
                getSummaryData();
            }
        }
    }, [experimentId, authReady, selectedPreprocess?.uuid, selectedPreprocess?.pipeline_status]);

    return {
        acceptPreprocess,
        acceptPreprocessError,
        acceptPreprocessLoading,
        getFormData,
        getPlotData,
        getPlots,
        getPlotSignedUrl,
        plotData,
        plotDataError,
        plotDataLoading,
        plots,
        plotsError,
        plotSignedUrl,
        plotSignedUrlError,
        plotSignedUrlLoading,
        plotsLoading,
        preprocess: selectedPreprocess,
        preprocessFormData,
        preprocessReadOnly,
        rollbackPreprocess,
        rollbackPreprocessError,
        rollbackPreprocessLoading,
        setPlotData,
        setPlotSignedUrl,
        setPreprocessFormData,
        setPreprocessReadOnly,
        summaryData,
        summaryDataError,
        summaryDataLoading,
        updatePreprocess,
        updatePreprocessError,
        updatePreprocessLoading,
    };
};

export default usePreprocessStep;
