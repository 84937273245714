import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import PipelineRun from '@models/PipelineRun';

type Props = { experiment: Experiment | null | undefined };
const useExperimentPipelineRuns = ({ experiment }: Props) => {
    const { data, error, ...otherSWR } = useSWR<PipelineRun[]>(() =>
        !experiment ? null : Endpoints.lab.experiment.pipelineRuns({ experimentId: experiment.uuid }),
    );

    return { data, error, loading: !data && !error && experiment, ...otherSWR };
};

export default useExperimentPipelineRuns;
