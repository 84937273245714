import UploadSession, { CreateUploadSessionParams } from '@models/UploadSession';
import Endpoints from '@services/Endpoints';
import { useContext, useMemo, useState } from 'react';
import { getFileIdentifier } from '@util/FileUtil';
import Logger from '@util/Logger';
import useApi from '@hooks/useApi';
import Experiment from '@models/Experiment';
import { blankToNull, isNotBlank } from '@util/StringUtil';
import { FileDataType } from '@models/PlutoFile';
import useMatchMutate from '@hooks/useMatchMutate';
import { AuthContext } from '@contexts/AuthContext';
import { AssayUploadContext } from '@contexts/AssayUploadContext';

const logger = Logger.make('useUploadSession', 'file_upload');

const getStorageKey = (fileId: string) => `__pluto.uploadsession.${fileId}`;

type Props = { file: File; experiment: Experiment; data_type: FileDataType };

const useUploadSession = ({ file, experiment, data_type }: Props) => {
    const { assayUnits, assayUnitsDisplayName } = useContext(AssayUploadContext);
    const authContext = useContext(AuthContext);
    const [session, setSession] = useState<UploadSession | null>(null);
    const [loading, setLoading] = useState(false);
    const mutate = useMatchMutate();
    const api = useApi();
    const fileId = useMemo(() => {
        return getFileIdentifier({ file, experimentId: experiment.uuid });
    }, [file, experiment.uuid]);

    const getCachedSession = (): UploadSession | null => {
        try {
            const key = getStorageKey(fileId);
            const value = localStorage.getItem(key);
            if (!isNotBlank(value)) {
                return null;
            }
            return JSON.parse(value) as UploadSession;
        } catch (error) {
            logger.error(error);
            return null;
        }
    };

    const setCachedSession = (session: UploadSession | null) => {
        const key = getStorageKey(fileId);
        if (!session) {
            localStorage.removeItem(key);
            return;
        }
        localStorage.setItem(key, JSON.stringify(session));
    };

    const removeCachedSession = () => {
        setCachedSession(null);
    };

    /**
     * @throws {ApiError} if there was any error fetching the session
     * @return {Promise<UploadSession | null>}
     */
    type SessionObject = {
        filename: string;
        file_size: number;
        file_type: string;
        data_type: FileDataType;
        units?: string;
        units_display_name?: string;
    };
    const fetchSession = async (): Promise<UploadSession | null> => {
        setLoading(true);
        const cachedSession = getCachedSession();
        if (cachedSession) {
            logger.debug('using cached upload session', cachedSession);
            setSession(session);
            setLoading(false);
            return cachedSession;
        }

        const newSessionObject: SessionObject = {
            filename: file.name,
            file_size: file.size,
            file_type: blankToNull(file.type) ?? 'text/plain',
            data_type,
        };
        if (assayUnits) newSessionObject.units = assayUnits;
        if (assayUnitsDisplayName) newSessionObject.units_display_name = assayUnitsDisplayName;

        const sessionResponse = await api.post<UploadSession, CreateUploadSessionParams>(
            Endpoints.lab.experiment.uploadSessions({ experimentId: experiment.uuid }),
            newSessionObject,
        );
        logger.info('sessionResponse', sessionResponse);
        setCachedSession(sessionResponse);
        setSession(session);
        setLoading(false);
        return sessionResponse;
    };

    /**
     * Mark a session as completed. Will remove the cached value and remove the session from the api
     * @return {Promise<void>}
     * @throws {ApiError} an error if something goes wrong
     */
    const completeSession = async (session: UploadSession) => {
        logger.debug('completing upload session for file', file.name, session);
        /* Ensure the user has a valid auth token in the event that the browser 
           was in the background and didn't refresh the token like it should have. */
        await authContext.refreshTokenIfNeeded();
        try {
            const response = await api.post<{ message: string }>(
                Endpoints.lab.experiment.uploadSessionComplete({
                    experimentId: experiment.uuid,
                    session_id: session.uuid,
                }),
                {
                    data_type,
                },
            );
            logger.debug('complete upload session response', response);
            await mutate.pathEqualsIgnoreQueryMutate(Endpoints.lab.experiment.files({ experimentId: experiment.uuid }));
        } catch (error) {
            logger.error(error);
            throw error;
        }

        removeCachedSession();
    };

    const cancelSession = async (session: UploadSession) => {
        logger.debug('canceling upload session', session);
        try {
            removeCachedSession();
        } catch (error) {
            logger.error(error);
            throw error;
        }

        try {
            await api.doDelete(
                Endpoints.lab.experiment.uploadSession({
                    experimentId: experiment.uuid,
                    session_id: session.uuid,
                }),
            );
        } catch (error) {
            logger.error(error);
            throw error;
        }
    };

    return { file, fileId, fetchSession, session, loading, removeCachedSession, completeSession, cancelSession };
};

export default useUploadSession;
