import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';
import { createContext, useContext } from 'react';
import { StateSetter } from '@contexts/ContextTypes';
import Experiment from '@models/Experiment';
import { BaseSpaceProject } from '@models/baseSpace/BaseSpaceProject';

type ContextType = {
    experiment: Experiment;
    selectedFiles: BaseSpaceFile[];
    setSelectedFiles: StateSetter<BaseSpaceFile[]>;
    addFile: (file: BaseSpaceFile) => void;
    removeFile: (file: BaseSpaceFile) => void;
    selectedProject: BaseSpaceProject | null;
    setSelectedProject: StateSetter<BaseSpaceProject | null>;
};

export const BaseSpaceBrowserContext = createContext<ContextType>({
    selectedFiles: [],
    setSelectedFiles: () => undefined,
    addFile: () => undefined,
    removeFile: () => undefined,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    experiment: null!,
    selectedProject: null,
    setSelectedProject: () => undefined,
});
export const useBaseSpaceBrowserContext = () => useContext(BaseSpaceBrowserContext);
