import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import React from 'react';
import { isDefined, isString } from '@util/TypeGuards';
import ScaleMethods from '@components/experiments/methods/displays/ScaleMethods';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import ProseList from '@components/ProseList';
import { ErrorBarMethods } from '@components/experiments/methods/displays/ErrorBarMethods';
import { LongitudinalAnalysis } from '@models/analysis/LongitudinalAnalysis';
import LinePlotDisplayOption from '@models/plotDisplayOption/LinePlotDisplayOption';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const LinePlotMethods = ({ plot, analysisParameters }: Props) => {
    const { getTargetById, getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as LongitudinalAnalysis;
    const display = plot.display as LinePlotDisplayOption;

    const targetNames =
        analysis?.targets.map((id) => (isString(id) ? id : getTargetById(id)?.display_name)).filter(isDefined) ?? [];
    const $targets = <ProseList items={targetNames} />;
    const groupNames = analysis?.groups?.map((g) => g.display_name).filter(isDefined) ?? [];
    const $groups = <ProseList items={groupNames} />;

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>Plot</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        Line plot showing the values for {$targets} on the <span className="italic">y</span>-axis and{' '}
                        <span className="font-semibold">
                            {getVariableById(analysis.x_axis_variable_id)?.display_name}
                        </span>{' '}
                        on the <span className="italic">x</span>-axis.{' '}
                        {analysis.groups.length > 0 ? (
                            <>
                                Samples are split by{' '}
                                <ProseList items={analysis.variables.map((id) => getVariableById(id)?.display_name)} />,
                                plotting groups: {$groups} each as their own series.
                            </>
                        ) : null}{' '}
                        Lines are centered at the average value for each group.
                        {display.show_error_bars && display.error_bars_locations?.length && (
                            <>
                                {' '}
                                <ErrorBarMethods error_bar_option={display.error_bars_option} />
                            </>
                        )}{' '}
                        <ScaleMethods y_axis_scale={display.y_axis_scale} />
                    </p>
                </div>
            </section>
        </div>
    );
};

export default LinePlotMethods;
