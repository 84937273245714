import React from 'react';
import Plot from '@models/Plot';
import { AssaySummaryAnalysisParameters } from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';

type Props = { plot: Plot; analysisParameters: AssaySummaryAnalysisParameters; experiment: Experiment };
const AssaySummaryAnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    return (
        <>
            <AsyncTargetPicker experiment={experiment} name="targets" />
            <AnalysisVariablesMultiSelectField variables={analysisParameters.variables} />
            <AnalysisGroupsField plot={plot} experiment={experiment} />
        </>
    );
};

export default AssaySummaryAnalysisFormFields;
