import { User } from '@models/User';
import Experiment from '@models/Experiment';

export enum FileFormat {
    PDF = 'PDF',
    JPEG = 'JPEG',
    file = 'file',
}

export type AttachmentSignature = {
    expiration: number;
    signature: string;
};

export type ExperimentAttachment = {
    display_name: string;
    filename: string;
    uuid: string;
    file_format?: FileFormat;
    external_id: string;
    external_file_url: string;
    file_size: number;
    is_archived: boolean;
    uploaded_by?: User;
    experiment?: Experiment;
    created_at: string;
};

export type AttachmentCreateParams = {
    external_id: string;
};
