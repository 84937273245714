import { FormikFieldError } from '@components/forms/FieldError';
import { isDefined } from '@util/TypeGuards';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import { Tooltip } from '@mui/material';
import TestTypeDisplayName, { getTestTypeInfo } from '@components/experiments/TestTypeDisplayName';
import { formatTableHeader } from '@util/StringUtil';
import React from 'react';
import { useFormikContext } from 'formik';
import { StatisticalTestFormValues } from '@hooks/useStatisticalTest';
import { StatisticalTestType } from '@models/SummaryStatisticalTestResult';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';

type Props = { statisticalTestTypes: StatisticalTestType[]; plot: Plot; experiment: Experiment };
const PlotStatisticsGroupField = ({ statisticalTestTypes, plot, experiment }: Props) => {
    const { values, setFieldValue } = useFormikContext<StatisticalTestFormValues>();

    const testType = statisticalTestTypes.find((t) => t.shortname === values.test_type);
    const { isExact } = getTestTypeInfo(testType);

    const { selectedGroups: groups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: values.variables,
    });

    return (
        <>
            {values.target && values.test_type && (
                <div className="form-field">
                    <span className="field-label">
                        Select {testType?.minimum_groups ?? ''}
                        {isExact ? '' : '+'} Groups
                    </span>
                    <FormikFieldError name="groups" className="mb-1" />
                    <div className="flex flex-row flex-wrap">
                        {groups.map((group, index) => {
                            const notEnoughSamples =
                                testType &&
                                isDefined(testType?.minimum_group_sample_size) &&
                                isDefined(group.sample_id_count) &&
                                testType.minimum_group_sample_size > 0 &&
                                group.sample_id_count < testType.minimum_group_sample_size;
                            return (
                                <SelectableItem
                                    key={`group_${group.id})${index}`}
                                    selected={values.groups.includes(group.id)}
                                    onSelect={() => setFieldValue('groups', toggleValue(values.groups, group.id))}
                                    className="mb-2 mr-2 flex flex-col items-center justify-center"
                                    disabled={notEnoughSamples}
                                >
                                    {notEnoughSamples ? (
                                        <Tooltip
                                            title={
                                                <div>
                                                    <TestTypeDisplayName test_type={testType} />
                                                    <span>{` requires at least
                                                                ${testType.minimum_group_sample_size ?? 1} samples. This
                                                                    group only has ${group.sample_id_count}.`}</span>
                                                </div>
                                            }
                                        >
                                            <span>{formatTableHeader(group.display_name)}</span>
                                        </Tooltip>
                                    ) : (
                                        <span>{formatTableHeader(group.display_name)}</span>
                                    )}
                                </SelectableItem>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default PlotStatisticsGroupField;
