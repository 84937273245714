import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const SurvivalAnalysisIcon = ({
    width = 24,
    height = 24,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <path
                d="M12 9V12L14.25 14.25L12 9ZM18.75 12C18.75 12.8864 18.5754 13.7642 18.2362 14.5831C17.897 15.4021 17.3998 16.1462 16.773 16.773C16.1462 17.3998 15.4021 17.897 14.5831 18.2362C13.7642 18.5754 12.8864 18.75 12 18.75C11.1136 18.75 10.2358 18.5754 9.41689 18.2362C8.59794 17.897 7.85382 17.3998 7.22703 16.773C6.60023 16.1462 6.10303 15.4021 5.76381 14.5831C5.42459 13.7642 5.25 12.8864 5.25 12C5.25 10.2098 5.96116 8.4929 7.22703 7.22703C8.4929 5.96116 10.2098 5.25 12 5.25C13.7902 5.25 15.5071 5.96116 16.773 7.22703C18.0388 8.4929 18.75 10.2098 18.75 12Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
