import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import useSWR from 'swr';
import { ComparisonGroupConfig } from '@models/ExperimentConfig';
import { ParameterOption } from '@models/AnalysisParameters';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';

type Props = { experiment: Experiment; plot: Plot; variables: number[] | null | undefined };
const useExperimentPlotGroups = <GROUP extends ParameterOption = ParameterOption>({
    experiment,
    plot,
    variables: _variables,
}: Props) => {
    const experimentId = experiment.uuid;
    const plotId = plot.uuid;
    const variables = [...(_variables ?? [])].sort();
    const api = useApi();
    // This uses a POST request, which is a little different from other places in the app.
    // The key is returned as a tuple (compound key) consisting of the URL and the variables passed in.
    // The variables are in a sorted list so that the cache key can be re-used no matter on the order a user selected the variables
    const { data, error } = useSWR<ComparisonGroupConfig<GROUP>>(
        () =>
            variables.length > 0
                ? [
                      Endpoints.lab.experiment.plot.groups({
                          experimentId,
                          plotId,
                      }),
                      variables,
                  ]
                : null,
        { fetcher: (url, variables) => api.post(url, { variables }), revalidateOnMount: true },
    );

    const loading = !data && !error && variables.length > 0;
    const groups = data?.items ?? [];

    /**
     * Get group details for a given group ID.
     * @param {number} group_id
     * @return {ParameterOption | null}
     */
    const getGroupById = (group_id: number): GROUP | null => {
        return groups.find((g) => g.id === group_id) ?? null;
    };

    const analysis = plot?.analysis as AssaySummaryAnalysis | null;
    const selectedGroupIds = analysis?.groups?.map((g) => g.id) ?? [];
    /**
     * The groups that were selected in the plot's analysis
     */
    const selectedGroups = groups.filter((g) => selectedGroupIds.includes(g.id));
    return { data, error, loading, groups, getGroupById, selectedGroups };
};

export default useExperimentPlotGroups;
