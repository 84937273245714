import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import { isDefined, isString } from '@util/TypeGuards';
import React from 'react';
import HeatmapDisplayOption, { getSummaryTypeDisplayName } from '@models/plotDisplayOption/HeatmapDisplayOption';
import NormalizationCPMMethods from '@components/experiments/methods/displays/NormalizationCPMMethods';
import ProseList from '@components/ProseList';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const SummaryHeatmapMethods = ({ plot, analysisParameters }: Props) => {
    const { getTargetById, getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as AssaySummaryAnalysis;
    const variables = plot.analysis?.variables ?? [];
    const display = plot.display as HeatmapDisplayOption;
    const targetNames =
        analysis?.targets.map((id) => (isString(id) ? id : getTargetById(id)?.display_name)).filter(isDefined) ?? [];
    const $targets = <ProseList items={targetNames} />;
    const groupNames = analysis?.groups?.map((g) => g.display_name).filter(isDefined) ?? [];
    const $groups = <ProseList items={groupNames} />;
    const $variables = <ProseList items={variables.map((id) => getVariableById(id)?.display_name).filter(isDefined)} />;
    const isNormalized = analysis?.analysis_type === 'assay_summary_cpm_normalized';
    const normalizationMethod = isNormalized ? 'counts per million (CPM)-normalized ' : '';

    const summaryName = getSummaryTypeDisplayName(display.summarize_values_by);

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>Plot</MethodsSectionTitle>
                <div>
                    <p>
                        <span>
                            Heatmap showing the {normalizationMethod}
                            {summaryName} values for {$targets} for all samples.{' '}
                            <NormalizationCPMMethods analysis_type={analysis?.analysis_type} />{' '}
                        </span>
                        {display.summarize_values_by !== 'none' && (
                            <span>
                                <span className="capitalize">{summaryName}</span> values were calculated within their
                                respective group: {$groups}.
                            </span>
                        )}{' '}
                        <span>Sample groups are colored by {$variables}.</span>
                    </p>
                </div>
            </section>
        </div>
    );
};

export default SummaryHeatmapMethods;
