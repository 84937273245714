import * as Yup from 'yup';

export const createSeuratObjectSchema = Yup.object({
    min_cells: Yup.number().min(0, 'Minimum required number is 0').max(50, 'Maximum allowed number is 50'),
    min_features: Yup.number().min(0, 'Minimum required number is 0').max(1000, 'Maximum allowed number is 1,000'),
});
export const filterSeuratObjectSchema = Yup.object({
    sample_configs: Yup.array().of(
        Yup.object({
            max_count: Yup.number().min(0, 'Minimum required number is 0'),
            max_feature: Yup.number().min(0, 'Minimum required number is 0'),
            max_mito: Yup.number()
                .min(0, 'Minimum required percentage is 0%')
                .max(100, 'Maximum allowed percentage is 100%'),
            min_count: Yup.number().min(0, 'Minimum required number is 0'),
            min_feature: Yup.number().min(0, 'Minimum required number is 0'),
            min_ns: Yup.number().min(0, 'Minimum required number is 0').max(1, 'Maximum allowed number is 1'),
            sample_id: Yup.string(),
        }),
    ),
    rm_doublets: Yup.boolean(),
    rm_samples: Yup.array().of(Yup.string()),
    filter_all_samples: Yup.boolean(),
});
export const normalizeSeuratObjectSchema = Yup.object({
    norm_method: Yup.string(),
    n_features: Yup.number().min(1000, 'Minimum required number is 1,000').max(5000, 'Maximum allowed number is 5,000'),
    n_dims: Yup.number(),
    n_neighbors: Yup.number(),
    regress_umi: Yup.boolean(),
    regress_mito: Yup.boolean(),
    regress_cc_diff: Yup.boolean(),
    regress_cc: Yup.boolean(),
});
export const integrateSeuratObjectSchema = Yup.object({
    int_method: Yup.string(),
    covariates: Yup.array()
        .of(Yup.string())
        .when(['int_method'], {
            is: (int_method: string) => int_method !== 'skip',
            then: Yup.array().required().min(1, 'Please select at least one covariate or choose No integration'),
        }),
    norm_method: Yup.string(),
    n_features: Yup.number(),
    n_dims: Yup.number(),
    n_neighbors: Yup.number(),
    regress_umi: Yup.boolean(),
    regress_mito: Yup.boolean(),
    regress_cc_diff: Yup.boolean(),
    regress_cc: Yup.boolean(),
});
export const clusterSeuratObjectSchema = Yup.object({
    k_param: Yup.number(),
    n_dims: Yup.number(),
    resolutions: Yup.array().of(Yup.number()),
    log2fc: Yup.number(),
    stat_test: Yup.string(),
    only_pos: Yup.boolean(),
});
export const refineSeuratObjectSchema = Yup.object({
    refine_method: Yup.string().required(),
    target_resolution: Yup.string().required(),
    target_clusters: Yup.array().when(['refine_method'], {
        is: (refine_method: string) => refine_method !== 'skip',
        then: Yup.array().min(
            1,
            'Please select at least one cluster to remove, or select the Skip radio button under Refinement strategy',
        ),
    }),
});
