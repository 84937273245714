// src/utils/hashUtil.ts

/**
 * Updates the URL hash with the given plot UUID.
 * @param plotUUID - The UUID of the plot to include in the URL hash.
 */
export const updateHashWithPlotUUID = (plotUUID: string) => {
    if (plotUUID) {
        window.location.hash = `plot-${plotUUID}`;
    }
};

/**
 * Updates the URL hash with the given preprocess UUID.
 * @param preprocessUUID - The UUID of the preprocess plot to include in the URL hash.
 */
export const updateHashWithPreprocessUUID = (preprocessUUID: string) => {
    if (preprocessUUID) {
        window.location.hash = `preprocess-${preprocessUUID}`;
    }
};

/**
 * Clears the URL hash.
 */
export const clearHash = () => {
    window.location.hash = '';
};
