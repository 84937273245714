import LoadingMessage from '@components/LoadingMessage';
import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { proseListJoiner } from '@util/ObjectUtil';
import useSWR from 'swr';
import { ExperimentData } from '@models/ExperimentData';
import Endpoints from '@services/Endpoints';
import { PrincipalComponentsAnalysis } from '@models/analysis/PrincipalComponentsAnalysis';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { plot: Plot; experiment: Experiment };
const PrincipalComponentsAnalysisMethodsSection = ({ plot, experiment }: Props) => {
    const analysis = plot.analysis as PrincipalComponentsAnalysis;

    const { data: assayData, error: assayError } = useSWR<ExperimentData>(() =>
        Endpoints.lab.experiment.assayData({
            experimentId: experiment.uuid,
            limit: 250,
        }),
    );

    const loading = !assayData && !assayError;
    const groups = analysis.groups ?? [];
    const allSamples = groups.length === 0;
    const targetCount = (assayData?.count ?? 0).toLocaleString();

    const $groups = allSamples ? (
        <span className="font-semibold">all samples</span>
    ) : (
        <span className="">
            samples from the {`${groups.length === 1 ? 'group' : 'groups'}`}:{' '}
            <span className="font-semibold">{proseListJoiner(groups.map((g) => g.display_name))}</span>
        </span>
    );

    return (
        <section>
            <MethodsSectionTitle>Principal components analysis</MethodsSectionTitle>
            {loading ? (
                <LoadingMessage inline message="Loading methods..." className="mt-2" />
            ) : (
                <div className="space-y-4">
                    <p className="mb-4">
                        Principal components analysis (PCA)<sup>1</sup> was calculated by applying the{' '}
                        <span className="font-mono">prcomp()</span> R function to{' '}
                        <span className="font-semibold">
                            {analysis.cpm_normalize ? 'counts per million (CPM)-normalized values' : 'raw values'}
                        </span>{' '}
                        for all <span className="font-semibold">{targetCount} targets</span> in the experiment and{' '}
                        {$groups}. {analysis.center_data && <span>The data was shifted to be zero centered. </span>}
                        {analysis.scale_data && (
                            <span>The data was scaled to have unit variance before PCA was computed. </span>
                        )}
                    </p>
                </div>
            )}
        </section>
    );
};

export default PrincipalComponentsAnalysisMethodsSection;
