import React from 'react';
import Experiment from '@models/Experiment';
import AsyncTargetPicker from '@/src/components/AsyncTargetPicker';

type Props = {
    experiment: Experiment;
};
const SeuratMarkerExpressionAnalysisFormFields = ({ experiment }: Props) => {
    return (
        <div className="space-y-8">
            <section>
                <AsyncTargetPicker
                    experiment={experiment}
                    name="targets"
                    maxItems={1}
                    useTargetDataEndpoint
                    label="Target"
                />
            </section>
        </div>
    );
};

export default SeuratMarkerExpressionAnalysisFormFields;
