import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
    Alert,
} from '@mui/material';
import DialogCloseButton from '@components/DialogCloseButton';
import useBiomarkers from '@hooks/useBiomarkers';
import Button from '@components/Button';
import useEvidence from '@hooks/useEvidence';
import { BiomarkerList, Biomarker, BiomarkerSetType } from '@models/Biomarker';
import { InternalEvidenceType } from '@models/Evidence';
import SuccessModal from '../../SuccessModal';
import PlutoDialogTitle from '../../PlutoDialogTitle';

type AddAsEvidenceDialogProps = {
    open: boolean;
    onClose: () => void;
    plotId?: string | null;
    experimentId?: string | null;
    literatureId?: string | null;
    datasetID?: string | null;
};

const AddAsEvidenceDialog: React.FC<AddAsEvidenceDialogProps> = ({
    open,
    onClose,
    literatureId,
    plotId,
    experimentId,
    datasetID,
}) => {
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState<BiomarkerList | null>(null);
    const [selectedBiomarker, setSelectedBiomarker] = useState<Biomarker | null>(null);
    const [targetType, setTargetType] = useState<'biomarkers' | 'targets'>('biomarkers');
    const [rationale, setRationale] = useState<string>('');
    const [status, setStatus] = useState<string>('None');
    const [score, setScore] = useState<number | null>(null);
    const [error, setError] = useState<string>('');
    const { biomarkerLists, listLoading, sortedBiomarkers, setFilters, resetFilters } = useBiomarkers({
        listSlug: selectedList?.uuid ?? undefined,
        targetType,
    });
    const { postNewEvidence } = useEvidence({});
    const type: InternalEvidenceType = !!literatureId ? 'literature' : !!plotId ? 'plot' : 'experiment';
    const newEvidenceId = (!!literatureId ? literatureId : !!plotId ? plotId : experimentId) ?? null;

    const handleSave = async () => {
        if (!selectedBiomarker?.uuid || !newEvidenceId) return;
        const setType: BiomarkerSetType = 'biomarker';
        const payload =
            type === 'literature' && !!datasetID
                ? {
                      type,
                      id: newEvidenceId,
                      referenceObjectID: selectedBiomarker.uuid,
                      rationale,
                      status,
                      score,
                      datasetID,
                      setType,
                  }
                : {
                      type,
                      id: newEvidenceId,
                      referenceObjectID: selectedBiomarker.uuid,
                      rationale,
                      status,
                      score,
                      setType,
                  };

        await postNewEvidence(payload);
        setShowSuccess(true);
    };

    const handleChangeType = (event) => {
        setSelectedList(null);
        setSelectedBiomarker(null);
        setTargetType(event.target.value as 'biomarkers' | 'targets');
    };

    const handleClose = () => {
        setSelectedList(null);
        setSelectedBiomarker(null);
        setTargetType('biomarkers');
        setRationale('');
        setStatus('None');
        setScore(0);
        setError('');
        onClose();
        setShowSuccess(false);
    };

    return (
        <>
            <SuccessModal
                open={showSuccess}
                onClose={handleClose}
                title="Success"
                message="Evidence added successfully"
            />
            <Dialog open={open && !showSuccess} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <PlutoDialogTitle
                        title="Add as evidence"
                        subTitle={
                            <p>
                                Save this {type} as a piece of evidence on one of your biomarkers or targets.
                                <a
                                    href="https://help.pluto.bio/en/articles/embedded-apps-in-pluto"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ml-2"
                                >
                                    Learn more
                                </a>
                            </p>
                        }
                    />
                </DialogTitle>
                <DialogCloseButton onClose={handleClose} />
                <DialogContent className="flex min-w-[450px] flex-col space-y-4 p-8">
                    {!!error ? <Alert severity="error">{error}</Alert> : null}
                    <RadioGroup row value={targetType} onChange={handleChangeType}>
                        <FormControlLabel value="biomarkers" control={<Radio />} label="Biomarker" />
                        <FormControlLabel value="targets" control={<Radio />} label="Target" />
                    </RadioGroup>
                    <Autocomplete
                        options={biomarkerLists}
                        getOptionLabel={(option) => option.name ?? ''}
                        value={selectedList}
                        onChange={(event, newValue) => {
                            setSelectedList(newValue);
                            resetFilters();
                            setSelectedBiomarker(null);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={`Select ${targetType === 'biomarkers' ? 'Biomarker' : 'Target'} List`}
                                margin="normal"
                            />
                        )}
                        loading={listLoading}
                    />
                    {selectedList && (
                        <Autocomplete
                            options={sortedBiomarkers}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={selectedBiomarker}
                            onChange={(event, newValue) => setSelectedBiomarker(newValue)}
                            onInputChange={(event, value) => setFilters({ search: value })}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`Select ${targetType === 'biomarkers' ? 'Biomarker' : 'Target'}`}
                                    margin="normal"
                                />
                            )}
                            loading={listLoading}
                        />
                    )}
                    <p className="text-md font-semibold tracking-tight">Evidence</p>
                    <TextField
                        label="Rationale"
                        multiline
                        rows={3}
                        value={rationale}
                        onChange={(e) => setRationale(e.target.value)}
                        margin="normal"
                    />
                    <div className="flex w-full flex-col">
                        <p className="mb-1 text-sm font-semibold tracking-tight">Score (0 - 100)</p>
                        <TextField
                            type="number"
                            value={score ?? null}
                            onChange={(e) => setScore(e.target.value ? Number(e.target.value) : null)}
                            inputProps={{ min: 0, max: 100 }}
                            margin="none"
                            className="w-24"
                        />
                    </div>
                    <div className="mb-4">
                        <span className="block text-sm font-semibold text-gray-700">Status</span>
                        <div className="mt-2">
                            {['None', 'Not started', 'In progress', 'Ready for review', 'Reviewed', 'Not relevant'].map(
                                (statusOption) => (
                                    <button
                                        key={statusOption}
                                        type="button"
                                        className={`mb-2 mr-2 rounded-md px-3 py-1 text-sm ${
                                            status === statusOption ? 'bg-primary text-white' : 'bg-gray-200'
                                        }`}
                                        onClick={() => setStatus(statusOption)}
                                    >
                                        {statusOption}
                                    </button>
                                ),
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                        disabled={!newEvidenceId || !selectedBiomarker || !selectedList}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddAsEvidenceDialog;
