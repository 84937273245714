import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import { AnalysisGroup } from '@models/AnalysisParameters';
import { Log2FoldChangeDirection, SimpleComparativeAnalysis } from '@models/AnalysisParameters';

export interface ClusteringAnalysis extends PipelineStatusAnalysis {
    analysis_type: 'clustering';
    variables: number[];
    groups: AnalysisGroup[];
    adj_pval_threshold: number;
    log2_fc_threshold: number;
    is_zscore: boolean;
    is_log2_transform: boolean;
    is_cpm_normalize: boolean;
    differential_analysis: SimpleComparativeAnalysis;
    log2_fc_direction: Log2FoldChangeDirection;
}

export function getTransformNames(analysis: ClusteringAnalysis): string[] | null {
    const transforms: string[] = [];
    if (analysis.is_cpm_normalize) {
        transforms.push('counts per million (CPM)-normalized');
    }
    if (analysis.is_log2_transform) {
        transforms.push('log2-transformed');
    }
    if (analysis.is_zscore) {
        transforms.push('zscore-transformed');
    }
    if (transforms.length > 0) {
        return transforms;
    }
    return null;
}
