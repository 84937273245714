import cn from 'classnames';
import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

const SeuratMarkerExpressionAnalysisIcon = ({
    className = defaultTextLightClass,
    height = 24,
    width = 24,
}: IconProps) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
            <path
                d="M8.0892 4.89066L8.17101 3.20769L8.21686 4.89066C8.21686 4.89066 8.22268 5.11373 8.21686 5.2566C8.2082 5.46905 8.18856 5.6712 8.16038 5.8612C8.21492 6.13069 8.29564 6.38135 8.39115 6.72033C8.49234 7.07948 8.57517 7.2779 8.69309 7.63517C8.81101 7.99243 8.89384 8.19086 8.99503 8.55L9.00011 8.56803L9.00011 8.56803C9.15697 9.12471 9.24711 9.44459 9.29698 10.0137C9.33449 10.4419 9.22418 10.6851 9.29698 11.1115C9.39703 11.6976 9.50595 12.0368 9.90086 12.5753C10.1801 12.956 10.4742 13.1254 10.8067 13.4901C11.1892 13.9097 11.5409 14.12 11.7125 14.5879C11.866 15.0064 11.8987 15.2721 11.7125 15.6857C11.5738 15.994 11.3836 16.1449 11.1086 16.4176C10.8012 16.7224 10.5566 16.8636 10.2028 17.1494C9.84906 17.4353 9.63533 17.5887 9.29698 17.8813C9.05298 18.0923 8.87652 18.1978 8.69309 18.4302C8.53221 18.6341 8.49721 18.7625 8.39115 18.9791C8.34262 19.0782 8.31341 19.2145 8.28807 19.3439C8.26959 19.4382 8.25317 19.5288 8.23281 19.5986C8.22786 19.6323 8.22255 19.6697 8.21686 19.711C8.20973 19.7627 8.19526 19.7572 8.1753 19.7158C8.11848 19.5977 8.01726 19.1881 7.91492 18.9791C7.80885 18.7625 7.77386 18.6341 7.61297 18.4302C7.42954 18.1978 7.25308 18.0923 7.00909 17.8813C6.67073 17.5887 6.457 17.4353 6.10325 17.1494C5.74951 16.8636 5.50486 16.7224 5.19742 16.4176C4.92244 16.1449 4.73229 15.994 4.59354 15.6857C4.40738 15.2721 4.44006 15.0064 4.59354 14.5879C4.76513 14.12 5.11687 13.9097 5.49937 13.4901C5.83185 13.1254 6.12595 12.956 6.4052 12.5753C6.80011 12.0368 6.90903 11.6976 7.00909 11.1115C7.08189 10.6851 6.97157 10.4419 7.00909 10.0137C7.05896 9.44459 7.14909 9.1247 7.30595 8.56803L7.31103 8.55C7.41222 8.19086 7.49506 7.99243 7.61297 7.63517C7.73089 7.2779 7.79141 7.18065 7.95306 6.72033C8.03898 6.47568 8.11217 6.18622 8.16038 5.8612C8.12355 5.67925 8.09866 5.48871 8.0892 5.2566C8.08338 5.11373 8.0892 4.89066 8.0892 4.89066Z"
                fill="currentColor"
            />
            <path
                d="M8.17101 20.8154C8.17101 20.8154 8.01743 19.6578 8.0892 19.711C8.27144 19.8462 8.25285 19.2616 8.39115 18.9791C8.49721 18.7625 8.53221 18.6341 8.69309 18.4302C8.87652 18.1978 9.05298 18.0923 9.29698 17.8813C9.63533 17.5887 9.84906 17.4353 10.2028 17.1494C10.5566 16.8636 10.8012 16.7224 11.1086 16.4176C11.3836 16.1449 11.5738 15.994 11.7125 15.6857C11.8987 15.2721 11.866 15.0064 11.7125 14.5879C11.5409 14.12 11.1892 13.9097 10.8067 13.4901C10.4742 13.1254 10.1801 12.956 9.90086 12.5753C9.50595 12.0368 9.39703 11.6976 9.29698 11.1115C9.22418 10.6851 9.33449 10.4419 9.29698 10.0137C9.24711 9.44459 9.15697 9.1247 9.00011 8.56803L8.99503 8.55C8.89384 8.19086 8.81101 7.99243 8.69309 7.63517C8.57517 7.2779 8.49234 7.07948 8.39115 6.72033C8.23115 6.15248 8.11267 5.83249 8.0892 5.2566C8.08338 5.11373 8.0892 4.89066 8.0892 4.89066L8.17101 3.20769L8.21686 4.89066C8.21686 4.89066 8.22268 5.11373 8.21686 5.2566C8.19339 5.83249 8.0892 6.33266 7.95306 6.72033C7.79141 7.18065 7.73089 7.2779 7.61297 7.63517C7.49506 7.99243 7.41222 8.19086 7.31103 8.55L7.30595 8.56803C7.14909 9.1247 7.05896 9.44459 7.00909 10.0137C6.97157 10.4419 7.08189 10.6851 7.00909 11.1115C6.90903 11.6976 6.80011 12.0368 6.4052 12.5753C6.12595 12.956 5.83185 13.1254 5.49937 13.4901C5.11687 13.9097 4.76513 14.12 4.59354 14.5879C4.44006 15.0064 4.40738 15.2721 4.59354 15.6857C4.73229 15.994 4.92244 16.1449 5.19742 16.4176C5.50486 16.7224 5.74951 16.8636 6.10325 17.1494C6.457 17.4353 6.67073 17.5887 7.00909 17.8813C7.25308 18.0923 7.42954 18.1978 7.61297 18.4302C7.77386 18.6341 7.80885 18.7625 7.91492 18.9791C8.05321 19.2616 8.18944 19.9101 8.21686 19.711C8.38896 18.4615 8.21686 20.7692 8.21686 20.7692"
                stroke="currentColor"
            />
            <path
                d="M8.61392 6.6854V3.49843C8.61392 3.33394 8.52149 3.18261 8.37561 3.1066C8.24801 3.04012 8.09508 3.0402 7.96792 3.10752C7.8247 3.18334 7.73511 3.33213 7.73511 3.49419L7.73511 6.68788C7.73511 6.78783 7.76924 6.88478 7.83186 6.96268C8.00656 7.18002 8.338 7.18015 8.51439 6.96418C8.57858 6.88558 8.61392 6.78688 8.61392 6.6854Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M8.17101 21V21C8.41175 21 8.6069 20.8049 8.6069 20.5641V18.6667C8.6069 18.4259 8.41174 18.2308 8.17101 18.2308V18.2308C7.93027 18.2308 7.73511 18.4259 7.73511 18.6667V20.5641C7.73511 20.8049 7.93026 21 8.17101 21V21ZM8.17101 21L8.37465 20.6766C8.47555 20.5163 8.36038 20.3077 8.17101 20.3077V20.3077C7.98163 20.3077 7.86646 20.5163 7.96736 20.6766L8.17101 21Z"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path d="M8.38895 7.61539H7.51715V7.84616H8.38895" stroke="currentColor" strokeWidth="0.5" />
            <path
                d="M15.7509 19.1093L15.6691 20.7923L15.6233 19.1093C15.6233 19.1093 15.6175 18.8863 15.6233 18.7434C15.6319 18.5309 15.6516 18.3288 15.6798 18.1388C15.6252 17.8693 15.5445 17.6187 15.449 17.2797C15.3478 16.9205 15.265 16.7221 15.1471 16.3648C15.0291 16.0076 14.9463 15.8091 14.8451 15.45L14.84 15.432L14.84 15.432C14.6832 14.8753 14.593 14.5554 14.5432 13.9863C14.5057 13.5581 14.616 13.3149 14.5432 12.8885C14.4431 12.3024 14.3342 11.9632 13.9393 11.4247C13.66 11.044 13.3659 10.8746 13.0335 10.5099C12.651 10.0903 12.2992 9.88 12.1276 9.41209C11.9741 8.99358 11.9415 8.7279 12.1276 8.31429C12.2664 8.006 12.4565 7.85508 12.7315 7.58242C13.0389 7.27757 13.2836 7.13636 13.6373 6.85055C13.9911 6.56474 14.2048 6.41126 14.5432 6.11868C14.7872 5.9077 14.9636 5.8022 15.1471 5.56978C15.3079 5.36594 15.3429 5.2375 15.449 5.02088C15.4975 4.92178 15.5267 4.78548 15.5521 4.65613C15.5706 4.56184 15.587 4.47125 15.6073 4.40144C15.6123 4.36771 15.6176 4.3303 15.6233 4.28901C15.6304 4.23727 15.6449 4.24278 15.6648 4.28424C15.7217 4.40232 15.8229 4.81185 15.9252 5.02088C16.0313 5.2375 16.0663 5.36594 16.2272 5.56978C16.4106 5.8022 16.5871 5.9077 16.8311 6.11868C17.1694 6.41126 17.3831 6.56474 17.7369 6.85055C18.0906 7.13636 18.3353 7.27757 18.6427 7.58242C18.9177 7.85508 19.1079 8.006 19.2466 8.31429C19.4328 8.7279 19.4001 8.99358 19.2466 9.41209C19.075 9.88 18.7233 10.0903 18.3408 10.5099C18.0083 10.8746 17.7142 11.044 17.435 11.4247C17.04 11.9632 16.9311 12.3024 16.8311 12.8885C16.7583 13.3149 16.8686 13.5581 16.8311 13.9863C16.7812 14.5554 16.6911 14.8753 16.5342 15.432L16.5291 15.45C16.4279 15.8091 16.3451 16.0076 16.2272 16.3648C16.1093 16.7221 16.0487 16.8194 15.8871 17.2797C15.8012 17.5243 15.728 17.8138 15.6798 18.1388C15.7166 18.3208 15.7415 18.5113 15.7509 18.7434C15.7568 18.8863 15.7509 19.1093 15.7509 19.1093Z"
                fill="currentColor"
            />
            <path
                d="M15.6691 3.18461C15.6691 3.18461 15.8227 4.34225 15.7509 4.28901C15.5687 4.15385 15.5873 4.73844 15.449 5.02088C15.3429 5.2375 15.3079 5.36594 15.1471 5.56978C14.9636 5.8022 14.7872 5.9077 14.5432 6.11868C14.2048 6.41126 13.9911 6.56474 13.6373 6.85055C13.2836 7.13636 13.0389 7.27757 12.7315 7.58242C12.4565 7.85508 12.2664 8.006 12.1276 8.31429C11.9415 8.7279 11.9741 8.99358 12.1276 9.41209C12.2992 9.88 12.651 10.0903 13.0335 10.5099C13.3659 10.8746 13.66 11.044 13.9393 11.4247C14.3342 11.9632 14.4431 12.3024 14.5432 12.8885C14.616 13.3149 14.5057 13.5581 14.5432 13.9863C14.593 14.5554 14.6832 14.8753 14.84 15.432L14.8451 15.45C14.9463 15.8091 15.0291 16.0076 15.1471 16.3648C15.265 16.7221 15.3478 16.9205 15.449 17.2797C15.609 17.8475 15.7275 18.1675 15.7509 18.7434C15.7568 18.8863 15.7509 19.1093 15.7509 19.1093L15.6691 20.7923L15.6233 19.1093C15.6233 19.1093 15.6175 18.8863 15.6233 18.7434C15.6468 18.1675 15.7509 17.6673 15.8871 17.2797C16.0487 16.8194 16.1093 16.7221 16.2272 16.3648C16.3451 16.0076 16.4279 15.8091 16.5291 15.45L16.5342 15.432C16.6911 14.8753 16.7812 14.5554 16.8311 13.9863C16.8686 13.5581 16.7583 13.3149 16.8311 12.8885C16.9311 12.3024 17.04 11.9632 17.435 11.4247C17.7142 11.044 18.0083 10.8746 18.3408 10.5099C18.7233 10.0903 19.075 9.88 19.2466 9.41209C19.4001 8.99358 19.4328 8.7279 19.2466 8.31429C19.1079 8.006 18.9177 7.85508 18.6427 7.58242C18.3353 7.27757 18.0906 7.13636 17.7369 6.85055C17.3831 6.56474 17.1694 6.41126 16.8311 6.11868C16.5871 5.9077 16.4106 5.8022 16.2272 5.56978C16.0663 5.36594 16.0313 5.2375 15.9252 5.02088C15.7869 4.73844 15.6507 4.08993 15.6233 4.28901C15.4512 5.53847 15.6233 3.23077 15.6233 3.23077"
                stroke="currentColor"
            />
            <path
                d="M15.2262 17.3146V20.5016C15.2262 20.6661 15.3187 20.8174 15.4645 20.8934C15.5921 20.9599 15.7451 20.9598 15.8722 20.8925C16.0155 20.8167 16.105 20.6679 16.105 20.5058L16.105 17.3121C16.105 17.2122 16.0709 17.1152 16.0083 17.0373C15.8336 16.82 15.5021 16.8198 15.3258 17.0358C15.2616 17.1144 15.2262 17.2131 15.2262 17.3146Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M15.6691 2.99997V2.99997C15.4284 2.99998 15.2332 3.19514 15.2332 3.43588V5.33331C15.2332 5.57405 15.4284 5.76921 15.6691 5.76921V5.76921C15.9099 5.76921 16.105 5.57405 16.105 5.33331V3.43588C16.105 3.19514 15.9099 2.99998 15.6691 2.99997V2.99997ZM15.6691 2.99997L15.4655 3.32341C15.3646 3.48366 15.4798 3.69228 15.6691 3.69228V3.69228C15.8585 3.69228 15.9737 3.48366 15.8728 3.32341L15.6691 2.99997Z"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path d="M15.4512 16.3846H16.323V16.1538H15.4512" stroke="currentColor" strokeWidth="0.5" />
        </svg>
    );
};

export default SeuratMarkerExpressionAnalysisIcon;
