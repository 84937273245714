import { PageLayout } from '@layouts/Layout';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import TextInput from '@components/forms/TextInput';
import { Close, Search } from '@mui/icons-material';
import { useDebounce } from 'react-use';
import Logger from '@util/Logger';
import LoadingMessage from '@components/LoadingMessage';
import { AnalysisCategoryShortname } from '@models/analysis/AnalysisType';
import { ScrollableSidebarContainer } from '../ScrollableSidebarContent';
import cn from 'classnames';
import { ChevronDoubleRightIcon } from '@heroicons/react/outline';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ContentfulSidebarView, { ContentfulLinkTypes } from './contentful/ContentfulSidebarView';
import { ContentfulAnalysis, fetchAnalyses } from '@/src/contentful/analysisInfo';
import LowCodeAnalysisCard from '../LowCodeAnalysisCard';
import ApiTokenForm from '@/src/components/ApiTokenForm';
import copy from '@copy/Copy';
import useAuth from '@/src/hooks/useAuth';
import CopyTextInput from '@/src/components/forms/CopyTextInput';
import { useMouseMoveHoverEffect } from '../../effects/MouseMoveHoverEffect';

const sortByName = (a: ContentfulAnalysis, b: ContentfulAnalysis): number => {
    const nameA = a?.name?.toUpperCase() || '';
    const nameB = b?.name?.toUpperCase() || '';

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};

const formatResults = (analyses: ContentfulAnalysis[]) => {
    const sortedResults: ContentfulAnalysis[] = analyses.sort(sortByName);
    return sortedResults;
};

const logger = Logger.make('LowCodeRecipesView');
const preview = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_MODE === 'true';

type AnalysisReduce = ContentfulAnalysis[];
const LowCodeRecipesView: PageLayout = () => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [analysisCategoryFilter, _] = useState<AnalysisCategoryShortname | null>(null);
    const [filteredResults, setFilteredResults] = useState<ContentfulAnalysis[]>([]);
    const [finalResults, setFinalResults] = useState<ContentfulAnalysis[]>([]);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const [contentfulExpanded, setContentfulExpanded] = useState(false);
    const [codeExpanded, setCodeExpanded] = useState('');
    const [codeExample, setCodeExample] = useState<string>('');
    const [codeExampleFetching, setCodeExampleFetching] = useState<boolean>(false);
    const { user } = useAuth();

    const [selectedAnalysis, setSelectedAnalysis] = useState<ContentfulAnalysis | null>(null);
    const toggleContentfulExpanded = () => setContentfulExpanded(!contentfulExpanded);
    const hasFilters = !!analysisCategoryFilter;
    const showLoading = !finalResults.length && !hasSearched && !hasFilters;
    const showNoResults = !finalResults.length && (hasSearched || hasFilters);

    const [lowCodeAnalyses, setLowCodeAnalyses] = useState<ContentfulAnalysis[]>([]);

    useMouseMoveHoverEffect();

    useEffect(() => {
        const getInfo = async () => {
            const contentfulData = await fetchAnalyses({
                preview: preview,
                category: 'lowcode',
            });
            if (contentfulData) {
                setLowCodeAnalyses(contentfulData);
            }
        };
        getInfo();
    }, []);

    //Unmount to ensure that analyses are fetched each time component is rendered
    useEffect(() => {
        () => setFinalResults([]);
    });

    useEffect(() => {
        if (lowCodeAnalyses.length > 0 && filteredResults?.length === 0 && !hasFilters) {
            setFilteredResults(lowCodeAnalyses);
        }
    }, [lowCodeAnalyses]);

    useDebounce(
        () => {
            const results = filteredResults.reduce<ContentfulAnalysis[]>((acc, analysis) => {
                const matchesDisplayName = analysis?.name?.toLowerCase().includes(searchVal.toLowerCase());
                const matchesCategory = analysis?.category?.toLowerCase().includes(searchVal.toLowerCase());

                if (matchesDisplayName || matchesCategory) {
                    acc.push(analysis);
                }

                return acc;
            }, []);
            const sortedResults = results.length > 0 ? formatResults(results) : [];
            setHasSearched(!!searchVal);
            setFinalResults(sortedResults);
        },
        300,
        [searchVal, filteredResults],
    );

    useEffect(() => {
        if (!analysisCategoryFilter && lowCodeAnalyses?.length > 0) {
            return setFilteredResults(lowCodeAnalyses);
        }

        if (lowCodeAnalyses?.length > 0) {
            const results = lowCodeAnalyses?.reduce<AnalysisReduce>((acc, analysis) => {
                const isSelectedCategory = analysisCategoryFilter && analysis.category !== analysisCategoryFilter;
                if (isSelectedCategory) {
                    acc.push(analysis);
                    return acc;
                }

                return acc;
            }, []);
            setFilteredResults(results);
        }
    }, [analysisCategoryFilter]);

    const handleContentfulExpanded = (analysis: ContentfulAnalysis | null) => {
        if ((contentfulExpanded && analysis?.name === selectedAnalysis?.name) || !analysis) {
            setSelectedAnalysis(null);
            toggleContentfulExpanded();
            return;
        }
        if (contentfulExpanded && analysis?.name !== selectedAnalysis?.name) {
            setSelectedAnalysis(analysis);
            return;
        }
        setSelectedAnalysis(analysis);
        toggleContentfulExpanded();
    };

    const handleCodeExpanded = async (link?: string, type?: ContentfulLinkTypes) => {
        if (type === codeExpanded) {
            return setCodeExpanded('');
        }
        setCodeExpanded(type ?? '');
        if (!Boolean(codeExpanded) && link) {
            try {
                setCodeExampleFetching(true);
                const response = await fetch(link);
                if (!response.ok) {
                    throw new Error("Couldn't fetch code.");
                }
                const data = await response.text();
                setCodeExample(data);
                setCodeExampleFetching(false);
            } catch (error) {
                logger.error(error);
                setCodeExampleFetching(false);
            }
        }
    };

    return (
        <div data-cy="low-code-recipes-view" className="relative h-full w-full">
            <div className="flex flex-[0_0_auto] flex-row justify-end border-b-2 border-b-gray-100 pb-4">
                <TextInput
                    className="w-1/3 pl-3"
                    noMargin
                    value={searchVal}
                    name="search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchVal(e.target.value)}
                    placeholder="Search analyses..."
                    iconRight={
                        <div className="flex">
                            {searchVal && (
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    onClick={() => setSearchVal('')}
                                    size="small"
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            )}
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                type="submit"
                                size="small"
                                color={searchVal ? 'primary' : 'default'}
                            >
                                <Search />
                            </IconButton>
                        </div>
                    }
                    disableFormik
                />
            </div>
            <div className="relative flex h-full w-full flex-1">
                <div className="relative flex h-full flex-1 overflow-auto scroll-smooth">
                    <div
                        className={cn('absolute top-0 transition-all duration-700', {
                            'w-[65%]': contentfulExpanded,
                            'w-full': !contentfulExpanded,
                        })}
                    >
                        <CSSTransition timeout={300} classNames="fade" in={showLoading} unmountOnExit>
                            <div className="flex h-full w-full flex-1 items-center justify-center">
                                <LoadingMessage message="Loading analyses..." immediate />
                            </div>
                        </CSSTransition>
                        <CSSTransition timeout={300} classNames="fade" in={showNoResults} unmountOnExit>
                            <div className="flex flex-1 items-center justify-center pb-12 pt-16">
                                <p>
                                    No analyses found. Need additional help or an analysis not available in Pluto?
                                    Contact our team.
                                </p>
                            </div>
                        </CSSTransition>
                        <TransitionGroup component={null}>
                            <CSSTransition timeout={500} classNames="item" in={true}>
                                <>
                                    <div
                                        className={cn(
                                            'grid flex-auto gap-8 rounded-lg pb-2 pt-4 transition-all duration-200',
                                            {
                                                'grid-cols-3': !contentfulExpanded,
                                                'grid-cols-2': contentfulExpanded,
                                            },
                                        )}
                                    >
                                        {finalResults.map((analysis) => {
                                            return (
                                                <LowCodeAnalysisCard
                                                    analysis={analysis}
                                                    key={analysis?.name}
                                                    onSelect={(analysis) => handleContentfulExpanded(analysis)}
                                                    isSelected={selectedAnalysis?.name === analysis?.name}
                                                />
                                            );
                                        })}
                                    </div>
                                    <p className="pb-14 pt-5 font-semibold tracking-tight">
                                        Don&apos;t see what you&apos;re looking for? We&apos;re constantly adding new
                                        functionality based on your feedback -
                                        <span key="pipeline-error">
                                            <a
                                                href="https://help.pluto.bio"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link mx-1"
                                            >
                                                contact us
                                            </a>
                                        </span>
                                        to share your thoughts!
                                    </p>
                                </>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>

                <ScrollableSidebarContainer
                    className={cn(
                        'absolute right-0 top-0 z-20 h-full w-1/3 border-l-2 border-gray-100 bg-white px-5 py-5 transition-all duration-700',
                        {
                            'translate-x-[120%]': !contentfulExpanded,
                            'delay-0': contentfulExpanded,
                            '-translate-x-[200%]': Boolean(codeExpanded),
                        },
                    )}
                >
                    <CSSTransition timeout={300} classNames="fade" in={contentfulExpanded} unmountOnExit>
                        <>
                            <ContentfulSidebarView
                                contentfulAnalysis={selectedAnalysis}
                                category="lowcode"
                                onViewCode={handleCodeExpanded}
                            />
                            <div
                                className={cn(
                                    'group absolute -left-[55px] top-[50%] z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                                    {
                                        'translate-x-[120%]': contentfulExpanded,
                                        'delay-0': !contentfulExpanded,
                                    },
                                )}
                                onClick={() => {
                                    setCodeExpanded('');
                                    setTimeout(() => handleContentfulExpanded(null), 150);
                                }}
                            >
                                <ChevronDoubleRightIcon
                                    height={20}
                                    width={20}
                                    className={cn(
                                        'text-indigo-500 transition-all duration-300 ease-in-out group-hover:translate-x-[1.5px]',
                                    )}
                                />
                            </div>
                        </>
                    </CSSTransition>
                </ScrollableSidebarContainer>
                <ScrollableSidebarContainer
                    className={cn(
                        'absolute right-0 top-0 z-20 h-full w-2/3 border-l-2 border-gray-100 bg-white px-5 py-5 transition-all duration-700',
                        {
                            'translate-x-0': Boolean(codeExpanded),
                            'translate-x-[105%]': !Boolean(codeExpanded),
                        },
                    )}
                >
                    <CSSTransition timeout={300} classNames="fade" in={Boolean(codeExpanded)} unmountOnExit>
                        <>
                            <div className="flex flex-1 flex-col overflow-auto">
                                {user?.organization && (
                                    <section>
                                        <div className="mb-4">
                                            <h3 className="text-lg font-semibold tracking-tight text-gray-500">
                                                API token
                                            </h3>
                                            <p>
                                                Use the Pluto API to fetch data from your projects and experiments.{' '}
                                                <a
                                                    href={copy.apiTokenHelpUrl}
                                                    target="_blank"
                                                    rel="nofollow noreferrer"
                                                >
                                                    Learn more
                                                </a>
                                            </p>
                                        </div>
                                        <ApiTokenForm disableDelete={true} />
                                    </section>
                                )}
                                <h3 className="mb-1 text-lg font-semibold tracking-tight text-gray-500">
                                    Code example
                                </h3>
                                <CopyTextInput
                                    value={codeExampleFetching ? 'Loading code example ...' : codeExample ?? ''}
                                    inputClasses="font-mono !text-xs"
                                    noMargin
                                    multilineRows={26}
                                    useSyntaxHighlight={true}
                                    language={codeExpanded}
                                />
                            </div>
                            <div
                                className={cn(
                                    'group absolute -left-[55px] top-[50%] z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                                    {
                                        'translate-x-[120%]': Boolean(codeExpanded),
                                        'delay-0': !Boolean(codeExpanded),
                                    },
                                )}
                                onClick={() => setCodeExpanded('')}
                            >
                                <ChevronDoubleRightIcon
                                    height={20}
                                    width={20}
                                    className={cn(
                                        'text-indigo-500 transition-all duration-300 ease-in-out group-hover:translate-x-[1.5px]',
                                    )}
                                />
                            </div>
                        </>
                    </CSSTransition>
                </ScrollableSidebarContainer>
            </div>
        </div>
    );
};

export default LowCodeRecipesView;
