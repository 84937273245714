import * as Yup from 'yup';
import Plot from '@models/Plot';

import { ExperimentAnalysis } from '@/src/models/analysis/ExperimentAnalysis';
import { OverlapAnalysisResponse } from '@/src/models/analysis/OverlapAnalysis';
import { ExperimentAnalysisInput } from '@/src/models/analysis/ExperimentAnalysisInput';
import { AnalysisInputFormValues, OverlapAnalysisInputFormValues } from './AnalysisInputFormTypes';
import { overlapAnalysisInputSchema } from './AnalysisInputFormSchemas';

/**
 * Values needed to initialize the Analysis input form
 */
export type FormSetupParams = {
    plot: Plot;
    input: ExperimentAnalysisInput;
};

/**
 * Values needed to create a new experiment analysis input form
 */
export type FormSetup = { initialValues: AnalysisInputFormValues; schema: Yup.AnySchema };

/**
 * Get the initial conditions for the analysis input form.
 * @param {Plot} plot
 * @param {AssaySummaryAnalysisParameters | DifferentialExpressionAnalysisParameters} analysisParameters
 * @param {Experiment} experiment
 * @return {FormSetup | null} returns null if the experiment type or analysis type is not supported
 */
export const getFormSetup = ({ plot, input }: FormSetupParams): FormSetup | null => {
    if (plot.analysis_type === 'overlap') {
        const initialValues: OverlapAnalysisInputFormValues = {
            name: input?.name ?? '',
            adj_pval_threshold: input?.adj_pval_threshold ?? undefined,
            differential_analysis_id: input?.differential_analysis_id ?? undefined,
            differential_analysis_shortname: input?.differential_analysis_shortname ?? undefined,
            fc_direction: input?.fc_direction ?? undefined,
            fc_lower_threshold: input?.fc_lower_threshold ?? null,
            fc_upper_threshold: input?.fc_upper_threshold ?? null,
            target_genes_format: input?.target_genes_format ?? '',
            targets_biomarker_list_id: input?.targets_biomarker_list_id ?? undefined,
            targets_csv_filename: input?.targets_csv_filename ?? undefined,
            targets_csv: undefined,
            targets: input?.targets ?? undefined,
            input_type: input?.input_type ?? undefined,
        };

        return {
            schema: overlapAnalysisInputSchema,
            initialValues,
        };
    }

    return null;
};

export const validateAnalysisInputsPlotDataReady = (analysis: ExperimentAnalysis | null) => {
    if (analysis) {
        switch (analysis?.analysis_type) {
            case 'overlap':
                const analysisData = analysis as OverlapAnalysisResponse;
                if (analysisData.list_1?.target_genes_format && analysisData.list_2?.target_genes_format) {
                    return true;
                }
                break;
            default:
                return false;
        }
    }
    return false;
};
