import React, { ReactNode } from 'react';
import SortableLegendField from '@components/experiments/plotDisplay/groups/SortableLegendField';
import { StringParameterOption } from '@/src/models/AnalysisParameters';
import { CustomLegendColorItem } from './ControlledCustomLegendColorField';

type Props = {
    customOptionField?: string;
    description?: string;
    editComponent?: (
        selectedItem: StringParameterOption | (CustomLegendColorItem & StringParameterOption),
    ) => ReactNode;
    ignoreDisplayOrder?: boolean;
    ignoreEmpty?: boolean;
    isEditable?: boolean;
    isSortable?: boolean;
    items: StringParameterOption[] | (CustomLegendColorItem & StringParameterOption[]);
    legendPopoverTitle?: string;
    loading?: boolean;
    name?: string;
};
/**
 * Fields to sort the legend
 */
const LegendFieldGroup = ({
    customOptionField,
    description,
    editComponent,
    ignoreDisplayOrder = false,
    ignoreEmpty = false,
    isEditable = true,
    isSortable = false,
    items = [],
    legendPopoverTitle,
    loading,
    name = 'legend_display_order',
}: Props) => {
    return (
        <SortableLegendField
            customOptionField={customOptionField}
            description={description}
            editComponent={editComponent}
            ignoreDisplayOrder={ignoreDisplayOrder}
            ignoreEmpty={ignoreEmpty}
            isEditable={isEditable}
            isSortable={isSortable}
            items={items}
            legendPopoverTitle={legendPopoverTitle}
            loading={loading}
            name={name}
            title={'Legend'}
        />
    );
};

export default LegendFieldGroup;
