import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import PValueToggleField from '@components/experiments/plotDisplay/fields/PValueToggleField';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import FoldChangeToggleField from '@components/experiments/plotDisplay/fields/FoldChangeToggleField';
import FoldChangeFieldGroup from '@components/experiments/plotDisplay/groups/FoldChangeFieldGroup';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Plot from '@models/Plot';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import Experiment from '@models/Experiment';
import VolcanoPlotDisplayOption, {
    DefaultVolcanoPlotDisplayOption,
} from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { CustomLegendColorItem } from '@/src/components/experiments/plotDisplay/groups/ControlledCustomLegendColorField';
import { useFormikContext } from 'formik';
import { DisplayFormValue } from '@models/PlotDisplayOption';
import { getVolcanoPlotThemeColors, getVolcanoWord } from '@components/plots/PlotUtil';
import RangeFieldGroup from '@components/experiments/plotDisplay/groups/RangeFieldGroup';
import PlotlyVolcanoPlotLegend from '@/src/components/analysisCategories/comparative/plots/PlotlyVolcanoPlotLegend';
import useDebouncedResizeObserver from '@/src/hooks/useDebouncedResizeObserver';
import { ResizableContainerContextProvider } from '@/src/contexts/ResizableContainerContext';
import LoadingMessage from '@/src/components/LoadingMessage';
import { useSwitchStyles } from '@/src/components/SwitchStyles';
import { Button, Link, Switch, Tooltip } from '@mui/material';
import SimpleSelectField from '../fields/SimpleSelectField';
import {
    AnalysisParameters,
    BiomarkerListAnalysisParameter,
    DifferentialExpressionAnalysisParameters,
} from '@/src/models/AnalysisParameters';
import NewTabIcon from '@components/icons/custom/NewTabIcon';
import { CSSTransition } from 'react-transition-group';
import ConditionalWrapper from '@/src/components/ConditionalWrapper';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';
import { PlotDisplayShortname } from '@/src/models/PlotDisplayType';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import useBiomarkers from '@/src/hooks/useBiomarkers';

type Props = {
    analysisParameters?: AnalysisParameters | null;
    plot: Plot;
    experiment: Experiment;
    chartType: PlotDisplayShortname;
};
const VolcanoDisplayFields = ({ analysisParameters, plot, experiment, chartType }: Props) => {
    const switchStyles = useSwitchStyles();
    const { values, setFieldValue } = useFormikContext<DisplayFormValue<VolcanoPlotDisplayOption>>();
    const display_type = values.display_type;
    const plotContainerRef = useRef<HTMLDivElement>(null);
    const { size: plotContainerSize } = useDebouncedResizeObserver({ ref: plotContainerRef, useDebounce: true });
    const [showPlotlyLegend, setShowPlotlyLegend] = useState(false);
    const { features } = useOrganizationPermissions();
    const isVolcanoPlotV2 = chartType === 'volcano_plot_v2';
    const { followedLists, followedListsLoading, followedListsError } = useBiomarkers({
        isCuratedLists: true,
    });
    const isFollowedList =
        values.targets_biomarker_list && followedLists.find((l) => l.uuid === values.targets_biomarker_list);
    const [useFollowedLists, setUseFollowedLists] = useState(false);
    const knownListsEnabled = features?.experiment_features?.knownlists_enabled;

    useEffect(() => {
        setTimeout(() => {
            setShowPlotlyLegend(true);
        }, 50);
    }, []);

    useEffect(() => {
        if (!followedListsLoading && followedLists?.length && !followedListsError && isFollowedList) {
            const isFollowedList =
                Boolean(values.targets_biomarker_list) &&
                followedLists.some((l) => l.uuid === values.targets_biomarker_list);
            setUseFollowedLists(isFollowedList);
        }
    }, [followedListsLoading, followedListsError, followedLists, values.targets_biomarker_list]);

    const items = useMemo<CustomLegendColorItem[]>(() => {
        const volcanoColors = getVolcanoPlotThemeColors(values.theme_color);
        const volcanoWord = getVolcanoWord(plot.analysis_type);
        const legendItems: CustomLegendColorItem[] = [
            {
                id: 'increased',
                label: values.custom_legend_json?.['increased'] ?? `Increased ${volcanoWord}`,
                themeColor: volcanoColors.positive.color,
                labelName: '',
            },
            {
                id: 'decreased',
                label: values.custom_legend_json?.['decreased'] ?? `Decreased ${volcanoWord}`,
                themeColor: volcanoColors.negative.color,
                labelName: '',
            },
            {
                id: 'increasedTarget',
                label: values.custom_legend_json?.['increasedTarget'] ?? `Increased labeled ${volcanoWord}`,
                themeColor: volcanoColors.positiveTarget.color,
                labelName: '',
            },
            {
                id: 'decreasedTarget',
                label: values.custom_legend_json?.['decreasedTarget'] ?? `Decreased labeled ${volcanoWord}`,
                themeColor: volcanoColors.negativeTarget.color,
                labelName: '',
            },
            {
                id: 'significantIncreased',
                label: values.custom_legend_json?.['significantIncreased'] ?? `Significantly increased ${volcanoWord}`,
                themeColor: volcanoColors.significantPositive.color,
                labelName: '',
            },
            {
                id: 'significantDecreased',
                label: values.custom_legend_json?.['significantDecreased'] ?? `Significantly decreased ${volcanoWord}`,
                themeColor: volcanoColors.significantNegative.color,
                labelName: '',
            },
            {
                id: 'insignificantIncreased',
                label: values.custom_legend_json?.['insignificantIncreased'] ?? `Increased ${volcanoWord}`,
                themeColor: volcanoColors.insignificantPositive.color,
                lineThemeColor: volcanoColors.significantPositive.color,
                labelName: '',
            },
            {
                id: 'insignificantDecreased',
                label: values.custom_legend_json?.['insignificantDecreased'] ?? `Decreased ${volcanoWord}`,
                themeColor: volcanoColors.insignificantNegative.color,
                lineThemeColor: volcanoColors.significantNegative.color,
                labelName: '',
            },
        ];

        if (values.show_pval_line) {
            legendItems.push({
                id: 'pvalThresholdLine',
                label: 'Adjusted p-value threshold line',
                themeColor: volcanoColors.horizontal.color,
                labelName: '',
            });
        }

        if (values.show_fold_change_lines) {
            legendItems.push({
                id: 'foldChangeThresholdLineLower',
                label: 'Lower fold change threshold line',
                themeColor: volcanoColors.vertical.color,
            });
            legendItems.push({
                id: 'foldChangeThresholdLineUpper',
                label: 'Upper fold change threshold line',
                themeColor: volcanoColors.vertical.color,
            });
        }

        return legendItems;
    }, [
        values.theme_color,
        plot.analysis_type,
        values.show_pval_line,
        values.show_fold_change_lines,
        values.custom_legend_json,
    ]);

    const biomarkerLists: BiomarkerListAnalysisParameter[] = useMemo(
        () => (analysisParameters as DifferentialExpressionAnalysisParameters)?.biomarker_list ?? [],
        [analysisParameters],
    );

    const renderTooltipWrapper = useCallback(
        (children, tooltipText: string) => (
            <Tooltip title={tooltipText ?? ''} placement="top" arrow>
                <div>{children}</div>
            </Tooltip>
        ),
        [experiment],
    );

    const getBiomarkerTooltipText = useCallback(() => {
        if (!features?.experiment_features.biomarkers_enabled) {
            return 'Biomarkers is not currently enabled for your lab space. Reach out to Pluto support to learn more.';
        }
        if ((!knownListsEnabled && !biomarkerLists?.length) || (!useFollowedLists && !biomarkerLists?.length)) {
            return "No biomarkers/targets available for this experiment's organism type";
        }
        if (knownListsEnabled && useFollowedLists && !followedLists?.length) {
            return 'No lists are followed on your organization';
        }
        return '';
    }, [biomarkerLists]);

    const biomarkerSelectDisabled =
        !features?.experiment_features.biomarkers_enabled ||
        (!knownListsEnabled && !biomarkerLists?.length) ||
        (!useFollowedLists && !biomarkerLists?.length) ||
        (useFollowedLists && !followedLists?.length);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <PValueField
                name="pval_fill_threshold"
                tooltipTitle="Set a threshold to determine which points appear filled on the volcano plot"
                showHint
                defaultValue={DefaultVolcanoPlotDisplayOption.pval_fill_threshold}
            />
            <PValueToggleField />
            <FoldChangeFieldGroup />
            <FoldChangeToggleField />
            <section className="mb-8">
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Axis ranges</h4>

                <div className="space-y-4">
                    <RangeFieldGroup
                        startName="x_axis_start"
                        endName="x_axis_end"
                        startLabel={
                            <span>
                                <span className="italic">x</span>-axis start
                            </span>
                        }
                        endLabel={
                            <span>
                                <span className="italic">x</span>-axis end
                            </span>
                        }
                    />
                    <RangeFieldGroup
                        startName="y_axis_start"
                        endName="y_axis_end"
                        startLabel={
                            <span>
                                <span className="italic">y</span>-axis start
                            </span>
                        }
                        endLabel={
                            <span>
                                <span className="italic">y</span>-axis end
                            </span>
                        }
                    />
                </div>
            </section>

            <ThemeFieldGroup hideStyle />

            <section className="mb-8">
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <p className="mb-2 text-xs text-gray-500/90">Click any annotation on the chart below to customize</p>
                <div className={'relative flex h-full w-full justify-center'} ref={plotContainerRef}>
                    <ResizableContainerContextProvider containerRef={plotContainerRef} size={plotContainerSize}>
                        {showPlotlyLegend ? (
                            <PlotlyVolcanoPlotLegend items={items} display={plot.display} />
                        ) : (
                            <LoadingMessage immediate message="Loading legend..." />
                        )}
                    </ResizableContainerContextProvider>
                </div>
            </section>

            <section className="mb-8">
                <div className="form-field !flex flex-row items-center justify-between">
                    <h4 className="text-lg font-semibold tracking-tight text-dark">Show target labels</h4>
                    <Switch
                        sx={switchStyles}
                        checked={values.custom_options_json?.show_target_labels ?? true}
                        name="show_target_labels"
                        onChange={(e) => {
                            setFieldValue('custom_options_json.show_target_labels', e.target.checked);
                        }}
                    />
                </div>
            </section>

            <section>
                <h4 className="text-lg font-semibold tracking-tight text-dark">Labels</h4>
                {isVolcanoPlotV2 ? (
                    <p className="mb-2 text-xs text-gray-500/90">
                        Select a biomarker/target list and/or individual targets to label on the plot
                    </p>
                ) : null}
                <div className="flex flex-col gap-3">
                    {isVolcanoPlotV2 ? (
                        <ConditionalWrapper
                            condition={biomarkerSelectDisabled}
                            wrapper={(children) => renderTooltipWrapper(children, getBiomarkerTooltipText())}
                        >
                            <>
                                <SimpleSelectField
                                    name="targets_biomarker_list"
                                    labelClassName="flex flex-1"
                                    label={
                                        <div className="flex flex-1 items-center justify-between h-6">
                                            <p>Biomarker/target list</p>
                                            {knownListsEnabled ? (
                                                <Button
                                                    className="flex items-center font-semibold tracking-tight text-xs py-1 px-0"
                                                    size="small"
                                                    color="primary"
                                                    variant="text"
                                                    onClick={() => {
                                                        setUseFollowedLists((prev) => !prev);
                                                        setFieldValue('targets_biomarker_list', null);
                                                    }}
                                                >
                                                    <SwitchHorizontalIcon width="14" className="mr-1 text-primary" />
                                                    Switch to {useFollowedLists ? 'org' : 'followed'} lists
                                                </Button>
                                            ) : (
                                                <CSSTransition
                                                    in={Boolean(values.targets_biomarker_list)}
                                                    timeout={300}
                                                    classNames="item"
                                                    unmountOnExit
                                                >
                                                    <Link
                                                        className="flex cursor-pointer items-center font-semibold tracking-tight text-primary hover:opacity-70 no-underline text-xs"
                                                        href={`/biomarker_lists/${values.targets_biomarker_list}`}
                                                        target="_blank"
                                                    >
                                                        <NewTabIcon width="14" className="mr-1 text-primary" />
                                                        Open list
                                                    </Link>
                                                </CSSTransition>
                                            )}
                                        </div>
                                    }
                                    items={[
                                        { label: 'None', value: '' },
                                        ...((useFollowedLists ? followedLists : biomarkerLists)?.map((l) => ({
                                            label: l.name,
                                            value: l.uuid,
                                        })) ?? []),
                                    ]}
                                    placeholder={
                                        knownListsEnabled
                                            ? `Select ${useFollowedLists ? 'followed' : 'org'} list...`
                                            : 'Select list...'
                                    }
                                    value={values.targets_biomarker_list ?? ''}
                                    onChange={(e) => {
                                        const updatedValue = e.target.value;
                                        setFieldValue('targets_biomarker_list', updatedValue || null);
                                    }}
                                    disabled={biomarkerSelectDisabled}
                                />
                                {knownListsEnabled ? (
                                    <CSSTransition
                                        in={Boolean(values.targets_biomarker_list)}
                                        timeout={300}
                                        classNames="item"
                                        unmountOnExit
                                    >
                                        <div>
                                            <Link
                                                className="inline-flex cursor-pointer items-center font-semibold tracking-tight text-primary hover:opacity-70 no-underline text-xs -mt-2"
                                                href={
                                                    useFollowedLists
                                                        ? `/curated-lists/${values.targets_biomarker_list}`
                                                        : `/biomarker_lists/${values.targets_biomarker_list}`
                                                }
                                                target="_blank"
                                            >
                                                <NewTabIcon width="14" className="mr-1 text-primary" />
                                                Open list
                                            </Link>
                                        </div>
                                    </CSSTransition>
                                ) : null}
                            </>
                        </ConditionalWrapper>
                    ) : null}
                    <AsyncTargetPicker
                        experiment={experiment}
                        name="selected_targets"
                        description={
                            display_type === 'volcano_plot_v2'
                                ? 'Paste in a comma-separated or new line-separated list (case sensitive) or lasso-select points on the plot'
                                : 'Paste in a comma-separated or new line-separated list (case sensitive)'
                        }
                    />
                </div>
            </section>
        </>
    );
};

export default VolcanoDisplayFields;
