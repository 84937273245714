import React, { useMemo } from 'react';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import Logger from '@util/Logger';
import { ArrowPlotData, GenericCellData } from '@/src/models/ExperimentData';
import RidgePlotBuilder from './builders/RidgePlotBuilder';
import { CustomPlotStylingOptions } from '../../comparative/plots/PlotlyVolcanoPlotUtil';

const logger = Logger.make('RidgePlotView');

type Props = { customPlotStylingOptions?: CustomPlotStylingOptions | null };

const RidgePlotView = ({ customPlotStylingOptions = null }: Props) => {
    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                logger.debug('rendering ridge plot with size', size);
                const { publicationMode, plot, plotData, experiment, isExportMode } = context;
                _svg.selectAll('g').remove();
                const svg = _svg.append('g');

                const builder = new RidgePlotBuilder({
                    plot,
                    data: plotData as ArrowPlotData<GenericCellData>,
                    svg,
                    _svg,
                    experiment,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    isExportMode,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions ?? null,
                });

                builder.draw();
            },
        [customPlotStylingOptions],
    );
    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default RidgePlotView;
