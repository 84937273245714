import { AnyPlotDisplayOption } from '@models/PlotDisplayOption';
import { isDefined } from '@util/TypeGuards';
import AnalysisType, {
    AnalysisCategoryShortname,
    AnalysisShortname,
    getCategoryForAnalysis,
} from '@models/analysis/AnalysisType';
import { ExperimentAnalysis, isPipelineAnalysis, PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import semverGt from 'semver/functions/gt';
import semverGte from 'semver/functions/gte';
import semverCoerce from 'semver/functions/coerce';
import { PlotDisplayShortname } from '@models/PlotDisplayType';

/**
 * Check if a given field is enabled for a specific pipeline version.
 *
 * Note: this does not currently take into account the AnalysisType or the DisplayType -- it's just a simple check based on field name.
 * @param {number} version
 * @param {"theme_color" | "theme_style" | "uuid" | "plot_title" | "display_type" | "experiment_id" | "created_at" | "updated_at" | "is_full_width" | "y_axis_title" | "y_axis_scale" | "y_axis_start" | "y_axis_end" | "color_points_by" | "shape_points_by" | "show_error_bars" | "error_bars_locations" | "error_bars_option" | "show_data_points" | "show_fold_change_lines" | "fold_change_fill_threshold_upper" | "fold_change_fill_threshold_lower" | "show_pval_line" | "pval_fill_threshold" | "selected_targets" | "summarize_values_by" | "heatmap_scale_color" | "group_display_order" | "x_axis_column" | "x_axis_start" | "x_axis_end" | "y_axis_column" | "show_proportion_of_variance" | "plot_subtitle" | "color_tracks_by_variable_ids" | "adj_p_value_filter" | "nes_filter"} field
 * @returns {boolean}
 */
export const isDisplayOptionFieldEnabled = ({
    version,
    field,
}: {
    version: number | string | null | undefined;
    field: keyof AnyPlotDisplayOption;
}): boolean => {
    const semver = semverCoerce(version);

    switch (field) {
        case 'show_proportion_of_variance':
            return isDefined(semver) && semverGt(semver, '1.0.0');
        default:
            return true;
    }
};

export const isDisplayTypeAvailableForAnalysisVersion = ({
    version,
    analysis_type,
    display_type,
}: {
    version: string | number | null | undefined;
    analysis_type: AnalysisShortname;
    display_type: PlotDisplayShortname;
}): boolean => {
    const category = getCategoryForAnalysis(analysis_type);

    return isDisplayTypeAvailableForCategoryVersion({ version, category, display_type, analysis_type });
};

export const isDisplayTypeAvailableForCategoryVersion = ({
    version,
    category,
    display_type,
    analysis_type,
}: {
    version: string | number | null | undefined;
    category: AnalysisCategoryShortname;
    display_type: PlotDisplayShortname;
    analysis_type: AnalysisShortname;
}): boolean => {
    const currentVersion = semverCoerce(version);
    const requiresVersion = (minVersion: string) => {
        if (!isDefined(currentVersion)) {
            return false;
        }

        return semverGte(currentVersion, minVersion);
    };

    switch (category) {
        case 'comparative':
            switch (display_type) {
                case 'heatmap':
                    if (analysis_type === 'differential_expression' || analysis_type === 'differential_binding') {
                        return requiresVersion('1.1.0');
                    }
            }
        default:
            return true;
    }
};

/**
 * Check to see if an analysis pipeline upgrade is available
 * @param {AnalysisType | null} analysisType
 * @param {BaseAssaySummaryExperimentAnalysis | DifferentialExpressionAnalysis | AssaySummaryAnalysis<number | string> | ImageAnalysis | PrincipalComponentsAnalysis | GeneSetEnrichmentAnalysis | PeakAnalysis | SurvivalAnalysis} analysis
 * @returns {boolean}
 */
export const isPipelineUpgradeAvailable = ({
    analysisType,
    analysis,
}: {
    analysisType: Pick<AnalysisType, 'pipeline_version'> | null | undefined;
    analysis:
        | ExperimentAnalysis
        | Pick<PipelineStatusAnalysis, 'pipeline_version' | 'pipeline_status'>
        | null
        | undefined;
}): boolean => {
    if (!analysis) {
        return false;
    }

    if (!isPipelineAnalysis(analysis) || !isDefined(analysisType?.pipeline_version)) {
        return false;
    }

    const latestSemver = semverCoerce(analysisType?.pipeline_version);
    const analysisSemver = semverCoerce(analysis.pipeline_version ?? '1.0');
    if (!latestSemver || !analysisSemver) {
        return false;
    }
    return semverGt(latestSemver, analysisSemver);
};
