import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import LoadingMessage from '@components/LoadingMessage';
import React from 'react';
import ProseList from '@components/ProseList';
import { UMAPAnalysis } from '@models/analysis/UMAPAnalysis';
import { isEmptyList } from '@util/ObjectUtil';
import useSWR from 'swr';
import { ExperimentData } from '@models/ExperimentData';
import Endpoints from '@services/Endpoints';

type Props = { plot: Plot; experiment: Experiment };
const UMAPAnalysisMethodsSection = ({ plot, experiment }: Props) => {
    const { data: assayData, error: assayDataError } = useSWR<ExperimentData>(() =>
        Endpoints.lab.experiment.assayData({
            experimentId: experiment.uuid,
            limit: 250,
        }),
    );
    const assayDataLoading = !assayData && !assayDataError;
    const targetCount = assayData?.count ?? 0;
    const analysis = plot.analysis as UMAPAnalysis | null;
    const groupNames = analysis?.groups?.map((g) => g.display_name) ?? [];
    const allSamples = isEmptyList(analysis?.groups);
    const loading = assayDataLoading;
    const isCPMNormalized = analysis?.cpm_normalize ?? false;

    return (
        <section>
            <MethodsSectionTitle>Uniform manifold and approximation analysis</MethodsSectionTitle>
            {loading ? (
                <LoadingMessage inline message="Loading methods..." className="mt-2" />
            ) : (
                <div className="space-y-4">
                    Uniform manifold approximation projection (UMAP)<sup>1</sup> was performed by applying the{' '}
                    <span className="font-pre">umap()</span> R function to{' '}
                    <span className="font-semibold">
                        {isCPMNormalized ? 'counts per million (CPM)-normalized values' : 'raw values'}
                    </span>{' '}
                    for all <span className="font-semibold">{targetCount.toLocaleString()}</span> targets in the
                    experiment and{' '}
                    {allSamples ? <span className="font-semibold">all samples</span> : <ProseList items={groupNames} />}
                    . The minimum point distance used was{' '}
                    <span className="font-semibold">{analysis?.min_distance ?? 0.5}</span> and the number of neighbors
                    was set to <span className="font-semibold">{analysis?.n_neighbors ?? 3}</span>.
                </div>
            )}
        </section>
    );
};

export default UMAPAnalysisMethodsSection;
