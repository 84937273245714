import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { PeakAnalysis } from '@models/analysis/PeakAnalysis';
import { IGVData } from '@models/ExperimentData';
import useSWR from 'swr';
import { isDefined } from '@util/TypeGuards';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { useMemo } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import PeakAnalysisFileItem, { IGVFile } from '@components/experiments/PeakAnalysisFileItem';
import LoadingMessage from '@components/LoadingMessage';

const logger = Logger.make('PeakAnalysisFiles');

type Props = { plot: Plot; experiment: Experiment };
const PeakAnalysisFiles = ({ plot, experiment }: Props) => {
    const analysis = plot.analysis as PeakAnalysis;
    const plotId = plot.uuid;
    const { data: plotData, error: plotDataError } = useSWR<IGVData>(() =>
        plot?.analysis && isDefined(plotId)
            ? Endpoints.lab.experiment.plot.data({
                  plotId: plotId,
                  experimentId: experiment.uuid,
              })
            : null,
    );

    if (analysis.analysis_type !== 'peak') {
        return null;
    }

    const loading = !plotData && !plotDataError;
    const fileCount = plotData?.count ?? 0;
    const { files } = useMemo<{ files: IGVFile[] }>(() => {
        logger.info({ plotData });
        const files: IGVFile[] = [];
        plotData?.items?.forEach((track) => {
            if (!track.url) {
                return;
            }
            const pathParts = track.url.split('?')[0]?.split('/');
            const filename = pathParts[pathParts.length - 1];
            const file: IGVFile = {
                id: track.name,
                type: track.type,
                filename,
                url: track.url,
                track_name: track.name,
            };
            files.push(file);
        });

        return { files: files ?? [] };
    }, [plotData?.data_hash]);

    return (
        <div className="space-y-2">
            <p className="text-lg font-semibold text-dark">Files ({fileCount})</p>
            {loading && <LoadingMessage message="Loading files..." immediate />}
            <Flipper flipKey={files.map((i) => i.id).join('')} spring="stiff">
                <div className="max-h-[250px] space-y-2 overflow-y-auto pb-[20px] lg:max-h-[400px]">
                    {files.map((file) => (
                        <Flipped flipId={file.id} key={file.id}>
                            <div>
                                <PeakAnalysisFileItem file={file} />
                            </div>
                        </Flipped>
                    ))}
                </div>
            </Flipper>
        </div>
    );
};

export default PeakAnalysisFiles;
