import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import { AnalysisGroup } from '@models/AnalysisParameters';

/**
 * t-Distributed Stochastic Neighbor Embedding (t-SNE)
 */
export interface TSNEAnalysis extends PipelineStatusAnalysis {
    groups: AnalysisGroup[] | null;
    cpm_normalize: boolean;
    perplexity: number;
    run_pre_pca: boolean;
    tsne_options: string[];
}

export const getMaxPerplexity = ({ sample_count }: { sample_count: number }): number => {
    const max = Math.max((sample_count - 1) / 3, 0);

    return Math.max(0.1, Math.floor(max * 10) / 10);
};
