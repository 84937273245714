import Experiment from '@models/Experiment';
import SampleDataTableView from './SampleDataTableView';
import { MethodsPlotContent } from './methods/MethodsPlotContent';
import ResultsDataTableView from './ResultsDataTableView';
import ExperimentDetailAssayDataTabView from './ExperimentDetailAssayDataTabView';
import { Node } from 'reactflow';

export type Props = {
    experiment: Experiment;
    selectedDataNode?: Node['data'];
};

const DataTableModalView = ({ experiment, selectedDataNode }: Props) => {
    const renderData = () => {
        switch (selectedDataNode.selectType) {
            case 'sample':
                return <SampleDataTableView experiment={experiment as Experiment} />;
            case 'assay':
                return <ExperimentDetailAssayDataTabView experiment={experiment as Experiment} />;
            case 'results':
                return (
                    <ResultsDataTableView experiment={experiment as Experiment} selectedPlot={selectedDataNode.plot} />
                );
            case 'methods':
                return <MethodsPlotContent plot={selectedDataNode.plot} experiment={experiment as Experiment} />;
        }
    };

    return <div className="h-full w-full p-8">{renderData()}</div>;
};

export default DataTableModalView;
