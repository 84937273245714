import React, { useMemo } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import useStatisticalTest from '@hooks/useStatisticalTest';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import BarBoxPlotBuilder from '@components/analysisCategories/summary/plots/builders/BarBoxPlotBuilder';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { processSummaryPlotData } from '@components/plots/PlotUtil';
import { SummaryAnalysisPlotData } from '@models/ExperimentData';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { BaseAssaySummaryExperimentAnalysis } from '@/src/models/analysis/ExperimentAnalysis';

type Props = {
    plot: Plot;
    experiment: Experiment;
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
};

const BarPlotView = ({ plot, experiment, customPlotStylingOptions }: Props) => {
    const { visiblePlotStats } = useStatisticalTest({ experiment, analysis: plot.analysis });
    const analysis = plot.analysis as BaseAssaySummaryExperimentAnalysis | null;

    const { groups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: plot.analysis?.variables,
    });

    const drawChart = useMemo<DrawChartFn>(() => {
        return ({ svgSelection: _svg, size, context, tooltipId }) => {
            const { publicationMode, plot, plotData, analysisParameters, idPrefix } = context;
            _svg.selectAll('g').remove();
            const { getTargetById } = getAnalysisParametersHelpers(analysisParameters);
            const getGroupById = (group_id: number) => {
                return groups.find((g) => g.id === group_id) ?? null;
            };
            const data = plotData as SummaryAnalysisPlotData | null | undefined;
            const processedData = processSummaryPlotData(data);
            if (!processedData) {
                return;
            }
            const svg = _svg.append('g');
            const builder = BarBoxPlotBuilder.make({
                svg,
                plot,
                data: processedData,
                experiment,
                getTargetById,
                getGroupById,
                visiblePlotStats,
                hasErrorBars: true,
                width: size.width,
                height: size.height,
                publicationMode,
                idPrefix,
                tooltipId,
                stylingOptions: customPlotStylingOptions ?? null,
            });

            builder.draw();
        };
    }, [visiblePlotStats, groups, customPlotStylingOptions, analysis?.group_display_order]);

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default BarPlotView;
