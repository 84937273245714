import Experiment, { ExperimentStat, ExperimentStatResponse } from '@models/Experiment';
import PlutoFile from '@models/PlutoFile';
import AssayFileItem from '@components/experiments/wizard/AssayFileItem';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { ReactNode, useEffect, useState } from 'react';
import MoreMenuIconButton, { MoreMenuItem } from '@components/MoreMenuIconButton';
import Endpoints from '@/src/services/Endpoints';
import useApi from '@/src/hooks/useApi';

type Props = {
    editDisabled?: boolean;
    experiment: Experiment;
    files: PlutoFile[];
    hideCount?: boolean;
    moreMenuItems?: MoreMenuItem[];
    onEdit?: () => void;
    onRemove?: (file: PlutoFile) => void;
    title?: ReactNode;
};
const AssayFileList = ({
    editDisabled = false,
    experiment,
    files,
    hideCount = false,
    moreMenuItems = [],
    onEdit,
    onRemove,
    title = 'Uploaded files',
}: Props) => {
    const [experimentStats, setExperimentStats] = useState<{ count: number; items: ExperimentStat[] } | null>(null);
    const api = useApi();

    const fileCount = files.length ?? 0;
    let isBamFile = false;
    files.forEach((file) => {
        if (file.data_type === 'bam' || file.data_type === 'bai') isBamFile = true;
    });

    useEffect(() => {
        if (!experiment) return;
        const fetchExperimentStats = async () => {
            const newStats = await api.get<ExperimentStatResponse>(
                Endpoints.lab.experiment.stats({ experimentId: experiment.uuid }),
            );
            setExperimentStats(newStats.analysis_types);
        };

        fetchExperimentStats();
    }, [experiment]);

    if (!files.length) {
        return null;
    }

    return (
        <div className="space-y-2">
            {!!isBamFile ? (
                <div className="mb-6 flex items-center justify-between">
                    <div className="flex-shrink-0">
                        <p className="mb-3 text-lg font-semibold text-dark">
                            {title} {hideCount ? '' : `(${fileCount})`}
                        </p>
                    </div>
                    <div className="ml-auto flex-shrink-0">
                        <MoreMenuIconButton outlined items={moreMenuItems} />
                    </div>
                </div>
            ) : (
                <p className="text-lg font-semibold text-dark">
                    {title} {hideCount ? '' : `(${fileCount})`}
                </p>
            )}

            <Flipper flipKey={files.map((i) => i.uuid).join('')} spring="stiff">
                <div className="mb-10 h-full space-y-2 overflow-y-auto pb-[20px]">
                    {files.map((file) => (
                        <Flipped flipId={file.uuid} key={file.uuid}>
                            <div>
                                <AssayFileItem
                                    file={file}
                                    experiment={experiment}
                                    onRemove={onRemove ? () => onRemove(file) : undefined}
                                    showCreatedBy={!isBamFile}
                                    showCreatedAt={!!isBamFile}
                                    deleteDisabled={!!experimentStats?.count}
                                    editDisabled={!!experimentStats?.count || editDisabled}
                                    onEdit={onEdit}
                                />
                            </div>
                        </Flipped>
                    ))}
                </div>
            </Flipper>
            {!!experimentStats?.count && (
                <p className="font-semibold tracking-tight">
                    This experiment has already been analyzed. Need to edit the original data?{' '}
                    <a href="https://help.pluto.bio/en/articles/" target="_blank" rel="noreferrer">
                        Contact support
                    </a>
                </p>
            )}
        </div>
    );
};

export default AssayFileList;
