import { Switch } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';
import { useSwitchStyles } from '@components/SwitchStyles';
import { ShowDataPointsConfig } from '@models/PlotConfigs';

const DataPointsToggleField = () => {
    const switchStyles = useSwitchStyles();
    const { values, handleChange } = useFormikContext<ShowDataPointsConfig>();
    return (
        <div className="flex flex-col">
            <h4 className="flex flex-row justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark">
                <span>Points</span>
                <Switch
                    sx={switchStyles}
                    name="show_data_points"
                    checked={values.show_data_points ?? false}
                    onChange={handleChange}
                />
            </h4>
        </div>
    );
};

export default DataPointsToggleField;
