import useSWR from 'swr';
import { ExperimentData, isArrowPlotData } from '@models/ExperimentData';
import DataTable from '@components/dataTable/DataTable';
import Endpoints from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import TextInput from '@components/forms/TextInput';
import { IconButton } from '@mui/material';
import { SearchIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { useMemo, useState } from 'react';
import { formatTableHeader } from '@util/StringUtil';
import Experiment, { ExperimentStatus } from '@models/Experiment';
import { generateAssayFileName } from '@util/ExperimentUtil';
import MoreMenuIconButton, { MoreMenuItem } from '@components/MoreMenuIconButton';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { WizardStepQueryParam } from '@components/experiments/wizard/ExperimentWizardUtils';
import Button from '@components/Button';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { assayHeaderRenderer, geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import { EditIcon } from '@components/icons/custom/EditIcon';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';

export type AssayDataTableViewProps = { experiment: Experiment; showUnits?: boolean; viewDataOnly?: boolean };
const AssayDataTableView = ({ experiment, showUnits = false, viewDataOnly = false }: AssayDataTableViewProps) => {
    const experimentId = experiment.uuid;
    const permissions = useExperimentPermissions(experiment);
    const { setEditDataInterstitialOpen } = useExperimentDetailViewContext();
    const [searchValue, setSearchValue] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const { data, error } = useSWR<ExperimentData>(() =>
        Endpoints.lab.experiment.assayData({
            experimentId,
            limit: 250,
            search: searchTerm,
        }),
    );

    const moreMenuItems = useMemo(() => {
        const downloadFilename = generateAssayFileName({ experiment });

        const downloadEndpoint = (filename: string) =>
            Endpoints.lab.experiment.downloadAssayData({ experimentId: experiment.uuid, filename });

        const items: MoreMenuItem[] = [];

        if (permissions.canEdit) {
            const enabled =
                !experiment?.pipeline_run || experiment.pipeline_run?.pipeline_status?.status !== 'completed';
            items.push({
                label: 'Edit assay data',
                icon: <EditIcon className="h-4 w-4" />,
                href: !enabled
                    ? undefined
                    : appendQueryParams(`/experiments/${experiment.pluto_id}/edit`, {
                          [QueryParam.STEP]: WizardStepQueryParam.DATA_UPLOAD,
                      }),
                onClick: enabled
                    ? undefined
                    : () => {
                          setEditDataInterstitialOpen(true);
                      },
            });
        }

        items.push(
            <DownloadDataMoreMenuItem
                key={'download_csv'}
                endpoint={downloadEndpoint}
                baseFilename={downloadFilename}
                extension=".csv"
            />,
        );
        return items;
    }, [permissions, experiment]);

    const loading = !data && !error;
    const hasItems = (data?.items ?? []).length > 0;

    if (loading && !searchOpen) {
        return (
            <LoadingMessage
                message={searchValue ? `Searching for "${searchTerm.trim()}"` : 'Loading Assay Data...'}
                size={24}
            />
        );
    }

    if (experiment.status === 'copying' && (!data || !hasItems)) {
        return (
            <div className="text-center">
                <p>Your data will appear once the experiment has finished copying</p>
            </div>
        );
    }

    if (!searchOpen && (!data || !hasItems)) {
        return (
            <div className="space-y-0 text-center">
                <div className="">
                    {experiment.status === ExperimentStatus.pending_complete &&
                    (experiment.fastqs_uploaded || experiment.fastqs_uploaded)
                        ? 'Assay data is being processed. Please check back later.'
                        : 'Assay data table has not been uploaded yet'}
                </div>
                {!(experiment.fastqs_uploaded && !experiment.assay_data_uploaded) &&
                    experiment.status !== ExperimentStatus.pending_complete && (
                        <div>
                            <Button
                                href={`/experiments/${experiment.pluto_id}/edit?step=${WizardStepQueryParam.DATA_UPLOAD}`}
                                variant="text"
                                color="primary"
                            >
                                Add assay data
                            </Button>
                        </div>
                    )}
            </div>
        );
    }

    const searchColumnName = formatTableHeader(data?.headers?.[0] ?? 'Gene');

    const closeSearch = () => {
        setSearchOpen(false);
        setSearchTerm('');
        setSearchValue('');
    };

    const toggleSearch = () => {
        if (!searchOpen) {
            setSearchOpen(true);
        } else {
            closeSearch();
        }
    };

    const unitName =
        experiment?.assay_data_units?.units_display_name ?? experiment?.assay_data_units?.units?.display_name;

    const renderData = () => {
        return (
            <>
                {data && hasItems && !isArrowPlotData(data) && (
                    <DataTable
                        data={data}
                        tableColor="bg-violet-200 text-violet-900"
                        headerRenderer={assayHeaderRenderer}
                        cellRenderer={geneLinkCellRenderer({ experiment })}
                        minHeight={'380px'}
                        viewDataOnly={viewDataOnly}
                    />
                )}
                {loading && (
                    <LoadingMessage
                        message={searchValue ? `Searching for "${searchTerm.trim()}"` : 'Loading Assay Data...'}
                        size={24}
                    />
                )}
                {(!data || !hasItems) && !loading && searchTerm && (
                    <div className="pt-8 text-center">
                        No Genes found matching <span className="font-semibold">&quot;{searchTerm.trim()}&quot;</span>
                    </div>
                )}
            </>
        );
    };
    if (viewDataOnly) {
        return renderData();
    }

    return (
        <div>
            <form
                className="mb-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    setSearchTerm(searchValue);
                }}
            >
                <div className="flex items-center justify-end space-x-4">
                    <div className={'w-full flex-grow items-center'}>
                        {searchOpen && (
                            <TextInput
                                value={searchValue ?? ''}
                                name="filter"
                                className="w-full"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                placeholder={`Search in ${searchColumnName}...`}
                                disableFormik
                                noMargin
                                iconRight={
                                    <div className="flex items-center">
                                        {searchValue && (
                                            <IconButton
                                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                                onClick={() => {
                                                    setSearchValue('');
                                                    setSearchTerm('');
                                                }}
                                                aria-label="Close search"
                                                size="large"
                                            >
                                                <XCircleIcon className="h-4 w-4 text-indigo-500" />
                                            </IconButton>
                                        )}
                                        {
                                            <IconButton
                                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                                type="submit"
                                                aria-label="Open search"
                                                size="large"
                                            >
                                                <SearchIcon className="h-4 w-4 text-indigo-500" />
                                            </IconButton>
                                        }
                                    </div>
                                }
                            />
                        )}
                        {unitName && showUnits && !searchOpen && (
                            <p className="text-lg font-semibold text-dark">{unitName}</p>
                        )}
                    </div>

                    <div className="rounded-full border border-indigo-100 bg-white">
                        <IconButton
                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                            onClick={toggleSearch}
                            className="text-indigo-500"
                            size="large"
                        >
                            {searchOpen ? (
                                <XIcon className="h-4 w-4 text-indigo-500" />
                            ) : (
                                <SearchIcon className="h-4 w-4 text-indigo-500" />
                            )}
                        </IconButton>
                    </div>
                    <MoreMenuIconButton outlined items={moreMenuItems} />
                </div>
            </form>

            {renderData()}
        </div>
    );
};
export default AssayDataTableView;
