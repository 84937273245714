import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const StackedBarPlotIcon = ({
    width = 40,
    height = 31,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className}
            viewBox="0 0 40 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.95"
                y="1.76895"
                width="9.5"
                height="27.7692"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="15.25"
                y="1.98845"
                width="9.5"
                height="27.55"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="2"
                y1="23.7885"
                x2="10.45"
                y2="23.7885"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="2"
                y1="19.0385"
                x2="10.45"
                y2="19.0385"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="2"
                y1="9.53845"
                x2="10.45"
                y2="9.53845"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="16.25"
                y1="20.9384"
                x2="24.7"
                y2="20.9384"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="15.3"
                y1="14.2885"
                x2="23.75"
                y2="14.2885"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="16.25"
                y1="5.73845"
                x2="24.7"
                y2="5.73845"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="28.6"
                y1="24.7384"
                x2="37.05"
                y2="24.7384"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="28.6"
                y1="17.1384"
                x2="37.05"
                y2="17.1384"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="29.55"
                y1="10.4884"
                x2="38"
                y2="10.4885"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <rect
                x="28.55"
                y="1.98845"
                width="9.5"
                height="27.55"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
