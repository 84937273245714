import React from 'react';
import { AssayDataUnits } from '@models/Experiment';
import RangeColorGroup from '@components/experiments/plotDisplay/groups/RangeColorGroup';

type Props = {
    endBottomOffsetClassname?: string;
    endColor?: string;
    endLeftOffsetClassname?: string;
    hintText: string;
    noMargin?: boolean;
    startBottomOffsetClassname?: string;
    startColor?: string;
    startLeftOffsetClassname?: string;
} & Pick<AssayDataUnits, 'units' | 'units_display_name'>;
const RangeFieldColorGroup = ({ hintText = 'heatmap', ...props }: Props) => {
    return (
        <div className="form-field">
            <RangeColorGroup
                colorEndName="max_color"
                colorStartName="min_color"
                endLabel="Maximum"
                startLabel="Minimum"
                startHint={<span>Minimum {hintText} color</span>}
                endHint={<span>Maximum {hintText} color</span>}
                {...props}
            />
        </div>
    );
};

export default RangeFieldColorGroup;
