import dynamic from 'next/dynamic';
import { PlotParams } from 'react-plotly.js';
import React, { useMemo, useState } from 'react';
import Logger from '@util/Logger';
import {
    buildPlotlyLayout,
    getVolcanoPlotLineSettings,
    POINT_OPACITY,
    POINT_SIZE,
} from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { PlotDisplayShortname } from '@/src/models/PlotDisplayType';
import { useResizableContainerContext } from '@/src/contexts/ResizableContainerContext';
import EditPlotlyLegendModal from '@/src/components/plots/legendEditor/EditPlotlyLegendModal';
import { CustomLegendColorItem } from '@/src/components/experiments/plotDisplay/groups/ControlledCustomLegendColorField';
import { PlotLegendDisplayFormValues } from '@/src/models/PlotDisplayOption';
import { CustomizablePlotChartDisplayOption } from '@/src/models/plotDisplayOption/BasePlotDisplayOption';
import { useFormikContext } from 'formik';
import { Annotations } from 'plotly.js';

const logger = Logger.make('PlotlyVolcanoPlotLegend');
const Plotly = dynamic(() => import('react-plotly.js'), { ssr: false });

interface CustomLayout extends Partial<Plotly.Layout> {
    annotations?: Array<Partial<Annotations> & { name: string }>;
}

type Props = {
    items?: CustomLegendColorItem[];
    display?: any;
};
const PlotlyVolcanoPlotLegend = ({ items, display: plotDisplay }: Props) => {
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { values, setFieldValue } =
        useFormikContext<Partial<PlotLegendDisplayFormValues & CustomizablePlotChartDisplayOption>>();

    const { size } = useResizableContainerContext();
    const display = useMemo(
        () => ({
            show_fold_change_lines: true,
            fold_change_fill_threshold_upper: 4,
            fold_change_fill_threshold_lower: 0.25,
            show_pval_line: true,
            pval_fill_threshold: 0.005,
            selected_targets: null,
            x_axis_start: -10,
            x_axis_end: 10,
            y_axis_start: 0,
            y_axis_end: 15,
            theme_color: plotDisplay.theme_color,
            theme_style: plotDisplay.theme_style,
            plot_title: null,
            display_type: 'volcano_plot_v2' as PlotDisplayShortname,
            uuid: Math.random().toString(36).substring(7),
        }),
        [plotDisplay],
    );
    const {
        volcanoThemeColors,
        negLog10PvalFillThreshold,
        log2FoldChangeFillThresholdLower,
        log2FoldChangeFillThresholdUpper,
    } = getVolcanoPlotLineSettings(display);

    const { data } = useMemo<{
        data: PlotParams['data'] | null;
    }>(() => {
        try {
            const customOptions = values?.custom_options_json ?? plotDisplay?.custom_options_json ?? {};
            const significantIncreasedPointColor =
                customOptions['significantIncreased']?.fill_color ?? volcanoThemeColors.significantPositive.color;
            const significantDecreasedPointColor =
                customOptions['significantDecreased']?.fill_color ?? volcanoThemeColors.significantNegative.color;

            // Define traces
            const data: PlotParams['data'] = [
                {
                    type: 'scattergl',
                    name: `insignificantDecreased`,
                    x: [-1],
                    y: [1],
                    marker: {
                        symbol: 'circle',
                        color:
                            customOptions['insignificantDecreased']?.fill_color ??
                            volcanoThemeColors.insignificantNegative.color,
                        line: {
                            width: 2,
                            color:
                                customOptions['insignificantDecreased']?.line_color ??
                                volcanoThemeColors.significantNegative.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: POINT_OPACITY * 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
                {
                    type: 'scattergl',
                    name: `insignificantIncreased`,
                    x: [1],
                    y: [1],
                    marker: {
                        symbol: 'circle',
                        color:
                            customOptions['insignificantIncreased']?.fill_color ??
                            volcanoThemeColors.insignificantPositive.color,
                        line: {
                            width: 2,
                            color:
                                customOptions['insignificantIncreased']?.line_color ??
                                volcanoThemeColors.significantPositive.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: POINT_OPACITY * 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
                {
                    type: 'scattergl',
                    name: `decreasedTarget`,
                    x: [-6],
                    y: [6],
                    marker: {
                        symbol: 'circle',
                        color: customOptions['decreasedTarget']?.fill_color ?? volcanoThemeColors.negativeTarget.color,
                        line: {
                            width: 2,
                            color:
                                customOptions['decreasedTarget']?.line_color ?? volcanoThemeColors.negativeTarget.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
                {
                    type: 'scattergl',
                    name: `increasedTarget`,
                    x: [6],
                    y: [6],
                    marker: {
                        symbol: 'circle',
                        color: customOptions['increasedTarget']?.fill_color ?? volcanoThemeColors.positiveTarget.color,
                        line: {
                            width: 2,
                            color:
                                customOptions['increasedTarget']?.line_color ?? volcanoThemeColors.positiveTarget.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
                {
                    type: 'scattergl',
                    name: `significantIncreased`,
                    x: [4],
                    y: [4],
                    marker: {
                        symbol: 'circle',
                        color: significantIncreasedPointColor,
                        line: {
                            width: 2,
                            color:
                                customOptions['significantIncreased']?.line_color ??
                                volcanoThemeColors.significantPositive.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
                {
                    type: 'scattergl',
                    name: `significantDecreased`,
                    x: [-4],
                    y: [4],
                    marker: {
                        symbol: 'circle',
                        color: significantDecreasedPointColor,
                        line: {
                            width: 2,
                            color:
                                customOptions['significantDecreased']?.line_color ??
                                volcanoThemeColors.significantNegative.color,
                        },
                        size: POINT_SIZE * 2,
                        opacity: 100,
                    },
                    mode: 'text+markers',
                    hoverinfo: 'none',
                },
            ];

            return { data };
        } catch (error) {
            logger.error(error);
            return { data: null };
        }
    }, [plotDisplay, values?.custom_options_json]);

    const layoutCfg = buildPlotlyLayout({
        display,
        size,
        publicationMode: false,
        stats: undefined,
        dragMode: false,
        thresholdLineWidth: 3,
        customOptions: values?.custom_options_json ?? plotDisplay?.custom_options_json ?? {},
    });
    const annotationShape: Partial<Plotly.Annotations> = {
        xref: 'x',
        yref: 'y',
        showarrow: true,
        font: {
            size: 12,
            color: '#ffffff',
        },
        align: 'center',
        arrowhead: 1,
        arrowsize: 1,
        arrowwidth: 2,
        arrowcolor: '#374151',
        borderpad: 3,
        bgcolor: '#374151',
        opacity: 0.8,
        captureevents: true,
    };
    const layout: CustomLayout = {
        ...layoutCfg,
        yaxis: {
            rangemode: 'tozero',
            showgrid: false,
            title: undefined,
            titlefont: { color: undefined, size: 0 },
            color: '#000',
            zeroline: true,
            linewidth: 1,
            showticklabels: false,
            type: 'linear',
        },
        xaxis: {
            showgrid: false,
            title: undefined,
            titlefont: { color: undefined, size: 0 },
            color: '#000',
            showticklabels: false,
            type: 'linear',
            range: [-10, 10],
        },
        hovermode: undefined,
        hidesources: true,
        margin: {
            t: 15,
            r: 5,
            l: 5,
            b: 15,
        },
        annotations: [
            {
                ...annotationShape,
                name: 'foldChangeThresholdLineUpper',
                x: log2FoldChangeFillThresholdUpper,
                y: 7,
                text: 'Upper fold change<br />threshold line',
                ax: 30,
                ay: -30,
                xshift: 4,
            },
            {
                ...annotationShape,
                name: 'foldChangeThresholdLineLower',
                x: log2FoldChangeFillThresholdLower,
                y: 7,
                text: 'Lower fold change<br />threshold line',
                ax: -30,
                ay: -30,
                xshift: -4,
            },
            {
                ...annotationShape,
                x: 7,
                name: 'pvalThresholdLine',
                y: negLog10PvalFillThreshold,
                text: 'Adjusted <i>p</i>-value<br />threshold line',
                ax: 0,
                ay: -30,
                yshift: 3,
            },
            {
                ...annotationShape,
                name: 'insignificantDecreased',
                x: -1,
                y: 1,
                text: 'Insignificant down',
                ax: -30,
                ay: -20,
                yshift: 7,
                xshift: -7,
            },
            {
                ...annotationShape,
                name: 'insignificantIncreased',
                x: 1,
                y: 1,
                text: 'Insignificant up',
                ax: 30,
                ay: -20,
                yshift: 7,
                xshift: 7,
            },
            {
                ...annotationShape,
                name: 'significantDecreased',
                x: -4,
                y: 4,
                text: 'Significant down',
                ax: -20,
                ay: -20,
                yshift: 7,
                xshift: -7,
            },
            {
                ...annotationShape,
                name: 'significantIncreased',
                x: 4,
                y: 4,
                text: 'Significant up',
                ax: 20,
                ay: -20,
                yshift: 7,
                xshift: 7,
            },
            {
                ...annotationShape,
                name: 'decreasedTarget',
                x: -6,
                y: 6,
                text: 'Labeled down',
                ax: -20,
                ay: -20,
                yshift: 7,
                xshift: -7,
            },
            {
                ...annotationShape,
                name: 'increasedTarget',
                x: 6,
                y: 6,
                text: 'Labeled up',
                ax: 20,
                ay: -20,
                yshift: 7,
                xshift: 7,
            },
        ],
    };

    const setOptions = ({ groupId, options }: { groupId: number | string; options: any }) => {
        setFieldValue(`custom_options_json.${groupId}`, options);
    };
    const setLegend = ({ legend }: { legend: Record<string, string> | null }) => {
        setFieldValue('custom_legend_json', {
            ...values.custom_legend_json,
            ...legend,
        });
    };

    return (
        <>
            <div className="relative flex h-[350px] w-full justify-center">
                <Plotly
                    data={data as PlotParams['data']}
                    layout={layout}
                    useResizeHandler={false}
                    onClickAnnotation={(e) => {
                        if (!e || !e.annotation) {
                            logger.debug('[annotation click]', 'no annotation event in the event handler');
                            return;
                        }
                        logger.debug('[annotation click]', e);
                        const { annotation } = e;

                        const selectedItem = (items ?? []).find(
                            (item) => item.id === (annotation as Annotations & { name: string })?.name,
                        );
                        if (selectedItem) {
                            setSelectedItem(selectedItem);
                            setOpenModal(true);
                        }
                    }}
                    config={{
                        displayModeBar: false,
                        autosizable: false,
                        doubleClick: false,
                    }}
                />
            </div>
            <EditPlotlyLegendModal
                onSave={({ groupId, options, legend }) => {
                    setOptions({ groupId, options });
                    setLegend({ legend });
                }}
                selectedItem={selectedItem}
                open={openModal}
                onClose={() => setOpenModal(false)}
            />
        </>
    );
};

export default PlotlyVolcanoPlotLegend;
