import cn, { Argument as ClassValue } from 'classnames';
import { ComparativeHeatmapColorScale, SummaryHeatmapColorScale, HeatmapTypes, ThemeColor } from '@models/PlotConfigs';
import {
    getComparativeHeatmapColorScale,
    getSummaryHeatmapColorScale,
    getPlotPalette,
} from '@components/ColorPaletteUtil';
import { PaletteColor } from '@components/PaletteColors';
import { useMemo } from 'react';

type Props = {
    colors: PaletteColor[];
    classNames?: ClassValue;
    className?: string;
    gradient?: boolean;
    gradientAngle?: number;
};
export const ColorPalette = ({ colors, classNames, className, gradient = false, gradientAngle = 90 }: Props) => {
    const colorsToLoop = useMemo(() => {
        let colorsToLoop = [...colors];
        if (gradient) {
            colorsToLoop = colorsToLoop.slice(0, -1);
        }
        return colorsToLoop;
    }, [colors, gradient]);

    return (
        <div
            className={cn(
                'flex w-full flex-row overflow-hidden rounded-xl border-y border-gray-200',
                classNames,
                className,
            )}
        >
            {colorsToLoop.map((color, i) => {
                const nextColor = colors[i + 1];

                return (
                    <span
                        key={`color_${i}`}
                        className={cn('h-8 flex-grow first:rounded-l-xl last:rounded-r-xl', {
                            [color.bg]: !gradient,
                        })}
                        style={
                            !gradient
                                ? undefined
                                : {
                                      background: `linear-gradient(${gradientAngle}deg, ${color.color} 0%, ${nextColor?.color} 100%)`,
                                  }
                        }
                    />
                );
            })}
        </div>
    );
};
type GradientBlockProps = { colors: string[]; classNames?: ClassValue; className?: string; gradientAngle?: number };
export const GradientBlock = ({ colors, classNames, className, gradientAngle = 0 }: GradientBlockProps) => {
    let colorString = `linear-gradient(${gradientAngle}deg, ${colors[0]} 0%, ${colors[0]} 100%)`;
    const colorCount = colors.length;

    if (colorCount === 2) {
        colorString = `linear-gradient(${gradientAngle}deg, ${colors[0]} 0%, ${colors[1]} 100%)`;
    }
    if (colorCount === 3) {
        colorString = `linear-gradient(${gradientAngle}deg, ${colors[0]} 0%, ${colors[1]} 50%, ${colors[2]} 100%)`;
    }

    return (
        <div className={cn('flex w-6 flex-row', classNames, className)}>
            <span
                className={cn('h-28 flex-grow')}
                style={{
                    background: colorString,
                }}
            />
        </div>
    );
};

export const ThemeColorPalette = ({ color }: { color: ThemeColor }) => {
    const { display: colors } = getPlotPalette(color);
    return <ColorPalette colors={colors} />;
};

const getHeatmapColorScale = (
    type: HeatmapTypes,
    heatmapColor: ComparativeHeatmapColorScale | SummaryHeatmapColorScale,
) => {
    if (type === 'comparative') {
        return getComparativeHeatmapColorScale(heatmapColor as ComparativeHeatmapColorScale);
    }
    return getSummaryHeatmapColorScale(heatmapColor as SummaryHeatmapColorScale);
};

export const HeatmapColorScaleItem = ({
    heatmapColor,
    className,
    type,
}: {
    heatmapColor: ComparativeHeatmapColorScale | SummaryHeatmapColorScale;
    className?: string;
    type: HeatmapTypes;
}) => {
    const colors = getHeatmapColorScale(type, heatmapColor);
    return <ColorPalette colors={colors} className={className} gradient />;
};

export const PaletteCool = () => {
    const { colors: colors } = getPlotPalette(ThemeColor.cool);
    return <ColorPalette colors={colors} />;
};

export const PaletteWarm = () => {
    const { colors: colors } = getPlotPalette(ThemeColor.warm);
    return <ColorPalette colors={colors} />;
};

export const PaletteGray = () => {
    const { colors: colors } = getPlotPalette(ThemeColor.gray);
    return <ColorPalette colors={colors} />;
};

export const PaletteBatlow = () => {
    const { colors: colors } = getPlotPalette(ThemeColor.batlow);
    return <ColorPalette colors={colors} />;
};

export const PaletteRoma = () => {
    const { colors: colors } = getPlotPalette(ThemeColor.roma);
    return <ColorPalette colors={colors} />;
};
