import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const PrincipalComponentsAnalysisIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    style,
}: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m5 7.713 10 1.928v9.641L5 17.354v-9.64Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 16 4 1V6.785L9 4.857V8"
            />
            <rect width="22" height="22" x="1" y="1" stroke="currentColor" strokeWidth="2" rx="2" />
            <path
                stroke="currentColor"
                fill="currentColor"
                strokeWidth=".25"
                d="M10.273 12.328c.081.087.133.215.133.408 0 .182-.058.295-.16.36-.107.066-.282.087-.547.038l-.174-.033v-1.034l.288.053c.233.044.378.12.46.208Zm-.484 1.824c.545.101.984.055 1.3-.159.32-.216.473-.582.473-1.076 0-.474-.143-.877-.449-1.189-.298-.307-.723-.503-1.254-.601l-.769-.143a.58.58 0 0 0-.713.593v3.27c0 .322.257.63.574.69a.468.468 0 0 0 .574-.478v-.956l.264.05Z"
            />
        </svg>
    );
};
