import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const DifferentialExpressionIcon = ({
    width = 33,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6868 17.5893V6.37012M15.6868 6.37012L13.4458 8.91806M15.6868 6.37012L17.9277 8.91806"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.31324 6.49176L8.31324 17.7109M8.31324 17.7109L10.5542 15.163M8.31324 17.7109L6.07227 15.163"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
        </svg>
    );
};
