import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import React from 'react';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import GeneSetEnrichmentAnalysisMethodsSection from '@components/experiments/methods/analyses/GeneSetEnrichmentAnalysisMethodsSection';
import GSEATableMethodsSection from '@components/experiments/methods/displays/GSEATableMethodsSection';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { useRankMethodInfo } from '@hooks/useRankMethod';
import Bold from '@components/elements/Bold';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const EnrichmentPlotMethods = ({ plot, experiment, analysisParameters }: Props) => {
    const analysis = plot.analysis as GeneSetEnrichmentAnalysis;
    const { rankMethodInfo } = useRankMethodInfo(analysis.rank_method);
    const controlGene =
        analysis?.differential_analysis?.control_group_name || analysis?.differential_analysis?.group1_name;
    const experimentalGene =
        analysis?.differential_analysis?.experimental_group_name || analysis?.differential_analysis?.group2_name;

    return (
        <div className="space-y-6">
            <GeneSetEnrichmentAnalysisMethodsSection
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
            <GSEATableMethodsSection analysis={analysis} />
            <section>
                <MethodsSectionTitle>Enrichment plot</MethodsSectionTitle>
                <p>
                    An enrichment plot is shown for the <Bold>{analysis.gene_set?.display_name ?? 'selected'}</Bold>{' '}
                    gene set. This plot shows the genes ranked by <Bold>{rankMethodInfo.formattedDisplayName}</Bold>{' '}
                    along the x-axis with the vertical ticks representing the location of the genes in this gene set.
                    The heatmap displays the expression of the genes, red being more expressed in the first group (
                    <Bold>{experimentalGene}</Bold>) and blue more expressed in the second group (
                    <Bold>{controlGene}</Bold>
                    ). The green line shows the enrichment score.
                </p>
            </section>
        </div>
    );
};

export default EnrichmentPlotMethods;
