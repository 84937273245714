import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

type Props = { children: ReactNode | undefined } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const ScrollableSidebarContainer = ({ children, className, ...props }: Props) => {
    return (
        <div className={cn('flex h-full flex-col', className)} {...props}>
            {children}
        </div>
    );
};

export const ScrollableSidebarContent = ({ children, className, ...props }: Props) => {
    return (
        <div className={cn('flex flex-col overflow-auto', className)} {...props}>
            <div className="flex h-full flex-col">
                <div className="h-full pb-8">{children}</div>
            </div>
        </div>
    );
};

export const ScrollableSidebarFooter = ({ children, className }: Props) => {
    return <div className={cn('px-8 pb-8 pt-4', className)}>{children}</div>;
};
