import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import SectionLabel from '@components/filters/labels/SectionLabel';
import CheckboxField from '@components/forms/CheckboxField';
import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import HeatmapColorScaleField from '@components/experiments/plotDisplay/fields/HeatmapColorScaleField';

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const ImageHeatmapDisplayFields = ({ plot }: Props) => {
    // const switchStyles = useSwitchStyles();
    // const { setFieldValue, values } = useFormikContext<ImageHeatmapDisplayOption>();
    // const [groupsOpen, setGroupsOpen] = useState((values.color_by_variables ?? []).length > 0);
    return (
        <div className="space-y-6">
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} label="Plot title" />
            <section>
                <SectionLabel>Dendrograms</SectionLabel>
                <div className="space-y-2">
                    <CheckboxField name="cluster_samples" label="Cluster samples" />
                    <CheckboxField name="cluster_targets" label="Cluster targets" />
                </div>
            </section>
            {/* @TODO: params for annotating groups aren't getting sent to the science pipeline yet */}
            {/* <section>
                <h4 className="flex flex-row justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark">
                    <span>Annotate groups</span>
                    <Switch
                        sx={switchStyles}
                        checked={groupsOpen}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setGroupsOpen(checked);
                            if (!checked) {
                                setFieldValue('color_by_variables', []);
                            }
                        }}
                    />
                </h4>
                <Collapse in={groupsOpen}>
                    <AnalysisVariablesMultiSelectField
                        variablesName="color_by_variables"
                        variables={analysisParameters?.variables ?? []}
                        label={'Variables'}
                        disableGroups
                    />
                </Collapse>
            </section> */}
            <section>
                <HeatmapColorScaleField />
            </section>
        </div>
    );
};

export default ImageHeatmapDisplayFields;
