import Plot from '@models/Plot';
import { TextAnalysis } from '@models/analysis/TextAnalysis';
import TextDisplayOption from '@models/plotDisplayOption/TextDisplayOption';
import { isNotBlank } from '@util/StringUtil';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { isDefined, isString } from '@util/TypeGuards';
import ProseList from '@components/ProseList';
import React from 'react';
import { AnalysisParameters } from '@models/AnalysisParameters';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const TextPlotMethods = ({ plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as TextAnalysis | null;
    const display = plot.display as TextDisplayOption | null;

    const { getTargetById } = getAnalysisParametersHelpers(analysisParameters);

    const targetNames =
        analysis?.targets.map((id) => (isString(id) ? id : getTargetById(id)?.display_name)).filter(isDefined) ?? [];

    return (
        <div className="space-y-4">
            {analysis?.methods && isNotBlank(analysis.methods) ? (
                <div className="whitespace-pre-line">{analysis.methods}</div>
            ) : (
                <div>No methods have been provided.</div>
            )}

            {targetNames.length > 0 && (
                <div>
                    This analysis is associated with the targets: <ProseList items={targetNames} />
                </div>
            )}

            {display?.attribution_label && isNotBlank(display.attribution_label) && (
                <div className="">
                    <MethodsSectionTitle>Attribution</MethodsSectionTitle>
                    <p>
                        <span>{display.attribution_label}</span>
                    </p>
                    <p>
                        {display?.attribution_url && isNotBlank(display.attribution_url) && (
                            <a href={display.attribution_url} target="_blank" rel="noreferrer nofollow">
                                <span>{display.attribution_url}</span>
                            </a>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};

export default TextPlotMethods;
