import { CellProps } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import React from 'react';
import { truncateText } from '@/src/util/textUtil';

const DefaultTableCell = ({ value }: CellProps<GenericCellData>) => {
    return <>{truncateText(String(value), 100)}</>;
};

export default DefaultTableCell;
