import { useEffect, useRef, useState } from 'react';

const useOverlapAnalysisInputsSubmit = () => {
    const [inputExpanded, setInputExpanded] = useState<string | null>(null);
    const [pingSave, setPingSave] = useState<string | null>(null);
    const inputSaveButtonRef = useRef<HTMLDivElement>(null);

    const scrollToInputSaveButton = () => {
        if (inputSaveButtonRef.current) {
            inputSaveButtonRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }

        // Wait for the scroll to complete before pinging the save button
        setTimeout(() => {
            setPingSave(inputExpanded);
        }, 250);
    };

    useEffect(() => {
        if (pingSave) {
            const timeout = setTimeout(() => setPingSave(null), 1800); // Stop the animation after 2 loops
            return () => clearTimeout(timeout);
        }
    }, [pingSave]);

    return {
        inputSaveButtonRef,
        inputExpanded,
        setInputExpanded,
        pingSave,
        scrollToInputSaveButton,
        setPingSave,
    };
};

export default useOverlapAnalysisInputsSubmit;
