import React from 'react';
import SimpleSelectField, { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import { useFormikContext } from 'formik';
import { PlotStatisticSelectOptions, PlotStatisticConfig } from '@models/PlotConfigs';

type Props = {
    placeholder?: string;
    label?: string;
    items?: ChoiceItem[];
};
const PlotStatisticSelectField = ({ placeholder = 'Select a plot statistic', items, label }: Props) => {
    const { values, handleChange } = useFormikContext<PlotStatisticConfig>();
    return (
        <div className="form-field">
            <SimpleSelectField
                name="plot_statistic"
                label={label ?? 'Plot Statistic'}
                placeholder={placeholder}
                value={values.plot_statistic}
                onChange={handleChange}
                items={items || PlotStatisticSelectOptions}
            />
        </div>
    );
};

export default PlotStatisticSelectField;
