import { useEffect, useState } from 'react';
import ButtonGroup, { ButtonGroupItem } from '../../ButtonGroup';
import { NoSsr } from '@mui/material';
import PlutoErrorBoundary from '../../PlutoErrorBoundary';
import cn from 'classnames';
import { NestedPreprocessPlot, PreprocessPlot } from '@models/PreprocessStep';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';
import LoadingMessage from '../../LoadingMessage';

type Props = {
    plots: PreprocessPlot[];
};
const PreprocessPlotContentView = ({ plots }: Props) => {
    const [iframeLoading, setIframeLoading] = useState(false);
    const dataTableViewItems: ButtonGroupItem[] = plots.map((plot) => ({
        value: plot,
        label: plot.display_name,
    }));
    const {
        getPlotData,
        getPlotSignedUrl,
        plotData,
        plotSignedUrl,
        plotSignedUrlLoading,
        setPlotData,
        setPlotSignedUrl,
    } = useExperimentWorkflowContext();

    const [selectedPlot, setSelectedPlot] = useState<PreprocessPlot>(plots[0]);
    const [selectedNestedPlot, setNestedPlot] = useState<PreprocessPlot | null>(null);

    useEffect(() => {
        if (selectedPlot?.has_plot) {
            getPlotSignedUrl(selectedPlot?.uuid);
        } else if (plotSignedUrl) {
            setPlotSignedUrl(null);
        }

        if (selectedPlot?.has_results) {
            getPlotData(selectedPlot?.uuid);
        } else if (plotData) {
            setPlotData(null);
        }

        if (selectedPlot.tabs) {
            setNestedPlot((selectedPlot as NestedPreprocessPlot)?.tabs?.[0] ?? null);
        } else {
            setNestedPlot(null);
        }
    }, [selectedPlot]);

    useEffect(() => {
        if (selectedNestedPlot?.has_plot) {
            getPlotSignedUrl(selectedNestedPlot?.uuid);
        } else if (plotSignedUrl) {
            setPlotSignedUrl(null);
        }

        if (selectedNestedPlot?.has_results) {
            getPlotData(selectedNestedPlot?.uuid);
        } else if (plotData) {
            setPlotData(null);
        }
    }, [selectedNestedPlot]);

    const getTabViewPlot = () => {
        return (
            <PlutoErrorBoundary>
                <NoSsr>
                    <div className="mt-6 flex h-full w-full justify-center">
                        {plotSignedUrlLoading ? (
                            <LoadingMessage message="Loading Plot..." />
                        ) : plotSignedUrl ? (
                            <iframe
                                src={plotSignedUrl}
                                width="100%"
                                height="100%"
                                onLoad={() => setIframeLoading(false)}
                                className={cn('rounded-xl', { hidden: iframeLoading })}
                            />
                        ) : (
                            <p className="flex w-full items-center justify-center">No plot available.</p>
                        )}
                    </div>
                </NoSsr>
            </PlutoErrorBoundary>
        );
    };
    const getNestedTabViewPlot = () => {
        const nestedTableViewItems: ButtonGroupItem[] =
            (selectedPlot as NestedPreprocessPlot)?.tabs?.map((plot) => ({
                value: plot,
                label: plot.display_name,
            })) ?? [];

        return (
            <>
                <div className="w-full md:relative md:z-10 md:mx-auto md:flex md:items-center md:justify-center">
                    <div className="flex overflow-x-auto">
                        <ButtonGroup
                            items={nestedTableViewItems}
                            onChange={(screen) =>
                                setNestedPlot(screen ?? (selectedNestedPlot?.tabs ? selectedNestedPlot.tabs[0] : null))
                            }
                            toggle={false}
                            value={selectedNestedPlot}
                            className="overflow-x-auto"
                        />
                    </div>
                </div>
                {getTabViewPlot()}
            </>
        );
    };
    return (
        <>
            <div className="w-full md:relative md:z-10 md:mx-auto md:flex md:items-center md:justify-center">
                <div className="flex overflow-x-auto">
                    <ButtonGroup
                        items={dataTableViewItems}
                        onChange={(screen) => setSelectedPlot(screen ?? plots[0])}
                        toggle={false}
                        value={selectedPlot}
                        className="overflow-x-auto"
                    />
                </div>
            </div>
            <div className="mt-6 flex h-full flex-col">
                {selectedNestedPlot ? getNestedTabViewPlot() : getTabViewPlot()}
            </div>
        </>
    );
};

export default PreprocessPlotContentView;
