import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import Experiment from '@models/Experiment';
import useCanvasNodes from '../hooks/useCanvasNodes';
import useInitializeCanvas from '../hooks/useInitializeCanvas';
import useCanvasEdges, { CustomEdge } from '../hooks/useCanvasEdges';
import { useRouter } from 'next/router';
import {
    DefaultEdgeOptions,
    Edge,
    Node,
    OnEdgesChange,
    OnNodesChange,
    useReactFlow,
    Viewport,
    XYPosition,
} from 'reactflow';
import { StateSetter } from './ContextTypes';
import Plot from '../models/Plot';

export type ContextType = {
    canvasLoaded: boolean;
    canvasLoading: boolean;
    canvasNodes: Node[];
    canvasNodesError: string;
    clearPanelChildNodes: (node: Node) => void;
    defaultEdgeOptions: DefaultEdgeOptions;
    defaultViewport: Viewport | undefined;
    deleteModalOpen: 'node' | 'edge' | '';
    edges: Edge[];
    edgesError: string;
    handleDeleteElement: (deleteType: 'node' | 'edge' | '') => void;
    handleSelectTextNodeToEdit: (id: string) => void;
    handleUpdateEdge: (edge: CustomEdge) => void;
    handleUpdateNode: (node: Node) => void;
    helperLineHorizontal: number | undefined;
    helperLineVertical: number | undefined;
    isCanvasInUrl: boolean;
    newNodeType: string | null;
    onDragOver: (_: React.DragEvent) => void;
    onDrop: (_: React.DragEvent) => void;
    onEdgesChange: OnEdgesChange;
    onNodeDrag: (_: React.MouseEvent, node: Node) => void;
    onNodeDragStop: (_: React.MouseEvent, node: Node) => void;
    onNodesChange: OnNodesChange;
    onSelectTargetAnalysisNode: (id: string) => void;
    postNewNode: (nodeData: Node['data']) => void;
    saveNodes: (nodes: Node[]) => void;
    selectedEdge: CustomEdge | null;
    selectedNode: Node | null;
    selectedTargetAnalysisNode: Node | null;
    setActivePlot: StateSetter<Plot | null>;
    setCanvasNodes: StateSetter<Node[]>;
    setDeleteModalOpen: StateSetter<'node' | 'edge' | ''>;
    setEdges: StateSetter<Edge[]>;
    setNewNodeLocation: StateSetter<XYPosition | null>;
    setNewNodeType: Dispatch<SetStateAction<string | null>>;
    setSelectedEdge: Dispatch<SetStateAction<Edge | null>>;
    setSelectedNode: Dispatch<SetStateAction<Node | null>>;
    setSelectedTargetAnalysisNode: StateSetter<Node | null>;
    setTextNodeToEdit: StateSetter<Node | null>;
    textNodeToEdit: Node | null;
};
const ExperimentCanvasContext = createContext<ContextType | null>(null);

export const useExperimentCanvasContext = () => {
    const context = useContext(ExperimentCanvasContext);
    if (!context) {
        throw new Error(
            'ExperimentCanvasContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

export const useOptionalExperimentCanvasContext = (): Partial<ContextType> => {
    const context = useContext(ExperimentCanvasContext);
    if (!context) {
        return {};
    }
    return context;
};

ExperimentCanvasContext.displayName = 'ExperimentCanvasContext';

export const ExperimentCanvasContextProvider = ({
    children,
    experiment,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
}) => {
    const router = useRouter();

    const isCanvasInUrl = useMemo(() => router.asPath?.includes('canvas'), [router.asPath]);
    const { deleteElements } = useReactFlow();
    const [deleteModalOpen, setDeleteModalOpen] = useState<'node' | 'edge' | ''>('');

    const {
        canvasLoading,
        canvasLoaded,
        defaultViewport,
        edges,
        canvasNodes,
        onEdgesChange,
        setEdges,
        setCanvasNodes,
    } = useInitializeCanvas({ experiment_id: experiment?.uuid, isCanvasInUrl });
    const { defaultEdgeOptions, selectedEdge, setSelectedEdge, handleUpdateEdge, edgesError } = useCanvasEdges({
        experiment_id: experiment?.uuid,
        edges,
        setEdges,
        canvasLoaded,
    });
    const {
        canvasNodesError,
        handleSelectTextNodeToEdit,
        handleUpdateNode,
        helperLineHorizontal,
        helperLineVertical,
        newNodeType,
        onDragOver,
        onDrop,
        onNodeDrag,
        onNodeDragStop,
        onNodesChange,
        onSelectTargetAnalysisNode,
        postNewNode,
        saveNodes,
        selectedNode,
        selectedTargetAnalysisNode,
        setActivePlot,
        setNewNodeLocation,
        setNewNodeType,
        setSelectedNode,
        setSelectedTargetAnalysisNode,
        setTextNodeToEdit,
        textNodeToEdit,
    } = useCanvasNodes({ experiment_id: experiment?.uuid, canvasLoaded, canvasNodes, setCanvasNodes });

    const clearPanelChildNodes = (node: Node) => {
        const updatedPanelNode = {
            ...node,
            data: { ...node.data, plotIds: [], spannedCells: [], lastAdjustedIndex: 0, arrangement: null },
        }; // reset to default
        const newNodes = canvasNodes
            .filter((n) => n?.parentNode !== node.id)
            .map((n) => (n.id === node.id ? updatedPanelNode : n));
        setSelectedNode(updatedPanelNode);
        setCanvasNodes(newNodes);
    };

    const deleteChildNodeFromParent = (parentNodeId: string, childNodeId: string) => {
        const parentNode = canvasNodes.find((n) => n.id === parentNodeId);
        if (parentNode) {
            const updatedPanelNode = {
                ...parentNode,
                data: {
                    ...parentNode.data,
                    plotIds: parentNode.data.plotIds.filter((id) => id !== childNodeId),
                    spannedCells: parentNode.data.spannedCells.filter((id) => id !== childNodeId),
                },
            };
            setCanvasNodes((prev) => prev.map((n) => (n.id === parentNodeId ? updatedPanelNode : n)));
        }
    };

    const handleDeleteElement = (deleteType: 'node' | 'edge' | '') => {
        if (!deleteType) return;
        if (deleteType === 'node') {
            if (!selectedNode) return;
            deleteElements({ nodes: [{ id: selectedNode.id }] });
            if (selectedNode.parentNode) {
                // timeout allows deleteElements to run first
                setTimeout(() => deleteChildNodeFromParent(selectedNode.parentNode ?? '', selectedNode.id), 1);
            }
            setDeleteModalOpen('');
            return setSelectedNode(null);
        }
        if (!selectedEdge) return;
        deleteElements({ edges: [{ id: selectedEdge.id }] });
        setDeleteModalOpen('');
        return setSelectedEdge(null);
    };

    return (
        <ExperimentCanvasContext.Provider
            value={{
                canvasLoaded,
                canvasLoading,
                canvasNodes,
                canvasNodesError,
                clearPanelChildNodes,
                defaultEdgeOptions,
                defaultViewport,
                deleteModalOpen,
                edges,
                edgesError,
                handleDeleteElement,
                handleSelectTextNodeToEdit,
                handleUpdateEdge,
                handleUpdateNode,
                helperLineHorizontal,
                helperLineVertical,
                isCanvasInUrl,
                newNodeType,
                onDragOver,
                onDrop,
                onEdgesChange,
                onNodeDrag,
                onNodeDragStop,
                onNodesChange,
                onSelectTargetAnalysisNode,
                postNewNode,
                saveNodes,
                selectedEdge,
                selectedNode,
                selectedTargetAnalysisNode,
                setActivePlot,
                setCanvasNodes,
                setDeleteModalOpen,
                setEdges,
                setNewNodeLocation,
                setNewNodeType,
                setSelectedEdge,
                setSelectedNode,
                setSelectedTargetAnalysisNode,
                setTextNodeToEdit,
                textNodeToEdit,
            }}
        >
            <>{children}</>
        </ExperimentCanvasContext.Provider>
    );
};
