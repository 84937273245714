import { TypeAnalysisSkeleton } from './types';
import { Entry } from 'contentful';
import contentfulClient from './contentfulClient';
import { AnalysisShortname } from '../models/analysis/AnalysisType';

type AnalysisInfoEntry = Entry<TypeAnalysisSkeleton, undefined, string>;

// Extend the Analysis interface
export interface ContentfulAnalysis {
    category?: string;
    description?: string;
    internalName: string;
    name: string;
    pythonBinderLink?: string;
    pythonCodeLink?: string;
    pythonColabLink?: string;
    rBinderLink?: string;
    rCodeLink?: string;
    rColabLink?: string;
    shortDescription?: string;
    shortname?: string;
    subcategory?: string;
}

export function parseContentfulAnalysisInfo(analysis?: AnalysisInfoEntry): ContentfulAnalysis | null {
    if (!analysis) {
        return null;
    }

    return {
        category: analysis.fields.category || null,
        description: analysis.fields.description || '',
        internalName: analysis.sys.id,
        name: analysis.fields.name || '',
        pythonBinderLink: analysis.fields.pythonBinderLink || undefined,
        pythonCodeLink: analysis.fields.pythonCodeLink || undefined,
        pythonColabLink: analysis.fields.pythonColabLink || undefined,
        rBinderLink: analysis.fields.rBinderLink || undefined,
        rCodeLink: analysis.fields.rCodeLink || undefined,
        rColabLink: analysis.fields.rColabLink || undefined,
        shortDescription: analysis.fields.shortDescription || '',
        shortname: analysis.fields.shortname,
        subcategory: analysis.fields.subcategory || undefined,
    };
}

interface FetchAnalysesOptions {
    preview: boolean;
    category?: string;
}
export async function fetchAnalyses({ preview, category }: FetchAnalysesOptions): Promise<ContentfulAnalysis[]> {
    const contentful = contentfulClient({ preview });

    const query: any = {
        content_type: 'analysis',
        include: 2,
        order: '-fields.name',
    };

    if (category) {
        query['fields.category'] = category;
    }

    const analysisResult = await contentful.getEntries<TypeAnalysisSkeleton>(query);

    return analysisResult.items.map((analysis) => parseContentfulAnalysisInfo(analysis) as ContentfulAnalysis);
}

interface FetchAnalysisOptions {
    shortname?: AnalysisShortname;
    preview: boolean;
}
export async function fetchAnalysis({ shortname, preview }: FetchAnalysisOptions): Promise<ContentfulAnalysis | null> {
    const contentful = contentfulClient({ preview });

    const analysisResult = await contentful.getEntries<TypeAnalysisSkeleton>({
        content_type: 'analysis',
        'fields.shortname': shortname,
        include: 1,
    });

    return parseContentfulAnalysisInfo(analysisResult.items[0]);
}
