import { usePlotContext } from '@contexts/PlotContext';
import { PathwayAnalysisPlotData } from '@models/ExperimentData';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import { isDefined } from '@util/TypeGuards';
import PlotNoDataView from '@components/plots/PlotNoDataView';
import React from 'react';
import EnrichmentPlotView from '@components/analysisCategories/pathway/plots/EnrichmentPlotView';
import ScoreBarPlotView from '@components/analysisCategories/pathway/plots/ScoreBarPlotView';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import NetworkGraphView from './plots/NetworkGraphView';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = {
    customPlotStylingOptions: CustomPlotStylingOptions | null;
    zoomEnabled?: boolean;
    xAxisTruncated: boolean;
    setZoomTransform?: (transformObject: any) => void;
};

const PathwayPlotDisplayView = ({ customPlotStylingOptions, zoomEnabled, setZoomTransform, xAxisTruncated }: Props) => {
    const { plot, plotData } = usePlotContext();
    const data = plotData as PathwayAnalysisPlotData | null;

    if (!data) {
        return null;
    }
    if (data.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }

    if (isDefined(data.items) && data.items.length === 0) {
        return <PlotNoDataView />;
    }

    switch (plot.display.display_type) {
        case 'enrichment_plot':
            return <EnrichmentPlotView customPlotStylingOptions={customPlotStylingOptions} />;
        case 'score_bar_plot':
            return (
                <ScoreBarPlotView customPlotStylingOptions={customPlotStylingOptions} xAxisTruncated={xAxisTruncated} />
            );
        case 'network_graph':
            return <NetworkGraphView zoomEnabled={zoomEnabled} setZoomTransform={setZoomTransform} />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default PathwayPlotDisplayView;
