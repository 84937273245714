import { useFormikContext } from 'formik';
import Button from '@components/Button';
import React from 'react';

type Props = { text?: string; disabled?: boolean };
const PlotDisplayFormSubmitButton = ({ text, disabled }: Props) => {
    const { submitForm, isSubmitting } = useFormikContext();

    const buttonText = text ?? 'Save changes';

    return (
        <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={submitForm}
            fullWidth
            disabled={isSubmitting || disabled}
            loading={isSubmitting}
            data-cy="submit-display-btn"
        >
            {isSubmitting ? 'Saving...' : buttonText}
        </Button>
    );
};

export default PlotDisplayFormSubmitButton;
