import { createTheme } from '@mui/material/styles';

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;

const theme = createTheme();
export const useSwitchStyles = () => {
    const borderWidth = 2;
    const width = pxToRem(56);
    const height = pxToRem(28);
    const dotSize = pxToRem(32);
    // const gap = (40 - 32) / 2;
    const gap = 0;
    const whiteColor = '#fff';
    return {
        root: {
            width,
            height,
            padding: 0,
            margin: theme.spacing(1),
            overflow: 'unset',
            display: 'flex',
            alignItems: 'center',
        },
        switchBase: {
            padding: pxToRem(gap),
            top: 'unset',
            '&$checked': {
                color: whiteColor,
                transform: `translateX(calc(${width} - ${dotSize} - ${pxToRem(2 * gap)}))`,
                '& + $track': {
                    backgroundColor: theme.palette.primary.light,
                    opacity: 1,
                },
                '& $thumb': {
                    backgroundColor: theme.palette.secondary.main,
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="${encodeURIComponent(
                        whiteColor,
                    )}" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>')`,
                },
            },
        },
        track: {
            borderRadius: 40,
            backgroundColor: theme.palette.primary.light,
            border: 'solid #fff',
            borderWidth,
            opacity: 0.48,
        },
        thumb: {
            width: dotSize,
            height: dotSize,
            boxShadow: 'none',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path fill="${encodeURIComponent(
                whiteColor,
            )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: theme.palette.primary.light,
        },
        checked: {},
        input: {
            height: '100%',
        },
    };
};
