import React, { ReactNode, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Collapse } from '@mui/material';

type Props = { children: ReactNode; label: ReactNode; initialOpen?: boolean };
const SimpleCollapse = ({ children, label, initialOpen = false }: Props) => {
    const [open, setOpen] = useState(initialOpen);
    return (
        <>
            <h4
                className="group mb-2 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                onClick={() => setOpen(!open)}
            >
                <div className="inline">{label}</div>
                <span>
                    <ChevronRightIcon
                        width={20}
                        className={cn(
                            'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                            { 'rotate-90': open },
                        )}
                    />
                </span>
            </h4>
            <Collapse className="" in={open}>
                {children}
            </Collapse>
        </>
    );
};

export default SimpleCollapse;
