import { GeneSetEnrichmentAnalysisParameters } from '@models/AnalysisParameters';
import { useField } from 'formik';
import React, { ReactNode, useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Chip, FormControl, MenuItem, Select } from '@mui/material';
import Button from '@components/Button';
import GeneSetCollection, { getShortDisplayName } from '@models/GeneSetCollection';
import { isDefined } from '@util/TypeGuards';
import { FormikFieldError } from '@components/forms/FieldError';
import SimpleSelectField, { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import { valueOrFirst } from '@util/ObjectUtil';

const NONE_VALUE = -1;

type Props = {
    name: string;
    analysisParameters: GeneSetEnrichmentAnalysisParameters;
    label?: ReactNode;
    multi?: boolean;
};
const GeneSetCollectionPickerField = ({
    name,
    analysisParameters,
    label = 'Gene set collection',
    multi = false,
}: Props) => {
    const choices = analysisParameters.gene_set_collections;
    const [open, setOpen] = useState(false);
    const [, { value, error, touched }, { setValue }] = useField<number[]>(name);
    const [, , { setValue: setGeneSetValue }] = useField<string | null>('gene_set');
    const formValue = value.length === 0 ? [NONE_VALUE] : value;

    const selectedCollections: GeneSetCollection[] =
        value.map((id) => choices.find((c) => c.id === Number(id))).filter(isDefined) ?? [];

    const removeId = (id: number) => {
        setValue(value.filter((v) => v !== id && v !== NONE_VALUE));
    };

    const items: ChoiceItem[] = choices.map((c) => {
        return { label: c.display_name, value: c.id };
    });

    if (!multi) {
        return (
            <SimpleSelectField
                name={name}
                label={label}
                items={items}
                placeholder="Select gene set collection"
                value={valueOrFirst(value) ?? ''}
                onChange={(e) => {
                    const updatedValue = Number(e.target.value) as number;
                    setValue([updatedValue].filter((v) => v !== NONE_VALUE));
                    setGeneSetValue(null);
                    setOpen(false);
                }}
            />
        );
    }

    return (
        <label className="form-field no-margin">
            <span className="field-label">{label}</span>
            <FormControl variant="outlined" fullWidth error={!!error && touched}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    name={name}
                    multiple={true}
                    value={formValue ?? [NONE_VALUE]}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(e) => {
                        const updatedValue = e.target.value as number[];
                        setValue(updatedValue.filter((v) => v !== NONE_VALUE));
                        setGeneSetValue(null);
                        setOpen(false);
                    }}
                    renderValue={() => <span className="opacity-70">Select gene set collection(s)...</span>}
                >
                    <MenuItem value={NONE_VALUE} disabled hidden className="!hidden">
                        Nothing selected
                    </MenuItem>
                    {choices.map((c) => (
                        <MenuItem value={c.id} key={c.id}>
                            <div className="max-w-sm">
                                <p className="whitespace-normal font-semibold">{c.display_name}</p>
                                <p className="whitespace-normal text-sm text-default">{c.description}</p>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={'mt-1 flex max-w-full flex-wrap'}>
                {selectedCollections.map((collection) => (
                    <Chip
                        key={collection.id}
                        onDelete={() => removeId(collection.id)}
                        label={getShortDisplayName(collection)}
                        className="max-width-full mb-0.5 mr-0.5 overflow-ellipsis"
                    />
                ))}
                {value.length > 0 && (
                    <Button variant="text" color="primary" onClick={() => setValue([])}>
                        Clear all
                    </Button>
                )}
            </div>
            <FormikFieldError name={name} />
        </label>
    );
};

export default GeneSetCollectionPickerField;
