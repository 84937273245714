import cn from 'classnames';
import { Divider, FormControl, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MenuProps } from '@/src/theme';
import { LoadingIndicator } from '@components/LoadingButton';
import { isDefined } from '@util/TypeGuards';
import { SEURAT_GROUP_CELL_COUNT_LIMIT } from '@models/ExperimentConfig';
import { FormikFieldError } from '@components/forms/FieldError';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SeuratDifferentialExpressionAnalysisFormValues } from '@models/analysis/SeuratDifferentialExpressionAnalysis';
import useExperimentPlotGroupsV2 from '@hooks/useExperimentPlotGroupsV2';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import useFormStyles from '@hooks/useFormStyles';
import { afterCharacter, isNotBlank } from '@util/StringUtil';

const NO_VALUE = -1;

type Props = { plot: Plot; experiment: Experiment; placeholder?: string };
const SeuratDifferentialGroupsPickerFields = ({ plot, experiment, placeholder = 'Select a group...' }: Props) => {
    const classes = useFormStyles;
    const { values, errors, touched, handleChange } =
        useFormikContext<SeuratDifferentialExpressionAnalysisFormValues>();

    const { loading, groups } = useExperimentPlotGroupsV2({
        annotation_set_id: values.annotation_set_id ?? '',
        experiment,
        latent_variable_id: values.latent_variable_id ?? '',
        plot,
        variable_ids: values.variable_ids ?? [],
    });

    const availableGroups = useMemo(() => {
        return (groups ?? []).sort((g1, g2) => {
            const g1ClusterNumber = g1.display_name.match(/Cluster (\d+)/)?.[1];
            const g2ClusterNumber = g2.display_name.match(/Cluster (\d+)/)?.[1];
            const g1VariableName = afterCharacter(g1.display_name, '-');
            const g2VariableName = afterCharacter(g2.display_name, '-');

            const sortByClusterNumber = Number(g1ClusterNumber) - Number(g2ClusterNumber);
            const sortByDisplayName = g1.display_name.toLowerCase().localeCompare(g2.display_name.toLowerCase());
            const sortByVariableName = g1VariableName.toLowerCase().localeCompare(g2VariableName.toLowerCase());

            // If either group is ineligible, it should be sorted to the bottom
            if (g1.cell_count < SEURAT_GROUP_CELL_COUNT_LIMIT) {
                return 1;
            }
            if (g2.cell_count < SEURAT_GROUP_CELL_COUNT_LIMIT) {
                return -1;
            }

            // Else, sort by cluster number
            if (sortByClusterNumber) {
                return sortByClusterNumber;
            } else if (sortByDisplayName) {
                // Or, sort by display name
                return sortByDisplayName;
            }

            // Then sort by variable name
            return sortByVariableName;
        });
    }, [groups]);

    const selected_group1_id = values.group1_id;
    const selected_group2_id = values.group2_id;
    const $group1 = 'Target Group';
    const $group2 = 'Reference Group';

    const controlGroupMatches = !!availableGroups.find((g) => g.uuid === selected_group1_id);
    const experimentalGroupMatches = !!availableGroups.find((g) => g.uuid === selected_group2_id);

    const showExperimentalError = isNotBlank(errors.group2_id) && touched.group2_id;
    const showControlError = isNotBlank(errors.group1_id) && touched.group1_id;

    const getGroupInfo = (groupId?: string | number | null) => {
        if (!isDefined(groupId)) {
            return null;
        }
        return availableGroups.find((g) => g.uuid === groupId) ?? null;
    };

    return (
        <div className="form-field rounded-lg bg-indigo-100 p-4" data-cy="groups-picker-field">
            <div
                className={cn('form-field !mb-2', {
                    'has-error': showExperimentalError,
                })}
            >
                <p className="field-label">{$group1}</p>
                <FormControl
                    fullWidth
                    variant="outlined"
                    error={showExperimentalError}
                    data-cy="experimental-group-input"
                >
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        value={selected_group1_id !== '' ? selected_group1_id : NO_VALUE}
                        name="group1_id"
                        onChange={handleChange}
                        fullWidth
                        unselectable="on"
                        className="whitespace-normal"
                        placeholder={placeholder}
                        error={showExperimentalError}
                        MenuProps={{
                            ...MenuProps,
                            sx: {
                                paper: classes.targetMenu,
                            },
                        }}
                        renderValue={(value: string | number | null) => {
                            if (loading) {
                                return (
                                    <span className="flex items-center space-x-2 opacity-50">
                                        <LoadingIndicator size={12} />
                                        <span>Loading groups...</span>
                                    </span>
                                );
                            }
                            const groupName = getGroupInfo(value)?.display_name;
                            if (isDefined(groupName)) {
                                return <span className="whitespace-normal">{groupName}</span>;
                            }
                            return <span className="opacity-50">{placeholder}</span>;
                        }}
                    >
                        <MenuItem value={NO_VALUE} disabled={loading || !selected_group1_id}>
                            {loading ? (
                                <span className="flex items-center space-x-2 opacity-50">
                                    <LoadingIndicator size={12} />
                                    <span>Loading groups...</span>
                                </span>
                            ) : (
                                <span>{selected_group1_id ? 'Clear selection' : placeholder}</span>
                            )}
                        </MenuItem>
                        {experimentalGroupMatches && <Divider />}
                        {availableGroups.map(({ display_name, uuid, cell_count }, i) => (
                            <MenuItem
                                sx={{
                                    root: classes.rootItem,
                                    selected: classes.selectedTarget,
                                }}
                                key={`target_${i}`}
                                value={uuid}
                                disabled={
                                    uuid === selected_group1_id ||
                                    uuid === selected_group2_id ||
                                    cell_count < SEURAT_GROUP_CELL_COUNT_LIMIT
                                }
                            >
                                <div className="flex flex-grow items-start justify-between space-x-2 whitespace-normal">
                                    <div className="flex flex-col">
                                        <span className="font-semibold">{display_name}</span>
                                        <span className="text-sm">
                                            {`${cell_count} ${cell_count === 1 ? 'cell' : 'cells'}`}
                                        </span>
                                    </div>
                                    {uuid === selected_group1_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            {$group1}
                                        </span>
                                    )}
                                    {uuid === selected_group2_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            {$group2}
                                        </span>
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormikFieldError name="group1_id" />
                </FormControl>
            </div>

            <span className="field-label no-margin mb-2 block">vs.</span>

            <div
                className={cn('form-field !mb-2', {
                    'has-error': showControlError,
                })}
            >
                <span className="field-label">{$group2}</span>
                <FormControl fullWidth variant="outlined" error={showControlError} data-cy="control-group-input">
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        value={selected_group2_id !== '' ? selected_group2_id : NO_VALUE}
                        placeholder={placeholder}
                        name="group2_id"
                        onChange={handleChange}
                        error={showControlError}
                        MenuProps={{
                            ...MenuProps,
                            sx: {
                                paper: classes.targetMenu,
                            },
                        }}
                        className="whitespace-normal"
                        fullWidth
                        renderValue={(value: string | number | null) => {
                            if (loading) {
                                return (
                                    <span className="flex items-center space-x-2 opacity-50">
                                        <LoadingIndicator size={12} />
                                        <span>Loading groups...</span>
                                    </span>
                                );
                            }
                            const groupName = getGroupInfo(value)?.display_name;
                            if (isDefined(groupName)) {
                                return <span className="whitespace-normal">{groupName}</span>;
                            }
                            return <span className="opacity-50">{placeholder}</span>;
                        }}
                    >
                        <MenuItem disabled={loading || !selected_group2_id} value={NO_VALUE}>
                            {loading ? (
                                <span className="flex items-center space-x-2 opacity-50">
                                    <LoadingIndicator size={12} />
                                    <span>Loading groups...</span>
                                </span>
                            ) : (
                                <span>{selected_group2_id ? 'Clear selection' : placeholder}</span>
                            )}
                        </MenuItem>
                        {controlGroupMatches && <Divider />}
                        {availableGroups.map(({ display_name, uuid, cell_count }, i) => (
                            <MenuItem
                                sx={{
                                    root: classes.rootItem,
                                    selected: classes.selectedTarget,
                                }}
                                key={`target_${i}`}
                                value={uuid}
                                disabled={
                                    uuid === selected_group1_id ||
                                    uuid === selected_group2_id ||
                                    cell_count < SEURAT_GROUP_CELL_COUNT_LIMIT
                                }
                            >
                                <div className="flex flex-grow items-start justify-between whitespace-normal">
                                    <div className="flex flex-col">
                                        <span className="font-semibold">{display_name}</span>
                                        <span className="text-sm">
                                            {cell_count === 1 ? `${cell_count} cell` : `${cell_count} cells`}
                                        </span>
                                    </div>
                                    {uuid === selected_group2_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            {$group2}
                                        </span>
                                    )}
                                    {uuid === selected_group1_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            {$group1}
                                        </span>
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormikFieldError name="group2_id" />
                </FormControl>
            </div>
            <p className="mt-4 text-xs italic opacity-75">
                Note: Groups need to include at least 3 cells to be eligible for analysis.
            </p>
        </div>
    );
};

export default SeuratDifferentialGroupsPickerFields;
