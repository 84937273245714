import {
    AnalysisParameters,
    AssaySummaryAnalysisParameters,
    ClusteringAnalysisParameters,
    CoverageAnalysisParameters,
    DifferentialExpressionAnalysisParameters,
    DifferentialExpressionContinuousAnalysisParameters,
    FunctionalAnnotationAnalysisParameters,
    GeneSetEnrichmentAnalysisParameters,
    SeuratMarkerExpressionAnalysisParameters,
    SeuratModuleScoreAnalysisParameters,
    OverlapAnalysisParameters,
    PeakAnalysisParameters,
    ProteinProteinInteractionAnalysisParameters,
    SampleCorrelationAnalysisParameters,
    SeuratDifferentialExpressionAnalysisParameters,
    SeuratOverRepresentationAnalysisParameters,
    SurvivalAnalysisParameters,
    TranscriptionFactorEnrichmentAnalysisParameters,
} from '@models/AnalysisParameters';
import { isDefined } from '@util/TypeGuards';

export const isAssaySummaryAnalysisParameters = (
    params: AnalysisParameters | null,
): params is AssaySummaryAnalysisParameters => {
    if (!params) {
        return false;
    }
    const coercedParams = params as AssaySummaryAnalysisParameters;
    return isDefined(coercedParams?.targets) && isDefined(coercedParams?.variables);
};

export const isDifferentialExpressionAnalysisParameters = (
    params: AnalysisParameters | null,
): params is DifferentialExpressionAnalysisParameters => {
    if (!params) {
        return false;
    }
    const coercedParams = params as DifferentialExpressionAnalysisParameters;
    return isDefined(coercedParams?.variables);
};

export const isSeuratDifferentialExpressionAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SeuratDifferentialExpressionAnalysisParameters => {
    if (!params) {
        return false;
    }
    const coercedParams = params as SeuratDifferentialExpressionAnalysisParameters;
    return isDefined(coercedParams?.variables);
};

export const isDifferentialExpressionContinuousAnalysisParameters = (
    params: AnalysisParameters | null,
): params is DifferentialExpressionContinuousAnalysisParameters => {
    if (!params) {
        return false;
    }
    const coercedParams = params as DifferentialExpressionContinuousAnalysisParameters;
    return isDefined(coercedParams?.variables);
};

export const isGeneSetEnrichmentAnalysisParameters = (
    params: AnalysisParameters | null,
): params is GeneSetEnrichmentAnalysisParameters => {
    if (!params) {
        return false;
    }

    const coercedParams = params as GeneSetEnrichmentAnalysisParameters;
    return isDefined(coercedParams.gene_set_collections);
};

export const isSeuratOverRepresentationAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SeuratOverRepresentationAnalysisParameters => {
    if (!params) {
        return false;
    }

    const coercedParams = params as SeuratOverRepresentationAnalysisParameters;
    return isDefined(coercedParams.gene_set_collections);
};

export const isFunctionalAnnotationAnalysisParameters = (
    params: AnalysisParameters | null,
): params is FunctionalAnnotationAnalysisParameters => {
    return true;
};

export const isPeakAnalysisParameters = (params: AnalysisParameters | null): params is PeakAnalysisParameters => {
    return true;
};

export const isSurvivalAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SurvivalAnalysisParameters => {
    return true;
};

export const isClusteringAnalysisParameters = (
    params: AnalysisParameters | null,
): params is ClusteringAnalysisParameters => {
    return true;
};

export const isCoverageAnalysisParameters = (
    params: AnalysisParameters | null,
): params is CoverageAnalysisParameters => {
    return true;
};

export const isTranscriptionFactorEnrichmentAnalysisParameters = (
    params: AnalysisParameters | null,
): params is TranscriptionFactorEnrichmentAnalysisParameters => {
    return true;
};

export const isProteinProteinInteractionAnalysisParameters = (
    params: AnalysisParameters | null,
): params is ProteinProteinInteractionAnalysisParameters => {
    return true;
};

export const isSampleCorrelationAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SampleCorrelationAnalysisParameters => {
    return true;
};

export const isOverlapAnalysisParameters = (params: AnalysisParameters | null): params is OverlapAnalysisParameters => {
    return true;
};

export const isSeuratMarkerExpressionAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SeuratMarkerExpressionAnalysisParameters => {
    return true;
};

export const isSeuratModuleScoreAnalysisParameters = (
    params: AnalysisParameters | null,
): params is SeuratModuleScoreAnalysisParameters => {
    return true;
};
