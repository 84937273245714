import Experiment from '@models/Experiment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { OverlapAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { OverlapAnalysisParameters } from '@models/AnalysisParameters';
import { AddRounded } from '@mui/icons-material';
import Button from '@components/Button';
import useAnalysisInputs from '@/src/hooks/useAnalysisInputs';
import Plot from '@/src/models/Plot';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CircularProgress, Tooltip } from '@mui/material';
import { AnalysisInputFormValues } from '../inputs/AnalysisInputFormTypes';
import { ApiError } from '@/src/services/ApiError';
import Logger from '@util/Logger';
import ConditionalWrapper from '@/src/components/ConditionalWrapper';
import cn from 'classnames';
import { OverlapLists } from '@/src/models/analysis/OverlapAnalysis';
import GridLayout, { Layout, LayoutItem } from 'react-grid-layout';
import OverlapAnalysisInput from '../inputs/OverlapAnalysisInput';
import AnalysisInputForm from '../inputs/AnalysisInputForm';
import { SelectorIcon } from '@heroicons/react/outline';
import { useExperimentDetailViewContext } from '@/src/contexts/ExperimentDetailViewContext';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { BarsTwo } from '@components/icons/custom/BarsTwo';

const logger = Logger.make('OverlapAnalysisFormFields');

type Props = {
    analysisParameters: OverlapAnalysisParameters;
    experiment: Experiment;
    plot: Plot;
};
const OverlapAnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    const { setFieldValue, submitForm } = useFormikContext<OverlapAnalysisFormValues>();
    const hasInitialized = useRef(false);
    const [tmpListOrder, setTmpListOrder] = useState<string[]>([]);
    const [isDraggable, setIsDraggable] = useState(false);
    const {
        inputExpanded,
        inputSaveButtonRef,
        pingSave,
        scrollToInputSaveButton,
        setAnalysisFormSubmitDisabled,
        setInputExpanded,
    } = useExperimentDetailViewContext();

    const updateAnalysisForm = useCallback((lists) => {
        const maxLists = OverlapLists.length;

        for (let i = 0; i < maxLists; i++) {
            const uuid = i < lists.length ? lists[i].uuid : null;
            setFieldValue(`list_${i + 1}_id`, uuid);
        }
    }, []);
    const onCreateList = useCallback((newList) => {
        setInputExpanded(newList.uuid);
    }, []);

    const { createList, creatingList, fetchingList, inputLists, removeList, setInputLists, updateList, updatingList } =
        useAnalysisInputs({
            experimentId: experiment.uuid,
            analysisId: plot.analysis?.uuid ?? '',
            updateAnalysisForm,
            plot,
            onCreateList,
        });

    const maxListsReached = inputLists?.length === 3;
    const addListButtonDisabled = maxListsReached || creatingList || fetchingList || isDraggable;

    const onAddList = async () => {
        if (inputExpanded) {
            scrollToInputSaveButton();
        } else {
            await createList();
        }
    };

    /**
     * Save the form values to the experiment annotation.
     * @param {OverlapAnalysisFormValues} values
     * @param {FormikHelpers<FormValues>} helpers
     * @return {Promise<void>}
     */
    const handleSubmit = async (
        values: AnalysisInputFormValues,
        helpers: FormikHelpers<AnalysisInputFormValues>,
        analysisInputId: string,
    ): Promise<void> => {
        logger.debug('Submitting Overlap analysis input values', values, analysisInputId);
        helpers.setStatus(null);
        try {
            const result = await updateList(values, analysisInputId);
            if (result?.error) {
                throw result.error; // Propagate the error to the parent catch block
            }
            const indexOf = analysisInputId ? inputLists.findIndex((list) => list.uuid === analysisInputId) : -1;

            // Expand next list, if it exists and is not filled out
            if (indexOf < inputLists.length - 1 && !inputLists[indexOf + 1]?.target_genes_format) {
                setInputExpanded(inputLists[indexOf + 1].uuid);
            } else {
                setInputExpanded(null);
            }
        } catch (error) {
            logger.error('ApiErrorMessage', ApiError.getMessage(error));
            helpers.setStatus({ error: ApiError.getMessage(error) });
        } finally {
            helpers.setSubmitting(false);
        }
    };

    // Auto-expand first input list on initial load if it is not filled out
    useEffect(() => {
        if (!hasInitialized.current && inputLists?.[0]?.uuid && !inputLists?.[0]?.target_genes_format) {
            setInputExpanded(inputLists[0].uuid);
            hasInitialized.current = true;
        }
    }, [inputLists]);

    useEffect(() => {
        if (isDraggable) {
            setAnalysisFormSubmitDisabled(true);
        } else {
            setAnalysisFormSubmitDisabled(false);
        }
    }, [isDraggable]);

    const renderWrapper = useCallback(
        (children) => (
            <Tooltip title="Maximum of 3 lists allowed." placement="right" arrow>
                <div className="mt-3 inline-flex items-center justify-center">{children}</div>
            </Tooltip>
        ),
        [experiment],
    );
    const handleLayoutChange = (newLayout: Layout) => {
        const newListOrder: string[] = newLayout
            .sort((a, b) => {
                return a.y - b.y;
            })
            .map((layoutItem: LayoutItem) => (isNaN(layoutItem.i) ? layoutItem.i : parseInt(layoutItem.i)));
        setTmpListOrder(newListOrder);
    };
    const handleLayoutSave = () => {
        for (let i = 0; i < tmpListOrder.length; i++) {
            setFieldValue(`list_${i + 1}_id`, tmpListOrder[i]);
        }
        setInputLists((prevLists) => {
            const orderMap = tmpListOrder.reduce((map, uuid, index) => {
                map[uuid] = index;
                return map;
            }, {});

            // Sort prevLists based on the order in tmpListOrder
            const sortedLists = [...prevLists].sort((a, b) => {
                return orderMap[a.uuid ?? 0] - orderMap[b.uuid ?? 0];
            });

            return sortedLists;
        });
    };

    const onRemove = async (analysisInputId: string) => {
        setInputExpanded(null);
        await removeList(analysisInputId);
    };

    const renderLists = () =>
        inputLists?.map((list, index) => (
            <div key={list?.uuid ?? index} className="w-full">
                <Flipped flipId={list?.uuid ?? index}>
                    <div
                        className={cn('flex flex-1 flex-row items-center gap-2', {
                            'cursor-grab': isDraggable,
                        })}
                    >
                        {isDraggable ? (
                            <div className="flex shrink-0">
                                <BarsTwo height={22} width={22} className="text-slate-500" />
                            </div>
                        ) : null}

                        <CSSTransition timeout={500} delay={1000} classNames="item" key={list?.uuid ?? index}>
                            <div className="w-full flex flex-1">
                                <AnalysisInputForm
                                    index={index}
                                    plot={plot}
                                    input={list}
                                    handleSubmit={(values, helpers) => handleSubmit(values, helpers, list?.uuid ?? '')}
                                >
                                    {(formikProps) => (
                                        <OverlapAnalysisInput
                                            analysisParameters={analysisParameters}
                                            deleteDisabled={inputLists.length <= 2}
                                            disableExpand={isDraggable}
                                            expanded={inputExpanded}
                                            experiment={experiment}
                                            formik={formikProps}
                                            index={index}
                                            list={list}
                                            pingSave={inputExpanded === pingSave}
                                            removeList={onRemove}
                                            saveButtonRef={inputSaveButtonRef}
                                            scrollToInputSaveButton={scrollToInputSaveButton}
                                            setExpanded={setInputExpanded}
                                            updateList={updateList}
                                            updatingList={updatingList}
                                        />
                                    )}
                                </AnalysisInputForm>
                            </div>
                        </CSSTransition>
                    </div>
                </Flipped>
            </div>
        ));

    return (
        <div className="space-y-8">
            <section>
                <div className="mb-2 flex flex-row items-center justify-between">
                    <p className="text-lg font-semibold text-dark">List of Comparisons</p>
                    <Button
                        size="small"
                        variant={isDraggable ? 'outlined' : 'text'}
                        color="primary"
                        onClick={() => {
                            if (inputExpanded) {
                                // Require save before re-ordering
                                scrollToInputSaveButton();
                            } else if (isDraggable) {
                                // Save the layout
                                handleLayoutSave();
                                submitForm();
                                setIsDraggable(false);
                            } else {
                                // Enter drag state
                                setInputExpanded(null);
                                setIsDraggable(true);
                            }
                        }}
                        startIcon={<SelectorIcon className="h-4 w-4" />}
                    >
                        {isDraggable ? 'Save Layout' : 'Re-order'}
                    </Button>
                </div>
                <div className="relative min-h-[152px] w-full">
                    <CSSTransition timeout={100} classNames="fade" in={fetchingList} unmountOnExit>
                        <div className="flex h-full w-full flex-col gap-3">
                            <div className="flex h-[49px] w-full animate-pulse flex-col rounded-xl bg-gray-100 text-center text-gray-700" />
                            <div className="flex h-[49px] w-full animate-pulse flex-col rounded-xl bg-gray-100 text-center text-gray-700" />
                        </div>
                    </CSSTransition>
                    <TransitionGroup component={null}>
                        <Flipper
                            flipKey={inputLists?.map((g) => g.uuid).join('')}
                            spring={'stiff'}
                            className="flex flex-col gap-3"
                        >
                            {isDraggable ? (
                                <GridLayout
                                    className="layout"
                                    rowHeight={48}
                                    width={1}
                                    cols={1}
                                    isResizable={false}
                                    onLayoutChange={handleLayoutChange}
                                >
                                    {renderLists()}
                                </GridLayout>
                            ) : (
                                renderLists()
                            )}
                        </Flipper>
                    </TransitionGroup>
                    <ConditionalWrapper condition={maxListsReached} wrapper={renderWrapper}>
                        <Button
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={onAddList}
                            startIcon={<AddRounded fontSize="small" />}
                            disabled={addListButtonDisabled}
                            className={cn({ 'mt-3': !maxListsReached })}
                        >
                            {creatingList ? (
                                <>
                                    Adding list...{' '}
                                    <CircularProgress color="inherit" className="ml-2 text-slate-500" size={14} />
                                </>
                            ) : (
                                'Add another list'
                            )}
                        </Button>
                    </ConditionalWrapper>
                </div>
            </section>
        </div>
    );
};

export default OverlapAnalysisFormFields;
