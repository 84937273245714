import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { Log2FoldChangeDirection } from '@models/AnalysisParameters';
import { AnalysisParameters } from '@models/AnalysisParameters';
import {
    TranscriptionFactorEnrichmentAnalysis,
    getShortDisplayName,
} from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import CodeBlockInline from '@components/CodeBlockInline';

function formatNumber(input: number) {
    return input;
}

function getDirectionName(direction: Log2FoldChangeDirection): string {
    switch (direction) {
        case 'both':
            return 'both positive and negative';
        case 'positive':
            return 'positive only';
        case 'negative':
            return 'negative only';
    }
}

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const TranscriptionFactorEnrichmentAnalysisMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as TranscriptionFactorEnrichmentAnalysis;
    const comparisonAnalysis = analysis.differential_analysis;
    const directionName = getDirectionName(analysis.log2_fc_direction);

    return (
        <section>
            <MethodsSectionTitle>Transcription factor enrichment analysis (TFEA)</MethodsSectionTitle>
            <div className="space-y-4">
                <p className="mb-4">
                    Transcription factor enrichment analysis (TFEA) was performed for the comparison{' '}
                    <Bold>{comparisonAnalysis.name}</Bold>. Prior to analysis, differential genes were filtered using an
                    adjusted <span className="italic">p</span>-value &le;&nbsp;
                    <Bold>{formatNumber(analysis.adj_pval_threshold)}</Bold> and log<sub>2</sub> fold change threshold{' '}
                    of <Bold>{formatNumber(analysis.log2_fc_threshold)}</Bold> and included genes that were{' '}
                    <Bold>{directionName}</Bold>) to create the input gene list.
                </p>
                <p className="mb-4">
                    TFEA scores were computed using the <CodeBlockInline>ChEA3</CodeBlockInline> tool with the TF-target
                    gene set library <Bold>{getShortDisplayName(analysis.tf_target_collection)}</Bold>. ChEA3 predicts
                    transcription factors (TFs) associated with the input gene list by using the Fisher&apos;s Exact
                    Test, with a background size of 20,000, to compare the input gene set to the TF target gene sets in
                    order to determine which TFs may be most closely associated with the input gene set.
                </p>
            </div>
        </section>
    );
};

export default TranscriptionFactorEnrichmentAnalysisMethodsSection;
