import { createContext, MutableRefObject, ReactNode, useContext } from 'react';
import { ContextNotInitializedError } from '@contexts/ContextErrors';
import { ObservedSize } from '@hooks/useDebouncedResizeObserver';

type ContextType = { size?: ObservedSize; containerRef: MutableRefObject<Element | null> };
const ResizableContainerContext = createContext<ContextType | null>(null);
ResizableContainerContext.displayName = 'ResizableContainerContext';

export const useResizableContainerContext = () => {
    const context = useContext(ResizableContainerContext);
    if (!context) {
        throw new ContextNotInitializedError({ name: 'ResizableContainerContext' });
    }
    return context;
};

type Props = Pick<ContextType, 'size' | 'containerRef'> & { children: ReactNode };
export const ResizableContainerContextProvider = ({ size, containerRef, children }: Props) => {
    return (
        <ResizableContainerContext.Provider
            value={{
                size,
                containerRef,
            }}
        >
            {children}
        </ResizableContainerContext.Provider>
    );
};
