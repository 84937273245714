import { IconProps } from '@components/icons/CustomIcons';

/**
 * Antibody icon
 * @param { number | string | undefined } height
 * @param { number | string | undefined } width
 * @param { React.CSSProperties | undefined } style
 * @param { string | undefined } className
 * @return { JSX.Element }
 * @constructor
 */
export const AntibodyIcon = ({ height, width, style, className }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14 22V8.66667L19 2" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
            <path d="M10 22V8.66667L5 2" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
            <line
                x1="1.5"
                y1="-1.5"
                x2="6.16963"
                y2="-1.5"
                transform="matrix(0.623333 0.781957 -0.749499 0.662005 1 6.21045)"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
            />
            <line
                x1="1.5"
                y1="-1.5"
                x2="6.16963"
                y2="-1.5"
                transform="matrix(-0.623333 0.781957 0.749499 0.662005 22.7808 6.21045)"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};
