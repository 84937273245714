import { useFormikContext } from 'formik';
import React from 'react';
import { CreateSeuratObjectFormValues } from '../PreprocessFormTypes';
import TextInputField from '@components/forms/TextInputField';
import { Tooltip } from '@mui/material';
import { HelpCircleIcon } from '@/src/components/icons/custom/HelpCircleIcon';

type Props = {
    readOnly: boolean;
};
const CreateSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue } = useFormikContext<CreateSeuratObjectFormValues>();
    return (
        <div className="space-y-8">
            <section>
                <TextInputField
                    name="min_cells"
                    label="Minimum Cells"
                    type="number"
                    value={values.min_cells}
                    disabled={readOnly}
                    labelRight={
                        <div className="ml-1 flex flex-1 items-center justify-start">
                            <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                <Tooltip
                                    title="Set this value to define the minimum number of cells in which a gene must be detected to be considered for analysis."
                                    placement="top"
                                    arrow
                                >
                                    <div>
                                        <HelpCircleIcon height={16} width={16} color="#3343AB" />
                                    </div>
                                </Tooltip>
                            </p>
                        </div>
                    }
                    onValueChange={(_, __, values) => setFieldValue('min_cells', values?.float ?? 0)}
                    fixedDecimalLength={0}
                    allowNegativeValue={false}
                    step={1}
                    min={0}
                    max={50}
                    useNumericFormat
                />
                <TextInputField
                    name="min_features"
                    label="Minimum Features"
                    type="number"
                    noMargin
                    value={values.min_features}
                    disabled={readOnly}
                    labelRight={
                        <div className="ml-1 flex flex-1 items-center justify-start">
                            <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                <Tooltip
                                    title="Set this value to define the minimum number of genes that a cell must express to be considered for analysis."
                                    placement="top"
                                    arrow
                                >
                                    <div>
                                        <HelpCircleIcon height={16} width={16} color="#3343AB" />
                                    </div>
                                </Tooltip>
                            </p>
                        </div>
                    }
                    onValueChange={(_, __, values) => setFieldValue('min_features', values?.float ?? 0)}
                    fixedDecimalLength={0}
                    allowNegativeValue={false}
                    step={1}
                    min={0}
                    max={1000}
                    useNumericFormat
                />
            </section>
        </div>
    );
};

export default CreateSeuratObjectFormFields;
