import React, { useMemo } from 'react';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { ExperimentData } from '@models/ExperimentData';
import LinePlotBuilder from '@components/analysisCategories/summary/plots/builders/LinePlotBuilder';
import { LinePlotDatum } from '@components/plots/PlotTypes';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const LinePlotView = ({ customPlotStylingOptions }: Props) => {
    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                const { publicationMode, plot, plotData, analysisParameters, experiment } = context;
                _svg.selectAll('g').remove();

                const data = plotData as ExperimentData<LinePlotDatum> | null | undefined;

                if (!data) {
                    return;
                }

                const svg = _svg.append('g');

                const builder = new LinePlotBuilder({
                    svg,
                    plot,
                    data,
                    experiment,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    analysisParameters: analysisParameters ?? null,
                    idPrefix: context.idPrefix,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions ?? null,
                });

                builder.draw();
            },
        [customPlotStylingOptions],
    );

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default LinePlotView;
