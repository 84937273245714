import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { displayName: string };
const PrepareDataMethods = ({ displayName }: Props) => {
    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        Feature-barcode matrices generated by 10x Genomics Cell Ranger were obtained for each sample in
                        the dataset. For each sample, transcript count matrices were created by assigning cell barcodes
                        as column names and genes as row names. Individual count matrices from different samples were
                        merged into a single, consolidated matrix.
                    </p>
                    <p>
                        Multiplet class information was added to the cell-level metadata using scDblFinder, which was
                        applied to each sample independently.
                    </p>

                    <div>
                        <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                        <p>
                            10x Genomics Cell Ranger v7.1.0
                            <br />
                            R v4.3.1
                            <br />
                            scDblFinder v1.14.0
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PrepareDataMethods;
