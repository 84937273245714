export type PreprocessShortname =
    | 'seurat_prepare_data'
    | 'seurat_cluster_object'
    | 'seurat_create_object'
    | 'seurat_filter_object'
    | 'seurat_integrate_object'
    | 'seurat_normalize_object'
    | 'seurat_refine_object'
    | string;

export const supportedExperimentTypes = ['scrnaseq'];
