import React, { ReactNode } from 'react';
import { isDefined } from '@util/TypeGuards';

export type BaseCitation = {
    title: string;
    author: string;
    publication: string;
    year: string;
};

export type JournalCitation = BaseCitation & {
    volume: string;
    page: string;
};
export type OnlineCitation = BaseCitation & {
    url: string;
    label: string;
};

export type OtherCitation = BaseCitation & {
    suffix: string;
};

export type CustomCitation = { custom: ReactNode };

export type CitationItem = JournalCitation | OnlineCitation | OtherCitation | CustomCitation;

export const isJournalCitation = (item: CitationItem): item is JournalCitation => {
    const i = item as JournalCitation;
    return isDefined(i?.volume) && isDefined(i?.page);
};

export const isOnlineCitation = (item: CitationItem): item is OnlineCitation => {
    const i = item as OnlineCitation;
    return isDefined(i?.label) && isDefined(i?.url);
};

export const isOtherCitation = (item: CitationItem): item is OtherCitation => {
    const i = item as OtherCitation;
    return isDefined(i?.suffix);
};

export const isCustomCitation = (item: CitationItem): item is CustomCitation => {
    const i = item as CustomCitation;
    return isDefined(i?.custom);
};

type CitationProps = { citation: CitationItem };

export const Citation = ({ citation }: CitationProps) => {
    if (isJournalCitation(citation)) {
        return <JournalCitationView citation={citation} />;
    }

    if (isOnlineCitation(citation)) {
        return <OnlineCitationView citation={citation} />;
    }

    if (isOtherCitation(citation)) {
        return <OtherCitationView citation={citation} />;
    }

    if (isCustomCitation(citation)) {
        return <span>{citation.custom}</span>;
    }

    return null;
};

export const JournalCitationView = ({ citation }: { citation: JournalCitation }) => {
    const { title, author, publication, volume, page, year } = citation;
    return (
        <span>
            {author}. {title}. <i>{publication}</i>. <span className="font-bold">{volume}</span>, {page} ({year}).
        </span>
    );
};

export const OnlineCitationView = ({ citation }: { citation: OnlineCitation }) => {
    const { title, author, publication, label, url, year } = citation;

    return (
        <span>
            {author}. {title}. <i>{publication}</i> {year}.{' '}
            <a href={url} target="_blank" rel="noreferrer nofollow">
                {label}
            </a>
            .
        </span>
    );
};

export const OtherCitationView = ({ citation }: { citation: OtherCitation }) => {
    const { title, author, publication, suffix, year } = citation;

    return (
        <span>
            {author}. {title}. <i>{publication}</i> {year}. {suffix}.
        </span>
    );
};
