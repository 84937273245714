export type StatisticalTestShortname = 'ttest_twosample_unpaired' | 'anova_oneway' | 'anova_twoway';
export type StatisticalTestStatus = 'published' | 'preview';

export type StatisticalTestType = {
    shortname: StatisticalTestShortname;
    display_name: string;
    maximum_groups?: number | null;
    minimum_groups: number;
    minimum_group_sample_size?: number | null;
};

export interface SummaryStatisticalTestResult {
    uuid: string;
    value: number;
    target: string | number;
    groups: number[];
    test_type: StatisticalTestType;
    status: StatisticalTestStatus;
    show_on_plot?: boolean;
}

export type UpdateStatisticalTestResultParams = Partial<Pick<SummaryStatisticalTestResult, 'show_on_plot'>>;

export const getStatisticalValueKey = (value: number): string => {
    if (value <= 0.001) return '***';
    if (value <= 0.01) return '**';
    if (value <= 0.05) return '*';

    return 'n.s.';
};

export type SummaryStatisticalTestFormValues = Omit<SummaryStatisticalTestResult, 'uuid' | 'value' | 'test_type'> & {
    test_type: StatisticalTestShortname;
};
