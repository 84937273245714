import { Tooltip } from '@mui/material';
import copy from '@copy/Copy';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

const SurvivalAnalysisTooltipHelpIcon = () => {
    return (
        <span>
            <Tooltip
                leaveDelay={500}
                arrow
                placement="right"
                title={
                    <div className="p-1">
                        Requires event-related columns in sample.{' '}
                        <a
                            href={copy.survivalAnalysisHelpUrl}
                            target="_blank"
                            rel="nofollow noreferrer"
                            className="text-indigo-300 underline hover:text-indigo-100"
                        >
                            Learn more
                        </a>
                    </div>
                }
            >
                <span className="link">
                    <HelpCircleIcon width={16} className="" />
                </span>
            </Tooltip>
        </span>
    );
};

export default SurvivalAnalysisTooltipHelpIcon;
