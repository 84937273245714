import { IconProps, defaultTextClass } from '@components/icons/CustomIcons';

export const LinePlotIcon = (props?: IconProps) => {
    const { width = 43, height = 24, className = defaultTextClass } = { ...props };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 47 48"
            width={width}
            height={height}
            className={className}
            strokeWidth={props?.strokeWidth ?? 2}
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.625 30c1.298 0 2.35-1.075 2.35-2.4 0-1.326-1.052-2.4-2.35-2.4s-2.35 1.075-2.35 2.4 1.052 2.4 2.35 2.4ZM9.4 24c1.298 0 2.35-1.075 2.35-2.4 0-1.326-1.052-2.4-2.35-2.4s-2.35 1.075-2.35 2.4S8.102 24 9.4 24Zm34.075-2.4c1.298 0 2.35-1.075 2.35-2.4s-1.052-2.4-2.35-2.4-2.35 1.075-2.35 2.4c0 1.326 1.052 2.4 2.35 2.4Zm-9.4 0c1.298 0 2.35-1.075 2.35-2.4s-1.052-2.4-2.35-2.4-2.35 1.075-2.35 2.4c0 1.326 1.052 2.4 2.35 2.4Zm-6.402.996c-1.18-.55-2.593-.027-3.155 1.167-.562 1.195-.06 2.61 1.12 3.16 1.18.55 2.594.027 3.156-1.168.562-1.194.06-2.609-1.12-3.159Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M1-1h5.587"
                transform="matrix(.7744 .6327 -.61666 .78723 9.4 22.8)"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M1-1h6.962"
                transform="matrix(-.96327 .26853 -.2582 -.96609 26.258 24)"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M1-1h7.008"
                transform="matrix(-.82592 .5638 -.54762 -.83673 34.465 18)"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M1-1h6.949"
                transform="rotate(-178.082 22.25 8.78) skewX(.079)"
            />
        </svg>
    );
};
