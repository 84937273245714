import Experiment from '@models/Experiment';
import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import React, { useMemo, useRef, useState } from 'react';
import NoSsr from '@components/NoSsr';
import SampleDataTableView from '@components/experiments/SampleDataTableView';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import Copy from '@copy/Copy';
import CopyTextInput from '@components/forms/CopyTextInput';
import { Tooltip } from '@mui/material';
import Button from '@components/Button';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';
import InstructionsAndTipsView from './InstructionsAndTipsView';
import PreprocessResultsDataTable from './PreprocessResultsDataTable';
import { MethodsPreprocessContent } from '../methods/MethodsPreprocessContent';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import PreprocessStep from '@/src/models/PreprocessStep';
import MethodsPreprocessReferences from '../methods/MethodsPreprocessReferences';
import LoadingMessage from '@components/LoadingMessage';

export type TabViewName = 'sample' | 'results_table' | 'summary_table' | 'instructions_and_tips' | 'methods';

type Props = {
    experiment: Experiment;
    initialTab?: TabViewName;
    tabs?: ButtonGroupItem<TabViewName>[];
};
const PreprocessDetailTabsView = ({ experiment, initialTab, tabs: tabsProp }: Props) => {
    const {
        plotData,
        plotDataError,
        plotDataLoading,
        selectedPreprocess,
        summaryData,
        summaryDataError,
        summaryDataLoading,
    } = useExperimentWorkflowContext();

    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });
    const preprocessIsRunning = selectedPreprocess?.pipeline_status === 'in_progress';

    const dataTableViewItems = useMemo<ButtonGroupItem<TabViewName>[]>(() => {
        if (tabsProp) {
            return tabsProp;
        }
        const tabs: ButtonGroupItem<TabViewName>[] = [{ value: 'instructions_and_tips', label: 'Instructions & Tips' }];

        if (selectedPreprocess?.preprocess_type.has_summary) {
            tabs.push({ value: 'summary_table', label: 'Summary Table' });
        } else if (Boolean(plotData)) {
            tabs.push({ value: 'results_table', label: 'Results' });
        }
        if (selectedPreprocess?.pipeline_ran && selectedPreprocess?.pipeline_status !== 'failed') {
            tabs.push({ value: 'methods', label: 'Methods' });
        }
        tabs.push({ value: 'sample', label: 'Samples' });

        return tabs;
    }, [selectedPreprocess, plotData]);

    const firstTab = dataTableViewItems.find((t) => t.value === initialTab) ?? dataTableViewItems[0];
    const [selectedTab, setTab] = useState<TabViewName>(firstTab.value);

    const getTabView = () => {
        switch (selectedTab) {
            case 'instructions_and_tips':
                return <InstructionsAndTipsView />;
            case 'sample':
                return <SampleDataTableView experiment={experiment} />;
            case 'summary_table':
                return (
                    <PreprocessResultsDataTable
                        experiment={experiment}
                        selectedPreprocess={selectedPreprocess}
                        data={summaryData}
                        loading={summaryDataLoading}
                        error={summaryDataError}
                    />
                );
            case 'results_table':
                return (
                    <PreprocessResultsDataTable
                        experiment={experiment}
                        selectedPreprocess={selectedPreprocess}
                        data={plotData}
                        loading={plotDataLoading}
                        error={plotDataError}
                    />
                );
            case 'methods':
                return (
                    <div className="m-auto flex w-[80%] max-w-3xl flex-col">
                        {preprocessIsRunning ? (
                            <LoadingMessage message="Recalculating methods..." />
                        ) : (
                            <>
                                <div ref={ref} className="mb-8 space-y-4">
                                    <PlutoErrorBoundary>
                                        <MethodsPreprocessContent
                                            preprocess={selectedPreprocess as PreprocessStep}
                                            experiment={experiment}
                                        />
                                    </PlutoErrorBoundary>
                                    <p>{Copy.preprocessCitation}</p>
                                    <MethodsPreprocessReferences
                                        preprocess={selectedPreprocess as PreprocessStep}
                                        className="mb-8"
                                    />
                                </div>
                                <div className="mb-8">
                                    <Button
                                        startIcon={<CopyIcon height={16} width={16} />}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => copyText()}
                                    >
                                        {showCopySuccess ? 'Copied!' : 'Copy'}
                                    </Button>
                                </div>
                            </>
                        )}
                        <div className="space-y-2">
                            <div className="space-y-1">
                                <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                    <span>Experiment ID</span>
                                    <Tooltip
                                        title={
                                            'Fetch results from this experiment using the Pluto API. Tap to learn more.'
                                        }
                                        arrow
                                    >
                                        <a href={Copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                            <HelpCircleIcon height={16} width={16} />
                                        </a>
                                    </Tooltip>
                                </p>
                            </div>
                            <CopyTextInput
                                value={experiment.pluto_id}
                                isPrivate={false}
                                inputClasses="!text-xs text-default opacity-60"
                            />
                        </div>

                        <div className="space-y-2">
                            <div className="space-y-1">
                                <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                    <span>Preprocess ID</span>
                                    <Tooltip
                                        title={
                                            'Fetch results from this preprocess using the Pluto API. Tap to learn more.'
                                        }
                                        arrow
                                    >
                                        <a href={Copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                            <HelpCircleIcon height={16} width={16} />
                                        </a>
                                    </Tooltip>
                                </p>
                            </div>
                            <CopyTextInput
                                value={selectedPreprocess?.uuid ?? ''}
                                isPrivate={false}
                                inputClasses="!text-xs text-default opacity-60"
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="md:relative md:z-10 md:mx-auto md:flex md:w-min md:items-center md:justify-center">
                <div className="flex overflow-x-auto ">
                    <ButtonGroup
                        items={dataTableViewItems}
                        onChange={(screen) => setTab(screen ?? 'sample')}
                        toggle={false}
                        value={selectedTab}
                        className=""
                    />
                </div>
            </div>
            <div className="mt-8 min-h-[400px] space-y-8">
                <PlutoErrorBoundary>
                    <NoSsr>{getTabView()}</NoSsr>
                </PlutoErrorBoundary>
            </div>
        </>
    );
};

export default PreprocessDetailTabsView;
