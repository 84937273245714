import { usePlotContext } from '@contexts/PlotContext';
import LoadingMessage from '@components/LoadingMessage';
import React from 'react';
import useExperimentSettings from '@hooks/useExperimentSettings';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';

const PlotLoadingView = () => {
    const { experiment } = usePlotContext();
    const { plot, overrides } = usePlotContext();
    const { getAnalysisInfo } = useExperimentSettings(experiment);
    const analysis_type = plot.analysis_type;
    const analysisInfo = getAnalysisInfo(analysis_type);
    const isPreview = plot.status === 'preview' || overrides.hasOverrides;
    return (
        <div className="flex flex-col justify-center px-8 py-16 text-center">
            <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-0 text-indigo-800">
                <LoadingMessage size={24} immediate />
            </div>
            <h2 className="text-xl font-semibold tracking-tight">
                <StatisticalSymbolLabel name={analysisInfo?.display_name} />{' '}
                {analysisInfo?.display_name?.toLowerCase()?.endsWith('analysis') ? '' : 'analysis'} is running...
            </h2>

            {isPreview && (
                <p className="text-base">
                    This analysis may take up to a few minutes. Please keep this window open - your plot will appear
                    shortly when the analysis is complete!
                </p>
            )}
            {!isPreview && (
                <p className="text-base">
                    This analysis may take up to a few minutes. It&apos;s safe to navigate away from this page - your
                    plot will appear shortly when the analysis is complete!
                </p>
            )}
        </div>
    );
};

export default PlotLoadingView;
