import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters, GeneSetEnrichmentAnalysisParameters } from '@models/AnalysisParameters';
import { DefaultGeneSetSize, GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import GeneSetCollection from '@models/GeneSetCollection';
import { isDefined } from '@util/TypeGuards';
import { pluralize, proseListJoiner } from '@util/ObjectUtil';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { useRankMethodInfo } from '@hooks/useRankMethod';
import Bold from '@components/elements/Bold';
import Mono from '@components/elements/Mono';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const GeneSetEnrichmentAnalysisMethodsSection = ({ plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as GeneSetEnrichmentAnalysis;
    const { rankMethodInfo } = useRankMethodInfo(analysis.rank_method);
    const allCollections =
        (analysisParameters as GeneSetEnrichmentAnalysisParameters | null)?.gene_set_collections ?? [];

    const getCollectionById = (id: number): GeneSetCollection | null => {
        return allCollections.find((c) => c.id === id) ?? null;
    };

    const selectedCollections = analysis.gene_set_collections.map(getCollectionById).filter(isDefined);

    return (
        <section>
            <MethodsSectionTitle>Gene set enrichment analysis (GSEA)</MethodsSectionTitle>
            <p>
                Gene set enrichment analysis (GSEA) was using the <Mono>fgsea</Mono> R package and the{' '}
                <Mono>fgseaMultilevel()</Mono> function <sup>1</sup>. The{' '}
                <Bold>{rankMethodInfo.formattedDisplayName}</Bold> from the <Bold>{analysis.name}</Bold> differential
                expression comparison was used to rank genes.{' '}
                <Bold>{proseListJoiner(selectedCollections.map((c) => c.display_name))}</Bold> gene set{' '}
                {pluralize(selectedCollections.length, 'collection', 'collections')} from the Molecular Signatures
                Database (MSigDB)<sup>2,3</sup> {pluralize(selectedCollections.length, 'was', 'were')} curated using the{' '}
                <Mono>msigdbr</Mono> R package<sup>4</sup>. Prior to running GSEA, the list of gene sets was filtered to
                include only gene sets with between {analysis.min_gene_set_size ?? DefaultGeneSetSize.min} and{' '}
                {analysis.max_gene_set_size ?? DefaultGeneSetSize.max} genes.
            </p>
        </section>
    );
};

export default GeneSetEnrichmentAnalysisMethodsSection;
