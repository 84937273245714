import Plot from '@models/Plot';
import { isDefined } from '@util/TypeGuards';
import { PeakAnalysis } from '@models/analysis/PeakAnalysis';
import React from 'react';
import ProseList from '@components/ProseList';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

type Props = { plot: Plot };
const PeakAnalysisMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as PeakAnalysis;

    const groupNames: string[] =
        !isDefined(analysis.groups) || analysis.groups.length === 0
            ? ['all samples']
            : analysis.groups.map((group) => group.display_name);

    return (
        <section>
            <MethodsSectionTitle>Peak analysis</MethodsSectionTitle>

            <div className="space-y-4">
                <p>
                    Peaks were analyzed using normalized bigWig files for <ProseList items={groupNames} />.
                </p>
            </div>
        </section>
    );
};

export default PeakAnalysisMethodsSection;
