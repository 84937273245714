import { PageLayout } from '@layouts/Layout';
import cn from 'classnames';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';
import SurvivalAnalysisTooltipHelpIcon from '@components/experiments/SurvivalAnalysisTooltipHelpIcon';
import { defaultTextLightClass } from '@components/icons/CustomIcons';
import { ContentfulAnalysis } from '@contentful/analysisInfo';
import { Blobs } from '../effects/MouseMoveHoverEffect';
import { AnalysisShortname } from '@models/analysis/AnalysisType';

type BadgeProps = {
    children: React.ReactNode;
};
const Badge = ({ children }: BadgeProps) => {
    return (
        <div
            data-cy="low-code-analysis-badge"
            className={cn('inline-flex grow-0 items-center justify-center rounded-lg bg-black/[.08] px-2 py-1')}
        >
            {children}
        </div>
    );
};

type PageProps = {
    analysis: ContentfulAnalysis;
    onSelect: (analysis: ContentfulAnalysis) => Promise<void> | void;
    isSelected: boolean;
};
const LowCodeAnalysisCard: PageLayout<PageProps> = ({ analysis, onSelect, isSelected }: PageProps) => {
    const handleAnalysisClicked = () => {
        onSelect(analysis);
    };
    const hasRLink = analysis.rBinderLink || analysis.rCodeLink || analysis.rColabLink;
    const hasPythonLink = analysis.pythonBinderLink || analysis.pythonCodeLink || analysis.pythonColabLink;

    return (
        <button
            data-cy="select-analysis-btn"
            onClick={handleAnalysisClicked}
            className={cn(
                'card group relative overflow-hidden rounded-lg bg-indigo-300/20 p-0.5 text-left transition-all duration-300 ease-in-out',
                {
                    '!bg-indigo-300': isSelected,
                },
            )}
        >
            <div
                className={cn(
                    'inner relative z-10 h-full w-full rounded-md bg-white px-2 py-1 backdrop-blur-3xl transition-all duration-300 group-hover:bg-white/80 group-hover:backdrop-blur-3xl',
                    {
                        '!bg-indigo-50': isSelected,
                    },
                )}
            >
                <div className="flex space-x-4 py-2">
                    <span className="pt-2">
                        <AnalysisIcon
                            shortname={analysis.name as AnalysisShortname}
                            width={24}
                            height={24}
                            className={defaultTextLightClass}
                        />
                    </span>
                    <div className="">
                        <p className="flex items-center space-x-2 text-base font-semibold">
                            <StatisticalSymbolLabel name={analysis.name} />

                            {analysis.name === 'survival' && <SurvivalAnalysisTooltipHelpIcon />}
                        </p>
                        <p>{analysis.shortDescription}</p>
                        <div className="mt-4 flex flex-row space-x-2" data-cy="links">
                            {hasPythonLink ? <Badge>Python</Badge> : null}
                            {hasRLink ? <Badge>R</Badge> : null}
                        </div>
                    </div>
                </div>
            </div>

            <Blobs />
        </button>
    );
};

export default LowCodeAnalysisCard;
