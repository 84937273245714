import Experiment from '@models/Experiment';
import PipelineRun from '@models/PipelineRun';
import Bold from '@components/elements/Bold';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Button from '@components/Button';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import React, { useRef } from 'react';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import PipelineRunItem from '@components/experiments/PipelineRunItem';
import Italic from '@components/elements/Italic';
import DoiLink from '@components/experiments/methods/DoiLink';

type Props = { experiment: Experiment; pipelineRun: PipelineRun };
const SCRNASeqPipelineMethods = ({ pipelineRun }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });
    const library_type = (pipelineRun.input_parameters?.library_type as string) ?? '';
    const pipeline_name = pipelineRun.pipeline_version.pipeline.pipeline_repo;
    const version = pipelineRun.pipeline_version.version;
    const genome = pipelineRun.genome?.display_name;

    return (
        <div className="space-y-8">
            <div className="space-y-8" ref={ref}>
                <section>
                    <MethodsSectionTitle>scRNA-seq pipeline</MethodsSectionTitle>
                    <p>
                        <Bold>{library_type}</Bold> FASTQ files were processed using the <Bold>{pipeline_name}</Bold>{' '}
                        pipeline (v{version}) with the default parameters set by nf-core, unless otherwise specified
                        <sup>1,2</sup>. Reads were aligned to <Bold>{genome}</Bold> and quantified to gene counts with
                        Cell Ranger<sup>3</sup>.
                    </p>
                </section>
                <section>
                    <MethodsSectionTitle>References</MethodsSectionTitle>
                    <ol className="ml-4 list-decimal space-y-4">
                        <li>
                            Ewels PA, Peltzer A, Fillinger S, Patel H, Alneberg J, Wilm A, Garcia MU, Di Tommaso P,
                            Nahnsen S. The nf-core framework for community-curated bioinformatics pipelines.{' '}
                            <Italic>Nat Biotechnol</Italic> (2020). <DoiLink doi="10.1038/s41587-020-0439-x" />.
                        </li>
                        <li>
                            nf-core/scrnaseq (latest version).{' '}
                            <a href="https://nf-co.re/scrnaseq" target="_blank" rel="nofollow noopener noreferrer">
                                https://nf-co.re/scrnaseq
                            </a>
                            . <DoiLink doi="10.5281/zenodo.3568187" />.
                        </li>
                        <li>
                            Zheng et al. (2017). Massively parallel digital transcriptional profiling of single cells.{' '}
                            <span className="italic">Nature Communications</span>. <span className="font-bold">8</span>:
                            1-12. <DoiLink doi="10.1038/ncomms14049" />.
                        </li>
                    </ol>
                </section>
            </div>
            <div>
                <Button
                    startIcon={<CopyIcon height={16} width={16} />}
                    color="primary"
                    variant="contained"
                    onClick={() => copyText()}
                >
                    {showCopySuccess ? 'Copied!' : 'Copy'}
                </Button>
            </div>
            <div className="space-y-2">
                <MethodsSectionTitle>Pipeline run details</MethodsSectionTitle>
                <PipelineRunItem pipelineRun={pipelineRun} active noRing />
            </div>
        </div>
    );
};

export default SCRNASeqPipelineMethods;
