import Experiment from '@models/Experiment';
import PipelineRun from '@models/PipelineRun';
import React, { useRef } from 'react';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import Mono from '@components/elements/Mono';
import Button from '@components/Button';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import PipelineRunItem from '@components/experiments/PipelineRunItem';
import Italic from '@components/elements/Italic';
import DoiLink from '@components/experiments/methods/DoiLink';

type Props = { experiment: Experiment; pipelineRun: PipelineRun };
const CutAndRunPipelineMethods = ({ pipelineRun }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });

    const library_type = (pipelineRun.input_parameters?.library_type as string) ?? '';
    const pipeline_name = pipelineRun.pipeline_version.pipeline.pipeline_repo;
    const version = pipelineRun.pipeline_version.version;
    const genome = pipelineRun.genome?.display_name;
    const peakcaller = (pipelineRun.input_parameters?.peakcaller as string) ?? '';
    const normalisation_mode = (pipelineRun.input_parameters?.normalisation_mode as string) ?? '';
    return (
        <div className="space-y-8">
            <div className="space-y-8" ref={ref}>
                <section>
                    <MethodsSectionTitle>CUT&RUN pipeline</MethodsSectionTitle>
                    <p className="mb-4">
                        <Bold>
                            <Mono>{library_type}</Mono>
                        </Bold>{' '}
                        FASTQ files were processed using the <Bold>{pipeline_name}</Bold> pipeline (v{version}).
                        <sup>1,2,3</sup> Adapter sequences were removed with <Mono>Trim Galore</Mono>
                        <sup>4</sup>. Reads were aligned with <Mono>Bowtie2</Mono>
                        <sup>5</sup> to <Bold>{genome}</Bold> and duplicates were removed for only control samples using{' '}
                        <Mono>picard</Mono>
                        <sup>6</sup>.
                    </p>
                    <p>
                        Generally speaking, &quot;peaks&quot; refer to regions where at least one of the target samples
                        significantly exceeds its matched control for a given genomic region. Here, peaks were called
                        with{' '}
                        <Mono>
                            <Bold>{peakcaller}</Bold>
                        </Mono>
                        <sup>7</sup> (normalization mode: <Bold>{normalisation_mode}</Bold>). Peaks were defined as
                        regions where at least one of the target samples significantly exceeds its matched IgG control
                        for a given genomic region. Consensus peaks were merged across all samples using{' '}
                        <Mono>BEDTools</Mono>
                        <sup>8</sup> and annotated to the nearest gene transcriptional start site using{' '}
                        <Mono>HOMER</Mono>
                        <sup>9</sup>. Peak counts were generated using <Mono>featureCounts</Mono>
                        <sup>10</sup>.
                    </p>
                </section>
                <section>
                    <MethodsSectionTitle>References</MethodsSectionTitle>
                    <ol className="ml-4 list-decimal space-y-4">
                        <li>
                            Ewels PA, Peltzer A, Fillinger S, Patel H, Alneberg J, Wilm A, Garcia MU, Di Tommaso P,
                            Nahnsen S. The nf-core framework for community-curated bioinformatics pipelines.{' '}
                            <Italic>Nat Biotechnol</Italic> (2020). <DoiLink doi="10.1038/s41587-020-0439-x" />.
                        </li>
                        <li>
                            nf-core/cutandrun (latest version).{' '}
                            <a href="https://nf-co.re/cutandrun" target="_blank" rel="nofollow noopener noreferrer">
                                https://nf-co.re/cutandrun
                            </a>
                            . <DoiLink doi="10.5281/zenodo.5653535" />.
                        </li>
                        <li>
                            Meers MP, Bryson TD, Henikoff JG, Henikoff S. Improved CUT&RUN chromatin profiling tools.{' '}
                            <Italic>eLife</Italic> (2019). <DoiLink doi="10.7554/eLife.46314" />.
                        </li>
                        <li>
                            FelixKrueger/TrimGalore (latest version).{' '}
                            <a
                                href="https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                className="break-words"
                            >
                                https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/
                            </a>
                            . <DoiLink doi="10.5281/zenodo.5127898" />.
                        </li>
                        <li>
                            Langmead B, Salzberg S. Fast gapped-read alignment with Bowtie 2.{' '}
                            <Italic>Nat Methods</Italic> (2012).{' '}
                            <a
                                href="https://bowtie-bio.sourceforge.net/"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                            >
                                https://bowtie-bio.sourceforge.net/
                            </a>
                            .
                        </li>
                        <li>
                            Picard Toolkit. Broad Institute (2019).{' '}
                            <a
                                href="https://broadinstitute.github.io/picard/"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                            >
                                https://broadinstitute.github.io/picard/
                            </a>
                            .
                        </li>
                        <li>
                            Meers MP, Tenenbaum D, Henikoff S. Peak calling by Sparse Enrichment Analysis for CUT&RUN
                            chromatin profiling. <Italic>Epigenetics and Chromatin</Italic>. (2019).{' '}
                            <DoiLink doi="10.1186/s13072-019-0287-4" />.
                        </li>
                        <li>
                            Quinlan, AR, Hall, IM. BEDTools: a flexible suite of utilities for comparing genomic
                            features.
                            <Italic>Bioinformatics</Italic>. 2010. PMID:{' '}
                            <a
                                href="https://www.ncbi.nlm.nih.gov/sites/entrez?Db=Pubmed&term=20110278[UID]"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                            >
                                20110278
                            </a>
                            .
                        </li>
                        <li>
                            Heinz S, Benner C, Spann N, Bertolino E et al. Simple Combinations of Lineage-Determining
                            Transcription Factors Prime cis-Regulatory Elements Required for Macrophage and B Cell
                            Identities. <Italic>Mol Cell</Italic>. 38(4):576-589. 2010. PMID:{' '}
                            <a
                                href="https://www.ncbi.nlm.nih.gov/sites/entrez?Db=Pubmed&term=20513432[UID]"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                            >
                                20513432
                            </a>
                            .
                        </li>
                        <li>
                            Liao Y, Smyth GK and Shi W (2014). featureCounts: an efficient general purpose program for
                            assigning sequence reads to genomic features. <Italic>Bioinformatics</Italic>, 30(7):923-30.
                        </li>
                    </ol>
                </section>
            </div>
            <div>
                <Button
                    startIcon={<CopyIcon height={16} width={16} />}
                    color="primary"
                    variant="contained"
                    onClick={() => copyText()}
                >
                    {showCopySuccess ? 'Copied!' : 'Copy'}
                </Button>
            </div>
            <div>
                <MethodsSectionTitle>Pipeline run details</MethodsSectionTitle>
                <PipelineRunItem pipelineRun={pipelineRun} active noRing />
            </div>
        </div>
    );
};

export default CutAndRunPipelineMethods;
