import { PlotListItem } from '@models/Plot';
import Experiment from '@models/Experiment';
import React, { useMemo } from 'react';
import { Item } from '@components/MoreMenuIconButton';
import { getExperimentCopyTooltipCopy, isExperimentCopyDisabled } from '@util/ExperimentUtil';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { CheckCircleIcon, PresentationChartBarIcon } from '@heroicons/react/outline';
import useArchiveExperiment from '@hooks/useArchiveExperiment';
import { PermissionName } from '@models/Permission';
import { hasPermission } from '@util/PermissionUtil';
import { UserPlusIcon } from '@components/icons/custom/UserPlusIcon';
import { ArchiveIcon } from '@components/icons/custom/ArchiveIcon';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { EditIcon } from '@components/icons/custom/EditIcon';
import EyeOffIcon from '@heroicons/react/outline/EyeOffIcon';
import useLabPermissions from '@hooks/useLabPermissions';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import { filterOnlyCompletePlotItems } from '@components/plots/PlotUtil';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import useAuth from '@hooks/useAuth';
import { useRouter } from 'next/router';

type Props = {
    clearAllAnalyses: () => void;
    experiment: Experiment;
    plotListItems: PlotListItem[];
    setBenchlingExportOpen: (open: boolean) => void;
    setCopyOpen: (open: boolean) => void;
    setEditOpen: (open: boolean) => void;
    setEvidenceModalOpen: (open: boolean) => void;
    setPresentationMode: (open: boolean) => void;
};
const useExperimentNavMenuItems = ({
    clearAllAnalyses,
    experiment,
    plotListItems,
    setCopyOpen,
    setEditOpen,
    setEvidenceModalOpen,
    setPresentationMode,
}: Props) => {
    const router = useRouter();

    const { canCreateExperiment } = useLabPermissions();
    const permissions = useExperimentPermissions(experiment);
    const { updateExperimentSharingModal } = useLabSpaceContext();
    const { archiveExperiment, unArchiveExperiment } = useArchiveExperiment(experiment);
    const completedPlots = filterOnlyCompletePlotItems(plotListItems);
    const hasCompletedPlots = (completedPlots?.length ?? 0) > 0;
    const experimentPath = `/experiments/${experiment?.pluto_id?.toUpperCase()}`;
    const { features } = useOrganizationPermissions();
    const { user } = useAuth();
    const is_staff = user?.is_staff;

    const goToAdminPage = () => {
        router.push(`${experimentPath}/admin`);
    };

    const menuItems = useMemo<Item[]>(() => {
        const menuItems: Item[] = [];
        if (!experiment?.is_archived) {
            if (hasPermission(experiment, { requires: PermissionName.edit_experiment })) {
                menuItems.push({
                    label: 'Edit',
                    closeOnClick: true,
                    onClick: () => setEditOpen(true),
                    icon: <EditIcon width={14} height={14} className="mr-1" />,
                });
            }

            if (hasPermission(experiment, { requires: [PermissionName.invite_experiment_users] })) {
                menuItems.push({
                    label: 'Invite',
                    onClick: () => updateExperimentSharingModal({ item: experiment, open: true }),
                    closeOnClick: true,
                    icon: <UserPlusIcon height={16} width={16} />,
                });
            }

            if (!!features?.experiment_features.biomarkers_enabled) {
                menuItems.push({
                    label: 'Save as evidence',
                    onClick: () => setEvidenceModalOpen(true),
                    icon: <CheckCircleIcon className="h-4 w-4" />,
                    closeOnClick: true,
                });
            }

            // copy experiment should be after invite and before archive
            if (canCreateExperiment && hasPermission(experiment, { requires: PermissionName.view_experiment })) {
                const copyDisabled = isExperimentCopyDisabled(experiment);
                const copyTooltip = getExperimentCopyTooltipCopy(experiment);

                menuItems.push({
                    label: 'Copy',
                    closeOnClick: true,
                    icon: <CopyIcon width={14} height={14} className="mr-1" />,
                    disabled: copyDisabled,
                    tooltip: copyDisabled ? copyTooltip : undefined,
                    onClick: () => setCopyOpen(true),
                });
            }
            if ((plotListItems?.length ?? 0) > 0) {
                menuItems.push({
                    label: 'Present',
                    onClick: () => {
                        setPresentationMode(true);
                    },
                    closeOnClick: true,
                    icon: <PresentationChartBarIcon width={18} />,
                    disabled: !hasCompletedPlots,
                    tooltip: !hasCompletedPlots ? 'Presentation mode requires at least one completed plot' : undefined,
                });
            }
            if (
                hasPermission(experiment, { requires: [PermissionName.edit_experiment] }) &&
                (plotListItems?.length ?? 0) > 0
            ) {
                menuItems.push({
                    label: 'Clear analyses',
                    onClick: clearAllAnalyses,
                    closeOnClick: true,
                    icon: <ArchiveIcon height={16} width={16} />,
                    color: 'danger-light',
                });
            }
            if (hasPermission(experiment, { requires: [PermissionName.archive_experiment] })) {
                menuItems.push({
                    label: 'Archive',
                    onClick: archiveExperiment,
                    closeOnClick: true,
                    icon: <ArchiveIcon height={16} width={16} />,
                    color: 'danger-light',
                });
            }
        } else {
            menuItems.push({
                label: 'Un-archive',
                closeOnClick: true,
                icon: <ArchiveIcon width={18} />,
                onClick: unArchiveExperiment,
            });
        }
        if (is_staff) {
            menuItems.push({
                label: 'Admin',
                closeOnClick: true,
                icon: <EyeOffIcon width={18} />,
                onClick: goToAdminPage,
            });
        }

        return menuItems;
    }, [permissions]);

    return { menuItems };
};

export default useExperimentNavMenuItems;
