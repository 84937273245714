import * as Yup from 'yup';

/** Validation schema for Overlap Analysis  */
export const overlapAnalysisInputSchema = Yup.object({
    name: Yup.string().nullable(),
    adj_pval_threshold: Yup.number().when('target_genes_format', {
        is: (value: string) => value === 'differential',
        then: Yup.number().required('Please select an adjusted p-value threshold'),
        otherwise: Yup.number().nullable(),
    }),
    fc_direction: Yup.string().when('target_genes_format', {
        is: (value: string) => value === 'differential',
        then: Yup.string().required('Please select a fold change direction'),
        otherwise: Yup.string(),
    }),
    fc_lower_threshold: Yup.number().when(['target_genes_format', 'fc_direction'], {
        is: (target_genes_format: string, fc_direction: string) =>
            target_genes_format === 'differential' && (fc_direction === 'both' || fc_direction === 'down'),
        then: Yup.number().required('Please select a lower fold change threshold'),
        otherwise: Yup.number().nullable(),
    }),
    fc_upper_threshold: Yup.number().when(['target_genes_format', 'fc_direction'], {
        is: (target_genes_format: string, fc_direction: string) =>
            target_genes_format === 'differential' && (fc_direction === 'both' || fc_direction === 'up'),
        then: Yup.number().required('Please select an upper fold change threshold'),
        otherwise: Yup.number().nullable(),
    }),
    differential_analysis_id: Yup.string()
        .label('Analysis')
        .nullable()
        .when('target_genes_format', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
    differential_analysis_shortname: Yup.string()
        .label('Analysis type')
        .nullable()
        .when('target_genes_format', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
    targets_csv: Yup.mixed().when(['target_genes_format', 'targets_csv_filename'], {
        is: (target_genes_format: string, targets_csv_filename: string) =>
            target_genes_format === 'file' && !targets_csv_filename,
        then: Yup.mixed().required('Please upload a target csv'),
        otherwise: Yup.mixed().nullable(),
    }),
    targets_csv_filename: Yup.string().nullable(),
    target_genes_format: Yup.string().required('Please select a target format'),
    targets: Yup.array(Yup.string()).when('target_genes_format', {
        is: (value: string) => value === 'list',
        then: Yup.array(Yup.string())
            .min(2, 'Please select at least 2 targets')
            .required('Please enter a list of target genes'),
        otherwise: Yup.array(Yup.string()).nullable(),
    }),
    targets_biomarker_list_id: Yup.string().when('target_genes_format', {
        is: (value: string) => value === 'biomarker',
        then: Yup.string().required('Please select a biomarker list'),
        otherwise: Yup.string().nullable(),
    }),
});
