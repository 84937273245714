import { usePlotContext } from '@contexts/PlotContext';
import IGVPlotView from '@components/analysisCategories/genome/plots/IGVPlotView';
import ServerImagePlotView from '@components/analysisCategories/comparative/plots/ServerImagePlotView';
import React from 'react';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import { OtherAnalysisPlotData } from '@models/ExperimentData';
import PlotlyPieChart from './plots/pie/PlotlyPieChart';
import PlotlyStackedBarPlot from './plots/stackedBars/PlotlyStackedBarPlot';
import PlotNoDataView from '../../plots/PlotNoDataView';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const displaysWithData = ['pie_chart', 'stacked_bar_plot'];

type Props = {
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
};

const GenomePlotDisplayView = ({ customPlotStylingOptions }: Props) => {
    const { plot, plotData } = usePlotContext();
    const data = plotData as OtherAnalysisPlotData | null;
    const display_type = plot.display.display_type;

    if (!data) {
        return <div>No plot data</div>;
    }

    if (data.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }

    if (data.count === 0 && displaysWithData.includes(display_type)) {
        return <PlotNoDataView />;
    }

    switch (display_type) {
        case 'igv_plot':
            return <IGVPlotView />;
        case 'tornado_plot':
        case 'profile_plot':
            return <ServerImagePlotView />;
        case 'pie_chart':
            return <PlotlyPieChart />;
        case 'stacked_bar_plot':
            return <PlotlyStackedBarPlot customPlotStylingOptions={customPlotStylingOptions} />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default GenomePlotDisplayView;
