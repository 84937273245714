import React from 'react';
import Plot from '@models/Plot';
import { AssaySummaryAnalysisParameters, BaseAnalysisParameters } from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import SingleSelectField, { SelectItem } from '@components/filters/fields/SingleSelectField';
import { Collapse, Switch } from '@mui/material';
import cn from 'classnames';
import { useSwitchStyles } from '@components/SwitchStyles';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import { useFormikContext } from 'formik';
import { LongitudinalAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import SimpleTargetsField from '@components/experiments/analyses/assaySummary/SimpleTargetsField';
import { isSimpleExperimentType } from '@models/ExperimentType';
import { MAX_TARGETS } from '@models/analysis/LongitudinalAnalysis';

type Props = { plot: Plot; analysisParameters: BaseAnalysisParameters | null | undefined; experiment: Experiment };
const LongitudinalAnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    const switchStyles = useSwitchStyles();
    const { values, setFieldValue } = useFormikContext<LongitudinalAnalysisFormValues>();

    const splitGroups = values.split_samples_into_groups;

    const isSimpleExperiment = isSimpleExperimentType(experiment.type.shortname);

    return (
        <>
            <SingleSelectField
                name="x_axis_variable_id"
                type="number"
                label="Longitudinal variable"
                className="mb-4"
                options={(analysisParameters?.variables ?? []).map<SelectItem<number>>((variable) => ({
                    value: variable.id,
                    label: variable.display_name,
                }))}
            />

            {isSimpleExperiment ? (
                <SimpleTargetsField
                    name="targets"
                    analysisParameters={analysisParameters as AssaySummaryAnalysisParameters}
                    maxItems={MAX_TARGETS}
                />
            ) : (
                <AsyncTargetPicker experiment={experiment} name="targets" maxItems={MAX_TARGETS} />
            )}

            <section>
                <h4
                    className="group mb-1 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setFieldValue('split_samples_into_groups', !values.split_samples_into_groups)}
                >
                    <span>Split samples into groups</span>
                    <span>
                        <Switch
                            sx={switchStyles}
                            name="show_data_points"
                            checked={values.split_samples_into_groups}
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    setFieldValue('group_ids', []);
                                }
                                setFieldValue('split_samples_into_groups', e.target.checked, !e.target.checked);
                            }}
                        />
                    </span>
                </h4>
                <div className="relative">
                    <p
                        className={cn('absolute transition-opacity', {
                            'opacity-0': splitGroups,
                            'delay-[.3s]': !splitGroups,
                        })}
                    >
                        All samples will be treated as a single data series
                    </p>
                    <Collapse className="mt-2" in={splitGroups}>
                        <>
                            <AnalysisVariablesMultiSelectField variables={analysisParameters?.variables ?? []} />

                            <AnalysisGroupsField plot={plot} experiment={experiment} />
                        </>
                    </Collapse>
                </div>
            </section>
        </>
    );
};

export default LongitudinalAnalysisFormFields;
