import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import TextAreaField from '@components/forms/TextAreaField';
import FieldLabel from '@components/filters/labels/FieldLabel';
import { useState } from 'react';
import Button from '@components/Button';
import PlutoMarkdownContentView from '@components/PlutoMarkdownContentView';
import { useFormikContext } from 'formik';
import { TextAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { MarkDownIcon } from '@components/icons/custom/MarkDown';
import {
    isAssaySummaryAnalysisParameters,
    isDifferentialExpressionAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import SimpleTargetsField from '@components/experiments/analyses/assaySummary/SimpleTargetsField';
import AsyncTargetPicker from '@components/AsyncTargetPicker';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null | undefined };
const TextAnalysisFormFields = ({ experiment, analysisParameters }: Props) => {
    const [preview, setPreview] = useState(false);
    const { values } = useFormikContext<TextAnalysisFormValues>();

    const content = values.content;

    return (
        <div className="space-y-8">
            <div>
                <div className="flex items-center justify-between space-x-4">
                    <FieldLabel className="text-sm">Content</FieldLabel>
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setPreview((current) => !current);
                        }}
                    >
                        {preview ? 'Write' : 'Preview'}
                    </Button>
                </div>
                {preview ? (
                    <PlutoMarkdownContentView
                        content={content ?? ''}
                        className="max-h-[50vh] overflow-auto rounded-lg border border-gray-200 bg-gray-50 p-4"
                    />
                ) : (
                    <div className="space-y-1">
                        <TextAreaField name="content" label={null} minRows={5} maxRows={25} className="no-margin" />
                        <p className="space-x-1 px-1 text-xs text-gray-400">
                            <MarkDownIcon className="inline-block" />
                            <span>Markdown formatting is supported.</span>
                            <a
                                href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax"
                                className="link"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                            >
                                <span className="">Learn more</span>
                            </a>
                        </p>
                    </div>
                )}
            </div>

            {experiment.type.shortname === 'simple' &&
                analysisParameters &&
                isAssaySummaryAnalysisParameters(analysisParameters) && (
                    <SimpleTargetsField name="targets" analysisParameters={analysisParameters} />
                )}
            {experiment.type.shortname !== 'simple' &&
                analysisParameters &&
                isDifferentialExpressionAnalysisParameters(analysisParameters) && (
                    <AsyncTargetPicker experiment={experiment} name="targets" />
                )}

            <div className="">
                <TextAreaField name="methods" label="Methods" minRows={4} />
            </div>
        </div>
    );
};

export default TextAnalysisFormFields;
