import React from 'react';
import { Alert } from '@mui/material';
import Plot from '@models/Plot';
import { SpreadsheetAnalysis } from '@models/analysis/SpreadsheetAnalysis';
import CsvTableViewer from '@components/csvViewer/csvTableViewer';
import SpreadsheetDisplayOption from '@models/plotDisplayOption/SpreadsheetDisplayOption';
import Link from 'next/link';
import NewTabIcon from '@/src/components/icons/custom/NewTabIcon';
import { isNotBlank } from '@util/StringUtil';

type Props = {
    plot: Plot;
    setPlotDragEnabled?: (enabled: boolean) => void;
};

const SpreadsheetPlotView: React.FC<Props> = ({ plot }) => {
    const analysis = plot.analysis as SpreadsheetAnalysis | null;
    const display = plot.display as SpreadsheetDisplayOption | null;
    const hasCaption = isNotBlank(display?.caption);

    if (!analysis) {
        return <Alert severity="error">Analysis data is missing.</Alert>;
    }

    const spreadsheetUrl = analysis.spreadsheet_url;

    if (!spreadsheetUrl) {
        return <Alert severity="error">Spreadsheet URL is missing.</Alert>;
    }

    const isCsv = spreadsheetUrl.includes('.csv'); // Checks if the file is a CSV
    const officeViewerUrl = `https://view.officeapps.live.com/op/view.aspx?embedded=true&src=${encodeURIComponent(
        spreadsheetUrl,
    )}`;

    const renderViewer = () => {
        if (isCsv) {
            const proxyUrl = `/api/ingest/spreadsheet?url=${encodeURIComponent(spreadsheetUrl)}`;
            return (
                <div style={{ height: '90%', overflow: 'scroll' }}>
                    <CsvTableViewer url={proxyUrl} />
                </div>
            );
        } else {
            return (
                <iframe
                    src={officeViewerUrl}
                    frameBorder="0"
                    style={{ width: '100%', height: '90%' }}
                    allowFullScreen
                />
            );
        }
    };

    return (
        <div className="mt-4 flex h-full w-full flex-grow flex-col justify-center">
            <div className="relative mx-auto h-full w-full">
                {renderViewer()}
                <div className="mt-2 flex items-center justify-between">
                    <div>{hasCaption && <p className="mt-2">{display?.caption}</p>}</div>
                    <div className="text-right">
                        {display?.spreadsheet_source && (
                            <Link href={display.spreadsheet_source_url || '#'} target="_blank">
                                {display.spreadsheet_source} <NewTabIcon className="inline-block w-4" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetPlotView;
