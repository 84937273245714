import React from 'react';
import NoSsr from '@components/NoSsr';
import cn from 'classnames';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import AnnotationPlotContentView from './AnnotationPlotContentView';
import LoadingMessage from '../../LoadingMessage';
import { useExperimentAnnotationContext } from '@/src/contexts/ExperimentAnnotationContext';

export type LegendPosition = 'bottom' | 'right' | 'auto';

const AnnotationPlotCardView = () => {
    const {
        annotationSetDetails: data,
        annotationSetDetailsLoading: loading,
        annotationSetDetailsError: error,
    } = useExperimentAnnotationContext();
    const dataReady = Boolean(data);
    const noData = !data && !loading && !error;

    return (
        <NoSsr>
            <div className={cn('relative h-full w-full overflow-hidden bg-white')} data-cy="annotation-plot-card">
                <div className="h-full">
                    <div className="flex h-full flex-grow flex-col-reverse">
                        <div
                            className={cn('flex h-full flex-col justify-center space-y-2', {
                                'justify-between': dataReady,
                            })}
                        >
                            {data ? (
                                <div className="flex h-full max-h-full w-full flex-col overflow-hidden">
                                    <PlutoErrorBoundary>
                                        <AnnotationPlotContentView signedUrl={data.signed_url} />
                                    </PlutoErrorBoundary>
                                </div>
                            ) : noData ? (
                                <div className="flex flex-1 items-center justify-center">No plot available.</div>
                            ) : (
                                <div className="flex flex-1 items-center justify-center">
                                    <LoadingMessage immediate message="Loading plot..." />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </NoSsr>
    );
};

export default AnnotationPlotCardView;
