import PlotShellActions from '@components/plots/PlotShellActions';
import React from 'react';
import { DisplayTypeIcon } from '@components/experiments/ExperimentIcons';
import { usePlotContext } from '@contexts/PlotContext';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import { BarPlotIcon } from '@components/icons/custom/BarPlotIcon';

const PlotNoDataView = () => {
    const { plot, hideEditButton, experiment } = usePlotContext();
    const permissions = useExperimentPermissions(experiment);
    return (
        <div className="flex h-full flex-col justify-center">
            <div className="space-y-8 py-4">
                <div className="flex flex-col items-center space-y-4">
                    <div className="mx-auto rounded-full bg-indigo-100 p-4 text-indigo-800">
                        {plot?.display?.display_type ? (
                            <DisplayTypeIcon type={plot.display.display_type} width={24} height={24} />
                        ) : (
                            <BarPlotIcon width={24} height={24} />
                        )}
                    </div>
                    <div className="text-center">
                        <p className="text-xl font-semibold text-dark">No data available</p>
                        <p className="text-base">There is no data available for the parameters you selected.</p>
                    </div>
                </div>
                {plot && !hideEditButton && permissions.canEdit && (
                    <PlotShellActions editText={'Edit parameters'} plot={plot} editPanel="plot" />
                )}
            </div>
        </div>
    );
};

export default PlotNoDataView;
