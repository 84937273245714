import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

export type Props = {
    confirmLoading?: boolean;
    error?: ReactNode;
    icon?: ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
    open?: boolean;
};
const AcceptPreprocessModal = ({ confirmLoading = false, error, icon, onCancel, onConfirm, open = false }: Props) => {
    const { isSmAndDown } = useBreakpoints();

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle iconStart={icon} iconEnd={icon} title="Are you sure?" />
            <DialogContent className="mb-4 space-y-4">
                {error && <Alert severity="error">{error}</Alert>}
                <p className="tracking-tight">
                    Once you&apos;ve finalized the parameters of this preprocessing step, accept the results in order to
                    proceed to the next step!
                </p>

                <p className="tracking-tight">
                    <span className="font-semibold">What happens when I accept these results?</span> After a
                    preprocessing step has been accepted, you&apos;ll be able to move onto the next step in the
                    workflow. The results in this step will become view-only.
                </p>

                <p className="tracking-tight">
                    <span className="font-semibold">What if I want to make changes later?</span> Not a problem! Should
                    you need to make modifications in the future, you will have the option to either copy the workflow
                    from this step, or rollback to this step.{' '}
                    <a
                        href="https://help.pluto.bio/en/articles/using-single-cell-rna-sequencing-preprocessing-workflows"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn more
                    </a>
                </p>

                <p className="text-lg font-semibold tracking-tight text-dark">
                    Ready to accept the results and proceed to the next preprocessing step?
                </p>
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button
                    variant={isSmAndDown ? 'outlined' : 'text'}
                    color="primary"
                    onClick={onCancel}
                    fullWidth={isSmAndDown}
                >
                    No, take me back
                </Button>
                <Button
                    onClick={onConfirm}
                    loading={confirmLoading}
                    disabled={confirmLoading}
                    fullWidth={isSmAndDown}
                    className="!bg-green-100 !px-4 !text-green-800"
                >
                    Yes, accept & proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AcceptPreprocessModal;
