import Experiment from '@models/Experiment';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import useSWR from 'swr';
import { GenericCellData, TabularData } from '@models/ExperimentData';
import Endpoints from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import DataTable from '@components/dataTable/DataTable';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { roundToDecimal } from '@util/StringUtil';
import NoDataFoundMessage from '../../NoDataFoundMessage';

type Props = { experiment: Experiment; analysis: ExperimentAnalysis };
const SummaryMeasuresTable = ({ experiment, analysis }: Props) => {
    const analysisId = analysis.uuid;
    const experimentId = experiment.uuid;
    const { data, error } = useSWR<TabularData>(() =>
        !analysisId
            ? null
            : Endpoints.lab.experiment.analysis.summary({
                  experimentId,
                  analysisId,
              }),
    );
    const loading = !data && !error;

    const processedData = useMemo(() => {
        if (!data) {
            return null;
        }
        const headers = data.headers.filter((h) => !h.toLowerCase().endsWith('shortname'));
        return { ...data, headers };
    }, [data]);

    if (loading) {
        return <LoadingMessage>Loading measures</LoadingMessage>;
    }

    if (!processedData || (processedData?.items?.length ?? 0) === 0) {
        return <NoDataFoundMessage subtitle="No summary table was found." />;
    }

    return (
        <div>
            <DataTable
                data={processedData}
                cellRenderer={() =>
                    ({ value }: CellProps<GenericCellData>) =>
                        Number.isFinite(value) ? roundToDecimal(value, { decimals: 5 }) : value
                    }
            />
        </div>
    );
};

export default SummaryMeasuresTable;
