import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import React from 'react';
import ProteinProteinInteractionAnalysisMethodsSection from '../analyses/ProteinProteinInteractionAnalysisMethodsSection';
import { isProteinProteinInteractionAnalysisParameters } from '@/src/models/AnalysisParametersHelpers';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const NetworkGraphMethods = ({ plot, analysisParameters }: Props) => {
    if (
        plot.analysis_type === 'protein_protein_interaction' &&
        isProteinProteinInteractionAnalysisParameters(analysisParameters)
    ) {
        return <ProteinProteinInteractionAnalysisMethodsSection plot={plot} />;
    }
    return <p>Unsupported analysis type for network graph, we&apos;re unable to find any methods.</p>;
};

export default NetworkGraphMethods;
