import Plot from '@models/Plot';
import React from 'react';
import { SeuratOverRepresentationAnalysis } from '@/src/models/analysis/SeuratOverRepresentationAnalysis';
import SeuratOverRepresentationFields from './SeuratOverRepresentationFields';
import SampleCorrelationFields from './SampleCorrelationFields';

type Props = { plot: Plot };
const DotPlotFields = ({ plot }: Props) => {
    const analysis = plot.analysis as SeuratOverRepresentationAnalysis | null;

    switch (analysis?.analysis_type) {
        case 'seurat_over_representation':
            return <SeuratOverRepresentationFields plot={plot} />;
        case 'sample_correlation':
            return <SampleCorrelationFields plot={plot} />;
        default:
            return <p>No dot plot display options found for analysis {analysis?.name}</p>;
    }
};

export default DotPlotFields;
