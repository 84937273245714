import { IconProps } from '@components/icons/CustomIcons';

export const FlaskIcon = (props?: IconProps) => {
    const { width = 16, height = 18 } = { ...props };
    return (
        <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.37 14.1855L11.2497 7.55859V2.25H11.5309C11.9985 2.25 12.3747 1.87383 12.3747 1.40625V0.84375C12.3747 0.376172 11.9985 0 11.5309 0H4.21842C3.75084 0 3.37467 0.376172 3.37467 0.84375V1.40625C3.37467 1.87383 3.75084 2.25 4.21842 2.25H4.49967V7.55859L0.379358 14.1855C-0.65072 15.8414 0.537561 18 2.49225 18H13.2571C15.2153 18 16.4001 15.8379 15.37 14.1855ZM4.84772 11.25L6.54225 8.52187C6.67233 8.33906 6.74615 8.11406 6.74615 7.875V2.25H8.99615V7.875C8.99615 8.11758 9.0735 8.33906 9.20006 8.52187L10.8946 11.25H4.84772Z"
                fill="currentColor"
            />
        </svg>
    );
};
