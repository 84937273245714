import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const TSNEAnalysisIcon = ({ width = 32, height = 32, className = defaultTextLightClass, style }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m5 7.713 10 1.928v9.641L5 17.354v-9.64Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 16 4 1V6.785L9 4.857V8"
            />
            <rect width="22" height="22" x="1" y="1" stroke="currentColor" strokeWidth="2" rx="2" />
            <path
                fill="currentColor"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth=".5"
                d="M9.967 15.578a.25.25 0 0 0 .273-.224l.265-2.675.84.249a.25.25 0 0 0 .32-.215l.084-.844a.25.25 0 0 0-.192-.268l-2.916-.688a.25.25 0 0 0-.306.218l-.084.844a.25.25 0 0 0 .179.265l.711.208-.274 2.77a.25.25 0 0 0 .225.273l.875.087Z"
            />
        </svg>
    );
};
