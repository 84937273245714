// Draw statistical test bars
import { SummaryStatisticalTestResult } from '@models/SummaryStatisticalTestResult';
import { ParameterOption } from '@models/AnalysisParameters';
import { yDomain } from '@components/plots/PlotUtil';
import { GroupSeries } from '@components/plots/PlotTypes';
import { ErrorBarOption } from '@models/PlotConfigs';

export type StatSummary = {
    stat: SummaryStatisticalTestResult;
    meta: {
        targetName: string;
        groupName1: string;
        groupName2: string;
        group1: ParameterOption | null;
        group2: ParameterOption | null;
        y1: number;
        y2: number;
        x1: number;
        x2: number;
    };
};

export type StatOverlap = {
    stat: SummaryStatisticalTestResult;
    groupId1: ParameterOption | null;
    groupId2: ParameterOption | null;
    yMax: number;
};

export const getErrorBarDeviationY = (d: GroupSeries, errorBarOption: ErrorBarOption): number => {
    const domain = yDomain(d);
    switch (errorBarOption) {
        case 'sem':
            return domain.standardErrorOfMean ?? 0;
        default:
        case 'sd':
            return domain.standardDeviation ?? 0;
    }
};
