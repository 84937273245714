import { allThemeStyles } from '@models/PlotConfigs';
import SelectableItem from '@components/forms/SelectableItem';
import { ThemeStyleIcon } from '@components/experiments/ExperimentIcons';
import React, { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

type Props = { label?: ReactNode };
const ThemeStyleField = ({ label = 'Style' }: Props) => {
    const { values, setFieldValue } = useFormikContext<BasePlotDisplayOptionFormValues>();
    return (
        <div className="form-field no-margin">
            <span className="field-label">{label}</span>
            <div className="flex flex-row flex-wrap">
                {allThemeStyles.map((style, index) => (
                    <SelectableItem
                        key={`color_theme_${index}`}
                        onSelect={() => setFieldValue('theme_style', style)}
                        selected={values.theme_style === style}
                        className="mb-2 mr-2"
                    >
                        <ThemeStyleIcon style={style} />
                    </SelectableItem>
                ))}
            </div>
        </div>
    );
};

export default ThemeStyleField;
