import BasePlotBuilder, { ConstructorParams as BaseParams } from '@components/plots/builders/BasePlotBuilder';
import { ParameterOption } from '@models/AnalysisParameters';
import { SummaryStatisticalTestResult } from '@models/SummaryStatisticalTestResult';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { DotSymbol, FlatSample, GroupSeries } from '@components/plots/PlotTypes';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import { max, mean, median, min } from 'd3';

type AnalysisParameterHelpers = ReturnType<typeof getAnalysisParametersHelpers>;
export type GroupStat = { median?: number; mean?: number; group_name: string; group_id: number; target_name: string };
export type ConstructorParams = BaseParams & {
    getGroupById: (id: number) => ParameterOption | null;
    visiblePlotStats?: SummaryStatisticalTestResult[] | null;
    hasErrorBars?: boolean;
} & Pick<AnalysisParameterHelpers, 'getTargetById'>;

export default abstract class SummaryAnalysisPlotBuilder extends BasePlotBuilder {
    allSamples: FlatSample[];
    getGroupById: (id: number) => ParameterOption | null;
    getTargetById: (targetId: number | string) => ParameterOption | null;

    protected constructor(options: ConstructorParams) {
        super(options);
        this.allSamples = this.makeFlatSamples();
        this.getTargetById = options.getTargetById;
        this.getGroupById = options.getGroupById;
    }

    get targetData() {
        return this.data.target_groups;
    }

    get analysis(): AssaySummaryAnalysis | null {
        return (this.plot?.analysis ?? null) as AssaySummaryAnalysis | null;
    }

    get group_display_order() {
        return this.analysis?.group_display_order ?? [];
    }

    get firstTargetGroups() {
        return this.sortGroups(this.targetData[0]?.groups ?? []);
    }

    get maxSampleValue() {
        return max(this.allSamples, (d) => d.value ?? 0) ?? 0;
    }

    get minSampleValue() {
        return min(this.allSamples, (d) => d.value ?? 0) ?? 0;
    }

    getTargetNames = () => {
        return this.targetData.map((t) => t.target_name);
    };

    getTargetSeriesByName = (targetName?: string | undefined | null) => {
        if (!targetName) {
            return null;
        }
        const targetSeries = this.targetData.find((d) => d.target_name === targetName);
        return targetSeries ?? null;
    };

    /**
     * Will return a new array of sorted groups
     * @param {GroupSeries[]} groups
     * @returns {GroupSeries[] | undefined}
     */
    sortGroups = (groups: GroupSeries[]) => {
        const group_display_order = this.group_display_order;
        return [...groups].sort((g1, g2) => {
            return group_display_order.indexOf(g1.group_id) - group_display_order.indexOf(g2.group_id);
        });
    };

    getGroupSeries = ({ groupId, targetName }: { groupId?: number; targetName?: string }) => {
        const targetSeries = this.getTargetSeriesByName(targetName);
        const groupSeries = targetSeries?.groups.find((g) => g.group_id === groupId) ?? null;

        return { targetSeries, groupSeries, groupId, targetName };
    };

    buildGroupFlatSamples = ({ group: g, target_name }: { group: GroupSeries; target_name: string }): FlatSample[] => {
        return g.samples.map((s) => ({
            ...s,
            group_name: g.group_name,
            group_id: g.group_id,
            target_name,
            symbol: DotSymbol.circle,
            symbol_color: `text-dark fill-current`,
        }));
    };

    protected makeFlatSamples = () => {
        const group_display_order = this.group_display_order;
        return this.targetData.reduce((prev, series) => {
            const samples = series.groups
                .sort((g1, g2) => {
                    return group_display_order.indexOf(g2.group_id) - group_display_order.indexOf(g1.group_id);
                })
                .map((g) => this.buildGroupFlatSamples({ group: g, target_name: series.target_name }))
                .flat();
            prev.push(...samples);
            return prev;
        }, [] as FlatSample[]);
    };

    getSortedGroups = () => {
        const group_display_order = this.group_display_order;
        return [...(this.firstTargetGroups ?? [])]?.sort((g1, g2) => {
            return group_display_order.indexOf(g1.group_id) - group_display_order.indexOf(g2.group_id);
        });
    };

    protected makeFlatGroupStats = () => {
        const group_display_order = this.group_display_order;
        return this.targetData.reduce((prev, series) => {
            const groupStats: GroupStat[] = series.groups
                .sort((g1, g2) => {
                    return group_display_order.indexOf(g2.group_id) - group_display_order.indexOf(g1.group_id);
                })
                .map((g: GroupSeries) => {
                    const groupSamples = g.samples.map((s) => ({
                        ...s,
                        group_name: g.group_name,
                        target_name: series.target_name,
                        symbol: DotSymbol.circle,
                        symbol_color: `text-dark fill-current`,
                    }));

                    const groupStat: GroupStat = {
                        median: median(groupSamples, (s) => s.value),
                        mean: mean(groupSamples, (s) => s.value),
                        group_name: g.group_name,
                        group_id: g.group_id,
                        target_name: series.target_name,
                    };
                    return groupStat;
                });

            prev.push(...groupStats);
            return prev;
        }, [] as GroupStat[]);
    };
}
