import React, { ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { PlotContextType, usePlotContext } from '@contexts/PlotContext';
import * as d3 from 'd3';
import { useAspectRatioSizeObserver } from '@hooks/useAspectRatioSizeObserver';
import { removePlotTooltip } from '@components/plots/PlotUtil';
import useUUID from '@hooks/useUUID';
import { useFeatureToggleContext } from '@contexts/FeatureToggleContext';
import Mono from '@components/elements/Mono';

export type DrawChartFn = (options: {
    svgSelection: d3.Selection<SVGSVGElement, unknown, d3.BaseType, unknown>;
    size: { height: number; width: number };
    context: PlotContextType;
    tooltipId: string;
}) => void;

type Props = {
    /**
     * Must be memoized
     */
    drawChart: DrawChartFn;
    children?: ReactNode;
    noHeight?: boolean;
};

/**
 * Draw Chart should be memoized
 * @constructor
 */
const DynamicPlotContainer = ({ drawChart }: Props) => {
    const plotContext = usePlotContext();
    const featureToggles = useFeatureToggleContext();
    const drawCount = useRef<number>(0);
    const {
        publicationMode,
        aspectRatio,
        plot,
        plotData: data,
        resizeNoLeading: noLeading,
        resizeNoTrailing: noTrailing,
        minHeight,
    } = plotContext;
    const { svgRef, containerRef, size } = useAspectRatioSizeObserver({
        aspectRatio,
        noLeading: noLeading,
        noTrailing: noTrailing,
        minHeight,
        useDebounce: true,
    });

    const { uuid } = useUUID();
    const tooltipId = `id_${uuid}`;
    useEffect(() => {
        if (svgRef.current && size) {
            drawChart({
                svgSelection: d3.select(svgRef.current),
                size,
                context: plotContext,
                tooltipId,
            });
            drawCount.current++;
        }
    }, [
        data?.count,
        data?.pipeline_status,
        data?.status,
        data?.data_hash,
        plot.display,
        publicationMode,
        size,
        drawChart,
    ]);

    useEffect(() => {
        return () => {
            removePlotTooltip(tooltipId);
        };
    }, [tooltipId]);

    return (
        <div
            className={cn('relative h-full w-full', {
                'border-2 border-dashed border-cyan-500': featureToggles.isEnabled('plot_size_debug'),
            })}
            ref={containerRef}
            data-chart-type={plot?.display?.display_type}
        >
            <svg
                ref={svgRef}
                viewBox={`0 0 ${size?.width ?? 0} ${size?.height ?? 0}`}
                className=""
                style={{
                    width: '100%',
                    height: '100%',
                    marginRight: '0px',
                    marginLeft: '0px',
                }}
            />
            {featureToggles.isEnabled('plot_size_debug') && (
                <div className="absolute right-0 top-0 bg-cyan-500/50 p-2 text-xs">
                    <p>
                        <Mono>DynamicPlotContainer.tsx</Mono>
                    </p>
                    <p>height: {size?.height}</p>
                    <p>width: {size?.width}</p>
                    <p>drawCount: {drawCount.current}</p>
                </div>
            )}
        </div>
    );
};

export default DynamicPlotContainer;
