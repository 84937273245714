import * as React from 'react';
import { FileFormat } from '@models/Attachment';
import cn from 'classnames';
import { DocumentTextIcon, PhotographIcon } from '@heroicons/react/outline';

type FileTypeIconProps = React.ComponentProps<'svg'> & { fileType?: FileFormat | string; className?: string };
const FileTypeIcon = ({ fileType, className, ...rest }: FileTypeIconProps) => {
    const defaultClasses = cn('shrink-0 h-5 w-5', className);
    const defaultProps: React.ComponentProps<'svg'> = { ...rest, className: defaultClasses, 'aria-hidden': 'true' };
    switch (fileType) {
        case FileFormat.JPEG:
            return <PhotographIcon {...defaultProps} />;
        default:
            return <DocumentTextIcon {...defaultProps} />;
    }
};

export default FileTypeIcon;
