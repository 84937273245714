import useAuth from '@/src/hooks/useAuth';
import { Chat } from '@models/Thread';
import ReactMarkdown from 'react-markdown';
import Logger from '@/src/util/Logger';
import { useEffect, useState } from 'react';
import useApi from '@/src/hooks/useApi';
import Endpoints from '@/src/services/Endpoints';
import { PlutoIntelligenceIcon } from '../../icons/custom/PlutoIntelligenceIcon';

const logger = Logger.make('PlutoIntelligence ChatItem');

type Props = { message: Chat; threadId: string };
type ImageResponse = { image: string };

const ChatItem = ({ message, threadId }: Props) => {
    const { user } = useAuth();
    const [imageSource, setImageSource] = useState<string>('');
    const api = useApi();
    const isImage = message.type === 'image';

    const fetchImageSource = async () => {
        try {
            const newImageResponse: ImageResponse = await api.get(Endpoints.lab.threadImage(threadId, message.content));
            setImageSource(newImageResponse.image);
        } catch (error) {
            console.log('error:', error);
        }
    };

    useEffect(() => {
        if (!message || !isImage) return;
        fetchImageSource();
    }, [message]);

    const imageURL = user?.avatar_url ?? '';

    const components = {
        code({ node, inline, className, children, ...props }) {
            logger.debug(`Rendering a ${node.type} element`);

            const match = /language-(\w+)/.exec(className || '');

            return !inline && match ? (
                <pre className={match[1]}>
                    <code className={className} {...props}>
                        {children}
                    </code>
                </pre>
            ) : (
                <code {...props}>{children}</code>
            );
        },
    };

    if (message.is_assistant)
        return (
            <div className="mb-4 flex w-full flex-col">
                <div className="flex items-center space-x-2">
                    <PlutoIntelligenceIcon height={26} width={26} />
                    <p className="text-sm font-semibold tracking-tight text-black">PI</p>
                </div>
                {isImage && imageSource ? (
                    <div className="flex h-full w-full items-center justify-center">
                        <img
                            className="h-auto w-[80%]"
                            src={`data:image/jpeg;base64,${imageSource}`}
                            alt="Base64 Image"
                        />
                    </div>
                ) : (
                    <ReactMarkdown className="prose ml-8 text-sm" components={components}>
                        {message.type === 'code_interpreter' ? '```\n' + message.content : message.content}
                    </ReactMarkdown>
                )}
            </div>
        );

    return (
        <div className="mb-4 flex w-full flex-col">
            <div className="flex items-center space-x-2">
                <img src={imageURL} className="h-6 w-6 rounded-full" />
                <p className="text-sm font-semibold tracking-tight text-black">
                    {user?.first_name + ' ' + user?.last_name}
                </p>
            </div>
            <p className="ml-8">{message.content}</p>
        </div>
    );
};

export default ChatItem;
