import { useState } from 'react';
import Button, { LoadableButtonProps } from '@components/Button';
import { Dialog, DialogContent } from '@mui/material';
import BaseSpaceBrowser from '@components/baseSpace/BaseSpaceBrowser';
import Experiment from '@models/Experiment';
import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';
import DialogCloseButton from '@components/DialogCloseButton';

type Props = { onSelected: (files: BaseSpaceFile[]) => void; experiment: Experiment } & LoadableButtonProps;
const BaseSpaceModalButton = ({ onSelected, experiment, ...props }: Props) => {
    const [open, setOpen] = useState(false);
    const handleDone = (files: BaseSpaceFile[]) => {
        onSelected(files);
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={() => setOpen(true)} variant="outlined" color="primary" {...props}>
                Import from BaseSpace
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
                <DialogCloseButton onClose={() => setOpen(false)} />
                <DialogContent>
                    <BaseSpaceBrowser onSelected={handleDone} experiment={experiment} onCancel={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BaseSpaceModalButton;
