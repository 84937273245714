import PlutoFile from '@models/PlutoFile';
import FileTypeIcon from '@components/FileTypeIcon';
import { humanFileSize } from '@util/StringUtil';
import { CircularProgress, IconButton } from '@mui/material';
import { DownloadIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import { getFullName } from '@models/User';
import useDownloadData from '@hooks/useDownloadData';
import Endpoints from '@services/Endpoints';
import Experiment from '@models/Experiment';

type Props = {
    deleteDisabled?: boolean;
    editDisabled?: boolean;
    experiment: Experiment;
    file: PlutoFile;
    onEdit?: () => void;
    onRemove?: () => void;
    showCreatedAt?: boolean;
    showCreatedBy?: boolean;
};
const AssayFileItem = ({
    deleteDisabled,
    editDisabled,
    experiment,
    file,
    onEdit,
    onRemove,
    showCreatedAt = false,
    showCreatedBy = true,
}: Props) => {
    const downloadEndpoint = () => {
        return Endpoints.lab.experiment.fileDownload(
            {
                experimentId: experiment.uuid,
                fileId: file.uuid,
            },
            { filename: file.filename },
        );
    };

    const { loading: downloadLoading, startDownload } = useDownloadData({
        baseFilename: file.filename,
        newTab: true,
        endpoint: downloadEndpoint,
    });

    return (
        <div className="flex justify-between overflow-auto rounded-md bg-indigo-50 p-2">
            <div className="flex items-start space-x-2 ">
                <div>
                    <FileTypeIcon fileType={file.file_type} className="mt-1 text-indigo-600" />
                </div>
                <div>
                    <p className=" max-w-full space-x-1 whitespace-normal break-all pr-2 font-semibold text-indigo-500 ">
                        {file.filename}
                    </p>
                    <p className="">
                        <span>{humanFileSize(file.file_size)}</span>
                        {showCreatedBy && (
                            <>
                                {' | '}
                                <span>{getFullName(file.created_by)}</span>
                            </>
                        )}
                        {showCreatedAt && file.created_at && (
                            <>
                                {' | '}
                                <span>
                                    {new Intl.DateTimeFormat('en-US', {
                                        dateStyle: 'medium',
                                        timeStyle: 'short',
                                    }).format(new Date(file.created_at))}
                                </span>
                            </>
                        )}
                    </p>
                </div>
            </div>
            <div className="mt-1 flex self-center">
                {onEdit && !editDisabled ? (
                    <IconButton sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }} onClick={onEdit} size="large">
                        <PencilIcon width={16} className="text-indigo-600" />
                    </IconButton>
                ) : null}
                <IconButton
                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                    onClick={() => startDownload({ extension: '' })}
                    disabled={downloadLoading}
                    size="small"
                >
                    {downloadLoading ? (
                        <CircularProgress size={12} variant="indeterminate" color="primary" />
                    ) : (
                        <DownloadIcon className="text-indigo-600" width={18} />
                    )}
                </IconButton>
                {onRemove && !deleteDisabled && (
                    <IconButton sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }} onClick={onRemove} size="large">
                        <XIcon width={18} className="text-error" />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default AssayFileItem;
