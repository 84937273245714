import * as Yup from 'yup';

export const updateAnnotationSetSchema = Yup.object({
    clusters: Yup.array().of(
        Yup.object({
            color: Yup.string(),
            description: Yup.string(),
            display_name: Yup.string(),
            number: Yup.string(),
            uuid: Yup.string(),
        }),
    ),
});

export const createAnnotationSchema = Yup.object({
    display_name: Yup.string().required('This field is required'),
    resolution: Yup.number().required('Please select one resolution'),
});

export const renameAnnotationSchema = Yup.object({
    display_name: Yup.string().required('This field is required'),
});
