import Button from '@components/Button';
import React from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@mui/material';

type Props = {
    disabled?: boolean;
    disabledWithoutSaving?: boolean;
    fullWidth?: boolean;
    onSubmit?: () => void;
    submittingText?: string;
    text?: string;
    variant?: 'text' | 'contained' | 'outlined' | undefined;
};

export const AnalysisFormSubmitButton = ({
    disabled = false,
    disabledWithoutSaving = false,
    fullWidth = true,
    onSubmit,
    submittingText = 'Saving...',
    text = 'Submit',
    variant = 'contained',
}: Props) => {
    const { submitForm, isSubmitting, setStatus } = useFormikContext();
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
        } else {
            setStatus(null);
            submitForm();
        }
    };
    const submitting = isSubmitting || disabled;

    return (
        <Button
            color="primary"
            data-cy="submit-analysis-btn"
            disabled={submitting || disabledWithoutSaving}
            fullWidth={fullWidth}
            onClick={handleSubmit}
            type="submit"
            variant={variant}
        >
            {submitting ? (
                <>
                    {submittingText} <CircularProgress color="inherit" className="ml-2 text-slate-500" size={16} />
                </>
            ) : (
                text
            )}
        </Button>
    );
};

export default AnalysisFormSubmitButton;
