import { useState } from 'react';
import { NoSsr } from '@mui/material';
import PlutoErrorBoundary from '../../PlutoErrorBoundary';
import cn from 'classnames';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import { AnnotationValues } from './AnnotationFormTypes';

type Props = {
    signedUrl: string;
};
const AnnotationPlotContentView = ({ signedUrl }: Props) => {
    const [iframeLoading, setIframeLoading] = useState(false);
    const { selectedAnnotation, annotations, editAnnotationFormRef: formRef } = useExperimentAnnotationContext();

    const selectedFragment = `cluster=${selectedAnnotation?.number}&${
        selectedAnnotation?.number
    }_col=${selectedAnnotation?.color.replace('#', '')}&hideTitle&hideLegend&${
        selectedAnnotation?.number
    }_name=${selectedAnnotation?.display_name.toLocaleLowerCase()}`;

    let viewAllFragment = '';
    (formRef ? formRef.current?.values?.annotations : annotations)?.forEach(
        (anno: AnnotationValues) =>
            (viewAllFragment += `${anno.number}_col=${anno.color.replace('#', '')}&${
                anno.number
            }_name=${anno.display_name.replace(' ', '%20')}&`),
    );

    const urlWithFragment = selectedAnnotation
        ? `${signedUrl}#${selectedFragment}`
        : `${signedUrl}#${viewAllFragment}hideTitle`;

    return (
        <PlutoErrorBoundary>
            <NoSsr>
                <div className="flex h-full w-full flex-col justify-center overflow-auto">
                    {signedUrl ? (
                        <>
                            <p className="mt-6 text-center text-lg font-semibold text-black">
                                {selectedAnnotation?.display_name ?? 'Dimensionality reduction plots'}
                            </p>

                            <iframe
                                src={urlWithFragment}
                                width="100%"
                                height="100%"
                                onLoad={() => setIframeLoading(false)}
                                className={cn(`rounded-xl pb-8`, { hidden: iframeLoading })}
                            />
                        </>
                    ) : (
                        <p className="flex w-full items-center justify-center">No plot available.</p>
                    )}
                </div>
            </NoSsr>
        </PlutoErrorBoundary>
    );
};

export default AnnotationPlotContentView;
