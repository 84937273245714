import React from 'react';
import Plot from '@models/Plot';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { proseListJoiner } from '@util/ObjectUtil';
import { SampleCorrelationAnalysis } from '@models/analysis/SampleCorrelationAnalysis';
import DotPlotDisplayOption from '@/src/models/plotDisplayOption/DotPlotDisplayOption';

type Props = { plot: Plot };
const SampleCorrelationAnalysisMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as SampleCorrelationAnalysis;
    if (!analysis) {
        return <div>No analysis was found.</div>;
    }
    const display = plot.display as DotPlotDisplayOption;

    const groups = analysis.groups ?? [];
    const allSamples = groups.length === 0;
    const isCPMNormalized = analysis?.cpm_normalize ?? false;

    const $groups = allSamples ? (
        <span className="font-semibold">All samples</span>
    ) : (
        <span className="">
            Samples from the {`${groups.length === 1 ? 'group' : 'groups'}`}:{' '}
            <span className="font-semibold">{proseListJoiner(groups.map((g) => g.display_name))}</span>
        </span>
    );

    return (
        <section>
            <section>
                <MethodsSectionTitle>Sample correlation analysis</MethodsSectionTitle>
                <p className="mb-6">
                    Sample correlation was performed using{' '}
                    <span className="font-semibold">
                        {isCPMNormalized ? 'counts per million (CPM)-normalized values' : 'normalized values'}
                    </span>
                    . {$groups} were correlated with all features using{' '}
                    <span className="font-semibold capitalize">{analysis?.method_type}</span> correlation<sup>1</sup>.
                </p>
            </section>
            {display.display_type === 'dot_plot' ? (
                <section>
                    <MethodsSectionTitle>Dot plot</MethodsSectionTitle>
                    <p className="mb-6">
                        Dot plot is colored by the correlation coefficient and sized by the significance of each
                        sample-sample correlation.
                    </p>
                </section>
            ) : null}
            {display.display_type === 'heatmap' ? (
                <section>
                    <MethodsSectionTitle>Heatmap</MethodsSectionTitle>
                    <p className="mb-6">
                        Heatmap is colored by the correlation coefficient of each sample-sample correlation.
                    </p>
                </section>
            ) : null}
        </section>
    );
};

export default SampleCorrelationAnalysisMethodsSection;
