import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import Workflow from '@models/Workflow';
import ArrowUturnLeftIcon from '@components/icons/custom/ArrowUturnLeftIcon';

export type Props = {
    confirmLoading?: boolean;
    error?: ReactNode;
    icon?: ReactNode;
    onCancel: () => void;
    onConfirm: (workflow: Workflow) => void;
    open?: boolean;
};
const ConfirmRollbackPreprocessModal = ({
    confirmLoading = false,
    error,
    icon,
    onCancel,
    onConfirm,
    open = false,
}: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const { selectedWorkflow, selectedPreprocess } = useExperimentWorkflowContext();

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle iconStart={icon} iconEnd={icon} title="Are you sure?" />
            <DialogContent className="mb-4 space-y-4">
                {error && <Alert severity="error">{error}</Alert>}
                <p className="text-base tracking-tight">
                    This will roll back the workflow <span className="font-semibold">{selectedWorkflow?.name}</span> to
                    the preprocess step{' '}
                    <span className="font-semibold">{selectedPreprocess?.preprocess_type?.display_name}</span> and
                    delete any edits or progress you may have made to subsequent steps in this workflow.
                </p>
                <p className="text-base font-semibold tracking-tight">
                    This is a destructive action and cannot be undone.
                </p>

                <p className="text-base tracking-tight">
                    Are you sure you want to roll back your workflow to this step?
                </p>
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button variant={isSmAndDown ? 'outlined' : 'text'} color="primary" onClick={onCancel}>
                    No, take me back
                </Button>
                <Button
                    onClick={() => onConfirm(selectedWorkflow as Workflow)}
                    variant="contained"
                    loading={confirmLoading}
                    disabled={confirmLoading}
                    className="!bg-red-800 !text-white"
                >
                    <ArrowUturnLeftIcon width={18} height={18} className="mr-2" /> Yes, roll back workflow
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmRollbackPreprocessModal;
