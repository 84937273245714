import React from 'react';
import Plot from '@models/Plot';
import { AssaySummaryAnalysisParameters } from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import SimpleTargetsField from '@components/experiments/analyses/assaySummary/SimpleTargetsField';

type Props = { plot: Plot; analysisParameters: AssaySummaryAnalysisParameters; experiment: Experiment };
const AssaySummaryAnalysisFormFields = ({ analysisParameters, experiment, plot }: Props) => {
    return (
        <>
            <SimpleTargetsField name="targets" analysisParameters={analysisParameters} />
            <AnalysisVariablesMultiSelectField variables={analysisParameters.variables} />
            <AnalysisGroupsField experiment={experiment} plot={plot} />
        </>
    );
};

export default AssaySummaryAnalysisFormFields;
