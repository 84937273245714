import { getPlotPalette } from '@components/ColorPaletteUtil';
import { IGVData, IGVTrackItem } from '@models/ExperimentData';
import { ThemeColor } from '@models/PlotConfigs';

export type Exon = {
    start: number;
    end: number;
    cdStart: number;
    cdEnd: number;
    utr: boolean;
};

/**
 * returns value of a GFF/GTF column 9 attribute
 */
export type GetAttribute = (attributeName: string) => string;

/**
 * The feature object passed to the color function
 */
export type IGVFeature = {
    chr: string;
    start: number;
    end: number;
    name: string;
    score: number;
    strand: string;
    cdStart: number;
    cdEnd: number;
    color: string;
    exons: Exon[];
    getAttributeValue: GetAttribute;
};

/**
 * Beginning with igv.js version 2.10.1 the color and altColor properties can be specified with a function that
 * takes a feature object as an argument and returns a CSS color string (e.g. "blue", "rgb(0,150,150)", "#d2691e").
 * Example:
 * ```
 * color: (feature) => {
 *         switch (feature.getAttributeValue('biotype')) {
 *             case "antisense":
 *                 return "blueviolet"
 *             case "protein_coding":
 *                 return "blue"
 *             case "retained_intron":
 *                 return "rgb(0, 150, 150)"
 *             case "processed_transcript":
 *                 return "purple"
 *             case "processed_pseudogene":
 *                 return "#7fff00"
 *             case "unprocessed_pseudogene":
 *                 return "#d2691e"
 *             default:
 *                 return "black"
 *         }
 *     }
 * ```
 */
export type ColorFunction = (feature: IGVFeature) => string;

export const makeIGVColorUtil = ({
    themeColor,
    data,
    group_display_order,
}: {
    themeColor: ThemeColor;
    data: IGVData;
    group_display_order: number[];
}) => {
    const defaultGroupOrder: number[] = [];

    const palette = getPlotPalette(themeColor);
    const groupsById = data.items
        .filter((item) => item.type === 'sample')
        .reduce<Record<number, Pick<IGVTrackItem, 'group_name' | 'group_id'>>>((map, item) => {
            map[item.group_id] = { group_name: item.group_name, group_id: item.group_id };
            if (!defaultGroupOrder.includes(item.group_id)) {
                defaultGroupOrder.push(item.group_id);
            }
            return map;
        }, {});

    const getTrackIndex = (group: Pick<IGVTrackItem, 'group_name' | 'group_id'>) => {
        let foundIndex = group_display_order.indexOf(group.group_id);
        if (foundIndex < 0) {
            foundIndex = defaultGroupOrder.indexOf(group.group_id);
        }
        return Math.max(0, foundIndex);
    };

    const sortedGroups = Object.values(groupsById).sort((g1, g2) => {
        return getTrackIndex(g1) - getTrackIndex(g2);
    });

    const getTrackGroupIndex = (item: IGVTrackItem) => {
        let foundIndex = group_display_order.indexOf(item.group_id);
        if (foundIndex < 0) {
            foundIndex = defaultGroupOrder.indexOf(item.group_id);
        }
        return Math.max(foundIndex, 0);
    };

    const getGroupIndex = (groupId: number) => {
        let foundIndex = group_display_order.indexOf(groupId);
        if (foundIndex < 0) {
            foundIndex = defaultGroupOrder.indexOf(groupId);
        }
        return Math.max(foundIndex, 0);
    };

    const getPaletteColor = (index: number) => {
        return palette.colors[index % (palette.colors.length - 1)];
    };

    const getColor = (index: number) => {
        return getPaletteColor(index).color;
    };

    const getGroupColor = (groupId: number) => {
        const index = getGroupIndex(groupId);
        return getColor(index);
    };

    const getGroupPaletteColor = (groupId: number) => {
        const index = getGroupIndex(groupId);
        return getPaletteColor(index);
    };

    const getTrackColor = (item: IGVTrackItem) => {
        return getColor(getTrackGroupIndex(item));
    };

    return {
        getTrackColor,
        getTrackGroupIndex,
        sortedGroups,
        getGroupColor,
        getPaletteColor,
        getGroupPaletteColor,
        groupsByName: groupsById,
    };
};
