import { useEffect, useRef, useState } from 'react';
import IGVBrowserConfig from '@models/igv/IGVBrowserConfig';
import IGVBrowser from '@models/igv/IGVBrowser';
import { useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Plot from '@models/Plot';
import IGVModule from '@models/igv/IGVModule';
import igv from 'igv/dist/igv.esm.min.js';
import Logger from '@util/Logger';

const logger = Logger.make('useIGV');

type Props = { config: IGVBrowserConfig; plot?: Plot };
const useIGV = ({ config, plot }: Props) => {
    const igvRef = useRef<HTMLDivElement>(null);
    const plotId = plot?.uuid;
    const [igv, setIgv] = useState<IGVModule | null>(null);
    const [igvBrowser, setIgvBrowser] = useState<IGVBrowser | null>(null);
    const experimentContext = useOptionalExperimentDetailViewContext();

    const createIGVBrowser = async (config: IGVBrowserConfig) => {
        if (!igvRef.current) {
            return;
        }

        return await drawIGV(igvRef.current, config);
    };

    const loadIGV = async () => {
        if (igv) {
            return igv;
        }
        if (!window || typeof window === 'undefined') {
            return;
        }
        const container = igvRef.current;
        if (!container) {
            return;
        }
        container.innerHTML = 'Loading IGV browser...';
        const igvModule: igv = await import('igv/dist/igv.esm.js');
        setIgv(igvModule);
        return igvModule;
    };

    const drawIGV = async (container: HTMLDivElement, config: IGVBrowserConfig) => {
        const igv = await loadIGV();
        if (!igv || !igv.default) {
            logger.log('no igv module was loaded');
            return;
        }
        container.innerHTML = '';
        const browser = await igv.default.createBrowser(container, { ...config });
        if (plotId) {
            experimentContext?.setIGVBrowserByPlotId?.(plotId, browser);
        }

        setIgvBrowser(browser);

        return browser;
    };

    useEffect(() => {
        if (!igvRef.current) return;

        createIGVBrowser(config);
    }, [igvRef, config]);

    useEffect(() => {
        igvBrowser?.loadSessionObject(config);
    }, [config]);

    return { igvRef, igv, igvBrowser, loadIGV };
};

export default useIGV;
