import React, { useMemo } from 'react';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import Logger from '@util/Logger';
import { ArrowPlotData, GenericCellData } from '@/src/models/ExperimentData';
import VennDiagramBuilder from '../builders/VennDiagramBuilder';

const logger = Logger.make('VennDiagram');

const VennDiagram = () => {
    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: _svg, size, context, tooltipId }) => {
                logger.debug('rendering venn diagram with size', size);
                const { publicationMode, plot, plotData, experiment, isExportMode } = context;
                _svg.selectAll('g').remove();
                const svg = _svg.append('g');

                const builder = new VennDiagramBuilder({
                    plot,
                    data: plotData as ArrowPlotData<GenericCellData>,
                    svg,
                    _svg,
                    experiment,
                    width: size.width,
                    height: size.height,
                    publicationMode,
                    isExportMode,
                    tooltipId,
                });

                builder.draw();
            },
        [],
    );
    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default VennDiagram;
