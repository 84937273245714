import Experiment from '@models/Experiment';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import React, { useCallback, useMemo, useState } from 'react';
import {
    BiomarkerListAnalysisParameter,
    DifferentialExpressionPickerFieldTypes,
    OverlapAnalysisParameters,
} from '@models/AnalysisParameters';
import SimpleCollapse from '@components/experiments/SimpleCollapse';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import SingleSelectField from '@components/filters/fields/SingleSelectField';
import AsyncTargetPicker from '@/src/components/AsyncTargetPicker';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Tooltip, createTheme } from '@mui/material';
import ConditionalWrapper from '@/src/components/ConditionalWrapper';
import cn from 'classnames';
import CustomDropzone from '@/src/components/fileUpload/CustomDropzone';
import SimpleSelectField from '../../plotDisplay/fields/SimpleSelectField';
import FileUploadDescription from '../../wizard/FileUploadDescription';
import ListNameField from '../fields/ListNameField';
import FoldChangeFieldGroup from '../../plotDisplay/groups/FoldChangeFieldGroup';
import { TrashIcon } from '@heroicons/react/outline';
import ConfirmModal from '@/src/components/ConfirmModal';
import { ExpandMore } from '@mui/icons-material';
import Button from '@/src/components/Button';
import { FormikProps } from 'formik';
import { AnalysisInputFormValues, OverlapAnalysisInputFormValues } from './AnalysisInputFormTypes';
import AnalysisInputFormSubmitButton from './AnalysisInputFormSubmitButton';
import AggregateFormErrorAlert from '../../AggregateFormErrorAlert';
import { isBlank } from '@/src/util/StringUtil';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';

const theme = createTheme();

const styles = {
    accordion: {
        flex: 1,
        boxShadow: 'none',
        borderRadius: '10px !important',
        '&.Mui-expanded': {
            margin: 0,
            border: `2px solid ${theme.palette.primary.main}`,
        },
        '&:not(expanded)': {
            border: `0.5px solid ${theme.palette.text.primary}`,
        },
        '&:last-child': {
            marginBottom: 0,
        },
        '&.Mui-disabled': {
            background: 'white',
            cursor: 'grab',
        },
    },
    accordionSummary: {
        padding: theme.spacing(0, 3),
        '&.Mui-disabled': {
            opacity: 1,
        },
    },
    accordionDetails: {
        padding: theme.spacing(3),
        paddingTop: 0,
    },
    accordionActions: {
        padding: theme.spacing(0, 3, 2, 3),
    },
};

type Props = {
    analysisParameters: OverlapAnalysisParameters;
    deleteDisabled?: boolean;
    disableExpand?: boolean;
    expanded: string | null;
    experiment: Experiment;
    formik: FormikProps<AnalysisInputFormValues>;
    index: number;
    list: any;
    pingSave: boolean;
    removeList: (analysisInputId: string) => void;
    saveButtonRef: React.RefObject<HTMLDivElement>;
    scrollToInputSaveButton: () => void;
    setExpanded: (id: string | null) => void;
    updateList: (values: OverlapAnalysisInputFormValues, analysisInputId?: string | null) => void;
    updatingList: boolean;
};
const OverlapAnalysisInput = ({
    analysisParameters,
    deleteDisabled = false,
    disableExpand = false,
    expanded,
    experiment,
    formik,
    index,
    list,
    pingSave,
    removeList,
    saveButtonRef,
    scrollToInputSaveButton,
    setExpanded,
    updateList,
    updatingList,
}: Props) => {
    const { values, setFieldValue, errors, touched, status, ...form } = formik;
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const { features } = useOrganizationPermissions();

    const errorMessages = Object.keys(errors)
        .filter((name) => (name.includes('|') ? true : touched[name] && !isBlank(errors[name])))
        .map((name) => errors[name]);

    const biomarkerList: BiomarkerListAnalysisParameter[] = useMemo(
        () => analysisParameters?.biomarker_list ?? [],
        [analysisParameters],
    );

    const updateValues = (updateObject: { [field: string]: string | number | string[] | undefined | null }) => {
        form.setValues({
            ...values,
            adj_pval_threshold: undefined,
            differential_analysis_id: undefined,
            differential_analysis_shortname: undefined,
            fc_direction: undefined,
            fc_lower_threshold: null,
            fc_upper_threshold: null,
            targets_biomarker_list_id: undefined,
            targets_csv_filename: undefined,
            targets_csv: undefined,
            target_genes_format: undefined,
            targets: undefined,
            ...updateObject,
        });
        form.setErrors({});
        form.setStatus({ error: null });
    };

    const renderTooltipWrapper = useCallback(
        (children, tooltipText: string) => (
            <Tooltip title={tooltipText ?? ''} placement="top" arrow>
                <div>{children}</div>
            </Tooltip>
        ),
        [experiment],
    );

    const handleAccordionChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
        const isSwitchingToNewList = isExpanded && expanded && list.uuid && expanded !== list.uuid;
        if (!isExpanded) {
            closeAccordion();
        } else if (isSwitchingToNewList) {
            scrollToInputSaveButton();
        } else if (!isSwitchingToNewList) {
            setExpanded(list.uuid);
        }
    };

    const closeAccordion = () => {
        updateList(values, expanded);
        setExpanded(null);
    };
    const formatName = () => {
        const name = list.name || values.name || 'Create list...';
        const maxLength = 30;
        if (disableExpand) {
            return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
        }
        return name;
    };

    const getBiomarkerTooltipText = useCallback(() => {
        if (!features?.experiment_features.biomarkers_enabled) {
            return 'Biomarkers is not currently enabled for your lab space. Reach out to Pluto support to learn more.';
        }
        if (!biomarkerList?.length) {
            return "No biomarkers/targets available for this experiment's organism type";
        }
        return '';
    }, [biomarkerList]);

    return (
        <Accordion
            sx={styles.accordion}
            expanded={expanded === list.uuid}
            onChange={handleAccordionChange}
            disabled={disableExpand}
            onClick={disableExpand ? (e) => e.stopPropagation() : undefined}
        >
            <AccordionSummary
                sx={styles.accordionSummary}
                expandIcon={disableExpand ? null : <ExpandMore color="inherit" />}
                onClick={disableExpand ? (e) => e.stopPropagation() : undefined}
            >
                <div className="flex items-center justify-center">
                    {disableExpand ? null : (
                        <span className="text-md mr-3 flex h-[24px] w-[24px] flex-shrink-0 items-center justify-center rounded-full bg-indigo-200 text-center font-semibold tracking-tight text-indigo-600">
                            {index + 1}
                        </span>
                    )}
                    <span
                        className={cn('text-md break-all font-semibold tracking-tight', {
                            'overflow-hidden text-ellipsis whitespace-nowrap': disableExpand,
                        })}
                    >
                        {formatName()}
                    </span>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
                <div className="flex flex-1 flex-col">
                    <div className="space-y-2">
                        <label
                            className={cn('block', {
                                'opacity-50': !analysisParameters?.target_list_is_enabled,
                            })}
                        >
                            <ConditionalWrapper
                                condition={!analysisParameters?.target_list_is_enabled}
                                wrapper={(children) =>
                                    renderTooltipWrapper(
                                        children,
                                        'Overlap analysis per target is only available for experiments with a pipeline run.',
                                    )
                                }
                            >
                                <input
                                    type="radio"
                                    className={cn('cursor-pointer text-indigo-500', {
                                        'cursor-not-allowed': !analysisParameters?.target_list_is_enabled,
                                    })}
                                    name="target_genes_format"
                                    checked={values.target_genes_format === 'list'}
                                    onChange={() => {
                                        updateValues({
                                            target_genes_format: 'list',
                                        });
                                    }}
                                    disabled={!analysisParameters?.target_list_is_enabled}
                                />
                                <span className="ml-2">Enter target list</span>
                            </ConditionalWrapper>
                        </label>

                        <label className="block">
                            <input
                                type="radio"
                                className="cursor-pointer text-indigo-500"
                                name="target_genes_format"
                                onChange={() => {
                                    updateValues({
                                        target_genes_format: 'file',
                                    });
                                }}
                                checked={values.target_genes_format === 'file'}
                            />
                            <span className="ml-2">Upload target csv</span>
                        </label>

                        <label
                            className={cn('block', {
                                'opacity-50': !analysisParameters?.differential_is_enabled,
                            })}
                        >
                            <ConditionalWrapper
                                condition={!analysisParameters?.differential_is_enabled}
                                wrapper={(children) =>
                                    renderTooltipWrapper(
                                        children,
                                        'Overlap analysis on differential targets requires a differential analysis to be run first.',
                                    )
                                }
                            >
                                <input
                                    type="radio"
                                    className={cn('cursor-pointer text-indigo-500', {
                                        'cursor-not-allowed': !analysisParameters?.differential_is_enabled,
                                    })}
                                    name="target_genes_format"
                                    onChange={() => {
                                        updateValues({
                                            target_genes_format: 'differential',
                                            fc_direction: 'up',
                                            fc_lower_threshold: null,
                                            fc_upper_threshold: 1.2,
                                            adj_pval_threshold: 0.05,
                                        });
                                    }}
                                    checked={values.target_genes_format === 'differential'}
                                    disabled={!analysisParameters?.differential_is_enabled}
                                />
                                <span className="ml-2">Use differential targets</span>
                            </ConditionalWrapper>
                        </label>
                        <label
                            className={cn('block', {
                                'opacity-50': !biomarkerList?.length,
                            })}
                        >
                            <ConditionalWrapper
                                condition={!features?.experiment_features.biomarkers_enabled || !biomarkerList?.length}
                                wrapper={(children) => renderTooltipWrapper(children, getBiomarkerTooltipText())}
                            >
                                <input
                                    type="radio"
                                    className={cn('cursor-pointer text-indigo-500', {
                                        'cursor-not-allowed': !biomarkerList?.length,
                                    })}
                                    name="target_genes_format"
                                    checked={values.target_genes_format === 'biomarker'}
                                    onChange={() => {
                                        updateValues({
                                            target_genes_format: 'biomarker',
                                        });
                                    }}
                                    disabled={!biomarkerList?.length}
                                />
                                <span className="ml-2">Select a biomarker/target list</span>
                            </ConditionalWrapper>
                        </label>

                        {Boolean(values.target_genes_format) && (
                            <div className="!mt-5">
                                <ListNameField name="name" placeholder={`List ${index + 1}`} />

                                {values.target_genes_format === 'list' ? (
                                    <AsyncTargetPicker
                                        name="targets"
                                        experiment={experiment}
                                        label="Targets"
                                        description="Search for targets in your assay data (if available), or paste in a comma-separated or new line-separated list (case sensitive)"
                                        className="!mb-0"
                                        columnNameProp="Gene_Symbol"
                                    />
                                ) : null}

                                {values.target_genes_format === 'file' ? (
                                    <>
                                        <div className="text-center">
                                            <FileUploadDescription
                                                uploadStep="targets_ppi"
                                                analysisParameters={analysisParameters}
                                                experiment={experiment}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <CustomDropzone
                                                uploadHeader="Upload CSV file"
                                                chooseFileText="Choose file"
                                                onFilesChanged={(files: File[] | null) => {
                                                    const file = files?.[0];
                                                    if (file) {
                                                        setFieldValue('targets_csv', file);
                                                        setFieldValue('targets_csv_filename', file?.name);
                                                    }
                                                }}
                                                acceptFileTypes={{ 'text/csv': ['.csv'] }}
                                                maxFiles={1}
                                                existingFileNames={
                                                    values?.targets_csv_filename ? [values.targets_csv_filename] : []
                                                }
                                            />
                                        </div>
                                    </>
                                ) : null}

                                {values.target_genes_format === 'differential' ? (
                                    <>
                                        <DifferentialExpressionAnalysisPickerField
                                            experimentId={experiment.uuid}
                                            name="differential_analysis_id"
                                            analysisTypeFieldName="differential_analysis_shortname"
                                            filter={{
                                                analysis_types: DifferentialExpressionPickerFieldTypes,
                                            }}
                                            selectSx={{ '& .MuiSelect-select': { whiteSpace: 'normal !important' } }}
                                        />
                                        <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                                            <div className="space-y-4">
                                                <PValueField
                                                    name="adj_pval_threshold"
                                                    hideSectionLabel
                                                    noMargin
                                                    max={0.25}
                                                    tooltipTitle="Adjusted p-value threshold for passing genes to be included in the analysis"
                                                    label={
                                                        <span>
                                                            Adjusted <i>p</i>-value
                                                        </span>
                                                    }
                                                />
                                                <SingleSelectField
                                                    name="fc_direction"
                                                    label="Fold change threshold direction"
                                                    options={[
                                                        { value: 'both', label: 'Both' },
                                                        { value: 'up', label: 'Up' },
                                                        { value: 'down', label: 'Down' },
                                                    ]}
                                                    onChange={(e) => {
                                                        const updatedValue = e.target.value;
                                                        if (updatedValue === 'up') {
                                                            setFieldValue('fc_lower_threshold', null);
                                                            setFieldValue('fc_upper_threshold', 1.2);
                                                        } else if (updatedValue === 'down') {
                                                            setFieldValue('fc_upper_threshold', null);
                                                            setFieldValue('fc_lower_threshold', 0.8);
                                                        } else if (updatedValue === 'both') {
                                                            setFieldValue('fc_lower_threshold', 0.8);
                                                            setFieldValue('fc_upper_threshold', 1.2);
                                                        }
                                                    }}
                                                />
                                                <FoldChangeFieldGroup
                                                    hideHints
                                                    hideTitle
                                                    lowerBoundDisabled={values.fc_direction === 'up'}
                                                    lowerBoundField="fc_lower_threshold"
                                                    upperBoundDisabled={values.fc_direction === 'down'}
                                                    upperBoundField="fc_upper_threshold"
                                                />
                                            </div>
                                        </SimpleCollapse>
                                    </>
                                ) : null}

                                {values.target_genes_format === 'biomarker' ? (
                                    <SimpleSelectField
                                        name="targets_biomarker_list_id"
                                        label=""
                                        items={
                                            biomarkerList?.map((l) => ({
                                                label: l.name,
                                                value: l.uuid,
                                            })) ?? []
                                        }
                                        placeholder="Select biomarkers or targets"
                                        value={values.targets_biomarker_list_id ?? ''}
                                        onChange={(e) => {
                                            const updatedValue = e.target.value;
                                            setFieldValue('targets_biomarker_list_id', updatedValue ?? undefined);
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </AccordionDetails>
            <AccordionActions sx={styles.accordionActions}>
                <div className="w-full flex-col">
                    {status && status.error && errorMessages.length === 0 && (
                        <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">{status.error}</p>
                    )}
                    <AggregateFormErrorAlert />
                    <div className="flex flex-row items-center justify-end">
                        <ConditionalWrapper
                            condition={deleteDisabled}
                            wrapper={(children) =>
                                renderTooltipWrapper(children, 'A minimum of 2 comparisons is required.')
                            }
                        >
                            <Button
                                onClick={() => setShowConfirmDelete(true)}
                                startIcon={
                                    <TrashIcon
                                        className={cn('h-5 w-5', {
                                            'text-error': !deleteDisabled,
                                        })}
                                    />
                                }
                                variant="text"
                                size="small"
                                disabled={deleteDisabled || updatingList}
                                className="text-error"
                            >
                                Remove
                            </Button>
                        </ConditionalWrapper>
                        <div className="relative" ref={expanded === list.uuid ? saveButtonRef : undefined}>
                            <span
                                className={cn('z-5 absolute left-0 top-0 h-full w-full rounded-full', {
                                    'animate-ping bg-pluto-superblue opacity-25': pingSave,
                                })}
                            />

                            <AnalysisInputFormSubmitButton
                                size="small"
                                variant="outlined"
                                disabled={updatingList}
                                beforeFormSubmit={() => {
                                    if (!values.name) {
                                        setFieldValue('name', `List ${index + 1}`);
                                    }
                                }}
                                className="z-1 bg-white"
                            />
                        </div>
                    </div>
                </div>
            </AccordionActions>
            <ConfirmModal
                open={showConfirmDelete}
                onConfirm={() => removeList(list.uuid)}
                onCancel={() => setShowConfirmDelete(false)}
                title="Remove list"
                message="Are you sure you want to remove this list? This action is irreversible."
                confirmText="Yes, remove"
                cancelText="Cancel"
            />
        </Accordion>
    );
};

export default OverlapAnalysisInput;
