import useDownloadData from '@hooks/useDownloadData';
import { DownloadIcon } from '@heroicons/react/solid';
import { CircularProgress, MenuItem } from '@mui/material';
import { useContext, useEffect } from 'react';
import { MoreMenuContext } from '@components/MoreMenuContext';

const styles = {
    downloadBtn: {
        border: '1px solid rgb(224, 231, 255)',
        minWidth: 0,
        padding: 12,
    },
    paper: {
        borderRadius: '8px',
        border: '1px solid #CED4FB',
        boxShadow: '0 0 16px 0 rgba(0,0,0,.08)',
    },
    menuItem: {
        color: '#616ECF',
    },
    selected: {},
    hover: {},
};

type Props = {
    /**
     * A URL getter to get the download url for a given filename
     * @param {string} filename the name of the file to download
     */
    endpoint?: (filename: string) => string;
    /** The filename without extension. The extension will be added based on the file format selected */
    baseFilename: string;
    extension: string;
    /**
     *  The URL to download the file, if available. This should be the same as the value returned from fetching data using
     *  endpoint param. Used if we already have the download URL.
     */
    url?: string;
    /**
     * Should the generated anchor tag use a new tab to download the file
     */
    newTab?: boolean;

    /**
     * A custom label instead of the default `Download ${extension}`
     */
    label?: string;
};

/**
 * Requires MoreMenuContext
 * @param {(filename: string) => string} endpoint
 * @param {string} baseFilename
 * @param {string} extension
 * @param {string} url
 * @param {newTab} newTab
 * @param {string} label
 * @return {JSX.Element}
 * @constructor
 */
const DownloadDataMoreMenuItem = ({ endpoint, baseFilename, extension, url, newTab = false, label }: Props) => {
    const { loading, completed, startDownload } = useDownloadData({ endpoint, baseFilename, url, newTab });
    const { handleClose } = useContext(MoreMenuContext);

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            if (completed) {
                handleClose();
            }
        }, 500);
        return () => {
            clearTimeout(timeoutHandler);
        };
    }, [completed]);

    const handleClicked = () => {
        startDownload({ extension });
    };

    return (
        <MenuItem
            onClick={() => handleClicked()}
            key={extension}
            sx={{ selected: styles.selected, root: styles.menuItem }}
        >
            <span className="mr-2">
                {loading ? (
                    <CircularProgress variant="indeterminate" size={16} color={'primary'} />
                ) : (
                    <DownloadIcon color="secondary" className="h-4 w-4" />
                )}
            </span>
            {label ?? `Download ${extension}`}
        </MenuItem>
    );
};

export default DownloadDataMoreMenuItem;
