import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const FunctionalAnnotationAnalysisIcon = ({
    width = 24,
    height = 24,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <path
                d="M3.8257 17.0153C3.28187 17.1116 2.91904 17.6305 3.01531 18.1743C3.11157 18.7181 3.63047 19.081 4.1743 18.9847L3.8257 17.0153ZM19.9087 18.4625C20.4587 18.5129 20.9454 18.1079 20.9958 17.558C21.0462 17.008 20.6413 16.5213 20.0913 16.4708L19.9087 18.4625ZM11.8961 6L12.874 5.79088C12.7739 5.32265 12.3559 4.99111 11.8772 5.00018C11.3984 5.00925 10.9933 5.3564 10.911 5.82809L11.8961 6ZM7.10007 17.4513L6.92577 16.4666L7.10007 17.4513ZM4.1743 18.9847L7.27437 18.4359L6.92577 16.4666L3.8257 17.0153L4.1743 18.9847ZM17.5763 18.2487L19.9087 18.4625L20.0913 16.4708L17.7589 16.257L17.5763 18.2487ZM11.4851 14.1719L12.8812 6.17191L10.911 5.82809L9.51489 13.8281L11.4851 14.1719ZM10.9182 6.20912L12.5221 13.7091L14.4779 13.2909L12.874 5.79088L10.9182 6.20912ZM17.7589 16.257C16.1329 16.108 14.7989 14.9067 14.4808 13.3052L12.5192 13.6948C13.0098 16.1655 15.0678 18.0187 17.5763 18.2487L17.7589 16.257ZM7.27437 18.4359C9.4453 18.0517 11.1355 16.336 11.4872 14.1595L9.51281 13.8405C9.29682 15.177 8.25891 16.2306 6.92577 16.4666L7.27437 18.4359Z"
                fill="currentColor"
            />
        </svg>
    );
};
