import Button, { LoadableButtonProps } from '@components/Button';
import { PropsWithChildren, useState } from 'react';
import CustomColorsForm from '@components/profile/CustomColorsForm';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';
import DialogCloseButton from '@components/DialogCloseButton';
import Link from 'next/link';
import useLabPermissions from '@hooks/useLabPermissions';

type Props = PropsWithChildren<Omit<LoadableButtonProps, 'children' | 'onClick'>> & { marginLeft?: string };
const CustomColorsFormDialogButton = ({ children, marginLeft, ...buttonProps }: Props) => {
    const [open, setOpen] = useState(false);

    const permissions = useLabPermissions();
    const canCreateOrgColors = permissions.canCreateOrganizationColors;

    return (
        <>
            <Button {...buttonProps} onClick={() => setOpen(true)} style={{ marginLeft }}>
                {children ?? 'Manage'}
            </Button>

            <Dialog
                onClose={() => setOpen(false)}
                open={open}
                className="overflow-visible"
                PaperProps={{ style: { overflow: 'visible' } }}
                fullWidth
                maxWidth="xs"
            >
                <DialogCloseButton onClose={() => setOpen(false)} />
                <DialogTitle>
                    <span className="mr-12 text-2xl font-semibold tracking-tight text-dark">Favorite Colors</span>
                </DialogTitle>
                <DialogContent style={{ overflow: 'visible' }} className="mb-8 space-y-4">
                    <p>
                        Add or remove colors to build a custom palette that you can quickly access when creating plots.
                        Drag and drop to reorder.{' '}
                        <a
                            href="https://help.pluto.bio/en/articles/6434735-managing-favorite-colors"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>
                    </p>
                    <CustomColorsForm hidePresets />
                    {canCreateOrgColors && (
                        <p>
                            <i>
                                Note: Colors saved here are visible to your account only. To manage the color palette
                                for your organization, edit your{' '}
                                <Link passHref href="/team" target="_blank" rel="noopener noreferrer" legacyBehavior>
                                    <p className="cursory-pointer text-primary hover:opacity-70">
                                        organization details
                                    </p>
                                </Link>
                                .
                            </i>
                        </p>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CustomColorsFormDialogButton;
