import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import {
    DifferentialExpressionPickerFieldTypes,
    SeuratOverRepresentationAnalysisParameters,
} from '@models/AnalysisParameters';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import GeneSetCollectionPickerField from '@components/experiments/analyses/fields/GeneSetCollectionPickerField';
import React from 'react';
import { useFormikContext } from 'formik';
import { SeuratOverRepresentationAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { Tooltip } from '@mui/material';
import { HelpCircleIcon } from '@/src/components/icons/custom/HelpCircleIcon';
import SimpleCollapse from '../../SimpleCollapse';
import TextInputField from '@/src/components/forms/TextInputField';
import PValueField from '../../plotDisplay/fields/PValueField';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: SeuratOverRepresentationAnalysisParameters };
const SeuratOverRepresentationAnalysisFormFields = ({ experiment, analysisParameters }: Props) => {
    const { setFieldValue } = useFormikContext<SeuratOverRepresentationAnalysisFormValues>();

    return (
        <div className="space-y-8">
            <GeneSetCollectionPickerField
                name="gene_set_collections"
                analysisParameters={analysisParameters}
                multi={false}
            />
            <div className="rounded-xl border border-indigo-500 p-4">
                <DifferentialExpressionAnalysisPickerField
                    experimentId={experiment.uuid}
                    name="differential_analysis_id"
                    analysisTypeFieldName="differential_analysis_shortname"
                    filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                    tooltip={{
                        title: 'Create a differential expression analysis to view it in this list. \nTap to learn more.',
                        href: 'https://help.pluto.bio/en/articles/',
                    }}
                />
                <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                    <div className="space-y-4">
                        <PValueField
                            name="adj_pval_de"
                            hideSectionLabel
                            noMargin
                            max={0.25}
                            label={
                                <div className="flex items-center">
                                    <span>
                                        {' '}
                                        Adjusted <i>p</i>-value
                                    </span>
                                    <Tooltip
                                        title="Adjusted p-value threshold for passing genes to be included in the analysis"
                                        placement="right"
                                        arrow
                                    >
                                        <span>
                                            <HelpCircleIcon
                                                fontSize="small"
                                                className="ml-2 cursor-pointer text-gray-400"
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        />

                        <TextInputField
                            name="n_genes"
                            type="number"
                            step={0.1}
                            min={50}
                            max={1000}
                            noMargin
                            label={
                                <div className="flex items-center">
                                    <span>
                                        Maximum genes to include (ranked by log<sub>2</sub>FC)
                                    </span>
                                    <Tooltip
                                        title="Maximum number of genes (ordered by log2 fold change) to use per DEG group"
                                        placement="right"
                                        arrow
                                    >
                                        <span>
                                            <HelpCircleIcon
                                                fontSize="small"
                                                className="ml-2 cursor-pointer text-gray-400"
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                            onChange={(e) => setFieldValue('n_genes', e.target.value)}
                        />
                    </div>
                </SimpleCollapse>
            </div>
        </div>
    );
};

export default SeuratOverRepresentationAnalysisFormFields;
