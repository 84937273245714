import {
    DifferentialExpressionPickerFieldTypes,
    FunctionalAnnotationAnalysisParameters,
} from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FunctionalAnnotationAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { Collapse, Tooltip } from '@mui/material';
import { ChevronRightIcon } from '@heroicons/react/outline';
import TextInput from '@components/forms/TextInput';
import cn from 'classnames';
import PValueField from '../../plotDisplay/fields/PValueField';
import FoldChangeFieldGroup from '../../plotDisplay/groups/FoldChangeFieldGroup';
import SimpleCollapse from '../../SimpleCollapse';
import DifferentialExpressionAnalysisPickerField from '../fields/DifferentialExpressionAnalysisPickerField';
import SimpleSelectField from '../../plotDisplay/fields/SimpleSelectField';
import Picker from '@components/forms/Picker';
import { WarningRounded } from '@mui/icons-material';
import ConditionalWrapper from '@components/ConditionalWrapper';

type Props = {
    analysisParameters: FunctionalAnnotationAnalysisParameters;
    experiment: Experiment;
};
const FunctionalAnnotationAnalysisFormFields = ({ experiment, analysisParameters }: Props) => {
    const { values, setFieldValue, ...form } = useFormikContext<FunctionalAnnotationAnalysisFormValues>();
    const [advancedExpanded, setAdvancedExpanded] = useState(true);

    const mismatchedGenome = experiment?.pipeline_run?.genome?.shortname !== values.genome_shortname;
    const noPipelineRun = !experiment?.pipeline_run;

    const updateValues = (updateObject: { [field: string]: string | number }) => {
        form.setValues({
            ...values,
            ...updateObject,
            sample_ids: [],
            differential_analysis_id: null,
            differential_analysis_shortname: null,
            adj_pval_threshold: 0.01,
        });
    };

    useEffect(() => {
        const genomeMatchingPipelineRun = analysisParameters?.genomes?.find(
            (g) => g.shortname === experiment?.pipeline_run?.genome?.shortname,
        );
        if (genomeMatchingPipelineRun) {
            setFieldValue('genome_shortname', genomeMatchingPipelineRun.shortname);
        }
    }, []);

    const renderTooltipWrapper = useCallback(
        (children, tooltipText) => (
            <Tooltip title={tooltipText ?? ''} placement="top" arrow>
                <div>{children}</div>
            </Tooltip>
        ),
        [experiment],
    );

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Select peaks for analysis</p>
                <div className="space-y-2">
                    <label
                        className={cn('block', {
                            'opacity-50': !analysisParameters?.sample_is_enabled,
                        })}
                    >
                        <ConditionalWrapper
                            condition={!analysisParameters?.sample_is_enabled}
                            wrapper={(children) =>
                                renderTooltipWrapper(
                                    children,
                                    'Functional analysis per sample is only available for experiments with a pipeline run.',
                                )
                            }
                        >
                            <input
                                type="radio"
                                className={cn('cursor-pointer text-indigo-500', {
                                    'cursor-not-allowed': !analysisParameters?.sample_is_enabled,
                                })}
                                name="peak_set_type"
                                checked={values.peak_set_type === 'sample'}
                                onChange={() => {
                                    updateValues({
                                        peak_set_type: 'sample',
                                    });
                                }}
                                disabled={!analysisParameters?.sample_is_enabled}
                            />
                            <span className="ml-2">Sample peaks</span>
                        </ConditionalWrapper>
                    </label>

                    <label
                        className={cn('block', {
                            'opacity-50': !analysisParameters?.consensus_peak_is_enabled,
                        })}
                    >
                        <input
                            type="radio"
                            className={cn('cursor-pointer text-indigo-500', {
                                'cursor-not-allowed': !analysisParameters?.consensus_peak_is_enabled,
                            })}
                            name="peak_set_type"
                            onChange={() => {
                                updateValues({
                                    peak_set_type: 'consensus',
                                });
                            }}
                            checked={values.peak_set_type === 'consensus'}
                            disabled={!analysisParameters?.consensus_peak_is_enabled}
                        />
                        <span className="ml-2">Consensus peaks</span>
                    </label>

                    <label
                        className={cn('block', {
                            'opacity-50': !analysisParameters?.differential_is_enabled,
                        })}
                    >
                        <ConditionalWrapper
                            condition={!analysisParameters?.differential_is_enabled}
                            wrapper={(children) =>
                                renderTooltipWrapper(
                                    children,
                                    'Functional analysis on differentially bound peaks requires a differential binding analysis to be run first.',
                                )
                            }
                        >
                            <input
                                type="radio"
                                className={cn('cursor-pointer text-indigo-500', {
                                    'cursor-not-allowed': !analysisParameters?.differential_is_enabled,
                                })}
                                name="peak_set_type"
                                onChange={() => {
                                    updateValues({
                                        peak_set_type: 'differential',
                                    });
                                }}
                                checked={values.peak_set_type === 'differential'}
                                disabled={!analysisParameters?.differential_is_enabled}
                            />
                            <span className="ml-2">Differentially bound peaks</span>
                        </ConditionalWrapper>
                    </label>

                    {values.peak_set_type === 'sample' ? (
                        <div className="!mt-4 rounded-xl border border-indigo-500 p-4">
                            <p className="field-label !mb-0">Sample(s)</p>
                            <Picker
                                name="sample_ids"
                                noOptionsText="No samples found"
                                options={
                                    analysisParameters?.samples.map((s) => ({
                                        label: s.display_name,
                                        value: s.uuid,
                                    })) ?? []
                                }
                                placeholder="Select a sample(s)"
                                itemName="sample"
                            />
                        </div>
                    ) : null}
                    {values.peak_set_type === 'differential' ? (
                        <div className="!mt-4 rounded-xl border border-indigo-500 p-4">
                            <DifferentialExpressionAnalysisPickerField
                                experimentId={experiment.uuid}
                                name="differential_analysis_id"
                                analysisTypeFieldName="differential_analysis_shortname"
                                filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                            />
                            <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                                <div className="space-y-4">
                                    <PValueField
                                        name="adj_pval_threshold"
                                        hideSectionLabel
                                        noMargin
                                        max={0.25}
                                        tooltipTitle="Adjusted p-value threshold for passing genes to be included in the analysis"
                                        label={
                                            <span>
                                                Adjusted <i>p</i>-value
                                            </span>
                                        }
                                    />

                                    <div>
                                        <FoldChangeFieldGroup titleClassname="text-sm mb-0" />
                                    </div>
                                </div>
                            </SimpleCollapse>
                        </div>
                    ) : null}
                </div>
            </section>
            <h4
                className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                onClick={() => setAdvancedExpanded(!advancedExpanded)}
            >
                <span>Advanced settings</span>
                <span>
                    <ChevronRightIcon
                        width={20}
                        className={cn(
                            'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                            { 'rotate-90': advancedExpanded },
                        )}
                    />
                </span>
            </h4>
            <Collapse className="" in={advancedExpanded}>
                <div>
                    <p className="field-label">TSS region (in bases)</p>
                    <div className="grid grid-cols-2 gap-2">
                        <TextInput
                            name="tss_upstream"
                            label="Upstream"
                            type="number"
                            value={values.tss_upstream ?? ''}
                            onValueChange={(_, __, values) => setFieldValue('tss_upstream', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={true}
                            step={1}
                            useNumericFormat
                        />
                        <TextInput
                            name="tss_downstream"
                            label="Downstream"
                            type="number"
                            value={values.tss_downstream ?? ''}
                            onValueChange={(_, __, values) => setFieldValue('tss_downstream', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            useNumericFormat
                        />
                    </div>
                </div>
                <div>
                    <SimpleSelectField
                        name="genome_shortname"
                        label="Genome"
                        items={
                            analysisParameters?.genomes.map((g) => ({ value: g.shortname, label: g.display_name })) ??
                            []
                        }
                        placeholder="Select a genome"
                        value={values.genome_shortname ?? ''}
                        onChange={(e) => {
                            setFieldValue('genome_shortname', e.target.value);
                        }}
                        tooltip={
                            noPipelineRun
                                ? {
                                      title: 'Warning: no pipeline run found. If this is a copied experiment, select the genome that was used for the original pipeline run.',
                                      icon: <WarningRounded className="text-amber-500" />,
                                  }
                                : mismatchedGenome
                                  ? {
                                        title: 'Warning: genome does not match pipeline run.',
                                        icon: <WarningRounded className="text-amber-500" />,
                                    }
                                  : undefined
                        }
                    />
                </div>
            </Collapse>
        </div>
    );
};

export default FunctionalAnnotationAnalysisFormFields;
