import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const BoxPlotIcon = (props?: IconProps) => {
    const { height = 38, width = 40, className = defaultTextLightClass } = props ?? {};
    return (
        <svg
            viewBox="0 0 38 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
        >
            <rect
                x="1"
                y="13"
                width="14"
                height="18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1="8" y1="5" x2="8" y2="13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="8" y1="31" x2="8" y2="39" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="5" x2="13" y2="5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="39" x2="13" y2="39" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="2" y1="22" x2="15" y2="22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <rect
                x="23"
                y="9"
                width="14"
                height="14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1="30" y1="1" x2="30" y2="8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="30" y1="24" x2="30" y2="35" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="25" y1="1" x2="35" y2="1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="25" y1="35" x2="35" y2="35" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="24" y1="16" x2="37" y2="16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};
