import { defaultTextClass, IconProps } from '@components/icons/CustomIcons';

export const VolcanoChartIcon = ({ width = 79, height = 83, className = defaultTextClass }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 79 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx="8.84593"
                cy="63.0735"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="24.5383"
                cy="42.4231"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="53.9012"
                cy="27.1958"
                r="3.92308"
                transform="rotate(-154.915 53.9012 27.1958)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="22.5774"
                cy="30.0012"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="44.2352"
                cy="52.231"
                r="3.92308"
                transform="rotate(-154.915 44.2352 52.231)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="39.7449"
                cy="64.315"
                r="3.92308"
                transform="rotate(-154.915 39.7449 64.315)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="41.2547"
                cy="76.4458"
                r="3.92308"
                transform="rotate(-154.915 41.2547 76.4458)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="28.4612"
                cy="77.0774"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="59.8641"
                cy="7.10012"
                r="3.92308"
                transform="rotate(-154.915 59.8641 7.10012)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="15.385"
                cy="21.5012"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="59.9012"
                cy="42.4927"
                r="3.92308"
                transform="rotate(-154.915 59.9012 42.4927)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="4.92308"
                cy="16.9231"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="55.6805"
                cy="71.5591"
                r="3.92308"
                transform="rotate(-154.915 55.6805 71.5591)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="66.2166"
                cy="20.2154"
                r="3.92308"
                transform="rotate(-154.915 66.2166 20.2154)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="26.2166"
                cy="56.2154"
                r="3.92308"
                transform="rotate(-154.915 26.2166 56.2154)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="19.2166"
                cy="10.2154"
                r="3.92308"
                transform="rotate(-154.915 19.2166 10.2154)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="46.2166"
                cy="37.2154"
                r="3.92308"
                transform="rotate(-154.915 46.2166 37.2154)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="6.23069"
                cy="46.3469"
                r="3.92308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="72.2547"
                cy="39.1392"
                r="3.92308"
                transform="rotate(-154.915 72.2547 39.1392)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
