import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import cn from 'classnames';
import Experiment from '@models/Experiment';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { Item, MoreMenuListItem, isItem } from '@components/MoreMenuIconButton';
import CopyExperimentDialog from '@components/experiments/CopyExperimentDialog';
import EditExperimentDialog from '@components/experiments/EditExperimentDialog';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import BenchlingExperimentMultiExportDialog from '@components/experiments/benchling/BenchlingExperimentMultiExportDialog';
import useExperimentNavMenuItems from '@hooks/useExperimentNavMenuItems';
import { PlotListItem } from '@models/Plot';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';
import ConfirmModal from '../ConfirmModal';

const ITEM_HEIGHT = 48;

const ActionLinks = ({ items, handleClose }: { items: Item[]; handleClose: () => void }) => {
    return (
        <div className="flex flex-col">
            {items.map((item, i) => {
                const isLast = i === items.length - 1;
                if (!isItem(item)) return <div key={`menu_item_${i}`}>{item}</div>;
                return (
                    <div key={`menu_item_${i}`} data-cy={`more_menu_${item.label}`}>
                        {isLast && items.length > 1 ? (
                            <div className="m-auto mt-2 w-[80%] border-t border-white/30"></div>
                        ) : null}
                        <MoreMenuListItem
                            item={item}
                            handleClose={handleClose}
                            className={cn(
                                'px-4 !text-sm !text-white/70 !transition last:py-4 hover:!bg-gray-light/10 hover:!text-white',
                                {
                                    '!py-1': !isLast,
                                    '!py-3': isLast,
                                },
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

type Props = {
    experiment: Experiment;
};
const ExperimentActionsDropdownMenu = ({ experiment }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [benchlingExportOpen, setBenchlingExportOpen] = useState(false);
    const open = Boolean(anchorEl);
    const [editOpen, setEditOpen] = useState(false);
    const [confirmClearAnalysesOpen, setConfirmClearAnalysesOpen] = useState(false);
    const [clearingAnalyses, setClearingAnalyses] = useState(false);
    const {
        clearAllAnalysesOnExperiment,
        copyOpen,
        plotListItems,
        setCopyOpen,
        setEvidenceModalOpen,
        setPresentationMode,
    } = useExperimentDetailViewContext();
    const { selectedWorkflow } = useExperimentWorkflowContext();

    const clearAllAnalyses = () => {
        setConfirmClearAnalysesOpen(true);
    };

    const { menuItems } = useExperimentNavMenuItems({
        clearAllAnalyses,
        experiment,
        plotListItems: plotListItems as PlotListItem[],
        setBenchlingExportOpen,
        setCopyOpen,
        setEditOpen,
        setEvidenceModalOpen,
        setPresentationMode,
    });

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className={cn('flex h-full items-center justify-center text-white hover:cursor-pointer')}
                onClick={handleOpenMenu}
            >
                <IconButton
                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                    size="small"
                    data-cy="experiment_more_menu"
                >
                    <MoreVert fontSize="small" className={cn('text-white/70 transition hover:text-white')} />
                </IconButton>
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                MenuListProps={{
                    style: {
                        padding: 0,
                        minWidth: '150px',
                    },
                    className: 'bg-gray-dark',
                }}
                PaperProps={{
                    style: {
                        borderRadius: 8,
                        boxShadow: '0 0 24px 0 rgba(0,0,0,.13)',
                        maxHeight: ITEM_HEIGHT * 7.5,
                        marginTop: 5,
                    },
                }}
            >
                <div>
                    <ActionLinks items={menuItems} handleClose={handleClose} />
                </div>
            </Menu>
            {experiment && (
                <>
                    <CopyExperimentDialog
                        open={copyOpen}
                        onClose={() => setCopyOpen(false)}
                        experiment={experiment}
                        workflow={selectedWorkflow}
                    />
                    <EditExperimentDialog
                        open={editOpen}
                        setOpen={(open) => setEditOpen(open)}
                        experiment={experiment}
                    />
                    <BenchlingExperimentMultiExportDialog
                        open={benchlingExportOpen}
                        onClose={() => setBenchlingExportOpen(false)}
                        experiment={experiment}
                    />
                    <ConfirmModal
                        open={confirmClearAnalysesOpen}
                        onCancel={() => setConfirmClearAnalysesOpen(false)}
                        onConfirm={() => {
                            setClearingAnalyses(true);
                            clearAllAnalysesOnExperiment().finally(() => {
                                setClearingAnalyses(false);
                                setConfirmClearAnalysesOpen(false);
                            });
                        }}
                        title="Clear all analyses"
                        message="Are you sure you want to clear all analyses on this experiment? All analyses on the analysis and canvas page will be removed. This action cannot be undone."
                        confirmText="Clear all"
                        confirmLoading={clearingAnalyses}
                    />
                </>
            )}
        </>
    );
};
export default ExperimentActionsDropdownMenu;
