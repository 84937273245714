import { useEffect, useState } from 'react';
import { OwnershipFilter } from '@util/ExperimentUtil';
import { LiteratureDataset, LiteratureDatasetResponse } from '@models/LiteratureDatasets';
import { BiomarkerList, BiomarkerListResponse } from '@models/Biomarker';
import PlotAggregation from '@models/aggregations/PlotAggregation';
import Experiment from '@models/Experiment';
import { Project } from '@models/Project';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import useApi from '@hooks/useApi';
import { PaginationResponse } from '@services/EndpointUtil';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { useRouter } from 'next/router';
import axios from 'axios';
import { HelpArticle } from '../pages/api/external/getHubspotSearch';

const logger = Logger.make('EvidenceAPI');

export const DashboardExperimentsArgs = { ownership: OwnershipFilter.ALL, limit: 2 };
export const DashboardProjectsArgs = { ownership: OwnershipFilter.ALL, pagination: { limit: 2 } };

const allSuggestions: Suggestion[] = [
    'Start a new experiment',
    'Read the latest literature',
    'Invite a new collaborator',
    'Manage my team',
    'Review my projects',
    'View all of my experiments',
    'Browse biomarker lists',
    'Work on a target',
    'Talk to Pluto about my project',
    'Read help docs',
    'Start a new GEO submission',
    'Search published data sets',
    'Import a published data set',
    'Learn about RNA-seq',
    'Learn about CUT&RUN',
    'See an example RNA-seq experiment',
    'Create a favorite color palette',
    'See a tutorial',
    'Understand pathway analysis',
    'Browse all analyses in Pluto',
];

export type Suggestion =
    | 'Start a new experiment'
    | 'Read the latest literature'
    | 'Invite a new collaborator'
    | 'Manage my team'
    | 'Review my projects'
    | 'View all of my experiments'
    | 'Browse biomarker lists'
    | 'Work on a target'
    | 'Talk to Pluto about my project'
    | 'Read help docs'
    | 'Start a new GEO submission'
    | 'Search published data sets'
    | 'Import a published data set'
    | 'Learn about RNA-seq'
    | 'Learn about CUT&RUN'
    | 'Browse apps'
    | 'See an example RNA-seq experiment'
    | 'Create a favorite color palette'
    | 'See a tutorial'
    | 'Understand pathway analysis'
    | 'Browse all analyses in Pluto';

export type TimeString =
    | 'Science never sleeps!'
    | 'Rise and shine,'
    | 'Good morning,'
    | 'Welcome back,'
    | 'Good afternoon,'
    | 'Good evening,'
    | 'Keep up the awesome work,';

const useDashboard = () => {
    const [searchResults, setSearchResults] = useState<HelpArticle[]>([]);
    const [defaultArticles, setDefaultArticles] = useState<HelpArticle[]>([]);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [plots, setPlots] = useState<PlotAggregation[]>([]);
    const [experiments, setExperiments] = useState<Experiment[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [lists, setLists] = useState<BiomarkerList[]>([]);
    const [literatureSets, setLiteratureSets] = useState<LiteratureDataset[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const [userHasSearched, setUserHasSearched] = useState<boolean>(false);
    const [experimentsError, setExperimentsError] = useState<string>('');
    const [experimentsLoading, setExperimentsLoading] = useState<boolean>(true);
    const [projectsError, setProjectsError] = useState<string>('');
    const [projectsLoading, setProjectsLoading] = useState<boolean>(true);
    const [plotsError, setPlotsError] = useState<string>('');
    const [plotsLoading, setPlotsLoading] = useState<boolean>(true);
    const [listsError, setListsError] = useState<string>('');
    const [listsLoading, setListsLoading] = useState<boolean>(false);
    const [literatureError, setLiteratureError] = useState<string>('');
    const [literatureLoading, setLiteratureLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [timeString, setTimeString] = useState<TimeString>('Science never sleeps!');
    const router = useRouter();

    const { features } = useOrganizationPermissions();
    const api = useApi();

    const defaultSearchTerms = [
        'RNA-seq',
        'CUT&RUN',
        'ChIP-seq',
        'ATAC-seq',
        'Gene Expression',
        'Proteomics',
        'Biomarkers',
        'Target Discovery',
        'Pathway Analysis',
        'Single-cell Analysis',
    ];

    const fetchDefaultArticles = async () => {
        try {
            // Select 3 random search terms
            const randomSearchTerms = defaultSearchTerms.sort(() => 0.5 - Math.random()).slice(0, 3);

            // Fetch default articles for each search term
            const defaultArticlesResponses = await Promise.all(
                randomSearchTerms.map((term) => axios.get(`/api/external/getHubspotSearch?search=${term}`)),
            );

            const allDefaultArticles = defaultArticlesResponses.map((response) => response.data.data.results).flat();

            // Limit the number of default articles to 3
            setDefaultArticles(allDefaultArticles.slice(0, 3));
        } catch (error) {
            logger.error(error);
            setDefaultArticles([]);
        }
    };

    const getTimeString = () => {
        const hr = new Date().getHours();
        if (hr > 20) return setTimeString('Keep up the awesome work,');
        if (hr > 17) return setTimeString('Good evening,');
        if (hr > 12) return setTimeString('Good afternoon,');
        if (hr > 10) return setTimeString('Welcome back,');
        if (hr > 7) return setTimeString('Good morning,');
        if (hr > 4) return setTimeString('Rise and shine,');
        setTimeString('Science never sleeps!');
    };

    const getSuggestions = () => {
        const newSuggestions = [...allSuggestions];
        if (!!features?.experiment_features?.embedded_apps_enabled) newSuggestions.push('Browse apps');
        setSuggestions(
            newSuggestions
                .map((value) => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value)
                .slice(0, 6),
        );
    };

    const handleClickSuggestion = (suggestion: Suggestion) => {
        switch (suggestion) {
            case 'Start a new experiment':
                router.push('/create-experiment');
                break;
            case 'Read the latest literature':
                router.push('/literature-datasets');
                break;
            case 'Invite a new collaborator':
                router.push('/team');
                break;
            case 'Manage my team':
                router.push('/team');
                break;
            case 'Review my projects':
                router.push('/projects');
                break;
            case 'View all of my experiments':
                router.push('/experiments');
                break;
            case 'Browse biomarker lists':
                router.push('/biomarker_lists');
                break;
            case 'Work on a target':
                router.push('/target_lists');
                break;
            case 'Talk to Pluto about my project':
                window.open('https://help.pluto.bio', '_blank');
                break;
            case 'Read help docs':
                window.open('https://help.pluto.bio', '_blank');
                break;
            case 'Start a new GEO submission':
                window.open('https://help.pluto.bio/en/articles/5180852-can-pluto-submit-my-data-to-geo', '_blank');
                break;
            case 'Search published data sets':
                router.push('/explore');
                break;
            case 'Import a published data set':
                window.open('https://help.pluto.bio/en/articles/5181706-requesting-new-experiments-in-pluto', '_blank');
                break;
            case 'Learn about RNA-seq':
                window.open('https://pluto.bio/resources/Learning%20Series/rnaseq-101-for-biologists', '_blank');
                break;
            case 'Learn about CUT&RUN':
                break;
            case 'Browse apps':
                router.push('/apps');
                break;
            case 'See an example RNA-seq experiment':
                window.open('https://app.pluto.bio/experiments/PLX251234/analysis', '_blank');
                break;
            case 'Create a favorite color palette':
                router.push('/account');
                break;
            case 'See a tutorial':
                window.open('https://app.pluto.bio/get-started/industry', '_blank');
                break;
            case 'Understand pathway analysis':
                window.open('https://help.pluto.bio/en/articles/6013440-gene-set-enrichment-analysis-gsea', '_blank');
                break;
            case 'Browse all analyses in Pluto':
                window.open('https://help.pluto.bio/en/articles/analyses', '_blank');
                break;

            default:
                return;
        }
    };

    const handleSearch = async (values: { search: string }) => {
        if (!values.search || values.search.length < 3)
            return setError('Search length must be at least three characters');
        setUserHasSearched(true);
        setSearching(true);
        setError('');
        try {
            const response: any = await axios.get(`/api/external/getHubspotSearch?search=${values.search}`);
            const results: HelpArticle[] = response.data.data.results;
            setSearchResults(results);
        } catch (error) {
            logger.error(error);
            setError('Failed to search knowledge base');
        } finally {
            setSearching(false);
        }
    };

    const fetchExperiments = async () => {
        setExperimentsLoading(true);
        setExperimentsError('');

        try {
            const experimentResponse = await api.get<PaginationResponse<Experiment>>(
                Endpoints.lab.experiments(DashboardExperimentsArgs),
            );
            setExperiments(experimentResponse.items);
        } catch (error) {
            logger.error(error);
            setExperimentsError('Failed to fetch experiments');
        } finally {
            setExperimentsLoading(false);
        }
    };

    const archiveExperiment = (experimentId: string) => {
        setExperiments((prevExperiments) => prevExperiments.filter((exp) => exp.uuid === experimentId));
    };

    const fetchProjects = async () => {
        setProjectsLoading(true);
        setProjectsError('');

        try {
            const projectResponse = await api.get<PaginationResponse<Project>>(
                Endpoints.lab.projects(DashboardProjectsArgs),
            );
            setProjects(projectResponse.items);
        } catch (error) {
            logger.error(error);
            setProjectsError('Failed to fetch projects');
        } finally {
            setProjectsLoading(false);
        }
    };

    const fetchPlots = async () => {
        setPlotsLoading(true);
        setPlotsError('');

        try {
            const plotResponse = await api.get<PaginationResponse<PlotAggregation>>(
                Endpoints.lab.aggregates.plots({
                    sort_by: '-updated_at',
                    limit: 10,
                    offset: 0,
                }),
            );
            setPlots(plotResponse.items);
        } catch (error) {
            logger.error(error);
            setPlotsError('Failed to fetch plots');
        } finally {
            setPlotsLoading(false);
        }
    };

    const fetchLists = async () => {
        if (!features?.experiment_features.biomarkers_enabled || !!lists.length) return;
        setListsLoading(true);
        setListsError('');

        try {
            const newLists = await api.get<BiomarkerListResponse>(Endpoints.lab.biomarkerSets.base());
            setLists(newLists.items.slice(0, 3));
        } catch (error) {
            logger.error(error);
            setListsError('Failed to fetch lists');
        } finally {
            setListsLoading(false);
        }
    };

    const fetchLiterature = async () => {
        if (!features?.experiment_features.literature_search_enabled) return;
        setLiteratureLoading(true);
        setLiteratureError('');

        try {
            const newDatasets = await api.get<LiteratureDatasetResponse>(Endpoints.lab.literature.datasets());
            setLiteratureSets(newDatasets.results.slice(0, 2));
        } catch (error) {
            logger.error(error);
            setLiteratureError('Failed to fetch literature');
        } finally {
            setLiteratureLoading(false);
        }
    };

    useEffect(() => {
        getTimeString();
        getSuggestions();
        fetchExperiments();
        fetchProjects();
        fetchPlots();
        fetchDefaultArticles();
    }, []);

    useEffect(() => {
        fetchLists();
        fetchLiterature();
    }, [features]);

    return {
        archiveExperiment,
        defaultArticles,
        error,
        experiments,
        experimentsError,
        experimentsLoading,
        fetchExperiments,
        fetchLiterature,
        fetchProjects,
        handleClickSuggestion,
        handleSearch,
        lists,
        listsError,
        listsLoading,
        literatureError,
        literatureLoading,
        literatureSets,
        plots,
        plotsError,
        plotsLoading,
        projects,
        projectsError,
        projectsLoading,
        searching,
        searchResults,
        suggestions,
        timeString,
        userHasSearched,
    };
};

export default useDashboard;
