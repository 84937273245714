import Papa from 'papaparse';
import SparkMD5 from 'spark-md5';

/**
 * Get a unique string value for a provided file & experiment. This method is _not_ a checksum of the file,
 * but rather take into account the following fields so do not use it to check the integrity of the contents of the file:
 *     - name
 *     - file size
 *     - last modified
 *     - file type
 * @param {object} options
 * @param {File} options.file
 * @param {string} options.experimentId
 * @return {string}
 */
export const getFileIdentifier = ({ file, experimentId }: { file: File; experimentId: string }): string => {
    return SparkMD5.hash(`${experimentId}${file.name}${file.size}${file.type}${file.lastModified}`);
};

/**
 * Parses a CSV file and returns the parsed data as an object.
 * @param {File} file - The CSV file to parse.
 * @returns {Promise<{ headers: string[], data: any[] }>} A promise that resolves with an object containing the headers and data of the parsed CSV file.
 * @throws {Error} If there is an error reading or parsing the file.
 */
export const parseCSVFromFile = <T extends { headers: string[]; data: T[] }>(
    file: File,
): Promise<{ headers: string[]; data: T[] }> => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = () => {
                const csvText = reader.result as string;

                Papa.parse<T>(csvText, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (results: Papa.ParseResult<T>) => {
                        if (results.meta.fields && results.meta.fields.length > 0) {
                            resolve({
                                headers: results.meta.fields,
                                data: results.data,
                            });
                        }
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
            };

            reader.onerror = () => {
                reject(new Error('Error reading file'));
            };

            reader.readAsText(file);
        } catch (error) {
            reject(error);
        }
    });
};
