import { Team } from '@models/Team';
import React, { ReactNode, useState } from 'react';
import Button, { LoadableButtonProps } from '@components/Button';
import EditTeamDialog from '@components/team/EditTeamDialog';
import { PlusIcon } from '@heroicons/react/solid';
import { EditIcon } from '@components/icons/custom/EditIcon';

type Props = {
    team?: Team | null;
    dialogTitle: ReactNode;
    children?: ReactNode;
    onSave?: (team: Team | null) => void;
} & LoadableButtonProps;
const TeamDialogFormButton = ({ team, children, dialogTitle, onSave, ...buttonProps }: Props) => {
    const [open, setOpen] = useState(false);

    const handleSave = (team: Team | null) => {
        onSave?.(team);
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} {...buttonProps}>
                {children}
            </Button>
            <EditTeamDialog
                team={team}
                open={open}
                onClose={() => setOpen(false)}
                title={dialogTitle}
                onSaved={handleSave}
            />
        </>
    );
};

type CreateTeamProps = Exclude<Partial<Props>, 'team'>;
export const CreateTeamDialogFormButton = ({ dialogTitle, children, ...props }: CreateTeamProps) => {
    return (
        <TeamDialogFormButton dialogTitle={dialogTitle ?? 'Create team'} {...props}>
            {children ?? (
                <span className="flex items-center">
                    <PlusIcon className="mr-1 h-3.5 w-3.5" /> Team
                </span>
            )}
        </TeamDialogFormButton>
    );
};

export const EditTeamDialogFormButton = ({
    dialogTitle,
    children,
    ...props
}: Exclude<Partial<Props>, 'dialogTitle'>) => {
    return (
        <TeamDialogFormButton dialogTitle={dialogTitle ?? 'Edit team'} {...props}>
            {children ?? (
                <span className="flex items-center">
                    <EditIcon />
                    <span>Edit team</span>
                </span>
            )}
        </TeamDialogFormButton>
    );
};

export default TeamDialogFormButton;
