import * as Yup from 'yup';
import { DialogActions } from '@mui/material';
import TextInputField from '@components/forms/TextInputField';
import Button from '@components/Button';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import Experiment from '@models/Experiment';
import Logger from '@util/Logger';
import { Alert } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Tooltip } from '@mui/material';
import copy from '@copy/Copy';

const logger = Logger.make('BenchingExportForm');

export const schema = Yup.object({
    step_key: Yup.string().required('This field is required').nullable(),
});
export type FormValues = { step_key: string };

type Props = {
    makeFile: () => Promise<File[] | null>;
    experiment: Experiment;
    renderPlotItem?: () => JSX.Element;
    helpText?: () => JSX.Element;
};
type FormStatus = { success?: boolean; error?: string } | null;
const BenchlingExportDialogForm = ({ makeFile, experiment, renderPlotItem, helpText }: Props) => {
    const api = useApi();
    const initialValues: FormValues = { step_key: '' };

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const files = await makeFile();
        if (!files) return helpers.setSubmitting(false);
        if (files.length === 0) {
            helpers.setStatus({
                success: false,
                error: 'Please select at least one result',
            } as FormStatus);
            return helpers.setSubmitting(false);
        }
        logger.info('Created files', files);
        helpers.setStatus(null);
        try {
            const formData = new FormData();
            files.forEach((file) => {
                if (file.type === 'text/csv') return formData.append('datasets', file);
                formData.append('files', file);
            });
            formData.append('step_key', values.step_key);
            const response = await api.apiService.$axios.post(
                Endpoints.lab.experiment.benchling.exportSingleAnalysisReport({ experimentId: experiment.uuid }),
                formData,
                { headers: api.apiService.authHeaders },
            );

            logger.info('response', response);
            helpers.setStatus({ success: true } as FormStatus);
        } catch (error) {
            logger.error(error);
            helpers.setStatus({
                success: false,
                error: 'Unable to export analysis. Please try again later',
            } as FormStatus);
        }

        return;
    };
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ submitForm, isSubmitting, status }) => {
                return (
                    <>
                        {(status as FormStatus)?.success && (
                            <Alert severity={'success'}>Successfully exported to Benchling</Alert>
                        )}
                        {(status as FormStatus)?.error && <Alert severity="error">{status.error}</Alert>}
                        <div className="mt-4 flex flex-col">
                            {helpText ? helpText() : null}
                            <div>
                                <p className="text-md font-semibold">Benchling analysis</p>
                                <p className="my-1 text-sm leading-4">
                                    Analysis key
                                    <a href={copy.exportToBenchlingHelpUrl} target="_blank" rel="noreferrer">
                                        <Tooltip className="cursor-pointer" title="Where do I find my analysis key?">
                                            <HelpOutlineOutlinedIcon fontSize="small" className="ml-1" />
                                        </Tooltip>
                                    </a>
                                </p>
                                <TextInputField
                                    name="step_key"
                                    label=""
                                    type="text"
                                    placeholder="Paste your analysis key..."
                                />
                            </div>
                            {renderPlotItem ? renderPlotItem() : null}
                        </div>
                        <DialogActions style={{ paddingBottom: 0 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={() => submitForm()}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                            >
                                Add to analysis
                            </Button>
                        </DialogActions>
                    </>
                );
            }}
        </Formik>
    );
};
export default BenchlingExportDialogForm;
