import useOverlapAnalysisInputsSubmit from './useOverlapAnalysisInputsSubmit';

const useAnalysisInputsSubmit = () => {
    const { inputSaveButtonRef, inputExpanded, setInputExpanded, pingSave, scrollToInputSaveButton, setPingSave } =
        useOverlapAnalysisInputsSubmit();

    /**
     * Handles the behavior of custom analysis submission.
     *
     * @param analysisType - The type of analysis.
     * @param dryRun - Indicates whether it is a dry run or not. Default is false.
     * @returns If analysisType is 'overlap' and inputExpanded is true, it returns true if dryRun is true, otherwise it scrolls to the input save button.
     */
    const onAnalysisSubmit = (analysisType: string, dryRun = false) => {
        switch (analysisType) {
            case 'overlap':
                if (inputExpanded) {
                    if (!dryRun) {
                        scrollToInputSaveButton();
                    } else return true;
                } else {
                    return undefined;
                }
                break;
            default:
                return undefined;
        }
    };

    return {
        inputSaveButtonRef,
        inputExpanded,
        setInputExpanded,
        pingSave,
        scrollToInputSaveButton,
        setPingSave,
        onAnalysisSubmit,
    };
};

export default useAnalysisInputsSubmit;
