import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import YAxisTitleField from '@components/experiments/plotDisplay/fields/YAxisTitleField';
import YAxisRangeFieldGroup from '@components/experiments/plotDisplay/groups/YAxisRangeFieldGroup';
import ErrorBarsFieldGroup from '@components/experiments/plotDisplay/groups/ErrorBarsFieldGroup';
import DataPointsToggleField from '@components/experiments/plotDisplay/fields/DataPointsToggleField';
import React from 'react';
import Plot from '@models/Plot';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import Experiment from '@models/Experiment';
import AssaySummaryLegendFieldGroup from '@components/experiments/plotDisplay/groups/AssaySummaryLegendFieldGroup';
import YAxisScaleField from '@components/experiments/plotDisplay/fields/YAxisScaleField';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';

type Props = { plot: Plot; experiment: Experiment };
const BarPlotFields = ({ plot, experiment }: Props) => {
    let yAxisPlaceholder =
        experiment?.assay_data_units?.units_display_name ?? experiment?.assay_data_units?.units?.display_name;

    if (plot.analysis?.analysis_type === 'assay_summary_cpm_normalized') {
        yAxisPlaceholder = 'CPM-normalized counts';
    }
    const selectedGroupsIds = (plot.analysis as AssaySummaryAnalysis).groups?.map((g) => g.id);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <YAxisTitleField placeholder={yAxisPlaceholder} />
            <YAxisRangeFieldGroup {...experiment.assay_data_units} />
            <YAxisScaleField />
            <ErrorBarsFieldGroup />
            <DataPointsToggleField />
            <ThemeFieldGroup />
            <AssaySummaryLegendFieldGroup
                plot={plot}
                experiment={experiment}
                selectedGroupIds={selectedGroupsIds}
                isSortable={true}
                description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
            />
        </>
    );
};

export default BarPlotFields;
