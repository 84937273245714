import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import {
    DifferentialExpressionPickerFieldTypes,
    GeneSetEnrichmentAnalysisParameters,
} from '@models/AnalysisParameters';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import GeneSetCollectionPickerField from '@components/experiments/analyses/fields/GeneSetCollectionPickerField';
import React, { useState } from 'react';
import TextInput from '@components/forms/TextInput';
import { stringToNumber } from '@util/StringUtil';
import { useFormikContext } from 'formik';
import { GeneSetEnrichmentAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { Collapse } from '@mui/material';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import RankMethodPickerField from '@components/experiments/plotDisplay/fields/RankMethodPickerField';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: GeneSetEnrichmentAnalysisParameters };
const GeneSetEnrichmentAnalysisFormFields = ({ experiment, analysisParameters }: Props) => {
    const [advancedExpanded, setAdvancedExpanded] = useState(false);
    const { values, setFieldValue } = useFormikContext<GeneSetEnrichmentAnalysisFormValues>();
    return (
        <div className="space-y-8">
            <div className="space-y-6">
                <DifferentialExpressionAnalysisPickerField
                    experimentId={experiment.uuid}
                    name="differential_analysis_id"
                    analysisTypeFieldName="differential_analysis_shortname"
                    tooltip={{
                        title: 'Create a differential expression analysis to view it in this list. \nTap to learn more.',
                        href: 'https://help.pluto.bio/en/articles/',
                    }}
                    filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                />

                <GeneSetCollectionPickerField
                    name="gene_set_collections"
                    analysisParameters={analysisParameters}
                    multi={false}
                />
            </div>
            <h4
                className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                onClick={() => setAdvancedExpanded(!advancedExpanded)}
            >
                <span>Advanced settings</span>
                <span>
                    <ChevronRightIcon
                        width={20}
                        className={cn(
                            'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                            { 'rotate-90': advancedExpanded },
                        )}
                    />
                </span>
            </h4>
            <Collapse className="" in={advancedExpanded}>
                <div className="space-y-6">
                    <div>
                        <p className="field-label">Gene set size</p>
                        <div className="grid grid-cols-2 gap-2">
                            <TextInput
                                name="min_gene_set_size"
                                label="Minimum"
                                type="number"
                                value={values.min_gene_set_size ?? ''}
                                step={1}
                                onChange={(e) => setFieldValue('min_gene_set_size', stringToNumber(e.target.value))}
                            />
                            <TextInput
                                name="max_gene_set_size"
                                label="Maximum"
                                type="number"
                                step={1}
                                value={values.max_gene_set_size ?? ''}
                                onChange={(e) => setFieldValue('max_gene_set_size', stringToNumber(e.target.value))}
                            />
                        </div>
                        <RankMethodPickerField name="rank_method" />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default GeneSetEnrichmentAnalysisFormFields;
