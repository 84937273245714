import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React, { useMemo } from 'react';
import ChartSubTitleField from '@components/experiments/plotDisplay/fields/ChartSubTitleField';
import GeneSetPickerField from '@components/experiments/plotDisplay/fields/GeneSetPickerField';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import SelectedCollectionsField from '@components/experiments/plotDisplay/SelectedCollectionsField';
import CustomColorsFormDialogButton from '@components/profile/CustomColorsFormDialogButton';
import CustomLegendColorField, {
    CustomLegendColorItem,
} from '@components/experiments/plotDisplay/groups/CustomLegendColorField';
import { useFormikContext } from 'formik';
import { EnrichmentPlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const EnrichmentPlotDisplayFields = ({ plot, experiment, analysisParameters }: Props) => {
    const analysis = plot.analysis as GeneSetEnrichmentAnalysis | null;
    const { values } = useFormikContext<EnrichmentPlotDisplayOptionFormValues>();

    const items = useMemo<CustomLegendColorItem[]>(() => {
        const legendItems: CustomLegendColorItem[] = [
            {
                id: 'enrichment_score_line',
                label: `Enrichment score line`,
                themeColor: '#00ff00',
                labelName: '',
            },
        ];

        return legendItems;
    }, [values.theme_color, plot.analysis_type]);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={analysis?.gene_set?.display_name} />
            <ChartSubTitleField placeholder={analysis?.name} />
            <SelectedCollectionsField plot={plot} experiment={experiment} analysisParameters={analysisParameters} />
            <GeneSetPickerField name="analysis_values.gene_set" experiment={experiment} plot={plot} />
            <section>
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <CustomColorsFormDialogButton color="primary" variant="text" size="small" marginLeft="-6px">
                    Manage favorite colors
                </CustomColorsFormDialogButton>
                <CustomLegendColorField items={items} hideTheme />
            </section>
        </>
    );
};

export default EnrichmentPlotDisplayFields;
