import { Organism, OrganismID } from '@models/Organism';
import cn from 'classnames';
import { CholeraeIcon } from '@components/icons/custom/CholeraeIcon';
import { DogIcon } from '@components/icons/custom/DogIcon';
import { FishIcon } from '@components/icons/custom/FishIcon';
import { FlowerIcon } from '@components/icons/custom/FlowerIcon';
import { FlyIcon } from '@components/icons/custom/FlyIcon';
import { FrogIcon } from '@components/icons/custom/FrogIcon';
import { HumanIcon } from '@components/icons/custom/HumanIcon';
import { MouseIcon } from '@components/icons/custom/MouseIcon';
import { RatIcon } from '@components/icons/custom/RatIcon';
import { UnicornIcon } from '@components/icons/custom/UnicornIcon';
import { WormIcon } from '@components/icons/custom/WormIcon';
import { FruitflyIcon } from '@components/icons/custom/FruitflyIcon';

export function getOrganismIcon(shortname: Organism['shortname']): JSX.Element | null {
    switch (shortname) {
        case OrganismID.human:
            return <HumanIcon />;
        case OrganismID.mouse:
            return <MouseIcon />;
        case OrganismID.other:
            return <UnicornIcon />;
        case OrganismID.fly:
            return <FlyIcon />;
        case OrganismID.african_clawed_frog:
            return <FrogIcon />;
        case OrganismID.worm:
            return <WormIcon />;
        case OrganismID.flower:
            return <FlowerIcon />;
        case OrganismID.fish:
        case OrganismID.zebrafish:
            return <FishIcon />;
        case OrganismID.dog:
            return <DogIcon />;
        case OrganismID.rat:
            return <RatIcon />;
        case OrganismID.fruitfly:
            return <FruitflyIcon />;
        case OrganismID.cholerae:
            return <CholeraeIcon />;
        default:
            return null;
    }
}

type Props = { organism?: Organism; className?: string };
const OrganismIcon = ({ organism, className = 'h-5 w-5 text-gray-300' }: Props) => {
    if (!organism) {
        return null;
    }
    return <span className={cn('inline-flex', className)}>{getOrganismIcon(organism.shortname)}</span>;
};

export default OrganismIcon;
