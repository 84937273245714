import { useFormikContext } from 'formik';
import React from 'react';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import { DifferentialExpressionContinuousAnalysisParameters } from '@models/AnalysisParameters';
import { DifferentialExpressionAnalysisFormValues } from '@models/analysis/DifferentialExpressionAnalysis';
import DifferentialGroupsPickerFields from '@components/experiments/analyses/fields/DifferentialGroupsPickerFields';
import DifferentialVariablesField from '@components/experiments/analyses/fields/DifferentialVariablesField';

type Props = {
    plot: Plot;
    analysisParameters: DifferentialExpressionContinuousAnalysisParameters;
    experiment: Experiment;
};
const DifferentialExpressionContinuousAnalysisFormFields = ({ plot, analysisParameters, experiment }: Props) => {
    const { values } = useFormikContext<DifferentialExpressionAnalysisFormValues>();

    return (
        <>
            <DifferentialVariablesField plot={plot} analysisParameters={analysisParameters} experiment={experiment} />
            {values.variables.length > 0 && <DifferentialGroupsPickerFields experiment={experiment} plot={plot} />}
        </>
    );
};

export default DifferentialExpressionContinuousAnalysisFormFields;
