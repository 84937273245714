import React, { useEffect } from 'react';
import { ScrollableSidebarContainer } from '@components/experiments/ScrollableSidebarContent';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import ConfirmModal from '@components/ConfirmModal';
import PreprocessForm from './PreprocessForm';

export type Props = {
    className?: string;
};

const EditPreprocessForm = ({ className }: Props) => {
    const {
        currentChanges,
        setCurrentChanges,
        unsavedChangesConfirmOpen,
        setUnsavedChangesConfirmOpen,
        setExperimentModalOpen,
        handleChangeSelectedPlot,
    } = useExperimentDetailViewContext();

    useEffect(() => () => setCurrentChanges(null), []);

    return (
        <ScrollableSidebarContainer data-cy="edit-preprocess-plot-sidebar" className={className}>
            <PlutoErrorBoundary>
                <PreprocessForm />
            </PlutoErrorBoundary>
            <ConfirmModal
                open={!!unsavedChangesConfirmOpen}
                onConfirm={() => {
                    switch (unsavedChangesConfirmOpen) {
                        case 'closeModal':
                            setExperimentModalOpen?.(false);
                            handleChangeSelectedPlot(null);
                        default:
                            break;
                    }
                    setUnsavedChangesConfirmOpen('');
                    setTimeout(() => {
                        setCurrentChanges(null);
                    }, 300);
                }}
                onCancel={() => setUnsavedChangesConfirmOpen('')}
                title="Unsaved Changes"
                message={
                    <>
                        <p>
                            You have unsaved changes to:{' '}
                            <span className="font-bold capitalize">
                                {currentChanges &&
                                    Object.keys(currentChanges)
                                        .map((field) => field.replaceAll('_', ' '))
                                        .join(', ')}
                            </span>
                            .
                        </p>
                        <p>Are you sure you want to close this modal?</p>
                    </>
                }
                confirmText="Yes, discard my changes"
                cancelText="No"
            />
        </ScrollableSidebarContainer>
    );
};

export default EditPreprocessForm;
