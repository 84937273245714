import { ExperimentTypeShortname } from '@models/ExperimentType';
import { TemplateDownloadLinkView } from '@components/experiments/UploadDataView';
import React from 'react';
import { DataFileType } from '@components/experiments/wizard/ExperimentWizardUtils';
import Experiment from '@models/Experiment';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { AnalysisParameters, ProteinProteinInteractionAnalysisParameters } from '@/src/models/AnalysisParameters';

const getRNASeqDescription = (dataFileType?: DataFileType | null) => {
    switch (dataFileType) {
        case 'csv':
            return 'Got count data? Paste it into our template to make the upload process nice & smooth.';
        default:
            // Note, this case shouldn't appear on the site, but let's have a sane fallback just in case.
            return 'Got raw data? Paste it in to our template to make the upload process nice & smooth.';
    }
};

const getAssayDescription = (
    experimentType?: ExperimentTypeShortname | null,
    dataFileType?: DataFileType | null,
): string => {
    switch (experimentType) {
        case ExperimentTypeShortname.rnaseq:
            return getRNASeqDescription(dataFileType);
        case ExperimentTypeShortname.simple:
            return 'Got quantitative data? Paste it into our template to make the upload process nice & smooth.';
        default:
            return 'Got raw data? Paste it in to our template to make the upload process nice & smooth.';
    }
};

const getSampleDescription = (experimentType?: ExperimentTypeShortname | null): string => {
    switch (experimentType) {
        default:
            return 'Ready to annotate your samples? Use our template to make the upload process nice & smooth.';
    }
};
const getTargetPPIDescription = (experimentType?: ExperimentTypeShortname | null): string => {
    switch (experimentType) {
        default:
            return 'Ready to analyze your targets? Use our template to make the upload process nice & smooth.';
    }
};

type Props = {
    experimentType?: ExperimentTypeShortname | null;
    uploadStep: 'assay' | 'sample' | 'targets_ppi';
    dataFileType?: DataFileType | null;
    experiment: Experiment | null;
    analysisParameters?: AnalysisParameters | null;
};
const FileUploadDescription = ({ analysisParameters, dataFileType, experiment, experimentType, uploadStep }: Props) => {
    const settings = useExperimentSettings(experiment);
    const getSampleLink = () => {
        const sampleTemplates = settings.experimentType?.sample_data_template;
        if (!sampleTemplates) {
            return null;
        }
        return (
            <TemplateDownloadLinkView
                fileUrl={sampleTemplates.file_url}
                googleSheetUrl={sampleTemplates.google_sheet_url}
            />
        );
    };

    const getAssayLink = () => {
        const assayTemplates = settings.experimentType?.assay_data_template;
        if (!assayTemplates) {
            return null;
        }

        return (
            <TemplateDownloadLinkView
                fileUrl={assayTemplates.file_url}
                googleSheetUrl={assayTemplates.google_sheet_url}
            />
        );
    };

    const getTargetsPPILink = () => {
        const parameters = analysisParameters as ProteinProteinInteractionAnalysisParameters;
        const googleSheetUrl = parameters?.google_sheet_url;
        const fileUrl = parameters?.file_url;
        if (!googleSheetUrl && !fileUrl) {
            return null;
        }

        return <TemplateDownloadLinkView fileUrl={fileUrl} googleSheetUrl={googleSheetUrl} />;
    };

    const getLink = () => {
        switch (uploadStep) {
            case 'assay':
                return getAssayLink();

            case 'targets_ppi':
                return getTargetsPPILink();
            case 'sample':
            default:
                return getSampleLink();
        }
    };

    const getUploadDescription = () => {
        switch (uploadStep) {
            case 'assay':
                return getAssayDescription(experimentType, dataFileType);
            case 'targets_ppi':
                return getTargetPPIDescription(experimentType);
            case 'sample':
            default:
                return getSampleDescription(experimentType);
        }
    };

    return (
        <>
            <h3 className="text-xl font-semibold tracking-tight">
                Start with our {uploadStep === 'sample' ? 'sample data ' : ''}template
            </h3>
            <p className="mx-auto mb-2 max-w-sm">{getUploadDescription()}</p>
            {getLink()}
        </>
    );
};

export default FileUploadDescription;
