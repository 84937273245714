import { ReactNode } from 'react';
import SimpleSelectField, { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import { allRankMethodInfos } from '@models/analysis/RankMethod';

const choices: ChoiceItem[] = allRankMethodInfos.map((info) => ({
    value: info.rank_method,
    label: info.formattedDisplayNameCapitalized,
}));

type Props = { name: string; label?: ReactNode };
const RankMethodPickerField = ({ name, label = 'Gene ranking method' }: Props) => {
    return <SimpleSelectField name={name} label={label} items={choices} placeholder="Select a value..." />;
};

export default RankMethodPickerField;
