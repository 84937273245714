import AttachmentItemListView from '@components/experiments/AttachmentItemListView';
import { CrudPermissions } from '@models/Permission';
import Experiment from '@models/Experiment';
import ResumableFileUploader from '@components/fileUpload/ResumableFileUploader';
import useExperimentFiles from '@/src/hooks/useExperimentFiles';

type Props = {
    experiment: Experiment;
} & CrudPermissions;

export const AttachmentsView = ({ experiment, canDownload, canEdit, canArchive, canView, canCreate }: Props) => {
    const { data: fileResponse, loading, refreshFiles } = useExperimentFiles({ experiment, data_type: 'attachment' });

    const attachments = fileResponse?.attachments?.items ?? [];
    const hasAttachments = attachments.length > 0;
    return (
        <div className="mx-auto mb-8 max-w-3xl space-y-8">
            {loading && <div>Loading...</div>}
            {!hasAttachments && !canCreate && (
                <div className="flex justify-center py-8 ">
                    <p className="text-lg font-semibold">No attachments have been uploaded.</p>
                </div>
            )}
            {canCreate && (
                <ResumableFileUploader
                    uploadHeader="Upload attachments"
                    experiment={experiment}
                    onDoneUploading={refreshFiles}
                    onFilesChanged={() => null}
                    dataType="attachment"
                    maxFiles={100}
                />
            )}
            {canView && hasAttachments && (
                <div>
                    <p className="text-lg font-semibold text-dark">Attachments ({attachments?.length ?? 0})</p>
                    <AttachmentItemListView
                        items={attachments}
                        className="mb-4 max-h-[250px] overflow-auto lg:max-h-[400px]"
                        canDownload={canDownload ?? false}
                        canEdit={canEdit}
                        canArchive={canArchive}
                        canCreate={canCreate}
                        canView={canView}
                        experiment={experiment}
                    />
                </div>
            )}
        </div>
    );
};

export default AttachmentsView;
