import React from 'react';
import { useFormikContext } from 'formik';
import { ShowPValueConfig } from '@models/PlotConfigs';

const PValueToggleField = () => {
    const { values, handleChange } = useFormikContext<ShowPValueConfig>();
    return (
        <label className="-mt-4 mb-8 block">
            <input
                type="checkbox"
                className="rounded text-indigo-500"
                name="show_pval_line"
                checked={values.show_pval_line}
                onChange={handleChange}
            />
            <span className="ml-2">
                Show adjusted <i>p</i>-value threshold line
            </span>
        </label>
    );
};

export default PValueToggleField;
