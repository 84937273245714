import Button from '@components/Button';
import { useState } from 'react';
import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';
import { BaseSpaceBrowserContext } from '@contexts/BaseSpaceBrowserContext';
import Experiment from '@models/Experiment';
import { BaseSpaceProject } from '@models/baseSpace/BaseSpaceProject';
import ProjectsTable from '@components/baseSpace/ProjectsTable';
import BaseSpaceProjectFilesTable from '@components/baseSpace/BaseSpaceProjectFilesTable';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { pluralize } from '@util/ObjectUtil';

type Props = { experiment: Experiment; onSelected: (files: BaseSpaceFile[]) => void; onCancel: () => void };
const BaseSpaceBrowser = ({ onSelected, experiment, onCancel }: Props) => {
    const [selectedFiles, setSelectedFiles] = useState<BaseSpaceFile[]>([]);
    const [selectedProject, setSelectedProject] = useState<BaseSpaceProject | null>(null);
    const addFile = (file: BaseSpaceFile) => {
        setSelectedFiles((current) => {
            if (current.find((f) => f.uuid === file.uuid)) {
                return current;
            }
            return [...current, file];
        });
    };

    const removeFile = (file: BaseSpaceFile) => {
        setSelectedFiles((current) => current.filter((f) => f.name !== file.name));
    };

    const handleSubmit = () => {
        onSelected(selectedFiles);
    };

    return (
        <BaseSpaceBrowserContext.Provider
            value={{
                selectedFiles,
                setSelectedFiles,
                addFile,
                removeFile,
                experiment,
                selectedProject,
                setSelectedProject,
            }}
        >
            <div className="space-y-2">
                <div className="flex flex-col space-y-4">
                    <div className="space-y-1 text-center">
                        <h3 className="text-2xl font-semibold tracking-tight text-dark">Import from BaseSpace</h3>
                        {selectedProject ? (
                            <p className="">Select BaseSpace FASTQ file(s) to import</p>
                        ) : (
                            <p className="">Select a project to see your FASTQ files available to import</p>
                        )}
                    </div>
                    <div className="min-h-[250px]">
                        {selectedProject ? (
                            <div className="space-y-2">
                                <div>
                                    <span className="-ml-1">
                                        <Button onClick={() => setSelectedProject(null)} color="primary">
                                            <span className="link flex items-center space-x-2">
                                                <ArrowLeftIcon width={16} />
                                                <span>Back to projects</span>
                                            </span>
                                        </Button>
                                    </span>
                                </div>
                                <BaseSpaceProjectFilesTable project={selectedProject} />
                            </div>
                        ) : (
                            <ProjectsTable />
                        )}
                    </div>
                </div>
                <div className="sticky bottom-0 flex justify-end space-x-4 bg-white pt-2">
                    <Button variant="text" onClick={() => onCancel()} type="button">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        type="button"
                        disabled={selectedFiles.length === 0}
                    >
                        {selectedFiles.length > 0
                            ? `Import ${selectedFiles.length} ${pluralize(selectedFiles.length, 'file', 'files')}`
                            : 'Done'}
                    </Button>
                </div>
            </div>
        </BaseSpaceBrowserContext.Provider>
    );
};

export default BaseSpaceBrowser;
