import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React from 'react';
import YAxisTitleField from '@components/experiments/plotDisplay/fields/YAxisTitleField';
import YAxisRangeFieldGroup from '@components/experiments/plotDisplay/groups/YAxisRangeFieldGroup';
import YAxisScaleField from '@components/experiments/plotDisplay/fields/YAxisScaleField';
import ErrorBarsFieldGroup from '@components/experiments/plotDisplay/groups/ErrorBarsFieldGroup';
import DataPointsToggleField from '@components/experiments/plotDisplay/fields/DataPointsToggleField';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import XAxisTitleField from '@components/experiments/plotDisplay/fields/XAxisTitleField';
import { LongitudinalAnalysis } from '@models/analysis/LongitudinalAnalysis';
import { isDefined } from '@util/TypeGuards';
import AssaySummaryLegendFieldGroup from '@components/experiments/plotDisplay/groups/AssaySummaryLegendFieldGroup';
import CustomLegendColorField from '@components/experiments/plotDisplay/groups/CustomLegendColorField';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues, PlotLegendDisplayFormValues } from '@models/PlotDisplayOption';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import XAxisRangeFieldGroup from '@components/experiments/plotDisplay/groups/XAxisRangeFieldGroup';

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const LinePlotDisplayFields = ({ plot, experiment, analysisParameters }: Props) => {
    const { values } = useFormikContext<Partial<PlotLegendDisplayFormValues & BasePlotDisplayOptionFormValues>>();

    const analysis = plot.analysis as LongitudinalAnalysis | null;
    let yAxisPlaceholder =
        experiment?.assay_data_units?.units_display_name ?? experiment?.assay_data_units?.units?.display_name;

    if (plot.analysis?.analysis_type === 'assay_summary_cpm_normalized') {
        yAxisPlaceholder = 'CPM-normalized counts';
    }

    const xVariable = isDefined(analysis?.x_axis_variable_id)
        ? analysisParameters?.variables?.find((v) => v.id === analysis?.x_axis_variable_id)
        : undefined;

    const selectedGroupsIds = analysis?.groups?.map((g) => g.id) ?? [];
    const hasGroups = (selectedGroupsIds ?? []).length > 0;

    const { colors: barColors } = getPlotPalette(values.theme_color);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <div className="space-y-2">
                <XAxisTitleField placeholder={xVariable?.display_name} noMargin />
                <XAxisRangeFieldGroup showLabelPrefix />
            </div>
            <div className="mb-8 space-y-2">
                <YAxisTitleField placeholder={yAxisPlaceholder} noMargin />
                <YAxisRangeFieldGroup {...experiment.assay_data_units} showLabelPrefix noMargin />
                <YAxisScaleField />
            </div>

            <ErrorBarsFieldGroup />
            <DataPointsToggleField />
            <ThemeFieldGroup hideStyle />

            {hasGroups ? (
                <AssaySummaryLegendFieldGroup
                    plot={plot}
                    experiment={experiment}
                    selectedGroupIds={selectedGroupsIds}
                    description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
                    isSortable={true}
                />
            ) : (
                <CustomLegendColorField
                    items={[
                        {
                            label: 'All samples',
                            id: 'all',
                            labelName: '',
                            themeColor: barColors[0].color,
                        },
                    ]}
                    editable={true}
                />
            )}
        </>
    );
};

export default LinePlotDisplayFields;
