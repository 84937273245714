import { ThemeablePlotChartDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';

export default interface CellScatterPlotDisplayOption extends ThemeablePlotChartDisplayOption {
    annotation_set_id: string | null;
    cell_coordinate_type: string;
    downsampling_num: number | null;
    downsampling_seed: number | null;
    latent_variable_id: string | null;
    max_color?: string;
    min_color?: string;
    variable_ids: string[] | undefined;
    groups: {
        [id: number]: boolean;
    } | null;
}

export const getCellCoordinateTypeDisplayName = (cell_coordinate_type?: string) => {
    switch (cell_coordinate_type) {
        case 'umap':
            return 'UMAP';
        case 'tsne':
            return 'tSNE';
        case 'pc':
            return 'PC';
    }
};
