import { Team } from '@models/Team';
import { PermissionName } from '@models/Permission';
import { hasPermission } from '@util/PermissionUtil';

export const getTeamPermissions = (team?: Team | null) => {
    const check = (requires: PermissionName | PermissionName[], any = false) => {
        return hasPermission(team, { requires, any });
    };
    return {
        canInviteMembers: check(PermissionName.invite_team_users),
        canEditRoles: check(PermissionName.invite_team_users),
        canRemoveMembers: check(PermissionName.remove_team_users),
        canEdit: check(PermissionName.edit_team),
        canCreateTeamProject: check(PermissionName.create_team_projects),
    };
};

export type TeamPermissions = ReturnType<typeof getTeamPermissions>;

const useTeamPermissions = (team?: Team | null) => {
    return { ...getTeamPermissions(team) };
};

export default useTeamPermissions;
