import Experiment from '@models/Experiment';
import { Dialog, DialogContent } from '@mui/material';
import CopyExperimentForm from '@components/experiments/CopyExperimentForm';
import Logger from '@util/Logger';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import Workflow from '@models/Workflow';
import CopyExperimentFromWorkflowForm from './workflows/CopyExperimentFromWorkflowForm';

const logger = Logger.make('CopyExperimentDialog');

type Props = { open: boolean; onClose: () => void; experiment: Experiment; workflow?: Workflow | null };
const CopyExperimentDialog = ({ open, onClose, experiment, workflow }: Props) => {
    const handleSuccess = (copiedExperiment: Experiment) => {
        logger.info('Copied experiment successfully', { original: experiment, copy: copiedExperiment });
        onClose();
    };
    const activeWorkflow = experiment.accepted_workflow;

    return (
        <Dialog key={experiment.uuid} open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
            <DialogCloseButton onClose={onClose} />
            <PlutoDialogTitle title={workflow ? 'Create new experiment from workflow' : 'Copy experiment'} />
            <DialogContent className="space-y-4">
                <p className="text-center text-sm text-dark">
                    {workflow
                        ? 'Create a new experiment using the sample data and in-progress work from experiment'
                        : 'Create a new experiment using the sample and assay data from experiment'}{' '}
                    <span className="font-semibold">
                        {experiment.pluto_id}: {experiment.name}
                    </span>{' '}
                </p>
                {workflow ? (
                    <CopyExperimentFromWorkflowForm
                        experiment={experiment}
                        onSuccess={handleSuccess}
                        workflow={workflow}
                    />
                ) : (
                    <CopyExperimentForm
                        experiment={experiment}
                        onSuccess={handleSuccess}
                        activeWorkflowName={activeWorkflow?.name}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CopyExperimentDialog;
