import Plot from '@models/Plot';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React from 'react';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import TransposeField from '../fields/TransposeField';
import {
    PlotDataHeaderAnalysis,
    functionalAnnotationAnalysisHeaderMapping,
} from '@/src/models/analysis/PlotDataHeaderAnalysis';
import ThemeFieldGroup from '../groups/ThemeFieldGroup';
import LegendFieldGroup from '../groups/LegendFieldGroup';
import { capitalizeWordsAndReplaceUnderscores } from '@/src/util/StringUtil';

type Props = { plot: Plot };
const StackedBarPlotDisplayFields = ({ plot }: Props) => {
    const analysis = plot.analysis as PlotDataHeaderAnalysis | null;
    const peakString = analysis?.peak_set_type === 'differential' ? 'differentially bound' : analysis?.peak_set_type;
    const isFunctionalAnnotationAnalysis = plot.analysis_type === 'functional_annotation';

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={`Functional annotation (${peakString} peaks)`} />
            <TransposeField />

            <ThemeFieldGroup hideStyle />
            <LegendFieldGroup
                isSortable
                description="Click and drag the legend items to reorder. Plot will only show peak sets included in the analysis"
                loading={!analysis?.plot_data_headers?.length}
                items={
                    analysis?.plot_data_headers?.slice(1)?.map((id) => ({
                        id,
                        display_name: isFunctionalAnnotationAnalysis
                            ? functionalAnnotationAnalysisHeaderMapping[id]
                            : capitalizeWordsAndReplaceUnderscores(id),
                    })) ?? []
                }
                legendPopoverTitle="Peak set display name"
            />
        </>
    );
};

export default StackedBarPlotDisplayFields;
