import Plot from '@models/Plot';
import DifferentialBindingMethodsSection from '@components/experiments/methods/analyses/DifferentialBindingMethodsSection';
import DifferentialExpressionMethodsSection from '@components/experiments/methods/analyses/DifferentialExpressionMethodsSection';
import DifferentialExpressionContinuousMethodsSection from '@components/experiments/methods/analyses/DifferentialExpressionContinuousMethodsSection';
import { AnalysisParameters } from '@models/AnalysisParameters';
import SeuratDifferentialExpressionMethodsSection from './SeuratDifferentialExpressionMethodsSection';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const ComparativeAnalysisMethodsSection = ({ plot, analysisParameters }: Props) => {
    switch (plot.analysis_type) {
        case 'differential_binding':
            return <DifferentialBindingMethodsSection plot={plot} analysisParameters={analysisParameters} />;
        case 'differential_expression':
            return <DifferentialExpressionMethodsSection plot={plot} analysisParameters={analysisParameters} />;
        case 'differential_expression_continuous':
            return <DifferentialExpressionContinuousMethodsSection plot={plot} />;
        case 'seurat_differential_expression':
            return <SeuratDifferentialExpressionMethodsSection plot={plot} />;
        default:
            return null;
    }
};

export default ComparativeAnalysisMethodsSection;
