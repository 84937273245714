type DownloadOptions = {
    /**
     * Filename with extension, such as `ExperimentData.csv` or `PlotDataExport.svg`
     */
    filename: string;
    /**
     * The URL of the file to download
     */
    url: string;
    /**
     * Should the generated anchor tag use a new tab to download the file
     */
    newTab?: boolean;
};

const useInlineFileDownload = () => {
    const triggerFileDownload = ({ filename, url, newTab }: DownloadOptions) => {
        const a = document.createElement('a');
        a.href = url;
        if (newTab) {
            a.target = '_blank';
        }
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
    };

    return { triggerFileDownload };
};

export default useInlineFileDownload;
