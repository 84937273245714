import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const PrismIcon = ({ height = 22, width = 22, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="20" height="20" rx="2" stroke="#6F7FE7" strokeWidth="2" />
            <path
                d="M8.38445 6.41666L3.6665 14.6667H8.38445M8.38445 6.41666H13.3075M8.38445 6.41666L13.3588 14.6667M13.3075 6.41666L18.3332 14.6667H13.3588M13.3075 6.41666L8.38445 14.6667M8.38445 14.6667H13.3588"
                stroke="#6F7FE7"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    );
};
