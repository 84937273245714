import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import ResultsDataTableView from './ResultsDataTableView';

export type Props = {
    open: boolean;
    onClose: () => void;
    plot: Plot;
    experiment: Experiment;
};
const ResultsModal = ({ open = false, onClose, plot, experiment }: Props) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogCloseButton onClose={() => onClose()} />

            <PlutoDialogTitle title="Results" />

            <DialogContent>
                <ResultsDataTableView experiment={experiment} selectedPlot={plot} />
            </DialogContent>
        </Dialog>
    );
};
export default ResultsModal;
