import { LayoutMetaProps } from '@layouts/DefaultLayout';
import BaseMeta from '@layouts/BaseMeta';
import { PropsWithChildren } from 'react';
import copy from '@copy/Copy';

export type Props = PropsWithChildren<{ meta?: LayoutMetaProps; noMetadata?: boolean; className?: string }>;
const SimpleLayout = ({ meta, noMetadata, children, className }: Props) => {
    const { title, titleFull, ...metaProps } = meta ?? {};
    const metaTitle = (titleFull ?? (title ? `${title} | ${copy.companyName}` : copy.siteTitleLong))?.substring(0, 60);
    return (
        <>
            {!noMetadata && meta && <BaseMeta {...metaProps} title={metaTitle} />}
            <main className={className}>{children}</main>
        </>
    );
};

export default SimpleLayout;
