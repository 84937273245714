import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import RefPointLabelField from '@components/experiments/plotDisplay/fields/RefPointLabelField';
import LegendLocationSelectField from '@components/experiments/plotDisplay/fields/LegendLocationSelectField';
import PlotTypeSelectField from '@components/experiments/plotDisplay/fields/PlotTypeSelectField';
import PlotStatisticSelectField from '@components/experiments/plotDisplay/fields/PlotStatisticSelectField';
import React, { useState } from 'react';
import Plot from '@models/Plot';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import { ChevronRightIcon } from '@heroicons/react/outline';

type Props = { plot: Plot };
const ProfilePlotDisplayFields = ({ plot }: Props) => {
    const [advancedExpanded, setAdvancedExpanded] = useState(false);
    return (
        <div className="space-y-6">
            <ChartTitleField placeholder={plot.analysis?.name} />
            <section>
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Profile</h4>
                <RefPointLabelField placeholder="TSS" />

                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setAdvancedExpanded(!advancedExpanded)}
                >
                    <span>Advanced settings</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': advancedExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse className="" in={advancedExpanded}>
                    <PlotStatisticSelectField placeholder="Mean" />
                    <PlotTypeSelectField placeholder="Lines" />
                </Collapse>
            </section>
            <section>
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <LegendLocationSelectField />
            </section>
        </div>
    );
};

export default ProfilePlotDisplayFields;
