import { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import PreprocessStep from '@models/PreprocessStep';

export interface ClusterSeuratObject extends PreprocessStep {
    input_preprocess_id: string;
    k_param: number;
    log2fc: number;
    n_dims: number;
    only_pos: boolean;
    resolutions: string[];
    stat_test: string;
}

export const StatTestSelectOptions: ChoiceItem[] = [
    {
        value: 'wilcox',
        label: 'Wilcoxon rank sum test',
    },
    {
        value: 'bimod',
        label: 'Likelihood-ratio test',
    },
    {
        value: 't',
        label: "Student's t-test",
    },
    {
        value: 'negbinom',
        label: 'Negative binomial generalized linear model',
    },
    {
        value: 'poisson',
        label: 'Poisson generalized linear model',
    },
    {
        value: 'LR',
        label: 'Logistic regression model',
    },
];
