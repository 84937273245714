import cn from 'classnames';
import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

const ProteinProteinInteractionAnalysisIcon = ({
    className = defaultTextLightClass,
    height = 24,
    width = 24,
}: IconProps) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
            <circle cx="16.5" cy="7.5" r="2.5" fill="currentColor" />
            <circle cx="7.5" cy="12.5" r="2.5" fill="currentColor" />
            <circle cx="16.5" cy="16.5" r="2.5" fill="currentColor" />
            <line x1="7.22361" y1="12.5528" x2="15.2236" y2="16.5528" stroke="currentColor" />
            <line x1="6.79693" y1="12.5431" x2="15.7969" y2="8.54309" stroke="currentColor" />
            <line x1="16.5" y1="16" x2="16.5" y2="8" stroke="currentColor" />
        </svg>
    );
};

export default ProteinProteinInteractionAnalysisIcon;
