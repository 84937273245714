import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

type Props = IconProps;
const ClusteringAnalysisIcon = (props?: Props) => {
    const { width = 24, height = 24, className = defaultTextLightClass } = props ?? {};
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 18V13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 18V13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 10L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 6V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 10V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 6V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 10H12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 6L18 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 13H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
};

export default ClusteringAnalysisIcon;
