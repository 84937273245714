import Plot from '@models/Plot';
import { useField } from 'formik';
import { IGVPlotConfig } from '@models/plotDisplayOption/IGVPlotDisplayOption';
import { ReactNode, useState } from 'react';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import Button from '@components/Button';
import IGVBrowserConfig from '@models/igv/IGVBrowserConfig';
import { TrashIcon } from '@heroicons/react/outline';

type Props = { name: string; plot: Plot };
const IGVCoordinatesField = ({ name, plot }: Props) => {
    const [inputProps, _meta, helpers] = useField<IGVPlotConfig | null>(name);
    const { getIgvBrowserByPlotId } = useExperimentDetailViewContext();
    const [error, setError] = useState<ReactNode>(null);

    const persistCurrentConfig = () => {
        const browser = getIgvBrowserByPlotId(plot.uuid);
        if (!browser) {
            setError('Unable to locate current configuration');
            return;
        }
        setError(null);

        const getProcessedConfig = (config: IGVBrowserConfig): IGVPlotConfig => {
            return { locus: config.locus };
        };

        helpers.setValue(getProcessedConfig(browser.toJSON()));
    };

    return (
        <div className="mb-8 space-y-2">
            <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Coordinates</h4>
            {error && <div className="rounded-2xl bg-error p-4 text-error">{error}</div>}

            <div>
                <p className="flex items-center justify-between space-x-4">
                    <span className="font-semibold">Default locus</span>{' '}
                </p>
                <p className="pl-4">{inputProps.value?.locus ?? 'None'}</p>
            </div>

            <div className="space-x-2 px-0">
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => persistCurrentConfig()}
                >
                    <span className="whitespace-nowrap">Set to current view</span>
                </Button>

                {inputProps.value?.locus && (
                    <Button
                        type="button"
                        className="text-error"
                        variant="text"
                        size="small"
                        onClick={() => helpers.setValue(null)}
                        startIcon={<TrashIcon width={14} className="text-error" />}
                    >
                        <span className="text-error">Clear</span>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default IGVCoordinatesField;
