import axios from 'axios';
import Papa from 'papaparse';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Logger from '@util/Logger';

const logger = Logger.make('CsvTableViewer');

interface CsvTableViewerProps {
    url: string;
}

interface RowData {
    id: number;
    [key: string]: any;
}

// Convert CSV data to the format expected by DataGrid
const processCsvData = (data: any[]): RowData[] => {
    return data.map((row, index) => ({
        id: index, // DataGrid requires an 'id' field
        ...row,
    }));
};

const CsvTableViewer = ({ url }: CsvTableViewerProps) => {
    const [rows, setRows] = useState<RowData[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(url, { responseType: 'blob' });
                Papa.parse(response.data, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        if (results.meta.fields && results.meta.fields.length > 0) {
                            const processedData = processCsvData(results.data);
                            setRows(processedData);
                            const colDefs = results.meta.fields.map((field: string) => ({
                                field: field,
                                headerName: field,
                                flex: 1,
                                minWidth: 150,
                                editable: true,
                            }));
                            setColumns(colDefs);
                        }
                    },
                    error: (error) => {
                        logger.error(error);
                    },
                });
            } catch (error) {
                logger.error(error);
            }
        };
        fetchData();
    }, [url]);

    const gridHeight = `calc(100vh - 250px`;

    return (
        <div className="w-full" style={{ height: gridHeight }}>
            <DataGrid
                rows={rows}
                columns={columns.map((column, index) => ({
                    ...column,
                    headerClassName: `font-semibold px-4 py-4 sticky top-0 z-10 bg-secondary text-white ${
                        index === 0 ? 'rounded-tl' : ''
                    } ${index === columns.length - 1 ? 'rounded-tr' : ''}`,
                    // ... other properties
                }))}
                pagination
                autoPageSize
            />
        </div>
    );
};

export default CsvTableViewer;
