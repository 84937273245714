import Plot from '@models/Plot';
import { FunctionalAnnotationAnalysis } from '@/src/models/analysis/FunctionalAnnotationAnalysis';
import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import CodeBlockInline from '@/src/components/CodeBlockInline';
import ProseList from '@/src/components/ProseList';
import { PlotDisplayOption } from '@/src/models/PlotDisplayOption';
import PieChartDisplayOption from '@/src/models/plotDisplayOption/PieChartDisplayOption';
import { capitalizeWordsAndReplaceUnderscores, formatStringToNumberWithSeparator } from '@/src/util/StringUtil';
import { AnalysisParameters, FunctionalAnnotationAnalysisParameters } from '@/src/models/AnalysisParameters';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const FunctionalAnnotationAnalysisMethodsSection = ({ plot, analysisParameters: ap }: Props) => {
    const analysis = plot.analysis as unknown as FunctionalAnnotationAnalysis;
    const analysisParameters = ap as FunctionalAnnotationAnalysisParameters;
    if (!analysis) {
        return <div>No analysis was found.</div>;
    }
    const display = plot.display as PlotDisplayOption;
    const sampleIdsPrettified = analysis.sample_ids.map((id) => {
        const matchSample = analysisParameters.samples.find((s) => s.uuid === id);
        return matchSample?.display_name || id;
    });

    return (
        <section>
            <section>
                <MethodsSectionTitle>Functional annotation analysis</MethodsSectionTitle>
                <p className="mb-6">
                    {analysis.peak_set_type === 'sample' ? (
                        <span>
                            Functional annotation analysis of peaks was performed using the{' '}
                            <CodeBlockInline>annotatePeak</CodeBlockInline> function from the{' '}
                            <CodeBlockInline>ChIPseeker</CodeBlockInline> R package<sup>1,2</sup> for the following
                            samples: <ProseList items={sampleIdsPrettified} />.
                        </span>
                    ) : null}
                    {analysis.peak_set_type === 'consensus' ? (
                        <span>
                            Functional annotation analysis of <Bold>consensus</Bold> peaks was performed using the{' '}
                            <CodeBlockInline>annotatePeak</CodeBlockInline> function from the{' '}
                            <CodeBlockInline>ChIPseeker</CodeBlockInline> R package<sup>1,2</sup>.
                        </span>
                    ) : null}
                    {analysis.peak_set_type === 'differential' ? (
                        <span>
                            Functional annotation analysis of <Bold>differentially bound</Bold> peaks was performed
                            using the <CodeBlockInline>annotatePeak</CodeBlockInline> function from the{' '}
                            <CodeBlockInline>ChIPseeker</CodeBlockInline> R package<sup>1,2</sup>. Differentially bound
                            peaks were defined as peaks with an adjusted <i>p</i>-value less than{' '}
                            <Bold>{analysis.adj_pval_threshold}</Bold>, and a fold change greater than{' '}
                            <Bold>{formatStringToNumberWithSeparator(analysis.fold_change_fill_threshold_upper)}</Bold>{' '}
                            (increased peaks) or less than{' '}
                            <Bold>{formatStringToNumberWithSeparator(analysis.fold_change_fill_threshold_lower)}</Bold>{' '}
                            (decreased peaks).
                        </span>
                    ) : null}
                </p>
                <p className="mb-6">
                    Peaks were annotated with respect to their genomic location relative to transcription start sites
                    (TSS), with a region of <Bold>{formatStringToNumberWithSeparator(analysis.tss_upstream)}</Bold> base
                    pairs upstream and <Bold>{formatStringToNumberWithSeparator(analysis.tss_downstream)}</Bold> base
                    pairs downstream of the TSS. Transcript-related features, in <CodeBlockInline>TxDb</CodeBlockInline>{' '}
                    format, from the UCSC Genome Browser database
                    <sup>3</sup> for <Bold>{analysis.genome_shortname}</Bold> were used for peak annotation.
                </p>
            </section>
            {display.display_type === 'stacked_bar_plot' ? (
                <section>
                    <MethodsSectionTitle>Stacked barplot</MethodsSectionTitle>
                    <p className="mb-6">Stacked barplot showing the frequency of annotations for each peak set.</p>
                </section>
            ) : null}
            {display.display_type === 'pie_chart' ? (
                <section>
                    <MethodsSectionTitle>Pie Chart</MethodsSectionTitle>
                    <p className="mb-6">
                        Pie chart showing the frequency of annotations for peak set:{' '}
                        <Bold>{capitalizeWordsAndReplaceUnderscores((display as PieChartDisplayOption).peak_set)}</Bold>
                    </p>
                </section>
            ) : null}
        </section>
    );
};

export default FunctionalAnnotationAnalysisMethodsSection;
