import { useField } from 'formik';
import React from 'react';
import { ScaleValue } from '@models/PlotConfigs';
import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';

type Props = { placeholder?: string | null | undefined; disabled?: boolean };
const YAxisScaleField = ({ disabled }: Props) => {
    const [{ value, name, onChange }, { error, touched }] = useField<ScaleValue | null>('y_axis_scale');
    return (
        <div className="form-field">
            <p className="field-label flex items-center justify-between">
                <span>
                    <span className="italic">y</span>-axis scale
                </span>
            </p>
            <FormControl variant="outlined" fullWidth error={!!error && touched} disabled={disabled}>
                <Select margin="dense" value={value ?? 'linear'} name={name} onChange={onChange}>
                    <MenuItem value="linear">Linear</MenuItem>
                    <MenuItem value="symlog">Symmetrical Log</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default YAxisScaleField;
