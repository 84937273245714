import ImportSession from '@models/baseSpace/ImportSession';
import { humanFileSize } from '@util/StringUtil';
import { CheckIcon } from '@heroicons/react/outline';
import { LinearProgress } from '@mui/material';

type Props = { session: ImportSession };
const ImportSessionItem = ({ session }: Props) => {
    const complete = session.status === 'completed';

    return (
        <div className="space-y-2 rounded-lg border border-indigo-100 bg-indigo-50 px-4 py-2">
            <div className="flex justify-between">
                <div className="flex flex-col space-y-1 pr-4">
                    <p className="break-all font-semibold">{session.basespace_file?.name ?? 'unknown'}</p>

                    <div className="flex items-center space-x-2">
                        <p className="flex items-center space-x-1 text-sm text-gray-500">
                            <span>{humanFileSize(session.basespace_file?.size ?? 0)}</span>
                            <span>&#x2022;</span>
                            {!complete && <span>Importing from BaseSpace</span>}
                        </p>

                        {complete && (
                            <span className="flex items-center space-x-0.5">
                                <CheckIcon className="text-success" width={18} />
                                <span className="text-xs">Import completed</span>
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {!complete && (
                <div>
                    <LinearProgress variant="indeterminate" color="primary" />
                </div>
            )}
        </div>
    );
};

export default ImportSessionItem;
