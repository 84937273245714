import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const NetworkGraphIcon = ({ height = 36, width = 37, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            fill="currentColor"
            viewBox="0 0 36 37"
        >
            <circle cx="17.6521" cy="4.47827" r="4" fill="currentColor" />
            <circle cx="30" cy="5" r="4" fill="currentColor" />
            <circle cx="31.5652" cy="18.3913" r="4" fill="currentColor" />
            <circle cx="31.5652" cy="32.3043" r="4" fill="currentColor" />
            <circle cx="23" cy="25" r="4" fill="currentColor" />
            <circle cx="17" cy="32" r="4" fill="currentColor" />
            <line x1="22.5165" y1="24.1272" x2="17.5165" y2="5.12725" stroke="currentColor" />
            <line x1="28.9547" y1="5.49795" x2="17.9547" y2="4.49795" stroke="currentColor" />
            <line x1="30.6464" y1="17.3536" x2="17.6464" y2="4.35355" stroke="currentColor" />
            <line x1="10.534" y1="22.8188" x2="17.534" y2="4.81878" stroke="currentColor" />
            <line x1="10.584" y1="23.2774" x2="4.58397" y2="14.2773" stroke="currentColor" />
            <line x1="10.6762" y1="22.619" x2="30.6762" y2="5.61903" stroke="currentColor" />
            <line x1="31.5" y1="19" x2="31.5" y2="31" stroke="currentColor" />
            <line x1="25.3201" y1="25.6159" x2="31.3201" y2="30.6159" stroke="currentColor" />
            <line x1="24.6464" y1="24.6464" x2="31.6464" y2="17.6464" stroke="currentColor" />
            <line x1="16.6204" y1="31.6746" x2="22.6204" y2="24.6746" stroke="currentColor" />
            <circle cx="9" cy="24" r="4" fill="currentColor" />
            <circle cx="4" cy="13" r="4" fill="currentColor" />
            <line x1="17.2394" y1="6.43895" x2="6.23943" y2="12.4389" stroke="currentColor" />
        </svg>
    );
};
