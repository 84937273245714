import { Dialog, DialogContent } from '@mui/material';
import CreateProjectForm, { CreateProjectSuccessFn } from '@components/dashboard/CreateProjectForm';
import React from 'react';
import { Project } from '@models/Project';
import useAuth from '@hooks/useAuth';
import router from 'next/router';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

type ConnectedProps = {
    showAddExperiment?: boolean;
    /**
     * Custom handler for when a project is added.
     * @param {Project} project
     */
    onCreated?: (project: Project) => void;
    /**
     * If all SWR caches matching /lab/projects should be invalidated after creating a new project
     */
    revalidateAllCaches?: boolean;
};

type Props = ConnectedProps & { open: boolean; onClose: () => void };

/**
 * The bare-bones project modal. You must control the opening/closing of the modal yourself.
 * @param {boolean} open
 * @param {() => void} onClose
 * @param {((project: Project) => void) | undefined} onCreated
 * @param {boolean | undefined} showAddExperiment
 * @return {JSX.Element}
 * @constructor
 */
export const CreateProjectModal = ({ open, onClose, onCreated, showAddExperiment }: Props) => {
    const { user } = useAuth();

    const teamLead = user?.organization?.org_team_owner ?? null;

    const onSubmit: CreateProjectSuccessFn = async ({ addExperiment, project }) => {
        if (onCreated) {
            await onCreated(project);
        }
        onClose();
        if (addExperiment) {
            await router.push(`/create-experiment?project=${project.uuid}`);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogCloseButton onClose={onClose} />
            <PlutoDialogTitle title="Add a project" />
            <DialogContent>
                <CreateProjectForm
                    onSuccess={onSubmit}
                    showAddExperiment={showAddExperiment}
                    teamLead={teamLead}
                    key="create-project"
                />
            </DialogContent>
        </Dialog>
    );
};

export default CreateProjectModal;
