import { ErrorBarOption } from '@models/PlotConfigs';
import React from 'react';

export const ErrorBarMethods = ({ error_bar_option }: { error_bar_option?: ErrorBarOption | null | undefined }) => {
    switch (error_bar_option) {
        case 'sd':
            return (
                <span>
                    Error bars represent the average +/- <span className="font-semibold">standard deviation (SD)</span>.
                </span>
            );
        case 'sem':
            return (
                <span>
                    Error bars represent the average +/-{' '}
                    <span className="font-semibold">standard error of the mean (SEM)</span>.
                </span>
            );
        default:
            return null;
    }
};
