import { AnalysisParameters } from '@models/AnalysisParameters';
import Plot from '@models/Plot';
import React from 'react';
import VolcanoPlotDisplayOption from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { DifferentialExpressionAnalysis } from '@models/analysis/DifferentialExpressionAnalysis';
import ComparativeAnalysisMethodsSection from '@components/experiments/methods/analyses/ComparativeAnalysisMethodsSection';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';

export const PValFillThreshold = ({ display }: { display: VolcanoPlotDisplayOption | null }) => {
    return <span className="font-semibold">{display?.pval_fill_threshold ?? 'adjusted p-value threshold'}</span>;
};

export const PValFillThresholdLower = ({ display }: { display: VolcanoPlotDisplayOption | null }) => {
    return (
        <span className="font-semibold">
            {display?.fold_change_fill_threshold_lower ?? 'lower fold change threshold'}
        </span>
    );
};

export const PValFillThresholdUpper = ({ display }: { display: VolcanoPlotDisplayOption | null }) => {
    return (
        <span className="font-semibold">
            {display?.fold_change_fill_threshold_upper ?? 'upper fold change threshold'}
        </span>
    );
};

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const VolcanoPlotMethods = ({ plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as DifferentialExpressionAnalysis | null;
    const display = plot.display as VolcanoPlotDisplayOption | null;

    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    const itemName = analysis?.analysis_type === 'differential_binding' ? 'peak' : 'gene';
    const hideSubSections = analysis?.analysis_type === 'seurat_differential_expression';

    return (
        <div className="space-y-6">
            <ComparativeAnalysisMethodsSection plot={plot} analysisParameters={analysisParameters} />
            {!hideSubSections && (
                <>
                    <section>
                        <MethodsSectionTitle>Volcano plot</MethodsSectionTitle>
                        <p className="mb-6">
                            Volcano plot showing the log<sub>2</sub> fold change of each {itemName} on the x-axis and
                            the&nbsp;
                            <span className="whitespace-nowrap">
                                -log
                                <sub>10</sub>(adjusted <i>p</i>-value)
                            </span>
                            {` on the y-axis. Points are filled if the ${itemName}'s adjusted `}
                            <span className="whitespace-nowrap">
                                <i>p</i>-value is ≤ <PValFillThreshold display={display} />
                            </span>{' '}
                            and its fold change is either less than <PValFillThresholdLower display={display} /> or
                            greater than <PValFillThresholdUpper display={display} />.
                        </p>
                    </section>
                    <section>
                        <p className="mb-1 font-semibold text-gray-900">Statistical significance</p>
                        <p className="mb-4">
                            The false discovery rate (FDR) method was applied for multiple testing correction
                            <sup>2</sup>.{' '}
                            <span className="whitespace-nowrap">
                                FDR-adjusted <i>p</i>-values
                            </span>{' '}
                            are shown on the y-axis of the volcano plot. An adjusted{' '}
                            <span className="whitespace-nowrap">
                                <i>p</i>-value
                            </span>{' '}
                            of <PValFillThreshold display={display} /> was used as the threshold for statistical
                            significance.
                        </p>
                    </section>
                </>
            )}
        </div>
    );
};

export default VolcanoPlotMethods;
