import { IconProps } from '@components/icons/CustomIcons';

export const IGVPlotLogo = ({ height, width }: IconProps) => {
    return (
        <img
            src={`https://cdn.bfldr.com/2Q1IPX6I/at/9xs8bbmmf877t6qh5bcmwqp/igv_logo?width=${width}&height=${height}`}
            alt="IGV Logo"
        />
    );
};
