import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

type Props = {
    title?: string;
    subtitle: string;
};
const NoDataFoundMessage = ({ title = 'No data found', subtitle }: Props) => {
    return (
        <div className="space-y-8 py-12">
            <div className="flex flex-col items-center space-y-4">
                <div className="mx-auto rounded-full bg-indigo-100 p-4 text-indigo-800">
                    <ExclamationCircleIcon width={24} height={24} />
                </div>
                <div className="text-center">
                    <p className="text-xl font-semibold text-dark">{title}</p>
                    <p className="text-base">{subtitle}</p>
                </div>
            </div>
        </div>
    );
};

export default NoDataFoundMessage;
