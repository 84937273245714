const STORAGE_KEY = '__plutoBrowserUpload';

type FileMetaInfo = {
    id: string;
    fileSize: number;
    chunkSize: number;
    checksums: string[];
    started: boolean;
};

export default class FileMeta implements FileMetaInfo {
    id: string;
    fileSize: number;
    chunkSize: number;
    checksums: string[] = [];
    started = false;
    storage: Storage;

    constructor(id: string, fileSize: number, chunkSize: number, storage: Storage = window.localStorage) {
        this.id = id;
        this.fileSize = fileSize;
        this.chunkSize = chunkSize;
        this.storage = storage;
        const info = this.getMetaInfo();
        if (info) {
            this.checksums = info.checksums ?? [];
        }
    }

    get metaInfo(): FileMetaInfo {
        return {
            id: this.id,
            fileSize: this.fileSize,
            chunkSize: this.chunkSize,
            started: this.started,
            checksums: this.checksums,
        };
    }

    getMetaInfo(): FileMetaInfo {
        const meta = this.storage.getItem(`${STORAGE_KEY}.${this.id}`);
        if (meta) {
            return JSON.parse(meta) as FileMetaInfo;
        } else {
            return {
                id: this.id,
                fileSize: this.fileSize,
                chunkSize: this.chunkSize,
                started: this.started,
                checksums: this.checksums,
            };
        }
    }

    persistMetaInfo() {
        this.setMetaInfo(this.metaInfo);
    }

    protected setMetaInfo(meta: FileMetaInfo | null | undefined) {
        const key = `${STORAGE_KEY}.${this.id}`;
        if (meta) {
            this.storage.setItem(key, JSON.stringify(meta));
        } else {
            this.storage.removeItem(key);
        }
    }

    isResumable() {
        const meta = this.getMetaInfo();
        return meta.started && this.chunkSize === meta.chunkSize;
    }

    getResumeIndex() {
        return this.getMetaInfo().checksums.length - 1;
    }

    getFileSize() {
        return this.getMetaInfo().fileSize;
    }

    addChecksum(index, checksum) {
        this.checksums[index] = checksum;
        this.started = true;
        this.persistMetaInfo();
    }

    getChecksum(index) {
        return this.getMetaInfo().checksums[index];
    }

    reset() {
        this.checksums = [];
        this.started = false;
        this.setMetaInfo(null);
    }
}
