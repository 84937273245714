import { defaultTextClass, IconProps } from '@components/icons/CustomIcons';

export const VolcanoChartFilledIcon = ({ width = 38, height = 39, className = defaultTextClass }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="4.7373"
                cy="29.1455"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="12.2119"
                cy="19.3096"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="26.198"
                cy="12.0564"
                r="1.86865"
                transform="rotate(-154.915 26.198 12.0564)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="11.2776"
                cy="13.3921"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="21.594"
                cy="23.9812"
                r="1.86865"
                transform="rotate(-154.915 21.594 23.9812)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="19.4551"
                cy="29.737"
                r="1.86865"
                transform="rotate(-154.915 19.4551 29.737)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="20.1743"
                cy="35.5154"
                r="1.86865"
                transform="rotate(-154.915 20.1743 35.5154)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="14.0806"
                cy="35.8159"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="29.0383"
                cy="2.48461"
                r="1.86865"
                transform="rotate(-154.915 29.0383 2.48461)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="7.8518"
                cy="9.34375"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="29.0559"
                cy="19.3425"
                r="1.86865"
                transform="rotate(-154.915 29.0559 19.3425)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="2.86865"
                cy="7.16308"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="27.0454"
                cy="33.1877"
                r="1.86865"
                transform="rotate(-154.915 27.0454 33.1877)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="32.0642"
                cy="8.73119"
                r="1.86865"
                transform="rotate(-154.915 32.0642 8.73119)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="13.0112"
                cy="25.8786"
                r="1.86865"
                transform="rotate(-154.915 13.0112 25.8786)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="9.67698"
                cy="3.968"
                r="1.86865"
                transform="rotate(-154.915 9.67698 3.968)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="22.5376"
                cy="16.8288"
                r="1.86865"
                transform="rotate(-154.915 22.5376 16.8288)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="3.49145"
                cy="21.1782"
                r="1.86865"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="34.9404"
                cy="17.7453"
                r="1.86865"
                transform="rotate(-154.915 34.9404 17.7453)"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
