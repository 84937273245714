import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

export type Props = {
    open?: boolean;
    title?: string;
    message?: string | ReactNode;
    confirmText?: string;
    onClose: () => void;
};
const SuccessModal = ({ open = false, message, title = 'Success', confirmText = 'Close', onClose }: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const handleConfirm = () => {
        onClose();
    };

    return (
        <Dialog open={open} maxWidth="sm">
            <DialogCloseButton onClose={() => onClose()} />
            {title && <PlutoDialogTitle title={title} />}
            {message && (
                <DialogContent className="mb-4 space-y-4">
                    <div className="text-base">{message}</div>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleConfirm}
                    variant="contained"
                    fullWidth={isSmAndDown}
                    size="small"
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default SuccessModal;
