import React, { ReactNode } from 'react';
import { AssayDataUnits } from '@models/Experiment';
import AxisRangeFieldGroup from '@components/experiments/plotDisplay/groups/AxisRangeFieldGroup';

type Props = { labelPrefix?: ReactNode; showLabelPrefix?: boolean } & Pick<
    AssayDataUnits,
    'units' | 'units_display_name'
>;
const XAxisRangeFieldGroup = ({ labelPrefix: labelPrefixProp, ...props }: Props) => {
    const labelPrefix = labelPrefixProp ?? (
        <span>
            <span className="italic">x</span>-axis
        </span>
    );

    return <AxisRangeFieldGroup startName="x_axis_start" endName="x_axis_end" labelPrefix={labelPrefix} {...props} />;
};

export default XAxisRangeFieldGroup;
