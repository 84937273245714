import { useEffect, useState } from 'react';
import Plot from '@models/Plot';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';

const useFetchPlot = ({ plotId, experimentId }: { plotId: string | undefined; experimentId: string | undefined }) => {
    const [plot, setPlot] = useState<Plot | null>(null);
    const [plotError, setPlotError] = useState<string>('');
    const [plotLoading, setPlotLoading] = useState<boolean>(false);
    const api = useApi();

    useEffect(() => {
        if (!plotId || !experimentId) return setPlot(null);

        const fetchPlot = async () => {
            setPlotError('');
            setPlotLoading(true);
            try {
                const newPlot = await api.get<Plot>(Endpoints.lab.experiment.plot.base({ plotId, experimentId }));
                setPlot(newPlot);
            } catch {
                setPlotError('Failed to fetch plot information! Please contact support.');
            } finally {
                setPlotLoading(false);
            }
        };

        fetchPlot();
    }, [plotId]);

    return {
        plot,
        plotError,
        plotLoading,
    };
};

export default useFetchPlot;
