import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const ImageAnalysisIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <path
                d="M1.1875 16.5629C3.27083 14.1462 8.08086 10.4739 9.5625 10.2508C10.8125 10.0627 17.3125 17.125 18.125 18.0005"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <path
                d="M15.3125 14.5L17.7296 12.8154C18.7567 12.0995 20.1203 12.0967 21.1503 12.8084L22.875 14"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <circle cx="16" cy="7" r="2" fill="currentColor" />
        </svg>
    );
};
