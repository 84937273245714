import { createTheme } from '@mui/material/styles';
const theme = createTheme();
const useFormStyles = {
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        backgroundColor: '#CED4FB',
        margin: 2,
    },
    chipIcon: {
        color: theme.palette.secondary.main,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    variableIcon: {
        '&&:empty': {
            display: 'none',
        },
    },
    checkbox: {
        marginRight: theme.spacing(1),
    },
    checked: {
        display: 'none',
        marginRight: theme.spacing(1),
    },
    rootItem: {
        '& $selectedTarget': {
            background: 'transparent',
        },
    },
    selectedTarget: {
        '& $checked': {
            display: 'block',
        },
        '& $checkbox': {
            display: 'none',
        },
    },
    targetMenu: {
        borderRadius: 8,
    },
};

export default useFormStyles;
