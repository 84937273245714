import Plot from '@models/Plot';
import { BaseAnalysisParameters, CoverageAnalysisParameters } from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import { useFormikContext } from 'formik';
import React from 'react';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import { CoverageAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';

type Props = {
    plot: Plot;
    analysisParameters: CoverageAnalysisParameters;
    experiment: Experiment;
};
const CoverageAnalysisFormFields = ({ experiment, plot, analysisParameters }: Props) => {
    const { values, ...form } = useFormikContext<CoverageAnalysisFormValues>();

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Analyze</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            checked={values.include_all_samples ?? false}
                            onChange={() => {
                                form.setValues({
                                    ...values,
                                    include_all_samples: true,
                                    variables: [],
                                    group_ids: [],
                                });
                            }}
                        />
                        <span className="ml-2">All samples</span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            onChange={() => {
                                form.setValues({
                                    ...values,
                                    include_all_samples: false,
                                    variables: [],
                                    group_ids: [],
                                });
                            }}
                            checked={!values.include_all_samples}
                        />
                        <span className="ml-2">Specific groups of samples</span>
                    </label>
                </div>
                {!values.include_all_samples && (
                    <div className="mt-4">
                        <AnalysisVariablesMultiSelectField
                            variables={(analysisParameters as BaseAnalysisParameters).variables}
                        />
                        <AnalysisGroupsField plot={plot} experiment={experiment} />
                    </div>
                )}
            </section>
            <p className="space-y-2">
                <span className="italic">Note:</span> This analysis will only plot genomic positions that are 2.0Kb
                upstream and/or 2.0Kb downstream of the transcription start site (TSS) reference point.
            </p>
        </div>
    );
};

export default CoverageAnalysisFormFields;
