import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const ScoreBarPlotIcon = ({ height = 31, width = 30, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            fill="currentColor"
            viewBox="0 0 31 30"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M5.415 14.385V1m4.274 13.385V5.616m4.277 8.769V8.693M26.793 29V15.616m-4.344 8.999v-9.23m-4.208 5.153v-5.692M1 14.385h29"
            />
        </svg>
    );
};
