import React, { PropsWithChildren, useEffect, useState } from 'react';
import Button from '@components/Button';
import TextInputField from '../../forms/TextInputField';
import ControlledCustomLegendColorField from '../../experiments/plotDisplay/groups/ControlledCustomLegendColorField';
import { PlotLegendDisplayFormValues } from '@/src/models/PlotDisplayOption';
import { useFormikContext } from 'formik';
import { CustomizablePlotChartDisplayOption } from '@/src/models/plotDisplayOption/BasePlotDisplayOption';
import { Dialog, DialogContent } from '@mui/material';
import DialogCloseButton from '../../DialogCloseButton';
import PlutoDialogTitle from '../../PlutoDialogTitle';
import { HybridLegendItem } from '../../experiments/plotDisplay/forms/VennDiagramDisplayFields';

const CIRCLE_OPACITY = 0.25;
const DEFAULT_LABEL_COLOR = '#000000';

type StateOptions = {
    circle_color?: string | null | undefined;
    circle_opacity?: number;
    label_color?: string | null | undefined;
    sublabel_color?: string | null | undefined;
};
type Props = PropsWithChildren<{
    onClose: () => void;
    onSave: ({ groupId, options, legend }: { groupId: string | number; options: any; legend?: any }) => void;
    open: boolean;
    selectedItem: HybridLegendItem;
}>;
const EditVennDiagramLegendModal = ({ onClose, onSave, open, selectedItem }: Props) => {
    const defaultCircleOpacity = CIRCLE_OPACITY * 100;

    const { values } = useFormikContext<Partial<PlotLegendDisplayFormValues & CustomizablePlotChartDisplayOption>>();
    const [unsavedOptions, setUnsavedOptions] = useState<StateOptions | null>(null);
    const [unsavedLegend, setUnsavedLegend] = useState<Record<string, string> | null>(null);

    const setInitialOptions = () => {
        if (values.custom_color_json?.[selectedItem.id]) {
            setUnsavedOptions({
                circle_color: values.custom_color_json?.[selectedItem.id],
            });
        }
        if (values.custom_options_json?.[selectedItem.id]) {
            setUnsavedOptions({
                ...values.custom_options_json?.[selectedItem.id],
            });
        }
    };

    const setInitialLegend = () => {
        if (values.custom_legend_json?.[selectedItem.id]) {
            setUnsavedLegend({
                [selectedItem.id]: values.custom_legend_json[selectedItem.id],
            });
        }
    };

    useEffect(() => {
        if (open) {
            setInitialOptions();
            setInitialLegend();
        }
    }, [open]);

    const renderFields = () => {
        return (
            <>
                <div className="mb-3 flex items-center space-x-1 pt-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Fill color</p>
                    <div className="flex-1 justify-center">
                        <ControlledCustomLegendColorField
                            hideLabel
                            items={[selectedItem]}
                            leftOffsetClassName="-left-48"
                            onChange={(color) => {
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    circle_color: color,
                                }));
                            }}
                            value={unsavedOptions?.circle_color}
                        />
                    </div>
                </div>
                <div className="mb-3 flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Fill opacity</p>
                    <div className="flex-1">
                        <TextInputField
                            name="circle_opacity"
                            label=""
                            value={unsavedOptions?.circle_opacity ?? defaultCircleOpacity}
                            noMargin
                            type="number"
                            step={1}
                            suffix="%"
                            min={0}
                            max={100}
                            onValueChange={(_, __, values) =>
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    circle_opacity: values?.float ?? 0,
                                }))
                            }
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            disableFormik
                            useNumericFormat
                        />
                    </div>
                </div>

                <div className="mb-3 flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Label color</p>
                    <div className="flex-1 justify-center">
                        <ControlledCustomLegendColorField
                            hideLabel
                            items={[selectedItem]}
                            leftOffsetClassName="-left-48"
                            onChange={(color) => {
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    label_color: color,
                                }));
                            }}
                            value={unsavedOptions?.label_color}
                            fallbackColor={DEFAULT_LABEL_COLOR}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Sub-label color</p>
                    <div className="flex-1 justify-center">
                        <ControlledCustomLegendColorField
                            hideLabel
                            items={[selectedItem]}
                            leftOffsetClassName="-left-48"
                            onChange={(color) => {
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    sublabel_color: color,
                                }));
                            }}
                            value={unsavedOptions?.sublabel_color}
                            fallbackColor={DEFAULT_LABEL_COLOR}
                        />
                    </div>
                </div>
            </>
        );
    };

    const closeAndReset = () => {
        onClose();
        setTimeout(() => {
            setUnsavedOptions(null);
            setUnsavedLegend(null);
        }, 100);
    };

    return (
        <Dialog open={open} onClose={closeAndReset} fullWidth maxWidth="xs">
            <DialogCloseButton onClose={closeAndReset} />

            <PlutoDialogTitle title="Edit legend group" titleClassName="!break-normal" />

            <DialogContent>
                <div className="space-y-4">
                    {renderFields()}
                    <div className="flex justify-between space-x-2 pt-4">
                        <Button
                            color="primary"
                            variant="text"
                            size="small"
                            onClick={() => {
                                setUnsavedOptions(null);
                                setUnsavedLegend(null);
                                onSave({ groupId: selectedItem?.id, options: {}, legend: {} });
                                closeAndReset();
                            }}
                        >
                            <span className="text-error">Reset</span>
                        </Button>
                        <div className="flex justify-end space-x-4 ">
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => {
                                    closeAndReset();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    onSave({
                                        groupId: selectedItem?.id,
                                        options: unsavedOptions,
                                        legend: unsavedLegend,
                                    });
                                    closeAndReset();
                                }}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditVennDiagramLegendModal;
