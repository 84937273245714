import React from 'react';

type Props = { doi: string; noPrefix?: boolean; domain?: string };
const DoiLink = ({ doi, noPrefix = false, domain = 'https://doi.org/' }: Props) => {
    return (
        <span>
            {!noPrefix && <span>doi: </span>}
            <a href={`${domain}${doi}`} target="_blank" rel="nofollow noopener noreferrer" className="whitespace-pre">
                {doi}
            </a>
        </span>
    );
};

export default DoiLink;
