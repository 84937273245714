import { IconProps } from '@components/icons/CustomIcons';

export const LongitudinalAnalysisIcon = (props?: IconProps) => {
    const { width = 18, height = 18, className } = { ...props };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={className}
            strokeWidth={props?.strokeWidth ?? 2}
        >
            <path
                d="M8.08694 15C8.73527 15 9.26085 14.502 9.26085 13.8876C9.26085 13.2733 8.73527 12.7753 8.08694 12.7753C7.4386 12.7753 6.91302 13.2733 6.91302 13.8876C6.91302 14.502 7.4386 15 8.08694 15Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.17391 12.7079C4.82225 12.7079 5.34783 12.2098 5.34783 11.5955C5.34783 10.9812 4.82225 10.4831 4.17391 10.4831C3.52558 10.4831 3 10.9812 3 11.5955C3 12.2098 3.52558 12.7079 4.17391 12.7079Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.8261 11.2247C20.4744 11.2247 21 10.7267 21 10.1124C21 9.49802 20.4744 9 19.8261 9C19.1777 9 18.6522 9.49802 18.6522 10.1124C18.6522 10.7267 19.1777 11.2247 19.8261 11.2247Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.913 11.2247C16.5614 11.2247 17.087 10.7267 17.087 10.1124C17.087 9.49802 16.5614 9 15.913 9C15.2647 9 14.7391 9.49802 14.7391 10.1124C14.7391 10.7267 15.2647 11.2247 15.913 11.2247Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8477 11.7283C12.2757 11.4502 11.5913 11.7144 11.3191 12.3185C11.0468 12.9226 11.2898 13.6378 11.8618 13.9159C12.4338 14.194 13.1182 13.9298 13.3905 13.3257C13.6627 12.7216 13.4197 12.0064 12.8477 11.7283Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M4.46123 12.0848L7.30435 13.8202" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line
                x1="1"
                y1="-1"
                x2="3.35511"
                y2="-1"
                transform="matrix(-0.960178 0.279389 -0.248053 -0.968746 12.3042 11.9663)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <line
                x1="1"
                y1="-1"
                x2="3.425"
                y2="-1"
                transform="matrix(-0.81441 0.580289 -0.531225 -0.84723 15.4971 9.60675)"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path d="M19.0492 10.2091L16.281 10.1124" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
};
