import React, { useEffect } from 'react';
import PreprocessStep from '@models/PreprocessStep';
import NormalizeSeuratMethods from './preprocesses/NormalizeSeuratMethods';
import CreateSeuratMethods from './preprocesses/CreateSeuratMethods';
import PrepareDataMethods from './preprocesses/PrepareDataMethods';
import FilterSeuratMethods from './preprocesses/FilterSeuratMethods';
import IntegrateSeuratMethods from './preprocesses/IntegrateSeuratMethods';
import ClusterSeuratMethods from './preprocesses/ClusterSeuratMethods';
import RefineSeuratMethods from './preprocesses/RefineSeuratMethods';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import { PreprocessFormValues } from '../preprocesses/PreprocessFormTypes';
import Experiment from '@/src/models/Experiment';
import LoadingMessage from '../../LoadingMessage';

type Props = {
    preprocess: PreprocessStep;
    experiment: Experiment;
};

/**
 * Displays information on the methods used to generate a preprocess.
 * @param {PreprocessStep} preprocess
 * @param {Experiment} experiment
 * @return {JSX.Element}
 * @constructor
 */
export const MethodsPreprocessContent = ({ experiment, preprocess }: Props) => {
    const preprocessType = preprocess?.preprocess_type?.shortname;
    const preprocessDisplayName = preprocess?.preprocess_type?.display_name;
    const { preprocessFormData, getFormData } = useExperimentWorkflowContext();
    const formData = preprocessFormData as PreprocessFormValues;

    useEffect(() => {
        if (!preprocessFormData) {
            getFormData();
        }
    }, [preprocessFormData]);

    if (!preprocessFormData) {
        return <LoadingMessage message="Loading methods..." />;
    }

    switch (preprocessType) {
        case 'seurat_prepare_data':
            return <PrepareDataMethods displayName={preprocessDisplayName} />;
        case 'seurat_create_object':
            return <CreateSeuratMethods preprocessFormData={formData} displayName={preprocessDisplayName} />;
        case 'seurat_filter_object':
            return (
                <FilterSeuratMethods
                    preprocessFormData={formData}
                    displayName={preprocessDisplayName}
                    experiment={experiment}
                />
            );
        case 'seurat_normalize_object':
            return <NormalizeSeuratMethods preprocessFormData={formData} displayName={preprocessDisplayName} />;
        case 'seurat_integrate_object':
            return <IntegrateSeuratMethods preprocessFormData={formData} displayName={preprocessDisplayName} />;
        case 'seurat_cluster_object':
            return <ClusterSeuratMethods preprocessFormData={formData} displayName={preprocessDisplayName} />;
        case 'seurat_refine_object':
            return <RefineSeuratMethods preprocessFormData={formData} displayName={preprocessDisplayName} />;
        default:
            return <p>Looks like we&apos;re unable to find any methods right now. Please try again later.</p>;
    }
};
