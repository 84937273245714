import {
    AXIS_LABEL_CLASSNAMES,
    AXIS_LABEL_PUBLICATION_CLASSNAMES,
    AXIS_TITLE_CLASSNAMES,
    AXIS_TITLE_PUBLICATION_CLASSNAMES,
    ErrorBarOption,
    ThemeStyle,
} from '@models/PlotConfigs';
import { FlatSample, getD3Symbol, GroupSeries, LineConfig } from '@components/plots/PlotTypes';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { ParameterOption } from '@models/AnalysisParameters';
import { getStatisticalValueKey, SummaryStatisticalTestResult } from '@models/SummaryStatisticalTestResult';
import * as d3 from 'd3';
import {
    axisBottom,
    axisLeft,
    BaseType,
    max,
    min,
    ScaleBand,
    scaleBand,
    ScaleLinear,
    ScaleSymLog,
    Selection,
} from 'd3';
import {
    BAR_CORNER_RADIUS,
    DATAPOINT_SYMBOL_SIZE,
    getAssaySummaryYAxisTitle,
    getGroupColor,
    getSimplePlotMargins,
    getSimpleTooltipContent,
    MAX_BAR_WIDTH,
    ROTATE_X_AXIS_GROUP_THRESHOLD,
    rotateXAxisLabels as drawRotatedXAxisLabels,
    wrapTextNode,
    Y_AXIS_PADDING_FACTOR,
    yAverage,
    yDomain,
} from '@components/plots/PlotUtil';
import BarPlotDisplayOption from '@models/plotDisplayOption/BarPlotDisplayOption';
import BoxPlotDisplayOption from '@models/plotDisplayOption/BoxPlotDisplayOption';
import { isBarPlotDisplayOption, isBoxPlotDisplayOption } from '@models/PlotDisplayOption';
import { getErrorBarDeviationY, StatOverlap, StatSummary } from '@components/plots/SummaryPlotUtil';
import { formatTableHeader } from '@util/StringUtil';
import { isDefined, isString } from '@util/TypeGuards';
import Logger from '@util/Logger';
import { ConstructorParams as BaseParams, PlotMargin } from '@components/plots/builders/BasePlotBuilder';
import SummaryAnalysisPlotBuilder from '@components/analysisCategories/summary/plots/builders/SummaryAnalysisPlotBuilder';
import { getPlotPalette, getSvgRectClasses } from '@components/ColorPaletteUtil';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const STAT_LINE_PADDING = 12;
const logger = Logger.make('BarBoxPlotBuilder');

const HIDE_X_AXIS_DOMAIN_WHEN_NEGATIVE = false;
type AnalysisParameterHelpers = ReturnType<typeof getAnalysisParametersHelpers>;

export type ConstructorParams = BaseParams & {
    stylingOptions?: CustomPlotStylingOptions | null;
    getGroupById: (id: number) => ParameterOption | null;
    visiblePlotStats?: SummaryStatisticalTestResult[] | null;
    hasErrorBars?: boolean;
} & Pick<AnalysisParameterHelpers, 'getTargetById'>;

export default class BarBoxPlotBuilder extends SummaryAnalysisPlotBuilder {
    customPlotStylingOptions;
    stylingOptions?: CustomPlotStylingOptions | null;
    visiblePlotStats: SummaryStatisticalTestResult[];
    hasErrorBars: boolean;
    scales: {
        yScale: ScaleLinear<number, number> | ScaleSymLog<number, number>;
        targetXScale: ScaleBand<string>;
        groupXScale: ScaleBand<string>;
    };
    getGroupById: (id: number) => ParameterOption | null;
    getTargetById: (targetId: number | string) => ParameterOption | null;
    statSummaryItems: StatSummary[];
    adjustedStatSummaryItems: StatSummary[];
    numStatAdjustments = 0;

    protected constructor(options: ConstructorParams) {
        super(options);

        this.visiblePlotStats = options.visiblePlotStats ?? [];
        this.hasErrorBars = options.hasErrorBars ?? false;
        this.numStatAdjustments = this.getStatOverlapInfo().numAdjustments;
        this.stylingOptions = options.stylingOptions;

        this.scales = this.makeScales({ margin: this.margin });
    }

    calculateMargins(): PlotMargin {
        const rotateXAxisLabels = this.data.target_groups.length > ROTATE_X_AXIS_GROUP_THRESHOLD;
        return getSimplePlotMargins({ rotateXAxisLabels, yAxisTitle: this.yAxisTitle });
    }

    protected getYScale = ({ margin }: { margin: PlotMargin }) => {
        const height = this.height;
        const scale = this.displayOptions.y_axis_scale ?? 'linear';
        const { yMin, yMax } = this.yDomain;
        switch (scale) {
            case 'symlog':
                return d3
                    .scaleSymlog()
                    .domain([yMin, yMax])
                    .rangeRound([height - margin.bottom, margin.top]);
            case 'linear':
            default:
                return d3
                    .scaleLinear()
                    .domain([yMin, yMax])
                    .rangeRound([height - margin.bottom, margin.top]);
        }
    };

    protected makeScales = ({ margin }: { margin: PlotMargin }) => {
        const firstTargetGroups = this.firstTargetGroups;

        const targetNames = this.getTargetNames();
        const width = this.width;
        const yScale = this.getYScale({ margin });
        const targetXScale = scaleBand()
            .domain(targetNames)
            .range([margin.left, width - margin.right])
            .padding(0.2);

        const groupXScale = scaleBand()
            .domain(firstTargetGroups.map((d) => d.group_name))
            .range([0, targetXScale.bandwidth()])
            .padding(0.1);

        return { yScale, targetXScale, groupXScale };
    };

    static make(options: ConstructorParams): BarBoxPlotBuilder {
        return new BarBoxPlotBuilder(options);
    }

    get xAxisLabelRotation() {
        if (this.displayOptions.display_type === 'heatmap') {
            return 90;
        }
        if (this.publicationMode || this.data.target_groups.length > ROTATE_X_AXIS_GROUP_THRESHOLD) {
            return 45;
        }
        return 0;
    }

    get displayOptions(): BarPlotDisplayOption | BoxPlotDisplayOption {
        return this.plot.display as BarPlotDisplayOption | BoxPlotDisplayOption;
    }

    get boxPlotDisplayOptions(): BoxPlotDisplayOption | null {
        if (isBoxPlotDisplayOption(this.displayOptions)) {
            return this.displayOptions;
        }
        return null;
    }

    get barPlotDisplayOptions(): BarPlotDisplayOption | null {
        if (isBarPlotDisplayOption(this.displayOptions)) {
            return this.displayOptions;
        }
        return null;
    }

    get yAxisTitle() {
        return getAssaySummaryYAxisTitle({
            experiment: this.experiment,
            analysisType: this.plot.analysis?.analysis_type,
            options: this.displayOptions,
        });
    }

    get yAxisFormat() {
        const { yMax } = this.yDomain;
        return yMax > 10000 ? '.1e' : ',f';
    }

    getBoxPlotWhiskerRange(): { max: number; min: number } {
        const targetData = this.targetData;
        const range = { max: 0, min: 0 };

        targetData.forEach((target) => {
            target.groups.forEach((group) => {
                const domain = yDomain(group);
                range.min = Math.min(range.min, domain.whiskerLower);
                range.max = Math.max(range.max, domain.whiskerUpper);
            });
        });

        return range;
    }

    get errorBarValues(): { bottom: number; top: number } {
        // Set up the Y axis
        const maxSampleValue = this.maxSampleValue;
        const minSampleValue = this.minSampleValue;
        const targetData = this.targetData;

        const isBarPlot = isBarPlotDisplayOption(this.displayOptions);
        if (!isBarPlot) {
            return { top: 0, bottom: 0 };
        }
        const barPlotOptions = this.barPlotDisplayOptions;
        const showErrorBars = barPlotOptions?.show_error_bars ?? false;
        const errorBarLocations = barPlotOptions?.error_bars_locations ?? [];
        const errorBarOption: ErrorBarOption = barPlotOptions?.error_bars_option ?? 'sd';
        const yErrorBarTop =
            max(targetData, (d) =>
                max(d.groups, (g) => {
                    const deviation =
                        showErrorBars && errorBarLocations.includes('top')
                            ? getErrorBarDeviationY(g, errorBarOption)
                            : 0;
                    const avg = yAverage(g);
                    return avg + deviation;
                }),
            ) ?? maxSampleValue;

        const yErrorBarBottom =
            min(targetData, (d) =>
                min(d.groups, (g) => {
                    const deviation =
                        showErrorBars && errorBarLocations.includes('bottom')
                            ? getErrorBarDeviationY(g, errorBarOption)
                            : 0;
                    const avg = yAverage(g);
                    return avg - deviation;
                }),
            ) ?? minSampleValue;

        return { top: yErrorBarTop, bottom: yErrorBarBottom };
    }

    getStatLineYOffsetFactor = () => {
        const hasStatLines = this.visiblePlotStats.length > 0;
        const statOffset = hasStatLines ? 0.08 : 0;
        return statOffset * this.numStatAdjustments;
    };

    get sampleYMax() {
        const { top: yErrorBarTop } = this.errorBarValues;
        return this.displayOptions.y_axis_end ?? Math.max(yErrorBarTop, this.maxSampleValue, 0);
    }

    get yDomain(): { yMin: number; yMax: number } {
        const { top: yErrorBarTop, bottom: yErrorBarBottom } = this.errorBarValues;
        const whiskerRange = this.displayOptions.display_type === 'box_plot' ? this.getBoxPlotWhiskerRange() : null;

        const yMin =
            this.displayOptions.y_axis_start ??
            Math.min(this.minSampleValue, yErrorBarBottom, 0, whiskerRange?.min ?? 0) * Y_AXIS_PADDING_FACTOR;

        const statOffset = this.getStatLineYOffsetFactor();
        const yMax =
            this.displayOptions.y_axis_end ??
            Math.max(yErrorBarTop, this.maxSampleValue, 0, whiskerRange?.max ?? 0) *
                (Y_AXIS_PADDING_FACTOR + statOffset);
        return { yMin, yMax };
    }

    appendYAxis = () => {
        const styles = this.stylingOptions?.yaxis;
        const { yScale } = this.scales;
        const { yMax } = this.yDomain;
        const height = this.height;
        const margin = this.margin;
        const yAxisTitle = this.yAxisTitle;
        const yAxisFormat = this.yAxisFormat;
        const magnitude = Math.floor(Math.log10(yMax));
        const logTickValues: number[] = [];
        const majorTicks: number[] = [0, 1];
        const publicationMode = this.publicationMode;
        for (let j = 0; j < 10; j++) {
            logTickValues.push(Number((0.1 * j).toFixed(1)));
        }
        for (let i = 0; i <= magnitude; i++) {
            const nextVal = Math.pow(10, i + 1);

            if (nextVal > 1) {
                const stepVal = nextVal / 10;
                for (let j = 1; j < 10; j++) {
                    const minorValue = stepVal * j;
                    if (minorValue < yMax) {
                        logTickValues.push(minorValue);
                    }
                }
            }
            if (nextVal < yMax) {
                logTickValues.push(nextVal);
                majorTicks.push(nextVal);
            }
        }
        if (Math.ceil(yMax) > 1.1 * majorTicks[majorTicks.length - 1]) {
            majorTicks.push(Math.ceil(yMax));
        }
        logTickValues.push(Math.ceil(yMax));

        const tickValues =
            isDefined(this.displayOptions.y_axis_scale) && this.displayOptions.y_axis_scale !== 'linear'
                ? logTickValues
                : null;

        const drawYAxis = (g) => {
            const yAxisConfig = axisLeft(yScale).ticks(null, yAxisFormat).tickSizeOuter(0);
            if (tickValues) {
                yAxisConfig.tickValues(tickValues).tickFormat((value: number) => {
                    if (majorTicks.includes(value)) {
                        return `${value}`;
                    }
                    return ``;
                });
            }
            return g
                .call((g) => g.select('.domain').remove())
                .attr('transform', `translate(${margin.left},0)`)
                .attr('class', `${publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES} y-axis`)
                .call(yAxisConfig)
                .call((g) =>
                    g
                        .append('text')
                        .attr('x', -height / 2)
                        .attr('y', -margin.left + 20)
                        .attr('fill', styles ? styles.fontColor : 'currentColor')
                        .style('font-size', styles ? styles.fontSize : '18')
                        .style('font-family', styles ? styles.fontFamily : 'Arial')
                        .attr('text-anchor', 'middle')
                        .attr('transform', 'rotate(-90)')
                        .attr('class', this.publicationMode ? AXIS_TITLE_PUBLICATION_CLASSNAMES : AXIS_TITLE_CLASSNAMES)
                        .text(`${yAxisTitle ?? ''}`),
                );
        };
        this.svg.selectAll('.y-axis').remove();
        this.svg.append('g').call(drawYAxis);
    };

    getBarWidth = () => {
        return Math.min(this.scales.groupXScale.bandwidth(), MAX_BAR_WIDTH);
    };

    getBarX = (group_name: string) => {
        const { groupXScale } = this.scales;
        return (groupXScale(group_name) ?? 0) + (groupXScale.bandwidth() - this.getBarWidth()) / 2;
    };

    appendXAxis = () => {
        const { targetXScale } = this.scales;
        const height = this.height;
        const margin = this.margin;
        const { yMin } = this.yDomain;
        let labelRotation = this.xAxisLabelRotation;
        const publicationMode = this.publicationMode;
        const drawXAxis = (g: Selection<SVGGElement, unknown, BaseType, unknown>) => {
            g.attr('transform', `translate(0,${height - margin.bottom})`)
                .attr('class', `${publicationMode ? AXIS_LABEL_PUBLICATION_CLASSNAMES : AXIS_LABEL_CLASSNAMES} x-axis`)
                .call(
                    axisBottom(targetXScale)
                        .tickSize(12)
                        .tickSizeOuter(0)
                        .tickFormat((label) => formatTableHeader(label)),
                );
            const labels = g.selectAll<SVGTextElement, undefined>('.tick text');

            if (
                this.targetData.length > 1 &&
                (targetXScale.bandwidth() < 80 ||
                    labels
                        .nodes()
                        .some((node) => (node.getBoundingClientRect()?.width ?? 0) >= targetXScale.bandwidth()))
            ) {
                labelRotation = 45;
            }

            if (labelRotation) {
                labels.call(wrapTextNode, 160);
                drawRotatedXAxisLabels(g, labelRotation);
            } else {
                labels.call(wrapTextNode, targetXScale.bandwidth());
            }

            if (yMin < 0 && HIDE_X_AXIS_DOMAIN_WHEN_NEGATIVE) {
                g.select('.domain').remove();
            }

            return g;
        };
        this.svg.selectAll('.x-axis').remove();
        this.svg.append('g').call(drawXAxis);
    };

    drawLine = (lineConfig: LineConfig<GroupSeries>) => {
        const targetData = this.targetData;
        const { targetXScale } = this.scales;
        this.svg
            .append('g')
            .attr('clip-path', `url(#${this.clipPathId})`)
            .selectAll('g')
            .data(targetData)
            .enter()
            .append('g')
            .attr('transform', (target) => `translate(${targetXScale(target.target_name)}, 0)`)
            .selectAll('line')
            .data((targetSeries) => targetSeries.groups)
            .enter()
            .append('line')
            .attr('pointer-events', 'none')
            .attr('stroke', '#333')
            .attr('stroke-width', 1)
            .attr('x1', lineConfig.x1)
            .attr('x2', lineConfig.x2)
            .attr('y1', lineConfig.y1)
            .attr('y2', lineConfig.y2)

            .style('display', (d) => (lineConfig.hidden?.(d) ? 'none' : ''))
            .attr('rx', 8);
    };

    drawYZeroLine = () => {
        const { yScale, targetXScale } = this.scales;
        const [xMin, xMax] = targetXScale.range();
        this.svg
            .append('g')
            .append('line')
            .attr('stroke', '#333')
            .attr('stroke-width', 1)
            .attr('x1', xMin)
            .attr('x2', xMax)
            .attr('y1', yScale(0) + 0.5)
            .attr('y2', yScale(0) + 0.5);
    };

    drawDataPoints = () => {
        const svg = this.svg;
        const options = this.displayOptions;
        const themeColor = options.theme_color;
        const firstTargetGroups = this.firstTargetGroups;
        const themeStyle = options.theme_style;
        const customColors = options.custom_color_json ?? {};
        const allSamples = this.allSamples;
        const { yMin, yMax } = this.yDomain;
        const { yScale, groupXScale, targetXScale } = this.scales;
        const tooltipContainer = this.tooltip;

        const getStrokeColor = (d: FlatSample) => {
            const custom = customColors[`${d.group_id}`];
            if (custom) {
                return custom;
            }
            return getGroupColor(d, { themeColor, firstTargetGroups })?.color;
        };

        const getFillColor = (d: FlatSample) => {
            return themeStyle === ThemeStyle.outline ? customColors[`${d.group_id}`] ?? getStrokeColor(d) : 'white';
        };

        // Draw data points. First, remove any existing ones. Filter out points outside of max/min range
        svg.selectAll('.data-point').remove();
        if (options.show_data_points) {
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')
                .data(allSamples.filter((s) => s.value >= yMin && s.value <= yMax))
                .enter()
                .append('path')
                .attr('d', (d) => {
                    return d3.symbol().type(getD3Symbol(d.symbol)).size(DATAPOINT_SYMBOL_SIZE)();
                })

                .style('fill-opacity', '.75')
                .attr('fill', (d) => getFillColor(d) ?? '')
                .attr('stroke', (d) => getStrokeColor(d) ?? '')
                .attr('stroke-width', 1)
                .attr('transform', (d) => {
                    const y = yScale(d.value);
                    const x =
                        (groupXScale(d.group_name) ?? 0) +
                        (targetXScale(d.target_name) ?? 0) +
                        groupXScale.bandwidth() / 2;
                    return `translate(${x}, ${y})`;
                })
                .on('mouseover', (event, d) => {
                    const circle = d3.select(event.target);
                    circle.style('cursor', 'crosshair').attr('stroke', 'red').attr('fill', 'red');
                    tooltipContainer.style('opacity', 1);
                    tooltipContainer
                        .html(getSimpleTooltipContent(d))
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                    event.parentNode?.appendChild(circle);
                })
                .on('mouseout', function (event, d) {
                    const circle = d3.select(event.target);
                    circle.style('fill-opacity', '.75').attr('stroke', getStrokeColor(d)).attr('fill', getFillColor(d));
                    tooltipContainer.style('opacity', 0);
                });
        }
    };

    getSortedStatGroups = (stat: SummaryStatisticalTestResult) => {
        const groupDisplayOrder = this.group_display_order;

        const sortedGroups = stat.groups.sort((g1, g2) => {
            return groupDisplayOrder.indexOf(g1) - groupDisplayOrder.indexOf(g2);
        });
        return sortedGroups;
    };

    getStatGroupSeriesInfo = (
        stat: SummaryStatisticalTestResult,
    ): {
        sortedGroupSeries: GroupSeries[];
        groupFirst: ParameterOption | null;
        groupLast: ParameterOption | null;
    } | null => {
        const { getTargetById, getGroupSeries, getGroupById } = this;
        const sortedGroups = this.getSortedStatGroups(stat);

        const groupFirst = getGroupById(sortedGroups[0]);
        const groupLast = getGroupById(sortedGroups[sortedGroups.length - 1]);
        const statTarget = stat.target;
        const targetName: string | undefined =
            getTargetById(stat.target)?.display_name ?? (isString(statTarget) ? statTarget : undefined);
        if (!groupFirst || !groupLast || !targetName) {
            logger.warn(`[stats] can not find group info when drawing stats for target ${stat.target}`);
            return null;
        }

        const sortedGroupSeries = sortedGroups
            .map((groupId) => {
                return getGroupSeries({
                    groupId: groupId,
                    targetName: targetName,
                })?.groupSeries;
            })
            .filter(isDefined);

        return { sortedGroupSeries, groupFirst, groupLast };
    };

    getStatDeviations = (stat: SummaryStatisticalTestResult, allGroupSeries: GroupSeries[]) => {
        const showErrorBars = this.barPlotDisplayOptions?.show_error_bars ?? false;
        const showDataPoints = this.displayOptions.show_data_points;
        const errorBarOption: ErrorBarOption = this.barPlotDisplayOptions?.error_bars_option ?? 'sd';

        if (allGroupSeries.length === 0) {
            return null;
        }
        return allGroupSeries.map((groupSeries) => {
            const dataPointDomain = yDomain(groupSeries);
            if (showErrorBars) {
                logger.debug('using error bar top or dataPoint domain max due to showErrorBars');
                const errorBarTop = yAverage(groupSeries) + getErrorBarDeviationY(groupSeries, errorBarOption);
                return Math.max(errorBarTop, dataPointDomain.max);
            }

            if (showDataPoints || isBoxPlotDisplayOption(this.displayOptions)) {
                logger.debug('using datapoint domain max due to show data points');
                return dataPointDomain.max;
            }

            return yAverage(groupSeries) + (showErrorBars ? getErrorBarDeviationY(groupSeries, errorBarOption) : 0);
        });
    };

    getStatOverlapInfo = (): { items: StatOverlap[]; numAdjustments: number } => {
        const overlapItems = this.visiblePlotStats
            .map<StatOverlap | null>((stat) => {
                const groupInfo = this.getStatGroupSeriesInfo(stat);
                if (!groupInfo) {
                    return null;
                }
                const { sortedGroupSeries, groupFirst, groupLast } = groupInfo;
                const deviations = this.getStatDeviations(stat, sortedGroupSeries);
                if (!deviations) {
                    return null;
                }
                const maxDeviation = Math.max(...deviations);

                return {
                    stat,
                    groupId1: groupFirst,
                    groupId2: groupLast,
                    yMax: maxDeviation,
                };
            })
            .filter(isDefined);

        if (overlapItems.length === 0) {
            return { items: overlapItems, numAdjustments: 0 };
        }

        const maxSampleY = this.sampleYMax;

        const maxStatY = overlapItems.reduce((max, stat) => {
            return stat.yMax > max ? stat.yMax : max;
        }, overlapItems[0].yMax);

        if (maxSampleY > maxStatY) {
            return { items: overlapItems, numAdjustments: 0 };
        }
        let numAdjustments = 1;
        overlapItems.forEach((stat, i) => {
            const previousStat = overlapItems[i - 1];
            if (!previousStat) {
                return;
            }

            if (previousStat.groupId1?.id === stat.groupId1?.id && previousStat.yMax === stat.yMax) {
                if (stat.yMax >= maxSampleY) {
                    numAdjustments++;
                }
            }
        });

        return { items: overlapItems, numAdjustments };
    };

    buildStatSummaryItems = (): StatSummary[] => {
        const { groupXScale, targetXScale, yScale } = this.scales;
        const { getTargetById, getGroupSeries, getGroupById } = this;
        const errorBarOption: ErrorBarOption = this.barPlotDisplayOptions?.error_bars_option ?? 'sd';
        const showErrorBars = this.barPlotDisplayOptions?.show_error_bars ?? false;
        const showDataPoints = this.displayOptions.show_data_points ?? false;
        const groupDisplayOrder = this.group_display_order;
        return this.visiblePlotStats
            .map<StatSummary | null>((stat) => {
                const sortedGroups = stat.groups.sort((g1, g2) => {
                    return groupDisplayOrder.indexOf(g1) - groupDisplayOrder.indexOf(g2);
                });

                const groupFirst = getGroupById(sortedGroups[0]);
                const groupLast = getGroupById(sortedGroups[sortedGroups.length - 1]);
                const statTarget = stat.target;
                const targetName: string | undefined =
                    getTargetById(stat.target)?.display_name ?? (isString(statTarget) ? statTarget : undefined);
                if (!groupFirst || !groupLast || !targetName) {
                    logger.warn('[stats] can not find group info', {
                        stat,
                        groupFirst,
                        groupLast,
                        targetName,
                    });
                    return null;
                }

                const allGroupSeries: GroupSeries[] = sortedGroups
                    .map((groupId) => {
                        return getGroupSeries({
                            groupId: groupId,
                            targetName: targetName,
                        })?.groupSeries;
                    })
                    .filter(isDefined);

                if (allGroupSeries.length === 0) {
                    logger.warn('[stats] no group series found for stat');
                    return null;
                }
                const deviations = allGroupSeries.map((groupSeries) => {
                    const dataPointDomain = yDomain(groupSeries);
                    if (showErrorBars) {
                        const errorBarTop = yAverage(groupSeries) + getErrorBarDeviationY(groupSeries, errorBarOption);
                        return Math.max(errorBarTop, dataPointDomain.max);
                    }

                    if (showDataPoints || isBoxPlotDisplayOption(this.displayOptions)) {
                        return dataPointDomain.max;
                    }

                    return (
                        yAverage(groupSeries) + (showErrorBars ? getErrorBarDeviationY(groupSeries, errorBarOption) : 0)
                    );
                });

                const maxDeviation = Math.max(...deviations);
                const yValue = yScale(maxDeviation) - STAT_LINE_PADDING;

                const x1 =
                    (groupXScale(groupFirst?.display_name ?? '') ?? 0) +
                    (targetXScale(targetName ?? '') ?? 0) +
                    groupXScale.bandwidth() / 2;

                const x2 =
                    (groupXScale(groupLast?.display_name ?? '') ?? 0) +
                    (targetXScale(targetName ?? '') ?? 0) +
                    groupXScale.bandwidth() / 2;

                return {
                    stat,
                    meta: {
                        group1: groupFirst,
                        group2: groupLast,
                        targetName,
                        groupName1: groupFirst?.display_name ?? '',
                        groupName2: groupLast?.display_name ?? '',
                        x1: Math.min(x1, x2),
                        x2: Math.max(x1, x2),
                        y1: yValue,
                        y2: yValue,
                    },
                };
            })
            .filter(isDefined);
    };

    static buildAdjustedStats(statSummaryItems: StatSummary[]): { stats: StatSummary[]; numAdjustments: number } {
        const adjustedStats = [...statSummaryItems];
        if (adjustedStats.length <= 1) {
            return { stats: adjustedStats, numAdjustments: 0 };
        }

        let numAdjustments = 0;
        const adjustStatAsNeeded = (stat: StatSummary, i: number, count: number): boolean => {
            if (count > adjustedStats.length) {
                logger.warn('reached limit of stat count', { adjustedStats, count });
                return false;
            }
            const allPrevious = adjustedStats.slice(0, i).filter((s) => {
                const sameTarget = s.stat.target === stat.stat.target;

                const overlappingX =
                    (s.meta.x1 < stat.meta.x2 && s.meta.x2 > stat.meta.x1) ||
                    stat.meta.x1 === s.meta.x1 ||
                    stat.meta.x2 === s.meta.x2;
                const closeY = s.meta.y1 === stat.meta.y1 || Math.abs(s.meta.y1 - stat.meta.y1) < STAT_LINE_PADDING * 2;
                return overlappingX && closeY && sameTarget;
            });

            if (allPrevious.length === 0) {
                return false;
            }
            const referenceStat = allPrevious[allPrevious.length - 1];

            const uniqueYValues = new Set(...[allPrevious.map((s) => s.meta.y1)]);

            const updatedY = referenceStat.meta.y1 - STAT_LINE_PADDING * 2 * uniqueYValues.size;
            stat.meta.y1 = updatedY;
            stat.meta.y2 = updatedY;
            numAdjustments++;

            return adjustStatAsNeeded(stat, i, count + 1);
        };

        adjustedStats.forEach((stat, i) => {
            adjustStatAsNeeded(stat, i, 0);
        });

        return { stats: adjustedStats, numAdjustments };
    }

    drawStats = () => {
        this.statSummaryItems = this.buildStatSummaryItems();
        const { stats: adjustedStats } = BarBoxPlotBuilder.buildAdjustedStats(this.statSummaryItems);
        this.adjustedStatSummaryItems = adjustedStats;
        const visiblePlotStats = this.visiblePlotStats;
        const svg = this.svg;

        if (visiblePlotStats && (visiblePlotStats?.length ?? 0) > 0) {
            const stats = this.statSummaryItems;

            // add stat horizontal line
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')
                .data(stats)
                .enter()
                .append('line')
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('x1', (stat) => stat.meta.x1)
                .attr('x2', (stat) => stat.meta.x2)
                .attr('y1', (stat) => stat.meta.y1)
                .attr('y2', (stat) => stat.meta.y2);

            // add stat vertical line caps (left)
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')
                .data(stats)
                .enter()
                .append('line')
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('x1', (stat) => stat.meta.x1)
                .attr('x2', (stat) => stat.meta.x1)
                .attr('y1', (stat) => stat.meta.y1 - 6)
                .attr('y2', (stat) => stat.meta.y2 + 6);

            // add stat vertical line caps (right)
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')
                .data(stats)
                .enter()
                .append('line')
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('x1', (stat) => stat.meta.x2)
                .attr('x2', (stat) => stat.meta.x2)
                .attr('y1', (stat) => stat.meta.y1 - 6)
                .attr('y2', (stat) => stat.meta.y2 + 6);

            // add stat label
            svg.append('g')
                .attr('clip-path', `url(#${this.clipPathId})`)
                .selectAll('g')
                .data(stats)
                .enter()
                .append('text')
                .attr('x', (stat) => stat.meta.x1 + (stat.meta.x2 - stat.meta.x1) / 2)
                .attr('y', (stat) => stat.meta.y1 - 6)
                .text((stat) => getStatisticalValueKey(stat.stat.value))
                .style('color', 'black')
                .attr('text-anchor', 'middle');
        }
    };

    get showErrorBars() {
        return this.barPlotDisplayOptions?.show_error_bars ?? false;
    }

    get errorBarLocations() {
        return this.barPlotDisplayOptions?.error_bars_locations ?? [];
    }

    get errorBarOption() {
        return this.barPlotDisplayOptions?.error_bars_option ?? 'sd';
    }

    get clipPathId() {
        return `${this.idPrefix}${this.plot.uuid}-clip`;
    }

    draw = () => {
        if (this.data.target_groups?.length === 0) {
            logger.warn('no data');
            return;
        }
        switch (this.plot.display.display_type) {
            case 'bar_plot':
                this.drawBarPlot();
                break;
            case 'box_plot':
                this.drawBoxPlot();
                break;
            default:
                logger.info('no plot display set...?');
                break;
        }

        const clipPathHeight = Math.max(this.height - this.margin.bottom - this.margin.top, 0);

        this.svg
            .append('defs')
            .append('clipPath')
            .attr('id', this.clipPathId)
            .append('rect')
            .attr('width', Math.max(this.width - this.margin.left - this.margin.right, 0))
            .attr('height', clipPathHeight)
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
    };

    drawBoxPlot = () => {
        const themeColor = this.themeColor;
        const themeStyle = this.themeStyle;
        const targetData = this.targetData;
        const customColors = this.displayOptions.custom_color_json ?? {};
        const svg = this.svg;
        const { colors: paletteColors } = getPlotPalette(themeColor);
        const { yMin } = this.yDomain;
        this.appendXAxis();
        this.appendYAxis();

        const yAxisWidth = this.svg.select<SVGGElement>('.y-axis')?.node()?.getBoundingClientRect().width ?? 0;
        const xAxisHeight = this.svg.select<SVGGElement>('.x-axis')?.node()?.getBoundingClientRect().height ?? 0;

        this.margin.left = yAxisWidth + 10;
        this.margin.bottom = xAxisHeight + 20;

        this.scales = this.makeScales({ margin: this.margin });

        this.appendYAxis();
        this.appendXAxis();

        const { yScale, targetXScale, groupXScale } = this.scales;

        const getBarWidth = () => this.getBarWidth();
        const getBarX = (groupName: string) => this.getBarX(groupName);

        // Draw Boxes
        svg.append('g')
            .attr('clip-path', `url(#${this.clipPathId})`)
            .selectAll('g')
            .data(targetData)
            .enter()
            .append('g')
            .attr('transform', (target) => `translate(${targetXScale(target.target_name)}, 0)`)
            .selectAll('rect')
            .data((targetSeries) => this.sortGroups(targetSeries.groups))
            .enter()
            .append('rect')
            .attr('class', (d, i) => {
                return !isDefined(customColors[`${d.group_id}`])
                    ? getSvgRectClasses({
                          color: paletteColors[i % paletteColors.length],
                          style: themeStyle,
                      })
                    : '';
            })
            .attr('fill', (d) => (themeStyle === ThemeStyle.outline ? 'white' : customColors[`${d.group_id}`]))
            .attr('stroke', (d) => customColors[`${d.group_id}`])
            .attr('stroke-width', themeStyle === ThemeStyle.outline ? 3 : 0)
            .attr('x', (groupSeries) => `${getBarX(groupSeries.group_name)}`)
            .attr('width', getBarWidth())
            .attr('y', (d) => yScale(yDomain(d).percentile75))
            .attr('height', (d) => {
                const { percentile25, percentile75 } = yDomain(d);
                const top = yScale(percentile75);
                const bottom = yScale(percentile25);
                const min = yScale(yMin);
                const height = bottom - top;
                const y2 = top + height;
                if (y2 > min) {
                    return Math.max(min - top, 0);
                }
                return Math.max(0, height);
            })
            .attr('rx', BAR_CORNER_RADIUS);

        // Draw statistical test bars
        this.drawStats();

        // Draw x-axis at y = 0;
        if (yMin < 0) {
            this.drawYZeroLine();
        }

        this.drawDataPoints();

        const topWhiskerWidthPercent = 0.5;
        // Now render all the horizontal lines at once - the whiskers and the median
        const lineConfigs: LineConfig<GroupSeries>[] = [
            // top vertical line
            {
                x1: (groupSeries) => {
                    const x = (groupXScale(groupSeries.group_name) ?? 0) + groupXScale.bandwidth() / 2;
                    return x;
                },
                x2: (groupSeries) => {
                    const x = (groupXScale(groupSeries.group_name) ?? 0) + groupXScale.bandwidth() / 2;
                    return x;
                },
                y1: (d) => Math.min(yScale(yMin), yScale(yDomain(d).percentile75)),
                y2: (d) => yScale(yDomain(d).whiskerUpper),
                hidden: (d) => {
                    const stats = yDomain(d);
                    return stats.percentile75 > stats.whiskerUpper;
                },
            },
            // Top whisker
            {
                x1: (d) =>
                    yScale(yMin) < yScale(yDomain(d).max)
                        ? 0
                        : getBarX(d.group_name) + getBarWidth() * topWhiskerWidthPercent * 0.5,
                x2: (d: GroupSeries) =>
                    yScale(yMin) < yScale(yDomain(d).max)
                        ? 0
                        : getBarX(d.group_name) + getBarWidth() - getBarWidth() * topWhiskerWidthPercent * 0.5,
                y1: (d) => Math.min(yScale(yMin) - 0.5, yScale(yDomain(d).whiskerUpper)),
                y2: (d) => Math.min(yScale(yMin) - 0.5, yScale(yDomain(d).whiskerUpper)),
                hidden: (d) => {
                    const stats = yDomain(d);
                    return stats.percentile75 > stats.whiskerUpper;
                },
            },

            // Median line
            {
                x1: (d) => (yScale(yMin) < yScale(yDomain(d).min) ? 0 : getBarX(d.group_name)),
                x2: (d) => (yScale(yMin) < yScale(yDomain(d).min) ? 0 : getBarX(d.group_name) + getBarWidth()),
                y1: (d) => Math.min(yScale(yMin), yScale(yDomain(d).percentile50)),
                y2: (d) => Math.min(yScale(yMin), yScale(yDomain(d).percentile50)),
            },

            // lower vertical line
            {
                x1: (groupSeries) => {
                    const x = (groupXScale(groupSeries.group_name) ?? 0) + groupXScale.bandwidth() / 2;
                    return x;
                },
                x2: (groupSeries) => {
                    const x = (groupXScale(groupSeries.group_name) ?? 0) + groupXScale.bandwidth() / 2;
                    return x;
                },
                y1: (d) => Math.min(yScale(yMin), yScale(yDomain(d).whiskerLower)),
                y2: (d) => yScale(yDomain(d).percentile25),
                hidden: (d) => {
                    const stats = yDomain(d);
                    return stats.percentile25 < stats.whiskerLower;
                },
            },

            // Bottom whisker
            {
                x1: (d) =>
                    yScale(yMin) < yScale(yDomain(d).min)
                        ? getBarX(d.group_name)
                        : getBarX(d.group_name) + getBarWidth() * topWhiskerWidthPercent * 0.5,
                x2: (d) =>
                    yScale(yMin) < yScale(yDomain(d).min)
                        ? getBarX(d.group_name)
                        : getBarX(d.group_name) + getBarWidth() - getBarWidth() * topWhiskerWidthPercent * 0.5,
                y1: (d) => Math.min(yScale(yMin) - 0.5, yScale(yDomain(d).whiskerLower)),
                y2: (d) => Math.min(yScale(yMin) - 0.5, yScale(yDomain(d).whiskerLower)),
                hidden: (d) => {
                    const stats = yDomain(d);
                    return stats.percentile25 < stats.whiskerLower;
                },
            },
        ];

        lineConfigs.forEach((lineConfig) => {
            // Draw horizontal lines after the box so that it's on top
            this.drawLine(lineConfig);
        });
    };

    drawBarPlot = () => {
        // const builder = this;
        const themeColor = this.themeColor;
        const themeStyle = this.themeStyle;
        const { yMin } = this.yDomain;
        const customColors = this.displayOptions.custom_color_json ?? {};
        const svg = this.svg;
        const { colors: barColors } = getPlotPalette(themeColor);

        this.appendXAxis();
        this.appendYAxis();

        const yAxisWidth = this.svg.select<SVGGElement>('.y-axis')?.node()?.getBoundingClientRect().width ?? 0;
        const xAxisHeight = this.svg.select<SVGGElement>('.x-axis')?.node()?.getBoundingClientRect().height ?? 0;

        this.margin.left = yAxisWidth + 10;
        this.margin.bottom = xAxisHeight + 20;

        this.scales = this.makeScales({ margin: this.margin });

        this.appendYAxis();
        this.appendXAxis();

        const { yScale, targetXScale, groupXScale } = this.scales;

        const getBarWidth = () => this.getBarWidth();
        const getBarX = (groupName: string) => this.getBarX(groupName);

        const targetData = this.targetData;
        // Draw Bars
        svg.append('g')
            .attr('clip-path', `url(#${this.clipPathId})`)
            .selectAll('g')
            .data(targetData)
            .enter()
            .append('g')
            .attr('transform', (target) => `translate(${targetXScale(target.target_name)}, 0)`)
            .selectAll('rect')
            .data((targetSeries) => this.sortGroups(targetSeries.groups))
            .enter()
            .append('rect')
            .attr('class', (d, i) => {
                return !isDefined(customColors[`${d.group_id}`])
                    ? getSvgRectClasses({
                          color: barColors[i % barColors.length],
                          style: themeStyle,
                      })
                    : '';
            })

            .attr('fill', (d) => (themeStyle === ThemeStyle.outline ? 'white' : customColors[`${d.group_id}`]))
            .attr('stroke', (d) => customColors[`${d.group_id}`])
            .attr('stroke-width', themeStyle === ThemeStyle.outline ? 3 : 0)
            .attr('x', (groupSeries) => `${getBarX(groupSeries.group_name)}`)
            .attr('width', getBarWidth())
            .attr('y', (d) => yScale(yMin) - Math.abs(yScale(yMin) - yScale(yAverage(d))))
            .attr('height', (d) => Math.max(0, yScale(yMin) - yScale(yAverage(d))))
            .attr('rx', BAR_CORNER_RADIUS);

        // Draw statistical test bars
        this.drawStats();

        // Draw x-axis at y = 0;
        if (yMin < 0) {
            this.drawYZeroLine();
        }

        this.drawDataPoints();

        // Draw error bars, if needed
        if (this.showErrorBars) {
            const errorBarOption = this.errorBarOption;
            const topWhiskerWidthPercent = 0.5;
            const errorBarLines: LineConfig<GroupSeries>[] = [];

            const getVerticalX = (d: GroupSeries) => (groupXScale(d.group_name) ?? 0) + groupXScale.bandwidth() / 2;
            // Draw the top error bars if needed
            if (this.errorBarLocations.includes('top')) {
                errorBarLines.push(
                    // horizontal whisker
                    {
                        x1: (d) => getBarX(d.group_name) + getBarWidth() * topWhiskerWidthPercent * 0.5,
                        x2: (d: GroupSeries) =>
                            getBarX(d.group_name) + getBarWidth() - getBarWidth() * topWhiskerWidthPercent * 0.5,
                        y1: (d) => yScale(yAverage(d) + getErrorBarDeviationY(d, errorBarOption)),
                        y2: (d) => yScale(yAverage(d) + getErrorBarDeviationY(d, errorBarOption)),
                    },
                    // vertical line
                    {
                        x1: getVerticalX,
                        x2: getVerticalX,
                        y1: (d) => Math.min(yScale(yMin), yScale(yAverage(d))),
                        y2: (d) =>
                            Math.min(yScale(yMin), yScale(yAverage(d) + getErrorBarDeviationY(d, errorBarOption))),
                    },
                );
            }

            // Draw the bottom error bars if needed
            if (this.errorBarLocations.includes('bottom')) {
                errorBarLines.push(
                    // horizontal whisker
                    {
                        x1: (groupSeries) =>
                            getBarX(groupSeries.group_name) + getBarWidth() * topWhiskerWidthPercent * 0.5,
                        x2: (groupSeries: GroupSeries) =>
                            getBarX(groupSeries.group_name) +
                            getBarWidth() -
                            getBarWidth() * topWhiskerWidthPercent * 0.5,
                        y1: (groupSeries) =>
                            Math.min(
                                yScale(yMin) - 0.5,
                                yScale(yAverage(groupSeries) - getErrorBarDeviationY(groupSeries, errorBarOption)),
                            ),
                        y2: (groupSeries) =>
                            Math.min(
                                yScale(yMin) - 0.5,
                                yScale(yAverage(groupSeries) - getErrorBarDeviationY(groupSeries, errorBarOption)),
                            ),
                    },
                    // vertical line
                    {
                        x1: getVerticalX,
                        x2: getVerticalX,
                        y1: (groupSeries) => Math.min(yScale(yMin), yScale(yAverage(groupSeries))),
                        y2: (groupSeries) =>
                            Math.min(
                                yScale(yMin),
                                yScale(yAverage(groupSeries) - getErrorBarDeviationY(groupSeries, errorBarOption)),
                            ),
                    },
                );
            }

            errorBarLines.forEach((lineConfig) => {
                // Draw horizontal lines after the box so that it's on top
                this.drawLine(lineConfig);
            });
        }
    };
}
