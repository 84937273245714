import React from 'react';
import NoSsr from '@components/NoSsr';
import cn from 'classnames';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import PreprocessPlotShellView from './PreprocessPlotShellView';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';
import PreprocessPlotContentView from './PreprocessPlotContentView';

export type LegendPosition = 'bottom' | 'right' | 'auto';

const PreprocessPlotCardView = () => {
    const { selectedPreprocess, plots, plotsLoading, plotsError } = useExperimentWorkflowContext();
    const validError = plotsError ? !plotsError.toString().includes('Preprocess matching query does not exist') : false;
    const dataReady =
        plots && plots.length && !plotsLoading && !plotsError && selectedPreprocess?.pipeline_status === 'completed';

    return (
        <NoSsr>
            <div className={cn('relative h-full w-full overflow-hidden bg-white')} data-cy="preprocess-plot-card">
                <div className="h-full">
                    <div className="flex h-full flex-grow flex-col-reverse">
                        <div
                            className={cn('flex h-full flex-col justify-center space-y-2', {
                                'justify-between': dataReady,
                            })}
                        >
                            {dataReady ? (
                                <div className="flex h-full max-h-full w-full flex-col overflow-hidden">
                                    <PlutoErrorBoundary>
                                        <PreprocessPlotContentView plots={plots} />
                                    </PlutoErrorBoundary>
                                </div>
                            ) : (
                                <PreprocessPlotShellView
                                    complete={Boolean(
                                        !plots?.length &&
                                            !plotsLoading &&
                                            selectedPreprocess?.pipeline_status === 'completed',
                                    )}
                                    pipelineLoading={selectedPreprocess?.pipeline_status === 'in_progress'}
                                    plotsLoading={plotsLoading}
                                    error={validError || selectedPreprocess?.pipeline_status === 'failed'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </NoSsr>
    );
};

export default PreprocessPlotCardView;
