import SparkMD5 from 'spark-md5';
import Logger from '@util/Logger';

const logger = Logger.make('FileProcessor', 'file_upload');

type UnpauseHandler = () => void;
export type FileChunk = ArrayBuffer;
export type RunnableFunction = (checksum: string, index: number, chunk: FileChunk) => Promise<boolean>;

class FileProcessor {
    paused: boolean;
    file: File;
    chunkSize: number;
    unpauseHandlers: UnpauseHandler[];
    canceled = false;
    currentIndex?: number;

    constructor(file: File, chunkSize: number) {
        this.paused = false;
        this.file = file;
        this.chunkSize = chunkSize;
        this.unpauseHandlers = [];
    }

    async run(fn: RunnableFunction, startIndex = 0, endIndex?: number): Promise<void> {
        const { file, chunkSize } = this;
        const totalChunks = Math.ceil(file.size / chunkSize);
        const spark = new SparkMD5.ArrayBuffer();

        logger.debug('Starting run on file:', {
            filename: file.name,
            totalChunks,
            startIndex,
            endIndex: endIndex || totalChunks,
        });
        if (this.canceled) {
            logger.warn('attempting to run a canceled file processor', { filename: file.name });
            return;
        }
        const processIndex = async (index: number) => {
            if (this.canceled) {
                logger.warn('attempting to run a canceled file processor');
                return;
            }
            if (index === totalChunks || index === endIndex) {
                logger.debug('File process complete', { filename: file.name });
                return;
            }
            if (this.paused) {
                logger.info('FileProcessor is paused, waiting for unpause', { filename: file.name });
                await waitForUnpause();
            }
            this.currentIndex = index;
            const start = index * chunkSize;
            const section = file.slice(start, start + chunkSize);
            const chunk = await getData(file, section);
            const checksum = getChecksum(spark, chunk);

            const shouldContinue = await fn(checksum, index, chunk);
            if (shouldContinue) {
                return processIndex(index + 1);
            }
            return;
        };

        const waitForUnpause = () => {
            return new Promise<void>((resolve) => {
                this.unpauseHandlers.push(resolve);
            });
        };

        await processIndex(startIndex);
    }

    pause() {
        this.paused = true;
    }

    unpause() {
        this.paused = false;
        this.unpauseHandlers.forEach((fn) => fn());
        this.unpauseHandlers = [];
    }

    cancel() {
        logger.warn('canceling file processor');
        this.canceled = true;
    }
}

function getChecksum(spark: SparkMD5.ArrayBuffer, chunk: FileChunk) {
    spark.append(chunk);
    return spark.end();
}

async function getData(file: File, blob: Blob) {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new window.FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
    });
}

export default FileProcessor;
