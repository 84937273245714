import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { CoverageAnalysis } from '@models/analysis/CoverageAnalysis';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import ProseList from '@components/ProseList';
import CodeBlockInline from '@components/CodeBlockInline';
import useExperimentPipelineRuns from '@hooks/useExperimentPipelineRuns';
import { isDefined } from '@util/TypeGuards';

type Props = { plot: Plot; experiment: Experiment };
const CoverageAnalysisMethodsSection = ({ plot, experiment }: Props) => {
    const analysis = plot.analysis as CoverageAnalysis;
    const hasGroups = (analysis.groups?.length ?? 0) > 0;
    const groupNames = analysis?.groups?.map((g) => g.display_name).filter(isDefined) ?? [];
    const $groups = hasGroups ? (
        <span>
            samples from the {`${analysis?.groups?.length === 1 ? 'group' : 'groups'}`}:{' '}
            <ProseList items={groupNames} />
        </span>
    ) : (
        <Bold>all samples</Bold>
    );
    const genome = experiment?.pipeline_run?.genome?.display_name;
    const runs = useExperimentPipelineRuns({ experiment });
    const activeRun = runs.data?.find((r) => experiment.pipeline_run?.uuid === r.uuid);
    const macs_gsize = (activeRun?.input_parameters?.macs_gsize as number)?.toLocaleString() ?? '';
    const library_type = activeRun?.input_parameters?.library_type ?? '';
    let readsText = '';
    if (
        experiment.type.short_display_name === 'CUT&RUN' ||
        (experiment.type.short_display_name === 'ChIP-seq' && library_type === 'paired_end')
    ) {
        readsText = 'Reads were extended to fragment size.';
    }
    if (experiment.type.short_display_name === 'ChIP-seq' && library_type === 'single_end') {
        readsText = 'Reads were extended to a fragment length of 200 base pairs.';
    }

    return (
        <section>
            <MethodsSectionTitle>Coverage Analysis</MethodsSectionTitle>
            <p>
                <span>
                    Coverage for {$groups} was processed using <CodeBlockInline>deepTools</CodeBlockInline> (v3.5.2) and
                    <Bold>{genome}</Bold> for annotations. BAM files were normalized to produce bigWig files using the{' '}
                    <CodeBlockInline>deepTools</CodeBlockInline> <CodeBlockInline>bamCoverage()</CodeBlockInline>{' '}
                    function; coverage was calculated as the number of reads per 10 base pair window and read coverage
                    was normalized to 1x depth using an effective genome size of <Bold>{macs_gsize}</Bold>. Chromosome X
                    was ignored for normalization, and blacklisted regions were excluded. {readsText} Coverage from each
                    bigWig file was calculated 2,000 base pairs upstream from the transcription start site (TSS) and
                    2,000 base pairs downstream from the TSS using the{' '}
                    <CodeBlockInline>reference-point</CodeBlockInline> mode of the{' '}
                    <CodeBlockInline>deepTools</CodeBlockInline> <CodeBlockInline>computeMatrix()</CodeBlockInline>{' '}
                    function. The mean coverage was calculated along a 10 base pair window within the defined regions.
                </span>
            </p>
        </section>
    );
};

export default CoverageAnalysisMethodsSection;
