import Plot from '@models/Plot';
import cn from 'classnames';
import { GradientBlock } from '@components/ColorPalette';
import DotPlotDisplayOption from '@models/plotDisplayOption/DotPlotDisplayOption';
import { CELL_PADDING_CLASS } from '@/src/components/plots/PlotLegendView';
import { PipelineStatusPlotData, PlotMapping, SeuratGeneSetSample } from '@/src/models/ExperimentData';
import { useMemo } from 'react';
import { findEvenlyDistributedValues, fractionStrToDecimal, roundUpToNearestPrecision } from '@/src/util/StringUtil';
import LoadingMessage from '@/src/components/LoadingMessage';

const analysesWithoutValueLegend = ['sample_correlation', 'seurat_marker_expression', 'seurat_module_score'];

const Dot = ({ size }: { size: string }) => {
    const px = size ?? 0;
    const style = { height: px, width: px };
    return (
        <div className="flex items-center justify-center">
            <span className={`block rounded-full bg-black`} style={style} />
        </div>
    );
};
const removeDuplicates = (array: string[]): string[] => {
    return [...new Set(array)];
};

type Props = {
    className?: string;
    colorTitle?: string;
    isExportMode?: boolean;
    plot: Plot | null;
    plotData: PipelineStatusPlotData;
    publicationMode?: boolean;
    showLegendBottom?: boolean;
    valueKey?: string;
};
const DotPlotLegend = ({
    className,
    colorTitle,
    isExportMode,
    plot,
    plotData,
    publicationMode,
    showLegendBottom,
    valueKey,
}: Props) => {
    const display = plot?.display as DotPlotDisplayOption;
    const items = plotData?.items as SeuratGeneSetSample[];
    const dataMap = plotData?.plot_mapping as PlotMapping;
    const hideValueLegend = analysesWithoutValueLegend.includes(plot?.analysis?.analysis_type as string);
    const noPlotDataAvailable = !items?.length || (!items?.length && plotData?.pipeline_status === 'completed');
    const standardSizes = useMemo(() => {
        if (!items?.length) {
            return [];
        }
        const multiplier = 100;
        const toFixed = 1;
        const precision = 0.001;
        const min = Math.min(...items.map((item) => fractionStrToDecimal(item[dataMap?.point_size] ?? '')));
        const max = Math.max(...items.map((item) => fractionStrToDecimal(item[dataMap?.point_size] ?? '')));

        const evenlyDistributedValues = findEvenlyDistributedValues({
            max,
            min,
            multiplier,
            precision,
            reverseOrder: true,
            toFixed,
        });

        const formattedMin = (roundUpToNearestPrecision(min, precision) * multiplier).toFixed(toFixed);
        const formattedMax = (roundUpToNearestPrecision(max, precision) * multiplier).toFixed(toFixed);
        return [formattedMax, ...evenlyDistributedValues, formattedMin];
    }, [items]);

    const sizeValueMap: Record<number, string> = useMemo(() => {
        if (!items?.length) {
            return {};
        }
        const medianPixelSize = 12;
        const areAllValuesSame = standardSizes.every((value) => value === standardSizes[0]);
        if (areAllValuesSame) {
            return {
                [standardSizes[0]]: `${medianPixelSize}px`,
            };
        }
        return {
            [standardSizes[0]]: '20px',
            [standardSizes[1]]: '16px',
            [standardSizes[2]]: `${medianPixelSize}px`,
            [standardSizes[3]]: '8px',
            [standardSizes[4]]: '4px',
        };
    }, [items, standardSizes]);

    const valuesArray = useMemo(() => {
        if (!items?.length) {
            return [];
        }
        let precision = 0.1,
            toFixed = 1,
            min = 0,
            max = 0,
            evenlyDistributedValues: number[] | string[] = [],
            formattedMin = '',
            formattedMax = '';

        switch (plot?.analysis?.analysis_type) {
            case 'seurat_marker_expression':
            case 'seurat_module_score':
                precision = 1;
                min = +items.reduce((prev, curr) =>
                    +prev[valueKey ?? dataMap.point_value] < +curr[valueKey ?? dataMap.point_value] ? prev : curr,
                )[valueKey ?? dataMap.point_value];
                max = +items.reduce((prev, curr) =>
                    +prev[valueKey ?? dataMap.point_value] > +curr[valueKey ?? dataMap.point_value] ? prev : curr,
                )[valueKey ?? dataMap.point_value];

                evenlyDistributedValues = findEvenlyDistributedValues({
                    max,
                    min,
                    precision,
                    reverseOrder: true,
                    toFixed: undefined,
                }) as string[];

                formattedMin = `${roundUpToNearestPrecision(min, 1)}`;
                formattedMax = `${roundUpToNearestPrecision(max, 1)}`;
                return removeDuplicates([formattedMax, ...evenlyDistributedValues, formattedMin]);

            case 'sample_correlation':
                precision = 0.1;
                toFixed = 1;
                min = -1;
                max = 1;

                evenlyDistributedValues = findEvenlyDistributedValues({
                    max,
                    min,
                    precision,
                    reverseOrder: true,
                    toFixed,
                }) as string[];

                formattedMin = roundUpToNearestPrecision(min, precision).toFixed(toFixed);
                formattedMax = roundUpToNearestPrecision(max, precision).toFixed(toFixed);
                return [formattedMax, ...evenlyDistributedValues, formattedMin];
            case 'seurat_over_representation':
            default:
                precision = 0.001;
                toFixed = 3;
                min = +items.reduce((prev, curr) =>
                    +prev[valueKey ?? dataMap.point_value] < +curr[valueKey ?? dataMap.point_value] ? prev : curr,
                )[valueKey ?? dataMap.point_value];
                max = +items.reduce((prev, curr) =>
                    +prev[valueKey ?? dataMap.point_value] > +curr[valueKey ?? dataMap.point_value] ? prev : curr,
                )[valueKey ?? dataMap.point_value];

                evenlyDistributedValues = findEvenlyDistributedValues({
                    max,
                    min,
                    precision,
                    reverseOrder: true,
                    toFixed,
                }) as number[];

                formattedMin = roundUpToNearestPrecision(min, precision).toFixed(toFixed);
                formattedMax = roundUpToNearestPrecision(max, precision).toFixed(toFixed);
                return [formattedMax, ...evenlyDistributedValues, formattedMin];
        }
    }, [items]);

    const getSizeTitle = () => {
        switch (dataMap.point_size) {
            case 'Gene_Ratio':
                return 'Gene ratio (%)';
            case 'P_Value':
                return 'P Value';
            default:
                'Point Size';
        }
    };
    const getColorTitle = () => {
        switch (valueKey ?? dataMap.point_value) {
            case 'Neg_log10_Adj_P_Value':
                return (
                    <>
                        -log<sub>10</sub> (FDR)
                    </>
                );
            case 'Coefficient':
                return 'Coefficient';
            default:
                return 'Color Value';
        }
    };

    const getGradientColors = () => {
        switch (plot?.analysis?.analysis_type) {
            case 'sample_correlation':
                return [display.min_color as string, '#f3f4f6', display.max_color as string];
            case 'seurat_over_representation':
            default:
                return [display.min_color as string, display.max_color as string];
        }
    };

    if (noPlotDataAvailable) return;

    if (!items.length && plotData.pipeline_status === 'in_progress') {
        return <LoadingMessage message="Loading legend..." immediate />;
    }

    return (
        <div
            className={cn('flex flex-shrink-0 justify-center py-2', className, {
                'font-sans text-[14px] text-black': publicationMode,
                'flex-row space-x-4': showLegendBottom,
                'flex-col space-y-4': !showLegendBottom,
                'h-full': !isExportMode,
            })}
        >
            {hideValueLegend ? null : (
                <div className="text-md flex flex-col items-center justify-center">
                    <div
                        className={cn(CELL_PADDING_CLASS, 'mb-2 break-words text-center font-semibold')}
                        style={{ wordBreak: 'break-word' }}
                    >
                        {getSizeTitle()}
                    </div>
                    <div className="flex flex-col items-center justify-center space-y-2">
                        {standardSizes.map((size, index) => (
                            <div className="flex w-full flex-row space-x-2" key={`${size}_${index}`}>
                                <div className="flex w-[25px] items-center justify-center">
                                    <Dot size={sizeValueMap[size]} key={size} />
                                </div>
                                <div className="flex flex-1 items-center justify-center">
                                    <p
                                        className={cn('text-center text-xs', {
                                            'text-[14px] text-black': publicationMode,
                                        })}
                                        key={size}
                                    >
                                        {size}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="text-md flex flex-col items-center">
                <div
                    className={cn(CELL_PADDING_CLASS, 'mb-2 break-words text-center font-semibold whitespace-pre-line')}
                    style={{ wordBreak: 'break-word' }}
                >
                    {colorTitle ?? getColorTitle()}
                </div>
                <div className="flex flex-row items-center justify-center space-x-2">
                    <div className="h-28 flex-col items-center space-y-2">
                        <GradientBlock colors={getGradientColors()} />
                    </div>
                    <div className="flex h-28 flex-col items-center justify-between space-y-1">
                        {valuesArray.map((num, index) => (
                            <p
                                key={`${num}_${index}`}
                                className={cn('text-center text-xs', {
                                    'text-[14px] text-black': publicationMode,
                                })}
                            >
                                {num}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DotPlotLegend;
