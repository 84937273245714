import React, { ReactNode, useRef, useState } from 'react';
import cn from 'classnames';
import { isBlank } from '@util/StringUtil';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@components/Button';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

type Props = {
    description?: string | null;
    characterLimit?: number;
    className?: string;
    modal?: boolean;
    modalTitle?: ReactNode;
};
const TruncatedDescription = ({ description, characterLimit = 140, className, modal = false, modalTitle }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);
    const { copyText, highlightText, showCopySuccess } = useCopyToClipboard({ ref: textRef });
    const toggleDescription = () => {
        setExpanded(!expanded);
    };

    if (!description || isBlank(description)) {
        return null;
    }

    const isOverLimit = description.length > characterLimit;

    return (
        <>
            <p className={cn(className)}>
                {(!expanded || modal) && isOverLimit ? (
                    <>
                        <span className="mr-2 whitespace-pre-line">{`${description.substring(
                            0,
                            characterLimit,
                        )}...`}</span>
                        <span className="cursor-pointer font-semibold text-primary" onClick={toggleDescription}>
                            Show&nbsp;more
                        </span>
                    </>
                ) : (
                    <>
                        <span className="mr-2 whitespace-pre-line">{description}</span>
                        {isOverLimit && !modal && (
                            <span className="cursor-pointer font-semibold text-primary" onClick={toggleDescription}>
                                Show&nbsp;less
                            </span>
                        )}
                    </>
                )}
            </p>

            <Dialog open={expanded} onClose={() => setExpanded(false)}>
                <DialogCloseButton onClose={() => setExpanded(false)} />
                <PlutoDialogTitle title={modalTitle} />

                <DialogContent className="relative">
                    <div className="whitespace-pre-line" ref={textRef}>
                        {description}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        startIcon={<CopyIcon width={18} />}
                        onClick={() => {
                            highlightText();
                            copyText();
                        }}
                        variant="text"
                        color="primary"
                    >
                        {showCopySuccess ? 'Copied!' : 'Copy\xa0text'}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setExpanded(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TruncatedDescription;
