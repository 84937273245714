import Experiment from '@models/Experiment';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import ImportSession from '@models/baseSpace/ImportSession';
import useAuth from '@hooks/useAuth';

type Props = { experiment: Experiment | null | undefined };
const useImportSessions = ({ experiment }: Props) => {
    const { user } = useAuth();
    const { data, error, mutate, isValidating } = useSWR<ImportSession[]>(() =>
        experiment?.uuid && user?.organization?.basespace_access_token
            ? Endpoints.lab.experiment.basespace.importSessions({ experimentId: experiment.uuid })
            : null,
    );

    const loading = !data && !error;

    return { loading, importSessions: data, error, mutate, isValidating };
};

export default useImportSessions;
