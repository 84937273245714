import { getExperimentIcon } from '@components/experiments/ExperimentIcons';
import { formatTableHeader } from '@util/StringUtil';
import { SortAscendingIcon } from '@heroicons/react/outline';
import React from 'react';

type TableHeaderIconProps = { text: string; sortable?: boolean };
export const TableHeaderIcon = ({ text, sortable }: TableHeaderIconProps) => {
    const icon = getExperimentIcon(text);
    const formattedTitle = formatTableHeader(text);

    // TODO: This is a quick fix to show the sort icon on Adjusted P Values.
    //  We'll need a more robust long term solution if we need to show custom icons like this.
    const renderColumnIcon = () => {
        if (text === 'Adj_P_Value' && !sortable) {
            return (
                <span className="relative top-[3px] ml-1">
                    <SortAscendingIcon className="h-5 w-5" />
                </span>
            );
        }
        return null;
    };

    return (
        <div className="flex flex-row items-center">
            {icon && <span className="pr-1">{icon}</span>}
            <span className="whitespace-nowrap capitalize">{formattedTitle}</span>
            {renderColumnIcon()}
        </div>
    );
};

export default TableHeaderIcon;
