import { createContext, ReactNode, RefObject, useContext, useEffect, useRef, useState } from 'react';
import Experiment from '@models/Experiment';
import { StateSetter } from '@contexts/ContextTypes';
import useAnnotations from '../hooks/useAnnotations';
import AnnotationSet, { Annotation, AnnotationSetDetails } from '../models/Annotation';
import {
    AnnotationFormValues,
    CreateAnnotationFormValues,
    SaveAnnotationFormValues,
} from '../components/experiments/annotations/AnnotationFormTypes';
import { useExperimentWorkflowContext } from './ExperimentWorkflowContext';
import { ResultData } from '../models/PreprocessStep';
import { FormikProps } from 'formik';

export type ContextType = {
    annotations: Annotation[] | null;
    annotationSets: AnnotationSet[] | null;
    annotationsByResolution:
        | {
              resolution: number;
              annotationSets: AnnotationSet[] | undefined;
          }[]
        | null;
    annotationsError: string | null;
    annotationSetDetails: AnnotationSetDetails | null;
    annotationSetDetailsError: string | null;
    annotationSetDetailsLoading: boolean;
    annotationSetsError: string | null;
    annotationSetsLoading: boolean;
    annotationsLoading: boolean;
    archiveAnnotation: () => Promise<AnnotationSet | undefined>;
    archiveError: string | null;
    archiveLoading: boolean;
    copyAnnotation: (formData: SaveAnnotationFormValues) => Promise<AnnotationSet | undefined>;
    copyAnnotationError: string | null;
    copyAnnotationLoading: boolean;
    createAnnotation: ({ resolution, display_name }: CreateAnnotationFormValues) => Promise<AnnotationSet | undefined>;
    createAnnotationError: string | null;
    createAnnotationLoading: boolean;
    getAnnotationSets: (experimentId: string) => Promise<AnnotationSet[] | undefined>;
    getPlotData: (plotId: string) => void;
    initialResolution: number | null;
    plotData: ResultData | null;
    plotDataError: { code?: string | undefined } | null | undefined;
    plotDataLoading: boolean;
    renameAnnotation: (formData: SaveAnnotationFormValues) => Promise<AnnotationSet | undefined>;
    renameAnnotationError: string | null;
    renameAnnotationLoading: boolean;
    resolutions: number[] | null;
    selectedAnnotation: Annotation | null;
    selectedAnnotationSet: AnnotationSet | null;
    setAnnotationSets: StateSetter<AnnotationSet[] | null>;
    setInitialResolution: StateSetter<number | null>;
    setSelectedAnnotation: StateSetter<Annotation | null>;
    setSelectedAnnotationSet: StateSetter<AnnotationSet | null>;
    updateAnnotationSet: (formData: AnnotationFormValues) => Promise<AnnotationSet | undefined>;
    updateAnnotationSetError: string | null;
    updateAnnotationSetLoading: boolean;
    editAnnotationFormRef: RefObject<FormikProps<AnnotationFormValues>>;
};
const ExperimentAnnotationContext = createContext<ContextType | null>(null);

export const useExperimentAnnotationContext = () => {
    const context = useContext(ExperimentAnnotationContext);
    if (!context) {
        throw new Error(
            'ExperimentAnnotationContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

ExperimentAnnotationContext.displayName = 'ExperimentAnnotationContext';

export const ExperimentAnnotationContextProvider = ({
    children,
    experiment,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
}) => {
    const [selectedAnnotationSet, setSelectedAnnotationSet] = useState<AnnotationSet | null>(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation | null>(null);
    const [resolutions, setResolutions] = useState<number[] | null>(null);
    const {
        annotations,
        annotationsError,
        annotationSetDetails,
        annotationSetDetailsError,
        annotationSetDetailsLoading,
        annotationSets,
        annotationSetsError,
        annotationSetsLoading,
        annotationsLoading,
        archiveAnnotation,
        archiveError,
        archiveLoading,
        createAnnotation,
        createAnnotationError,
        createAnnotationLoading,
        getAnnotations,
        getAnnotationSetDetails,
        getAnnotationSets,
        getPlotData,
        plotData,
        plotDataError,
        plotDataLoading,
        renameAnnotation,
        renameAnnotationError,
        renameAnnotationLoading,
        copyAnnotation,
        copyAnnotationError,
        copyAnnotationLoading,
        setAnnotationSets,
        setPlotData,
        updateAnnotationSet,
        updateAnnotationSetError,
        updateAnnotationSetLoading,
    } = useAnnotations(experiment, selectedAnnotationSet, setSelectedAnnotationSet);
    const { preprocessParameters, getWorkflowPreprocessParameters } = useExperimentWorkflowContext();
    const [annotationsByResolution, setAnnotationsByResolution] = useState<
        | {
              resolution: number;
              annotationSets: AnnotationSet[] | undefined;
          }[]
        | null
    >(null);
    const [initialResolution, setInitialResolution] = useState<number | null>(null);
    const editAnnotationFormRef = useRef<FormikProps<AnnotationFormValues> | null>(null);

    const _setAnnotationsByResolution = (resolutionsProp) => {
        const annotationsByResolution:
            | {
                  resolution: number;
                  annotationSets: AnnotationSet[] | undefined;
              }[]
            | null = [];
        resolutionsProp?.map((res) => {
            annotationsByResolution?.push({
                resolution: res,
                annotationSets: annotationSets?.filter((ann) => ann.resolution === res),
            });
        });
        setAnnotationsByResolution(annotationsByResolution);
    };

    useEffect(() => {
        if (experiment?.accepted_workflow?.uuid && experiment?.uuid) {
            const _getAnnotationSets = async () => {
                return await getAnnotationSets(experiment?.uuid);
            };
            _getAnnotationSets().then((annotationSets) => {
                const selectedParams = preprocessParameters?.find(
                    (param) => param.workflowId === experiment?.accepted_workflow?.uuid,
                );
                if (selectedParams && annotationSets) {
                    const res = selectedParams?.preprocessParameters
                        ? Object.keys(selectedParams?.preprocessParameters?.resolution_clusters as object)
                              .map((r) => +r)
                              .sort((a, b) => a - b)
                        : null;
                    setResolutions(res);
                    _setAnnotationsByResolution(res);
                } else if (experiment.accepted_workflow?.uuid) {
                    setAnnotationsByResolution(null);
                    setResolutions(null);
                    getWorkflowPreprocessParameters(experiment.accepted_workflow?.uuid, true);
                }
            });
        } else {
            setAnnotationsByResolution(null);
            setResolutions(null);
            setAnnotationSets(null);
        }
    }, [experiment?.accepted_workflow?.uuid, preprocessParameters]);

    useEffect(() => {
        if (annotationSets && resolutions) {
            _setAnnotationsByResolution(resolutions);
        }
    }, [annotationSets]);
    useEffect(() => {
        if (selectedAnnotationSet) {
            getAnnotations();
            getAnnotationSetDetails();
        }
    }, [selectedAnnotationSet]);
    useEffect(() => {
        if (selectedAnnotation) {
            getPlotData(selectedAnnotation.uuid);
        } else {
            setPlotData(null);
        }
        getAnnotationSetDetails();
    }, [selectedAnnotation]);

    return (
        <ExperimentAnnotationContext.Provider
            value={{
                annotations,
                annotationsByResolution,
                annotationsError,
                annotationSetDetails,
                annotationSetDetailsError,
                annotationSetDetailsLoading,
                annotationSets,
                annotationSetsError,
                annotationSetsLoading,
                annotationsLoading,
                archiveAnnotation,
                archiveError,
                archiveLoading,
                copyAnnotation,
                copyAnnotationError,
                copyAnnotationLoading,
                createAnnotation,
                createAnnotationError,
                createAnnotationLoading,
                editAnnotationFormRef,
                getAnnotationSets,
                getPlotData,
                initialResolution,
                plotData,
                plotDataError,
                plotDataLoading,
                renameAnnotation,
                renameAnnotationError,
                renameAnnotationLoading,
                resolutions,
                selectedAnnotation,
                selectedAnnotationSet,
                setAnnotationSets,
                setInitialResolution,
                setSelectedAnnotation,
                setSelectedAnnotationSet,
                updateAnnotationSet,
                updateAnnotationSetError,
                updateAnnotationSetLoading,
            }}
        >
            <>{children}</>
        </ExperimentAnnotationContext.Provider>
    );
};
