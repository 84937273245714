import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import Plot from '@models/Plot';
import React, { ReactNode } from 'react';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import PrincipalComponentsAnalysisMethodsSection from '@components/experiments/methods/analyses/PrincipalComponentsAnalysisMethodsSection';
import { proseListJoiner } from '@util/ObjectUtil';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { formatTableHeader } from '@util/StringUtil';
import { PrincipalComponentsAnalysis } from '@models/analysis/PrincipalComponentsAnalysis';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import UMAPAnalysisMethodsSection from '@components/experiments/methods/analyses/UMAPAnalysisMethodsSection';
import TSNEMethodsSection from '@components/experiments/methods/analyses/TSNEMethodsSection';

type Props = { experiment: Experiment; plot: Plot; analysisParameters: AnalysisParameters | null };
const SampleScatterPlotMethods = ({ plot, experiment, analysisParameters }: Props) => {
    const analysis = plot.analysis as PrincipalComponentsAnalysis | null;
    const display = plot.display as SampleScatterPlotDisplayOption | null;

    const colorPointsByVariables = display?.color_points_by ?? [];

    const { getVariableById } = getAnalysisParametersHelpers(analysisParameters);

    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    let $analysisSection: ReactNode = null;
    switch (analysis?.analysis_type) {
        case 'umap':
            $analysisSection = <UMAPAnalysisMethodsSection plot={plot} experiment={experiment} />;
            break;
        case 'principal_components':
            $analysisSection = <PrincipalComponentsAnalysisMethodsSection plot={plot} experiment={experiment} />;
            break;
        case 'tsne':
            $analysisSection = <TSNEMethodsSection plot={plot} experiment={experiment} />;
            break;
        default:
            break;
    }

    return (
        <div className="space-y-6">
            {$analysisSection}
            <section>
                <MethodsSectionTitle>Sample scatter plot</MethodsSectionTitle>
                <p className="mb-6">
                    Scatter plot shows <span className="font-semibold">{display?.x_axis_column}</span> on the{' '}
                    <span className="italic">x</span>-axis and{' '}
                    <span className="font-semibold">{display?.y_axis_column}</span> on the{' '}
                    <span className="italic">y</span>-axis. Points are colored by{' '}
                    <span className="font-semibold">
                        {proseListJoiner(
                            colorPointsByVariables.flatMap((variable_id) => {
                                const variable = getVariableById(variable_id);
                                if (!variable) {
                                    return null;
                                }
                                return formatTableHeader(variable.display_name);
                            }),
                        )}
                        {'.'}
                    </span>
                </p>
            </section>
        </div>
    );
};

export default SampleScatterPlotMethods;
