import { useFormikContext } from 'formik';
import React from 'react';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import { DifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { DifferentialExpressionAnalysisFormValues } from '@models/analysis/DifferentialExpressionAnalysis';
import DifferentialGroupsPickerFields from '@components/experiments/analyses/fields/DifferentialGroupsPickerFields';
import DifferentialVariablesField from '@components/experiments/analyses/fields/DifferentialVariablesField';
import CovariatesPickerFields from '@components/experiments/analyses/fields/CovariatesPickerFields';

type Props = { plot: Plot; analysisParameters: DifferentialExpressionAnalysisParameters; experiment: Experiment };
const DifferentialBindingAnalysisFormFields = ({ plot, analysisParameters, experiment }: Props) => {
    const { values } = useFormikContext<DifferentialExpressionAnalysisFormValues>();

    return (
        <>
            <DifferentialVariablesField plot={plot} analysisParameters={analysisParameters} experiment={experiment} />
            {values.variables.length > 0 && <DifferentialGroupsPickerFields experiment={experiment} plot={plot} />}
            {values.variables.length > 0 && (
                <CovariatesPickerFields plot={plot} analysisParameters={analysisParameters} experiment={experiment} />
            )}
        </>
    );
};

export default DifferentialBindingAnalysisFormFields;
