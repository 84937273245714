import React from 'react';

const SummaryStatisticsMethodsSection = () => {
    return (
        <section>
            <p className="mb-1 font-semibold text-gray-900">Statistics</p>
            <div className="space-y-4">
                <p>
                    {'* '}
                    <span className="italic">p</span>
                    {' ≤ 0.05; ** '}
                    <span className="italic">p</span>
                    {' ≤ 0.01; *** '}
                    <span className="italic">p</span>
                    {' ≤ 0.001; n.s. '}
                    <span className="italic">p</span>
                    {' > 0.05'}
                </p>
            </div>
        </section>
    );
};

export default SummaryStatisticsMethodsSection;
