import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const SampleScatterPlotIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.76892 31.4756C6.73542 31.4756 7.51892 30.6921 7.51892 29.7256C7.51892 28.7591 6.73542 27.9756 5.76892 27.9756C4.80242 27.9756 4.01892 28.7591 4.01892 29.7256C4.01892 30.6921 4.80242 31.4756 5.76892 31.4756Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.448 13.033C27.5687 12.632 26.5165 13.013 26.098 13.8842C25.6794 14.7554 26.053 15.7868 26.9323 16.1878C27.8117 16.5889 28.8638 16.2078 29.2824 15.3367C29.7009 14.4655 29.3274 13.4341 28.448 13.033Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9042 17.8184C17.0088 17.8184 17.9042 16.9229 17.9042 15.8184C17.9042 14.7138 17.0088 13.8184 15.9042 13.8184C14.7997 13.8184 13.9042 14.7138 13.9042 15.8184C13.9042 16.9229 14.7997 17.8184 15.9042 17.8184Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.4375 4.21664C30.5582 3.81556 29.506 4.19664 29.0875 5.06781C28.6689 5.93899 29.0425 6.97035 29.9218 7.37143C30.8012 7.77252 31.8533 7.39143 32.2719 6.52026C32.6904 5.64909 32.3169 4.61772 31.4375 4.21664Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2979 13.667C11.4025 13.667 12.2979 12.7716 12.2979 11.667C12.2979 10.5624 11.4025 9.66699 10.2979 9.66699C9.19334 9.66699 8.29791 10.5624 8.29791 11.667C8.29791 12.7716 9.19334 13.667 10.2979 13.667Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.05261 11.4297C6.15718 11.4297 7.05261 10.5343 7.05261 9.42969C7.05261 8.32512 6.15718 7.42969 5.05261 7.42969C3.94804 7.42969 3.05261 8.32512 3.05261 9.42969C3.05261 10.5343 3.94804 11.4297 5.05261 11.4297Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.3396 30.7069C30.4603 30.3058 29.4081 30.6869 28.9896 31.558C28.571 32.4292 28.9446 33.4606 29.8239 33.8617C30.7033 34.2628 31.7554 33.8817 32.174 33.0105C32.5925 32.1393 32.219 31.108 31.3396 30.7069Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.567 26.2108C14.6876 25.8097 13.6355 26.1908 13.2169 27.062C12.7984 27.9331 13.1719 28.9645 14.0513 29.3656C14.9306 29.7667 15.9828 29.3856 16.4013 28.5144C16.8199 27.6432 16.4463 26.6119 15.567 26.2108Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0466 4.18271C12.0416 3.72434 10.8391 4.15986 10.3608 5.15549C9.88248 6.15111 10.3094 7.32982 11.3144 7.7882C12.3193 8.24657 13.5218 7.81105 14.0001 6.81542C14.4785 5.8198 14.0515 4.64109 13.0466 4.18271Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="21"
                y1="3"
                x2="21"
                y2="37"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="37"
                y1="21"
                x2="3"
                y2="21"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
};
