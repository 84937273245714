import { PermissionName } from '@models/Permission';
import { useMemo } from 'react';
import { hasPermission } from '@util/PermissionUtil';
import useAuth from '@hooks/useAuth';
import useTeams from '@hooks/useTeams';
import useProjects from '@hooks/useProjects';

const useLabPermissions = () => {
    const { user, authLoading } = useAuth();
    /* TODO: for now, we fetch all experiments to find out if any have permission to create an experiment
     *   in the future, we will likely call a dedicated API endpoint */
    // const { data: projectPage, error: projectsError } = useSWR<PaginationResponse<Project>>(() =>
    //     user ? Endpoints.lab.projects() : null,
    // );
    const { projectPage, projectsLoading } = useProjects();
    const { teams = [], loading: teamsLoading } = useTeams();
    const organization = user?.organization;

    const loading = teamsLoading || projectsLoading || authLoading;

    const projects = projectPage?.items ?? [];

    const projectUserPermissions: PermissionName[] = useMemo(() => {
        const allPermissions = projects.flatMap((project) => project.user_permissions ?? []);
        return Array.from(new Set<PermissionName>(allPermissions));
    }, [projects]);

    // const teams = teamPage?.items ?? [];

    const permissions = useMemo(() => {
        const allTeamPermissions = new Set<PermissionName>(teams.flatMap((t) => t.user_permissions ?? []));
        const canCreateTeamProjects = allTeamPermissions.has(PermissionName.create_team_projects);

        const canCreateExperiment = hasPermission(
            { user_permissions: projectUserPermissions },
            {
                requires: [
                    PermissionName.create_project_experiments,
                    PermissionName.create_organization_shareable_experiments,
                ],
                any: true,
            },
        );

        const canCreateProject =
            canCreateTeamProjects ||
            hasPermission(organization, {
                requires: [
                    PermissionName.create_organization_shareable_projects,
                    PermissionName.create_organization_shareable_projects,
                    PermissionName.create_team_projects,
                    PermissionName.create_organization_public_projects,
                ],
                any: true,
            });

        const canCreateShareableProjects = hasPermission(organization, {
            requires: [PermissionName.create_organization_shareable_projects],
            any: true,
        });

        const canManageLabMembers = hasPermission(organization, {
            requires: [
                PermissionName.remove_organization_users,
                PermissionName.invite_organization_users,
                PermissionName.edit_organization_users,
            ],
            any: true,
        });

        const canCreateTeams = hasPermission(organization, { requires: [PermissionName.create_organization_teams] });
        const canCreateFundingSource = hasPermission(organization, {
            requires: [PermissionName.create_organization_funding_sources],
        });
        const canCreateOrganizationColors = hasPermission(organization, {
            requires: [PermissionName.create_organization_funding_sources],
        });
        const canArchiveFundingSource = hasPermission(organization, {
            requires: [PermissionName.archive_organization_funding_sources],
        });
        const canViewOrganizationAnalytics = hasPermission(organization, {
            requires: [PermissionName.view_organization_analytics],
        });

        return {
            canCreateProject,
            canCreateShareableProjects,
            canCreateExperiment,
            canManageLabMembers,
            canCreateTeamProjects,
            canCreateTeams,
            canCreateFundingSource,
            canCreateOrganizationColors,
            canEditFundingSource: canCreateFundingSource,
            canArchiveFundingSource,
            canViewOrganizationAnalytics,
            canRequestReport: canViewOrganizationAnalytics,
        };
    }, [organization, projectUserPermissions, loading, teams]);

    return { loading, projectPermissions: projectUserPermissions, ...permissions };
};

export default useLabPermissions;
