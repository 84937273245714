import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import YAxisTitleField from '@components/experiments/plotDisplay/fields/YAxisTitleField';
import React, { useMemo } from 'react';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import XAxisTitleField from '@components/experiments/plotDisplay/fields/XAxisTitleField';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import { useField } from 'formik';
import SortableLegendField from '@components/experiments/plotDisplay/groups/SortableLegendField';

type Props = { plot: Plot; experiment: Experiment; analysisParameters?: AnalysisParameters | null };
const KaplanMeierCurvePlotDisplayFields = ({ experiment, plot }: Props) => {
    const analysis = plot.analysis as SurvivalAnalysis | null;
    const variables = analysis?.variables ?? [];
    const selectedGroupIds = analysis?.groups?.map((g) => g.id) ?? [];
    const [{ value: group_display_order }] = useField<number[]>('group_display_order');
    const { groups: allGroups, loading: groupsLoading } = useExperimentPlotGroups({
        experiment,
        plot,
        variables,
    });

    const orderedGroups = useMemo(() => {
        const selectedGroups = allGroups?.filter((g) => selectedGroupIds.includes(g.id)) ?? [];

        selectedGroups.sort((g1, g2) => {
            return group_display_order.indexOf(g1.id) - group_display_order.indexOf(g2.id);
        });
        return selectedGroups;
    }, [allGroups, group_display_order]);

    return (
        <div>
            <FullWidthToggleField />
            <ChartTitleField placeholder={analysis?.name} />
            <XAxisTitleField placeholder={`OS (${analysis?.time_to_event_units})`} />
            <YAxisTitleField placeholder={'Survival probability'} />
            <ThemeFieldGroup hideStyle />
            <SortableLegendField
                items={orderedGroups}
                name="group_display_order"
                loading={groupsLoading}
                description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
                isSortable={true}
            />
        </div>
    );
};

export default KaplanMeierCurvePlotDisplayFields;
