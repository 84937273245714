import { useFormikContext } from 'formik';
import { SurvivalAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import VariablePickerField, { DisabledItem } from '@components/experiments/analyses/fields/VariablePickerField';
import { ParameterOption } from '@models/AnalysisParameters';
import React, { useMemo } from 'react';

type Props = { variables: ParameterOption[] };
const SurvivalAnalysisVariablesPickerFieldGroup = ({ variables }: Props) => {
    const formik = useFormikContext<SurvivalAnalysisFormValues>();
    const { time_to_event_variable_id, status_variable_id } = formik.values;

    const { disabledStatusItems, disabledEventItems } = useMemo(() => {
        const disabledStatusItems: DisabledItem[] = [];
        const disabledEventItems: DisabledItem[] = [];

        const timeToEventVariable = variables.find((v) => v.id === time_to_event_variable_id);
        const statusVariable = variables.find((v) => v.id === status_variable_id);

        if (timeToEventVariable) {
            disabledStatusItems.push({ value: timeToEventVariable.id, label: 'Time to event' });
        }
        if (statusVariable) {
            disabledEventItems.push({ value: statusVariable.id, label: 'Status' });
        }

        return { disabledEventItems, disabledStatusItems };
    }, [time_to_event_variable_id, status_variable_id, variables]);

    return (
        <div className="form-field rounded-lg bg-indigo-100 p-4">
            <VariablePickerField
                variables={variables}
                label="Status variable"
                variablesName="status_variable_id"
                disabledItems={disabledStatusItems}
            />

            <span className="field-label no-margin mb-8 block">and</span>

            <VariablePickerField
                variables={variables}
                label="Time to event variable"
                variablesName="time_to_event_variable_id"
                disabledItems={disabledEventItems}
            />
        </div>
    );
};

export default SurvivalAnalysisVariablesPickerFieldGroup;
