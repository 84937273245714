import Experiment from '@models/Experiment';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import useSWR from 'swr';
import {
    StatisticalTestShortname,
    SummaryStatisticalTestResult,
    UpdateStatisticalTestResultParams,
} from '@models/SummaryStatisticalTestResult';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import Logger from '@util/Logger';
import { useMemo } from 'react';

const logger = Logger.make('hooks/useStatisticalTest');

export type StatisticalTestFormValues = {
    target: number | string | null;
    groups: number[];
    test_type: StatisticalTestShortname | null;
    variables: number[];
};

type Props = { experiment: Experiment; analysis: ExperimentAnalysis | null };
const useStatisticalTest = ({ experiment, analysis }: Props) => {
    const experimentId = experiment.uuid;
    const analysisId = analysis?.uuid;
    const api = useApi();
    const {
        mutate: mutateResults,
        data: results,
        error: resultsError,
        isValidating: resultsValidating,
    } = useSWR<SummaryStatisticalTestResult[]>(() =>
        analysisId
            ? Endpoints.lab.experiment.analysis.stats({
                  experimentId,
                  analysisId,
              })
            : null,
    );
    const resultsLoading = !results && !resultsError;

    const visiblePlotStats = useMemo(() => {
        return results?.filter((r) => r.show_on_plot);
    }, [results]);

    const deleteStatResult = async (statResult: SummaryStatisticalTestResult) => {
        if (!analysisId) {
            return;
        }
        try {
            await mutateResults((results) => results?.filter((r) => r.uuid !== statResult.uuid), false);

            await api.doDelete(
                Endpoints.lab.experiment.analysis.stat({
                    experimentId,
                    analysisId,
                    statId: statResult.uuid,
                }),
            );
        } catch (error) {
            logger.error(error);
        }
    };

    const createStatisticalTest = async (values: StatisticalTestFormValues) => {
        if (!analysisId) {
            throw new Error('Unable to create statistical test. No analysis was provided.');
        }
        const url = Endpoints.lab.experiment.analysis.stats({
            experimentId,
            analysisId,
        });

        const result = await api.post<SummaryStatisticalTestResult | null>(url, values);

        if (result) {
            await mutateResults((current) => (current ? [result, ...current] : [result]), false);
        }
        return result;
    };

    const updateStatisticalTest = async (
        result: SummaryStatisticalTestResult,
        values: UpdateStatisticalTestResultParams,
    ) => {
        if (!analysisId) {
            throw new Error('Unable to create statistical test. No analysis was provided.');
        }

        const url = Endpoints.lab.experiment.analysis.stat({
            experimentId,
            analysisId,
            statId: result.uuid,
        });

        const newStatResult = await api.put<SummaryStatisticalTestResult>(url, values);

        await mutateResults((current) => {
            if (!current) {
                return current;
            }
            const existing = current?.find((r) => r.uuid === newStatResult.uuid);
            if (existing) {
                existing.show_on_plot = values.show_on_plot ?? false;
            }

            return [...current];
        });
    };

    return {
        mutateResults,
        results,
        resultsLoading,
        resultsValidating,
        resultsError,
        deleteStatResult,
        createStatisticalTest,
        updateStatisticalTest,
        visiblePlotStats,
    };
};

export default useStatisticalTest;
