import { BaseType, Selection } from 'd3';
import Plot from '@models/Plot';
import { PlotTargetGroups } from '@components/plots/PlotTypes';
import Experiment from '@models/Experiment';
import { createPlotTooltip } from '@components/plots/PlotUtil';
import { ThemeablePlotChartDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';
import { ThemeStyle } from '@models/PlotConfigs';
import { CustomPlotStylingOptions } from '../../analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

export type PlotMargin = { top: number; left: number; right: number; bottom: number };
export interface ZoomTransformObject extends d3.ZoomTransform {
    originalWidth: number;
    originalHeight: number;
}
export type ConstructorParams<DATA = PlotTargetGroups, PLOT extends Plot = Plot> = {
    _svg?: Selection<BaseType, unknown, BaseType, unknown>;
    data: DATA;
    experiment: Experiment;
    height: number;
    idPrefix?: string;
    isExportMode?: boolean;
    onZoomTransform?: (transformObject: ZoomTransformObject) => void;
    plot: PLOT;
    publicationMode?: boolean;
    svg: Selection<BaseType, unknown, BaseType, unknown>;
    tooltipId: string;
    width: number;
    zoomEnabled?: boolean;
    stylingOptions?: CustomPlotStylingOptions | null;
};

export default abstract class BasePlotBuilder<DATA = PlotTargetGroups, PLOT extends Plot = Plot> {
    _svg?: Selection<BaseType, unknown, BaseType, unknown>;
    data: DATA;
    experiment: Experiment;
    height: number;
    idPrefix = '';
    isExportMode?: boolean;
    margin: PlotMargin;
    onZoomTransform?: (transformObject: ZoomTransformObject) => void;
    plot: PLOT;
    publicationMode?: boolean;
    svg: Selection<BaseType, unknown, BaseType, unknown>;
    tooltip: Selection<HTMLDivElement, unknown, HTMLElement, unknown>;
    tooltipId: string;
    width: number;
    zoomEnabled?: boolean;
    stylingOptions?: CustomPlotStylingOptions | null;

    protected constructor(options: ConstructorParams<DATA, PLOT>) {
        this.svg = options.svg;
        this._svg = options._svg;
        // Make sure plot is initialized before anything that may depend on it
        this.plot = options.plot;
        this.data = options.data;
        this.experiment = options.experiment;
        this.width = options.width;
        this.height = options.height;
        this.onZoomTransform = options.onZoomTransform;
        const tooltipId = options.tooltipId ?? 'NO_TOOLTIP_ID';
        this.tooltipId = tooltipId;
        this.tooltip = createPlotTooltip(tooltipId);
        this.margin = this.calculateMargins();
        this.publicationMode = options.publicationMode ?? false;
        this.isExportMode = options.isExportMode ?? false;
        this.idPrefix = options.idPrefix ?? '';
        this.zoomEnabled = options.zoomEnabled ?? true;
        this.stylingOptions = options.stylingOptions ?? null;
    }

    get themeColor() {
        return (this.plot.display as ThemeablePlotChartDisplayOption)?.theme_color;
    }

    get themeStyle() {
        return (this.plot.display as ThemeablePlotChartDisplayOption)?.theme_style ?? ThemeStyle.medium;
    }

    clearAll() {
        this.svg.selectChildren().remove();
    }

    abstract calculateMargins(): PlotMargin;

    abstract draw(): void;
}
