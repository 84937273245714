import { CellProps } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import React from 'react';
import GeneInfoPopover from '@components/GeneInfoPopover';
import { OrganismID } from '@models/Organism';

export const RatGeneLinkCell = ({ value }: CellProps<GenericCellData>) => {
    return (
        <div className="inline-block">
            <GeneInfoPopover gene={value} organism={OrganismID.rat} />
        </div>
    );
};

export default RatGeneLinkCell;
