import { usePlotContext } from '@contexts/PlotContext';
import { TextAnalysis } from '@models/analysis/TextAnalysis';

import PlutoMarkdownContentView from '@components/PlutoMarkdownContentView';
import cn from 'classnames';
import { isNotBlank } from '@util/StringUtil';
import TextDisplayOption from '@models/plotDisplayOption/TextDisplayOption';

const TextPlotView = () => {
    const { plot, disableMaxHeight } = usePlotContext();

    const analysis = plot.analysis as TextAnalysis;
    const content = analysis.content ?? '';
    const display = plot.display as TextDisplayOption | null;

    return (
        <div className={cn('h-full overflow-hidden')}>
            <div className="flex h-full flex-col">
                <div className={cn('flex-grow overflow-auto ')}>
                    <PlutoMarkdownContentView
                        content={content}
                        className={cn('px-4 sm:px-6', {
                            'max-h-[500px]': !disableMaxHeight,
                            'h-full overflow-auto': disableMaxHeight,
                            'pt-4': isNotBlank(plot.display.plot_title),
                        })}
                    />
                </div>
                {display?.attribution_label && isNotBlank(display.attribution_label) && (
                    <div className="mt-4 flex-shrink-0 px-4 sm:px-6">
                        {display?.attribution_url && isNotBlank(display.attribution_url) ? (
                            <a href={display.attribution_url} target="_blank" rel="noreferrer nofollow">
                                <span>{display.attribution_label}</span>
                            </a>
                        ) : (
                            <span>{display.attribution_label}</span>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextPlotView;
