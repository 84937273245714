import CoverageAnalysisMethodsSection from '@components/experiments/methods/analyses/CoverageAnalysisMethodsSection';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import CodeBlockInline from '@components/CodeBlockInline';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import ProfilePlotDisplayOption from '@models/plotDisplayOption/ProfilePlotDisplayOption';
import { PlotStatistic } from '@models/analysis/CoverageAnalysis';

export const formattedStatisticsNames = {
    mean: 'mean',
    median: 'median',
    min: 'min',
    max: 'max',
    std: 'standard deviation',
    sum: 'sum',
};

type Props = { plot: Plot; experiment: Experiment };
const ProfilePlotMethods = ({ plot, experiment }: Props) => {
    const display = plot.display as ProfilePlotDisplayOption | null;
    const plotStatistic = display?.plot_statistic as PlotStatistic;
    const plotStatisticName = formattedStatisticsNames[plotStatistic];
    return (
        <>
            <CoverageAnalysisMethodsSection plot={plot} experiment={experiment} />
            <section>
                <MethodsSectionTitle>TSS profile plot</MethodsSectionTitle>
                <span>
                    The genome-wide signal profile <Bold>{plotStatisticName}</Bold> for each sample was generated and
                    plotted using the <CodeBlockInline>deepTools</CodeBlockInline>{' '}
                    <CodeBlockInline>plotProfile()</CodeBlockInline> function.
                </span>
            </section>
        </>
    );
};

export default ProfilePlotMethods;
