import { PageLayout } from '@layouts/Layout';
import { Tooltip } from '@mui/material';
import cn from 'classnames';
import { InfoOutlined } from '@mui/icons-material';
import { blankToUndefined, isBlank } from '@util/StringUtil';
import AnalysisIcon from '@components/experiments/analyses/AnalysisIcon';
import StatisticalSymbolLabel from '@components/StatisticalSymbolLabel';
import SurvivalAnalysisTooltipHelpIcon from '@components/experiments/SurvivalAnalysisTooltipHelpIcon';
import { defaultTextLightClass } from '@components/icons/CustomIcons';
import { DisplayTypeIcon } from './ExperimentIcons';
import AnalysisType from '@models/analysis/AnalysisType';
import { Blobs } from '../effects/MouseMoveHoverEffect';

type PageProps = {
    analysis: AnalysisType;
    onSelect: (analysis: AnalysisType) => Promise<void> | void;
    isSelected: boolean;
};
const AnalysisCard: PageLayout<PageProps> = ({ analysis, onSelect, isSelected }: PageProps) => {
    const handleAnalysisClicked = () => {
        if (!analysis.is_enabled) return null;
        onSelect(analysis);
    };

    return (
        <button
            data-cy="select-analysis-btn"
            onClick={handleAnalysisClicked}
            disabled={!analysis.is_enabled}
            className={cn('card relative overflow-hidden rounded-lg p-0.5 text-left', {
                'bg-gray-200 hover:cursor-default': !analysis.is_enabled,
                'group bg-indigo-300/20 transition-all duration-300 ease-in-out': analysis.is_enabled,
                '!bg-indigo-300': isSelected,
            })}
        >
            <div
                className={cn(
                    'inner relative z-10 h-full w-full rounded-md px-2 py-1 backdrop-blur-3xl transition-all duration-300',
                    {
                        'bg-gray-200': !analysis.is_enabled,
                        'bg-white group-hover:bg-white/80 group-hover:backdrop-blur-3xl': analysis.is_enabled,
                        '!bg-indigo-50': isSelected,
                    },
                )}
            >
                {/* TODO: Check tooltip styles */}
                <Tooltip
                    disableFocusListener={isBlank(analysis.tooltip)}
                    disableTouchListener={isBlank(analysis.tooltip)}
                    disableHoverListener={isBlank(analysis.tooltip)}
                    title={<p className="max-w-[200px] text-[14px] leading-5">{analysis.tooltip}</p>}
                    placement="top"
                    arrow
                >
                    <div className="flex space-x-4 py-2">
                        <span className="pt-2">
                            <AnalysisIcon
                                shortname={analysis.shortname}
                                width={24}
                                height={24}
                                className={cn({
                                    'text-gray-500': !analysis.is_enabled,
                                    [defaultTextLightClass]: analysis.is_enabled,
                                })}
                            />
                        </span>
                        <div className="">
                            <p className="flex items-center space-x-2 text-base font-semibold">
                                <StatisticalSymbolLabel name={analysis.display_name} />

                                {analysis.shortname === 'survival' && <SurvivalAnalysisTooltipHelpIcon />}
                            </p>
                            <p>{analysis.description}</p>
                            <div className="mt-4 flex flex-row" data-cy="display-type">
                                {analysis.display_types.map((type, index) => {
                                    const isDisabled = !type.is_enabled;

                                    return (
                                        <div
                                            className={cn(
                                                'relative mr-2 flex flex-col items-center space-y-2 rounded-lg border border-indigo-100 px-4 py-2 text-center text-dark',
                                                {
                                                    'border-indigo-200': isDisabled || !analysis.is_enabled,
                                                },
                                            )}
                                            key={index}
                                        >
                                            <div
                                                className={cn('flex flex-grow items-center', {
                                                    'px-5 text-gray-300': isDisabled,
                                                })}
                                            >
                                                <DisplayTypeIcon
                                                    type={type.shortname}
                                                    className={blankToUndefined(
                                                        cn({
                                                            'text-gray-400': isDisabled || !analysis.is_enabled,
                                                            [defaultTextLightClass]: analysis.is_enabled,
                                                        }),
                                                    )}
                                                    width={24}
                                                    height={24}
                                                />
                                            </div>
                                            <span
                                                className={cn({
                                                    'text-dark': !isDisabled,
                                                    'text-gray-500': isDisabled,
                                                })}
                                            >
                                                {type.display_name}{' '}
                                                {isDisabled && (
                                                    <Tooltip
                                                        title={
                                                            <span className="text-sm">
                                                                {!type.is_enabled
                                                                    ? 'This plot type requires the analysis to be upgraded to the latest version'
                                                                    : 'Coming soon!'}
                                                            </span>
                                                        }
                                                        arrow
                                                        placement="top"
                                                        disableFocusListener
                                                    >
                                                        <InfoOutlined
                                                            className="absolute right-1.5 top-1.5 text-indigo-600"
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                )}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </div>

            <Blobs />
        </button>
    );
};

export default AnalysisCard;
