import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { PreprocessFormValues, RefineSeuratObjectFormValues } from '../../preprocesses/PreprocessFormTypes';
import ProseList from '@components/ProseList';
import { StatTestSelectOptions } from '@models/preprocesses/ClusterSeuratObject';
import CodeBlockInline from '@components/CodeBlockInline';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string };
const RefineSeuratMethods = ({ preprocessFormData, displayName }: Props) => {
    const preprocess = preprocessFormData as RefineSeuratObjectFormValues;
    const prettyStatTestName = StatTestSelectOptions.find((option) => option.value === preprocess.stat_test)?.label;
    const refineMethodSkipped = preprocess.refine_method === 'skip';
    const refineMethodRemoveCluster = preprocess.refine_method === 'remove_clusters';

    return (
        <div className="space-y-6">
            <section>
                {refineMethodSkipped ? (
                    <p>Refinement was skipped. No methods are available.</p>
                ) : refineMethodRemoveCluster ? (
                    <>
                        <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                        <div className="space-y-4">
                            <p>
                                Cells belonging to the following cluster(s) from resolution{' '}
                                {Number(preprocess.target_resolution).toFixed(1)} were removed from the Seurat object:{' '}
                                <ProseList items={preprocess?.target_clusters ?? []} />.
                            </p>

                            <div>
                                <MethodsSectionTitle>Marker gene identification</MethodsSectionTitle>
                                <p>
                                    Marker genes are genes that are differentially and specifically expressed in
                                    particular cell types or clusters compared to all other cell types or clusters in a
                                    dataset. Marker genes for each cluster in each resolution were identified using the{' '}
                                    {preprocess.stat_test !== 'wilcox' ? (
                                        <CodeBlockInline>FindAllMarkers()</CodeBlockInline>
                                    ) : (
                                        <CodeBlockInline>RunPrestoAll()</CodeBlockInline>
                                    )}{' '}
                                    function, with the {prettyStatTestName} and a log2 fold change threshold of{' '}
                                    {preprocess.log2fc}.{' '}
                                    {preprocess.only_pos &&
                                        'Only positive marker genes for each cluster were returned.'}
                                </p>
                            </div>

                            <div>
                                <MethodsSectionTitle>Dot plot of top marker genes</MethodsSectionTitle>
                                <p>
                                    For the dot plot, the top 3 marker genes for each cluster were identified by sorting
                                    the marker genes for each cluster first by a gene&apos;s adjusted p-value, and then
                                    by
                                    {!preprocess.only_pos && ' the absolute value of'} a gene&apos;s average log2 fold
                                    change.
                                </p>
                            </div>

                            <div>
                                <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                                <p>
                                    R v4.3.1
                                    <br />
                                    Seurat v4.3.0
                                    <br />
                                    {preprocess.stat_test === 'wilcox' ? (
                                        <>
                                            SeuratWrappers v0.3.1
                                            <br />
                                            Presto v1.0.0
                                        </>
                                    ) : null}
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}
            </section>
        </div>
    );
};

export default RefineSeuratMethods;
