import { ReactElement } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

type Props = { enabled: boolean; children: ReactElement } & TooltipProps;
const ConditionalTooltip = ({ enabled, children, ...tooltipProps }: Props) => {
    if (enabled) {
        return <Tooltip {...tooltipProps}>{children}</Tooltip>;
    }
    return children;
};

export default ConditionalTooltip;
