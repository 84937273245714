import { DisplayTypeIcon } from '@components/experiments/ExperimentIcons';
import React, { useMemo } from 'react';
import { usePlotContext } from '@contexts/PlotContext';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { BarPlotIcon } from '@components/icons/custom/BarPlotIcon';

const PlotNotSupportedView = () => {
    const { plot, experiment } = usePlotContext();
    const { getAnalysisInfo, getDisplayTypeInfo } = useExperimentSettings(experiment);
    const display_type = plot.display.display_type;
    const analysis_type = plot.analysis?.analysis_type ?? plot.analysis_type;

    const { analysisInfo, displayInfo } = useMemo(() => {
        const analysisInfo = getAnalysisInfo(analysis_type);
        const displayInfo = getDisplayTypeInfo({ analysisType: analysis_type, displayType: display_type });
        return { analysisInfo, displayInfo };
    }, [display_type, analysis_type]);

    return (
        <div className="flex flex-col items-center space-y-4 py-12">
            <div className="mx-auto rounded-full bg-indigo-100 p-4 text-indigo-800">
                {display_type ? (
                    <DisplayTypeIcon type={plot.display.display_type} width={24} height={24} />
                ) : (
                    <BarPlotIcon width={24} height={24} />
                )}
            </div>
            <div className="text-center">
                <p className="text-xl font-semibold text-dark">Chart not supported</p>
                <span className="font-semibold">{displayInfo?.display_name ?? display_type}</span> is not a supported
                chart type for <span className="font-semibold">{analysisInfo?.display_name ?? plot.analysis_type}</span>{' '}
                analyses
            </div>
        </div>
    );
};

export default PlotNotSupportedView;
