import TextInputField from '@components/forms/TextInputField';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';

const ExternalPlotDisplayFields = () => {
    return (
        <>
            <FullWidthToggleField />
            <TextInputField name="plot_title" label="Title" placeholder={'External file'} />
        </>
    );
};

export default ExternalPlotDisplayFields;
