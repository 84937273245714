import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { isNotBlank } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import Logger from '@util/Logger';
import cn from 'classnames';
import { XIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';
import PrismGraphsetDisplayOption from '@/src/models/plotDisplayOption/PrismDisplayOption';
import { PreviewGraphData } from '@models/ExperimentData';
import { valueOrFirst } from '@util/ObjectUtil';
import { useRouter } from 'next/router';

const logger = Logger.make('PlotImageView');

type Props = { plot: Plot; experiment: Experiment; setPlotDragEnabled?: (enabled: boolean) => void };
const PrismGraphsetView = ({ plot, experiment, setPlotDragEnabled }: Props) => {
    const router = useRouter();
    const query = router.query;
    const analysis = plot.analysis as PrismAnalysis | null;
    const display = plot.display as PrismGraphsetDisplayOption | null;
    const plotId = plot.uuid;
    const [imageError, setImageError] = useState<boolean>(false);
    const [captionExpanded, setCaptionExpanded] = useState(false);
    const hasCaption = isNotBlank(display?.caption);
    const publicKey = valueOrFirst(query.shareKey);

    const { data: plotData, error: plotDataError } = useSWR<PreviewGraphData>(() =>
        plot?.analysis && isDefined(plotId)
            ? Endpoints.lab.experiment.plot.data(
                  {
                      plotId: plotId,
                      experimentId: experiment.uuid,
                  },
                  {
                      key: publicKey ?? undefined,
                  },
              )
            : null,
    );

    const plotUrl = plotData?.url;

    useEffect(() => {
        if (captionExpanded) {
            setPlotDragEnabled?.(false);
        } else {
            setPlotDragEnabled?.(true);
        }
    }, [captionExpanded]);

    const handlePreviewLoadError = (error: unknown) => {
        logger.error(`Image failed to load for analysis_id=${analysis?.uuid}`, error);
        setImageError(true);
    };

    return (
        <div className="mt-4 flex flex-grow flex-col justify-center">
            {(display?.selected_graph || plotUrl) && !imageError && (
                <div className="relative mx-auto">
                    <img
                        src={plotUrl}
                        alt={display?.selected_graph ?? analysis?.name ?? 'Prism preview'}
                        className="max-h-[400px] object-contain"
                        onError={handlePreviewLoadError}
                    />
                </div>
            )}
            {imageError ||
                (plotDataError && (
                    <div>
                        <Alert severity="error">The image failed to load.</Alert>
                    </div>
                ))}
            {!plotUrl && (
                <div className="h-full">
                    <p className="text-m font-semibold">
                        Graph image will appear here once it has been uploaded and processed. Edit to toggle which graph
                        or layout to show.
                    </p>
                </div>
            )}
            {hasCaption && (
                <div>
                    <div
                        className={cn('mx-auto mt-4 flex w-full max-w-3xl flex-col', {
                            'justify-end': !hasCaption,
                            'justify-between': hasCaption,
                        })}
                    >
                        {hasCaption && (
                            <div className="flex max-w-full" onClick={() => setCaptionExpanded(!captionExpanded)}>
                                <p className="overflow-hidden text-ellipsis whitespace-nowrap">{display?.caption}</p>
                                {display?.caption && display?.caption?.length > 100 && (
                                    <span className="link">more</span>
                                )}
                            </div>
                        )}
                    </div>
                    {hasCaption && (
                        <Transition
                            show={captionExpanded}
                            enter="transition-all duration-125"
                            enterFrom="translate-y-full"
                            enterTo="h-full translate-y-0"
                            leave="transition-all duration-125"
                            leaveFrom="h-full translate-y-0"
                            leaveTo="translate-y-full"
                            as="div"
                            className="absolute bottom-0 left-4 right-4 mx-auto mt-4 h-full max-h-[65%] max-w-3xl bg-white"
                        >
                            <span className="absolute -top-5 right-2 z-10">
                                <span
                                    onClick={() => setCaptionExpanded(false)}
                                    className="flex cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white p-2 text-primary hover:bg-gray-50 active:bg-gray-100"
                                >
                                    <XIcon width={18} />
                                </span>
                            </span>
                            <div className=" h-full space-y-8 overflow-y-scroll rounded-t-xl border border-b-0 border-gray-200 bg-white px-4 py-4 text-dark backdrop-blur-2xl">
                                <p className="whitespace-pre-line">{display?.caption}</p>
                            </div>
                        </Transition>
                    )}
                </div>
            )}
        </div>
    );
};

export default PrismGraphsetView;
