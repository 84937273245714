import Plot from '@models/Plot';
import {
    BaseAnalysisParameters,
    ClusteringAnalysisParameters,
    DifferentialExpressionPickerFieldTypes,
} from '@models/AnalysisParameters';
import Experiment from '@models/Experiment';
import { useFormikContext } from 'formik';
import React from 'react';
import DifferentialExpressionAnalysisPickerField from '@components/experiments/analyses/fields/DifferentialExpressionAnalysisPickerField';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import TextInputField from '@components/forms/TextInputField';
import SingleSelectField from '@components/filters/fields/SingleSelectField';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import { ClusteringAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import CheckboxField from '@components/forms/CheckboxField';
import Bold from '@components/elements/Bold';
import { formatSmallNumber } from '@util/StringUtil';
import SimpleCollapse from '@components/experiments/SimpleCollapse';

type Props = {
    plot: Plot;
    analysisParameters: ClusteringAnalysisParameters;
    experiment: Experiment;
};
const ClusteringAnalysisFormFields = ({ experiment, plot, analysisParameters }: Props) => {
    const { values, ...form } = useFormikContext<ClusteringAnalysisFormValues>();

    return (
        <div className="space-y-8">
            <div className="rounded-xl border border-indigo-500 p-4">
                <DifferentialExpressionAnalysisPickerField
                    experimentId={experiment.uuid}
                    name="differential_analysis_id"
                    analysisTypeFieldName="differential_analysis_shortname"
                    filter={{ analysis_types: DifferentialExpressionPickerFieldTypes }}
                />
                <SimpleCollapse label={'Thresholds'} initialOpen={false}>
                    <div className="space-y-4">
                        <PValueField
                            name="adj_pval_threshold"
                            hideSectionLabel
                            showHint
                            noMargin
                            label={
                                <span>
                                    Adjusted <i>p</i>-value threshold
                                </span>
                            }
                        />

                        <TextInputField
                            name="log2_fc_threshold"
                            type="number"
                            step={0.1}
                            noMargin
                            label={
                                <span>
                                    Absolute log<sub>2</sub> fold change threshold
                                </span>
                            }
                            hint={
                                <p>
                                    <span className="block">
                                        Positive fold change ={' '}
                                        {Number.parseFloat(
                                            formatSmallNumber({ value: Math.pow(2, values.log2_fc_threshold) }),
                                        )}
                                    </span>
                                    <span className="block">
                                        Negative fold change ={' '}
                                        {Number.parseFloat(
                                            formatSmallNumber({ value: Math.pow(2, -values.log2_fc_threshold) }),
                                        )}
                                    </span>
                                </p>
                            }
                        />
                        <SingleSelectField
                            name="log2_fc_direction"
                            label={'Direction'}
                            options={[
                                {
                                    value: 'both',
                                    label: 'Both positive and negative',
                                },
                                { value: 'positive', label: 'Positive only' },
                                { value: 'negative', label: 'Negative only' },
                            ]}
                        />
                    </div>
                </SimpleCollapse>
            </div>

            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Analyze</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            checked={values.include_all_samples ?? false}
                            onChange={() => {
                                form.setValues({
                                    ...values,
                                    include_all_samples: true,
                                    variables: [],
                                    group_ids: [],
                                });
                            }}
                        />
                        <span className="ml-2">All samples</span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            onChange={() => {
                                form.setValues({
                                    ...values,
                                    include_all_samples: false,
                                    variables: [],
                                    group_ids: [],
                                });
                            }}
                            checked={!values.include_all_samples}
                        />
                        <span className="ml-2">Specific groups of samples</span>
                    </label>
                </div>
                {!values.include_all_samples && (
                    <div className="mt-4">
                        <AnalysisVariablesMultiSelectField
                            variables={(analysisParameters as BaseAnalysisParameters).variables}
                        />
                        <AnalysisGroupsField plot={plot} experiment={experiment} />
                    </div>
                )}
            </section>
            <section>
                <SimpleCollapse label="Data transformations">
                    <div className="space-y-2">
                        <CheckboxField
                            className="flex items-start"
                            offsetCheckbox={false}
                            checkboxClassName="mt-1"
                            name="is_zscore"
                            label={
                                <div>
                                    <Bold className="block">Z-score transformation</Bold>
                                    <p>Centers and scales data to a standard normal distribution</p>
                                </div>
                            }
                        />
                        <CheckboxField
                            name="is_log2_transform"
                            className="flex items-start"
                            offsetCheckbox={false}
                            checkboxClassName="mt-1"
                            label={
                                <div>
                                    <Bold className="block">
                                        Log<sub>2</sub> transform
                                    </Bold>
                                    <p>Transform the data to a log scale</p>
                                </div>
                            }
                        />
                        <CheckboxField
                            name="is_cpm_normalize"
                            className="flex items-start"
                            offsetCheckbox={false}
                            checkboxClassName="mt-1"
                            label={
                                <div>
                                    <p>
                                        <Bold className="">CPM Normalize</Bold> (Recommended)
                                    </p>
                                    <p>Normalizes raw counts</p>
                                </div>
                            }
                        />
                    </div>
                </SimpleCollapse>
            </section>
        </div>
    );
};

export default ClusteringAnalysisFormFields;
