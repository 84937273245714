import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import Button from '@components/Button';
import { ReactNode, useEffect, useState } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import TextInput from '@components/forms/TextInput';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import Workflow from '@models/Workflow';

export type Props = {
    confirmLoading?: boolean;
    defaultName?: string;
    description?: string | ReactNode;
    error?: ReactNode;
    icon?: ReactNode;
    onCancel: () => void;
    onConfirm: (workflowTitle: string) => void;
    open?: boolean;
    submitText?: string;
    title: string;
};
const NewWorkflowModal = ({
    confirmLoading = false,
    defaultName,
    description,
    error,
    icon,
    onCancel,
    onConfirm,
    open = false,
    submitText = 'Create',
    title,
}: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const { workflows } = useExperimentWorkflowContext();
    const [name, setName] = useState('');

    useEffect(() => {
        if (open) {
            if (defaultName) {
                setName(defaultName);
            } else {
                setName(`scRNA-seq preprocessing workflow ${(workflows as Workflow[])?.length + 1}`);
            }
        }
    }, [open]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle iconStart={icon} iconEnd={icon} title={title} />
            <DialogContent className="mb-4 space-y-4">
                {description}
                {error && <Alert severity="error">{error}</Alert>}
                <TextInput
                    label="New workflow name"
                    value={name}
                    name="name"
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    disableFormik
                    autoFocus
                />
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button variant={isSmAndDown ? 'outlined' : 'text'} color="primary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => onConfirm(name)}
                    variant="contained"
                    loading={confirmLoading}
                    disabled={confirmLoading}
                >
                    {submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default NewWorkflowModal;
