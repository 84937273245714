import React, { PropsWithChildren } from 'react';
import cn, { Argument as ClassValue } from 'classnames';

export type Props = PropsWithChildren<{
    className?: ClassValue;
    selected?: boolean;
    disabled?: boolean;
    onSelect?: () => void;
    paddingClass?: string;
    row?: boolean;
    activeClasses?: ClassValue;
    noBorder?: boolean;
    borderClasses?: string;
    cyId?: string;
}>;

const SelectableItem = ({
    children,
    className = '',
    onSelect,
    row = false,
    selected = false,
    disabled = false,
    paddingClass = 'px-3 py-2',
    noBorder = false,
    borderClasses = 'border-indigo-100',
    cyId,
    activeClasses = [
        {
            'bg-indigo-200': selected,
            'hover:bg-indigo-300': selected && !disabled,
            'bg-gray-200 border-0': disabled && !selected,
        },
    ],
}: Props) => {
    return (
        <div
            className={cn(
                paddingClass,
                'ease relative flex rounded-lg border transition duration-200 ',
                className,
                selected ? activeClasses : null,
                {
                    'flex-col': !row,
                    'flex-row': row,
                    [`${borderClasses} cursor-pointer text-dark`]: !disabled,
                    'cursor-default border-0 bg-gray-200 text-gray-500': disabled && !selected,
                    'hover:bg-indigo-50': !selected && !disabled,
                    'border-0': noBorder,
                },
            )}
            onClick={() => {
                if (!disabled) {
                    onSelect?.();
                }
            }}
            data-selected={selected}
            data-disabled={disabled}
            data-cy={cyId}
        >
            {children}
        </div>
    );
};

export default SelectableItem;
