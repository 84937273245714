import Plot from '@models/Plot';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import React from 'react';
import { DifferentialExpressionContinuousAnalysis } from '@models/analysis/DifferentialExpressionContinuousAnalysis';

type Props = { plot: Plot };
const DifferentialExpressionContinuousMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as DifferentialExpressionContinuousAnalysis | null;

    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    const experimentalGroupInfo = analysis.experimental_group;
    const controlGroupInfo = analysis.control_group;

    const $experimentalGroup = (
        <span className="font-semibold">{experimentalGroupInfo?.display_name ?? 'Experimental group'}</span>
    );
    const $controlGroup = <span className="font-semibold">{controlGroupInfo?.display_name ?? 'Control group'}</span>;

    return (
        <section>
            <MethodsSectionTitle>Differential expression analysis</MethodsSectionTitle>

            <div className="space-y-4">
                <p className="mb-4">
                    Differential expression analysis was performed comparing the groups {$experimentalGroup} vs{' '}
                    {$controlGroup} with the <span className="font-mono">limma</span> R package
                    <sup>1</sup>, which tests for differential expression using linear models.
                </p>

                <p>
                    Log<sub>2</sub> fold change was calculated for the comparison: {$experimentalGroup} vs{' '}
                    {$controlGroup}. Thus, genes with a positive log<sub>2</sub> fold change value had increased
                    expression in {$experimentalGroup} samples. Genes with a negative log<sub>2</sub> fold change value
                    had increased expression in {$controlGroup} samples.
                </p>
                <p className="italic">
                    Note: differential expression results shown here do not account for any experimental covariates that
                    may be present (e.g. batch, paired subjects, etc).
                </p>
            </div>
        </section>
    );
};

export default DifferentialExpressionContinuousMethodsSection;
