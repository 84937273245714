import PlutoMarkdownContentView from '@/src/components/PlutoMarkdownContentView';
import ExternalDisplayOption from '@/src/models/plotDisplayOption/ExternalPlotDisplayOption';
import { ExternalAnalysis } from '@models/analysis/ExternalAnalysis';
import Plot from '@models/Plot';
import { isNotBlank } from '@util/StringUtil';
import React from 'react';

type Props = { plot: Plot };
const ExternalPlotMethods = ({ plot }: Props) => {
    const analysis = plot.analysis as ExternalAnalysis | null;
    const display = plot.display as ExternalDisplayOption | null;
    const methods: string | null = (analysis?.methods || display?.methods) ?? null;

    if (methods && isNotBlank(methods)) return <PlutoMarkdownContentView content={methods} />;
    return <div>No methods have been provided.</div>;
};

export default ExternalPlotMethods;
