import React from 'react';
import Plot from '@models/Plot';
import { DifferentialExpressionAnalysis } from '@models/analysis/DifferentialExpressionAnalysis';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { AnalysisParameters } from '@models/AnalysisParameters';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const DifferentialBindingMethodsSection = ({ plot, analysisParameters }: Props) => {
    const analysis = plot.analysis as DifferentialExpressionAnalysis | null;
    if (!analysis) {
        return <div>No analysis was found.</div>;
    }

    const {
        experimental_group: experimentalGroupInfo,
        control_group: controlGroupInfo,
        covariates: covariatesInfo,
    } = analysis;

    const getCovariateNameById = (id) => {
        const variable = analysisParameters?.variables.find((v) => v.id === id);
        return variable ? variable.display_name : null;
    };
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    const covariateNames = covariatesInfo?.map((id) => getCovariateNameById(id));
    if (covariateNames && covariateNames.length > 0) {
        // Capitalize only the first covariate's name
        covariateNames[0] = capitalizeFirstLetter(covariateNames[0]);
    }
    const verb = covariateNames && covariateNames.length > 1 ? 'were' : 'was';

    const $experimentalGroup = (
        <span className="font-semibold">{experimentalGroupInfo?.display_name ?? 'Experimental group'}</span>
    );
    const $controlGroup = <span className="font-semibold">{controlGroupInfo?.display_name ?? 'Control group'}</span>;

    return (
        <section>
            <MethodsSectionTitle>Differential peak analysis</MethodsSectionTitle>

            <div className="space-y-4">
                <p className="mb-4">
                    Differential peak analysis was performed comparing the groups: {$experimentalGroup} vs{' '}
                    {$controlGroup}. The comparison was performed with the DESeq2 R package
                    <sup>1</sup>, which tests for differential abundance based on a model using the negative binomial
                    distribution.{' '}
                    {covariateNames && covariateNames.length > 0 ? (
                        <span>
                            <span className="font-semibold">{covariateNames.join(', ')}</span> {verb} included in the
                            design as covariate(s).
                        </span>
                    ) : (
                        <p className="italic">
                            Note: differential peak analysis results shown here do not account for any experimental
                            covariates that may be present (e.g. batch, paired subjects, etc).
                        </p>
                    )}
                </p>

                <p>
                    Log<sub>2</sub> fold change was calculated for the comparison: {$experimentalGroup} vs{' '}
                    {$controlGroup}. Thus, peaks with a positive log<sub>2</sub> fold change value had increased peak
                    coverage in {$experimentalGroup} samples. Peaks with a negative log<sub>2</sub> fold change value
                    had increased peak coverage in {$controlGroup} samples.
                </p>
            </div>
        </section>
    );
};

export default DifferentialBindingMethodsSection;
