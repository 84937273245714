import Button from '@components/Button';
import React, { ComponentProps, MouseEvent, useEffect, useRef, useState } from 'react';
import { DropzoneProps, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { ProgressEventInfo } from '@services/ApiService';
import { Menu, MenuItem } from '@mui/material';
import { humanFileSize } from '@util/StringUtil';
import { Alert } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import LoadingProgress from '@components/LoadingProgress';
import Logger from '@util/Logger';
import { pluralize, proseListJoiner } from '@util/ObjectUtil';

const logger = Logger.make('UploadDataView');

export type Props = ComponentProps<'div'> & {
    title?: string | null;
    description?: React.ReactNode;
    uploadFiles: (files: File[]) => Promise<void>;
    progress?: ProgressEventInfo | null;
    noPadding?: boolean;
    error?: string | null;
    showSuccess?: boolean;
    resetOnSuccess?: boolean;
    disabled?: boolean;
    successMessage?: string;
    hideSelectFileButton?: boolean;
    onFilesCleared?: () => void;
} & Pick<DropzoneProps, 'accept' | 'maxFiles' | 'maxSize'>;

export const FileUploadSuccessView = ({ message = 'File has been successfully uploaded' }: { message?: string }) => {
    return (
        <div className="flex flex-col justify-center px-8 text-center">
            <div className="mb-2 flex items-center justify-center">
                <span className="rounded-full bg-success p-2 text-success">
                    <CheckCircleIcon className="h-6 w-6" />
                </span>
            </div>
            <h2 className="text-xl font-semibold tracking-tight">{message}</h2>
        </div>
    );
};

export type TemplateDownloadLinkProps = { googleSheetUrl: string; fileUrl: string };
export const TemplateDownloadLinkView = ({ googleSheetUrl, fileUrl }: TemplateDownloadLinkProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                endIcon={<KeyboardArrowDownRoundedIcon />}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleClick}
            >
                Download template
            </Button>
            <Menu
                id="download-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                keepMounted
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem component="a" href={googleSheetUrl} target="_blank">
                    Google Sheet
                </MenuItem>
                <MenuItem component="a" download href={fileUrl}>
                    Download CSV
                </MenuItem>
            </Menu>
        </>
    );
};

const UploadDataView = ({
    description,
    uploadFiles,
    title = 'Drag and drop your files here',
    progress,
    className,
    noPadding,
    children,
    showSuccess: initialSuccess,
    error: uploadError,
    resetOnSuccess = false,
    disabled: disabledProp,
    accept,
    maxFiles = 1,
    maxSize,
    successMessage,
    hideSelectFileButton = false,
    onFilesCleared,
    ...props
}: Props) => {
    const [files, setFiles] = useState<File[]>([]);
    const [inputError, setInputError] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState<number | null>(null);
    const [showSuccess, setShowSuccess] = useState<boolean>(initialSuccess ?? false);

    const hasFiles = files.length > 0;
    const error = inputError ?? uploadError;

    const disabled = disabledProp || !!error || showSuccess;

    useEffect(() => {
        if (resetOnSuccess && (progress?.percentage ?? 0) >= 1) {
            handleClearFiles();
        }
        // if ((progress?.percentage ?? 0) >= 1 && initialSuccess && !uploadError) {
        if (progress === null && initialSuccess && !uploadError) {
            // handleClearFiles(true);
            setShowSuccess(true);
        }
        if (uploadError) {
            setShowSuccess(false);
        }
    }, [resetOnSuccess, progress, initialSuccess, uploadError]);

    useEffect(() => {
        if (ref?.current) {
            const updatedSize = ref.current?.getBoundingClientRect();
            const contentHeight = ref.current?.scrollHeight;
            const previousHeight = maxHeight ?? 0;
            const updatedHeight = updatedSize?.height ?? 0;

            const newHeight = Math.max(updatedHeight, contentHeight);
            if (previousHeight <= newHeight) {
                setMaxHeight(newHeight);
            }
        }
    }, [ref, error, showSuccess]);

    const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if ((rejectedFiles?.length ?? 0) > 0) {
            const errorMessages: string[] = [];

            if ((rejectedFiles?.length ?? 0) > maxFiles) {
                errorMessages.push(`You may only upload ${maxFiles} file${maxFiles !== 1 ? 's' : ''} at a time.`);
            }

            const anyBadTypes = rejectedFiles.some((file) =>
                file.errors.some((e) => e.code === ErrorCode.FileInvalidType),
            );
            if (anyBadTypes) {
                const allowedExtensions = new Set<string>();
                Object.values(accept ?? {}).forEach((fileTypes) => {
                    fileTypes?.forEach((fileType) => {
                        allowedExtensions.add(fileType);
                    });
                });

                errorMessages.push(
                    `${pluralize(maxFiles, 'File', 'Files')} must be in ${proseListJoiner(
                        Array.from(allowedExtensions),
                        { lastDelimiter: 'or' },
                    )} format.`,
                );
            }
            if (errorMessages.length === 0) {
                errorMessages.push(
                    `${rejectedFiles.length} file${rejectedFiles.length !== 1 ? 's' : ''} was rejected.`,
                );
            }
            setInputError(errorMessages.join(' '));
        }
        if (acceptedFiles.length > 0) {
            acceptedFiles.forEach((acceptedFile: File) => uploadIndividualFile(acceptedFile));
        } else {
            setFiles([]);
        }
    };

    const uploadIndividualFile = async (acceptedFile: File) => {
        logger.info('starting file upload');
        setFiles([...files, acceptedFile]);
        setShowSuccess(false);
        await uploadFiles([acceptedFile]);
        logger.info('[DataUploadView] finished file upload...');
        setFiles([]);
    };

    const handleClearFiles = async (showSuccess = false) => {
        await uploadFiles([]);
        setFiles([]);
        onFilesCleared?.();
        setInputError(null);
        setShowSuccess(showSuccess);
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        disabled,
        accept,
        maxFiles,
        maxSize,
        multiple: maxFiles > 1,
    });

    const renderInputErrorView = () => {
        return (
            <div className="flex flex-col justify-center px-8 py-4 text-center">
                <div className="mb-2 flex items-center justify-center">
                    <span className="rounded-full bg-error p-2 text-error">
                        <ExclamationCircleIcon className="h-6 w-6" />
                    </span>
                </div>
                <h2 className="text-xl font-semibold tracking-tight">Unable to upload</h2>
                <p className="mb-4 text-base">{error}</p>
                <div>
                    <Button onClick={() => handleClearFiles()} variant="outlined">
                        Try again
                    </Button>
                </div>
            </div>
        );
    };

    const renderEmptyStateView = () => {
        return (
            <>
                {title && (
                    <h3
                        className={cn('text-xl font-semibold tracking-tight transition', {
                            'text-gray-500': disabled,
                        })}
                    >
                        {title}
                    </h3>
                )}
                {!isDragActive && (
                    <>
                        <div onClick={(e) => e.preventDefault()}>
                            {description && <div className="mx-auto max-w-sm">{description}</div>}
                        </div>
                        {!hideSelectFileButton && (
                            <div className="mt-2">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={open}
                                    disabled={disabled}
                                >
                                    Select File
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };

    const renderUploaderView = () => {
        return (
            <div>
                <input {...getInputProps()} />
                <div className="flex flex-col items-center text-center">
                    {error ? renderInputErrorView() : renderEmptyStateView()}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        // Show the "select files" screen
        if (error || (!hasFiles && !showSuccess)) {
            return renderUploaderView();
        }
        if (hasFiles || showSuccess) {
            return (
                <div className="flex w-full flex-1 flex-col items-center justify-center rounded-2xl text-center focus:border-solid focus:outline-none focus:ring">
                    {uploadError && (
                        <Alert severity="error" className="mb-6 w-full">
                            {uploadError}
                        </Alert>
                    )}
                    {!showSuccess && !uploadError && progress && (
                        <div className="flex w-full flex-col items-center justify-center">
                            <div className="mb-2 flex justify-center">
                                <LoadingProgress progress={progress} />
                            </div>
                            <div>
                                {files.map((file) => {
                                    return (
                                        <div key={`selected_file ${file.name}`} className="flex flex-col">
                                            <span className="break-all font-semibold text-dark">{file.name}</span>
                                            <span className="text-default">{humanFileSize(file.size)}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {showSuccess && <FileUploadSuccessView message={successMessage} />}
                    {showSuccess && !progress && (
                        <Button onClick={() => handleClearFiles()} color="primary" variant="text">
                            {showSuccess ? 'Upload new file' : 'Clear file'}
                        </Button>
                    )}
                </div>
            );
        }
    };

    return (
        <div
            {...getRootProps()}
            {...props}
            style={{ height: (maxHeight ?? 0) > 0 ? `${Math.max(maxHeight ?? 0, 200)}px` : '200px' }}
            className={cn(
                'mt-4 flex flex-1 flex-col justify-center rounded-2xl border-2 border-dashed transition focus:border-solid focus:outline-none focus:ring',
                {
                    'bg-teal-100': isDragActive,
                    'p-12': !noPadding,
                    'border-indigo-500 ': !disabled,
                    'border-gray-200 ': disabled,
                },
                className,
            )}
        >
            <div className={'py-4'} ref={ref}>
                {renderContent()}
                {children}
            </div>
        </div>
    );
};

export default UploadDataView;
