import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const TornadoPlotIcon = ({
    width = 23,
    height = 26,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            className={className}
            fill="none"
            height={height}
            strokeWidth={strokeWidth ?? 2}
            style={style}
            viewBox="0 0 23 26"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5434_2309)">
                <path
                    d="M0 1.625V2.31562C0 3.18398 0.0872768 4.04219 0.256696 4.875H18.3692C18.5335 4.52461 18.7542 4.19961 19.0366 3.92031L21.0183 1.96016C21.2339 1.74687 21.3571 1.45234 21.3571 1.14766C21.3571 0.512891 20.8386 0 20.1969 0H1.64286C0.734152 0 0 0.726172 0 1.625ZM18.0817 6.5H0.698214C1.32455 8.32305 2.35647 9.98867 3.71696 11.375H21.1518L18.7286 8.18086C18.3538 7.68828 18.133 7.1043 18.0817 6.5ZM6.47902 13.518L12.4549 16.9965C12.8759 17.2402 13.2661 17.5398 13.61 17.875H22.846C22.9487 17.3773 23 16.8645 23 16.3465C23 15.1785 22.7279 14.0359 22.2145 13H5.65759C5.92455 13.1828 6.19665 13.3555 6.47902 13.518ZM14.7857 19.5C15.3145 20.5867 15.4942 21.8105 15.294 23.0039L14.9911 24.8168C14.8884 25.4363 15.371 26.0051 16.0076 26.0051C16.2797 26.0051 16.5415 25.8984 16.7366 25.7055L20.7513 21.7344C21.4085 21.0844 21.9373 20.3277 22.3121 19.5051H14.7857V19.5Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_5434_2309">
                    <rect width={width} height={height} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    );
};
