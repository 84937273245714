import useFormStyles from '@hooks/useFormStyles';
import React, { ReactNode, useMemo } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { isBlank } from '@util/StringUtil';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MenuProps } from '@/src/theme';
import FormFieldError from '@components/forms/FieldError';
import { ParameterOption } from '@models/AnalysisParameters';
import { useField } from 'formik';
import { AnalysisVariables } from '@models/analysis/ExperimentAnalysis';
import { isDefined } from '@util/TypeGuards';

const NO_VALUE = -1;

type FieldValue = AnalysisVariables['variables'];

export type DisabledItem = { value: number; label: ReactNode };

type Props = {
    variables: ParameterOption[];
    variablesName?: string;
    groupsName?: string;
    disabled?: boolean;
    label?: ReactNode;
    placeholder?: string;
    disabledItems?: DisabledItem[];
};
const VariablePickerField = ({
    variables,
    variablesName = 'variables',
    disabled = false,
    label = 'Variable',
    placeholder = 'Select a variable...',
    disabledItems,
}: Props) => {
    const classes = useFormStyles;
    const [inputProps, field] = useField<FieldValue>(variablesName);
    const error = field.error;
    const { sortedVariables } = useMemo(() => {
        return {
            sortedVariables: (variables ?? []).sort((o1, o2) => {
                return (o1.display_name ?? '').localeCompare(o2?.display_name ?? '');
            }),
        };
    }, [variables]);

    return (
        <div className="form-field">
            <span className="field-label">{label}</span>
            <FormControl fullWidth variant="outlined" error={!isBlank(error)} disabled={disabled}>
                <Select
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    margin="dense"
                    value={isDefined(inputProps.value) ? inputProps.value : NO_VALUE}
                    placeholder={placeholder}
                    onChange={(e) => {
                        inputProps.onChange(e);
                    }}
                    error={!isBlank(error)}
                    MenuProps={{
                        ...MenuProps,
                        sx: {
                            paper: classes.targetMenu,
                        },
                    }}
                    className="whitespace-normal"
                    fullWidth
                    disabled={disabled}
                    name={variablesName}
                >
                    <MenuItem value={NO_VALUE} disabled>
                        <span className="text-sm">{placeholder}</span>
                    </MenuItem>
                    {sortedVariables.map((variable) => {
                        const disabledItem = disabledItems?.find((item) => item.value === variable.id);
                        return (
                            <MenuItem
                                key={variable.display_name}
                                value={variable.id}
                                disabled={isDefined(disabledItem)}
                            >
                                <div className="flex flex-grow items-start justify-between space-x-2 whitespace-normal">
                                    <span className="text-sm">{variable.display_name}</span>
                                    {disabledItem?.label && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            {disabledItem.label}
                                        </span>
                                    )}
                                </div>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {!isBlank(error) && <FormFieldError>{error}</FormFieldError>}
        </div>
    );
};

export default VariablePickerField;
