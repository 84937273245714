import { ReactNode } from 'react';

type Props = {
    items: ReactNode[];
    itemClassName?: string;
    lastJoinerWord?: string;
    joinerClassName?: string;
    delimiter?: string;
};
const ProseList = ({
    items,
    itemClassName = 'font-semibold',
    joinerClassName,
    lastJoinerWord = 'and',
    delimiter = ', ',
}: Props) => {
    if (items.length === 0) {
        return null;
    }

    if (items.length === 1) {
        return <span className={itemClassName}>{items[0]}</span>;
    }
    const last = items[items.length - 1];
    const listItems = items.slice(0, -1);

    return (
        <>
            {listItems.map((item, i) => (
                <span key={`item_${i}`}>
                    <span className={itemClassName}>{item}</span>
                    {listItems.length > 1 && <span className={joinerClassName}>{delimiter}</span>}
                </span>
            ))}
            <span className={joinerClassName}> {lastJoinerWord} </span>
            {<span className={itemClassName}>{last}</span>}
        </>
    );
};

export default ProseList;
