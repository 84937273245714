import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters, BaseAnalysisParameters } from '@models/AnalysisParameters';
import { useFormikContext } from 'formik';
import { UMAPAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import React, { useEffect, useMemo, useState } from 'react';
import { isDefined } from '@util/TypeGuards';
import TextInputField from '@components/forms/TextInputField';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters };
const UMAPAnalysisFormFields = ({ plot, experiment, analysisParameters }: Props) => {
    const { values, setFieldValue, ...form } = useFormikContext<UMAPAnalysisFormValues>();
    const [advancedExpanded, setAdvancedExpanded] = useState(false);

    const variables = values.variables ?? [];
    const { groups: availableGroups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables,
    });

    const totalSampleCount = analysisParameters.assay_sample_count;

    const maxSampleCount = useMemo(() => {
        if ((values.group_ids ?? []).length === 0) {
            return totalSampleCount;
        }
        const selectedGroups = availableGroups.filter((group) => values.group_ids?.includes(group.id));
        return selectedGroups.reduce((sum, group) => {
            return sum + (group.sample_id_count ?? 0);
        }, 0);
    }, [values.variables, values.group_ids, availableGroups, totalSampleCount]);

    useEffect(() => {
        setFieldValue('max_sample_count', maxSampleCount);
    }, [maxSampleCount]);

    useEffect(() => {
        if (
            (form.errors.n_neighbors && form.touched.n_neighbors) ||
            (form.errors.min_distance && form.touched.min_distance)
        ) {
            setAdvancedExpanded(true);
        }
    }, [form.errors, form.submitCount]);

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Analyze</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            checked={values.include_all_samples}
                            onChange={() => {
                                setFieldValue('include_all_samples', true);
                                setFieldValue('variables', []);
                                setFieldValue('group_ids', []);
                                form.setFieldTouched('variables', false);
                                form.setFieldTouched('group_ids', false);
                            }}
                        />
                        <span className="ml-2">
                            All samples {isDefined(totalSampleCount) && <span>({totalSampleCount})</span>}
                        </span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            onChange={() => {
                                setFieldValue('include_all_samples', false);
                            }}
                            checked={!values.include_all_samples}
                        />
                        <span className="ml-2">Specific groups of samples</span>
                    </label>
                </div>
                {!values.include_all_samples && (
                    <div className="mt-4">
                        <AnalysisVariablesMultiSelectField
                            variables={(analysisParameters as BaseAnalysisParameters).variables}
                        />
                        <AnalysisGroupsField plot={plot} experiment={experiment} groupsName="group_ids" />
                    </div>
                )}
            </section>

            <h4
                className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                onClick={() => setAdvancedExpanded(!advancedExpanded)}
            >
                <span>Advanced settings</span>
                <span>
                    <ChevronRightIcon
                        width={20}
                        className={cn(
                            'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                            { 'rotate-90': advancedExpanded },
                        )}
                    />
                </span>
            </h4>
            <Collapse className="" in={advancedExpanded}>
                <div className="space-y-4">
                    <TextInputField
                        name="n_neighbors"
                        type="number"
                        step={1}
                        max={maxSampleCount}
                        min={0}
                        label="Number of neighbors"
                        hint={`Maximum of ${maxSampleCount}`}
                        validateOnBlur
                    />

                    <TextInputField
                        name="min_distance"
                        label="Minimum point distance"
                        type="number"
                        min={0}
                        step={0.1}
                        validateOnBlur
                    />
                </div>
            </Collapse>
        </div>
    );
};

export default UMAPAnalysisFormFields;
