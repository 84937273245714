export type GeneSetCollectionShortname =
    | 'h'
    | 'c1'
    | 'c2__cgp'
    | 'c2__cp_biocarta'
    | 'c2__cp_kegg'
    | 'c2__cp_pid'
    | 'c2__cp_reactome'
    | 'c2__cp_wikipathways'
    | 'c3__mir_mir_legacy'
    | 'c3__mir_mirdb'
    | 'c3__tft_gtrd'
    | 'c3__tft_tft_legacy'
    | 'c4__cgn'
    | 'c4__cm'
    | 'c5__go_bp'
    | 'c5__go_cc'
    | 'c5__go_mf'
    | 'c5__hpo'
    | 'c6'
    | 'c7__immunesigdb'
    | 'c7__vax'
    | 'c8'
    | 'panglao_db'
    | 'panglao_dbplus';

export default interface GeneSetCollection {
    id: number;
    shortname: GeneSetCollectionShortname;
    url: string | null;
    number_gene_sets: number;
    display_name: string;
    description: string | null;
}

export const getShortDisplayName = (collection: Pick<GeneSetCollection, 'display_name'>): string => {
    return collection.display_name.replace(/:(.*?)-/, ':');
};
