import React from 'react';
import SimpleSelectField, { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import { useFormikContext } from 'formik';
import { LegendLocationSelectOptions, LegendLocationConfig } from '@models/PlotConfigs';

type Props = {
    placeholder?: string;
    items?: ChoiceItem[];
};
const LegendLocationSelectField = ({ placeholder = 'Select legend location', items }: Props) => {
    const { values, handleChange } = useFormikContext<LegendLocationConfig>();

    return (
        <div className="form-field">
            <SimpleSelectField
                name="legend_location"
                label="Legend location"
                placeholder={placeholder}
                value={values.legend_location}
                onChange={handleChange}
                items={items || LegendLocationSelectOptions}
            />
        </div>
    );
};

export default LegendLocationSelectField;
