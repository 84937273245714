import Endpoints from '@services/Endpoints';
import Experiment from '@models/Experiment';
import useApi from '@hooks/useApi';
import { useSWRConfig } from 'swr';
import { useState } from 'react';
import Logger from '@util/Logger';
import useMatchMutate from '@hooks/useMatchMutate';
import { PaginationResponse } from '../services/EndpointUtil';
import { DashboardExperimentsArgs } from '@hooks/useDashboard';

const logger = Logger.make('hooks/useArchiveExperiment');
const useArchiveExperiment = (experiment: Experiment) => {
    const { post, doDelete } = useApi();
    const { mutate } = useSWRConfig();
    const { pathEqualsIgnoreQueryMutate, regexMutate } = useMatchMutate();
    const [archived, setArchived] = useState(experiment?.is_archived);
    const archiveExperiment = async () => {
        try {
            const archiveUrl = Endpoints.lab.experiment.archive({ experimentId: experiment.uuid });
            await post(archiveUrl);
            await mutate(Endpoints.lab.experiment.base(experiment.uuid), { ...experiment, archived: true });

            const mutator = async (current: PaginationResponse<Experiment> | undefined) => {
                if (!current) {
                    return current;
                }
                const items = (current?.items ?? []).map((exp) => {
                    return { ...exp, is_archived: exp.uuid === experiment.uuid ? true : exp.is_archived };
                });

                return { ...current, items };
            };

            const promises: Promise<any>[] = [
                pathEqualsIgnoreQueryMutate<PaginationResponse<Experiment>>(Endpoints.lab.experiments(), mutator, {
                    revalidate: true,
                    populateCache: true,
                }),
                // Update cache for lab space experiments
                mutate<PaginationResponse<Experiment>>(Endpoints.lab.experiments(DashboardExperimentsArgs), mutator),
                regexMutate<PaginationResponse<Experiment>>(/\/projects\/.*\/experiments/, mutator, {
                    revalidate: false,
                    populateCache: true,
                }),
            ];
            if (experiment?.project?.uuid) {
                promises.push(
                    pathEqualsIgnoreQueryMutate<PaginationResponse<Experiment>>(
                        Endpoints.lab.project.experiments({ projectId: experiment.project.uuid }),
                        mutator,
                        {
                            revalidate: true,
                            populateCache: true,
                        },
                    ),
                );
            }

            await Promise.all(promises);

            setArchived(true);
        } catch (error) {
            logger.error(error);
            setArchived(false);
        }
    };

    const unArchiveExperiment = async () => {
        try {
            const archiveUrl = Endpoints.lab.experiment.archive({ experimentId: experiment.uuid });
            await doDelete(archiveUrl);
            await mutate(Endpoints.lab.experiment.base(experiment.uuid), { ...experiment, archived: false });
            setArchived(false);
        } catch (error) {
            logger.error(error);
            setArchived(false);
        }
    };

    return { archiveExperiment, unArchiveExperiment, archived };
};
export default useArchiveExperiment;
