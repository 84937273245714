import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const RidgePlotIcon = ({ height = 41, width = 40, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
            fill="currentColor"
            viewBox="0 0 41 40"
        >
            <path
                d="M39.6685 26.7846L1.88741 26.6514C1.72287 26.6508 1.68596 26.4202 1.84211 26.3683C1.84211 26.3683 7.17073 24.4821 9.42105 22.9999C11.9501 21.3341 13.2105 18.3683 14.0526 15.842C14.0526 15.842 16.5789 6.99986 19.3471 7C21.3618 7.0001 22.8947 10.7893 22.8947 10.7893L25.0806 15.5142C25.0806 15.5142 27.0627 21.3946 29.3807 22.7186C29.8302 22.9753 30.2445 23.2039 30.6263 23.4071C32.7366 24.5304 37.4907 25.5496 39.7368 26.3683C39.9672 26.4451 39.9113 26.7855 39.6685 26.7846Z"
                fill="currentColor"
                fillOpacity="0.5"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M37.9797 33.9192H7.83536C7.48901 33.9192 6.74372 33.9651 6.99477 33.7265C7.04923 33.6747 7.14979 33.6092 7.3158 33.5262C7.79823 33.285 12.8848 32.3305 14.0526 30.5788C15.7368 28.0525 18.6929 18.0646 19.1053 17.5262C19.4127 17.1249 19.7203 16.9149 19.7789 16.8906C19.8374 16.8663 20.132 16.7247 20.9968 16.8906C21.5465 16.9961 21.9139 17.1051 22.4737 17.1051C22.9215 17.1051 23.2067 17.0736 23.6473 16.8906C23.6473 16.8906 24.1879 16.5846 24.5073 16.2357C24.8714 15.8378 25.0582 15.5189 25.3673 14.9258C25.6098 14.4605 25.7168 14.1274 25.9407 13.6159C26.2765 12.8486 26.6662 10.3683 27.9474 10.3683C29.6316 10.3683 32.579 29.3156 32.579 29.3156C33 30.5788 32.8567 30.4664 34.6842 31.842C35.2704 32.2832 37.4899 33.2999 38.0526 33.5262C38.6179 33.7534 38.5889 33.9192 37.9797 33.9192Z"
                stroke="currentColor"
                fill="transparent"
                strokeWidth="2"
            />
        </svg>
    );
};
