import { FormikErrors, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FilterSeuratObjectFormValues } from '../PreprocessFormTypes';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FormControl, MenuItem, Select, Collapse, SelectChangeEvent } from '@mui/material';
import CheckboxField from '@components/forms/CheckboxField';
import TextInputField from '@components/forms/TextInputField';
import Picker from '@components/forms/Picker';
import { blankToUndefined } from '@util/StringUtil';
import Bold from '@components/elements/Bold';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import WarningRounded from '@mui/icons-material/WarningRounded';
import DisclosureAccordion from '@/src/components/DisclosureAccordion';

type Props = {
    readOnly: boolean;
};
const FilterSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue, errors } = useFormikContext<FilterSeuratObjectFormValues>();
    const [selectedSampleIndex, setSelectedSampleIndex] = useState<number>(0);
    const [umisExpanded, setUmisExpanded] = useState(false);
    const [featuresExpanded, setFeaturesExpanded] = useState(false);
    const [complexityExpanded, setComplexityExpanded] = useState(false);
    const [mitoExpanded, setMitoExpanded] = useState(false);
    const [otherExpanded, setOtherExpanded] = useState(false);

    const updateFieldValue = (value: string | number, fieldName: string) => {
        if (values.filter_all_samples) {
            values.sample_configs.map((_, index) => {
                setFieldValue(`sample_configs[${index}].${fieldName}`, blankToUndefined(value) ? Number(value) : 0);
            });
        } else {
            setFieldValue(
                `sample_configs[${selectedSampleIndex}].${fieldName}`,
                blankToUndefined(value) ? Number(value) : 0,
            );
        }
    };

    const getNestedError = (fieldName: string) => {
        const sampleConfigErrors = errors.sample_configs as FormikErrors<{
            sample_id: string;
            min_count: number;
            max_count: number;
            min_feature: number;
            max_feature: number;
            min_ns: number;
            max_mito: number;
        }>[];
        if (values.filter_all_samples && errors.sample_configs) {
            const errorObject = sampleConfigErrors.find((configError) => {
                if (configError && configError[fieldName]) {
                    return true;
                }
                return false;
            });
            return errorObject ? errorObject[fieldName] : '';
        }
        if (!values.filter_all_samples && errors.sample_configs) {
            const errorObject = sampleConfigErrors[selectedSampleIndex];
            if (errorObject && errorObject[fieldName]) {
                return errorObject[fieldName];
            }
        }
        return '';
    };

    return (
        <div className="space-y-8">
            <section>
                <DisclosureAccordion
                    title="Important preprocess info"
                    Icon={<WarningRounded className="w-4 text-amber-500" />}
                >
                    <p>
                        The QC plots are generated using the <span className="font-semibold">unfiltered</span> data. The
                        lines in the QC plots represent the filtering thresholds selected for the different filtering
                        categories. Thus, the QC plots offer a visual representation of what cells will be included in
                        and excluded from the data moving forward. Note that if you elect to remove any samples from
                        your data that this will <span className="font-semibold">not</span> be reflected in the QC
                        plots.
                    </p>
                </DisclosureAccordion>
            </section>
            <section>
                <p className="field-label !mb-0">Sample(s) to remove (optional)</p>
                <Picker
                    disabled={readOnly}
                    name="rm_samples"
                    noOptionsText="No samples found"
                    options={values.sample_configs.map((sample) => sample.sample_id)}
                    placeholder="Select sample(s) for removal..."
                    itemName="sample"
                />
            </section>
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Filtering Strategy</p>
                <div className="space-y-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="filter_all"
                            checked={values.filter_all_samples}
                            onChange={() => {
                                setFieldValue(`rm_samples`, []);
                                setFieldValue(`filter_all_samples`, !values.filter_all_samples);
                            }}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Apply same filtering strategy to all samples</span>
                    </label>
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="filter_all"
                            onChange={() => {
                                setFieldValue(`filter_all_samples`, !values.filter_all_samples);
                            }}
                            checked={!values.filter_all_samples}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Independently define filtering for each sample</span>
                    </label>
                </div>
            </section>
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Thresholds</p>
                <p className="mb-1 font-semibold">Editing filtering strategy for</p>
                {values.filter_all_samples ? (
                    'All samples'
                ) : (
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            margin="dense"
                            name="samples"
                            value={selectedSampleIndex}
                            onChange={(e: SelectChangeEvent<number>) => setSelectedSampleIndex(Number(e.target.value))}
                            disabled={readOnly}
                        >
                            {values.sample_configs.map((sample, index) => (
                                <MenuItem key={`sample_${sample.sample_id}`} value={index}>
                                    {sample.sample_id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </section>
            <section className="space-y-3">
                <section>
                    <h4
                        className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                        onClick={() => setUmisExpanded(!umisExpanded)}
                    >
                        <span>UMIs</span>
                        <span>
                            <ChevronRightIcon
                                width={20}
                                className={cn(
                                    'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                    { 'rotate-90': umisExpanded },
                                )}
                            />
                        </span>
                    </h4>
                    <Collapse in={umisExpanded}>
                        <div className="space-y-6">
                            <TextInputField
                                name="min_count"
                                label="Minimum number of UMIs per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].min_count}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'min_count')}
                                fixedDecimalLength={0}
                                allowNegativeValue={false}
                                step={1}
                                min={0}
                                useNumericFormat
                            />
                            <TextInputField
                                name="max_count"
                                label="Maximum number of UMIs per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].max_count}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'max_count')}
                                fixedDecimalLength={0}
                                allowNegativeValue={false}
                                step={1}
                                min={0}
                                useNumericFormat
                            />
                        </div>
                    </Collapse>
                </section>
                <section>
                    <h4
                        className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                        onClick={() => setFeaturesExpanded(!featuresExpanded)}
                    >
                        <span>Features</span>
                        <span>
                            <ChevronRightIcon
                                width={20}
                                className={cn(
                                    'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                    { 'rotate-90': featuresExpanded },
                                )}
                            />
                        </span>
                    </h4>
                    <Collapse in={featuresExpanded}>
                        <div className="space-y-6">
                            <TextInputField
                                name="min_feature"
                                label="Minimum number of features per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].min_feature}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'min_feature')}
                                fixedDecimalLength={0}
                                allowNegativeValue={false}
                                step={1}
                                min={0}
                                useNumericFormat
                            />
                            <TextInputField
                                name="max_feature"
                                label="Maximum number of features per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].max_feature}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'max_feature')}
                                fixedDecimalLength={0}
                                allowNegativeValue={false}
                                step={1}
                                min={0}
                                useNumericFormat
                            />
                        </div>
                    </Collapse>
                </section>
                <section>
                    <h4
                        className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                        onClick={() => setComplexityExpanded(!complexityExpanded)}
                    >
                        <span>Complexity</span>
                        <span>
                            <ChevronRightIcon
                                width={20}
                                className={cn(
                                    'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                    { 'rotate-90': complexityExpanded },
                                )}
                            />
                        </span>
                    </h4>
                    <Collapse in={complexityExpanded}>
                        <div className="space-y-6">
                            <TextInputField
                                name="min_ns"
                                label="Minimum novelty score per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].min_ns}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'min_ns')}
                                decimalsLimit={1}
                                allowNegativeValue={false}
                                step={0.1}
                                min={0}
                                max={1}
                                useNumericFormat
                            />
                        </div>
                    </Collapse>
                </section>
                <section>
                    <h4
                        className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                        onClick={() => setMitoExpanded(!mitoExpanded)}
                    >
                        <span>Mitochondrial</span>
                        <span>
                            <ChevronRightIcon
                                width={20}
                                className={cn(
                                    'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                    { 'rotate-90': mitoExpanded },
                                )}
                            />
                        </span>
                    </h4>
                    <Collapse in={mitoExpanded}>
                        <div className="space-y-6">
                            <TextInputField
                                name="max_mito"
                                label="Maximum % mito UMIs per cell"
                                type="number"
                                value={values.sample_configs[selectedSampleIndex].max_mito}
                                disabled={readOnly}
                                onValueChange={(_, __, values) => updateFieldValue(values?.float ?? 0, 'max_mito')}
                                fixedDecimalLength={0}
                                allowNegativeValue={false}
                                step={1}
                                min={0}
                                max={100}
                                suffix="%"
                                useNumericFormat
                                error={getNestedError('max_mito')}
                            />
                        </div>
                    </Collapse>
                </section>
                <section>
                    <h4
                        className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                        onClick={() => setOtherExpanded(!otherExpanded)}
                    >
                        <span>Other</span>
                        <span>
                            <ChevronRightIcon
                                width={20}
                                className={cn(
                                    'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                    { 'rotate-90': otherExpanded },
                                )}
                            />
                        </span>
                    </h4>
                    <Collapse in={otherExpanded}>
                        <div className="space-y-3">
                            <CheckboxField
                                name="rm_doublets"
                                className="flex cursor-pointer items-start"
                                offsetCheckbox={false}
                                checkboxClassName="mt-1"
                                label={
                                    <div>
                                        <Bold className="block">Remove doublets</Bold>
                                        <p>Remove doublets from Seurat object</p>
                                    </div>
                                }
                                disabled={readOnly}
                            />
                        </div>
                    </Collapse>
                </section>
            </section>
        </div>
    );
};

export default FilterSeuratObjectFormFields;
