import Image from 'next/image';
import { usePlotContext } from '@contexts/PlotContext';
import React, { useState } from 'react';
import { PlotPipelinePlotData } from '@models/ExperimentData';
import Logger from '@util/Logger';
import { Alert } from '@mui/material';
import LoadingMessage from '@components/LoadingMessage';

const logger = Logger.make('ServerImagePlotView');

/**
 * This component is to render a heatmap that was server-side generated as an image.
 * @constructor
 */
const ServerImagePlotView = () => {
    const { plot, plotData } = usePlotContext();
    const [pngError, setPngError] = useState<boolean>(false);
    const data = plotData as PlotPipelinePlotData | null | undefined;
    const pngs = data?.plots?.images?.png;
    const pngUrl = plot?.display?.is_full_width ? pngs?.wide : pngs?.square ?? pngs?.auto;
    const display = plot?.display;
    const analysis = plot?.analysis;

    if (plotData?.plot_status === 'in_progress') {
        return (
            <div className="flex h-full items-center justify-center">
                <LoadingMessage className="">
                    <p className="text-center">
                        The plot is rendering. This may take a few minutes. It&apos;s safe to navigate away from this
                        page in the meantime.
                    </p>
                </LoadingMessage>
            </div>
        );
    }

    if (!pngUrl || plotData?.plot_status === 'failed') {
        return (
            <div className="py-4">
                <Alert severity="error">Unable to render the plot due to a server error.</Alert>
            </div>
        );
    }
    return (
        <>
            <div className="h-full w-full">
                <div className="group relative mx-auto h-full w-full">
                    {pngError && (
                        <div>
                            <Alert severity="error">The image failed to load.</Alert>
                        </div>
                    )}
                    <Image
                        src={pngUrl}
                        alt={display?.plot_title ?? analysis?.name ?? 'Plot image'}
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                        loading="eager"
                        onLoad={() => setPngError(false)}
                        onError={(error) => {
                            logger.error(`Image failed to load for analysis_id=${analysis?.uuid}`, pngUrl, error);
                            setPngError(true);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default ServerImagePlotView;
