import { ParameterOption } from '@models/AnalysisParameters';

export enum TargetType {
    target = 'target',
    variable = 'variable',
    group = 'group',
}

export interface ComparisonItem<T extends TargetType> {
    type: T;
    id: number;
    display_name: string;
    selected: boolean;
}

export type GroupItem = ComparisonItem<TargetType.group>;

export const RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD = 2;
export const SEURAT_GROUP_CELL_COUNT_LIMIT = 3;

export interface ComparisonGroupConfig<ITEM = GroupItem> {
    selected_variables: number[];
    items: ITEM[];
}

export const getGroupItems = <T extends ParameterOption>(
    groups: ComparisonGroupConfig<T>[],
    variables: number[],
): ComparisonGroupConfig<T> | null => {
    return (
        groups.find((group) => {
            return (
                group.selected_variables.length === variables.length &&
                (group.selected_variables ?? []).every((v) => variables.includes(v))
            );
        }) ?? null
    );
};
