export const HumanIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 31 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.726 12.375a6.187 6.187 0 100-12.375 6.187 6.187 0 000 12.375z" fill="currentColor" />
        <path
            d="M11.636 64a2.471 2.471 0 01-2.468-2.469v-40a.391.391 0 00-.357-.39l-.035-.002a.392.392 0 00-.385.322c-.618 3.406-2.003 12.022-2.484 15.055a2.452 2.452 0 01-2.825 2.05 2.472 2.472 0 01-2.051-2.825c.232-1.463 2.285-14.357 2.767-16.52 1.126-5.07 5.52-5.07 8.144-5.07h7.57c2.626 0 7.018 0 8.144 5.07.502 2.256 2.745 16.377 2.768 16.52a2.45 2.45 0 01-.44 1.837 2.456 2.456 0 01-2.004 1.019 2.454 2.454 0 01-2.434-2.08c-.481-3.028-1.865-11.634-2.484-15.054a.391.391 0 00-.777.07v40A2.471 2.471 0 0119.817 64a2.471 2.471 0 01-2.468-2.468V41.504a.392.392 0 00-.392-.392h-2.46a.392.392 0 00-.392.392V61.53A2.472 2.472 0 0111.635 64z"
            fill="currentColor"
            stroke="currentColor"
            strokeMiterlimit="10"
        />
    </svg>
);
