import React, { useMemo } from 'react';
import RadioField, { RadioChoice } from '@components/experiments/plotDisplay/fields/RadioField';

type Props = {
    name?: string;
    label?: string;
    disableNone?: boolean;
};
const SummarizeValuesField = ({
    name = 'summarize_values_by',
    label = 'Summarize values by',
    disableNone = false,
}: Props) => {
    const choices = useMemo(() => {
        const options: RadioChoice[] = [
            {
                label: 'Group average',
                value: 'mean',
            },
            {
                label: 'Group median',
                value: 'median',
            },
            {
                label: 'None (sample-level)',
                value: 'none',
                disabled: disableNone,
            },
        ];

        return options;
    }, [disableNone]);

    return <RadioField name={name} choices={choices} label={label} />;
};

export default SummarizeValuesField;
