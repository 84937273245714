import { SurvivalData } from '@models/ExperimentData';
import { isDefined } from '@util/TypeGuards';
import PlotNoDataView from '@components/plots/PlotNoDataView';
import KaplanMeierCurvePlotView from '@components/analysisCategories/clinicalOutcomes/plots/KaplanMeierCurvePlotView';
import React from 'react';
import { usePlotContext } from '@contexts/PlotContext';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = { customPlotStylingOptions?: CustomPlotStylingOptions | null };

const ClinicalOutcomesPlotDisplayView = ({ customPlotStylingOptions }: Props) => {
    const { plot, plotData } = usePlotContext();

    const data = plotData as SurvivalData | null;
    if (!data) {
        return null;
    }
    if (data.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }
    if (isDefined(data.items) && data.items.length === 0) {
        return <PlotNoDataView />;
    }

    switch (plot.display.display_type) {
        case 'kaplan_meier_curve':
            return <KaplanMeierCurvePlotView customPlotStylingOptions={customPlotStylingOptions} />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default ClinicalOutcomesPlotDisplayView;
