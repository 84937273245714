import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { ClusterSeuratObjectFormValues, PreprocessFormValues } from '../../preprocesses/PreprocessFormTypes';
import CodeBlockInline from '@components/CodeBlockInline';
import ProseList from '@components/ProseList';
import { formatStringToNumberWithSeparator } from '@/src/util/StringUtil';
import { StatTestSelectOptions } from '@/src/models/preprocesses/ClusterSeuratObject';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string };
const ClusterSeuratMethods = ({ preprocessFormData, displayName }: Props) => {
    const preprocess = preprocessFormData as ClusterSeuratObjectFormValues;
    const prettyStatTestName = StatTestSelectOptions.find((option) => option.value === preprocess.stat_test)?.label;

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        To cluster the data, the K-nearest neighbor graph was first computed using the{' '}
                        <CodeBlockInline>FindNeighbors()</CodeBlockInline> function, with k ={' '}
                        {formatStringToNumberWithSeparator(preprocess.k_param)} and the first{' '}
                        {formatStringToNumberWithSeparator(preprocess.n_dims)} dimensions of the reduction. Clusters
                        were identified using the <CodeBlockInline>FindClusters()</CodeBlockInline> function for the
                        following resolution(s):{' '}
                        <ProseList items={preprocess?.resolutions?.map((res) => Number(res).toFixed(1)) ?? []} />.
                    </p>

                    <div>
                        <MethodsSectionTitle>Marker gene identification</MethodsSectionTitle>
                        <p>
                            Marker genes are genes that are differentially and specifically expressed in particular cell
                            types or clusters compared to all other cell types or clusters in a dataset. Marker genes
                            for each cluster in each resolution were identified using the{' '}
                            {preprocess.stat_test !== 'wilcox' ? (
                                <CodeBlockInline>FindAllMarkers()</CodeBlockInline>
                            ) : (
                                <CodeBlockInline>RunPrestoAll()</CodeBlockInline>
                            )}{' '}
                            function, with the {prettyStatTestName} and a log2 fold change threshold of{' '}
                            {preprocess.log2fc}.{' '}
                            {preprocess.only_pos && 'Only positive marker genes for each cluster were returned.'}
                        </p>
                    </div>

                    <div>
                        <MethodsSectionTitle>Dot plot of top marker genes</MethodsSectionTitle>
                        <p>
                            For the dot plot, the top 3 marker genes for each cluster were identified by sorting the
                            marker genes for each cluster first by a gene&apos;s adjusted p-value, and then by
                            {!preprocess.only_pos && ' the absolute value of'} a gene&apos;s average log2 fold change.
                        </p>
                    </div>

                    <div>
                        <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                        <p>
                            R v4.3.1
                            <br />
                            Seurat v4.3.0
                            <br />
                            {preprocess.stat_test === 'wilcox' ? (
                                <>
                                    SeuratWrappers v0.3.1
                                    <br />
                                    Presto v1.0.0
                                </>
                            ) : null}
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ClusterSeuratMethods;
