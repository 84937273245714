import React, { ReactNode } from 'react';
import { LegendSVG } from '@components/plots/PlotLegendView';
import { isDefined } from '@util/TypeGuards';
import cn from 'classnames';
import { isNotBlank, isWhite } from '@util/StringUtil';
import { PencilIcon } from '@heroicons/react/outline';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues, PlotLegendDisplayFormValues } from '@models/PlotDisplayOption';
import useAuth from '@hooks/useAuth';
import { ThemeStyle } from '@models/PlotConfigs';
import LoadingMessage from '@components/LoadingMessage';
import { OtherIcon } from '@components/icons/custom/OtherIcon';
import { CustomizablePlotChartDisplayOption } from '@/src/models/plotDisplayOption/BasePlotDisplayOption';
import GroupColorPickerModal from '@/src/components/plots/colorPicker/GroupColorPickerModal';

export type CustomLegendColorItem = {
    id: string;
    label: string | ReactNode;
    themeColor: string;
    lineThemeColor?: string;
    labelName?: string;
};
export type CustomTraceColorItem = {
    name: string;
    marker: {
        color: string;
        line: {
            color: string;
        };
    };
};
type Props = {
    bottomOffsetClassname?: string;
    editable?: boolean;
    fallbackColor?: string;
    hideLabel?: boolean;
    hideTheme?: boolean;
    items: CustomLegendColorItem[];
    leftOffsetClassName?: string;
    loading?: boolean;
    onChange?: (color: string | undefined | null) => void;
    value: string | null | undefined;
};
const ControlledCustomLegendColorField = ({
    bottomOffsetClassname,
    editable = false,
    fallbackColor,
    hideLabel = false,
    hideTheme = false,
    items,
    leftOffsetClassName,
    loading = false,
    onChange,
    value,
}: Props) => {
    const { values } =
        useFormikContext<
            Partial<PlotLegendDisplayFormValues & BasePlotDisplayOptionFormValues & CustomizablePlotChartDisplayOption>
        >();
    const { user } = useAuth();
    const userColors = user?.custom_plot_colors ?? [];
    const orgColors = user?.organization?.custom_plot_colors ?? [];

    const setCustomColor = ({
        color,
    }: {
        color: string | undefined | null;
        initialColor?: string | undefined | null;
    }) => {
        if (onChange) {
            onChange(color);
        }
    };

    if (loading) {
        return (
            <div>
                <LoadingMessage inline immediate message="Loading..." />
            </div>
        );
    }

    return (
        <div>
            {items.map((item) => {
                const defaultColor = fallbackColor ?? item.themeColor;
                const legendFillColor = value ?? defaultColor;
                return (
                    <li
                        key={item.id}
                        className="-mx-2 flex items-center justify-center space-x-1 rounded-lg px-2 py-1 hover:bg-gray-100 hover:text-dark"
                    >
                        <div className="flex items-center space-x-1">
                            <GroupColorPickerModal
                                title={item.label}
                                labelName={item.labelName}
                                color={legendFillColor}
                                onChange={(newColor) => setCustomColor({ color: newColor })}
                                showRemove={isDefined(value)}
                                onRemove={() => {
                                    setCustomColor({
                                        color: null,
                                        initialColor: defaultColor,
                                    });
                                }}
                                customColors={userColors}
                                customColorsLabel="My favorite colors"
                                secondaryCustomColors={orgColors}
                                secondaryCustomColorsLabel="Organization colors"
                                hideThemes
                                themeColor={hideTheme ? undefined : values.theme_color}
                                bottomOffsetClassname={bottomOffsetClassname}
                                leftOffsetClassName={leftOffsetClassName}
                            >
                                <div className="group relative mt-0.5 flex cursor-pointer items-center justify-center">
                                    <LegendSVG
                                        radius={4}
                                        width={26}
                                        style={{
                                            fill: legendFillColor,
                                            fillOpacity: values.theme_style === ThemeStyle.medium ? 0.75 : 1,
                                            stroke: isWhite(legendFillColor) ? 'rgb(209, 213, 219)' : legendFillColor,
                                            strokeOpacity: 1,
                                        }}
                                        className={cn('shrink-0 transition-all duration-500')}
                                    />
                                    {isNotBlank(value) && (
                                        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center group-hover:hidden">
                                            <OtherIcon className="text-white" width={14} height={14} />
                                        </div>
                                    )}

                                    <div className="absolute bottom-0 left-0 right-0 top-0 hidden items-center justify-center transition group-hover:flex">
                                        <PencilIcon className="h-4 w-4 text-white" strokeWidth="2.5" />
                                    </div>
                                </div>
                            </GroupColorPickerModal>
                            {!editable && !hideLabel && <span>{item.label}</span>}
                        </div>
                    </li>
                );
            })}
        </div>
    );
};

export default ControlledCustomLegendColorField;
