import { IconProps } from '@components/icons/CustomIcons';

export const BMIIcon = (props?: IconProps) => {
    const { width = 18, height = 18 } = { ...props };
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.75 2.25h-.913c.577.994.913 2.145.913 3.375A6.758 6.758 0 019 12.375a6.758 6.758 0 01-6.75-6.75c0-1.23.336-2.38.913-3.375H2.25C1.01 2.25 0 3.26 0 4.5v11.25C0 16.99 1.01 18 2.25 18h13.5c1.24 0 2.25-1.01 2.25-2.25V4.5c0-1.24-1.01-2.25-2.25-2.25zm-6.75 9A5.625 5.625 0 109 0a5.625 5.625 0 000 11.25zm-.01-5.342l1.18-2.754a.562.562 0 111.035.442l-1.184 2.762c.235.251.385.584.385.955A1.406 1.406 0 118.99 5.907z"
                fill="currentColor"
            />
        </svg>
    );
};
