import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';

export type Props = {
    open?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    confirmLoading?: boolean;
    icon?: ReactNode;
    error?: ReactNode;
};
const AcceptWorkflowModal = ({ open = false, onCancel, onConfirm, confirmLoading = false, icon, error }: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const { selectedWorkflow } = useExperimentWorkflowContext();

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle iconStart={icon} iconEnd={icon} title="Are you sure?" />
            <DialogContent className="mb-4 space-y-4">
                <p className="text-base tracking-tight">
                    Once a workflow has been selected, you will no longer be able to modify the preprocessing steps
                    applied this experiment. All workflows you have created will remain viewable on this page.
                </p>
                <h2 className="text-xl font-semibold tracking-tight">Confirm selected preprocessing workflow</h2>
                <p className="!mt-1 text-base tracking-tight">
                    You have selected the preprocessing workflow: <b>{selectedWorkflow?.name}</b> to use for your
                    downstream analysis. Are you ready to proceed to analysis?
                </p>
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button
                    variant={isSmAndDown ? 'outlined' : 'text'}
                    color="primary"
                    onClick={onCancel}
                    fullWidth={isSmAndDown}
                >
                    No, take me back
                </Button>
                <Button
                    color="primary"
                    onClick={onConfirm}
                    variant="contained"
                    loading={confirmLoading}
                    disabled={confirmLoading}
                >
                    Yes, complete preprocessing
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AcceptWorkflowModal;
