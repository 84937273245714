import useSWR from 'swr';
import { ExperimentData } from '@models/ExperimentData';
import DataTable from '@components/dataTable/DataTable';
import Endpoints, { DEFAULT_PAGE_LIMIT } from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import Experiment from '@models/Experiment';
import { generateSampleDataFileName } from '@util/ExperimentUtil';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { WizardStepQueryParam } from '@components/experiments/wizard/ExperimentWizardUtils';
import DownloadDataMoreMenuItem from '@components/DownloadDataMoreMenuItem';
import MoreMenuIconButton, { MoreMenuItem } from '@components/MoreMenuIconButton';
import React, { useEffect, useMemo, useState } from 'react';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import Button from '@components/Button';
import { EditIcon } from '@components/icons/custom/EditIcon';
import useExperimentFiles from '@hooks/useExperimentFiles';
import { combinedCellRenderer } from '@components/dataTable/cellRenderers';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import useAuth from '@hooks/useAuth';

export type SampleDataTableViewProps = { experiment: Experiment; viewDataOnly?: boolean };
const SampleDataTableView = ({ experiment, viewDataOnly }: SampleDataTableViewProps) => {
    const { user } = useAuth();
    const isStaff = user?.is_staff || false;
    const experimentId = experiment.uuid;
    const permissions = useExperimentPermissions(experiment);
    const { setEditDataInterstitialOpen } = useExperimentDetailViewContext();
    // Get fastq files to match up with sample data
    const { data: experimentFiles, loading: fastqFilesLoading } = useExperimentFiles({
        experiment,
        data_type: 'fastq',
    });

    const fastqFileNames = (experimentFiles?.['fastq']?.items ?? []).map((file) => file.filename);

    const [refreshInterval, setRefreshInterval] = useState<number | undefined>(undefined);

    const { data, error } = useSWR<ExperimentData<Record<string, string | number | null>>>(
        () => Endpoints.lab.experiment.sampleData({ experimentId }, { limit: DEFAULT_PAGE_LIMIT }),
        { refreshInterval },
    );

    const firstColumn = data?.headers[0];
    useEffect(() => {
        if (
            (experiment.sample_data_uploaded || ['draft', 'pending'].includes(data?.status ?? '')) &&
            (data?.count ?? 0) === 0
        ) {
            setRefreshInterval(2000);
        } else {
            setRefreshInterval(undefined);
        }
    }, [experiment, data]);

    const { menuItems } = useMemo(() => {
        const downloadEndpoint = (filename: string) =>
            Endpoints.lab.experiment.downloadSampleData({ experimentId }, { filename: filename });
        const downloadFilename = generateSampleDataFileName({ experiment });
        const menuItems: MoreMenuItem[] = [
            <DownloadDataMoreMenuItem
                key={'download_csv'}
                endpoint={downloadEndpoint}
                baseFilename={downloadFilename}
                extension=".csv"
            />,
        ];

        return { menuItems };
    }, [experiment, permissions]);

    const loading = !data && !error;
    const hasItems = (data?.items ?? []).length > 0;

    const r1FieldName = data?.headers.find((h) => h.toLowerCase().trim() === 'r1_fastq') ?? 'r1_fastq';
    const r2FieldName = data?.headers.find((h) => h.toLowerCase().trim() === 'r2_fastq') ?? 'r2_fastq';
    const hasR1 = data?.headers?.map((h) => h.toLowerCase()).includes('r1_fastq');
    const enhancedItems = useMemo(() => {
        if (!hasR1 || fastqFilesLoading) {
            return data?.items;
        } else {
            return data?.items.map((sample) => {
                const r1 = sample[r1FieldName] as string | null;
                const r2 = sample[r2FieldName] as string | null;
                const missing_r1 = !fastqFileNames.includes(r1 ?? '');
                const missing_r2 = !fastqFileNames.includes(r2 ?? '');
                // logger.debug(`sample_id: ${sample.Sample_ID}`, { missing_r1, missing_r2 });
                return { ...sample, missing_r1, missing_r2, merged_replicates: experiment.merged_replicates };
            });
        }
    }, [hasR1, data, fastqFileNames, fastqFilesLoading]);

    if (loading) {
        return <LoadingMessage message="Loading sample data..." />;
    }

    if (
        experiment.status === 'copying' &&
        (!data || !hasItems || data.status === 'draft' || data.status === 'pending')
    ) {
        return (
            <div className="text-center">
                <p>Your data will appear once the experiment has finished copying</p>
            </div>
        );
    }

    if (!data || !hasItems) {
        return (
            <div className="text-center">
                <p>Sample data table has not been uploaded yet</p>
                <div>
                    <Button
                        href={`/experiments/${experiment.pluto_id}/edit?step=${WizardStepQueryParam.SAMPLE_UPLOAD}`}
                        variant="text"
                        color="primary"
                    >
                        Add sample data
                    </Button>
                </div>
            </div>
        );
    }

    if (permissions.canEdit) {
        const enabled =
            !experiment?.pipeline_run || experiment.pipeline_run?.pipeline_status?.status !== 'completed' || isStaff;
        menuItems.unshift({
            label: 'Edit sample data',
            icon: <EditIcon width={14} height={14} />,
            href: !enabled
                ? undefined
                : appendQueryParams(`/experiments/${experiment.pluto_id}/edit`, {
                      [QueryParam.STEP]: WizardStepQueryParam.SAMPLE_UPLOAD,
                  }),
            onClick: enabled
                ? undefined
                : () => {
                      setEditDataInterstitialOpen(true);
                  },
        });
    }

    return (
        <>
            <DataTable
                data={{ ...data, items: enhancedItems ?? [] }}
                sortable
                sortBy={firstColumn}
                filterable
                moreMenuButton={<MoreMenuIconButton outlined items={menuItems} />}
                cellRenderer={combinedCellRenderer()}
                viewDataOnly={viewDataOnly}
            />
        </>
    );
};

export default SampleDataTableView;
