import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const BarsTwo = (props?: IconProps) => {
    const { height = 38, width = 40, className = defaultTextLightClass } = props ?? {};
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={className}
            height={height}
            width={width}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
        </svg>
    );
};
