import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import { useFormikContext } from 'formik';
import TextDisplayOption from '@models/plotDisplayOption/TextDisplayOption';
import { DisplayFormValue } from '@models/PlotDisplayOption';
import { isNotBlank } from '@util/StringUtil';
import TextInputField from '@components/forms/TextInputField';

const TextDisplayFields = () => {
    const { values } = useFormikContext<DisplayFormValue<TextDisplayOption>>();

    const hasAttribution = isNotBlank(values.attribution_label);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField label="Title" />
            <TextInputField name="attribution_label" label="Attribution" hint="e.g. author or source" hintTop />
            {hasAttribution && (
                <TextInputField
                    name="attribution_url"
                    label="Attribution link"
                    placeholder="https://example.com"
                    hint="Optional URL for attribution"
                    hintTop
                />
            )}
        </>
    );
};

export default TextDisplayFields;
