import { IconProps } from '@components/icons/CustomIcons';

export const VialIcon = ({ width = 18, height = 20, className }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M19.9039 7.75407L12.8956 0.762402C12.7664 0.633236 12.5539 0.633236 12.4247 0.762402L11.0081 2.1749C10.8789 2.30407 10.8789 2.51657 11.0081 2.64574L11.4747 3.10824L1.37472 13.1874C-0.241951 14.7999 -0.504452 17.4374 0.983051 19.1666C1.84139 20.1666 3.04555 20.6666 4.24972 20.6624C5.34972 20.6624 6.44972 20.2457 7.28723 19.4082L17.5497 9.17074L18.0164 9.63323C18.1456 9.7624 18.3581 9.7624 18.4872 9.63323L19.9039 8.22074C20.0331 8.09574 20.0331 7.88324 19.9039 7.75407ZM13.2497 10.6666H6.70806L12.8747 4.5124L16.1456 7.7749L13.2497 10.6666Z"
                fill="currentColor"
            />
        </svg>
    );
};
