export interface PlotDataHeaderAnalysis {
    plot_data_headers?: string[];
    peak_set_type?: string;
}

export const functionalAnnotationAnalysisHeaderMapping = {
    distal_intergenic: 'Distal intergenic',
    downstream_loe_300bp: 'Downstream (<=300bp)',
    five_prime_utr: '5’ UTR',
    other_exon: 'Other exon',
    other_intron: 'Other intron',
    peak_set: 'Peak set',
    promoter_1_to_2kb: 'Promoter (1-2kb)',
    promoter_2_to_3kb: 'Promoter (2-3kb)',
    promoter_loe_1kb: 'Promoter (<=1kb)',
    promoter: 'Promoter',
    first_exon: '1st exon',
    first_intron: '1st intron',
    three_prime_utr: '3’ UTR',
};
