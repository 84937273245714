import { allThemeColors } from '@models/PlotConfigs';
import SelectableItem from '@components/forms/SelectableItem';
import Button from '@components/Button';
import { ThemeColorPalette } from '@components/ColorPalette';
import React, { ReactNode, useState } from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues } from '@models/PlotDisplayOption';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

type Props = { label?: ReactNode };
const ThemeColorField = ({ label = 'Colors' }: Props) => {
    const { values, setFieldValue } = useFormikContext<BasePlotDisplayOptionFormValues>();
    const [showAllThemes, setShowAllThemes] = useState(false);

    return (
        <div className="form-field no-margin">
            <span className="field-label">{label}</span>
            <Collapse in={showAllThemes} collapsedSize={161.9}>
                <div className="flex flex-col space-y-1 p-0.5">
                    {allThemeColors.map((color, index) => (
                        <SelectableItem
                            key={`color_theme_${index}`}
                            onSelect={() => setFieldValue('theme_color', color)}
                            selected={values.theme_color === color}
                            noBorder
                            className=""
                            paddingClass="px-2 py-2"
                            activeClasses="ring-2 ring-indigo-500"
                        >
                            <ThemeColorPalette color={color} />
                        </SelectableItem>
                    ))}
                </div>
            </Collapse>
            <Button
                className="mt-2 flex flex-col space-y-1"
                color="primary"
                variant="text"
                size="small"
                onClick={() => setShowAllThemes(!showAllThemes)}
                style={{ marginLeft: -6 }}
            >
                {showAllThemes ? 'View less' : 'View all'}
                {showAllThemes ? <ExpandLess /> : <ExpandMore />}
            </Button>
        </div>
    );
};

export default ThemeColorField;
