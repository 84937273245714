import React, { useState } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import CovariatesVariablesField from '@components/experiments/analyses/fields/CovariatesVariablesField';
import { DifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { Switch } from '@mui/material';
import { useSwitchStyles } from '@components/SwitchStyles';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import { useFormikContext } from 'formik';
import { DifferentialExpressionAnalysisFormValues } from '@models/analysis/DifferentialExpressionAnalysis';

type Props = {
    plot: Plot;
    analysisParameters: DifferentialExpressionAnalysisParameters;
    experiment: Experiment;
};
const CovariatesPickerFields = ({ plot, experiment, analysisParameters }: Props) => {
    // Check if .covariates has any values and initialize showCovariateGroups accordingly
    const { values, setFieldValue } = useFormikContext<DifferentialExpressionAnalysisFormValues>();
    const initialCovariatesState = values && values.covariates && values.covariates.length > 0 ? true : false;
    const [showCovariateGroups, setShowCovariateGroups] = useState(initialCovariatesState);

    const switchStyles = useSwitchStyles();

    const covariatesToggle = () => {
        if (showCovariateGroups) setFieldValue('covariates', []);
        setShowCovariateGroups(!showCovariateGroups);
    };

    return (
        <div className="form-field rounded-lg p-4" data-cy="groups-picker-field">
            <div className="flex flex-col">
                <h4 className="flex flex-row justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark">
                    <span>Covariates</span>
                    <Switch
                        sx={switchStyles}
                        name="show_data_points"
                        checked={showCovariateGroups ?? false}
                        onClick={covariatesToggle}
                    />
                </h4>
            </div>
            <div className="mb-3 mt-2 text-xs">
                <a
                    href="https://help.pluto.bio/en/articles/including-covariates-in-differential-analyses"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center"
                >
                    <HelpCircleIcon height={16} width={16} color="#3343AB" className="mr-1 inline-block" />
                    When should covariates be included?
                </a>
            </div>
            {showCovariateGroups && (
                <div>
                    <CovariatesVariablesField
                        plot={plot}
                        analysisParameters={analysisParameters}
                        experiment={experiment}
                    />
                </div>
            )}
        </div>
    );
};

export default CovariatesPickerFields;
