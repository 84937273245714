import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import Italic from '@components/elements/Italic';

type Props = { analysis: GeneSetEnrichmentAnalysis };
const GSEATableMethodsSection = ({ analysis }: Props) => {
    const rank_method = analysis.rank_method;
    return (
        <section>
            <MethodsSectionTitle>GSEA Table</MethodsSectionTitle>
            <p>
                GSEA results for each tested gene set are shown in the table. The Adj <Italic>p</Italic>
                -value column contains the false discovery rate (FDR)-adjusted p-value. The NES column contains the
                normalized enrichment score (NES) computed by GSEA, which represents the magnitude of enrichment
                {rank_method === 'p_value'
                    ? '.'
                    : 'as well as the direction. A positive NES indicates more enrichment in the first group while a ' +
                      'negative NES indicates more enrichment in the second group.'}
            </p>
        </section>
    );
};

export default GSEATableMethodsSection;
