import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import TextInput from '@components/forms/TextInput';
import { ClusterSeuratObjectFormValues } from '../PreprocessFormTypes';
import SimpleSelectField from '../../plotDisplay/fields/SimpleSelectField';
import { StatTestSelectOptions } from '@models/preprocesses/ClusterSeuratObject';
import Picker from '@components/forms/Picker';
import { WarningRounded } from '@mui/icons-material';
import TextInputField from '@/src/components/forms/TextInputField';

type Props = {
    readOnly: boolean;
};
const ClusterSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue } = useFormikContext<ClusterSeuratObjectFormValues>();
    const [visualizationExpanded, setVisualizationExpanded] = useState(false);
    const [statisticsExpanded, setStatisticsExpanded] = useState(false);
    const [clusterResolutionOptions, setClusterResolutionOptions] = useState<string[]>([]);

    useEffect(() => {
        const options: string[] = [];
        for (let i = 0.1, l = 2.1; i < l; i += 0.1) {
            options.push(i.toFixed(1));
        }
        setClusterResolutionOptions(options);
    }, []);
    return (
        <div className="space-y-8">
            <section>
                <p className="field-label !mb-0">Cluster resolution(s)</p>
                <Picker
                    disabled={readOnly}
                    name="resolutions"
                    noOptionsText="No resolutions found"
                    options={clusterResolutionOptions}
                    placeholder="Select up to four resolutions..."
                    itemName="resolution"
                    maxItems={4}
                    sortValues={(val) => val.sort((a, b) => Number(a?.value) - Number(b?.value))}
                />
            </section>
            <section>
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setStatisticsExpanded(!statisticsExpanded)}
                >
                    <span>Statistics</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': statisticsExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={statisticsExpanded}>
                    <div className="space-y-6">
                        <SimpleSelectField
                            name="stat_test"
                            label={'Test to use'}
                            value={values.stat_test}
                            onChange={(e) => setFieldValue('stat_test', e.target.value)}
                            items={StatTestSelectOptions}
                            disabled={readOnly}
                            tooltip={{
                                title: 'Tests besides the Wilcoxon Rank Sum Test will have a significantly longer runtime that is proportional to the number of resolutions and clusters you have',
                                icon: <WarningRounded width={10} className="text-amber-500" />,
                            }}
                        />
                        <TextInput
                            name="log2fc"
                            label="Log2 fold change threshold"
                            type="text"
                            value={values.log2fc ?? ''}
                            onChange={(e) => setFieldValue('log2fc', e.target.value)}
                            disabled={readOnly}
                        />
                        <div className="space-y-2">
                            <p className="field-label">Marker types to output</p>
                            <label className="block cursor-pointer">
                                <input
                                    type="radio"
                                    className="text-indigo-500"
                                    name="only_pos"
                                    checked={values.only_pos === false}
                                    onChange={() => {
                                        setFieldValue('only_pos', false);
                                    }}
                                    disabled={readOnly}
                                />
                                <span className="ml-2">Both positive and negative</span>
                            </label>
                            <label className="block cursor-pointer">
                                <input
                                    type="radio"
                                    className="text-indigo-500"
                                    name="only_pos"
                                    onChange={() => {
                                        setFieldValue('only_pos', true);
                                    }}
                                    checked={values.only_pos === true}
                                    disabled={readOnly}
                                />
                                <span className="ml-2">Positive markers only</span>
                            </label>
                        </div>
                    </div>
                </Collapse>
            </section>
            <section className="!mt-4">
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setVisualizationExpanded(!visualizationExpanded)}
                >
                    <span>Visualization</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': visualizationExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={visualizationExpanded}>
                    <div className="space-y-6">
                        <TextInputField
                            name="n_dims"
                            label="Number of principal components"
                            type="number"
                            value={values.n_dims}
                            disabled={readOnly}
                            onValueChange={(_, __, values) => setFieldValue('n_dims', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            min={0}
                            useNumericFormat
                        />
                        <TextInputField
                            name="k_param"
                            label="k for the k-nearest neighbor algorithm"
                            type="number"
                            value={values.k_param}
                            disabled={readOnly}
                            onValueChange={(_, __, values) => setFieldValue('k_param', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            min={0}
                            useNumericFormat
                        />
                    </div>
                </Collapse>
            </section>
        </div>
    );
};

export default ClusterSeuratObjectFormFields;
