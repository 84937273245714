import { ExperimentAnalysis, isPipelineAnalysis, PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import Experiment from '@models/Experiment';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { isPipelineUpgradeAvailable } from '@components/plots/util/PipelineVersionUtil';

type Props = { analysis: ExperimentAnalysis | null | undefined; experiment: Experiment | null };
const useAnalysisPipelineVersion = ({ analysis, experiment }: Props) => {
    const { getAnalysisInfo } = useExperimentSettings(experiment);

    const analysisTypeShortname = analysis?.analysis_type ?? null;

    const analysisType = analysisTypeShortname ? getAnalysisInfo(analysisTypeShortname) : null;

    const latestVersion = analysisType?.pipeline_version;
    const currentVersion = (analysis as PipelineStatusAnalysis | null | undefined)?.pipeline_version ?? 1.0;

    const upgradeAvailable = isPipelineUpgradeAvailable({ analysis, analysisType });

    return {
        analysisType: analysisType,
        isPipelineAnalysis: isPipelineAnalysis(analysis),
        currentVersion,
        latestVersion,
        upgradeAvailable,
    };
};

export default useAnalysisPipelineVersion;
