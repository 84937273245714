import { PlusIcon } from '@heroicons/react/solid';
import Button from '@components/Button';
import { ReactNode } from 'react';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import useExperimentSettings from '@hooks/useExperimentSettings';

type Props = { label?: ReactNode; onClick?: () => void; cy?: string };
const CreateAnalysisButton = ({ label = 'Analysis', onClick, cy = 'create-analysis-btn-static' }: Props) => {
    const {
        currentChanges,
        setUnsavedChangesConfirmOpen,
        handleChangeSelectedPlot,
        setExperimentModalOpen,
        setCurrentPlotPanel,
        experiment,
    } = useExperimentDetailViewContext();
    const { hasAvailableAnalysisTypes } = useExperimentSettings(experiment);

    const createPlot = () => {
        if (currentChanges) {
            setUnsavedChangesConfirmOpen('createAnalysis');
        } else {
            handleChangeSelectedPlot(null);
            setCurrentPlotPanel('analysis');
            setExperimentModalOpen(true);
        }
    };

    if (!hasAvailableAnalysisTypes) {
        return null;
    }

    return (
        <Button onClick={onClick || createPlot} variant="contained" color="primary" data-cy={cy}>
            <PlusIcon className="mr-1 h-3.5 w-3.5" />
            <span className="whitespace-nowrap">{label}</span>
        </Button>
    );
};

export default CreateAnalysisButton;
