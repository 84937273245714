import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const EnrichmentPlotIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 39 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M38 17.8673H1.71875L3.53281 9.30668L7.16094 11.6628L8.975 6.16518L12.6031 9.30668L14.4172 3.02368L27.1156 13.2336L29.7543 9.30668L38 17.8673Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinejoin="round"
            />
            <line
                x1="1"
                y1="1"
                x2="1"
                y2="27.8625"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="12.125"
                y1="22.5782"
                x2="12.125"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="15.125"
                y1="22.5782"
                x2="15.125"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="29.125"
                y1="22.5782"
                x2="29.125"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="8.09375"
                y1="22.5782"
                x2="8.09375"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="17.5"
                y1="22.5782"
                x2="17.5"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="32.2812"
                y1="22.5782"
                x2="32.2812"
                y2="28"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
};
