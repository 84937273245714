import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import { Dialog, DialogActions, DialogContent, FormControl, MenuItem, Select } from '@mui/material';
import { blankToUndefined, formatSmallNumber } from '@util/StringUtil';
import useAnalysisGeneSets from '@hooks/useAnalysisGeneSets';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import GeneSetTableField from '@components/experiments/plotDisplay/fields/GeneSetTableField';
import { FormikFieldError } from '@components/forms/FieldError';
import { isDefined } from '@util/TypeGuards';
import DialogCloseButton from '@components/DialogCloseButton';
import DownloadGeneSetsButton from '@components/experiments/plotDisplay/fields/DownloadGeneSetsButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import CheckboxField from '@components/forms/CheckboxField';
import { TruncatedText } from '@components/experiments/history/items/TruncatedText';

type Props = { name?: string; experiment: Experiment; plot: Plot; disabled?: boolean };
const GeneSetPickerField = ({ name = 'gene_set', experiment, plot, disabled = false }: Props) => {
    const experimentId = experiment.uuid;
    const analysisId = plot.analysis?.uuid;

    const [expanded, setExpanded] = useState(false);

    const [inputProps, { error, touched, value }] = useField<string>(name);
    const { loading, data } = useAnalysisGeneSets({
        analysisId,
        experimentId,
        includeGeneSetShortname: blankToUndefined(value),
    });
    useEffect(() => {
        setExpanded(false);
    }, [value]);

    return (
        <>
            <label className="form-field">
                <p className="field-label flex items-center justify-between">
                    <span>Gene set</span>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => setExpanded(true)}
                        endIcon={<ArrowsExpandIcon className="h-4 w-4" />}
                    >
                        Expand
                    </Button>
                </p>
                <FormControl variant="outlined" fullWidth error={!!error && touched} disabled={loading || disabled}>
                    <Select
                        onChange={inputProps.onChange}
                        margin="dense"
                        name={name}
                        value={loading ? 'loading' : value ?? ''}
                    >
                        <MenuItem value="loading" disabled className="!hidden">
                            Loading
                        </MenuItem>
                        {!loading && data?.items.length === 0 && (
                            <MenuItem value="" disabled>
                                No gene sets available
                            </MenuItem>
                        )}
                        {data?.items.map((geneSet) => (
                            <MenuItem key={geneSet.Gene_Set_Shortname} value={geneSet.Gene_Set_Shortname}>
                                <div className="py-1">
                                    <TruncatedText className="text-dark" text={geneSet.Gene_Set_Display_Name} />
                                    <p className="space-x-3 text-sm">
                                        <span>NES: {geneSet.NES?.toFixed(2) ?? '--'}</span>
                                        <span>
                                            Adj <i>p</i>-value:{' '}
                                            {isDefined(geneSet.Adj_P_Value)
                                                ? formatSmallNumber({
                                                      value: geneSet.Adj_P_Value,
                                                      decimals: 5,
                                                      minThreshold: 0.0001,
                                                  })
                                                : '--'}
                                        </span>
                                        <span>Size: {geneSet.Size}</span>
                                    </p>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormikFieldError name={name} />
                </FormControl>
                <div className="mt-2 space-y-1">
                    <CheckboxField
                        className="flex items-start"
                        checkboxClassName="mt-1"
                        name="show_p_value"
                        label={
                            <p>
                                Show adjusted <span className="italic">p</span>-value
                            </p>
                        }
                    />
                    <CheckboxField
                        className="flex items-start"
                        checkboxClassName="mt-1"
                        name="show_nes_value"
                        label={<p>Show normalized enrichment score (NES)</p>}
                    />
                </div>
            </label>

            <Dialog open={expanded} onClose={() => setExpanded(false)} maxWidth="md" fullWidth>
                <DialogCloseButton onClose={() => setExpanded(false)} />

                <PlutoDialogTitle title="Select gene set" />
                <DialogContent className="mt-2">
                    <GeneSetTableField
                        experiment={experiment}
                        plot={plot}
                        onSelected={() => setExpanded(false)}
                        name={name}
                    />
                </DialogContent>
                <DialogActions>
                    <DownloadGeneSetsButton experiment={experiment} analysis={plot.analysis} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GeneSetPickerField;
