import { StatisticalTestType } from '@models/SummaryStatisticalTestResult';
import React, { HTMLProps, ReactNode } from 'react';
import { isDefined } from '@util/TypeGuards';

type Props = { test_type: Partial<StatisticalTestType> } & HTMLProps<HTMLSpanElement>;
/**
 * Renders the test_type and applies any special formatting needed, specifically the italic `t` in `t-test`.
 * @param {Partial<StatisticalTestType>} test_type
 * @param {HTMLProps<HTMLSpanElement>} props
 * @return {JSX.Element}
 * @constructor
 */
const TestTypeDisplayName = ({ test_type, ...props }: Props) => {
    const parts = test_type.display_name?.split(' ') ?? [];
    const nodes: ReactNode[] =
        parts.map((part, i) => {
            const suffix = i === parts.length - 1 ? '' : ' ';
            if (part.endsWith('-test')) {
                const [prefix] = part.split('-test');
                return (
                    <React.Fragment key={`${i}_part`}>
                        <i>{prefix}</i>
                        {`-test${suffix}`}
                    </React.Fragment>
                );
            }
            return <React.Fragment key={`${i}_part`}>{`${part}${suffix}`}</React.Fragment>;
        }) ?? [];

    return <span {...props}>{nodes}</span>;
};

export const getTestTypeInfo = (testType?: StatisticalTestType | null) => {
    const min = testType?.minimum_groups ?? null;
    const max = testType?.maximum_groups ?? null;
    const isExact = isDefined(min) && isDefined(max) && min === max;
    return { isExact };
};

export default TestTypeDisplayName;
