import Plot from '@models/Plot';
import React from 'react';
import { Citation, CitationItem } from '@components/experiments/methods/Citation';
import DoiLink from './DoiLink';
import useAnalysisParameters from '@/src/hooks/useAnalysisParameters';
import Experiment from '@/src/models/Experiment';
import {
    PanglaoShortnames,
    SeuratOverRepresentationAnalysis,
} from '@/src/models/analysis/SeuratOverRepresentationAnalysis';
import { SeuratOverRepresentationAnalysisParameters } from '@/src/models/AnalysisParameters';

type Props = { plot: Plot; className?: string; experiment: Experiment };
const MethodsReferences = ({ plot, className, experiment }: Props) => {
    const { analysisParameters } = useAnalysisParameters({ plot: plot, experiment });
    const plotType = plot?.display.display_type;
    const getCitations = (): CitationItem[] => {
        switch (plotType) {
            case 'box_plot':
            case 'bar_plot':
            case 'heatmap': {
                switch (plot.analysis_type) {
                    case 'assay_summary_cpm_normalized':
                        return [
                            {
                                title: 'edgeR: a Bioconductor package for differential expression analysis of digital gene expression data',
                                author: 'Robinson MD, McCarthy DJ, Smyth GK',
                                publication: 'Bioinformatics',
                                volume: '26',
                                page: '139-140',
                                year: '2010',
                            },
                        ];
                    case 'differential_expression':
                    case 'differential_binding':
                        return [
                            {
                                title: 'Moderated estimation of fold change and dispersion for RNA-seq data with DESeq2',
                                author: 'Love MI, Huber W, Anders S',
                                publication: 'Genome Biology',
                                volume: '15',
                                page: '550',
                                year: '2014',
                            },
                            {
                                title: 'Controlling the false discovery rate - a practical and powerful approach to multiple testing',
                                author: 'Benjamini, Y. & Hochberg, Y',
                                publication: 'J. R. Stat. Soc. B Met',
                                volume: '57',
                                page: '289–300',
                                year: '1995',
                            },
                        ];
                    case 'differential_expression_continuous':
                        return [
                            {
                                custom: (
                                    <p>
                                        Ritchie ME, Phipson B, Wu D, Hu Y, Law CW, Shi W, Smyth GK. limma powers
                                        differential expression analyses for RNA-sequencing and microarray studies.{' '}
                                        <span className="italic">Nucleic Acids Research</span>,{' '}
                                        <span className="font-semibold">43</span>(7), e47 (2015).
                                    </p>
                                ),
                            },
                            {
                                title: 'Controlling the false discovery rate - a practical and powerful approach to multiple testing',
                                author: 'Benjamini, Y. & Hochberg, Y',
                                publication: 'J. R. Stat. Soc. B Met',
                                volume: '57',
                                page: '289–300',
                                year: '1995',
                            },
                        ];
                    default:
                        return [];
                }
            }

            case 'dot_plot': {
                switch (plot.analysis_type) {
                    case 'seurat_over_representation':
                        const hasPanglaoShortname = (
                            analysisParameters as SeuratOverRepresentationAnalysisParameters
                        )?.gene_set_collections.some(
                            (collection) =>
                                (plot?.analysis as SeuratOverRepresentationAnalysis)?.gene_set_collections?.[0] ===
                                    collection.id && PanglaoShortnames.includes(collection.shortname),
                        );
                        if (hasPanglaoShortname) {
                            return [
                                {
                                    custom: (
                                        <>
                                            Tianzhi et al. (2021). “clusterProfiler 4.0: A universal enrichment tool for
                                            interpreting omics data.“ <span className="italic">The Innovation</span>{' '}
                                            (2021). <DoiLink doi="10.1016/j.xinn.2021.100141" />
                                        </>
                                    ),
                                },
                                {
                                    custom: (
                                        <>
                                            Franzén, O., Gan, L., and Björkegren, J.L.M. (2019). “PanglaoDB: a web
                                            server for exploration of mouse and human single-cell RNA sequencing data.”{' '}
                                            <span className="italic">Database.</span>{' '}
                                            <DoiLink
                                                doi="doi/10.1093/database/baz046/5427041"
                                                domain="https://academic.oup.com/database/article/"
                                            />
                                        </>
                                    ),
                                },
                                {
                                    custom: (
                                        <>
                                            Benjamini, Y., & Hochberg, Y. (2000). “On the adaptive control of the false
                                            discovery rate in multiple testing with independent statistics.”{' '}
                                            <span className="italic">
                                                Journal of Educational and Behavioral Statistics.
                                            </span>{' '}
                                            <DoiLink doi="doi/10.2307/1165312" domain="https://psycnet.apa.org/" />
                                        </>
                                    ),
                                },
                            ];
                        }
                        return [
                            {
                                custom: (
                                    <>
                                        Tianzhi et al. (2021). “clusterProfiler 4.0: A universal enrichment tool for
                                        interpreting omics data.“ <span className="italic">The Innovation</span> (2021).{' '}
                                        <DoiLink doi="10.1016/j.xinn.2021.100141" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Subramanian et al. (2005). “Gene set enrichment analysis: A knowledge-based
                                        approach for interpreting genome-wide expression profiles.”{' '}
                                        <span className="italic">PNAS.</span> <DoiLink doi="10.1073/pnas.0506580102" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Liberzon et al. (2015). “The Molecular Signatures Database (MSigDB) hallmark
                                        gene set collection.” <span className="italic">Cell Systems.</span>{' '}
                                        <DoiLink doi="10.1016/j.cels.2015.12.004" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Igor Dolgalev (2022). msigdbr: “MSigDB Gene Sets for Multiple Organisms in a
                                        Tidy Data Format”. <span className="italic">R package.</span>{' '}
                                        <a
                                            href="https://CRAN.R-project.org/package=msigdbr"
                                            target="_blank"
                                            rel="noopener nofollow noreferrer"
                                        >
                                            https://CRAN.R-project.org/package=msigdbr
                                        </a>
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Benjamini, Y., & Hochberg, Y. (2000). “On the adaptive control of the false
                                        discovery rate in multiple testing with independent statistics.”{' '}
                                        <span className="italic">
                                            Journal of Educational and Behavioral Statistics.
                                        </span>{' '}
                                        <DoiLink doi="doi/10.2307/1165312" domain="https://psycnet.apa.org/" />
                                    </>
                                ),
                            },
                        ];
                    case 'sample_correlation':
                        return [
                            {
                                custom: (
                                    <>
                                        Harrell Jr., F., and Dupont, Ch. (2019). “Hmisc: Harrell Miscellaneous.” R
                                        Package.{' '}
                                        <a href="https://CRAN.R-project.org/package=Hmisc">
                                            https://CRAN.R-project.org/package=Hmisc
                                        </a>
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }
            }

            case 'volcano_plot':
            case 'volcano_plot_v2':
                let analysisReference: CitationItem = {
                    title: 'Moderated estimation of fold change and dispersion for RNA-seq data with DESeq2',
                    author: 'Love MI, Huber W, Anders S',
                    publication: 'Genome Biology',
                    volume: '15',
                    page: '550',
                    year: '2014',
                };

                if (plot.analysis_type === 'differential_expression_continuous') {
                    analysisReference = {
                        custom: (
                            <p>
                                Ritchie ME, Phipson B, Wu D, Hu Y, Law CW, Shi W, Smyth GK. limma powers differential
                                expression analyses for RNA-sequencing and microarray studies.{' '}
                                <span className="italic">Nucleic Acids Research</span>,{' '}
                                <span className="font-semibold">43</span>(7), e47 (2015).
                            </p>
                        ),
                    };
                }
                if (plot.analysis_type === 'seurat_differential_expression') {
                    return [
                        {
                            custom: (
                                <>
                                    Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                    <span className="italic">Cell</span> (2021). doi:{' '}
                                    <a
                                        href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                        target="_blank"
                                        rel="noopener nofollow noreferrer"
                                    >
                                        10.1016/j.cell.2021.04.048
                                    </a>
                                </>
                            ),
                        },
                    ];
                }

                return [
                    analysisReference,
                    {
                        title: 'Controlling the false discovery rate - a practical and powerful approach to multiple testing',
                        author: 'Benjamini, Y. & Hochberg, Y',
                        publication: 'J. R. Stat. Soc. B Met',
                        volume: '57',
                        page: '289–300',
                        year: '1995',
                    },
                ];
            case 'sample_scatter_plot':
                switch (plot.analysis_type) {
                    case 'umap':
                        return [
                            {
                                custom: 'McInnes, L, Healy, J. UMAP: Uniform Manifold Approximation and Projection for Dimension Reduction, ArXiv e-prints 1802.03426 (2018).',
                            },
                        ];
                    case 'principal_components':
                        return [
                            {
                                title: 'Moderated estimation of fold change and dispersion for RNA-seq data with DESeq2',
                                author: 'Love MI, Huber W, Anders S',
                                publication: 'Genome Biology',
                                volume: '15',
                                page: '550',
                                year: '2014',
                            },
                        ];
                    case 'tsne':
                        return [
                            {
                                custom: (
                                    <>
                                        van der Maaten L, Hinton G. Visualizing High-Dimensional Data Using t-SNE.{' '}
                                        <span className="italic">Journal of Machine Learning Research</span>, 9,
                                        2579-2605 (2008).
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        van der Maaten L. Accelerating t-SNE using Tree-Based Algorithms.{' '}
                                        <span className="italic">Journal of Machine Learning Research</span>, 15,
                                        3221-3245 (2014).
                                    </>
                                ),
                            },
                            {
                                custom: 'Krijthe JH. Rtsne: T-Distributed Stochastic Neighbor Embedding using Barnes-Hut Implementation. R package (2015), https://github.com/jkrijthe/Rtsne',
                            },
                        ];
                    default:
                        return [];
                }
            case 'enrichment_plot':
            case 'score_bar_plot':
                switch (plot.analysis_type) {
                    case 'gene_set_enrichment':
                        return [
                            {
                                author: 'Korotkevich G, Sukhov V, Sergushichev A',
                                title: 'Fast gene set enrichment analysis',
                                publication: 'bioRxiv',
                                year: '2019',
                                url: 'https://doi.org/10.1101/060012',
                                label: 'doi: 10.1101/060012',
                            },
                            {
                                author: 'Subramanian A, Tamayo P, Mootha VK, et al',
                                title: 'Gene set enrichment analysis: a knowledge-based approach for interpreting genome-wide expression profiles',
                                publication: 'Proc Natl Acad Sci U S A.',
                                year: '2005;102(43):15545-15550',
                                label: 'doi: 10.1073/pnas.0506580102',
                                url: 'https://doi.org/10.1073/pnas.0506580102',
                            },
                            {
                                author: 'Liberzon A, Birger C, Thorvaldsdóttir H, Ghandi M, Mesirov JP, Tamayo P',
                                title: 'The Molecular Signatures Database (MSigDB) hallmark gene set collection',
                                publication: 'Cell Syst.',
                                year: '2015;1(6):417-425',
                                label: 'doi: 10.1016/j.cels.2015.12.004',
                                url: 'https://doi.org/10.1016/j.cels.2015.12.004',
                            },
                            {
                                custom: (
                                    <span>
                                        Igor Dolgalev (2021). msigdbr: MSigDB Gene Sets for Multiple Organisms in a Tidy
                                        Data Format. R package version 7.4.1.{' '}
                                        <a href="https://CRAN.R-project.org/package=msigdbr">
                                            https://CRAN.R-project.org/package=msigdbr
                                        </a>
                                        .
                                    </span>
                                ),
                            },
                        ];
                    case 'transcription_factor_enrichment':
                        return [
                            {
                                author: "Keenan AB, Torre D, Lachmann A, Leong AK, Wojciechowicz M, Utti V, Jagodnik K, Kropiwnicki E, Wang Z, Ma'ayan A",
                                title: 'ChEA3: transcription factor enrichment analysis by orthogonal omics integration',
                                publication: 'Nucleic Acids Research.',
                                year: '2019',
                                label: 'doi: 10.1093/nar/gkz446',
                                url: 'https://doi.org/10.1093/nar/gkz446',
                            },
                            {
                                custom: (
                                    <span>
                                        ChEA3 web application{' '}
                                        <a href="https://maayanlab.cloud/chea3/">https://maayanlab.cloud/chea3/</a>.
                                    </span>
                                ),
                            },
                        ];
                    default:
                        return [];
                }
            case 'kaplan_meier_curve':
                return [
                    {
                        author: 'Therneau T',
                        title: 'A Package for Survival Analysis in R',
                        publication: 'R package version 3.3-1',
                        year: '(2022)',
                        label: 'https://cran.r-project.org/package=survival',
                        url: 'https://cran.r-project.org/package=survival',
                    },
                    {
                        custom: (
                            <span>
                                Therneau, T.M., Grambsch, P.M.{' '}
                                <span className="italic">Modeling Survival Data: Extending the Cox Model.</span>{' '}
                                Springer, New York. (2000).
                            </span>
                        ),
                    },
                    {
                        custom: (
                            <span>
                                Clark, T.G., Bradburn, M.J., Love, S.B., and Altman, D.G. Survival Analysis Part I:
                                Basic concepts and first analyses. <span className="italic">Br J Cancer.</span> Jul 21;
                                89(2): 232–238. (2003).
                            </span>
                        ),
                    },
                ];
            case 'igv_plot':
                return [
                    {
                        custom: (
                            <span>
                                James T. Robinson, Helga Thorvaldsdóttir, Wendy Winckler, Mitchell Guttman, Eric S.
                                Lander, Gad Getz, Jill P. Mesirov. Integrative Genomics Viewer. Nature Biotechnology 29,
                                24–26 (2011).
                            </span>
                        ),
                    },
                    {
                        custom: (
                            <span>
                                James T. Robinson, Helga Thorvaldsdóttir, Douglass Turner, Jill P. Mesirov. igv.js: an
                                embeddable JavaScript implementation of the Integrative Genomics Viewer (IGV). bioRxiv
                                2020.05.03075499.
                            </span>
                        ),
                    },
                ];
            case 'pie_chart':
                switch (plot.analysis_type) {
                    case 'functional_annotation':
                        return [
                            {
                                custom: (
                                    <>
                                        Wang, Q. <i>et al</i>. (2022). “Exploring epigenomic datasets by ChIPseeker.“{' '}
                                        <span className="italic">Current Protocols</span>. 2(10): e585.{' '}
                                        <DoiLink doi="10.1002/cpz1.585" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Yu, G., Wang, L.G., and He, Q.Y. (2015). “ChIPseeker: an R/Bioconductor package
                                        for ChIP peak annotation, comparison and visualization.”{' '}
                                        <span className="italic">Bioinformatics.</span> 31(14):2382-2383.{' '}
                                        <DoiLink doi="10.1093/bioinformatics/btv145" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Raney, B.J. <i>et al</i>. (2024). “The UCSC Genome Browser database: 2024
                                        update.” Nucleic Acids Res. Jan 5;52(D1):D1082-D1088.{' '}
                                        <DoiLink doi="10.1093/nar/gkad987" />
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }
            case 'stacked_bar_plot':
                switch (plot.analysis_type) {
                    case 'functional_annotation':
                        return [
                            {
                                custom: (
                                    <>
                                        Wang, Q. <i>et al</i>. (2022). “Exploring epigenomic datasets by ChIPseeker.“{' '}
                                        <span className="italic">Current Protocols</span>. 2(10): e585.{' '}
                                        <DoiLink doi="10.1002/cpz1.585" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Yu, G., Wang, L.G., and He, Q.Y. (2015). “ChIPseeker: an R/Bioconductor package
                                        for ChIP peak annotation, comparision and visualization.”{' '}
                                        <span className="italic">Bioinformatics.</span> 31(14):2382-2383.{' '}
                                        <DoiLink doi="10.1093/bioinformatics/btv145" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Raney, B.J. <i>et al</i>. (2024). “The UCSC Genome Browser database: 2024
                                        update.” Nucleic Acids Res. Jan 5;52(D1):D1082-D1088.{' '}
                                        <DoiLink doi="10.1093/nar/gkad987" />
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }

            case 'network_graph':
                switch (plot.analysis_type) {
                    case 'protein_protein_interaction':
                        return [
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Kirsch R, Koutrouli M, Nastou K, Mehryary F, Hachilif R, Annika
                                        GL, Fang T, Doncheva NT, Pyysalo S, Bork P, Jensen LJ, von Mering C. (2023).
                                        “The STRING database in 2023: protein-protein association networks and
                                        functional enrichment analyses for any sequenced genome of interest.“{' '}
                                        <span className="italic">Nucleic Acids Res</span>. Jan 6;51(D1):D638-646.{' '}
                                        <DoiLink doi="10.1093/nar/gkac1000" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Gable AL, Nastou KC, Lyon D, Kirsch R, Pyysalo S, Doncheva NT,
                                        Legeay M, Fang T, Bork P, Jensen LJ, von Mering C. (2021). “The STRING database
                                        in 2021: customizable protein-protein networks, and functional characterization
                                        of user-uploaded gene/measurement sets.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan 8;49(D1):D605-12.{' '}
                                        <DoiLink doi="10.1093/nar/gkaa1074" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Gable AL, Lyon D, Junge A, Wyder S, Huerta-Cepas J, Simonovic M,
                                        Doncheva NT, Morris JH, Bork P, Jensen LJ, von Mering C. (2019). “STRING v11:
                                        protein-protein association networks with increased coverage, supporting
                                        functional discovery in genome-wide experimental datasets.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 47:D607-613.{' '}
                                        <DoiLink doi="10.1093/nar/gky1131" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Morris JH, Cook H, Kuhn M, Wyder S, Simonovic M, Santos A,
                                        Doncheva NT, Roth A, Bork P, Jensen LJ, von Mering C. (2017). “The STRING
                                        database in 2017: quality-controlled protein-protein association networks, made
                                        broadly accessible.” <span className="italic">Nucleic Acids Res.</span> Jan;
                                        45:D362-68. <DoiLink doi="10.1093/nar/gkw937" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Franceschini A, Wyder S, Forslund K, Heller D, Huerta-Cepas J,
                                        Simonovic M, Roth A, Santos A, Tsafou KP, Kuhn M, Bork P, Jensen LJ, von Mering
                                        C. (2015). “STRING v10: protein-protein interaction networks, integrated over
                                        the tree of life.” <span className="italic">Nucleic Acids Res.</span> Jan;
                                        43:D447-52. <DoiLink doi="10.1093/nar/gku1003" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Franceschini A, Lin J, von Mering C, Jensen LJ. (2015). “SVD-phy: improved
                                        prediction of protein functional associations through singular value
                                        decomposition of phylogenetic profiles.”{' '}
                                        <span className="italic">Bioinformatics.</span> Nov; btv696.{' '}
                                        <DoiLink doi="10.1093/bioinformatics/btv696" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Franceschini A, Szklarczyk D, Frankild S, Kuhn M, Simonovic M, Roth A, Lin J,
                                        Minguez P, Bork P, von Mering C, Jensen LJ. (2013). “STRING v9.1:
                                        protein-protein interaction networks, with increased coverage and integration.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 41:D808-15.{' '}
                                        <DoiLink doi="10.1093/nar/gks1094" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Szklarczyk D, Franceschini A, Kuhn M, Simonovic M, Roth A, Minguez P, Doerks T,
                                        Stark M, Muller J, Bork P, Jensen LJ, von Mering C. (2011). “The STRING database
                                        in 2011: functional interaction networks of proteins, globally integrated and
                                        scored.” <span className="italic">Nucleic Acids Res.</span> Jan; 39:D561-8.{' '}
                                        <DoiLink doi="10.1093/nar/gkq973" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Jensen LJ, Kuhn M, Stark M, Chaffron S, Creevey C, Muller J, Doerks T, Julien P,
                                        Roth A, Simonovic M, Bork P, von Mering C. (2009). “STRING 8--a global view on
                                        proteins and their functional interactions in 630 organisms.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 37:D412-6.{' '}
                                        <DoiLink doi="10.1093/nar/gkn760" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        von Mering C, Jensen LJ, Kuhn M, Chaffron S, Doerks T, Krueger B, Snel B, Bork
                                        P. (2007). “STRING 8--a global view on proteins and their functional
                                        interactions in 630 organisms.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 35:D358-62.{' '}
                                        <DoiLink doi="10.1093/nar/gkl825" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        von Mering C, Jensen LJ, Snel B, Hooper SD, Krupp M, Foglierini M, Jouffre N,
                                        Huynen MA, Bork P. (2005). “STRING: known and predicted protein-protein
                                        associations, integrated and transferred across organisms.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 33:D433-7.{' '}
                                        <DoiLink doi="10.1093/nar/gki005" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        von Mering C, Huynen M, Jaeggi D, Schmidt S, Bork P, Snel B. (2003). “STRING: a
                                        database of predicted functional associations between proteins.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Jan; 31:258-61.{' '}
                                        <DoiLink doi="10.1093/nar/gkg034" />
                                    </>
                                ),
                            },
                            {
                                custom: (
                                    <>
                                        Snel B, Lehmann G, Bork P, Huynen MA. (2000). “STRING: a web-server to retrieve
                                        and display the repeatedly occurring neighbourhood of a gene.”{' '}
                                        <span className="italic">Nucleic Acids Res.</span> Sep 15;28(18):3442-4.{' '}
                                        <DoiLink doi="10.1093/nar/28.18.3442" />
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }

            case 'venn_diagram':
                switch (plot.analysis_type) {
                    case 'overlap':
                        return [];
                    default:
                        return [];
                }

            case 'ridge_plot':
                switch (plot.analysis_type) {
                    case 'seurat_marker_expression':
                    case 'seurat_module_score':
                        return [
                            {
                                custom: (
                                    <>
                                        Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                        <span className="italic">Cell</span> (2021). doi:{' '}
                                        <a
                                            href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                            target="_blank"
                                            rel="noopener nofollow noreferrer"
                                        >
                                            10.1016/j.cell.2021.04.048
                                        </a>
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }

            case 'violin_plot':
                switch (plot.analysis_type) {
                    case 'seurat_marker_expression':
                    case 'seurat_module_score':
                        return [
                            {
                                custom: (
                                    <>
                                        Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                        <span className="italic">Cell</span> (2021). doi:{' '}
                                        <a
                                            href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                            target="_blank"
                                            rel="noopener nofollow noreferrer"
                                        >
                                            10.1016/j.cell.2021.04.048
                                        </a>
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }

            case 'cell_scatter_plot':
                switch (plot.analysis_type) {
                    case 'seurat_marker_expression':
                    case 'seurat_module_score':
                        return [
                            {
                                custom: (
                                    <>
                                        Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                        <span className="italic">Cell</span> (2021). doi:{' '}
                                        <a
                                            href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                            target="_blank"
                                            rel="noopener nofollow noreferrer"
                                        >
                                            10.1016/j.cell.2021.04.048
                                        </a>
                                    </>
                                ),
                            },
                        ];
                    default:
                        return [];
                }

            default:
                return [];
        }
    };

    const citations = getCitations();

    if (citations.length === 0) {
        return null;
    }

    return (
        <div className={className}>
            <p className="mb-1 font-semibold text-gray-900">References</p>
            <ol className="ml-4 list-outside list-decimal space-y-2">
                {citations.map((citation, i) => (
                    <li key={i}>
                        <Citation citation={citation} />
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default MethodsReferences;
