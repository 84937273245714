import { ReactNode } from 'react';
import DefaultLayout, { DefaultLayoutProps } from '@layouts/DefaultLayout';
import { NextPage } from 'next';
import DashboardLayout, { DashboardLayoutProps } from '@layouts/DashboardLayout';
import ExperimentLayout, { ExperimentLayoutProps } from '@layouts/ExperimentLayout';
import SimpleLayout, { Props as SimpleLayoutProps } from '@layouts/SimpleLayout';

export type LayoutRenderFunction = (page: ReactNode) => ReactNode;

export type PageLayout<P = unknown> = NextPage<P, P> & {
    /** Get the page layout */
    getLayout?: LayoutRenderFunction;

    /** If this page is considered part of the Application (vs Marketing Site). Used for analytics and retargeting. */
    app_page?: boolean;
};

export const getDefaultLayout =
    (props: DefaultLayoutProps = {}): LayoutRenderFunction =>
    (page) => <DefaultLayout {...props}>{page}</DefaultLayout>;

export const getDashboardLayout =
    (props: DashboardLayoutProps = {}): LayoutRenderFunction =>
    (page) => <DashboardLayout {...props}>{page}</DashboardLayout>;

export const getSimpleLayout =
    (props: SimpleLayoutProps = {}): LayoutRenderFunction =>
    (page) => <SimpleLayout {...props}>{page}</SimpleLayout>;

export const getExperimentLayout =
    (props: ExperimentLayoutProps = {}): LayoutRenderFunction =>
    (page) => <ExperimentLayout {...props}>{page}</ExperimentLayout>;
