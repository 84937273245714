import { ErrorBarLocation, ErrorBarsConfig } from '@models/PlotConfigs';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import React from 'react';
import { useFormikContext } from 'formik';

const ErrorBarLocationField = () => {
    const { values, setFieldValue } = useFormikContext<ErrorBarsConfig>();
    return (
        <div className="form-field">
            <span className="field-label">Location</span>
            <div className="flex flex-row flex-wrap">
                {(['top', 'bottom'] as ErrorBarLocation[]).map((location, i) => {
                    return (
                        <SelectableItem
                            key={`location_${location}_${i}`}
                            selected={values.error_bars_locations?.includes(location)}
                            onSelect={() =>
                                setFieldValue(
                                    'error_bars_locations',
                                    toggleValue(values.error_bars_locations ?? [], location),
                                )
                            }
                            className="mb-2 mr-2 flex flex-col items-center justify-center"
                        >
                            <span className="capitalize">{location}</span>
                        </SelectableItem>
                    );
                })}
            </div>
        </div>
    );
};

export default ErrorBarLocationField;
