import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

const useWhiteIconButtonStyles = {
    whiteButton: {
        border: '1px solid rgb(224, 231, 255)',
        minWidth: 0,
        padding: 8,
        background: 'white',
        '&:hover': {
            background: '#F9F9F9',
        },
    },
    hover: {},
};

type Props = IconButtonProps;
/**
 * An Icon button with a white background
 * @param {string | null} propName
 * @return {JSX.Element}
 * @constructor
 */
const WhiteIconButton = ({ children, ...props }: Props) => {
    return (
        <IconButton
            sx={{ '& .MuiIconButton-label': { lineHeight: 1 }, root: useWhiteIconButtonStyles.whiteButton }}
            className="bg-white"
            color="inherit"
            {...props}
            size="large"
        >
            {children}
        </IconButton>
    );
};

export default WhiteIconButton;
