import { Tooltip } from '@mui/material';
import cn from 'classnames';

export const TruncatedText = ({
    text,
    className,
    max = 26,
    fullLength = false,
}: {
    text: string | null | undefined;
    className?: string;
    max?: number;
    fullLength?: boolean;
}) => {
    if (!text) {
        return null;
    }
    const isTruncated = `${text}`.length > max;
    let truncated = `${text}`.substring(0, max);
    if (isTruncated) {
        truncated = truncated.trimEnd();
    }
    return (
        <Tooltip
            title={text}
            arrow
            placement="top"
            enterDelay={500}
            disableTouchListener={!isTruncated}
            disableFocusListener={!isTruncated}
            disableHoverListener={!isTruncated}
        >
            <span className={cn('overflow-ellipsis', { 'max-w-xs': !fullLength }, className)}>
                {`${truncated}${isTruncated ? '...' : ''}`}
            </span>
        </Tooltip>
    );
};
