import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Experiment, { CopyExperimentFromWorkflowParams } from '@models/Experiment';
import NewWorkflowModal from '@components/experiments/workflows/NewWorkflowModal';
import { StateSetter } from '@contexts/ContextTypes';
import useWorkflows from '@hooks/useWorkflows';
import Workflow, { AcceptedWorkflow, PreprocessParameters, WorkflowPreprocessParameters } from '@models/Workflow';
import PreprocessStep, { PreprocessPlot, ResultData } from '../models/PreprocessStep';
import ConfirmModal from '../components/ConfirmModal';
import EditWorkflowModal from '../components/experiments/workflows/EditWorkflowModal';
import usePreprocessStep from '../hooks/usePreprocessStep';
import { PreprocessFormValues } from '../components/experiments/preprocesses/PreprocessFormTypes';
import ConfirmRollbackPreprocessModal from '../components/experiments/workflows/ConfirmRollbackPreprocessModal';

export type ContextType = {
    _acceptPreprocess: () => void;
    _setConfirmBranchModalOpen: (open: boolean, step: PreprocessStep, workflow: Workflow) => void;
    _setConfirmRollbackModalOpen: (open: boolean, step: PreprocessStep, workflow: Workflow) => void;
    acceptPreprocessLoading: boolean;
    acceptWorkflow: () => Promise<AcceptedWorkflow | undefined>;
    acceptWorkflowError: string | null;
    acceptWorkflowLoading: boolean;
    acceptWorkflowModalOpen: boolean;
    confirmAcceptPreprocessModalOpen: boolean;
    confirmCreateModalOpen: boolean;
    copyExperimentFromWorkflow: (formValues: CopyExperimentFromWorkflowParams) => Promise<Experiment | undefined>;
    createWorkflow: (name: string) => Promise<Workflow | undefined>;
    createWorkflowError: string | null;
    createWorkflowLoading: boolean;
    getFormData: () => void;
    getPlotData: (plotId: string) => void;
    getPlotSignedUrl: (plotId: string) => void;
    getPreprocessParameters: (workflowId: string) => Promise<PreprocessParameters>;
    getWorkflows: (getParameters?: boolean) => void;
    plotData: ResultData | null;
    plotDataError: { code?: string | undefined } | null | undefined;
    plotDataLoading: boolean;
    plots: PreprocessPlot[] | null;
    plotsError: string | null;
    plotSignedUrl: string | null;
    plotSignedUrlError: string | null;
    plotSignedUrlLoading: boolean;
    plotsLoading: boolean;
    preprocessFormData: PreprocessFormValues | null;
    preprocessParameters: WorkflowPreprocessParameters[] | null;
    preprocessReadOnly: boolean;
    selectedPreprocess: PreprocessStep | null;
    selectedWorkflow: Workflow | null;
    getWorkflowPreprocessParameters: (
        workflowId: string,
        requireResolutionClusters: boolean,
    ) => Promise<WorkflowPreprocessParameters>;
    setAcceptWorkflowModalOpen: StateSetter<boolean>;
    setConfirmAcceptPreprocessModalOpen: StateSetter<boolean>;
    setConfirmCreateModalOpen: StateSetter<boolean>;
    setConfirmDeleteModalOpen: StateSetter<boolean>;
    setConfirmRollbackPreprocessModalOpen: StateSetter<boolean>;
    setEditWorkflowModalOpen: StateSetter<boolean>;
    setPlotData: StateSetter<ResultData | null>;
    setPlotSignedUrl: StateSetter<string | null>;
    setPreprocessFormData: StateSetter<PreprocessFormValues | null>;
    setPreprocessParameters: StateSetter<WorkflowPreprocessParameters[] | null>;
    setPreprocessReadOnly: StateSetter<boolean>;
    setSelectedPreprocess: StateSetter<PreprocessStep | null>;
    setSelectedWorkflow: StateSetter<Workflow | null>;
    setWorkflowsReadOnly: StateSetter<boolean>;
    summaryData: ResultData | null;
    summaryDataError: { code?: string | undefined } | null | undefined;
    summaryDataLoading: boolean;
    updatePreprocess: (formValues: any) => void;
    updatePreprocessLoading: boolean;
    updateWorkflowsOrder: (newOrder: string[]) => void;
    workflows: Workflow[] | null;
    workflowsCopying: boolean;
    workflowsError: string | null;
    workflowsLoading: boolean;
    workflowsReadOnly: boolean;
};
const ExperimentWorkflowContext = createContext<ContextType | null>(null);

export const useExperimentWorkflowContext = () => {
    const context = useContext(ExperimentWorkflowContext);
    if (!context) {
        throw new Error(
            'ExperimentWorkflowContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

export const useOptionalExperimentWorkflowContext = (): Partial<ContextType> => {
    const context = useContext(ExperimentWorkflowContext);
    if (!context) {
        return {};
    }
    return context;
};

ExperimentWorkflowContext.displayName = 'ExperimentWorkflowContext';

export const ExperimentWorkflowContextProvider = ({
    children,
    experiment,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
}) => {
    const [confirmCreateModalOpen, setConfirmCreateModalOpen] = useState(false);
    const [confirmBranchModalOpen, setConfirmBranchModalOpen] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [editWorkflowModalOpen, setEditWorkflowModalOpen] = useState(false);
    const [acceptWorkflowModalOpen, setAcceptWorkflowModalOpen] = useState(false);
    const [confirmAcceptPreprocessModalOpen, setConfirmAcceptPreprocessModalOpen] = useState(false);
    const [confirmRollbackPreprocessModalOpen, setConfirmRollbackPreprocessModalOpen] = useState(false);
    const [selectedPreprocess, setSelectedPreprocess] = useState<PreprocessStep | null>(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null);
    const [preprocessReadOnly, setPreprocessReadOnly] = useState<boolean>(false);
    const [workflowsReadOnly, setWorkflowsReadOnly] = useState<boolean>(false);
    const [workflowsCopying, setWorkflowsCopying] = useState<boolean>(false);
    const {
        acceptWorkflow,
        acceptWorkflowError,
        acceptWorkflowLoading,
        archiveError,
        archiveLoading,
        archiveWorkflow,
        copyExperimentFromWorkflow,
        createWorkflow,
        createWorkflowError,
        createWorkflowLoading,
        forkError,
        forkLoading,
        forkWorkflow,
        getPreprocessParameters,
        getWorkflowPreprocessParameters,
        getWorkflows,
        preprocessParameters,
        saveWorkflow,
        saveWorkflowError,
        saveWorkflowLoading,
        setPreprocessParameters,
        updateWorkflowsOrder,
        workflows,
        workflowsError,
        workflowsLoading,
    } = useWorkflows(experiment, selectedWorkflow);
    const {
        acceptPreprocess,
        acceptPreprocessError,
        acceptPreprocessLoading,
        getFormData,
        getPlotData,
        getPlotSignedUrl,
        plotData,
        plotDataError,
        plotDataLoading,
        plots,
        plotsError,
        plotSignedUrl,
        plotSignedUrlError,
        plotSignedUrlLoading,
        plotsLoading,
        preprocessFormData,
        rollbackPreprocess,
        rollbackPreprocessError,
        rollbackPreprocessLoading,
        setPlotData,
        setPlotSignedUrl,
        setPreprocessFormData,
        summaryData,
        summaryDataError,
        summaryDataLoading,
        updatePreprocess,
        updatePreprocessLoading,
    } = usePreprocessStep(
        experiment,
        getWorkflows,
        preprocessReadOnly,
        selectedPreprocess,
        selectedWorkflow,
        setPreprocessReadOnly,
        setSelectedPreprocess,
    );

    useEffect(() => {
        if (experiment?.accepted_workflow) {
            setWorkflowsReadOnly(true);
        } else {
            setWorkflowsReadOnly(false);
        }
    }, [experiment]);

    useEffect(() => {
        if (workflows?.some((workflow) => workflow.status === 'copying')) {
            setWorkflowsCopying(true);
        } else if (workflowsCopying) {
            setWorkflowsCopying(false);
        }
    }, [workflows]);

    const _setConfirmBranchModalOpen = (open: boolean, step: PreprocessStep, workflow: Workflow) => {
        if (open) {
            setSelectedWorkflow(workflow);
            setSelectedPreprocess(step);
        } else {
            setSelectedPreprocess(null);
        }
        setConfirmBranchModalOpen(open);
    };
    const _saveWorkflow = async (val: any) => {
        const savedWorkflow = await saveWorkflow(val);
        if (savedWorkflow && !saveWorkflowError && !saveWorkflowLoading) {
            setEditWorkflowModalOpen(false);
        }
    };
    const _archiveWorkflow = async () => {
        const archivedWorkflow = await archiveWorkflow();
        if (archivedWorkflow && !archiveError && !archiveLoading) {
            setConfirmDeleteModalOpen(false);
            setEditWorkflowModalOpen(false);
        }
    };
    const _acceptPreprocess = async () => {
        const acceptedPreprocess = await acceptPreprocess();
        if (acceptedPreprocess && !acceptPreprocessError && !acceptPreprocessLoading) {
            setConfirmAcceptPreprocessModalOpen(false);
        }
    };
    const _setConfirmRollbackModalOpen = (open: boolean, step: PreprocessStep, workflow: Workflow) => {
        if (open) {
            setSelectedWorkflow(workflow);
            setSelectedPreprocess(step);
        } else {
            setSelectedPreprocess(null);
        }
        setConfirmRollbackPreprocessModalOpen(open);
    };
    const _rollbackPreprocess = async (workflow: Workflow) => {
        setSelectedWorkflow(workflow);
        const rolledBackPreprocess = await rollbackPreprocess();
        if (rolledBackPreprocess && !rollbackPreprocessError && !rollbackPreprocessLoading) {
            setConfirmRollbackPreprocessModalOpen(false);
        }
    };

    const _copyNewWorkflow = async (newWorkflowTitle: string, copy_to_preprocess_id: string) => {
        const newWorkflow: Workflow | undefined = await forkWorkflow(newWorkflowTitle, copy_to_preprocess_id);
        if (newWorkflow && !forkError && !forkLoading) {
            setConfirmBranchModalOpen(false);
        }
    };

    return (
        <ExperimentWorkflowContext.Provider
            value={{
                _acceptPreprocess,
                _setConfirmBranchModalOpen,
                _setConfirmRollbackModalOpen,
                acceptPreprocessLoading,
                acceptWorkflow,
                acceptWorkflowError,
                acceptWorkflowLoading,
                acceptWorkflowModalOpen,
                confirmAcceptPreprocessModalOpen,
                confirmCreateModalOpen,
                copyExperimentFromWorkflow,
                createWorkflow,
                createWorkflowError,
                createWorkflowLoading,
                getFormData,
                getPlotData,
                getPlotSignedUrl,
                getPreprocessParameters,
                getWorkflowPreprocessParameters,
                getWorkflows,
                plotData,
                plotDataError,
                plotDataLoading,
                plots,
                plotsError,
                plotSignedUrl,
                plotSignedUrlError,
                plotSignedUrlLoading,
                plotsLoading,
                preprocessFormData,
                preprocessParameters,
                preprocessReadOnly,
                selectedPreprocess,
                selectedWorkflow,
                setAcceptWorkflowModalOpen,
                setConfirmAcceptPreprocessModalOpen,
                setConfirmCreateModalOpen,
                setConfirmDeleteModalOpen,
                setConfirmRollbackPreprocessModalOpen,
                setEditWorkflowModalOpen,
                setPlotData,
                setPlotSignedUrl,
                setPreprocessFormData,
                setPreprocessParameters,
                setPreprocessReadOnly,
                setSelectedPreprocess,
                setSelectedWorkflow,
                setWorkflowsReadOnly,
                summaryData,
                summaryDataError,
                summaryDataLoading,
                updatePreprocess,
                updatePreprocessLoading,
                updateWorkflowsOrder,
                workflows,
                workflowsCopying,
                workflowsError,
                workflowsLoading,
                workflowsReadOnly,
            }}
        >
            <>
                {children}
                <NewWorkflowModal
                    open={confirmBranchModalOpen}
                    onConfirm={(title) => {
                        const copy_to_preprocess_id = selectedPreprocess?.uuid ?? '';
                        _copyNewWorkflow(title, copy_to_preprocess_id);
                    }}
                    onCancel={() => setConfirmBranchModalOpen(false)}
                    confirmLoading={forkLoading}
                    title="Copy workflow"
                    description={
                        <p className="text-base tracking-tight">
                            Create a new workflow from <span className="font-semibold">{selectedWorkflow?.name}</span>{' '}
                            in order to begin modifying the preprocessing step{' '}
                            <span className="font-semibold">{selectedPreprocess?.preprocess_type?.display_name}</span>.
                        </p>
                    }
                    defaultName={`Branch from ${selectedWorkflow?.name}`}
                    submitText="Copy"
                />
                <EditWorkflowModal
                    open={editWorkflowModalOpen}
                    onConfirm={_saveWorkflow}
                    onCancel={() => setEditWorkflowModalOpen(false)}
                    confirmLoading={saveWorkflowLoading}
                />
                <ConfirmRollbackPreprocessModal
                    open={confirmRollbackPreprocessModalOpen}
                    onConfirm={_rollbackPreprocess}
                    onCancel={() => setConfirmRollbackPreprocessModalOpen(false)}
                    confirmLoading={rollbackPreprocessLoading}
                />

                <ConfirmModal
                    cancelText="Cancel"
                    confirmLoading={archiveLoading}
                    confirmText="Yes, archive workflow"
                    message="Are you sure you want to archive this workflow? This action can not be undone."
                    open={confirmDeleteModalOpen}
                    onConfirm={_archiveWorkflow}
                    onCancel={() => {
                        setConfirmDeleteModalOpen(false);
                    }}
                    title={`Archive ${selectedWorkflow?.name}?`}
                />
            </>
        </ExperimentWorkflowContext.Provider>
    );
};
