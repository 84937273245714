import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const PieChartIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 4,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_8275_161" fill="white">
                <path d="M30.7692 16.6154C30.7692 19.3786 30.025 22.0908 28.6148 24.4671C27.2045 26.8434 25.1803 28.7959 22.7548 30.1197C20.3293 31.4435 17.592 32.0895 14.8306 31.99C12.0692 31.8905 9.38552 31.0491 7.06158 29.5542C4.73764 28.0593 2.85921 25.9661 1.62365 23.4945C0.388098 21.0229 -0.15898 18.2642 0.0398995 15.5081C0.238779 12.752 1.17627 10.1004 2.75389 7.83181C4.3315 5.56321 6.491 3.76138 9.00549 2.61563L15.3846 16.6154H30.7692Z" />
            </mask>
            <path
                d="M30.7692 16.6154C30.7692 19.3786 30.025 22.0908 28.6148 24.4671C27.2045 26.8434 25.1803 28.7959 22.7548 30.1197C20.3293 31.4435 17.592 32.0895 14.8306 31.99C12.0692 31.8905 9.38552 31.0491 7.06158 29.5542C4.73764 28.0593 2.85921 25.9661 1.62365 23.4945C0.388098 21.0229 -0.15898 18.2642 0.0398995 15.5081C0.238779 12.752 1.17627 10.1004 2.75389 7.83181C4.3315 5.56321 6.491 3.76138 9.00549 2.61563L15.3846 16.6154H30.7692Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                mask="url(#path-1-inside-1_8275_161)"
            />
            <mask id="path-2-inside-2_8275_161" fill="white">
                <path d="M32 14.1538C32 11.8497 31.3886 9.58046 30.2188 7.54311C29.0491 5.50576 27.3564 3.76194 25.2878 2.4631C23.2191 1.16426 20.8371 0.349682 18.3486 0.0901058C15.8601 -0.169471 13.3403 0.133806 11.0081 0.973596L16.6154 14.1538H32Z" />
            </mask>
            <path
                d="M32 14.1538C32 11.8497 31.3886 9.58046 30.2188 7.54311C29.0491 5.50576 27.3564 3.76194 25.2878 2.4631C23.2191 1.16426 20.8371 0.349682 18.3486 0.0901058C15.8601 -0.169471 13.3403 0.133806 11.0081 0.973596L16.6154 14.1538H32Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                mask="url(#path-2-inside-2_8275_161)"
            />
        </svg>
    );
};
