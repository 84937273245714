import Experiment from '@models/Experiment';
import OrganismIcon from '@components/OrganismIcon';
import React, { ReactNode } from 'react';
import cn from 'classnames';

const ReadOnlyField = ({ title, icon, value }: { title: string; icon?: ReactNode; value: ReactNode }) => {
    return (
        <div className="flex items-center space-x-2">
            <span className="font-semibold text-default">{title}</span>
            <div className="flex items-center space-x-1">
                {icon}
                <span className="text-dark">{value}</span>
            </div>
        </div>
    );
};

type Props = { experiment: Experiment; className?: string };
const EditExperimentReadOnlyFields = ({ experiment, className }: Props) => {
    return (
        <div className={cn(className, 'space-y-2')}>
            <ReadOnlyField
                title="Organism"
                value={experiment.organism.display_name}
                icon={
                    <OrganismIcon
                        organism={experiment.organism}
                        className="h-4 w-4 items-center justify-center self-center text-grey-light"
                    />
                }
            />
            <ReadOnlyField title="Experiment type" value={experiment.type.display_name} />
            {experiment.target_type?.display_name && (
                <ReadOnlyField title="Target type" value={experiment.target_type?.display_name} />
            )}
        </div>
    );
};

export default EditExperimentReadOnlyFields;
