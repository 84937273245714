import Plot from '@models/Plot';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React from 'react';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartSubTitleField from '../../fields/ChartSubTitleField';
import MaxGeneSetsField from '../../fields/MaxGeneSetsField';
import RangeFieldColorGroup from '../../groups/RangeFieldColorGroup';
import SingleSelectField from '@/src/components/filters/fields/SingleSelectField';
import { SeuratOverRepresentationAnalysis } from '@/src/models/analysis/SeuratOverRepresentationAnalysis';
import TransposeField from '../../fields/TransposeField';

type Props = { plot: Plot };
const SeuratOverRepresentationFields = ({ plot }: Props) => {
    const analysis = plot.analysis as SeuratOverRepresentationAnalysis | null;

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={'Top enriched pathways'} />
            <ChartSubTitleField placeholder={analysis?.differential_analysis?.name} />
            <TransposeField />
            <section className="flex flex-1 flex-row items-center space-x-4">
                <div className="flex-2">
                    <MaxGeneSetsField />
                </div>
                <div className="flex-1">
                    <SingleSelectField
                        className="mb-2"
                        name="max_gene_set_group_option"
                        options={[
                            { value: 'any', label: 'All groups' },
                            { value: 'per', label: 'Per group' },
                        ]}
                        variant="standard"
                    />
                </div>
            </section>

            <section>
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <RangeFieldColorGroup hintText="dot plot" />
            </section>
        </>
    );
};

export default SeuratOverRepresentationFields;
