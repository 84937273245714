import Experiment from '@models/Experiment';
import useSWR, { useSWRConfig } from 'swr';
import { ExperimentFileMap } from '@models/ExperimentFile';
import Endpoints from '@services/Endpoints';
import PlutoFile, { FileDataType } from '@models/PlutoFile';
import useApi from '@hooks/useApi';

type Props = { experiment: Experiment | null | undefined; data_type?: FileDataType };
const useExperimentFiles = ({ experiment, data_type }: Props) => {
    const { mutate } = useSWRConfig();
    const { fetcher } = useApi();
    const { data, error } = useSWR<Partial<ExperimentFileMap>>(() =>
        experiment?.uuid ? Endpoints.lab.experiment.files({ experimentId: experiment.uuid }, { data_type }) : null,
    );

    const refreshFiles = async () => {
        if (!experiment) return;
        const newFiles = await fetcher<Partial<ExperimentFileMap>>(
            Endpoints.lab.experiment.files({ experimentId: experiment.uuid }, { data_type }),
        );
        mutate(Endpoints.lab.experiment.files({ experimentId: experiment.uuid }, { data_type }), {
            ...newFiles,
        });
    };

    const downloadFile = async (file: PlutoFile, experimentId: string) => {
        const link = document.createElement('a');
        const downloadUrl = await fetcher<string>(
            Endpoints.lab.experiment.fileDownload({ experimentId, fileId: file.uuid }, { filename: file.filename }),
            { method: 'GET' },
        );
        link.href = downloadUrl['url'];
        link.download = file.filename;
        link.click();
    };

    const loading = !data && !error;

    return { data, error, loading, refreshFiles, downloadFile };
};

export default useExperimentFiles;
