import { RefObject, useCallback, useMemo, useState } from 'react';
import { throttle, debounce } from 'throttle-debounce';
import useResizeObserver from 'use-resize-observer';

export type ObservedSize = {
    width?: number | undefined;
    height?: number | undefined;
};
export default <T extends Element>(options?: {
    wait?: number;
    ref?: RefObject<T> | T | null | undefined;
    initialSize?: ObservedSize;
    noLeading?: boolean;
    noTrailing?: boolean;
    useDebounce?: boolean;
}) => {
    const { wait = 200, ref: inputRef, noTrailing, noLeading = true, initialSize, useDebounce = false } = options ?? {};
    const [size, setSize] = useState<ObservedSize>(initialSize ?? {});
    const resizeHandler = useCallback((size: ObservedSize) => {
        requestAnimationFrame(() => {
            setSize(size);
        });
    }, []);
    const onResize = useMemo(() => {
        if (!useDebounce) {
            return throttle(wait, resizeHandler, { noLeading, noTrailing });
        }
        return debounce(wait, resizeHandler, { atBegin: false });
    }, [wait, useDebounce]);
    const { ref } = useResizeObserver<T>({ onResize, ref: inputRef, box: 'border-box' });
    return { ref, size };
};
