import useSWR from 'swr';
import { PaginationResponse } from '@services/EndpointUtil';
import { Project } from '@models/Project';
import Endpoints from '@services/Endpoints';
import useAuth from '@hooks/useAuth';

const useProjects = () => {
    const { user } = useAuth();
    const {
        data: projectPage,
        error: projectsError,
        ...swrStuff
    } = useSWR<PaginationResponse<Project>>(() => (user ? Endpoints.lab.projects() : null));

    return { projectPage, projectsError, projectsLoading: !projectPage && !projectsError, ...swrStuff };
};

export default useProjects;
