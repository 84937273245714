import CreateSeuratObjectFormFields from './forms/CreateSeuratObjectFormFields';
import NormalizeSeuratObjectFormFields from './forms/NormalizeSeuratObjectFormFields';
import FilterSeuratObjectFormFields from './forms/FilterSeuratObjectFormFields';
import IntegrateSeuratObjectFormFields from './forms/IntegrateSeuratObjectFormFields';
import ClusterSeuratObjectFormFields from './forms/ClusterSeuratObjectFormFields';
import RefineSeuratObjectFormFields from './forms/RefineSeuratObjectFormFields';
import PreprocessStep from '@models/PreprocessStep';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';

type Props = { preprocess: PreprocessStep | null };

export const PreprocessFormFields = ({ preprocess }: Props) => {
    const preprocessType = preprocess?.preprocess_type?.shortname;
    const { preprocessReadOnly, workflowsReadOnly } = useExperimentWorkflowContext();
    const readOnly = preprocessReadOnly || workflowsReadOnly;

    if (preprocessType === 'seurat_create_object') {
        return <CreateSeuratObjectFormFields readOnly={readOnly} />;
    }
    if (preprocessType === 'seurat_filter_object') {
        return <FilterSeuratObjectFormFields readOnly={readOnly} />;
    }
    if (preprocessType === 'seurat_normalize_object') {
        return <NormalizeSeuratObjectFormFields readOnly={readOnly} />;
    }
    if (preprocessType === 'seurat_integrate_object') {
        return <IntegrateSeuratObjectFormFields readOnly={readOnly} />;
    }
    if (preprocessType === 'seurat_cluster_object') {
        return <ClusterSeuratObjectFormFields readOnly={readOnly} />;
    }
    if (preprocessType === 'seurat_refine_object') {
        return <RefineSeuratObjectFormFields readOnly={readOnly} />;
    }

    return null;
};

export default PreprocessFormFields;
