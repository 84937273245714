import React, { useState } from 'react';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import RefPointLabelField from '@components/experiments/plotDisplay/fields/RefPointLabelField';
import PlotsToShowSelectField from '@components/experiments/plotDisplay/fields/PlotsToShowSelectField';
import ZRangeFieldGroup from '@components/experiments/plotDisplay/groups/ZRangeFieldGroup';
import PlotStatisticSelectField from '@components/experiments/plotDisplay/fields/PlotStatisticSelectField';
import Plot from '@models/Plot';
import { PlotsToShowConfig } from '@models/PlotConfigs';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useFormikContext } from 'formik';

type Props = { plot: Plot };
const TornadoPlotDisplayFields = ({ plot }: Props) => {
    const [advancedExpanded, setAdvancedExpanded] = useState(false);
    const { values } = useFormikContext<PlotsToShowConfig>();
    const showPlotStatistic = values.plots_to_show.includes('p');

    return (
        <div className="space-y-6">
            <ChartTitleField placeholder={plot.analysis?.name} />
            <section>
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Heatmap</h4>
                <RefPointLabelField />
                <ZRangeFieldGroup />
                <PlotsToShowSelectField />
                {showPlotStatistic && (
                    <>
                        <h4
                            className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                            onClick={() => setAdvancedExpanded(!advancedExpanded)}
                        >
                            <span>Advanced settings</span>
                            <span>
                                <ChevronRightIcon
                                    width={20}
                                    className={cn(
                                        'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                        { 'rotate-90': advancedExpanded },
                                    )}
                                />
                            </span>
                        </h4>
                        <Collapse className="" in={advancedExpanded}>
                            {showPlotStatistic && <PlotStatisticSelectField label="Profile Plot Statistic" />}
                        </Collapse>
                    </>
                )}
            </section>
        </div>
    );
};

export default TornadoPlotDisplayFields;
