import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters, GeneSetEnrichmentAnalysisParameters } from '@models/AnalysisParameters';
import { isDefined } from '@util/TypeGuards';
import React, { useState } from 'react';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import SearchableGeneSetTableDialog from '@components/experiments/SearchableGeneSetTableDialog';
import Button from '@components/Button';
import { ArrowsExpandIcon } from '@heroicons/react/outline';

type Props = {
    plot: Plot;
    experiment: Experiment;
    analysisParameters?: AnalysisParameters | null;
    showTableButton?: boolean;
};
const SelectedCollectionsField = ({ plot, analysisParameters, experiment, showTableButton = false }: Props) => {
    const [geneSetOpen, setGeneSetOpen] = useState(false);
    const analysis = plot.analysis as GeneSetEnrichmentAnalysis | null;
    const allCollections = (analysisParameters as GeneSetEnrichmentAnalysisParameters | null)?.gene_set_collections;
    const selectedCollections =
        analysis?.gene_set_collections
            ?.map((collectionId) => allCollections?.find((c) => c.id === collectionId))
            .filter(isDefined) ?? [];
    return (
        <div className="mb-8">
            <p className="field-label">Collection(s)</p>
            <ul className="space-y-2 pl-2">
                {selectedCollections.map((c, i) => (
                    <li key={c.id}>
                        <div className="flex items-center justify-between">
                            <span>{c.display_name}</span>
                            {i === 0 && showTableButton && (
                                <div>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => setGeneSetOpen(true)}
                                        startIcon={<ArrowsExpandIcon width={14} />}
                                    >
                                        <span className="whitespace-nowrap text-xs">View all gene sets</span>
                                    </Button>
                                    <SearchableGeneSetTableDialog
                                        open={geneSetOpen}
                                        setOpen={setGeneSetOpen}
                                        experiment={experiment}
                                        plot={plot}
                                        hideSelectColumn
                                    />
                                </div>
                            )}
                        </div>
                    </li>
                ))}
                {selectedCollections.length === 0 && <span>No collections selected</span>}
            </ul>
        </div>
    );
};

export default SelectedCollectionsField;
