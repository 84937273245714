import cn from 'classnames';
import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

const VennDiagramIcon = ({ className = defaultTextLightClass, height = 37, width = 39 }: IconProps) => {
    return (
        <svg
            viewBox="0 0 39 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <circle cx="12.5" cy="24.5" r="11.5" stroke="#6F7FE7" strokeWidth="2" />
            <circle cx="26.5" cy="24.5" r="11.5" stroke="#6F7FE7" strokeWidth="2" />
            <circle cx="20.5" cy="12.5" r="11.5" stroke="#6F7FE7" strokeWidth="2" />
        </svg>
    );
};

export default VennDiagramIcon;
