import Plot from '@models/Plot';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React from 'react';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import RangeFieldColorGroup from '../../groups/RangeFieldColorGroup';
import { SampleCorrelationAnalysis } from '@models/analysis/SampleCorrelationAnalysis';

type Props = { plot: Plot };
const SampleCorrelationFields = ({ plot }: Props) => {
    const analysis = plot.analysis as SampleCorrelationAnalysis | null;

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={analysis?.name} />

            <section>
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <RangeFieldColorGroup
                    endBottomOffsetClassname="-top-48 mb-8"
                    endLeftOffsetClassname="-translate-x-36"
                    hintText="dot plot"
                    startBottomOffsetClassname="-top-48 mb-8"
                    startLeftOffsetClassname="translate-x-6"
                />
            </section>
        </>
    );
};

export default SampleCorrelationFields;
