import DataTable from '@components/dataTable/DataTable';
import LoadingMessage from '@components/LoadingMessage';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from '@mui/material';
import Experiment from '@models/Experiment';
import { generateResultDataFileName } from '@util/ExperimentUtil';
import { geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import { ResultData } from '@models/PreprocessStep';
import MoreMenuIconButton, { MoreMenuItem } from '../../MoreMenuIconButton';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import DownloadDataMoreMenuItem from '../../DownloadDataMoreMenuItem';
import cn from 'classnames';

const StatusMessage = () => {
    return (
        <Alert severity="error" className="mx-auto max-w-lg">
            <div className="">Failed to fetch results</div>
        </Alert>
    );
};

export type Props = {
    experiment: Experiment;
    data: ResultData | null;
    loading: boolean;

    error: { code?: string } | null | undefined;
};
const AnnotationsDataTable = ({ experiment, data, loading, error }: Props) => {
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortedData, setSortedData] = useState<ResultData | null>(null);
    const [sorting, setSorting] = useState<boolean>(false);
    const [sortDesc, setSortDesc] = useState(false);
    const permissions = useExperimentPermissions(experiment);

    const handleResultSort = async (colId, desc: boolean) => {
        if (!sortedData || !sortedData.items) return;

        setSorting(true);
        const sortedItems = await [...sortedData.items].sort((a, b) => {
            const aVal = a[colId];
            const bVal = b[colId];
            if (typeof aVal === 'string' || typeof bVal === 'string')
                return desc
                    ? aVal.toString().localeCompare(bVal.toString())
                    : bVal.toString().localeCompare(aVal.toString());
            return desc ? Number(bVal) - Number(aVal) : Number(aVal) - Number(bVal);
        });
        const newData = { ...sortedData, items: sortedItems };

        setSorting(false);
        setSortedData(newData);
    };

    useEffect(() => {
        if (!data || !data.headers) return;
        const formattedData = { ...data } as ResultData;
        formattedData.headers = formattedData?.headers.filter((h) => h !== 'Cluster');

        setSortedData(formattedData);
    }, [data]);

    useEffect(() => {
        handleResultSort(sortBy, sortDesc);
    }, [sortBy, sortDesc]);

    const downloadFilename = data ? generateResultDataFileName({ experiment, data }) : '';
    const downloadEnabled = Boolean(data?.download_url);

    const { menuItems } = useMemo(() => {
        const menuItems: MoreMenuItem[] = [];
        if (downloadEnabled && data) {
            menuItems.push(
                <DownloadDataMoreMenuItem url={data.download_url} baseFilename={downloadFilename} extension=".csv" />,
            );
        }

        return { menuItems };
    }, [experiment, permissions]);

    const renderTable = () => {
        if (!!loading)
            return (
                <div className="mb-4 mt-2 space-y-4">
                    <LoadingMessage message="Loading results..." size={24} />
                </div>
            );
        if (error) return <StatusMessage />;
        if (!sortedData) return <p className="mb-2 mt-2 text-default">Select a cluster to view its top markers</p>;

        return (
            <div
                className={cn('mt-10 w-full', {
                    'bg-white opacity-50': !!sorting,
                })}
            >
                <DataTable
                    isSorting={sorting}
                    data={sortedData}
                    tableColor="bg-cyan-100 text-cyan-900"
                    key={sortedData.uuid ?? 'no_data'}
                    cellRenderer={geneLinkCellRenderer({ experiment })}
                    onSortedChange={(header, desc) => {
                        setSortBy(header);
                        setSortDesc(desc ?? false);
                    }}
                    sortBy={sortBy ?? undefined}
                    sortable
                    filterable
                    manualSortBy
                    pageSize={250}
                    sortDesc={sortDesc}
                    moreMenuButton={<MoreMenuIconButton outlined items={menuItems} />}
                />
            </div>
        );
    };

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between">
                <p className="z-10 text-lg font-semibold text-default">Marker genes</p>
            </div>
            {renderTable()}
        </div>
    );
};
export default AnnotationsDataTable;
