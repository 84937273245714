import { ThemeablePlotChartDisplayOption } from '@models/plotDisplayOption/BasePlotDisplayOption';
import { ComparativeHeatmapColorScale, SummaryHeatmapColorScale } from '@models/PlotConfigs';

export type SummaryType = 'median' | 'mean' | 'none';
export default interface HeatmapDisplayOption extends ThemeablePlotChartDisplayOption {
    summarize_values_by: SummaryType;
    heatmap_scale_color?: ComparativeHeatmapColorScale | SummaryHeatmapColorScale;
    adj_p_value_threshold?: number | null;
    is_transposed?: boolean;
}

export const getSummaryTypeDisplayName = (summarize_by?: SummaryType) => {
    switch (summarize_by) {
        case 'median':
            return 'median';
        case 'mean':
            return 'average';
        case 'none':
        default:
            return 'raw';
    }
};
