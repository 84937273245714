import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { TimeoutValue } from '@util/ObjectUtil';
import Logger from '@util/Logger';

const logger = Logger.make('hooks/useCopyToClipboard');

type Timeout = TimeoutValue;

type Props = { ref: MutableRefObject<HTMLElement | null>; successTimeoutDuration?: number };
const useCopyToClipboard = ({ ref, successTimeoutDuration = 2500 }: Props) => {
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    const successTimeoutRef = useRef<Timeout | null>(null);

    const clearSuccessTimeout = () => {
        if (successTimeoutRef.current) {
            logger.info('clearing success timeout...');
            clearTimeout(successTimeoutRef.current);
            setShowCopySuccess(false);
        }
    };

    const handleCopySuccess = useCallback(() => {
        setShowCopySuccess(true);
        logger.debug('setting copy to clipboard success');
        if (successTimeoutDuration) {
            logger.debug('copy success timeout is ', successTimeoutDuration);
            successTimeoutRef.current = setTimeout(() => {
                logger.debug('removing copy to clipboard success');
                setShowCopySuccess(false);
                successTimeoutRef.current = null;
            }, successTimeoutDuration);
        }
    }, [successTimeoutDuration]);

    const highlightText = () => {
        try {
            const el = ref.current;
            if (!el) {
                logger.error(new Error('Unable to select text: no element selected'));
                return;
            }
            const range = document.createRange();
            range.selectNodeContents(el);
            const selection = window.getSelection();
            if (!selection) {
                logger.error(new Error('Unable to select text: no selection could be created'));
                return;
            }
            selection?.removeAllRanges();
            selection?.addRange(range);
        } catch (error) {
            logger.error(error);
        }
    };

    const getSelectedText = () => {
        let selectedText = '';
        if (window.getSelection) {
            // all modern browsers and IE9+
            selectedText = window.getSelection()?.toString() ?? '';
        }
        return selectedText;
    };

    const copyText = async (options?: { highlight?: boolean; textAsString?: string }) => {
        const { highlight = true } = options ?? {};
        try {
            const el = ref.current;
            if (!el) {
                logger.error(new Error('Unable to copy text to clipboard: no element selected'));
                return;
            }
            if (highlight) {
                highlightText();
            }
            const text = options?.textAsString ?? el.innerText;

            // Do modern approach first, else fall back to deprecated method
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text);
                handleCopySuccess();
            } else {
                logger.error(new Error('Unsupported copy method - navigator.clipboard is undefined'));
            }
        } catch (error) {
            logger.error(error);
        }
    };

    useEffect(() => {
        return () => {
            clearSuccessTimeout();
        };
    }, []);

    return { ref, copyText, getSelectedText, showCopySuccess, highlightText };
};

export default useCopyToClipboard;
