import React from 'react';
import { Citation, CitationItem } from '@components/experiments/methods/Citation';
import PreprocessStep from '@models/PreprocessStep';
import {
    IntegrateSeuratObjectFormValues,
    NormalizeSeuratObjectFormValues,
    PreprocessFormValues,
    RefineSeuratObjectFormValues,
} from '../preprocesses/PreprocessFormTypes';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';

type Props = {
    preprocess: PreprocessStep;
    className?: string;
};
const MethodsPreprocessReferences = ({ preprocess, className }: Props) => {
    const preprocessType = preprocess?.preprocess_type?.shortname;
    const { preprocessFormData } = useExperimentWorkflowContext();
    const formData = preprocessFormData as PreprocessFormValues;

    const getCitations = (): CitationItem[] => {
        switch (preprocessType) {
            case 'seurat_prepare_data': {
                return [
                    {
                        custom: (
                            <>
                                Zheng et al. (2017). Massively parallel digital transcriptional profiling of single
                                cells. <span className="italic">Nature Communications</span>.{' '}
                                <span className="font-bold">8</span>: 1-12. doi:{' '}
                                <a
                                    href="https://doi.org/doi:10.1038/ncomms14049/"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1038/ncomms14049
                                </a>
                            </>
                        ),
                    },
                    {
                        custom: (
                            <>
                                Germain et al. (2022). Doublet identification in single-cell sequencing data using
                                scDblFinder. <span className="italic">f1000research</span>. doi:{' '}
                                <a
                                    href="https://doi.org/10.12688/f1000research.73600.2"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.12688/f1000research.73600.2
                                </a>
                            </>
                        ),
                    },
                ];
            }
            case 'seurat_create_object': {
                return [
                    {
                        custom: (
                            <>
                                Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                <span className="italic">Cell</span> (2021). doi:{' '}
                                <a
                                    href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2021.04.048
                                </a>
                            </>
                        ),
                    },
                    {
                        custom: (
                            <>
                                Germain et al. (2022). Doublet identification in single-cell sequencing data using
                                scDblFinder. <span className="italic">f1000research</span>. doi:{' '}
                                <a
                                    href="https://doi.org/10.12688/f1000research.73600.2"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.12688/f1000research.73600.2
                                </a>
                            </>
                        ),
                    },
                ];
            }
            case 'seurat_filter_object': {
                return [
                    {
                        custom: (
                            <>
                                Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                <span className="italic">Cell</span> (2021). doi:{' '}
                                <a
                                    href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2021.04.048
                                </a>
                            </>
                        ),
                    },
                ];
            }
            case 'seurat_refine_object':
            case 'seurat_cluster_object': {
                const refineMethodSkipped = (formData as RefineSeuratObjectFormValues)?.refine_method === 'skip';
                if (refineMethodSkipped) {
                    return [];
                }
                return [
                    {
                        custom: (
                            <>
                                Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                <span className="italic">Cell</span> (2021). doi:{' '}
                                <a
                                    href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2021.04.048
                                </a>
                            </>
                        ),
                    },
                ];
            }
            case 'seurat_integrate_object': {
                const intMethodSkipped = (formData as IntegrateSeuratObjectFormValues)?.int_method === 'skip';
                if (intMethodSkipped) {
                    return [];
                }
                const citations = [
                    {
                        custom: (
                            <>
                                Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                <span className="italic">Cell</span> (2021). doi:{' '}
                                <a
                                    href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2021.04.048
                                </a>
                            </>
                        ),
                    },
                ];
                const data = formData as IntegrateSeuratObjectFormValues;
                if (data?.int_method === 'harmony') {
                    citations.push({
                        custom: (
                            <>
                                Korsunsky et al. Fast, sensitive and accurate integration of single-cell data with
                                Harmony. <span className="italic">Nature Methods</span> (2019). doi:{' '}
                                <a
                                    href="https://doi.org/10.1038/s41592-019-0619-0"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1038/s41592-019-0619-0
                                </a>
                            </>
                        ),
                    });
                }
                if (data?.int_method === 'rpca' || data?.int_method === 'cca') {
                    citations.push({
                        custom: (
                            <>
                                Stuart and Butler et al. Comprehensive Integration of Single-Cell Data{' '}
                                <span className="italic">Cell</span> (2019). doi:{' '}
                                <a
                                    href="https://doi.org/10.1016/j.cell.2019.05.031"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2019.05.031
                                </a>
                            </>
                        ),
                    });
                }
                if (data?.norm_method === 'sctransform') {
                    citations.push({
                        custom: (
                            <>
                                Hefemeister and Satija. Normalization and variance stabilization of single-cell RNA-seq
                                data using regularized negative binomial regression.{' '}
                                <span className="italic">Genome Biology</span> (2019). doi:{' '}
                                <a
                                    href="https://genomebiology.biomedcentral.com/articles/10.1186/s13059-019-1874-1"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1186/s13059-019-1874-1
                                </a>
                            </>
                        ),
                    });
                }
                return citations;
            }
            case 'seurat_normalize_object': {
                const citations = [
                    {
                        custom: (
                            <>
                                Hao and Hao et al. Integrated analysis of multimodal single-cell data.{' '}
                                <span className="italic">Cell</span> (2021). doi:{' '}
                                <a
                                    href="http://dx.doi.org/10.1016/j.cell.2021.04.048"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1016/j.cell.2021.04.048
                                </a>
                            </>
                        ),
                    },
                ];
                if ((formData as NormalizeSeuratObjectFormValues)?.norm_method === 'sctransform') {
                    citations.push({
                        custom: (
                            <>
                                Hefemeister and Satija. Normalization and variance stabilization of single-cell RNA-seq
                                data using regularized negative binomial regression.{' '}
                                <span className="italic">Genome Biology</span> (2019). doi:{' '}
                                <a
                                    href="https://genomebiology.biomedcentral.com/articles/10.1186/s13059-019-1874-1"
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                >
                                    10.1186/s13059-019-1874-1
                                </a>
                            </>
                        ),
                    });
                }
                return citations;
            }

            default:
                return [];
        }
    };

    const citations = getCitations();

    if (citations.length === 0) {
        return null;
    }

    return (
        <div className={className}>
            <p className="mb-1 font-semibold text-gray-900">References</p>
            <ol className="ml-4 list-outside list-decimal space-y-2">
                {citations.map((citation, i) => (
                    <li key={i}>
                        <Citation citation={citation} />
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default MethodsPreprocessReferences;
