import React from 'react';
import { useFormikContext } from 'formik';
import { BasePlotDisplayOptionFormValues } from '@models/PlotDisplayOption';

const FullWidthToggleField = () => {
    const { values, handleChange } = useFormikContext<BasePlotDisplayOptionFormValues>();
    return (
        <label className="mb-8 block pt-1">
            <input
                type="checkbox"
                className="rounded text-indigo-500"
                name="is_full_width"
                checked={values.is_full_width}
                onChange={handleChange}
            />
            <span className="ml-2">Show full-width on canvas</span>
        </label>
    );
};

export default FullWidthToggleField;
