import { IconProps } from '@components/icons/CustomIcons';

export const CholeraeIcon = (props?: IconProps) => {
    const { width = 44, height = 28, ...svgProps } = props ?? {};

    return (
        <svg
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
            height={height}
            width={width}
        >
            <rect width="21" height="21" fill="none" />
            <path
                d="M4.32461 11.0375C3.89608 11.8474 3.22465 13.6781 3.96714 14.5218C4.70962 15.3654 5.35371 15.2797 5.49754 15.415C6.08363 15.675 6.47731 17.0639 6.6863 17.4423C6.77318 17.7666 7.35552 18.2535 8.98982 17.607"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path
                d="M16.454 5.62379C13.5208 5.66495 10.7742 5.71442 5.47963 9.81434"
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default CholeraeIcon;
