import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const UMAPAnalysisIcon = ({ width = 32, height = 32, className = defaultTextLightClass, style }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m5 7.713 10 1.928v9.641L5 17.354v-9.64Z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 16 4 1V6.785L9 4.857V8"
            />
            <rect width="22" height="22" x="1" y="1" stroke="currentColor" strokeWidth="2" rx="2" />
            <path
                stroke="currentColor"
                fill="currentColor"
                strokeWidth=".25"
                d="M8.618 15.098c.33.372.787.603 1.351.708.375.07.707.065.99-.02.285-.084.506-.243.659-.477.153-.233.228-.52.228-.858v-2.33a.724.724 0 0 0-.57-.685.465.465 0 0 0-.57.474v2.174c0 .32-.065.518-.17.623-.099.098-.27.139-.544.088-.285-.053-.46-.158-.558-.293-.103-.14-.167-.36-.167-.679v-2.18a.724.724 0 0 0-.57-.685.464.464 0 0 0-.57.474v2.318c0 .52.16.975.49 1.348Z"
            />
        </svg>
    );
};
