import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { BenchlingPlotData } from './BenchlingExperimentMultiExportDialog';
import { DisplayTypeIcon } from '../ExperimentIcons';
import { getPlotDisplayTitle } from '../../plots/PlotUtil';
import Plot from '@models/Plot';
import useFetchPlot from '@/src/hooks/useFetchPlot';

const PlotItem = ({
    onChange,
    disabled,
    dataCy,
    plotExportData,
    csvSelected: csvSel,
    htmlSelected: htmlSel,
    analysisType,
    plotId,
    experimentId,
}: {
    onChange: (selected: boolean, dataType: string, plot: Plot) => void;
    disabled?: boolean;
    dataCy?: string;
    plotExportData?: BenchlingPlotData;
    csvSelected?: boolean;
    htmlSelected?: boolean;
    analysisType?: string;
    plotId: string;
    experimentId: string;
}) => {
    const [htmlSelected, setHtmlSelected] = useState<boolean>(plotExportData?.file_exported || htmlSel || false);
    const [csvSelected, setCsvSelected] = useState<boolean>(plotExportData?.dataset_exported || csvSel || false);
    const { plot, plotError } = useFetchPlot({ plotId, experimentId });

    const handleChange = (label: string, val: boolean, setter: (val: boolean) => void) => {
        if (!plot) return;

        setter(val);
        onChange(val, label, plot);
    };

    const noCSV =
        analysisType === 'peak' || analysisType === 'text' || analysisType === 'image' || analysisType === 'prism';

    if (!!plotError)
        return (
            <div
                className={'flex items-center justify-between rounded-lg border border-indigo-300 p-2'}
                data-cy={dataCy}
            >
                <p className="text-default">{plotError}</p>
            </div>
        );

    if (!plot)
        return (
            <div
                className={'flex items-center justify-between rounded-lg border border-indigo-300 p-2'}
                data-cy={dataCy}
            >
                <p className="text-default">Loading...</p>
            </div>
        );

    return (
        <div className={'flex items-center justify-between rounded-lg border border-indigo-300 p-2'} data-cy={dataCy}>
            <div className="flex w-9/12 items-center space-x-4 pl-4">
                <div>
                    <DisplayTypeIcon type={plot.display.display_type} width={22} />
                </div>
                <div>
                    <p className="font-semibold">{getPlotDisplayTitle(plot)}</p>
                </div>
            </div>
            <div className="flex w-36 flex-row justify-between">
                <Checkbox
                    disabled={disabled}
                    checked={htmlSelected}
                    onClick={() => handleChange('html', !htmlSelected, setHtmlSelected)}
                />
                {noCSV ? (
                    <div />
                ) : (
                    <Checkbox
                        disabled={disabled}
                        checked={csvSelected}
                        onClick={() => handleChange('csv', !csvSelected, setCsvSelected)}
                    />
                )}
            </div>
        </div>
    );
};

export default PlotItem;
