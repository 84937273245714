import TextInputField from '@/src/components/forms/TextInputField';
import React from 'react';

interface Props {
    label?: string;
    name: string;
    placeholder?: string;
}

const ListNameField: React.FC<Props> = ({ name, label = 'List Name', placeholder }) => {
    return (
        <div className="">
            <TextInputField name={name} label={label} className="!mb-4" placeholder={placeholder} />
        </div>
    );
};

export default ListNameField;
