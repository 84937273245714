import { IconProps } from '@components/icons/CustomIcons';

export const MouseIcon = (props?: IconProps) => {
    const { width = 44, height = 28 } = { ...props };
    return (
        <svg {...{ height, width }} viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.675 17.89c-.672-3.565-3.026-6.995-6.187-9.954.269-.269.538-.605.74-.941 1.412-2.287 1.008-5.112-.875-6.322-1.883-1.211-4.64-.337-6.053 1.95-.134.202-.201.403-.336.673C27.131 1.21 22.894 0 19.06 0 8.682 0-.99 8.78.08 20.042h41.711c1.143-.067 2.085-1.076 1.883-2.152zm-8.743-3.766a1.88 1.88 0 01-1.883-1.883 1.88 1.88 0 011.883-1.884c1.009 0 1.816.807 1.816 1.884 0 1.076-.807 1.883-1.816 1.883z"
                fill="currentColor"
            />
            <path
                d="M1 19.5c.369 5.719 5.5 6 9.5 5s12.116-2.314 13.635 2.244"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
