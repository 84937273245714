import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import {
    StatisticalTestShortname,
    StatisticalTestType,
    SummaryStatisticalTestResult,
} from '@models/SummaryStatisticalTestResult';
import Button from '@components/Button';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import { ButtonGroupItem } from '@components/ButtonGroup';
import SelectableItem from '@components/forms/SelectableItem';
import { FormikFieldError } from '@components/forms/FieldError';
import { AssaySummaryAnalysisParameters } from '@models/AnalysisParameters';
import * as Yup from 'yup';
import { StatisticalTestFormValues } from '@hooks/useStatisticalTest';
import TestTypeDisplayName from '@components/experiments/TestTypeDisplayName';
import { isDefined } from '@util/TypeGuards';
import copy from '@copy/Copy';
import Logger from '@util/Logger';
import PlotStatisticsGroupField from '@components/experiments/PlotStatisticsGroupField';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

type FormValues = StatisticalTestFormValues;

const logger = Logger.make('PlotStatisticsForm');

const getSchema = ({ testTypes }: { testTypes: StatisticalTestType[] }) => {
    return Yup.object({
        target: Yup.string().nullable().required('Please choose a target'),
        test_type: Yup.string().required('Please choose a test type').nullable(),
        groups: Yup.array()
            .required('Please choose groups')
            .when('test_type', (shortname: StatisticalTestShortname, schema: Yup.ArraySchema<Yup.AnySchema>) => {
                const testType = testTypes.find((t) => t.shortname === shortname);
                const min = testType?.minimum_groups ?? null;
                const max = testType?.maximum_groups ?? null;
                const isExact = isDefined(min) && isDefined(max) && min === max;
                if (isExact) {
                    return schema
                        .min(min, `Must select exactly ${min} groups`)
                        .max(max, `Must select exactly ${max} groups`);
                }

                if (isDefined(min) && isDefined(max)) {
                    return schema
                        .min(min, `Please choose between ${min} and ${max} groups`)
                        .max(max, `Please choose no more than ${max} groups`);
                }

                if (!max && isDefined(min)) {
                    return schema.min(min, `Please choose at least ${min} groups`);
                }
                return schema;
            }),
    });
};
type Props = {
    plot: Plot;
    experiment: Experiment;
    createStatisticalTest: (values: StatisticalTestFormValues) => Promise<SummaryStatisticalTestResult | null>;
};
const PlotStatisticsForm = ({ plot, experiment, createStatisticalTest }: Props) => {
    const analysis = plot.analysis as AssaySummaryAnalysis | null;
    const { statisticalTestTypes } = useExperimentSettings(experiment);
    const { getTargetById } = useAnalysisParameters<AssaySummaryAnalysisParameters>({
        plot,
        experiment,
    });

    const initialValues: FormValues = {
        target: null,
        test_type: null,
        variables: analysis?.variables ?? [],
        groups: [],
    };

    const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        logger.debug('PlotStatistics Submitted', values);
        await createStatisticalTest(values);
        actions.setSubmitting(false);
        actions.resetForm();
        await actions.validateForm(initialValues);
    };

    const buttonGroupItems: ButtonGroupItem<number | string>[] = (analysis?.target_display_order ?? []).map(
        (target) => ({
            label: getTargetById(target)?.display_name ?? `${target}`,
            value: target,
        }),
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnMount
            validationSchema={getSchema({ testTypes: statisticalTestTypes })}
            enableReinitialize
        >
            {({ setFieldValue, values, isSubmitting }) => {
                // const availableGroups =
                //     getGroupItems<ParameterOption & { sample_id_count?: number }>(
                //         analysisParameters?.groups ?? [],
                //         values.variables,
                //     )?.items.filter((g) => visibleGroups.includes(g.id)) ?? [];
                // logger.debug('available groups', availableGroups);
                // const testType = statisticalTestTypes.find((t) => t.shortname === values.test_type);
                // const { isExact } = getTestTypeInfo(testType);
                return (
                    <Form>
                        <label className="field-label mb-2 text-lg">Select target</label>
                        <FormikFieldError name="target" />
                        <div className="mb-4 flex flex-wrap">
                            {buttonGroupItems.map((item) => {
                                return (
                                    <SelectableItem
                                        key={item.value}
                                        selected={item.value === values.target}
                                        onSelect={() => setFieldValue('target', item.value)}
                                        className="mb-1 mr-1"
                                    >
                                        {item.label}
                                    </SelectableItem>
                                );
                            })}
                        </div>

                        <div className="form-field">
                            <label className="field-label text-lg">Select statistical test</label>
                            <FormikFieldError name="test_type" className="mb-1" />
                            <fieldset className="my-4">
                                <legend className="sr-only">Select statistical test</legend>
                                <div className="space-y-4">
                                    {statisticalTestTypes.map((testType) => (
                                        <div key={testType.shortname} className="flex items-center">
                                            <input
                                                id={testType.shortname}
                                                name="test_type"
                                                type="radio"
                                                className="h-4 w-4 border-gray-300 text-indigo-500"
                                                checked={values.test_type === testType.shortname}
                                                onChange={() => setFieldValue('test_type', testType.shortname)}
                                            />
                                            <label
                                                htmlFor={testType.shortname}
                                                className="ml-2 block text-sm font-medium text-default"
                                            >
                                                <TestTypeDisplayName test_type={testType} />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <a
                                href={copy.statisticsHelpUrl}
                                className="flex items-center space-x-2"
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                                <HelpCircleIcon className="h-4 w-4" />
                                <span>Help me choose</span>
                            </a>
                        </div>

                        <PlotStatisticsGroupField
                            statisticalTestTypes={statisticalTestTypes}
                            plot={plot}
                            experiment={experiment}
                        />

                        {/*{values.target && values.test_type && analysisParameters && (*/}
                        {/*    <div className="form-field">*/}
                        {/*        <span className="field-label">*/}
                        {/*            Select {testType?.minimum_groups ?? ''}*/}
                        {/*            {isExact ? '' : '+'} Groups*/}
                        {/*        </span>*/}
                        {/*        <FormikFieldError name="groups" className="mb-1" />*/}
                        {/*        <div className="flex flex-row flex-wrap">*/}
                        {/*            {availableGroups.map((group, index) => {*/}
                        {/*                const notEnoughSamples =*/}
                        {/*                    testType &&*/}
                        {/*                    isDefined(testType?.minimum_group_sample_size) &&*/}
                        {/*                    isDefined(group.sample_id_count) &&*/}
                        {/*                    testType.minimum_group_sample_size > 0 &&*/}
                        {/*                    group.sample_id_count < testType.minimum_group_sample_size;*/}
                        {/*                return (*/}
                        {/*                    <SelectableItem*/}
                        {/*                        key={`group_${group.id})${index}`}*/}
                        {/*                        selected={values.groups.includes(group.id)}*/}
                        {/*                        onSelect={() =>*/}
                        {/*                            setFieldValue('groups', toggleValue(values.groups, group.id))*/}
                        {/*                        }*/}
                        {/*                        className="flex flex-col justify-center items-center mr-2 mb-2"*/}
                        {/*                        disabled={notEnoughSamples}*/}
                        {/*                    >*/}
                        {/*                        {notEnoughSamples ? (*/}
                        {/*                            <Tooltip*/}
                        {/*                                title={*/}
                        {/*                                    <div>*/}
                        {/*                                        <TestTypeDisplayName test_type={testType} />*/}
                        {/*                                        <span>{` requires at least*/}
                        {/*                                        ${testType.minimum_group_sample_size ?? 1} samples. This*/}
                        {/*                                            group only has ${group.sample_id_count}.`}</span>*/}
                        {/*                                    </div>*/}
                        {/*                                }*/}
                        {/*                            >*/}
                        {/*                                <span>{formatTableHeader(group.display_name)}</span>*/}
                        {/*                            </Tooltip>*/}
                        {/*                        ) : (*/}
                        {/*                            <span>{formatTableHeader(group.display_name)}</span>*/}
                        {/*                        )}*/}
                        {/*                    </SelectableItem>*/}
                        {/*                );*/}
                        {/*            })}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        <div className="mb-4">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting || !values.test_type || !values.target}
                                loading={isSubmitting}
                            >
                                Run test
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PlotStatisticsForm;
