import Button from '@components/Button';
import React, { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import SummaryMeasuresTable from '@components/experiments/analyses/summary/pca/SummaryMeasuresTable';
import Experiment from '@models/Experiment';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

const getTitle = (analysis: ExperimentAnalysis) => {
    switch (analysis.analysis_type) {
        case 'umap':
            return 'UMAP summary measures';
        case 'principal_components':
        default:
            return 'Principal components summary measures';
    }
};

type Props = { className?: string; experiment: Experiment; analysis: ExperimentAnalysis };
const SummaryMeasuresDialogButton = ({ className, experiment, analysis }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <span className={className}>
                <Button variant="text" color="primary" size="small" className="" onClick={() => setOpen(true)}>
                    View all measures
                </Button>
            </span>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogCloseButton onClose={() => setOpen(false)} />
                <PlutoDialogTitle title={getTitle(analysis)} />
                <DialogContent className="mt-4">
                    <SummaryMeasuresTable experiment={experiment} analysis={analysis} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SummaryMeasuresDialogButton;
