import TextInput from '@components/forms/TextInput';
import { formatSmallNumber, stringToNumber } from '@util/StringUtil';
import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { Tooltip } from '@mui/material';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

type Props = {
    name: string;
    defaultValue?: number;
    showHint?: boolean;
    tooltipTitle?: string;
    hideSectionLabel?: boolean;
    sectionLabel?: string;
    label?: ReactNode;
    noMargin?: boolean;
};
const TopScoresField = ({
    name,
    showHint = false,
    tooltipTitle,
    hideSectionLabel,
    sectionLabel = 'Top scores',
    label = <span>Top scores on plot</span>,
    noMargin = false,
}: Props) => {
    const [inputProps, , { setValue }] = useField(name);
    const top_scores_filter = inputProps.value ?? '';

    return (
        <>
            {!hideSectionLabel && (
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">{sectionLabel}</h4>
            )}
            <TextInput
                name={name}
                label={
                    <div className="flex items-center">
                        <span>{label}</span>
                        {tooltipTitle && (
                            <Tooltip title={tooltipTitle} placement="top" arrow>
                                <span>
                                    <HelpCircleIcon fontSize="small" className="ml-2 cursor-pointer text-gray-400" />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                }
                type="number"
                value={top_scores_filter}
                min={0}
                step={1.0}
                noMargin={noMargin}
                hint={
                    showHint ? (
                        <span className="pl-1">
                            -log<sub>10</sub> adj <i>p</i>-value
                            {!top_scores_filter
                                ? ' = (not set)'
                                : formatSmallNumber({
                                      value: -1 * Math.log10(top_scores_filter),
                                      defaultPrefix: ' = ',
                                  })}
                        </span>
                    ) : null
                }
                onChange={(e) => setValue(stringToNumber(e.target.value))}
                useFormikError
            />
        </>
    );
};

export default TopScoresField;
