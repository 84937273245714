import React, { useEffect, useRef, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import FieldError from '@components/forms/FieldError';
import Button from '@components/Button';
import { AddRounded } from '@mui/icons-material';
import { ProjectColorDot } from '@components/dashboard/ProjectColorSelect';
import { Project } from '@models/Project';
import { PermissionName } from '@models/Permission';
import { hasPermission } from '@util/PermissionUtil';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { CreateProjectModal } from '@components/dashboard/CreateProjectModal';
import { PaginationResponse } from '@services/EndpointUtil';
import { blankToNull } from '@util/StringUtil';

const DEFAULT_LIMIT = 100;
const NONE_VALUE = 'NONE';
const styles = {
    wrap: {
        whiteSpace: 'normal',
    },
};

type Props = {
    onChange?: (project: string | null) => void;
    value?: string | null;
    label?: string;
    name: string;
    error?: string | null | boolean;
    defaultMostRecent?: boolean;
    disabled?: boolean;
    showAddProject?: boolean;
    requiredPermissions?: PermissionName[];
    matchAnyPermissions?: boolean;
    hidePermissionDenied?: boolean;
    disablePermissionDenied?: boolean;
    showNone?: boolean;
    noneLabel?: string;
    onProjectCreated?: (project: Project) => Promise<void> | void;
};

const ProjectSelect: React.FunctionComponent<Props> = ({
    onChange,
    value,
    label,
    name,
    error,
    defaultMostRecent = true,
    disabled = false,
    showAddProject = false,
    requiredPermissions = [],
    matchAnyPermissions = false,
    hidePermissionDenied = false,
    disablePermissionDenied = true,
    showNone = false,
    noneLabel = 'No project',
    onProjectCreated,
}: Props) => {
    const {
        data: projectsData,
        error: projectsError,
        mutate,
    } = useSWR<PaginationResponse<Project>>(`${Endpoints.lab.projects()}?limit=${DEFAULT_LIMIT}`);
    const loadedRef = useRef(false);
    const [createProjectOpen, setCreateProjectOpen] = useState(false);
    const isDisabled = (project: Project) => {
        if (requiredPermissions.length === 0 || !disablePermissionDenied) {
            return false;
        }
        return !hasPermission(project, { requires: requiredPermissions, any: matchAnyPermissions });
    };

    const isHidden = (project: Project) => {
        if (requiredPermissions.length === 0 || !hidePermissionDenied) {
            return false;
        }
        return !hasPermission(project, { requires: requiredPermissions, any: matchAnyPermissions });
    };

    const loading = !projectsData && !projectsError;
    const allProjects = projectsData?.items ?? [];
    const projects = allProjects.filter((p) => !isHidden(p)).sort((a, b) => a.name.localeCompare(b.name)); // Sort in ascending order

    useEffect(() => {
        if (!value && defaultMostRecent && !loading && projects.length > 0 && !loadedRef.current) {
            onChange?.(projects[0].uuid);
            loadedRef.current = true;
        }
    }, [projects, loading, defaultMostRecent, value]);

    const showNewProjectModal = () => {
        setCreateProjectOpen(true);
    };

    const handleProjectCreated = async (project: Project) => {
        await mutate((data) => ({ count: (data?.count ?? 0) + 1, items: [project, ...(data?.items ?? [])] }));
        onProjectCreated?.(project);
    };

    const noneValue = showNone ? NONE_VALUE : value;
    let currentValue = blankToNull(value) ?? noneValue;
    if (loading) {
        currentValue = 'loading';
    }
    return (
        <>
            <div className="form-field">
                <div className="flex flex-row items-baseline justify-between">
                    <span className="field-label w-unset">{label ?? 'Select a project'}</span>
                    {showAddProject && (
                        <Button
                            variant="text"
                            onClick={showNewProjectModal}
                            startIcon={<AddRounded fontSize="small" />}
                            className="shrink-0 whitespace-nowrap"
                            disabled={disabled}
                            size="small"
                        >
                            Add Project
                        </Button>
                    )}
                </div>
                <FormControl variant="outlined" fullWidth error={!!error}>
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        name={name}
                        value={currentValue}
                        onChange={({ target }) => {
                            const value = target.value as string | null;
                            if (value === NONE_VALUE) {
                                onChange?.(null);
                            } else {
                                onChange?.(value ?? null);
                            }
                        }}
                        disabled={disabled}
                        sx={{ selectMenu: styles.wrap }}
                    >
                        {loading && (
                            <MenuItem disabled value="loading">
                                Loading...
                            </MenuItem>
                        )}
                        {showNone && <MenuItem value={NONE_VALUE}>{noneLabel}</MenuItem>}
                        {!loading &&
                            projects.map((project) => (
                                <MenuItem
                                    key={project.uuid}
                                    value={project.uuid}
                                    sx={styles.wrap}
                                    disabled={isDisabled(project)}
                                >
                                    <div className="flex max-w-sm flex-row items-center justify-start whitespace-normal sm:max-w-lg">
                                        <ProjectColorDot color={project.color} className="mr-2 shrink-0" />
                                        <span style={{ whiteSpace: 'normal' }}>{project.name}</span>
                                    </div>
                                </MenuItem>
                            ))}
                    </Select>
                    {error && <FieldError>{error}</FieldError>}
                </FormControl>
            </div>
            <CreateProjectModal
                showAddExperiment={false}
                onCreated={handleProjectCreated}
                open={createProjectOpen}
                onClose={() => setCreateProjectOpen(false)}
            />
        </>
    );
};

export default ProjectSelect;
