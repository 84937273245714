import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const TranscriptionFactorEnrichmentAnalysisIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <path
                d="M7 15H18"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6V18"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 10H7"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 6L11 10"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
