import { defaultProps, IconProps } from '@components/icons/CustomIcons';

export const ArchiveIcon = (props: IconProps) => {
    const { height, width, className } = { ...defaultProps, ...props };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={width}
            height={height}
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="currentColor"
                strokeWidth="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
            />
        </svg>
    );
};
