import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import React from 'react';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { useFormikContext } from 'formik';
import { IGVPlotDisplayOptionFormValues } from '@models/PlotDisplayOption';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import AssaySummaryLegendFieldGroup from '@components/experiments/plotDisplay/groups/AssaySummaryLegendFieldGroup';
import IGVCoordinatesField from '@components/experiments/plotDisplay/fields/IGVCoordinatesField';
import IGVTracksField from '@components/experiments/plotDisplay/fields/IGVTracksField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null | undefined };
const IGVPlotDisplayFields = ({ analysisParameters, plot, experiment }: Props) => {
    const { values, handleChange } = useFormikContext<IGVPlotDisplayOptionFormValues>();

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name ?? ''} />
            <IGVCoordinatesField name={'config_json'} plot={plot} />
            <IGVTracksField analysisParameters={analysisParameters} />
            <label className="mb-8 block pt-1">
                <input
                    type="checkbox"
                    className="rounded text-indigo-500"
                    name="autoscale_tracks_by_group"
                    checked={values.autoscale_tracks_by_group}
                    onChange={handleChange}
                />
                <span className="ml-2">Scale y-axis by group</span>
            </label>
            <ThemeFieldGroup hideStyle />
            <AssaySummaryLegendFieldGroup
                plot={plot}
                experiment={experiment}
                ignoreEmpty={true}
                variables={values.color_tracks_by_variable_ids}
                orderedGroupsFieldName="group_display_order"
                description="Click and drag the legend items to reorder. Plot will only show groups included in the analysis"
                minSampleCount={1}
                isSortable={true}
            />
        </>
    );
};

export default IGVPlotDisplayFields;
