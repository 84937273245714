import React, { PropsWithChildren, useEffect, useState } from 'react';
import Button from '@components/Button';
import TextInputField from '../../forms/TextInputField';
import ControlledCustomLegendColorField, {
    CustomLegendColorItem,
} from '../../experiments/plotDisplay/groups/ControlledCustomLegendColorField';
import { PlotLegendDisplayFormValues } from '@/src/models/PlotDisplayOption';
import { useFormikContext } from 'formik';
import { CustomizablePlotChartDisplayOption } from '@/src/models/plotDisplayOption/BasePlotDisplayOption';
import {
    LINE_DASH_OPTIONS,
    LINE_WIDTH,
    POINT_OPACITY,
    POINT_SIZE,
} from '../../analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { Dialog, DialogContent, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Dash } from 'plotly.js';
import DialogCloseButton from '../../DialogCloseButton';
import PlutoDialogTitle from '../../PlutoDialogTitle';

type PointStateOptions = {
    line_color?: string | null | undefined;
    opacity?: number;
    fill_color?: string | null | undefined;
    point_size?: number;
};
type LineStateOptions = {
    dash?: Dash;
    color?: string | null | undefined;
    width?: number;
};
const LEGEND_FIELDS = [
    'significantDecreased',
    'significantIncreased',
    'insignificantDecreased',
    'insignificantIncreased',
];

type Props = PropsWithChildren<{
    onClose: () => void;
    onSave: ({ groupId, options, legend }: { groupId: string | number; options: any; legend?: any }) => void;
    open: boolean;
    selectedItem: CustomLegendColorItem;
}>;
const EditPlotlyLegendModal = ({ onClose, onSave, open, selectedItem }: Props) => {
    const isInsignificant = selectedItem?.id.includes('insignificant');
    const defaultPointOpacity = isInsignificant ? 100 : POINT_OPACITY * 100;
    const isLineType = selectedItem?.id.toLowerCase().includes('line');
    const showLabelField = LEGEND_FIELDS.includes(selectedItem?.id);

    const { values } = useFormikContext<Partial<PlotLegendDisplayFormValues & CustomizablePlotChartDisplayOption>>();
    const [unsavedOptions, setUnsavedOptions] = useState<PointStateOptions | LineStateOptions | null>(null);
    const [unsavedLegend, setUnsavedLegend] = useState<Record<string, string> | null>(null);

    const setInitialPointOptions = () => {
        if (values.custom_options_json?.[selectedItem?.id]) {
            setUnsavedOptions({
                ...values.custom_options_json?.[selectedItem?.id],
            });
        }
    };

    const setInitialLineOptions = () => {
        if (values.custom_options_json?.[selectedItem?.id]) {
            setUnsavedOptions({
                ...values.custom_options_json?.[selectedItem?.id],
            });
        }
    };

    const setInitialLegend = () => {
        if (values.custom_legend_json?.[selectedItem?.id]) {
            setUnsavedLegend({
                [selectedItem.id]: values.custom_legend_json[selectedItem.id],
            });
        }
    };

    useEffect(() => {
        if (!open) return;
        if (isLineType) {
            setInitialLineOptions();
        } else {
            setInitialPointOptions();
        }
        setInitialLegend();
    }, [open]);

    const renderPointFields = () => {
        return (
            <>
                {showLabelField ? (
                    <div className="flex items-center space-x-1 pt-1">
                        <div className="flex-1 justify-center">
                            <TextInputField
                                name="label"
                                label="Legend label"
                                value={unsavedLegend?.[selectedItem?.id] ?? (selectedItem?.label as string)}
                                noMargin
                                onChange={(e) =>
                                    setUnsavedLegend({
                                        [selectedItem?.id]: e.target.value,
                                    })
                                }
                                disableFormik
                            />
                        </div>
                    </div>
                ) : null}
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Fill color</p>
                    <div className="flex-1 justify-center">
                        <ControlledCustomLegendColorField
                            hideLabel
                            items={[selectedItem]}
                            leftOffsetClassName="-left-48"
                            onChange={(color) => {
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    fill_color: color,
                                }));
                            }}
                            value={(unsavedOptions as PointStateOptions)?.fill_color}
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Border / Stroke / Line</p>
                    <div className="flex-1 justify-center">
                        <div>
                            <ControlledCustomLegendColorField
                                hideLabel
                                items={[selectedItem]}
                                leftOffsetClassName="-left-48"
                                fallbackColor={selectedItem?.lineThemeColor}
                                onChange={(color) => {
                                    setUnsavedOptions((prev: PointStateOptions) => ({
                                        ...prev,
                                        line_color: color,
                                    }));
                                }}
                                value={(unsavedOptions as PointStateOptions)?.line_color}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Opacity</p>
                    <div className="flex-1">
                        <TextInputField
                            name="opacity"
                            label=""
                            value={(unsavedOptions as PointStateOptions)?.opacity ?? defaultPointOpacity}
                            noMargin
                            type="number"
                            step={1}
                            suffix="%"
                            min={0}
                            max={100}
                            onValueChange={(_, __, values) =>
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    opacity: values?.float ?? 0,
                                }))
                            }
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            disableFormik
                            useNumericFormat
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Point size</p>
                    <div className="flex-1">
                        <TextInputField
                            name="point_size"
                            label=""
                            value={(unsavedOptions as PointStateOptions)?.point_size ?? POINT_SIZE}
                            noMargin
                            type="number"
                            step={1}
                            min={0}
                            max={20}
                            onValueChange={(_, __, values) =>
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    point_size: values?.float ?? 0,
                                }))
                            }
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            disableFormik
                            useNumericFormat
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderLineFields = () => {
        return (
            <>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Color</p>
                    <div className="flex-1 justify-center">
                        <div>
                            <ControlledCustomLegendColorField
                                hideLabel
                                items={[selectedItem]}
                                leftOffsetClassName="-left-48"
                                onChange={(color) => {
                                    setUnsavedOptions((prev) => ({
                                        ...prev,
                                        color,
                                    }));
                                }}
                                value={(unsavedOptions as LineStateOptions)?.color}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Width</p>
                    <div className="flex-1">
                        <TextInputField
                            name="width"
                            label=""
                            value={(unsavedOptions as LineStateOptions)?.width ?? LINE_WIDTH}
                            noMargin
                            type="number"
                            step={1}
                            min={0}
                            max={20}
                            onValueChange={(_, __, values) =>
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    width: values?.float ?? 0,
                                }))
                            }
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            disableFormik
                            useNumericFormat
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <p className="flex-[2] font-semibold tracking-tight text-gray-500">Dash</p>
                    <div className="flex-1">
                        <Select
                            className="w-full"
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            margin="dense"
                            value={(unsavedOptions as LineStateOptions)?.dash ?? 'solid'}
                            onChange={({ target }) => {
                                const value = target.value as Dash | undefined;
                                setUnsavedOptions((prev) => ({
                                    ...prev,
                                    dash: value,
                                }));
                            }}
                        >
                            {LINE_DASH_OPTIONS.map((option) => (
                                <MenuItem key={option.name} value={option.name}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </>
        );
    };

    const closeAndReset = () => {
        onClose();
        setTimeout(() => {
            setUnsavedOptions(null);
            setUnsavedLegend(null);
        }, 100);
    };

    return (
        <Dialog open={open} onClose={closeAndReset} fullWidth maxWidth="xs">
            <DialogCloseButton onClose={closeAndReset} />

            <PlutoDialogTitle title={`Edit ${selectedItem?.label}`} titleClassName="!break-normal" />

            <DialogContent>
                <div className="space-y-4">
                    {isLineType ? renderLineFields() : renderPointFields()}
                    <div className="flex justify-between space-x-2 pt-4">
                        <Button
                            color="primary"
                            variant="text"
                            size="small"
                            onClick={() => {
                                setUnsavedOptions(null);
                                setUnsavedLegend(null);
                                onSave({ groupId: selectedItem?.id, options: {}, legend: {} });
                                closeAndReset();
                            }}
                        >
                            <span className="text-error">Reset</span>
                        </Button>
                        <div className="flex justify-end space-x-4 ">
                            <Button
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => {
                                    closeAndReset();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    onSave({
                                        groupId: selectedItem?.id,
                                        options: unsavedOptions,
                                        legend: unsavedLegend,
                                    });
                                    closeAndReset();
                                }}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditPlotlyLegendModal;
