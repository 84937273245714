import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';
import cn from 'classnames';

const TextAnalysisIcon = ({ className = defaultTextLightClass, height = 24, width = 24 }: IconProps) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth="2" />
            <path
                d="M5 8.25V6H17V8.25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.75 18H13.25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11 6V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M17 12L17 18L19 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M19 14H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default TextAnalysisIcon;
