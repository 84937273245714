import { Formik } from 'formik';
import TextInput from '@components/forms/TextInput';
import Button from '@components/Button';
import ProgramSelect from '@components/dashboard/ProgramSelect';
import { CreateProjectParams, Project, ProjectType, UpdateProjectParams } from '@models/Project';
import TeamSelect from '@components/dashboard/TeamSelect';
import SelectableItem from '@components/forms/SelectableItem';
import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import { AvatarCircle } from '@components/AvatarCircle';
import ProjectColorSelect from '@components/dashboard/ProjectColorSelect';
import { TextareaAutosize, Tooltip } from '@mui/material';
import { blankToNull, isBlank } from '@util/StringUtil';
import { Alert } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { PermissionName } from '@models/Permission';
import { SimpleUser } from '@models/User';
import { InfoOutlined } from '@mui/icons-material';
import useProjectForm, {
    DESCRIPTION_CHARACTER_LIMIT,
    formSchema,
    FormValues,
    FUNDING_SOURCE_NONE,
    LINK_NAME_CHARACTER_LIMIT,
} from '@hooks/useProjectForm';
import useAuth from '@hooks/useAuth';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import useTeams from '@hooks/useTeams';
import { ArchiveIcon } from '@components/icons/custom/ArchiveIcon';
import ConditionalTooltip from '@components/ConditionalTooltip';
import { isDefined } from '@util/TypeGuards';
import Logger from '@util/Logger';

const logger = Logger.make('CreateProjectForm');

export type CreateProjectSuccessFn = (props: { addExperiment: boolean; project: Project }) => Promise<void>;

type Props = {
    project?: Project;
    onSuccess?: CreateProjectSuccessFn;
    onArchive?: () => void;
    onUnArchive?: () => void;
    showAddExperiment?: boolean;
    teamLead?: SimpleUser | null;
    showDescription?: boolean;
    archived?: boolean;
    showArchive?: boolean;
    error?: string | null;
};

const CreateProjectForm = ({
    project,
    onSuccess,
    showAddExperiment = true,
    teamLead,
    showDescription = false,
    onArchive,
    onUnArchive,
    archived = false,
    showArchive = false,
    error,
}: Props) => {
    const { user } = useAuth();
    const { getTeamPermissionsById } = useTeams();
    const {
        canCreateShareableProjects,
        canCreateTeamProjects,
        initialValues: initial,
        isEdit,
        submitFormValues,
        saveError,
    } = useProjectForm({ project });
    const orgPermissions = useOrganizationPermissions(user?.organization);
    // const [initial] = useState(initialValues);
    const hasOrganization = isDefined(user?.organization);
    const onSubmit = async (values: FormValues) => {
        try {
            const params: CreateProjectParams | UpdateProjectParams = {
                name: values.projectName,
                team_id: values.type === ProjectType.team ? values.teamId : undefined,
                color: values.color,
                funding_source_id:
                    values.funding_source_id === FUNDING_SOURCE_NONE ? undefined : values.funding_source_id,
                description: showDescription ? blankToNull(values.description) : undefined,
                external_url_display_name: blankToNull(values.external_url_display_name),
                external_url: blankToNull(values.external_url),
                uuid: project?.uuid,
            };

            const updatedProject = await submitFormValues(params);

            if (updatedProject && onSuccess) {
                await onSuccess({ addExperiment: values.addExperiment ?? false, project: updatedProject });
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const handleArchive = () => {
        if (archived) {
            onUnArchive?.();
        } else {
            onArchive?.();
        }
    };

    return (
        <Formik initialValues={initial} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
            {({ values, handleSubmit, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
                // const canAddExperiment = orgPermissions.canAddExperimentForProjectType(values.type);
                const isTeam = values.type === ProjectType.team;
                let canAddExperiment = !isTeam && orgPermissions.canCreateShareableExperiments;
                if (isTeam && values.teamId) {
                    canAddExperiment = getTeamPermissionsById(values.teamId)?.canCreateTeamProject ?? false;
                }

                return (
                    <form onSubmit={handleSubmit}>
                        {archived && (
                            <Alert
                                severity="warning"
                                color="info"
                                className="mb-4"
                                action={
                                    <Button color="inherit" size="small" onClick={handleArchive}>
                                        UN-ARCHIVE
                                    </Button>
                                }
                            >
                                This project has been archived
                            </Alert>
                        )}
                        {(error || saveError) && (
                            <Alert severity="error" className="mb-4">
                                {error || saveError}
                            </Alert>
                        )}
                        <TextInput
                            value={values.projectName}
                            label="Name your project"
                            name="projectName"
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                        />

                        <ProgramSelect
                            label={`Program`}
                            name="funding_source_id"
                            value={values.funding_source_id}
                            error={errors.funding_source_id && touched.funding_source_id && errors.funding_source_id}
                            onChange={handleChange}
                            noneValue={FUNDING_SOURCE_NONE}
                            showNone={true}
                        />

                        {showDescription && (
                            <div className="form-field">
                                <label className="field-label">Project Description</label>
                                <TextareaAutosize
                                    className="field-input w-full"
                                    // value={values.description}
                                    // label="Name your project"
                                    name="description"
                                    value={values.description ?? ''}
                                    // errors={errors}
                                    // touched={touched}
                                    onChange={handleChange}
                                    maxLength={DESCRIPTION_CHARACTER_LIMIT}
                                />
                                <FormHelperText>{`${values.description?.length}/${DESCRIPTION_CHARACTER_LIMIT}`}</FormHelperText>
                            </div>
                        )}
                        {!isEdit && (
                            <>
                                <div className="form-field">
                                    <span className="field-label">Project type</span>
                                    <div className="flex flex-row space-x-2">
                                        <div>
                                            <SelectableItem
                                                cyId="proj_team"
                                                disabled={!canCreateTeamProjects}
                                                selected={values.type === ProjectType.team}
                                                onSelect={() => setFieldValue('type', ProjectType.team)}
                                            >
                                                <span>Team...</span>
                                            </SelectableItem>
                                        </div>
                                        <ConditionalTooltip
                                            enabled={!canCreateShareableProjects}
                                            arrow
                                            placement="bottom-start"
                                            title="Creating personal projects is not allowed because you aren't currently in an organization. Please contact your organization admin to be invited."
                                        >
                                            <div className="flex-shrink-0">
                                                <SelectableItem
                                                    cyId="proj_personal"
                                                    className="h-full flex-shrink-0 items-center justify-center"
                                                    disabled={!canCreateShareableProjects}
                                                    selected={values.type === ProjectType.shareable}
                                                    onSelect={() => setFieldValue('type', ProjectType.shareable)}
                                                >
                                                    <span>Personal</span>
                                                </SelectableItem>
                                            </div>
                                        </ConditionalTooltip>
                                    </div>
                                    {values.type === ProjectType.shareable && (
                                        <div className="mt-4 flex flex-row items-center space-x-2">
                                            {teamLead && (
                                                <AvatarCircle
                                                    imageUrl={teamLead.avatar_url}
                                                    userId={teamLead.uuid}
                                                    size="xs"
                                                />
                                            )}
                                            <span>Your organization admin is always included in your projects.</span>
                                        </div>
                                    )}
                                </div>
                                <div className={cn({ hidden: values.type === ProjectType.shareable })}>
                                    <TeamSelect
                                        label="Select your team"
                                        name="teamId"
                                        value={values.teamId}
                                        error={errors.teamId && touched.teamId && errors.teamId}
                                        onChange={handleChange}
                                        showAddTeam={hasOrganization && orgPermissions.canCreateTeams}
                                        permissions={{ requires: [PermissionName.create_team_projects] }}
                                    />
                                </div>
                            </>
                        )}
                        {isEdit && (
                            <>
                                <TextInput
                                    value={values.external_url ?? ''}
                                    label={
                                        <>
                                            Add link
                                            <Tooltip
                                                title="Add a link to external data source, published paper, etc."
                                                placement="top"
                                                arrow
                                            >
                                                <InfoOutlined
                                                    fontSize="small"
                                                    className="-mt-1 ml-1 cursor-pointer text-gray-400"
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                    name="external_url"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="https://dropbox.com/home/My_Project"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        handleChange(e);
                                        const updatedValue = e.target.value;
                                        if (isBlank(updatedValue)) {
                                            setFieldValue('external_url_display_name', '');
                                        }
                                    }}
                                />
                                {!isBlank(values.external_url) && (
                                    <div>
                                        <TextInput
                                            className="-mt-4"
                                            value={values.external_url_display_name ?? ''}
                                            label="Link display text"
                                            name="external_url_display_name"
                                            errors={errors}
                                            touched={touched}
                                            onChange={handleChange}
                                            placeholder="View project on Dropbox"
                                            maxLength={LINK_NAME_CHARACTER_LIMIT}
                                            hint={`${
                                                (values.external_url_display_name ?? '').length
                                            }/${LINK_NAME_CHARACTER_LIMIT}`}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        <div>
                            <ProjectColorSelect
                                name="color"
                                label="Project color"
                                onChange={(c) => setFieldValue('color', c)}
                                error={errors.color && touched.color && errors.color}
                                value={values.color}
                            />
                        </div>

                        <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                            {showArchive && (
                                <Button onClick={handleArchive} startIcon={<ArchiveIcon />}>
                                    {archived ? 'Un-archive' : 'Archive'}
                                </Button>
                            )}
                            <Button
                                disabled={isSubmitting || archived}
                                color="primary"
                                variant={showAddExperiment ? 'outlined' : 'contained'}
                                onClick={() => {
                                    setFieldValue('addExperiment', false);
                                    handleSubmit();
                                }}
                                data-cy="save_and_close"
                            >
                                Save & close
                            </Button>
                            {showAddExperiment && (
                                <Button
                                    disabled={isSubmitting || archived || !canAddExperiment}
                                    color="primary"
                                    variant="contained"
                                    data-cy="save_and_add_experiment"
                                    onClick={() => {
                                        setFieldValue('addExperiment', true);
                                        handleSubmit();
                                    }}
                                >
                                    Save & add experiment
                                </Button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default CreateProjectForm;
