import { BaseSpaceProject } from '@models/baseSpace/BaseSpaceProject';
import useSWR from 'swr';
import { BaseSpaceFile } from '@models/baseSpace/BaseSpaceFile';
import Endpoints from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import { ApiError } from '@services/ApiError';
import { isDefined } from '@util/TypeGuards';
import { useBaseSpaceBrowserContext } from '@contexts/BaseSpaceBrowserContext';
import { IconButton } from '@mui/material';
import { RefreshIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Column, useSortBy, useTable } from 'react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { humanFileSize } from '@util/StringUtil';
import DataTableHeader from '@components/dataTable/DataTableHeader';
import DataTableBody from '@components/dataTable/DataTableBody';
import useImportSessions from '@hooks/useImportSessions';
import { pluralize } from '@util/ObjectUtil';
import { getSessionStatusDisplayName } from '@models/baseSpace/ImportSession';

const tableColor = 'bg-secondary text-white';
type Props = { project: BaseSpaceProject };
const BaseSpaceProjectFilesTable = ({ project }: Props) => {
    const [allSelected, setAllSelected] = useState(false);
    const { experiment, addFile, removeFile, selectedFiles } = useBaseSpaceBrowserContext();
    const { importSessions, loading: importSessionsLoading } = useImportSessions({ experiment });
    const {
        data: files,
        error,
        mutate,
        isValidating,
    } = useSWR<BaseSpaceFile[]>(
        () =>
            experiment &&
            Endpoints.lab.experiment.basespace.projectFiles({
                experimentId: experiment?.uuid,
                projectId: project.uuid,
            }),
    );

    const availableFiles = files?.filter(
        (file) => !isDefined(importSessions?.find((session) => session.basespace_file?.uuid === file.uuid)),
    );

    const handleToggleAll = useCallback(
        (selected: boolean) => {
            availableFiles?.forEach((file) => {
                selected ? addFile(file) : removeFile(file);
            });
        },
        [availableFiles],
    );

    useEffect(() => {
        if (!isDefined(availableFiles) || !isDefined(selectedFiles) || !isDefined(files)) {
            return;
        }

        if (availableFiles?.every((file) => isDefined(selectedFiles.find((selected) => selected.uuid === file.uuid)))) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [selectedFiles, availableFiles, files]);

    const columns: Column<BaseSpaceFile>[] = useMemo(() => {
        return [
            {
                Header: () => (
                    <div className="block flex flex cursor-pointer items-center space-x-2 ">
                        <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={(e) => {
                                handleToggleAll(e.target.checked);
                            }}
                            className="flex-shrink-0 rounded text-indigo-500"
                        />

                        <div className="flex flex-grow items-center justify-between space-x-4">
                            <span className="block font-semibold">File Name</span>
                        </div>
                    </div>
                ),
                accessor: 'name',
                disableSortBy: true,
                enableMultiSort: false,
                Cell: (cell) => {
                    const session = importSessions?.find((s) => s.basespace_file?.uuid === cell.row.original.uuid);
                    const hasImportSession = !!session;
                    return (
                        <label className="block flex flex cursor-pointer items-center space-x-2 ">
                            <input
                                type="checkbox"
                                checked={
                                    !!selectedFiles.find((f) => f.uuid === cell.row.original.uuid) || hasImportSession
                                }
                                onChange={(e) =>
                                    e.target.checked ? addFile(cell.row.original) : removeFile(cell.row.original)
                                }
                                className={cn('flex-shrink-0 rounded ', {
                                    'text-gray-400': hasImportSession,
                                    'text-indigo-500': !hasImportSession,
                                })}
                                disabled={hasImportSession}
                            />
                            <div
                                className={cn('flex flex-grow items-center justify-between space-x-4', {
                                    'text-gray-400': hasImportSession,
                                })}
                            >
                                <span className="block font-semibold">{cell.value} </span>
                                {session && (
                                    <span className="ml-4 text-xs text-gray-400">
                                        {getSessionStatusDisplayName(session.status)}
                                    </span>
                                )}
                            </div>
                        </label>
                    );
                },
            },
            {
                Header: 'Size',
                accessor: 'size',
                enableMultiSort: false,
                Cell: (cell) => <p className="text-right">{cell.value ? humanFileSize(cell.value) : '--'}</p>,
            },
        ];
    }, [allSelected, files, selectedFiles, importSessions]);

    const tableData = useMemo<BaseSpaceFile[]>(() => {
        return files ?? [];
    }, [files]);

    const tableInstance = useTable(
        {
            columns,
            data: tableData,
            disableSortRemove: true,
            // initialState: initialStateProp,
            // manualSortBy: manualSortBy,
        },
        useSortBy,
    );
    const { getTableProps } = tableInstance;

    const loading = (!isDefined(files) && !error) || importSessionsLoading;
    if (loading) {
        return <LoadingMessage immediate message="Loading files from BaseSpace" inline className="py-8" />;
    }

    const $refresh = !loading && (
        <IconButton sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }} onClick={() => mutate()} size="large">
            <RefreshIcon width={18} className={cn({ 'animate-spin': isValidating && !loading })} />
        </IconButton>
    );

    if (error) {
        return (
            <div>
                <div className="flex justify-end">{$refresh}</div>
                <div className="rounded-xl bg-error p-4 text-error">{ApiError.getMessage(error)}</div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex items-start justify-between">
                <div className="mb-2">
                    <span className="text-lg font-semibold">{project.name}</span>
                    {selectedFiles?.length > 0 && (
                        <span className="ml-2">
                            {`${selectedFiles.length} ${pluralize(selectedFiles.length, 'file', 'files')} selected`}
                        </span>
                    )}
                </div>

                <div className="-mt-2 flex-shrink-0">{$refresh}</div>
            </div>
            {(files ?? [])?.length === 0 ? (
                <div className="my-4 rounded-xl bg-indigo-50 p-4 text-center font-semibold">No files found</div>
            ) : (
                <div className="overflow-hidden rounded-xl">
                    <div className="max-h-[55vh] w-full overflow-auto">
                        <div className="">
                            <table
                                {...getTableProps()}
                                className="rounded-corners relative w-full table-auto border-separate"
                            >
                                <DataTableHeader tableInstance={tableInstance} tableColor={tableColor} />
                                <DataTableBody tableInstance={tableInstance} />
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BaseSpaceProjectFilesTable;
