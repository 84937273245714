import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import SearchableGeneSetTable from '@components/experiments/plotDisplay/fields/SearchableGeneSetTable';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import DownloadGeneSetsButton from '@components/experiments/plotDisplay/fields/DownloadGeneSetsButton';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    experiment: Experiment;
    plot: Plot;
    hideSelectColumn?: boolean;
};
const SearchableGeneSetTableDialog = ({ open, setOpen, experiment, plot, hideSelectColumn = false }: Props) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
            <DialogCloseButton onClose={() => setOpen(false)} />
            <PlutoDialogTitle title="Search gene sets" />
            <DialogContent>
                <SearchableGeneSetTable
                    experiment={experiment}
                    plot={plot}
                    onSelected={() => undefined}
                    hideSelectColumn={hideSelectColumn}
                />
            </DialogContent>
            <DialogActions>
                <DownloadGeneSetsButton experiment={experiment} analysis={plot.analysis} />
            </DialogActions>
        </Dialog>
    );
};

export default SearchableGeneSetTableDialog;
