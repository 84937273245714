import RadioField from '@components/experiments/plotDisplay/fields/RadioField';
import React from 'react';

type Props = { name: string };
const NormalizedEnrichmentScoreField = ({ name = 'nes_filter' }: Props) => {
    const choices: { value: string; label: string }[] = [
        {
            label: 'Positive only',
            value: 'positive',
        },
        {
            label: 'Negative only',
            value: 'negative',
        },
        {
            label: 'Both',
            value: 'both',
        },
    ];

    return <RadioField name={name} choices={choices} label={'Normalized enrichment scores'} sectionLabel />;
};

export default NormalizedEnrichmentScoreField;
