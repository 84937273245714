import { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import Experiment from '@models/Experiment';
import useResumableFileUpload from '@hooks/useResumableFileUpload';
import { FileDataType } from '@models/PlutoFile';
import { humanFileSize } from '@util/StringUtil';
import { XIcon } from '@heroicons/react/solid';
import ConfirmModal from '@components/ConfirmModal';
import { ProgressEventInfo } from '@services/ApiService';

type Props = {
    file: File;
    onDelete?: () => void;
    experiment: Experiment;
    data_type: FileDataType;
    onUploadComplete?: () => void;
    onProgress?: (progress: ProgressEventInfo | null) => void;
};
const ResumableFilePending = ({ file, onDelete, experiment, data_type, onUploadComplete, onProgress }: Props) => {
    const { uploadError, cancelUpload } = useResumableFileUpload({
        experiment,
        file,
        data_type,
        onUploadComplete,
        onProgress,
    });

    const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

    const handleDelete = async () => {
        if (confirmRemoveOpen) {
            await cancelUpload();
            setConfirmRemoveOpen(false);
            onDelete?.();
        } else {
            setConfirmRemoveOpen(true);
        }
    };

    return (
        <div className="space-y-2 rounded-lg border border-indigo-100 bg-indigo-50 px-4 py-2">
            <div className="flex justify-between">
                <div className="flex flex-col space-y-1 pr-4">
                    <p className="break-all font-semibold">{file.name}</p>
                    {!uploadError && (
                        <div className="flex items-center space-x-2">
                            <p className="text-sm text-gray-500">{humanFileSize(file.size)}</p>
                            <span className="flex items-center space-x-1">
                                <CircularProgress size={10} variant="indeterminate" />
                                <span className="text-xs">Pending...</span>
                            </span>
                        </div>
                    )}
                </div>
                <div className="flex flex-col">
                    <div className="flex items-start">
                        <Tooltip
                            enterDelay={500}
                            title="Cancel the file upload and remove the file from the queue."
                            arrow
                            placement="top"
                        >
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                onClick={() => handleDelete()}
                                size="small"
                            >
                                <XIcon width={18} className="text-error" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <ConfirmModal
                onConfirm={() => {
                    handleDelete();
                }}
                onCancel={() => {
                    setConfirmRemoveOpen(false);
                }}
                open={confirmRemoveOpen}
                title="Remove file upload"
                confirmText="Remove file"
                cancelText="Continue uploading"
                message={
                    <div className="space-y-4">
                        <p>
                            Are you sure you want to cancel uploading the following file? This action can not be undone.
                        </p>
                        <p className="break-all rounded-md bg-gray-100 p-2 font-mono text-sm">{file.name}</p>
                    </div>
                }
            />
        </div>
    );
};

export default ResumableFilePending;
