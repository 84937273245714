import * as Yup from 'yup';

/** Validation Schema for AssaySummary analyses. */
export const assaySummarySchema = Yup.object({
    targets: Yup.array().required('Please select at least one target').min(1, 'Please select at least one target'),
    variables: Yup.array()
        .required('Please choose at least one variable')
        .min(1, 'Please select at least one variable'),
    group_ids: Yup.array().required('Please select at least one group').min(1, 'Please select at least one group'),
});

/** Validation Schema for Differential Expression AssaySummary analyses. */
export const differentialExpressionAssaySummarySchema = Yup.object({
    targets: Yup.array().required('Please select at least one target').min(1, 'Please select at least one target'),
    variables: Yup.array()
        .required('Please choose at least one variable')
        .min(1, 'Please select at least one variable'),
    group_ids: Yup.array().required('Please select at least one group').min(1, 'Please select at least one group'),
});

/** Validation Schema for Longitudinal analyses. */
export const longitudinalSchema = Yup.object({
    x_axis_variable_id: Yup.string().label('Longitudinal variable').required().nullable(),
    targets: Yup.array()
        .label('Targets')
        .required('Please select at least one target')
        .min(1, 'Please select at least one target')
        .max(5, 'You may only choose up to 5 targets'),
    variables: Yup.array()
        .label('Variables')
        .when('split_samples_into_groups', {
            is: true,
            then: Yup.array()
                .required('Please choose at least one variable')
                .min(1, 'Please select at least one variable'),
            otherwise: Yup.array().notRequired().nullable(),
        }),
    split_samples_into_groups: Yup.boolean().default(false),
    group_ids: Yup.array()
        .label('Groups')
        .when('split_samples_into_groups', {
            is: true,
            then: Yup.array().required('Please select at least one group').min(1, 'Please select at least one group'),
            otherwise: Yup.array().notRequired().nullable(),
        }),
});

/** Validation Schema for Differential Expression analyses. */
export const differentialExpressionSchema = Yup.object({
    variables: Yup.array()
        .required('Please choose at least one variable')
        .min(1, 'Please select at least one variable'),
    control_group_id: Yup.number().required('Please select a control group').min(0, 'Please select a control group'),
    experimental_group_id: Yup.number()
        .required('Please select an experimental group')
        .min(0, 'Please select an experimental group'),
});

/** Validation Schema for Seurat Differential Expression analyses. */
export const seuratDifferentialExpressionSchema = Yup.object({
    variable_ids: Yup.array().of(Yup.string()),
    annotation_set_id: Yup.string(),
    latent_variable_id: Yup.string().nullable(),
    group1_id: Yup.string().when(['variable_ids', 'annotation_set_id', 'latent_variable_id'], {
        is: (variable_ids, annotation_set_id, latent_variable_id) =>
            variable_ids?.length > 0 || annotation_set_id || latent_variable_id,
        then: Yup.string().required('Please select the first group'),
    }),
    group2_id: Yup.string().when(['variable_ids', 'annotation_set_id', 'latent_variable_id'], {
        is: (variable_ids, annotation_set_id, latent_variable_id) =>
            variable_ids?.length > 0 || annotation_set_id || latent_variable_id,
        then: Yup.string().required('Please select a second group'),
    }),
    log2fc: Yup.number().required('Log2 fold change is required.').min(0, 'Minimum required number is 0'),
    stat_test: Yup.string().required('Please select a stat test.'),
    min_pct: Yup.number()
        .required('Minimum fraction of cells is required.')
        .min(0.01, 'Minimum required number is 0.01')
        .max(1, 'Maximum allowed number is 1.00'),
}).test({
    name: 'atLeastOneRequired',
    test: function (values) {
        const { variable_ids, annotation_set_id, latent_variable_id } = values;

        if ((variable_ids ?? []).length === 0 && !annotation_set_id && !latent_variable_id) {
            return this.createError({
                path: 'variable_ids|annotation_set_id|latent_variable_id',
                message: 'At least one variable, annotation set or latent variable is required',
            });
        }

        return true;
    },
});

/** Validation Schema for Peak analyses. */
export const peakAnalysisSchema = Yup.object({
    // groups: Yup.array().required('Please choose at least one group').min(1, 'Please select at least one group'),
    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
});

/** Validation Schema for Survival analyses. */
export const survivalAnalysisSchema = Yup.object({
    group_ids: Yup.array().required('Please choose at least one group').min(1, 'Please select at least one group'),
    time_to_event_units: Yup.string().required('Units are required').min(1, 'Please enter units').nullable(),
    status_variable_id: Yup.number().required('Please choose a status variable').nullable(),
    time_to_event_variable_id: Yup.number().required('Please choose a time to event variable').nullable(),
});

/** Validation schema for Image Analysis  */
export const imageAnalysisSchema = Yup.object({
    image: Yup.object()
        .shape({ name: Yup.string().required('Please choose a file') })
        .nullable(),
    methods: Yup.string().nullable(),
    targets: Yup.array(),
});

/** Validation schema for Spreadsheet Analysis  */
export const spreadsheetAnalysisSchema = Yup.object({
    spreadsheet: Yup.object()
        .shape({ name: Yup.string().required('Please choose a file') })
        .nullable(),
    methods: Yup.string().nullable(),
    targets: Yup.array(),
});

/** Validation schema for Prism Analysis  */
export const prismAnalysisSchema = Yup.object({
    experiment_file_id: Yup.string().nullable(),
    methods: Yup.string().nullable(),
});

/** Validation schema for External Analysis  */
export const externalAnalysisSchema = Yup.object({
    display_file_id: Yup.string().nullable(),
    methods: Yup.string().nullable(),
    results_file_id: Yup.string().nullable(),
    script_file_id: Yup.string().nullable(),
});

export const textAnalysisSchema = Yup.object({
    content: Yup.string().nullable().label('Content'),
    targets: Yup.array().nullable().label('Targets'),
});

/** Validation schema for Principal Components Analysis  */
export const principalComponentsAnalysisSchema = Yup.object({
    include_all_samples: Yup.boolean(),

    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),

    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
    center_data: Yup.boolean(),
    scale_data: Yup.boolean(),
});

/** Validation schema for UMAP Analysis  */
export const umapAnalysisSchema = Yup.object({
    include_all_samples: Yup.boolean(),
    max_sample_count: Yup.number(),
    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),

    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
    min_distance: Yup.number()
        .required('Minimum point distance is required')
        .label('Minimum point distance')
        .moreThan(0),
    n_neighbors: Yup.number()
        .label('Number of neighbors')
        .required('This field is required')
        .min(1)
        .test({
            name: 'max',
            message: function () {
                return `Number of neighbors must be less than or equal to the total number of selected samples`;
            },
            exclusive: false,
            test: function (value: number) {
                if (value <= this.parent.max_sample_count) {
                    return true;
                }
                return this.createError({
                    message: `Number of neighbors must be less than or equal to ${this.parent.max_sample_count}`,
                    path: 'n_neighbors',
                });
            },
        }),
});

/** Validation schema for TSNE Analysis  */
export const tsneAnalysisSchema = Yup.object({
    include_all_samples: Yup.boolean(),
    max_perplexity: Yup.number(),
    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),

    group_ids: Yup.array()
        .label('Groups')
        .when('include_all_samples', {
            is: (value) => value === true,
            then: Yup.array().max(0, 'You may not have any groups selected'),
            otherwise: Yup.array().min(1, 'Please select at least 1 group'),
        }),
    perplexity: Yup.number()
        .label('Perplexity')
        .required('This field is required')
        .min(0.1)
        .test({
            name: 'max',
            exclusive: false,
            test: function (value: number) {
                if (value <= this.parent.max_perplexity) {
                    return true;
                }
                return this.createError({
                    message: `Perplexity must be less than or equal to ${this.parent.max_perplexity}`,
                    path: 'perplexity',
                });
            },
        }),
});

/** Validation schema for Gene Set Enrichment Analysis */
export const geneSetEnrichmentAnalysisSchema = Yup.object({
    differential_analysis_id: Yup.string().required('Please select a comparison'),
    gene_set_collections: Yup.array()
        .required('Please select at least one gene set collection')
        .min(1, 'Please select at least one gene set collection'),
    rank_method: Yup.string().required().label('Gene rank method').nullable(),
});

/** Validation schema for Seurat Over Representation Analysis */
export const seuratOverRepresentationAnalysisSchema = Yup.object({
    differential_analysis_id: Yup.string().required('Please select a comparison'),
    gene_set_collections: Yup.array()
        .required('Please select at least one gene set collection')
        .min(1, 'Please select at least one gene set collection'),
    n_genes: Yup.number()
        .required('Maximum genes to include is required')
        .min(50, 'Minimum allowed genes is 50')
        .max(1000, 'Maximum allowed genes is 1000'),
    adj_pval_de: Yup.number()
        .required('Adjusted p-value threshold is required')
        .min(0, 'Minimum allowed threshold is 0')
        .max(0.25, 'Maximum allowed threshold is 0.25'),
});

export const clusteringAnalysisSchema = Yup.object({
    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),

    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
    adj_pval_threshold: Yup.number(),
    log2_fc_threshold: Yup.number(),
    is_zscore: Yup.boolean(),
    is_log2_transform: Yup.boolean(),
    is_cpm_normalize: Yup.boolean(),
    differential_analysis_id: Yup.string().required().label('Analysis').nullable(),
    differential_analysis_shortname: Yup.string().required().label('Analysis type').nullable(),
    log2_fc_direction: Yup.string().nullable().label('Direction'),
});

export const coverageAnalysisSchema = Yup.object({
    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),

    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
});

/** Validation schema for Transcription Factor Enrichment Analysis */
export const transcriptionFactorEnrichmentAnalysisSchema = Yup.object({
    differential_analysis_id: Yup.string().required('Please select a comparison'),
    differential_analysis_shortname: Yup.string().required().label('Analysis type').nullable(),
    tf_target_collection_shortname: Yup.string().required('Please select a collection'),
    adj_pval_threshold: Yup.number(),
    log2_fc_threshold: Yup.number(),
    log2_fc_direction: Yup.string().nullable().label('Direction'),
});

/** Validation schema for Functional Annotation Analysis */
export const functionalAnnotationAnalysisSchema = Yup.object({
    peak_set_type: Yup.string().required('Please select a peak set type'),
    genome_shortname: Yup.string().required('Please select a genome'),
    adj_pval_threshold: Yup.number()
        .min(0, 'Minimum required adjusted p-val threshold is 0')
        .max(1, 'Maximum allowed adjusted p-val threshold is 1')
        .when('peak_set_type', {
            is: (value: string) => value === 'differential',
            then: Yup.number().required('Adjusted p-val threshold is required'),
            otherwise: Yup.number(),
        }),
    fold_change_fill_threshold_lower: Yup.number().when('peak_set_type', {
        is: (value: string) => value === 'differential',
        then: Yup.number()
            .min(0, 'Minimum required fold change lower threshold is 0')
            .required('Lower threshold is required'),
        otherwise: Yup.number(),
    }),
    fold_change_fill_threshold_upper: Yup.number().when('peak_set_type', {
        is: (value: string) => value === 'differential',
        then: Yup.number()
            .min(0, 'Minimum required fold change upper threshold is 0')
            .required('Upper threshold is required'),
        otherwise: Yup.number(),
    }),
    tss_upstream: Yup.number()
        .required('Upstream TSS is required')
        .min(-3000, 'Minimum required upstream TSS is -3000')
        .max(-50, 'Maximum allowed upstream TSS is -50'),
    tss_downstream: Yup.number()
        .required('Downstream TSS is required')
        .min(50, 'Minimum required downstream TSS is 50')
        .max(3000, 'Maximum allowed downstream TSS is 3000'),
    sample_ids: Yup.array(Yup.string()).when('peak_set_type', {
        is: (value: string) => value === 'sample',
        then: Yup.array(Yup.string())
            .required('Please select at least one sample')
            .min(1, 'Please select at least one sample'),
        otherwise: Yup.array(Yup.string()),
    }),
    differential_analysis_id: Yup.string()
        .label('Analysis')
        .nullable()
        .when('peak_set_type', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
    differential_analysis_shortname: Yup.string()
        .label('Analysis type')
        .nullable()
        .when('peak_set_type', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
});

/** Validation schema for Protein-Protein Interaction Analysis */
export const proteinProteinInteractionAnalysisSchema = Yup.object({
    adj_pval_threshold: Yup.number().when('target_genes_format', {
        is: (value: string) => value === 'differential',
        then: Yup.number().required('Please select an adjusted p-value threshold'),
        otherwise: Yup.number().nullable(),
    }),
    fc_direction: Yup.string().when('target_genes_format', {
        is: (value: string) => value === 'differential',
        then: Yup.string().required('Please select a fold change direction'),
        otherwise: Yup.string(),
    }),
    fc_threshold: Yup.number().when('target_genes_format', {
        is: (value: string) => value === 'differential',
        then: Yup.number().required('Please select a fold change threshold'),
        otherwise: Yup.number().nullable(),
    }),
    differential_analysis_id: Yup.string()
        .label('Analysis')
        .nullable()
        .when('target_genes_format', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
    differential_analysis_shortname: Yup.string()
        .label('Analysis type')
        .nullable()
        .when('target_genes_format', {
            is: (value: string) => value === 'differential',
            then: Yup.string().required('Please select a comparison'),
            otherwise: Yup.string().nullable(),
        }),
    network_type: Yup.string().required('Please select a network type'),
    targets_csv: Yup.mixed().when(['target_genes_format', 'targets_csv_filename'], {
        is: (target_genes_format: string, targets_csv_filename: string) =>
            target_genes_format === 'file' && !targets_csv_filename,
        then: Yup.mixed().required('Please upload a target csv'),
        otherwise: Yup.mixed().nullable(),
    }),
    targets_csv_filename: Yup.string().nullable(),
    target_genes_format: Yup.string().required('Please select a target format'),
    targets: Yup.array(Yup.string()).when('target_genes_format', {
        is: (value: string) => value === 'list',
        then: Yup.array(Yup.string())
            .min(2, 'Please select at least 2 targets')
            .required('Please enter a list of target genes'),
        otherwise: Yup.array(Yup.string()).nullable(),
    }),
    targets_biomarker_list: Yup.string().when('target_genes_format', {
        is: (value: string) => value === 'biomarker',
        then: Yup.string().required('Please select a biomarker list'),
        otherwise: Yup.string().nullable(),
    }),
});

/** Validation schema for Sample Correlation Analysis  */
export const sampleCorrelationAnalysisSchema = Yup.object({
    include_all_samples: Yup.boolean(),
    variables: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any variables selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 variable'),
    }),
    group_ids: Yup.array().when('include_all_samples', {
        is: (value) => value === true,
        then: Yup.array().max(0, 'You may not have any groups selected'),
        otherwise: Yup.array().min(1, 'Please select at least 1 group'),
    }),
    method_type: Yup.string().required('Please select a correlation method'),
    name: Yup.string().optional(),
});

/** Validation schema for Overlap Analysis  */
export const overlapAnalysisSchema = Yup.object({
    list_1_id: Yup.string().required('Please fill out the first list'),
    list_2_id: Yup.string().required('Please fill out the second list'),
    list_3_id: Yup.string().nullable(),
    list_4_id: Yup.string().nullable(),
    list_5_id: Yup.string().nullable(),
});

/** Validation schema for Seurat Marker Expression Analysis */
export const seuratMarkerExpressionAnalysisSchema = Yup.object({
    targets: Yup.array()
        .required('Please select one target')
        .min(1, 'Please select one target')
        .max(1, 'Please select one target'),
});

/** Validation schema for Seurat Module Score Analysis */
export const seuratModuleScoreAnalysisSchema = Yup.object({
    targets: Yup.array()
        .required('Please select at least two targets')
        .min(2, 'Please select at least two targets')
        .max(2000, 'Please select less than 2000 targets'),
    module_score_name: Yup.string().required('Module score name is required'),
});
