import useSWR from 'swr';
import { TabularData } from '@models/ExperimentData';
import Endpoints from '@services/Endpoints';
import { PCASummaryMeasure, SummaryMeasureShortname } from '@models/PCASummaryMeasure';
import { useMemo } from 'react';

type Props = { analysisId?: string | null | undefined; experimentId: string };
const usePCASummary = ({ analysisId, experimentId }: Props) => {
    const { data, error } = useSWR<TabularData<PCASummaryMeasure>>(() =>
        !analysisId
            ? null
            : Endpoints.lab.experiment.analysis.summary({
                  experimentId,
                  analysisId,
              }),
    );
    const loading = !data && !error;

    const { getMeasureValue, getProportionOfVariance } = useMemo(() => {
        const getMeasureValue = (measure: SummaryMeasureShortname, column: string): number | null => {
            if (!data) {
                return null;
            }

            return data.items.find((m) => m.Summary_Measure_Shortname === measure)?.[column] ?? null;
        };

        const getProportionOfVariance = (column: string): number | null => {
            return getMeasureValue('proportion_variance', column);
        };

        return { getMeasureValue, getProportionOfVariance };
    }, [data]);

    return { loading, tabularData: data, error, getMeasureValue, getProportionOfVariance };
};

export default usePCASummary;
