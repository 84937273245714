import TextInput from '@components/forms/TextInput';
import { blankToNull } from '@util/StringUtil';
import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { AssayDataUnits } from '@models/Experiment';
import { isDefined } from '@util/TypeGuards';

type Props = {
    labelPrefix?: ReactNode;
    showLabelPrefix?: boolean;
    startName: string;
    endName: string;
    noMargin?: boolean;
} & Pick<AssayDataUnits, 'units' | 'units_display_name'>;
const AxisRangeFieldGroup = ({
    units,
    units_display_name,
    labelPrefix,
    showLabelPrefix,
    startName,
    endName,
    noMargin,
}: Props) => {
    const unitHint = units?.display_name ?? units_display_name ?? '';

    const [{ value: startValue }, , { setValue: setStartValue }] = useField(startName);
    const [{ value: endValue }, , { setValue: setEndValue }] = useField(endName);

    return (
        <div className="grid grid-cols-2 gap-2">
            <TextInput
                name={startName}
                label={labelPrefix && showLabelPrefix ? <>{labelPrefix} start</> : 'Start'}
                type="number"
                value={startValue ?? ''}
                step={0.1}
                hint={unitHint}
                onChange={(e) => {
                    const value = blankToNull(e.target.value);
                    setStartValue(isDefined(value) ? Number(value) : null);
                }}
                noMargin={noMargin}
            />
            <TextInput
                name={endName}
                label={labelPrefix && showLabelPrefix ? <>{labelPrefix} end</> : 'End'}
                type="number"
                step={0.1}
                value={endValue ?? ''}
                hint={unitHint}
                onChange={(e) => {
                    const value = blankToNull(e.target.value);
                    setEndValue(isDefined(value) ? Number(value) : null);
                }}
                noMargin={noMargin}
            />
        </div>
    );
};

export default AxisRangeFieldGroup;
