import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters, BaseAnalysisParameters } from '@models/AnalysisParameters';
import { useFormikContext } from 'formik';
import React from 'react';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import { PrincipalComponentsAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters };

const PrincipalComponentsAnalysisFormFields = ({ plot, experiment, analysisParameters }: Props) => {
    const { values, handleChange, ...form } = useFormikContext<PrincipalComponentsAnalysisFormValues>();

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Analyze</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            checked={values.include_all_samples}
                            onChange={() => {
                                form.setFieldValue('include_all_samples', true);
                                form.setFieldValue('variables', []);
                                form.setFieldValue('group_ids', []);
                            }}
                        />
                        <span className="ml-2">Include all samples</span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            onChange={() => {
                                form.setFieldValue('include_all_samples', false);
                            }}
                            checked={!values.include_all_samples}
                        />
                        <span className="ml-2">Specific groups of samples</span>
                    </label>
                </div>
                {!values.include_all_samples && (
                    <div className="mt-4">
                        <AnalysisVariablesMultiSelectField
                            variables={(analysisParameters as BaseAnalysisParameters).variables}
                        />
                        <AnalysisGroupsField plot={plot} experiment={experiment} />
                    </div>
                )}
            </section>
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Data transformations</p>
                <div className="space-y-4">
                    <label className="flex">
                        <input
                            type="checkbox"
                            className="mt-1 cursor-pointer rounded text-indigo-500"
                            name="center_data"
                            checked={values.center_data}
                            onChange={handleChange}
                        />
                        <div className="ml-2 inline-block">
                            <span className="block">
                                <span className="font-semibold">Center data</span> (Recommended)
                            </span>
                            <span>Shifts data to be zero centered</span>
                        </div>
                    </label>
                    <label className="flex">
                        <input
                            type="checkbox"
                            className="mt-1 cursor-pointer rounded text-indigo-500"
                            name="scale_data"
                            checked={values.scale_data}
                            onChange={handleChange}
                        />
                        <div className="ml-2 inline-block">
                            <span className="block">
                                <span className="font-semibold">Scale data</span> (Recommended)
                            </span>
                            <span>Scales data to have unit variance</span>
                        </div>
                    </label>
                </div>
            </section>
        </div>
    );
};

export default PrincipalComponentsAnalysisFormFields;
