import React from 'react';
import SimpleSelectField, { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import { PlotTypeSelectOptions, PlotTypeConfig } from '@models/PlotConfigs';
import { useFormikContext } from 'formik';

type Props = {
    placeholder?: string;
    items?: ChoiceItem[];
};
const PlotTypeSelectField = ({ placeholder = 'Select a plot type', items }: Props) => {
    const { values, handleChange } = useFormikContext<PlotTypeConfig>();
    return (
        <div className="form-field">
            <SimpleSelectField
                name="plot_type"
                label="Plot Type"
                placeholder={placeholder}
                value={values.plot_type}
                onChange={handleChange}
                items={items || PlotTypeSelectOptions}
            />
        </div>
    );
};

export default PlotTypeSelectField;
