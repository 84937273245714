import { ReactNode } from 'react';
import cn from 'classnames';
import Checkbox from '@mui/material/Checkbox';

const ClickableItem = ({
    selected,
    onChange,
    icon,
    label,
    disabled,
    dataCy,
}: {
    selected?: boolean;
    onChange: (selected: boolean) => void;
    icon?: ReactNode;
    label: ReactNode;
    disabled?: boolean;
    dataCy?: string;
}) => {
    return (
        <div
            className={cn(
                'flex cursor-pointer items-center justify-between space-x-4 rounded-lg border border-indigo-300 p-2',
                {
                    'bg-indigo-50': selected && !disabled,
                    'bg-gray-100': selected && disabled,
                    'hover:bg-indigo-50/50': !selected && !disabled,
                },
            )}
            onClick={() => {
                if (disabled) {
                    return;
                }
                onChange(!selected);
            }}
            data-cy={dataCy}
        >
            <div className="flex items-center space-x-4 pl-4">
                {icon && <div>{icon}</div>}
                <div>{label}</div>
            </div>
            <Checkbox
                checked={selected}
                disabled={disabled}
                onChange={(e) => {
                    if (e.target.checked) {
                        onChange(true);
                    } else {
                        onChange(false);
                    }
                }}
            />
        </div>
    );
};

export default ClickableItem;
