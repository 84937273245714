import { ExclamationCircleIcon, CheckCircleIcon, SparklesIcon } from '@heroicons/react/outline';
import React from 'react';
import LoadingMessage from '@components/LoadingMessage';

type Props = {
    complete: boolean;
    error: boolean;
    pipelineLoading: boolean;
    plotsLoading: boolean;
};
const PreprocessPlotShellView = ({ complete, error, pipelineLoading, plotsLoading }: Props) => {
    if (pipelineLoading) {
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-0 text-indigo-800">
                    <LoadingMessage size={24} immediate />
                </div>
                <h2 className="text-xl font-semibold tracking-tight">Preprocessing step is running</h2>
                <p className="text-base">
                    The results of this step will appear here once it has completed. This process may take up to 30
                    minutes. It is safe to close this window or navigate away in the meantime.
                </p>
            </div>
        );
    }
    if (complete) {
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <div className="mx-auto mb-4 rounded-full bg-success p-0">
                    <div className="flex flex-col items-center justify-center space-y-4 p-8">
                        <CheckCircleIcon className="text-success" width={24} />
                    </div>
                </div>
                <h2 className="text-xl font-semibold tracking-tight">Preprocessing step is complete</h2>
                <p className="text-base">
                    This step has successfully completed but doesn&apos;t generate any plots for you to view. Go ahead
                    and continue on to the next step in the workflow!
                </p>
            </div>
        );
    }
    if (error) {
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <div className="mx-auto mb-4 rounded-full bg-error p-0">
                    <div className="flex flex-col items-center justify-center space-y-4 p-8">
                        <ExclamationCircleIcon className="text-error" width={24} />
                    </div>
                </div>
                <h2 className="text-xl font-semibold tracking-tight">
                    There was an issue with this preprocessing step
                </h2>
                <p className="text-base">
                    Something went wrong with preprocessing, unfortunately. Our team has been notified and will be in
                    touch with next steps as soon as we can!
                </p>
            </div>
        );
    }
    if (plotsLoading) {
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-0 text-indigo-800">
                    <LoadingMessage size={24} immediate />
                </div>
                <h2 className="text-xl font-semibold tracking-tight">Loading plots</h2>
                <p className="text-base">Your plots will appear here in a moment. Hang tight!</p>
            </div>
        );
    }
    return (
        <div className="flex flex-col justify-center px-8 py-12 text-center">
            <div className="mx-auto mb-4 rounded-full bg-indigo-100 p-4 text-indigo-800">
                <SparklesIcon height={24} width={24} />
            </div>
            <h2 className="text-xl font-semibold tracking-tight">New insights in the making</h2>
            <div className="space-y-8">
                <p className="text-base">
                    Your plots will appear here once you&apos;ve finished setting up your preprocessing using the
                    options to the left. Not sure how to configure your preprocessing at this stage? Check out the
                    Instructions & Tips tab below!
                </p>
            </div>
        </div>
    );
};

export default PreprocessPlotShellView;
