import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import Logger from '@util/Logger';
import { pluralize } from '@util/ObjectUtil';

const logger = Logger.make('TablePaginationFooter');

const PageItem = ({ p, page, setPageIndex }: { p: number; page: number; setPageIndex: (page: number) => void }) => {
    return (
        <span
            key={`page_${p}`}
            onClick={() => {
                logger.debug('setting page index to', p);
                setPageIndex(p);
            }}
            className={cn('relative cursor-pointer items-center border px-4 py-2 text-sm font-medium ', {
                'z-10 inline-flex border-primary/60 bg-indigo-50 text-primary focus:z-20': p === page,
                'border-gray-300 bg-white text-gray-500 hover:bg-gray-50 focus:z-20 ': p !== page,
            })}
        >
            {(p + 1).toLocaleString()}
        </span>
    );
};

type Props = {
    page: number;
    pageSize: number;
    totalPages: number;
    nextPage: () => void;
    previousPage: () => void;
    className?: string;
    totalResults: number;
    setPageIndex: (page: number) => void;
    pageOptions: number[];
    maxPageOptions?: number;
};
const TablePaginationFooter = ({
    page,
    nextPage,
    previousPage,
    pageSize,
    className,
    totalPages,
    totalResults,
    setPageIndex,
    pageOptions,
    maxPageOptions = 4,
}: Props) => {
    // const {} = useTab
    const firstIndex = page * pageSize + 1;
    const lastIndex = Math.min(page * pageSize + pageSize, totalResults);
    logger.info({ page, pageSize, totalResults, pageOptions, maxPageOptions, totalPages, firstIndex, lastIndex });
    const optionsStart: number[] =
        pageOptions.length <= maxPageOptions ? pageOptions : [...pageOptions].slice(0, maxPageOptions / 2);

    const optionsEnd: number[] =
        pageOptions.length <= maxPageOptions
            ? []
            : [...pageOptions].slice(Math.max(pageOptions.length - maxPageOptions / 2, 0));

    const showDots = optionsEnd.length > 0 && pageOptions.length > maxPageOptions;

    if (pageOptions.length === 1) {
        return (
            <p className="px-2 py-2 text-center text-sm">
                Showing all {totalResults.toLocaleString()} {pluralize(totalResults, 'result', 'results')}
            </p>
        );
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 items-center justify-between sm:hidden">
                <span
                    onClick={() => previousPage()}
                    className="relative inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Previous
                </span>

                <p className="px-2 text-center text-xs">
                    Showing <span className="font-medium">{firstIndex.toLocaleString()}</span> to{' '}
                    <span className="font-medium">{lastIndex.toLocaleString()}</span> of&nbsp;
                    <span className="font-medium">{totalResults.toLocaleString()}</span>&nbsp;results
                </p>

                <span
                    onClick={() => nextPage()}
                    className="relative inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Next
                </span>
            </div>
            <div className="flex hidden flex-1 flex-col items-center justify-between space-y-2 sm:flex">
                {pageOptions.length > 1 && (
                    <div>
                        <nav
                            className={cn('isolate inline-flex -space-x-px rounded-md shadow-sm', className)}
                            aria-label="Pagination"
                        >
                            <span
                                onClick={() => previousPage()}
                                className="relative inline-flex cursor-pointer items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </span>

                            {optionsStart.map((p) => {
                                return <PageItem key={`page_${p}`} p={p} page={page} setPageIndex={setPageIndex} />;
                            })}

                            {showDots && (
                                <span
                                    className={cn(
                                        'relative inline-flex items-center space-x-1 border border-gray-300 bg-white px-4 py-2 text-sm',
                                        {
                                            'z-10 border-primary/60 bg-indigo-50 text-primary':
                                                !optionsEnd.includes(page) && !optionsStart.includes(page),
                                        },
                                    )}
                                >
                                    {(page < optionsStart[optionsStart.length - 1] + 1 ||
                                        (optionsStart.length > 0 &&
                                            page > optionsStart[optionsStart.length - 1] + 1)) && <span>...</span>}
                                    {!optionsEnd.includes(page) && !optionsStart.includes(page) && (
                                        <span>{page + 1}</span>
                                    )}
                                    {optionsEnd.length > 0 &&
                                        page < optionsEnd[0] - 1 &&
                                        !optionsEnd.includes(page) &&
                                        !optionsStart.includes(page) && <span>...</span>}
                                </span>
                            )}
                            {optionsEnd.map((p) => {
                                return <PageItem key={`page_${p}`} p={p} page={page} setPageIndex={setPageIndex} />;
                            })}

                            <span
                                onClick={() => nextPage()}
                                className="relative inline-flex cursor-pointer items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        </nav>
                    </div>
                )}
                <div>
                    <p className="text-sm">
                        Showing <span className="font-medium">{firstIndex.toLocaleString()}</span> to{' '}
                        <span className="font-medium">{lastIndex.toLocaleString()}</span> of{' '}
                        <span className="font-medium">{totalResults.toLocaleString()}</span> results
                    </p>
                </div>
            </div>
        </div>
    );
};
export default TablePaginationFooter;
