import TextInput from '@components/forms/TextInput';
import { formatSmallNumber, stringToNumber } from '@util/StringUtil';
import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { Tooltip } from '@mui/material';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

type Props = {
    name: string;
    defaultValue?: number;
    showHint?: boolean;
    tooltipTitle?: string;
    hideSectionLabel?: boolean;
    sectionLabel?: string;
    label?: ReactNode;
    noMargin?: boolean;
    max?: number;
};
const PValueField = ({
    name,
    showHint = false,
    tooltipTitle,
    hideSectionLabel,
    sectionLabel = 'Statistical significance',
    label = (
        <span>
            Adjusted <i>p</i>-value
        </span>
    ),
    noMargin = false,
    max,
}: Props) => {
    const [inputProps, , { setValue }] = useField(name);

    const pval_fill_threshold = inputProps.value ?? '';
    return (
        <>
            {!hideSectionLabel && (
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">{sectionLabel}</h4>
            )}
            <div className="">
                <TextInput
                    name={name}
                    label={
                        <div className="flex items-center">
                            <span>{label}</span>
                            {tooltipTitle && (
                                <Tooltip title={tooltipTitle} placement="top" arrow>
                                    <span>
                                        <HelpCircleIcon
                                            fontSize="small"
                                            className="ml-2 cursor-pointer text-gray-400"
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    }
                    type="number"
                    value={pval_fill_threshold}
                    min={0}
                    step={0.01}
                    max={max}
                    noMargin={noMargin}
                    hint={
                        showHint ? (
                            <span className="pl-1">
                                -log<sub>10</sub> adj <i>p</i>-value
                                {!pval_fill_threshold
                                    ? ' = (not set)'
                                    : formatSmallNumber({
                                          value: -1 * Math.log10(pval_fill_threshold),
                                          defaultPrefix: ' = ',
                                      })}
                            </span>
                        ) : null
                    }
                    onChange={(e) => setValue(stringToNumber(e.target.value))}
                    useFormikError
                />
            </div>
        </>
    );
};

export default PValueField;
