import { ReportQuality } from '@models/Experiment';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { Tooltip } from '@mui/material';

type Props = {
    quality?: ReportQuality | null;
    showGoodIcon?: boolean;
    className?: string;
    width?: number;
    noColor?: boolean;
    tooltip?: boolean;
};
const ReportQualityIcon = ({ quality, showGoodIcon = false, className, width = 18, noColor, tooltip }: Props) => {
    if (!quality) {
        return null;
    }

    switch (quality) {
        case 'good':
            if (!showGoodIcon) {
                return null;
            }
            if (!tooltip) {
                return <CheckCircleIcon width={width} className={cn(className, { ['text-success']: !noColor })} />;
            }
            return (
                <Tooltip title="QC identified no issues" arrow>
                    <CheckCircleIcon width={width} className={cn(className, { ['text-success']: !noColor })} />
                </Tooltip>
            );

        case 'fair':
            if (!tooltip) {
                return <ExclamationIcon width={width} className={cn(className, { ['text-amber-500']: !noColor })} />;
            } else {
                return (
                    <Tooltip title="QC identified some issues" arrow>
                        <ExclamationIcon width={width} className={cn(className, { ['text-amber-500']: !noColor })} />
                    </Tooltip>
                );
            }

        case 'poor':
            if (!tooltip) {
                return <ExclamationCircleIcon width={width} className={cn(className, { ['text-error']: !noColor })} />;
            } else {
                return (
                    <Tooltip title="QC identified major issues" arrow>
                        <ExclamationCircleIcon width={width} className={cn(className, { ['text-error']: !noColor })} />
                    </Tooltip>
                );
            }
    }

    return null;
};

export default ReportQualityIcon;
