import { ComparativeAnalysisPlotData, isArrowPlotData } from '@models/ExperimentData';
import PlotNoDataView from '@components/plots/PlotNoDataView';
import React from 'react';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import { usePlotContext } from '@contexts/PlotContext';
import ComparativeHeatmapView from '@components/analysisCategories/comparative/plots/ComparativeHeatmapView';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import PlotlyVolcanoPlot from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlot';
import VolcanoPlotView from '@components/analysisCategories/comparative/plots/VolcanoPlotView';
import Logger from '@util/Logger';
import ServerImagePlotView from '@components/analysisCategories/comparative/plots/ServerImagePlotView';
import {
    CustomPlotStylingOptions,
    DragMode,
} from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import PlotlyDotPlot from './plots/dot/PlotlyDotPlot';
import VennDiagram from './plots/VennDiagram';

const logger = Logger.make('ComparativePlotDisplayView');

type Props = {
    dragMode?: DragMode;
    customPlotStylingOptions?: CustomPlotStylingOptions | null;
};
const ComparativePlotDisplayView = ({ dragMode = undefined, customPlotStylingOptions = null }: Props) => {
    const { plot, plotData } = usePlotContext();
    const data = plotData as ComparativeAnalysisPlotData | null;

    const display_type = plot.display.display_type;

    if (!data) {
        logger.warn('no plot data, returning null');
        return <div>No plot data</div>;
    }

    if (data.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }

    if (data.count === 0 || (!isArrowPlotData(data) && (data.items?.length ?? 0) === 0)) {
        return <PlotNoDataView />;
    }

    switch (display_type) {
        case 'volcano_plot':
            return <VolcanoPlotView customPlotStylingOptions={customPlotStylingOptions} />;
        case 'volcano_plot_v2':
            return <PlotlyVolcanoPlot dragMode={dragMode} customPlotStylingOptions={customPlotStylingOptions} />;
        case 'heatmap':
            return <ComparativeHeatmapView />;
        case 'image_heatmap':
            return <ServerImagePlotView />;
        case 'dot_plot':
            return <PlotlyDotPlot customPlotStylingOptions={customPlotStylingOptions} />;
        case 'venn_diagram':
            return <VennDiagram />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default ComparativePlotDisplayView;
