import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import React, { useState } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import { useFormikContext } from 'formik';
import SeuratDifferentialVariablesField from '../../analyses/fields/SeuratDifferentialVariablesField';
import SeuratDifferentialLatentVariablesField from '../../analyses/fields/SeuratDifferentialLatentVariablesField';
import { AnalysisParameters, SeuratMarkerExpressionAnalysisParameters } from '@/src/models/AnalysisParameters';
import { useSwitchStyles } from '@/src/components/SwitchStyles';
import { Switch } from '@mui/material';
import SimpleSelectField, { ChoiceItem } from '../fields/SimpleSelectField';
import { useExperimentAnnotationContext } from '@/src/contexts/ExperimentAnnotationContext';
import ManageGroups from '../groups/ManageGroups';
import { CellScatterPlotDisplayOptionFormValues } from '@/src/models/PlotDisplayOption';
import RangeFieldColorGroup from '../groups/RangeFieldColorGroup';
import TextInput from '@/src/components/forms/TextInput';
import Button from '@/src/components/Button';
import { mutate } from 'swr';
import Endpoints from '@/src/services/Endpoints';

type Props = {
    analysisParameters?: AnalysisParameters | null;
    experiment: Experiment;
    plot: Plot;
    setDisplaySaveDisabled: (value: boolean) => void;
    setUpdateGroupsDisabled: (value: boolean) => void;
    updateGroupsDisabled: boolean;
};
const CellScatterPlotDisplayFields = ({
    analysisParameters,
    experiment,
    plot,
    setDisplaySaveDisabled,
    setUpdateGroupsDisabled,
    updateGroupsDisabled,
}: Props) => {
    const { values, setFieldValue } = useFormikContext<CellScatterPlotDisplayOptionFormValues>();
    const [splitByCluster, setSplitByCluster] = useState(Boolean(values?.annotation_set_id) ?? false);
    const switchStyles = useSwitchStyles();
    const { annotationSets } = useExperimentAnnotationContext();
    const typedParameters = analysisParameters as SeuratMarkerExpressionAnalysisParameters;

    const ClusterAnnotationSetItems: ChoiceItem[] =
        annotationSets
            ?.sort((a, b) => a.resolution - b.resolution)
            .map((c) => {
                return {
                    label: (
                        <div className="flex flex-col">
                            <p className="text-dark">{c.display_name}</p>
                            <div className="flex flex-row text-sm text-gray-500">
                                <p>Resolution: {c.resolution.toFixed(1)}</p>{' '}
                                <p className="ml-4">Clusters: {c.clusters.length}</p>
                            </div>
                        </div>
                    ),
                    value: c.uuid,
                };
            }) ?? [];

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <TextInput
                name="custom_options_json.dot_radius"
                label="Point size"
                type="number"
                value={values.custom_options_json?.dot_radius}
                placeholder="2.0"
                onValueChange={(value, _, __) => {
                    setFieldValue('custom_options_json.dot_radius', value);
                }}
                allowNegativeValue={false}
                allowDecimals
                step={0.5}
                min={0}
                useNumericFormat
                decimalScale={1}
            />
            <section className="mb-8">
                <p className="field-label">Color scale</p>
                <RangeFieldColorGroup hintText="expression" />
            </section>
            <section className="mb-8">
                {typedParameters?.cell_count >= 100_000 ? (
                    <>
                        <TextInput
                            name="downsampling_seed"
                            label="Downsampling seed"
                            type="number"
                            value={values.downsampling_seed ?? 42}
                            onValueChange={(_, __, values) => setFieldValue('downsampling_seed', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            useNumericFormat
                            tooltip={{
                                title: 'Set a seed for downsampling to ensure you consistently get the same subset of data each time you run your analysis.',
                            }}
                        />
                        <TextInput
                            name="downsampling_num"
                            label="Downsampling number"
                            type="number"
                            value={values.downsampling_num ?? 100_000}
                            onValueChange={(_, __, values) => setFieldValue('downsampling_num', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            max={100_000}
                            min={0}
                            useNumericFormat
                            tooltip={{
                                title: 'Set a downsampling number for quicker plot visualization. If the number of cells in your dataset is less than the downsampling number, all cells will be used.',
                            }}
                        />
                    </>
                ) : null}
            </section>
            <section className="mb-8 space-y-4">
                <h4 className="text-dark mb-2 text-lg font-semibold tracking-tight">Cell coordinates</h4>
                <div className="space-y-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="cell_coordinate_type"
                            onChange={() => {
                                setFieldValue('cell_coordinate_type', 'umap');
                            }}
                            checked={values.cell_coordinate_type === 'umap'}
                        />
                        <span className="ml-2">UMAP</span>
                    </label>
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="cell_coordinate_type"
                            checked={values.cell_coordinate_type === 'tsne'}
                            onChange={() => {
                                setFieldValue('cell_coordinate_type', 'tsne');
                            }}
                        />
                        <span className="ml-2">tSNE</span>
                    </label>
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="cell_coordinate_type"
                            checked={values.cell_coordinate_type === 'pc'}
                            onChange={() => {
                                setFieldValue('cell_coordinate_type', 'pc');
                            }}
                        />
                        <span className="ml-2">PC</span>
                    </label>
                </div>
            </section>
            <section className="mb-8 space-y-4">
                <h4 className="text-dark mb-2 text-lg font-semibold tracking-tight">Cell groups</h4>
                <p className="mb-4">
                    Group your cells by combining cluster annotation sets, variables, and/or latent variables
                </p>
                <div className="form-field !flex flex-row items-center justify-between">
                    <span className="field-label">Group by cluster</span>
                    <Switch
                        sx={switchStyles}
                        checked={splitByCluster}
                        name="split_by_cluster"
                        onChange={(e) => {
                            setSplitByCluster((prev) => !prev);
                            if (!e.target.checked) {
                                setFieldValue('annotation_set_id', null);
                            }
                        }}
                    />
                </div>
                {splitByCluster && (
                    <SimpleSelectField
                        name="annotation_set_id"
                        label="Cluster annotation set"
                        // @Post-MVP: Adding expand button and modal
                        // label={
                        //     <>
                        //         <span>Cluster annotation set</span>
                        //         <Button
                        //             variant="text"
                        //             color="primary"
                        //             onClick={() => setExpanded(true)}
                        //             endIcon={<ArrowsExpandIcon className="h-4 w-4" />}
                        //         >
                        //             Expand
                        //         </Button>
                        //     </>
                        // }
                        // labelClassName="w-full flex items-center justify-between"
                        value={values.annotation_set_id}
                        onChange={(e) => setFieldValue('annotation_set_id', e.target.value)}
                        items={ClusterAnnotationSetItems}
                        placeholder="Select an annotation set..."
                    />
                )}
                <SeuratDifferentialVariablesField
                    plot={plot}
                    analysisParameters={typedParameters}
                    experiment={experiment}
                />
                <SeuratDifferentialLatentVariablesField
                    plot={plot}
                    analysisParameters={typedParameters}
                    experiment={experiment}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        mutate(
                            Endpoints.lab.experiment.plot.groupsV3({
                                experimentId: experiment.uuid,
                                plotId: plot.uuid,
                            }),
                        );
                    }}
                    disabled={updateGroupsDisabled}
                >
                    Update groups
                </Button>
            </section>

            <ManageGroups
                plot={plot}
                experiment={experiment}
                setDisplaySaveDisabled={setDisplaySaveDisabled}
                setUpdateGroupsDisabled={setUpdateGroupsDisabled}
            />
        </>
    );
};

export default CellScatterPlotDisplayFields;
