import { AnalysisCategory } from '@models/analysis/AnalysisCategory';
import { AnalysisGroup } from '@models/AnalysisParameters';
import { AnalysisShortname } from '@models/analysis/AnalysisType';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import { ClusteringAnalysis } from '@models/analysis/ClusteringAnalysis';
import { CoverageAnalysis } from '@models/analysis/CoverageAnalysis';
import { DifferentialExpressionAnalysis } from '@models/analysis/DifferentialExpressionAnalysis';
import { ExternalAnalysis } from '@models/analysis/ExternalAnalysis';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import { isBlank } from '@util/StringUtil';
import { SeuratMarkerExpressionAnalysis } from '@models/analysis/SeuratMarkerExpressionAnalysis';
import { SeuratModuleScoreAnalysis } from '@models/analysis/SeuratModuleScoreAnalysis';
import { OverlapAnalysis, OverlapAnalysisResponse } from './OverlapAnalysis';
import { PeakAnalysis } from '@models/analysis/PeakAnalysis';
import { PipelineStatus } from '@models/ExperimentData';
import { PrincipalComponentsAnalysis } from '@models/analysis/PrincipalComponentsAnalysis';
import { PrismAnalysis } from './PrismAnalysis';
import { ProteinProteinInteractionAnalysis } from './ProteinProteinInteractionAnalysis';
import { SampleCorrelationAnalysis } from './SampleCorrelationAnalysis';
import { SeuratOverRepresentationAnalysis } from './SeuratOverRepresentationAnalysis';
import { SpreadsheetAnalysis } from '@models/analysis/SpreadsheetAnalysis';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import { TextAnalysis } from '@models/analysis/TextAnalysis';
import { TranscriptionFactorEnrichmentAnalysis } from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import { UMAPAnalysis } from '@models/analysis/UMAPAnalysis';

export interface BaseExperimentAnalysis {
    analysis_form_type?: string;
    analysis_type: AnalysisShortname;
    category: AnalysisCategory;
    has_results_summary?: boolean;
    name?: string | null;
    uuid: string;
    variables?: number[];
}

// export type PipelineStatusCode = ''

export interface PipelineStatusAnalysis extends BaseExperimentAnalysis {
    pipeline_status: PipelineStatus;
    pipeline_version?: string | number | null;
    pipeline_status_code?: string; //todo: get a set of known status codes
}

export const isPipelineAnalysis = (
    analysis?: BaseExperimentAnalysis | Pick<PipelineStatusAnalysis, 'pipeline_version' | 'pipeline_status'> | null,
): analysis is PipelineStatusAnalysis => {
    return !isBlank((analysis as PipelineStatusAnalysis)?.pipeline_status);
};

export interface AnalysisVariables {
    variables: number[];
}

export interface BaseAssaySummaryExperimentAnalysis extends BaseExperimentAnalysis, AnalysisVariables {
    variables: number[];
    groups: AnalysisGroup[];
    group_display_order: number[];
}

export type PlotMetadata = {
    has_gene_set: boolean;
    has_score_directions: boolean;
    show_top_scores: boolean;
    x_axis_column: string;
    y_axis_column: string;
    p_value_column: string;
};

export interface ExperimentAnalysisWithCustomLegend extends PipelineStatusAnalysis, SeuratOverRepresentationAnalysis {}

export type ExperimentAnalysis =
    | AssaySummaryAnalysis
    | BaseAssaySummaryExperimentAnalysis
    | ClusteringAnalysis
    | CoverageAnalysis
    | DifferentialExpressionAnalysis
    | ExternalAnalysis
    | GeneSetEnrichmentAnalysis
    | ImageAnalysis
    | SeuratMarkerExpressionAnalysis
    | SeuratModuleScoreAnalysis
    | OverlapAnalysis
    | OverlapAnalysisResponse
    | PeakAnalysis
    | PrincipalComponentsAnalysis
    | PrismAnalysis
    | ProteinProteinInteractionAnalysis
    | SampleCorrelationAnalysis
    | SeuratOverRepresentationAnalysis
    | SpreadsheetAnalysis
    | SurvivalAnalysis
    | TextAnalysis
    | TranscriptionFactorEnrichmentAnalysis
    | UMAPAnalysis;
