import { usePlotContext } from '@contexts/PlotContext';
import ExternalPlotView from '@components/analysisCategories/content/plots/ExternalPlotView';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';

const ExternalPlotDisplayView = () => {
    const { plot, experiment } = usePlotContext();
    switch (plot.display.display_type) {
        case 'external':
            return <ExternalPlotView plot={plot} experiment={experiment} />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default ExternalPlotDisplayView;
