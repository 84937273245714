import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { FilterSeuratObjectFormValues, PreprocessFormValues } from '../../preprocesses/PreprocessFormTypes';
import ProseList from '@components/ProseList';
import DataTable from '@components/dataTable/DataTable';
import { geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import Experiment from '@models/Experiment';
import { formatStringToNumberWithSeparator } from '@util/StringUtil';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string; experiment: Experiment };
const FilterSeuratMethods = ({ preprocessFormData, displayName, experiment }: Props) => {
    const preprocess = preprocessFormData as FilterSeuratObjectFormValues;

    const formatDataForTable = () => {
        const headers = Object.keys(preprocess.sample_configs[0]).filter((key) => key !== 'sample_id');
        headers.splice(0, 0, 'sample_id');
        return {
            display_name: 'Filtering Thresholds',
            shortname: 'filtering_thresholds',
            headers,
            items: preprocess.sample_configs,
        };
    };

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                <div className="space-y-4">
                    {preprocess?.rm_samples?.length > 0 && (
                        <p>
                            The following samples were removed from the Seurat object:{' '}
                            <ProseList items={preprocess.rm_samples} />
                        </p>
                    )}

                    {preprocess.filter_all_samples ? (
                        <p>
                            Cells were removed from the Seurat object based on the following thresholds: UMI counts
                            below {formatStringToNumberWithSeparator(preprocess.sample_configs[0].min_count)}, UMI
                            counts exceeding {formatStringToNumberWithSeparator(preprocess.sample_configs[0].max_count)}
                            , feature counts below{' '}
                            {formatStringToNumberWithSeparator(preprocess.sample_configs[0].min_feature)}, and feature
                            counts exceeding{' '}
                            {formatStringToNumberWithSeparator(preprocess.sample_configs[0].max_feature)}. Cells with
                            novelty scores below{' '}
                            {formatStringToNumberWithSeparator(preprocess.sample_configs[0].min_ns)} or with a
                            percentage of mitochondrial UMIs above{' '}
                            {formatStringToNumberWithSeparator(preprocess.sample_configs[0].max_mito)}% were also
                            excluded.
                        </p>
                    ) : (
                        <p>
                            Cells were removed from the Seurat object based on the following thresholds (see table for
                            explicit threshold values): UMI counts below{' '}
                            <span className="font-semibold">Minimum number of UMIs per cell</span>, UMI counts exceeding{' '}
                            <span className="font-semibold">Maximum number of UMIs per cell</span>, feature counts below{' '}
                            <span className="font-semibold">Minimum number of features per cell</span>, and feature
                            counts exceeding <span className="font-semibold">Maximum number of features per cell</span>.
                            Cells with novelty scores below{' '}
                            <span className="font-semibold">Minimum novelty score per cell</span> or with a percentage
                            of mitochondrial UMIs above{' '}
                            <span className="font-semibold">Maximum % mito UMIs per cell</span> were also excluded.
                        </p>
                    )}

                    {preprocess.rm_doublets && (
                        <p>Cells identified as potential doublets/multiplets were removed from the data.</p>
                    )}

                    {!preprocess.filter_all_samples && preprocess.sample_configs && (
                        <DataTable
                            data={formatDataForTable()}
                            tableColor="bg-cyan-100 text-cyan-900"
                            key={'filter_seurat_methods'}
                            cellRenderer={geneLinkCellRenderer({ experiment })}
                        />
                    )}

                    <div>
                        <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                        <p>
                            R v4.3.1
                            <br />
                            Seurat v4.3.0
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FilterSeuratMethods;
