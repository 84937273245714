import * as Yup from 'yup';
import {
    ClusterSeuratObjectFormValues,
    CreateSeuratObjectFormValues,
    FilterSeuratObjectFormValues,
    IntegrateSeuratObjectFormValues,
    NormalizeSeuratObjectFormValues,
    PreprocessFormValues,
    RefineSeuratObjectFormValues,
} from '@components/experiments/preprocesses/PreprocessFormTypes';
import {
    clusterSeuratObjectSchema,
    createSeuratObjectSchema,
    filterSeuratObjectSchema,
    integrateSeuratObjectSchema,
    normalizeSeuratObjectSchema,
    refineSeuratObjectSchema,
} from '@components/experiments/preprocesses/PreprocessFormSchemas';

import { NormalizeSeuratObject } from '@models/preprocesses/NormalizeSeuratObject';
import { CreateSeuratObject } from '@models/preprocesses/CreateSeuratObject';
import { FilterSeuratObject } from '@models/preprocesses/FilterSeuratObject';
import { IntegrateSeuratObject } from '@models/preprocesses/IntegrateSeuratObject';
import { ClusterSeuratObject } from '@models/preprocesses/ClusterSeuratObject';
import PreprocessStep from '@models/PreprocessStep';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import { RefineSeuratObject } from '@models/preprocesses/RefineSeuratObject';

/**
 * Values needed to initialize the Preprocess form
 */
export type FormSetupParams = {
    preprocess: PreprocessStep | null;
    formData: PreprocessFormValues | null;
};
export type InitialValues = PreprocessFormValues | null;

/**
 * Values needed to create a new Preprocess Form
 */
export type FormSetup = { initialValues: InitialValues; schema: Yup.AnySchema | null };

const getDefaultSampleConfigs = () => {
    const { preprocessParameters, selectedWorkflow } = useExperimentWorkflowContext();
    const samples = preprocessParameters?.find((param) => param.workflowId === selectedWorkflow?.uuid);
    const formatted = samples?.preprocessParameters?.sample_ids?.map((param) => ({
        sample_id: param,
        min_count: 1000,
        max_count: 10000,
        min_feature: 500,
        max_feature: 5000,
        min_ns: 0.8,
        max_mito: 20,
    })) ?? [
        {
            sample_id: 'test',
            min_count: 1000,
            max_count: 10000,
            min_feature: 500,
            max_feature: 5000,
            min_ns: 0.8,
            max_mito: 20,
        },
        {
            sample_id: 'test2',
            min_count: 1000,
            max_count: 10000,
            min_feature: 500,
            max_feature: 5000,
            min_ns: 0.8,
            max_mito: 20,
        },
    ];
    return formatted ?? [];
};

/**
 * Get the initial conditions for the preprocess form.
 * @param {PreprocessStep} preprocess
 * @param {} formData
 * @return {FormSetup | null} returns null if the preprocess type is not supported
 */
export const getFormSetup = ({ preprocess: preprocessProp, formData }: FormSetupParams): FormSetup => {
    const preprocessType = preprocessProp?.preprocess_type?.shortname;
    if (preprocessType === 'seurat_create_object') {
        const preprocess = formData as CreateSeuratObject | null;
        const initialValues: CreateSeuratObjectFormValues = {
            min_cells: preprocess?.min_cells ?? 0,
            min_features: preprocess?.min_features ?? 200,
            preprocess_type: 'seurat_create_object',
        };
        return {
            schema: createSeuratObjectSchema,
            initialValues,
        };
    }
    if (preprocessType === 'seurat_filter_object') {
        const preprocess = formData as FilterSeuratObject | null;
        const initialValues: FilterSeuratObjectFormValues = {
            preprocess_type: 'seurat_filter_object',
            rm_doublets: preprocess?.rm_doublets ?? false,
            rm_samples: preprocess?.rm_samples ?? [],
            filter_all_samples: preprocess?.filter_all_samples ?? true,
            sample_configs: preprocess?.sample_configs ?? getDefaultSampleConfigs(),
        };
        return {
            schema: filterSeuratObjectSchema,
            initialValues,
        };
    }

    if (preprocessType === 'seurat_normalize_object') {
        const preprocess = formData as NormalizeSeuratObject | null;
        const initialValues: NormalizeSeuratObjectFormValues = {
            n_dims: preprocess?.n_dims ?? 40,
            n_features: preprocess?.n_features ?? 3000,
            n_neighbors: preprocess?.n_neighbors ?? 20,
            norm_method: preprocess?.norm_method ?? 'lognormalize',
            preprocess_type: 'seurat_normalize_object',
            regress_cc_diff: preprocess?.regress_cc_diff ?? false,
            regress_cc: preprocess?.regress_cc ?? false,
            regress_mito: preprocess?.regress_mito ?? false,
            regress_umi: preprocess?.regress_umi ?? false,
        };
        return {
            schema: normalizeSeuratObjectSchema,
            initialValues,
        };
    }
    if (preprocessType === 'seurat_integrate_object') {
        const preprocess = formData as IntegrateSeuratObject | null;
        const initialValues: IntegrateSeuratObjectFormValues = {
            covariates: preprocess?.covariates ?? [],
            int_method: preprocess?.int_method ?? 'skip',
            n_dims: preprocess?.n_dims ?? 40,
            n_features: preprocess?.n_features ?? 3000,
            n_neighbors: preprocess?.n_neighbors ?? 20,
            norm_method: preprocess?.norm_method ?? 'lognormalize',
            preprocess_type: 'seurat_integrate_object',
            regress_cc_diff: preprocess?.regress_cc_diff ?? false,
            regress_cc: preprocess?.regress_cc ?? false,
            regress_mito: preprocess?.regress_mito ?? false,
            regress_umi: preprocess?.regress_umi ?? false,
        };
        return {
            schema: integrateSeuratObjectSchema,
            initialValues,
        };
    }
    if (preprocessType === 'seurat_cluster_object') {
        const preprocess = formData as ClusterSeuratObject | null;
        const initialValues: ClusterSeuratObjectFormValues = {
            input_preprocess_id: preprocess?.input_preprocess_id ?? '',
            k_param: preprocess?.k_param ?? 20,
            log2fc: preprocess?.log2fc ?? 0.25,
            n_dims: preprocess?.n_dims ?? 40,
            only_pos: preprocess?.only_pos ?? true,
            preprocess_type: 'seurat_cluster_object',
            resolutions: preprocess?.resolutions ?? ['0.2', '0.6', '1.0', '1.2'],
            stat_test: preprocess?.stat_test ?? 'wilcox',
        };
        return {
            schema: clusterSeuratObjectSchema,
            initialValues,
        };
    }
    if (preprocessType === 'seurat_refine_object') {
        const preprocess = formData as RefineSeuratObject | null;
        const initialValues: RefineSeuratObjectFormValues = {
            target_clusters: preprocess?.target_clusters ?? [],
            preprocess_type: 'seurat_refine_object',
            refine_method: preprocess?.refine_method ?? 'remove_clusters',
            target_resolution: preprocess?.target_resolution ?? '1.0',
        };
        return {
            schema: refineSeuratObjectSchema,
            initialValues,
        };
    }

    return {
        schema: null,
        initialValues: null,
    };
};
