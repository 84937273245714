import { MutableRefObject, useEffect, useState } from 'react';

function useOnScreen<T extends Element>({
    ref,
    rootMargin = '0px',
    initialOnScreen = false,
    forceOffScreen = false,
}: {
    ref: MutableRefObject<T | null>;
    rootMargin?: string;
    initialOnScreen?: boolean;
    forceOffScreen?: boolean;
}) {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState<boolean>(initialOnScreen);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
                threshold: 0.1,
            },
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const forceShow = () => {
        setIntersecting(true);
    };

    return { onScreen: !forceOffScreen && isIntersecting, forceShow };
}

export default useOnScreen;
