import { ScrollableSidebarContent } from '@components/experiments/ScrollableSidebarContent';
import React from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import LoadingMessage from '@components/LoadingMessage';
import { Alert } from '@mui/material';
import AnalysisForm from '@components/experiments/analyses/AnalysisForm';
import { ApiError } from '@services/ApiError';
import useAnalysisParameters from '@hooks/useAnalysisParameters';

type Props = { plot: Plot; experiment: Experiment };
const AnalysisView = ({ plot, experiment }: Props) => {
    const {
        analysisParameters,
        analysisParametersError,
        loading: paramsLoading,
    } = useAnalysisParameters({
        plot,
        experiment,
    });

    if (paramsLoading) {
        return (
            <div>
                <LoadingMessage message="Analysis parameters loading..." />
            </div>
        );
    }

    if (analysisParametersError) {
        return (
            <ScrollableSidebarContent className="px-8">
                <Alert severity={'error'}>
                    <p className="font-semibold">Unable to load the analysis parameters.</p>
                    <p>{ApiError.getMessage(analysisParametersError, '\n\n')}</p>
                </Alert>
            </ScrollableSidebarContent>
        );
    }

    if (!plot || !analysisParameters) {
        return (
            <ScrollableSidebarContent className="px-8">
                Either the Plot, the Analysis Parameters, or both were not found.
            </ScrollableSidebarContent>
        );
    }

    return <AnalysisForm plot={plot} experiment={experiment} analysisParameters={analysisParameters ?? null} />;
};

export default AnalysisView;
