import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ScrollableSidebarContent } from '@components/experiments/ScrollableSidebarContent';
import { Flipped, Flipper } from 'react-flip-toolkit';
import StatisticsResultView from '@components/experiments/StatisticsResultView';
import React, { useState } from 'react';
import useStatisticalTest from '@hooks/useStatisticalTest';
import { SummaryStatisticalTestResult, UpdateStatisticalTestResultParams } from '@models/SummaryStatisticalTestResult';
import ConfirmModal from '@components/ConfirmModal';
import PlotStatisticsForm from '@components/experiments/PlotStatisticsForm';
import { formatSmallNumber } from '@util/StringUtil';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';

type Props = { plot: Plot; experiment: Experiment };
const PlotStatisticsView = ({ experiment, plot }: Props) => {
    const analysis = plot.analysis as AssaySummaryAnalysis | null;
    const [statToDelete, setStatToDelete] = useState<SummaryStatisticalTestResult | null>(null);
    const { results, deleteStatResult, createStatisticalTest, updateStatisticalTest } = useStatisticalTest({
        experiment,
        analysis,
    });
    const { getTargetById } = useAnalysisParameters({ plot, experiment });

    const { getGroupById } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: analysis?.variables,
    });

    const handleUpdateStatResult = async (
        result: SummaryStatisticalTestResult,
        values: UpdateStatisticalTestResultParams,
    ) => {
        await updateStatisticalTest(result, values);
    };

    const handleDeleteStatsResult = async (result: SummaryStatisticalTestResult | null) => {
        if (!result || result.uuid !== statToDelete?.uuid) {
            setStatToDelete(result);
            return;
        }
        await deleteStatResult(result);
        setStatToDelete(null);
    };

    return (
        <>
            <ScrollableSidebarContent className="px-8">
                <div className="w-full">
                    <PlotStatisticsForm
                        plot={plot}
                        experiment={experiment}
                        createStatisticalTest={createStatisticalTest}
                    />
                    <p className="mb-4 text-xl font-semibold text-dark">Tests</p>
                    {results && (
                        <Flipper flipKey={results.map((r) => r.uuid).join('')} spring="stiff">
                            <ul className="space-y-4">
                                {results.length === 0 && <p>You haven&apos;t run any tests yet</p>}
                                {results.map((result) => {
                                    return (
                                        <Flipped key={result.uuid} flipId={result.uuid}>
                                            <li>
                                                <StatisticsResultView
                                                    result={result}
                                                    onDelete={handleDeleteStatsResult}
                                                    onUpdate={(values: UpdateStatisticalTestResultParams) =>
                                                        handleUpdateStatResult(result, values)
                                                    }
                                                    getGroupById={getGroupById}
                                                    getTargetById={getTargetById}
                                                />
                                            </li>
                                        </Flipped>
                                    );
                                })}
                            </ul>
                        </Flipper>
                    )}
                </div>
            </ScrollableSidebarContent>
            <ConfirmModal
                open={!!statToDelete}
                onConfirm={() => handleDeleteStatsResult(statToDelete)}
                onCancel={() => setStatToDelete(null)}
                title="Delete statistical test"
                message={
                    <p>
                        Are you sure you want to remove <i>p</i>
                        {formatSmallNumber({
                            value: statToDelete?.value,
                            defaultPrefix: '=',
                        })}
                        ?
                    </p>
                }
                confirmText="Yes, delete"
                cancelText="Cancel"
            />
        </>
    );
};

export default PlotStatisticsView;
