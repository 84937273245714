import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useMemo,
    useRef,
    useState,
    useEffect,
} from 'react';
import Plot, { PlotListItem, PlotListItemsResponse } from '@models/Plot';
import Experiment from '@models/Experiment';
import { useSWRConfig } from 'swr';
import Endpoints from '@services/Endpoints';
import ConfirmModal from '@components/ConfirmModal';
import useApi from '@hooks/useApi';
import { isNotBlank } from '@util/StringUtil';
import Logger from '@util/Logger';
import { AuthContext } from '@contexts/AuthContext';
import { buildExperimentShellParams } from '@util/ExperimentUtil';
import useExperimentPermissions, { ExperimentPermissions } from '@hooks/useExperimentPermissions';
import PlotEmbedModal from '@components/PlotEmbedModal';
import InsightsAdminPhase from '@models/InsightsAdminPhase';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { SelectableExperimentType } from '@models/ExperimentType';
import IGVBrowser from '@models/igv/IGVBrowser';
import { StateSetter } from '@contexts/ContextTypes';
import { PaginationParams } from '@services/EndpointUtil';
import ExperimentModal from '@components/ExperimentModal';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import { TabViewName } from '@components/experiments/roadmap/ExperimentRoadmap';
import AcceptPreprocessModal from '@components/experiments/preprocesses/AcceptPreprocessModal';
import PreprocessStep from '@models/PreprocessStep';
import NewWorkflowModal from '@components/experiments/workflows/NewWorkflowModal';
import Workflow, { AcceptedWorkflow } from '@models/Workflow';
import AcceptWorkflowModal from '@components/experiments/workflows/AcceptWorkflowModal';
import AddAsEvidenceDialog from '@components/biomarkers/evidence/AddAsEvidenceDialog';
import AnnotationSet from '@models/Annotation';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import { useExperimentCanvasContext } from '@contexts/ExperimentCanvasContext';
import { Node } from 'reactflow';
import useAnalysisInputsSubmit from '../hooks/useAnalysisInputsSubmit';
import { clearHash, updateHashWithPreprocessUUID } from '@util/hashUtil';

const logger = Logger.make('ExperimentDetailViewContext');
export type EditPlotPanelName = 'analysis' | 'plot' | 'statistics';
export type UnsavedChangesActions = '' | 'changePlotPanel' | 'changeSelectedPlot' | 'createAnalysis' | 'closeModal';

export interface PreviewFile extends File {
    preview?: string;
}

export type PlotResponse = {
    count: number;
    items: Plot[];
};

export type OverrideMap = Partial<Record<string, string | null | undefined>>;
export type PlotOverrideSummary = {
    display_id?: string | null | undefined;
    analysis_id?: string | null | undefined;
    hasOverrides: boolean;
};
export type ContextType = {
    addPreviewPlot: (plot: Plot) => void;
    analysisFormSubmitDisabled: boolean;
    archivePlot: (plotId: string, fromCanvas?: boolean) => void;
    clearAllAnalysesOnExperiment: () => Promise<void>;
    clearAllOverrides: () => void;
    clearPlotOverrides: (plotId: string) => void;
    commentsModalOpen: boolean;
    copyOpen: boolean;
    currentChanges: Partial<Record<string, any>> | string[] | null;
    currentContextMenuPlotId: string | null;
    currentPlotPanel: EditPlotPanelName;
    currentRoadmapTab: TabViewName | null;
    disableRemovePlots: boolean;
    editDataInterstitialOpen: boolean;
    experiment: Experiment | null;
    experimentCommentsOpen: boolean;
    experimentModalOnClose: () => void;
    experimentType: SelectableExperimentType | null | undefined;
    getIgvBrowserByPlotId: (plotId: string) => IGVBrowser | null;
    getPlotOverrides: (plotId: string) => PlotOverrideSummary;
    handleChangeSelectedPlot: (plot: Plot | null | undefined) => void;
    hasPreviewPlots: boolean;
    inputExpanded: string | null;
    inputSaveButtonRef: React.RefObject<HTMLDivElement>;
    insightsAdminPhases: InsightsAdminPhase[];
    nestedCurrentChanges: string | null;
    onAnalysisSubmit: (analysisType: string, dryRun?: boolean) => true | undefined;
    openAnnotationModal: (selectedAnnotationSet: AnnotationSet) => void;
    openDataTableModal: (node: Node) => void;
    openPreprocessModal: (selectedWorkflow: Workflow, preprocess: PreprocessStep, source?: string) => void;
    permissions: ExperimentPermissions | null;
    pingSave: string | null;
    plotAnalysisOverrides: OverrideMap;
    plotDisplayOptionOverrides: OverrideMap;
    plotListItemCount: number;
    plotListItems: PlotListItem[] | null;
    plotListLoading: boolean;
    plotQuery: PaginationParams;
    plotToEmbed: Plot | null;
    refreshExperiment: () => Promise<void>;
    refreshPlotById: (id: string) => Promise<void>;
    refreshPlotItems: () => Promise<void>;
    removePreviewPlot: (plot: Pick<Plot, 'uuid'>) => void;
    scrollToInputSaveButton: () => void;
    selectedPlot: Plot | null;
    setAnalysisFormSubmitDisabled: Dispatch<SetStateAction<boolean>>;
    setCommentsModalOpen: (open: boolean) => void;
    setConfirmCreateModalOpen: Dispatch<SetStateAction<boolean>>;
    setCopyOpen: StateSetter<boolean>;
    setCurrentChanges: Dispatch<SetStateAction<Partial<Record<string, any>> | string[] | null>>;
    setCurrentContextMenuPlotId: StateSetter<string | null>;
    setCurrentPlotPanel: Dispatch<SetStateAction<EditPlotPanelName>>;
    setCurrentRoadmapTab: Dispatch<SetStateAction<TabViewName | null>>;
    setEditDataInterstitialOpen: StateSetter<boolean>;
    setEvidenceModalOpen: (open: boolean) => void;
    setEvidencePlotId: (id: string) => void;
    setExperimentCommentsOpen: (open: boolean) => void;
    setExperimentModalOpen: Dispatch<SetStateAction<boolean>>;
    setIGVBrowserByPlotId: (plotId: string, igvBrowser: IGVBrowser | null | undefined) => void;
    setInputExpanded: Dispatch<SetStateAction<string | null>>;
    setNestedCurrentChanges: Dispatch<SetStateAction<string | null>>;
    setOpenOnComments: (open: boolean) => void;
    setOpenOnPlot: (open: boolean) => void;
    setPingSave: Dispatch<SetStateAction<string | null>>;
    setPlotAnalysisOverride: (params: { plotId: string; analysisId: string | null }) => void;
    setPlotDisplayOptionOverride: (params: { plotId: string; displayOptionId: string | null }) => void;
    setPlotQuery: Dispatch<SetStateAction<PaginationParams>>;
    setPlotToEmbed: (plot: Plot | null) => void;
    setSelectedPlot: Dispatch<SetStateAction<Plot | null>>;
    settingsLoading: boolean;
    setTmpSelectedPlot: StateSetter<Plot | null>;
    setUnsavedChangesConfirmOpen: StateSetter<UnsavedChangesActions>;
    setZoomTransform: Dispatch<SetStateAction<d3.ZoomTransform | null>>;
    showAdminPhases: boolean;
    tmpSelectedPlot: Plot | null;
    unsavedChangesConfirmOpen: UnsavedChangesActions;
    updatePlotDisplayOrder: (updatedOrder: string[]) => Promise<void>;
    updatePreviewPlot: (plot: Plot) => void;
    zoomTransform: d3.ZoomTransform | null;
    /** Applies publication styles to plots, like pure black text color, different font size, etc. */
    publicationMode: boolean;
    setPublicationMode: (publicationView: boolean) => void;
    /** For presenting to a group */
    presentationMode: boolean;
    setPresentationMode: StateSetter<boolean>;
};
export const ExperimentDetailViewContext = createContext<ContextType | null>(null);

export const useExperimentDetailViewContext = () => {
    const context = useContext(ExperimentDetailViewContext);
    if (!context) {
        throw new Error(
            'ExperimentDetailViewContext has not been defined. Ensure you have wrapped your component in a context provider',
        );
    }
    return context;
};

export const useOptionalExperimentDetailViewContext = (): Partial<ContextType> => {
    const context = useContext(ExperimentDetailViewContext);
    if (!context) {
        return {};
    }
    return context;
};

ExperimentDetailViewContext.displayName = 'ExperimentDetailViewContext';
const defaultQuery: PaginationParams = { offset: 0, limit: 20 };

export const ExperimentDetailViewContextProvider = ({
    children,
    experiment,
    disableRemovePlots = false,
}: {
    children?: ReactNode;
    experiment?: Experiment | null;
    disableRemovePlots?: boolean;
}) => {
    const experimentId = experiment?.uuid;
    const [analysisFormSubmitDisabled, setAnalysisFormSubmitDisabled] = useState(false);
    const [archiveLoading, setArchiveLoading] = useState(false);
    const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [currentChanges, setCurrentChanges] = useState<Partial<Record<string, any>> | string[] | null>(null);
    const [currentContextMenuPlotId, setCurrentContextMenuPlotId] = useState<string | null>(null);
    const [currentPlotPanel, setCurrentPlotPanel] = useState<EditPlotPanelName>('analysis');
    const [currentRoadmapTab, setCurrentRoadmapTab] = useState<TabViewName | null>(null);
    const [editDataInterstitialOpen, setEditDataInterstitialOpen] = useState(false);
    const [experimentCommentsOpen, setExperimentCommentsOpen] = useState<boolean>(false);
    const [evidenceModalOpen, setEvidenceModalOpen] = useState<boolean>(false);
    const [evidencePlotId, setEvidencePlotId] = useState<string | null>(null);
    const [experimentModalOpen, setExperimentModalOpen] = useState(false);
    const [nestedCurrentChanges, setNestedCurrentChanges] = useState<string | null>(null);
    const [openOnComments, setOpenOnComments] = useState<boolean>(false);
    const [openOnPlot, setOpenOnPlot] = useState<boolean>(false);
    const [plotAnalysisOverrides, setPlotAnalysisOverrides] = useState<OverrideMap>({});
    const [plotDisplayOptionOverrides, setPlotDisplayOptionOverrides] = useState<OverrideMap>({});
    const [plotDisplayOrder, setPlotDisplayOrder] = useState<string[]>(experiment?.plot_display_order ?? []);
    const [plotIdForArchive, setPlotIdForArchive] = useState<{ plotId: string; fromCanvas: boolean } | null>(null);
    const [plotListItemCount, setPlotListItemCount] = useState<number>(0);
    const [plotListItems, setPlotListItems] = useState<PlotListItem[]>([]);
    const [plotListLoading, setPlotListLoading] = useState<boolean>(false);
    const [plotQuery, setPlotQuery] = useState<PaginationParams>(defaultQuery);
    const [plotToEmbed, setPlotToEmbed] = useState<Plot | null>(null);
    const [presentationMode, setPresentationMode] = useState(false);
    const [previewPlots, setPreviewPlots] = useState<Plot[]>([]);
    const [publicationMode, setPublicationMode] = useState(false);
    const [removePlotConfirmOpen, setRemovePlotConfirmOpen] = useState(false);
    const [selectedPlot, setSelectedPlot] = useState<Plot | null>(null);
    const [selectedPlotId, setSelectedPlotId] = useState<string | null>(null);
    const [tmpSelectedPlot, setTmpSelectedPlot] = useState<Plot | null>(null);
    const [unsavedChangesConfirmOpen, setUnsavedChangesConfirmOpen] = useState<UnsavedChangesActions>('');
    const [zoomTransform, setZoomTransform] = useState(null);
    const igvBrowsersRef = useRef<Record<string, IGVBrowser | null>>({});
    const permissions = useExperimentPermissions(experiment);
    const {
        _acceptPreprocess,
        acceptPreprocessLoading,
        acceptWorkflow,
        acceptWorkflowError,
        acceptWorkflowLoading,
        acceptWorkflowModalOpen,
        confirmAcceptPreprocessModalOpen,
        confirmCreateModalOpen,
        createWorkflow,
        createWorkflowError,
        createWorkflowLoading,
        setAcceptWorkflowModalOpen,
        setConfirmAcceptPreprocessModalOpen,
        setConfirmCreateModalOpen,
        setPlotSignedUrl,
        setPreprocessReadOnly,
        setSelectedPreprocess,
        setSelectedWorkflow,
    } = useExperimentWorkflowContext();
    const { setSelectedAnnotationSet, setSelectedAnnotation } = useExperimentAnnotationContext();
    const { setSelectedNode, saveNodes, setCanvasNodes, canvasNodes } = useExperimentCanvasContext();
    const {
        inputExpanded,
        inputSaveButtonRef,
        onAnalysisSubmit,
        pingSave,
        scrollToInputSaveButton,
        setInputExpanded,
        setPingSave,
    } = useAnalysisInputsSubmit();

    const openPreprocessModal = (workflow: Workflow, preprocess: PreprocessStep, source?: string) => {
        if (source === 'deep_link') {
            setOpenOnComments(true);
        }
        setPlotSignedUrl(null);
        setSelectedWorkflow(workflow);
        setSelectedPreprocess(preprocess);
        setExperimentModalOpen(true);
        if (preprocess.step_status === 'accepted' || preprocess.pipeline_status === 'in_progress') {
            setPreprocessReadOnly(true);
        } else if (!Boolean(experiment?.accepted_workflow)) {
            setPreprocessReadOnly(false);
        }
        updateHashWithPreprocessUUID(preprocess.uuid);
    };
    const openAnnotationModal = (annotationSet: AnnotationSet) => {
        setSelectedAnnotationSet(annotationSet);
        setExperimentModalOpen(true);
    };
    const openDataTableModal = (node: Node) => {
        setSelectedNode(node);
        setExperimentModalOpen(true);
    };

    const { loading: settingsLoading, experimentType } = useExperimentSettings(experiment);
    const insightsAdminPhases = experimentType?.insights_admin_phases ?? [];

    const showAdminPhases = (insightsAdminPhases?.length ?? 0) > 0 && experiment?.status !== 'complete';

    const { fetcher, ...api } = useApi();
    const { mutate } = useSWRConfig();
    const hasPreviewPlots = previewPlots.length > 0;
    const { loggedIn } = useContext(AuthContext);

    useEffect(() => {
        if (!experimentId || !loggedIn) return;

        fetchPlotList();
    }, [plotQuery, experimentId]);

    useEffect(() => {
        if (!experiment?.plot_display_order) return;
        if (!plotDisplayOrder.length) setPlotDisplayOrder(experiment.plot_display_order);
    }, [experiment]);

    useEffect(() => {
        if (!selectedPlotId || !experimentId) return;

        const fetchSelectedPlot = async () => {
            try {
                const newSelectedPlot = await api.get<Plot>(
                    Endpoints.lab.experiment.plot.base({ experimentId, plotId: selectedPlotId }),
                );
                setSelectedPlot(newSelectedPlot);
            } catch {
                setSelectedPlot(null);
            }
        };

        fetchSelectedPlot();
    }, [selectedPlotId]);

    const fetchPlotList = async () => {
        if (!experimentId) return;
        setPlotListLoading(true);
        try {
            const newResponse = await api.get<PlotListItemsResponse>(
                Endpoints.lab.experiment.plotsV2({ experimentId }, plotQuery),
            );
            setPlotListItems(newResponse.items ?? []);
            setPlotListItemCount(newResponse.count ?? 0);
        } catch (error) {
            logger.log(error);
        } finally {
            setPlotListLoading(false);
        }
    };

    const handleChangeSelectedPlot = (plot: Plot | null) => {
        if ((currentChanges || nestedCurrentChanges) && !unsavedChangesConfirmOpen) {
            setTmpSelectedPlot(plot);
            setUnsavedChangesConfirmOpen('changeSelectedPlot');
            return;
        }
        // if a new plot is selected, reset the panel to analysis
        if (selectedPlotId !== plot?.uuid) {
            setCurrentPlotPanel('analysis');
        }
        setSelectedPlot(plot);
    };

    const updatePlotDisplayOrder = async (updatedOrder: string[]) => {
        if (!experiment) {
            return;
        }
        setPlotDisplayOrder(updatedOrder);
        if (!permissions.canEdit) {
            return;
        }
        // save the updated plot display order
        await mutate<Experiment>(
            Endpoints.lab.experiment.base(experiment.uuid),
            async (current) => {
                const params = buildExperimentShellParams({ experiment: current ?? experiment });
                params.plot_display_order = updatedOrder;
                return await api.put<Experiment>(Endpoints.lab.experiment.base(experiment.uuid), params);
            },
            {
                revalidate: false,
                optimisticData: {
                    ...experiment,
                    plot_display_order: updatedOrder,
                },
            },
        );
    };

    const refreshPlotById = async (plotId: string) => {
        if (experiment?.uuid) {
            await mutate(Endpoints.lab.experiment.plot.base({ experimentId: experiment.uuid, plotId }));
        } else {
            logger.warn('refreshPlotById called but no experiment was present ');
        }
    };

    const refreshExperiment = async () => {
        if (experiment?.uuid) {
            await mutate(Endpoints.lab.experiment.base(experiment.uuid));
        }
    };

    const archivePlot = (plotId: string, fromCanvas = false) => {
        setRemovePlotConfirmOpen(true);
        setPlotIdForArchive({ plotId, fromCanvas });
    };

    const setPlotAnalysisOverride = ({
        plotId,
        analysisId,
    }: {
        plotId: string;
        analysisId: string | null | undefined;
    }) => {
        setPlotAnalysisOverrides({ ...plotAnalysisOverrides, [plotId]: analysisId });
    };

    const setPlotDisplayOptionOverride = ({
        plotId,
        displayOptionId,
    }: {
        plotId: string;
        displayOptionId: string | undefined | null;
    }) => {
        setPlotDisplayOptionOverrides({ ...plotDisplayOptionOverrides, [plotId]: displayOptionId });
    };

    const refreshPlotItems = async () => {
        if (experiment?.uuid) {
            try {
                const newPlots = await api.get<PlotListItemsResponse>(
                    Endpoints.lab.experiment.plotsV2({ experimentId: experiment.uuid }, plotQuery),
                );
                setPlotListItems(newPlots.items ?? []);
                setPlotListItemCount(newPlots.count ?? 0);
            } catch (error) {
                logger.warn(error);
            }
        } else {
            logger.warn('refreshPlots called but no experiment was present ');
        }
    };

    const confirmArchivePlot = async (plotIdForArchiveProp?: string) => {
        if (!experiment?.uuid || (!plotIdForArchive?.plotId && !plotIdForArchiveProp)) {
            logger.warn('No Plot ID was found to archive, can not archive plot');
            setRemovePlotConfirmOpen(false);
            return;
        }
        const plotIdToArchive = (plotIdForArchive?.plotId || plotIdForArchiveProp) as string;

        try {
            setArchiveLoading(true);
            await fetcher(
                Endpoints.lab.experiment.plot.archive({
                    experimentId: experiment?.uuid,
                    plotId: plotIdToArchive,
                }),
                { method: 'POST' },
            );
            await refreshPlotItems();

            const sortedPlots = [...plotListItems].filter((p) => p.uuid !== plotIdToArchive);
            const updatedOrder: string[] = sortedPlots.map((p) => p.uuid);
            await updatePlotDisplayOrder?.(updatedOrder);

            if (plotIdForArchive?.fromCanvas) {
                const filteredNodes = canvasNodes.filter((node) => node.data?.plotId !== plotIdToArchive);
                setCanvasNodes(filteredNodes);
                setSelectedNode(null);
            } else if (!plotIdForArchiveProp) {
                // Manual archiving of a plot should remove the plot(s) from the canvas (multi-figure panel)
                // Mass archives (if plotIdForArchiveProp exists) should handle this separately
                const filteredNodes = canvasNodes.filter((node) => node.data?.plotId !== plotIdToArchive);
                saveNodes(filteredNodes);
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setArchiveLoading(false);
            setRemovePlotConfirmOpen(false);
            setPlotIdForArchive(null);
            if (plotIdToArchive === selectedPlotId) {
                setSelectedPlotId(null);
            }
        }
    };

    const cancelArchivePlot = () => {
        setRemovePlotConfirmOpen(false);
        setPlotIdForArchive(null);
    };

    const getPlotOverrides = (plotId: string): PlotOverrideSummary => {
        const display_id = plotDisplayOptionOverrides[plotId] ?? undefined;
        const analysis_id = plotAnalysisOverrides[plotId] ?? undefined;
        const isPreview = previewPlots.some((p) => p.uuid === plotId);
        return {
            hasOverrides: isPreview || isNotBlank(display_id) || isNotBlank(analysis_id),
            display_id,
            analysis_id,
        };
    };

    const addPreviewPlot = (plot: Plot) => {
        logger.debug('add/update preview plot', { plot });

        setPreviewPlots((plots) => {
            const index = plots.findIndex((p) => p.uuid === plot.uuid);
            if (index === -1) {
                return [plot, ...plots];
            }
            plots[index] = plot;

            return [...plots];
        });
    };

    const updatePreviewPlot = (plot: Plot) => {
        addPreviewPlot(plot);
    };

    const clearPlotOverrides = (plotId: string) => {
        removePreviewPlot({ uuid: plotId });
    };

    const removePreviewPlot = (plot: Pick<Plot, 'uuid'>) => {
        const plotId = plot.uuid;
        setPreviewPlots((plots) => plots.filter((p) => p.uuid !== plot.uuid));
        setPlotDisplayOptionOverride({ plotId, displayOptionId: null });
        setPlotAnalysisOverride({ plotId, analysisId: null });
    };

    const clearAllOverrides = () => {
        setPlotDisplayOptionOverrides({});
        setPlotAnalysisOverrides({});
        handleChangeSelectedPlot(null);
        setPreviewPlots([]);
    };

    const _allPlots: PlotListItem[] = plotListItems ?? [];

    const sortedPlotItems = useMemo(() => {
        if (!_allPlots) {
            return _allPlots;
        }

        const sortedPlots = [..._allPlots];
        sortedPlots.sort((p1, p2) => {
            const pi1 = plotDisplayOrder.indexOf(p1.uuid);
            const pi2 = plotDisplayOrder.indexOf(p2.uuid);
            return pi1 - pi2;
        });
        return sortedPlots;
    }, [_allPlots, plotDisplayOrder]);

    const setIGVBrowserByPlotId = (plotId: string, browser: IGVBrowser | null | undefined) => {
        return (igvBrowsersRef.current[plotId] = browser ?? null);
    };

    const getIgvBrowserByPlotId = (plotId: string) => igvBrowsersRef.current[plotId] ?? null;

    const experimentModalOnClose = () => {
        if ((currentChanges || nestedCurrentChanges) && !unsavedChangesConfirmOpen) {
            setUnsavedChangesConfirmOpen('closeModal');
            return;
        }
        setExperimentModalOpen(false);
        setTimeout(() => {
            // Allow ExperimentModal to animate close before resetting state
            handleChangeSelectedPlot(null);
            setSelectedPreprocess(null);
            setSelectedWorkflow(null);
            setSelectedAnnotationSet(null);
            setSelectedAnnotation(null);
            setPreprocessReadOnly(false);
            setOpenOnComments(false);
            setOpenOnPlot(false);
            setZoomTransform(null);
            setInputExpanded(null);
            clearHash();
        }, 100);
    };

    const _createNewWorkflow = async (newWorkflowTitle: string) => {
        const newWorkflow: Workflow | undefined = await createWorkflow(newWorkflowTitle);
        if (newWorkflow && !createWorkflowError && !createWorkflowLoading) {
            setConfirmCreateModalOpen(false);
            // Open Create Seurat preprocess modal
            openPreprocessModal(newWorkflow, newWorkflow.preprocesses[1]);
        }
    };
    const _acceptWorkflow = async () => {
        const acceptedWorkflow: AcceptedWorkflow | undefined = await acceptWorkflow();
        if (acceptedWorkflow && !acceptWorkflowError && !acceptWorkflowLoading) {
            setAcceptWorkflowModalOpen(false);
            setCurrentRoadmapTab('annotation');
        }
    };

    const clearAllAnalysesOnExperiment = async () => {
        if (plotListItems.length === 0) return;
        setSelectedNode(null);
        const uuids = plotListItems.map((plotItem) => plotItem.uuid);

        return Promise.all(uuids.map((uuid) => confirmArchivePlot(uuid)))
            .then(() => {
                updatePlotDisplayOrder([]);
                const filteredNodes = canvasNodes.filter((node) => !uuids.includes(node.data?.plotId));
                saveNodes(filteredNodes);
            })
            .catch((error) => {
                logger.log(`Error clearing all analyses for experiment (uuid: ${experiment?.uuid}):`, error);
            });
    };

    return (
        <ExperimentDetailViewContext.Provider
            value={{
                addPreviewPlot,
                analysisFormSubmitDisabled,
                archivePlot,
                clearAllAnalysesOnExperiment,
                clearAllOverrides,
                clearPlotOverrides,
                commentsModalOpen,
                copyOpen,
                currentChanges,
                currentContextMenuPlotId,
                currentPlotPanel,
                currentRoadmapTab,
                disableRemovePlots,
                editDataInterstitialOpen,
                experiment: experiment ?? null,
                experimentCommentsOpen,
                experimentModalOnClose,
                experimentType,
                getIgvBrowserByPlotId,
                getPlotOverrides,
                handleChangeSelectedPlot,
                hasPreviewPlots,
                inputExpanded,
                inputSaveButtonRef,
                insightsAdminPhases,
                nestedCurrentChanges,
                onAnalysisSubmit,
                openAnnotationModal,
                openDataTableModal,
                openPreprocessModal,
                permissions,
                pingSave,
                plotAnalysisOverrides,
                plotDisplayOptionOverrides,
                plotListItemCount,
                plotListItems: sortedPlotItems,
                plotListLoading,
                plotQuery,
                plotToEmbed,
                presentationMode,
                publicationMode,
                refreshExperiment,
                refreshPlotById,
                refreshPlotItems,
                removePreviewPlot,
                scrollToInputSaveButton,
                selectedPlot,
                setAnalysisFormSubmitDisabled,
                setCommentsModalOpen,
                setConfirmCreateModalOpen,
                setCopyOpen,
                setCurrentChanges,
                setCurrentContextMenuPlotId,
                setCurrentPlotPanel,
                setCurrentRoadmapTab,
                setEditDataInterstitialOpen,
                setEvidenceModalOpen,
                setEvidencePlotId,
                setExperimentCommentsOpen,
                setExperimentModalOpen,
                setIGVBrowserByPlotId,
                setInputExpanded,
                setNestedCurrentChanges,
                setOpenOnComments,
                setOpenOnPlot,
                setPingSave,
                setPlotAnalysisOverride,
                setPlotDisplayOptionOverride,
                setPlotQuery,
                setPlotToEmbed,
                setPresentationMode,
                setPublicationMode,
                setSelectedPlot,
                settingsLoading,
                setTmpSelectedPlot,
                setUnsavedChangesConfirmOpen,
                setZoomTransform,
                showAdminPhases,
                tmpSelectedPlot,
                unsavedChangesConfirmOpen,
                updatePlotDisplayOrder,
                updatePreviewPlot,
                zoomTransform,
            }}
        >
            <>
                {children}
                <ConfirmModal
                    open={removePlotConfirmOpen}
                    onConfirm={confirmArchivePlot}
                    onCancel={cancelArchivePlot}
                    title="Remove plot"
                    message="Are you sure you want to remove this plot?"
                    confirmText="Yes, remove plot"
                    cancelText="No, never mind"
                    confirmLoading={archiveLoading}
                />
                {experiment && (
                    <PlotEmbedModal
                        plot={plotToEmbed}
                        experiment={experiment}
                        open={!!plotToEmbed}
                        onClose={() => setPlotToEmbed(null)}
                        publicationMode={publicationMode}
                        overrides={plotToEmbed ? getPlotOverrides(plotToEmbed?.uuid) : null}
                        refreshPlotById={refreshPlotById}
                    />
                )}
                <ExperimentModal
                    experiment={experiment}
                    open={experimentModalOpen}
                    onClose={experimentModalOnClose}
                    openOnComments={openOnComments}
                    openOnPlot={openOnPlot}
                />

                <AcceptPreprocessModal
                    open={confirmAcceptPreprocessModalOpen}
                    onConfirm={() => {
                        _acceptPreprocess();
                        setTimeout(() => {
                            experimentModalOnClose();
                        }, 500);
                    }}
                    onCancel={() => setConfirmAcceptPreprocessModalOpen(false)}
                    confirmLoading={acceptPreprocessLoading}
                />
                <AcceptWorkflowModal
                    open={acceptWorkflowModalOpen}
                    onConfirm={_acceptWorkflow}
                    onCancel={() => setAcceptWorkflowModalOpen(false)}
                    confirmLoading={acceptWorkflowLoading}
                />

                <NewWorkflowModal
                    open={confirmCreateModalOpen}
                    onConfirm={_createNewWorkflow}
                    onCancel={() => setConfirmCreateModalOpen(false)}
                    confirmLoading={createWorkflowLoading}
                    title="Create workflow"
                />
                <AddAsEvidenceDialog
                    open={evidenceModalOpen}
                    onClose={() => setEvidenceModalOpen(false)}
                    plotId={evidencePlotId}
                    experimentId={experiment?.uuid}
                />
            </>
        </ExperimentDetailViewContext.Provider>
    );
};
