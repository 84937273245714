import { useBaseSpaceBrowserContext } from '@contexts/BaseSpaceBrowserContext';
import useSWR from 'swr';
import { BaseSpaceProject } from '@models/baseSpace/BaseSpaceProject';
import Endpoints from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import { Column, useSortBy, useTable } from 'react-table';
import React, { useMemo } from 'react';
import DataTableHeader from '@components/dataTable/DataTableHeader';
import DataTableBody from '@components/dataTable/DataTableBody';
import { humanFileSize } from '@util/StringUtil';
import { IconButton } from '@mui/material';
import { RefreshIcon } from '@heroicons/react/outline';
import cn from 'classnames';

const tableColor = 'bg-secondary text-white';
const ProjectsTable = () => {
    const { experiment, setSelectedProject } = useBaseSpaceBrowserContext();
    const {
        data: projects,
        error: projectsError,
        isValidating,
        mutate,
    } = useSWR<BaseSpaceProject[]>(
        () => experiment?.uuid && Endpoints.lab.experiment.basespace.projects({ experimentId: experiment.uuid }),
    );
    const projectsLoading = !projects && !projectsError;

    const columns: Column<BaseSpaceProject>[] = useMemo(() => {
        return [
            {
                Header: 'Project Name',
                accessor: 'name',
                enableMultiSort: false,
                Cell: (cell) => (
                    <div>
                        <span className="link" onClick={() => setSelectedProject(cell.row.original)}>
                            {cell.value}
                        </span>
                    </div>
                ),
            },
            {
                Header: 'Size',
                accessor: 'size',
                enableMultiSort: false,
                Cell: (cell) => <p className="text-right">{cell.value ? humanFileSize(cell.value) : '--'}</p>,
            },
            {
                Header: 'Updated',
                accessor: 'updated_at',
                enableMultiSort: false,
                Cell: (cell) => <p className="text-right">{new Date(cell.value).toLocaleString()}</p>,
            },
        ];
    }, []);

    const tableData = useMemo<BaseSpaceProject[]>(() => {
        return projects ?? [];
    }, [projects]);

    const tableInstance = useTable(
        {
            columns,
            data: tableData,
            // initialState: initialStateProp,
            // manualSortBy: manualSortBy,
        },
        useSortBy,
    );
    const { getTableProps } = tableInstance;
    const $refresh = !projectsLoading && (
        <IconButton
            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
            onClick={() => mutate()}
            color="primary"
            size="large"
        >
            <RefreshIcon width={18} className={cn({ 'animate-spin': isValidating && !projectsLoading })} />
        </IconButton>
    );

    if (projectsLoading) {
        return <LoadingMessage immediate message="Loading projects" inline />;
    }

    return (
        <div>
            <div className="flex items-end justify-end">{$refresh}</div>
            <div className="overflow-hidden rounded-xl">
                <div className="max-h-[65vh] w-full overflow-auto">
                    <div className="">
                        <table
                            {...getTableProps()}
                            className="rounded-corners relative w-full table-auto border-separate"
                        >
                            <DataTableHeader tableInstance={tableInstance} tableColor={tableColor} />
                            <DataTableBody tableInstance={tableInstance} />
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsTable;
