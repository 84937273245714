import { DifferentialExpressionAnalysisInfo } from './DifferentialExpressionAnalysis';
import { BaseExperimentAnalysis } from './ExperimentAnalysis';

export const PanglaoShortnames = ['panglao_db', 'panglao_dbplus'];

export interface SeuratOverRepresentationAnalysis extends BaseExperimentAnalysis {
    differential_analysis: DifferentialExpressionAnalysisInfo | null;
    gene_set_collections: number[];
    n_genes: number;
    adj_pval_de: number;
}
