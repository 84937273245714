import { createClient } from 'contentful';

// This is the standard Contentful client. It fetches
// content that has been published.
const client = createClient({
    accessToken: 'WgByARQTDhkZCDgZ1-g6Hl6IC7OxJQj_jl682hsxtHg',
    space: 'a8kqajd3asj2',
});

// This is a Contentful client that's been configured
// to fetch drafts and unpublished content.
const previewClient = createClient({
    accessToken: `${process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    space: `${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
    host: 'preview.contentful.com',
});

// This little helper will let us switch between the two
// clients easily:
export default function contentfulClient({ preview = false }) {
    if (preview) {
        console.log('Using the preview client');
        return previewClient;
    }

    return client;
}
