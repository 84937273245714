import { IconProps } from '@components/icons/CustomIcons';

export const DrugIcon = (props?: IconProps) => {
    const { width = 20, height = 18 } = { ...props };
    return (
        <svg width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.281 10.42L14.73 3.917a3.97 3.97 0 00-5.531-.976A3.955 3.955 0 007.757 4.81a3.875 3.875 0 00-3.868-3.698A3.887 3.887 0 000 5.001v7.777c0 2.15 1.74 3.889 3.889 3.889 2.15 0 3.889-1.74 3.889-3.889V7.601c.114.299.253.594.444.868l4.556 6.503a3.961 3.961 0 003.257 1.695 3.97 3.97 0 003.246-6.246zM5.556 8.89H2.222V5a1.667 1.667 0 013.334 0v3.89zm6.763 1.558l-2.277-3.253a1.727 1.727 0 01-.289-1.306c.08-.458.337-.861.72-1.128a1.745 1.745 0 012.434.43l2.277 3.254-2.865 2.003z"
                fill="currentColor"
            />
        </svg>
    );
};
