import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import ClusteringAnalysisMethodsSection from '@components/experiments/methods/analyses/ClusteringAnalysisMethodsSection';
import { AnalysisParameters } from '@models/AnalysisParameters';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { ClusteringAnalysis, getTransformNames } from '@models/analysis/ClusteringAnalysis';
import ProseList from '@components/ProseList';
import ImageHeatmapDisplayOption, { getClusteringNames } from '@models/plotDisplayOption/ImageHeatmapDisplayOption';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { isDefined } from '@util/TypeGuards';

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const ImageHeatmapMethods = ({ plot, experiment, analysisParameters }: Props) => {
    const { getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as ClusteringAnalysis;
    const unitsName =
        experiment?.assay_data_units?.units_display_name ?? experiment?.assay_data_units?.units?.display_name;
    const display = plot.display as ImageHeatmapDisplayOption;
    const transforms = getTransformNames(analysis) ?? [unitsName ?? 'raw'];

    const variableNames =
        display?.color_by_variables?.map((variable) => getVariableById(variable)?.display_name).filter(isDefined) ?? [];

    const clustering = getClusteringNames(display);
    return (
        <>
            <ClusteringAnalysisMethodsSection
                plot={plot}
                experiment={experiment}
                analysisParameters={analysisParameters}
            />
            <section>
                <MethodsSectionTitle>Heatmap</MethodsSectionTitle>
                <span>
                    Heatmap shows <ProseList items={transforms} /> values.{' '}
                </span>
                {variableNames.length > 0 && (
                    <span>
                        Samples are colored by <ProseList items={variableNames} />.{' '}
                    </span>
                )}
                {clustering && (
                    <span>
                        Dendrograms show clustering by Euclidean distance for <ProseList items={clustering} />.
                    </span>
                )}
            </section>
        </>
    );
};

export default ImageHeatmapMethods;
