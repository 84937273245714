import Experiment from '@models/Experiment';
import PipelineRunItem from '@components/experiments/PipelineRunItem';
import useExperimentPipelineRuns from '@hooks/useExperimentPipelineRuns';
import cn from 'classnames';
import { ReactNode } from 'react';
import { isDefined } from '@util/TypeGuards';
import { InformationCircleIcon } from '@heroicons/react/outline';
import LoadingMessage from '@components/LoadingMessage';
import Bold from '@components/elements/Bold';

type Props = {
    experiment: Experiment | null | undefined;
    className?: string;
    listClassName?: string;
    title?: ReactNode;
};

const PipelineRunsListView = ({ experiment, className, listClassName, title = 'Pipeline runs' }: Props) => {
    const pipelineRuns = useExperimentPipelineRuns({ experiment });
    const activeId = experiment?.pipeline_run?.uuid;

    if (pipelineRuns.loading) {
        return <LoadingMessage immediate message="Loading pipeline runs..." />;
    }

    if (pipelineRuns.data && pipelineRuns.data.length > 0) {
        return (
            <div className={className}>
                {isDefined(title) && <h3 className="mb-2 text-lg font-semibold text-dark">{title}</h3>}

                <div className={cn('flex flex-col space-y-2', listClassName)}>
                    {pipelineRuns.data
                        ?.sort((d1, d2) => {
                            return new Date(d2.created_at).getTime() - new Date(d1.created_at).getTime();
                        })
                        .map((pipelineRun, i) => (
                            <PipelineRunItem pipelineRun={pipelineRun} key={i} active={pipelineRun.uuid === activeId} />
                        ))}
                    {(pipelineRuns.data ?? []).length === 0 && !pipelineRuns.loading && (
                        <div className="flex space-x-2 rounded-lg bg-indigo-100 p-4">
                            <div className="mt-1 flex-shrink-0 text-indigo-500">
                                <InformationCircleIcon width={18} />
                            </div>
                            <div>
                                Pipeline parameters are not available for the version of the pipeline that was run for
                                this experiment.{' '}
                                <a
                                    href="https://help.pluto.bio"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    Contact us
                                </a>{' '}
                                to get the latest parameters.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="pb-8 pt-4">
            <p className="text-center">
                <Bold>This experiment has no pipeline runs</Bold>
            </p>
        </div>
    );
};

export default PipelineRunsListView;
