import { symbolCircle, SymbolType as D3SymbolType } from 'd3-shape';
import { PointColor, PointShape } from '@models/PlotConfigs';
import Plot from '@models/Plot';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import { symbolCross, symbolDiamond, symbolSquare, symbolStar, symbolTriangle, symbolWye } from 'd3';

export type MousePosition = {
    mouseX: null | number;
    mouseY: null | number;
};

export const initialMouseState = (): MousePosition => ({
    mouseX: null,
    mouseY: null,
});

export enum DotSymbol {
    circle,
    triangle,
    square,
    cross,
    diamond,
    wye,
    star,
}

export type SymbolConfig = {
    symbol: DotSymbol;
    symbol_color: string;
};
export type TargetID = {
    target_name: string;
};

export type SummaryAnalysisSample = {
    group_id: string;
    sample_id: string;
    target_name: string;
    value: number;
    group_name: string;
};

export type LinePlotDatum = {
    sample_id: string;
    target_name: string;
    target_id: string | number;
    x_value: number;
    y_value: number;
} & (
    | {
          group_id?: null;
          group_name?: null;
      }
    | { group_id: number; group_name: string }
);

export type PlotTargetGroups = {
    target_groups: TargetSeries[];
};

export type TargetSeries = TargetID & {
    groups: GroupSeries[];
};
export type GroupName = {
    group_name: string;
    // group_id: number;
};
export type SampleValue = {
    value: number;
    sample_id: string;
    point_shape_id: PointShape;
    point_color_id: PointColor;
};
export type GroupSeries = GroupName & {
    samples: SampleValue[];
    group_id: number;
};

export type LineConfig<T> = {
    x1: (d: T) => number;
    x2: (d: T) => number;
    y1: (d: T) => number;
    y2: (d: T) => number;
    hidden?: (d: T) => boolean;
};

export type FlatSample = SampleValue & GroupName & TargetID & SymbolConfig & { group_id: number };

export const getD3Symbol = (dotSymbol: DotSymbol): D3SymbolType => {
    switch (dotSymbol) {
        case DotSymbol.diamond:
            return symbolDiamond;
        case DotSymbol.wye:
            return symbolWye;
        case DotSymbol.star:
            return symbolStar;
        case DotSymbol.circle:
            return symbolCircle;
        case DotSymbol.triangle:
            return symbolTriangle;
        case DotSymbol.square:
            return symbolSquare;
        case DotSymbol.cross:
            return symbolCross;
    }
};

export const getTargetSymbol = ({
    sortedTargets,
    target_id,
}: {
    sortedTargets: (string | number)[];
    target_id: string | number;
}) => {
    const index = sortedTargets.indexOf(target_id);

    switch (index) {
        case 0:
            return DotSymbol.circle;
        case 1:
            return DotSymbol.triangle;
        case 2:
            return DotSymbol.star;
        case 3:
            return DotSymbol.square;
        case 4:
            return DotSymbol.wye;
        case 5:
        default:
            return DotSymbol.cross;
    }
};

export const isPlotSampleScatterDisplay = (
    plot: Plot,
): plot is Plot<ExperimentAnalysis, SampleScatterPlotDisplayOption> => {
    return plot.display?.display_type === 'sample_scatter_plot';
};
