import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { ClusteringAnalysis, getTransformNames } from '@models/analysis/ClusteringAnalysis';
import { Log2FoldChangeDirection } from '@models/AnalysisParameters';
import { isDefined } from '@util/TypeGuards';
import ProseList from '@components/ProseList';
import React from 'react';
import Bold from '@components/elements/Bold';
import { formatSmallNumber } from '@util/StringUtil';
import Logger from '@util/Logger';

const logger = Logger.make('ClusteringAnalysisMethodsSection');

function formatNumber(input: number) {
    const formatted = Number.parseFloat(formatSmallNumber({ value: input }));
    logger.debug('[formatNumber] ', { input, formatted });
    return input;
}

function getDirectionName(direction: Log2FoldChangeDirection): string {
    switch (direction) {
        case 'both':
            return 'both positive and negative';
        case 'positive':
            return 'positive only';
        case 'negative':
            return 'negative only';
    }
}

type Props = { plot: Plot; experiment: Experiment; analysisParameters: AnalysisParameters | null };
const ClusteringAnalysisMethodsSection = ({ plot }: Props) => {
    const analysis = plot.analysis as ClusteringAnalysis;
    const comparisonAnalysis = analysis.differential_analysis;
    const hasGroups = (analysis.groups?.length ?? 0) > 0;
    const groupNames = analysis?.groups?.map((g) => g.display_name).filter(isDefined) ?? [];
    const $groups = hasGroups ? <ProseList items={groupNames} /> : <Bold>all samples</Bold>;
    const directionName = getDirectionName(analysis.log2_fc_direction);
    const transforms = getTransformNames(analysis);

    return (
        <section>
            <MethodsSectionTitle>Clustering analysis</MethodsSectionTitle>
            <p>
                <span>
                    Clustering analysis was performed for {$groups} using differentially abundant features in the
                    comparison <Bold>{comparisonAnalysis.name}</Bold>.
                </span>
                <span>
                    {' '}
                    Features were filtered using an adjusted <span className="italic">p</span>-value &le;&nbsp;
                    <Bold>{formatNumber(analysis.adj_pval_threshold)}</Bold> and log<sub>2</sub> fold change threshold
                    of <Bold>{formatNumber(analysis.log2_fc_threshold)}</Bold> (showing features that are{' '}
                    <Bold>{directionName}</Bold>).
                </span>
                {transforms && (
                    <span>
                        {' '}
                        Prior to plotting, the data was <ProseList items={transforms} />.
                    </span>
                )}
            </p>
        </section>
    );
};

export default ClusteringAnalysisMethodsSection;
