import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import PeakAnalysisMethodsSection from '@components/experiments/methods/analyses/PeakAnalysisMethodsSection';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import { isDefined } from '@util/TypeGuards';
import ProseList from '@components/ProseList';
import IGVPlotDisplayOption from '@models/plotDisplayOption/IGVPlotDisplayOption';

type Props = { experiment: Experiment; plot: Plot; analysisParameters: AnalysisParameters | null };
const IGVMethods = ({ plot, analysisParameters }: Props) => {
    const { getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const display = plot.display as IGVPlotDisplayOption;
    const variables = display.color_tracks_by_variable_ids ?? [];
    const variableNames = variables.map((id) => getVariableById(id)?.display_name).filter(isDefined);
    return (
        <div className="space-y-6">
            <PeakAnalysisMethodsSection plot={plot} />
            <section>
                <MethodsSectionTitle title="IGV plot" />
                <p>
                    Peaks were visualized on an Integrative Genome Viewer (IGV)<sup>1</sup> plot using the igv.js
                    library<sup>2</sup>. Each sample is shown as one track, and tracks are colored by{' '}
                    <ProseList items={variableNames} />.
                </p>
            </section>
        </div>
    );
};

export default IGVMethods;
