import { useState } from 'react';
import GeneSet, { GeneSetField } from '@models/GeneSet';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import { TabularData } from '@models/ExperimentData';
import useMatchMutate from '@hooks/useMatchMutate';

type Props = {
    analysisId: string | undefined | null;
    experimentId: string;
    limit?: number;
    sortBy?: GeneSetField;
    sortByDesc?: boolean;
    search?: string | undefined;
    includeGeneSetShortname?: string;
};
const useAnalysisGeneSets = ({
    analysisId,
    experimentId,
    sortBy: initialSortBy = 'Adj_P_Value',
    sortByDesc: initialSortByDesc = false,
    limit: initialLimit = 100,
    search: initialSearch,
    includeGeneSetShortname,
}: Props) => {
    const [limit, setLimit] = useState<number | undefined>(initialLimit);
    const [sortBy, setSortBy] = useState<GeneSetField>(initialSortBy);
    const [sortByDesc, setSortByDesc] = useState(initialSortByDesc);
    const [search, setSearch] = useState<string | undefined>(initialSearch);
    const { startsWithMutate } = useMatchMutate();
    const sort_by: GeneSetField = sortByDesc ? `-${sortBy}` : sortBy;

    const { data, error } = useSWR<Omit<TabularData<GeneSet>, 'count'>>(() =>
        !analysisId
            ? null
            : Endpoints.lab.experiment.analysis.summary(
                  {
                      experimentId,
                      analysisId,
                  },
                  { limit, search, sort_by, include: includeGeneSetShortname },
              ),
    );
    const loading = !data && !error;

    const appendValueIfNotPresent = (item: GeneSet) => {
        if (!analysisId) {
            return;
        }
        const path = Endpoints.lab.experiment.analysis.summary({
            experimentId,
            analysisId,
        });
        startsWithMutate<TabularData<GeneSet>>(path, (data) => {
            if (!data) return data;
            const items = data.items;
            const found = items.find((geneSet) => geneSet.Gene_Set_Shortname === item.Gene_Set_Shortname);
            if (found) return data;

            items.unshift(item);
            data.items = items;
            return data;
        });
    };

    return {
        setLimit,
        setSortBy,
        error,
        data,
        loading,
        setSearch,
        search,
        limit,
        sortBy,
        tabularData: data,
        setSortByDesc,
        sortByDesc,
        appendValueIfNotPresent,
    };
};

export default useAnalysisGeneSets;
