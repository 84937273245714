import { EnrichmentColorValue, EnrichmentData, EnrichmentItem } from '@models/ExperimentData';
import { isDefined, RequiredNonNullable } from '@util/TypeGuards';

const enrichmentColorValueMap: Record<EnrichmentColorValue, string> = {
    [1]: '#2062A6',
    [2]: 'rgb(55,122,183)',
    [3]: 'rgb(105,168,210)',
    [4]: 'rgb(237,243,255)',
    [5]: 'rgb(253,226,213)',
    [6]: 'rgb(250,166,139)',
    [7]: 'rgb(248,97,74)',
    [8]: '#E3423C',
};
export const heatmapColors = Object.values(enrichmentColorValueMap);

export const getEnrichmentColorValue = (colorValue: EnrichmentColorValue): string => {
    return enrichmentColorValueMap[colorValue];
};

type AxisStats = {
    max: number;
    min: number;
};

export type NonNullableEnrichmentItem = RequiredNonNullable<EnrichmentItem>;
export const isNonNullableEnrichmentItem = (item: EnrichmentItem): item is NonNullableEnrichmentItem => {
    return (
        isDefined(item.Running_ES) && isDefined(item.Rank) && isDefined(item.Color_Value) && isDefined(item.Gene_Tick)
    );
};

export type DataPoint = { x: number; y: number } & EnrichmentItem;
export type ColorDataPoint = { x: number; colorValue: EnrichmentColorValue };

const yValue = (d: NonNullableEnrichmentItem): number => {
    return d.Running_ES;
};
const xValue = (d: EnrichmentItem): number => {
    return d.Rank;
};

export const prepareEnrichmentPlotData = (
    data: EnrichmentData,
): {
    xStats: AxisStats;
    yStats: AxisStats;
    lineItems: DataPoint[];
    colorItems: ColorDataPoint[];
} | null => {
    const allItemsSorted = data.items.sort((d1, d2) => {
        return xValue(d1) - xValue(d2);
    });

    const nonNullableItems: NonNullableEnrichmentItem[] = allItemsSorted.filter(isNonNullableEnrichmentItem);
    const [first] = nonNullableItems;
    if (!first) {
        return null;
    }
    // initialize values. Will iterate through all items to update stats
    const xStats: AxisStats = { max: xValue(first), min: xValue(first) };
    const yStats: AxisStats = {
        max: yValue(first),
        min: yValue(first),
    };

    const lineItems = nonNullableItems.map((d) => {
        const point = { ...d, x: xValue(d), y: yValue(d) };
        if (point.x > xStats.max) {
            xStats.max = point.x;
        }
        if (point.x < xStats.min) {
            xStats.min = point.x;
        }

        if (point.y > yStats.max) {
            yStats.max = point.y;
        }
        if (point.y < yStats.min) {
            yStats.min = point.y;
        }
        return point;
    });

    // Should be sorted already
    // lineItems.sort((d1, d2) => {
    //     return d1.x - d2.x;
    // });
    const colorItemData: ColorDataPoint[] = allItemsSorted
        .filter((item) => !isDefined(item.Running_ES))
        .map((item) => ({
            x: xValue(item),
            y: item.Running_ES,
            colorValue: item.Color_Value,
        }));
    const colorValueMap = colorItemData.reduce<Partial<Record<EnrichmentColorValue, ColorDataPoint>>>(
        (colorMap, item) => {
            const existing = colorMap[item.colorValue];
            if (existing && existing.x > item.x) {
                return colorMap;
            }
            colorMap[item.colorValue] = item;
            return colorMap;
        },
        {},
    );

    const colorItems = Object.values(colorValueMap).sort((d1, d2) => d1.x - d2.x);

    return { lineItems, xStats: xStats, yStats: yStats, colorItems };
};
