import { CellProps } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Tooltip } from '@mui/material';
import { CheckCircleIcon } from '@components/icons/custom/CheckCircleIcon';
import { isDefined } from '@util/TypeGuards';
import { isBlank } from '@util/StringUtil';
import React from 'react';
import { truncateText } from '@/src/util/textUtil';

const MAX_VALUE_LENGTH = 60;

type Props = CellProps<GenericCellData>;
const SampleDataFastqFileCell = ({ value, column, row }: Props) => {
    const isR1 = column.id?.toLowerCase() === 'r1_fastq';
    const isR2 = column.id?.toLowerCase() === 'r2_fastq';

    const isFastqColumn = isR1 || isR2;
    let missing: boolean | undefined;
    if (isR1) {
        missing = row.original['missing_r1'] as boolean | undefined;
    } else if (isR2) {
        missing = row.original['missing_r2'] as boolean | undefined;
    }

    const showErrorTooltip = isFastqColumn && missing && isDefined(missing) && !value.includes(',');
    const showSuccessTooltip = isFastqColumn && isDefined(missing) && !missing && !value.includes(',');
    const showMergedTooltip = value.includes(',');

    return (
        <div className="flex flex-shrink-0 items-center space-x-2">
            {showErrorTooltip && (
                <Tooltip
                    title="Value must match an uploaded FASTQ file name in order to run pipeline"
                    arrow
                    placement="top-start"
                >
                    <span className="relative top-0.5 w-4 text-error">
                        <ExclamationIcon width={18} />
                    </span>
                </Tooltip>
            )}
            {showSuccessTooltip && (
                <Tooltip title="Value matches the name of an uploaded FASTQ file" arrow placement="top-start">
                    <span className="relative top-0.5 w-4 text-success">
                        <CheckCircleIcon width={14} />
                    </span>
                </Tooltip>
            )}
            {showMergedTooltip && (
                <Tooltip title="FASTQ files were merged by the pipeline" arrow placement="top-start">
                    <span className="relative top-0.5 w-4 text-success">
                        <CheckCircleIcon width={14} />
                    </span>
                </Tooltip>
            )}
            {isBlank(value) || !isDefined(value) ? (
                <i className="text-sm text-gray-300">File name missing</i>
            ) : (
                <span className="whitespace-nowrap" title={value.length > MAX_VALUE_LENGTH ? value : undefined}>
                    {truncateText(value, MAX_VALUE_LENGTH)}
                </span>
            )}
        </div>
    );
};

export default SampleDataFastqFileCell;
