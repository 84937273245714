import React, { useEffect } from 'react';
import { ScrollableSidebarContainer } from '@components/experiments/ScrollableSidebarContent';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import ConfirmModal from '@components/ConfirmModal';
import AnnotationForm from './AnnotationForm';
import { useExperimentAnnotationContext } from '@/src/contexts/ExperimentAnnotationContext';
import Experiment from '@models/Experiment';

export type Props = {
    className?: string;
    openAISidebar: (arg: number) => void;
    experiment: Experiment;
};

const EditAnnotationForm = ({ className, openAISidebar, experiment }: Props) => {
    const { setCurrentChanges, unsavedChangesConfirmOpen, setUnsavedChangesConfirmOpen, setExperimentModalOpen } =
        useExperimentDetailViewContext();
    const { setSelectedAnnotationSet } = useExperimentAnnotationContext();

    useEffect(() => () => setCurrentChanges(null), []);

    return (
        <ScrollableSidebarContainer data-cy="edit-annotation-sidebar" className={className}>
            <PlutoErrorBoundary>
                <AnnotationForm openAISidebar={openAISidebar} experiment={experiment} />
            </PlutoErrorBoundary>
            <ConfirmModal
                open={!!unsavedChangesConfirmOpen}
                onConfirm={() => {
                    switch (unsavedChangesConfirmOpen) {
                        case 'closeModal':
                            setExperimentModalOpen?.(false);
                            setSelectedAnnotationSet(null);
                        default:
                            break;
                    }
                    setUnsavedChangesConfirmOpen('');
                    setTimeout(() => {
                        setCurrentChanges(null);
                    }, 300);
                }}
                onCancel={() => setUnsavedChangesConfirmOpen('')}
                title="Unsaved Changes"
                message={
                    <>
                        <p>You have unsaved changes.</p>
                        <p>Are you sure you want to close this modal?</p>
                    </>
                }
                confirmText="Yes, discard my changes"
                cancelText="No"
            />
        </ScrollableSidebarContainer>
    );
};

export default EditAnnotationForm;
