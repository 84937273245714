import { ReactNode, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import Logger from '@util/Logger';

const logger = Logger.make('useFileHandler');

export const formatErrorMessage = (message: string) => {
    if (message.includes('File type must be')) return `File type is not valid`;
    return message;
};

type Props = { onFilesChanged?: (files: File[]) => void };
const useFileHandler = ({ onFilesChanged }: Props) => {
    const [files, setFiles] = useState<File[] | null>(null);
    const [fileErrors, setFileErrors] = useState<{ fileName: string; node: ReactNode }[] | null>(null);
    const hasFiles = (files?.length ?? 0) > 0;

    const onDrop = async (accepted: File[], rejected: FileRejection[]) => {
        logger.info({ files: { accepted, rejected } });
        setFiles((current) => {
            const newFiles = accepted?.filter((f) => !(current ?? []).some((c) => c.name === f.name));
            let filteredCurrent = (current ?? []).filter((c) => !(fileErrors ?? []).some((f) => f.fileName === c.name));
            if (newFiles.length !== accepted.length) {
                logger.warn('Duplicate file was selected, ignoring it');
                filteredCurrent = current ?? [];
            } else {
                setFileErrors((prev) => {
                    const filterErrors = (prev ?? []).filter((c) => !(files ?? []).some((f) => f.name === c.fileName));
                    return filterErrors.length > 0 ? filterErrors : null;
                });
            }
            return [...newFiles, ...filteredCurrent];
        });
        onFilesChanged?.([...accepted, ...(files ?? [])]);

        if ((rejected ?? []).length > 0) {
            setFileErrors(
                rejected.map((r) => ({
                    fileName: r.file.name,
                    node: (
                        <div key={r.file.name}>
                            <p className="font-semibold">{r.file.name}</p>
                            {r.errors.map((e) => (
                                <p key={e.code}>{formatErrorMessage(e.message)}</p>
                            ))}
                        </div>
                    ),
                })),
            );
        }
    };

    const removeFile = (file: File) => {
        setFiles((current) => {
            if (!current) {
                return current;
            }
            return current.filter((f) => f != file);
        });

        const updatedFiles = (files ?? []).filter((f) => f != file);
        onFilesChanged?.(updatedFiles);
    };

    const clearErrors = () => setFileErrors(null);

    return { files, fileErrors, hasFiles, onDrop, removeFile, clearErrors, setFileErrors, setFiles };
};

export default useFileHandler;
