import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { FormikFieldError } from '@components/forms/FieldError';
import cn from 'classnames';

type Props = {
    checkboxClassName?: string;
    className?: string;
    disabled?: boolean;
    label: ReactNode;
    name: string;
    offsetCheckbox?: boolean;
};
const CheckboxField = ({ checkboxClassName, className, disabled, label, name, offsetCheckbox = true }: Props) => {
    const [inputProps] = useField<boolean>(name);
    return (
        <div>
            <label className={cn('block pt-1', className)}>
                <input
                    type="checkbox"
                    className={cn('rounded text-indigo-500 hover:cursor-pointer', checkboxClassName, {
                        '-mt-0.5': offsetCheckbox,
                    })}
                    name={name}
                    checked={inputProps.value}
                    onChange={inputProps.onChange}
                    disabled={disabled}
                />
                <div className="ml-2 inline-block">{label}</div>
            </label>
            <FormikFieldError name={name} />
        </div>
    );
};

export default CheckboxField;
