import { Dialog, DialogContent, Tooltip } from '@mui/material';
import Button from '@components/Button';
import React, { useRef } from 'react';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import Plot from '@models/Plot';
import CopyTextInput from '@components/forms/CopyTextInput';
import Experiment from '@models/Experiment';
import { MethodsPlotContent } from '@components/experiments/methods/MethodsPlotContent';
import MethodsReferences from '@components/experiments/methods/MethodsReferences';
import copy from '@copy/Copy';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import DialogCloseButton from '@components/DialogCloseButton';
import Copy from '@copy/Copy';
import { getCategoryForAnalysis } from '@models/analysis/AnalysisType';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

export type Props = {
    open: boolean;
    onClose: () => void;
    plot: Plot;
    experiment: Experiment;
    overrideAnalysisId?: string | null;
    publicKey?: string | null;
};
const MethodsModal = ({ open = false, onClose, plot, overrideAnalysisId, experiment }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref, successTimeoutDuration: 5000 });
    const analysisId = overrideAnalysisId ?? plot.analysis?.uuid;

    const showPlutoAttribution =
        plot.analysis?.category?.shortname !== 'content' && getCategoryForAnalysis(plot.analysis_type) !== 'content';

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogCloseButton onClose={() => onClose()} />

            <PlutoDialogTitle title="Methods" />

            <DialogContent>
                <div ref={ref} className="mb-8 space-y-4">
                    <PlutoErrorBoundary>
                        <MethodsPlotContent plot={plot} experiment={experiment} />
                    </PlutoErrorBoundary>
                    {showPlutoAttribution && <p>{Copy.analysisCitation}</p>}
                    <MethodsReferences plot={plot} experiment={experiment} className="mb-8" />
                </div>
                <div className="mb-8">
                    <Button
                        startIcon={<CopyIcon height={16} width={16} />}
                        color="primary"
                        variant="contained"
                        onClick={() => copyText()}
                    >
                        {showCopySuccess ? 'Copied!' : 'Copy'}
                    </Button>
                </div>

                <div className="space-y-2">
                    <div className="space-y-1">
                        <p className="flex items-center space-x-2 font-semibold text-gray-900">
                            <span>Experiment ID</span>
                            <Tooltip
                                title={'Fetch results from this experiment using the Pluto API. Tap to learn more.'}
                                arrow
                            >
                                <a href={copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                    <HelpCircleIcon height={16} width={16} />
                                </a>
                            </Tooltip>
                        </p>
                    </div>
                    <CopyTextInput
                        value={experiment.pluto_id}
                        isPrivate={false}
                        inputClasses="!text-xs text-default opacity-60"
                    />
                </div>

                <div className="space-y-2">
                    <div className="space-y-1">
                        <p className="flex items-center space-x-2 font-semibold text-gray-900">
                            <span>Plot ID</span>
                            <Tooltip
                                title={'Fetch results from this plot using the Pluto API. Tap to learn more.'}
                                arrow
                            >
                                <a href={copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                    <HelpCircleIcon height={16} width={16} />
                                </a>
                            </Tooltip>
                        </p>
                    </div>
                    <CopyTextInput
                        value={plot.uuid}
                        isPrivate={false}
                        inputClasses="!text-xs text-default opacity-60"
                    />
                </div>

                {analysisId && (
                    <div className="space-y-2">
                        <div className="space-y-1">
                            <p className="flex items-center space-x-2 font-semibold text-gray-900">
                                <span>Analysis ID</span>
                                <Tooltip
                                    title={'Fetch results from this analysis using the Pluto API. Tap to learn more.'}
                                    arrow
                                >
                                    <a href={copy.apiTokenHelpUrl} target="_blank" rel="noreferrer nofollow">
                                        <HelpCircleIcon height={16} width={16} />
                                    </a>
                                </Tooltip>
                            </p>
                        </div>
                        <CopyTextInput
                            value={analysisId}
                            isPrivate={false}
                            inputClasses="!text-xs text-default opacity-60"
                        />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
export default MethodsModal;
