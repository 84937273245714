const italicMatcher = new RegExp('^((\\(|\\[|_)+)?(t|p)-');

const Word = ({ word }: { word: string }) => {
    const specialMatch = word.match(italicMatcher);
    if (specialMatch && (specialMatch?.index ?? -1) >= 0) {
        const length = specialMatch[0].length;
        return (
            <>
                {word.substring(0, length - 2)}
                <span className="italic">{specialMatch[0]?.substring(length - 2, length - 1)}</span>-
                {word.substring(length)}
            </>
        );
    }

    return <>{word}</>;
};

type Props = { name: string | null | undefined };

/**
 * Format special statistics-related words, such as `t-` and `p-` prefixes, like in `p-value`.
 * @param {string | null | undefined} name
 * @return {JSX.Element | null}
 * @constructor
 */
const StatisticalSymbolLabel = ({ name }: Props) => {
    if (!name) {
        return null;
    }

    const words = name.split(' ');

    return (
        <span>
            {words.map((word, i) => (
                <span key={`${name}_${i}`}>
                    <Word word={word} />
                    {i !== words.length - 1 ? ' ' : ''}
                </span>
            ))}
        </span>
    );
};

export default StatisticalSymbolLabel;
