import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { RefineSeuratObjectFormValues } from '../PreprocessFormTypes';
import SelectableItem from '@components/forms/SelectableItem';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import Picker from '@components/forms/Picker';
import { PreprocessParameters } from '@models/Workflow';
import { sortStringNumbers } from '@/src/util/StringUtil';

type Props = {
    readOnly: boolean;
};
const RefineSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue } = useFormikContext<RefineSeuratObjectFormValues>();
    const { preprocessParameters, selectedWorkflow, getWorkflowPreprocessParameters } = useExperimentWorkflowContext();
    const params = preprocessParameters?.find((param) => param.workflowId === selectedWorkflow?.uuid);
    const resolution_clusters = (params?.preprocessParameters as PreprocessParameters)?.resolution_clusters ?? [];
    const targetResolution = (+values.target_resolution).toFixed(1);
    const clusters = resolution_clusters[targetResolution] ?? [];
    const resolutions = sortStringNumbers(Object.keys(resolution_clusters)) ?? [];

    useEffect(() => {
        if (!(Object.keys(resolution_clusters).length > 0)) {
            getWorkflowPreprocessParameters(selectedWorkflow?.uuid ?? '', true);
        }
    }, [resolution_clusters]);

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Refinement Strategy</p>
                <div className="space-y-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="refine_method"
                            checked={values.refine_method === 'remove_clusters'}
                            onChange={() => {
                                setFieldValue('refine_method', 'remove_clusters');
                            }}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Remove cluster(s) from data set</span>
                    </label>
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="refine_method"
                            onChange={() => {
                                setFieldValue('refine_method', 'skip');
                                setFieldValue('target_resolution', '1.0');
                            }}
                            checked={values.refine_method === 'skip'}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Skip, no further refinement needed</span>
                    </label>
                </div>
            </section>
            {values.refine_method !== 'skip' && (
                <>
                    <section>
                        <p className="field-label">Cluster resolution</p>
                        <div className="flex flex-row flex-wrap">
                            {resolutions.length > 0 ? (
                                resolutions.map((res, i) => (
                                    <SelectableItem
                                        key={`type_${i}`}
                                        selected={targetResolution === res}
                                        onSelect={() => setFieldValue('target_resolution', res)}
                                        disabled={readOnly}
                                        className="mb-2 mr-2 flex flex-col items-center justify-center"
                                    >
                                        <span>{res}</span>
                                    </SelectableItem>
                                ))
                            ) : (
                                <p>No resolutions found</p>
                            )}
                        </div>
                    </section>
                    <section>
                        <p className="field-label !mb-0">Cluster(s) to remove</p>
                        <Picker
                            disabled={readOnly}
                            name="target_clusters"
                            noOptionsText="No clusters found"
                            options={clusters}
                            placeholder="Select one or more clusters..."
                            itemName="cluster"
                            sortOptions={false}
                        />
                    </section>
                </>
            )}
        </div>
    );
};

export default RefineSeuratObjectFormFields;
