import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const GeneSetEnrichmentAnalysisIcon = ({
    width = 32,
    height = 32,
    className = defaultTextLightClass,
    strokeWidth = 2,
    style,
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            style={style}
            className={className || defaultTextLightClass}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="22" height="22" rx="2" stroke="currentColor" strokeWidth={strokeWidth} />
            <path
                d="M3.94739 12.9474H20.0527L15.579 7.57895L14.6842 10.2632L8.42107 4L7.52634 9.36842L4.84212 7.57895L3.94739 12.9474Z"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinejoin="round"
            />
            <line
                x1="4.94739"
                y1="16.6309"
                x2="4.94739"
                y2="19.9993"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="9.42102"
                y1="16.6309"
                x2="9.42102"
                y2="19.9993"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="13"
                y1="16.6309"
                x2="13"
                y2="19.9993"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
            <line
                x1="19.2632"
                y1="16.6309"
                x2="19.2632"
                y2="19.9993"
                stroke="currentColor"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
};
