import AttachmentItemView from '@components/experiments/AttachmentItemView';
import { ComponentProps } from 'react';
import cn from 'classnames';
import { CrudPermissions } from '@models/Permission';
import PlutoFile from '@models/PlutoFile';
import Experiment from '@models/Experiment';

type Props = ComponentProps<'div'> & { items: PlutoFile[]; experiment: Experiment } & CrudPermissions;
const AttachmentItemListView = ({
    items,
    className,
    canDownload,
    canEdit,
    canArchive,
    canView,
    canCreate,
    experiment,
    ...props
}: Props) => {
    return (
        <div className={cn('sm:col-span-2', className)} {...props}>
            <dd className="mt-1 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {items.map((item) => (
                        <AttachmentItemView
                            key={`attachment_${item.uuid}`}
                            item={item}
                            canDownload={canDownload}
                            canArchive={canArchive}
                            canEdit={canEdit}
                            canView={canView}
                            canCreate={canCreate}
                            experiment={experiment}
                        />
                    ))}
                </ul>
            </dd>
        </div>
    );
};
export default AttachmentItemListView;
