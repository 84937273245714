import { DimensionalityReductionAnalysisPlotData } from '@models/ExperimentData';
import { isDefined } from '@util/TypeGuards';
import PlotNoDataView from '@components/plots/PlotNoDataView';
import SampleScatterPlotView from '@components/analysisCategories/dimensionalityReduction/plots/SampleScatterPlotView';
import React from 'react';
import { usePlotContext } from '@contexts/PlotContext';
import PlotLoadingView from '@components/plots/PlotLoadingView';
import { isPlotSampleScatterDisplay } from '@components/plots/PlotTypes';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const DimensionalityReductionPlotDisplayView = ({ customPlotStylingOptions }: Props) => {
    const { experiment, plot, plotData } = usePlotContext();
    const data = plotData as DimensionalityReductionAnalysisPlotData;
    if (!data) {
        return null;
    }
    if (data.pipeline_status === 'in_progress') {
        return <PlotLoadingView />;
    }

    if (isDefined(data.items) && data.items.length === 0) {
        return <PlotNoDataView />;
    }

    switch (plot.display.display_type) {
        case 'sample_scatter_plot':
            if (isPlotSampleScatterDisplay(plot)) {
                return (
                    <SampleScatterPlotView
                        plot={plot}
                        experiment={experiment}
                        customPlotStylingOptions={customPlotStylingOptions}
                    />
                );
            }
            return null;
        default:
            return null;
    }
};

export default DimensionalityReductionPlotDisplayView;
