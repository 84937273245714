import { ButtonGroupItem } from '@components/ButtonGroup';
import { ExperimentTypeShortname, isSimpleExperimentType } from '@models/ExperimentType';
import { createContext } from 'react';
import Experiment from '@models/Experiment';

export type DataFileType = 'csv' | 'fastq' | 'public';

export const fileTypeItems: ButtonGroupItem<DataFileType>[] = [
    { value: 'fastq', label: 'FASTQ' },
    { value: 'csv', label: 'CSV' },
    { value: 'public', label: 'Other' },
];

export enum WizardStep {
    SHELL,
    DATA_UPLOAD,
    SAMPLE_UPLOAD,
    PIPELINE_REVIEW,
    COMPLETE,
}

export enum WizardStepQueryParam {
    SHELL = 'SHELL',
    DATA_UPLOAD = 'DATA_UPLOAD',
    SAMPLE_UPLOAD = 'SAMPLE_UPLOAD',
    PIPELINE_REVIEW = 'PIPELINE_REVIEW',
    COMPLETE = 'COMPLETE',
}

export const defaultSteps = [WizardStep.SHELL, WizardStep.DATA_UPLOAD, WizardStep.SAMPLE_UPLOAD];
export const pipelineReviewSteps = [
    WizardStep.SHELL,
    WizardStep.DATA_UPLOAD,
    WizardStep.SAMPLE_UPLOAD,
    WizardStep.PIPELINE_REVIEW,
];

export const getWizardStepBySlug = (slug?: string | null): WizardStep | null => {
    if (!slug) {
        return null;
    }
    const key = Object.keys(WizardStep).find((key) => key === slug.trim().toUpperCase());
    if (!key) {
        return null;
    }
    return WizardStep[key];
};

export const getWizardStepQueryParam = (step: WizardStep): string | undefined => {
    switch (step) {
        case WizardStep.SHELL:
            return WizardStepQueryParam.SHELL;
        case WizardStep.DATA_UPLOAD:
            return WizardStepQueryParam.DATA_UPLOAD;
        case WizardStep.SAMPLE_UPLOAD:
            return WizardStepQueryParam.SAMPLE_UPLOAD;
        case WizardStep.PIPELINE_REVIEW:
            return WizardStepQueryParam.PIPELINE_REVIEW;
        case WizardStep.COMPLETE:
            return WizardStepQueryParam.COMPLETE;
        default:
            return undefined;
    }
};

export const getInitialStep = (experiment: Experiment | null | undefined): WizardStep | null => {
    if (!experiment) {
        return null;
    }
    const { assay_data_uploaded, fastqs_uploaded, fastqs_queued } = experiment;
    const assayCompleted = assay_data_uploaded || fastqs_uploaded || fastqs_queued;
    if (!assayCompleted) {
        return WizardStep.DATA_UPLOAD;
    }
    return WizardStep.SAMPLE_UPLOAD;
};

export type WizardContextValues = { dataFileType: DataFileType };
export type WizardContextSetters = { updateContext: (updates: Partial<WizardContextValues>) => void };
export const WizardContext = createContext<WizardContextValues & WizardContextSetters>({
    dataFileType: 'csv',
    updateContext: () => null,
});

export function getStepPageInfo({
    isCreate,
    step,
    typeShortname,
    typeDisplayName,
}: {
    isCreate: boolean;
    step: WizardStep;
    typeShortname?: ExperimentTypeShortname;
    typeDisplayName?: string;
}): { title: string; description: string } {
    const isOtherType = isSimpleExperimentType(typeShortname);
    switch (step) {
        case WizardStep.SHELL:
            return {
                title: isCreate ? 'Create your experiment' : 'Edit your experiment',
                description: "Let's gather some basic information about your experiment",
            };
        case WizardStep.DATA_UPLOAD:
            return {
                title: `Upload your ${isOtherType ? 'assay' : typeDisplayName} data`,
                description: 'Upload data for your experiment in one of the available formats',
            };
        case WizardStep.SAMPLE_UPLOAD:
            return { title: 'Annotate your samples', description: 'Now, upload the annotations for each sample' };
        case WizardStep.COMPLETE:
            return { title: 'Complete', description: "You're ready to set up your plots" };
        case WizardStep.PIPELINE_REVIEW:
            return {
                title: `Run the ${typeDisplayName} pipeline`,
                description: 'Last step! Finalize the remaining set-up details needed for FASTQ processing and QC ',
            };
        default:
            return { title: '', description: '' };
    }
}

/**
 * The title to show in the experiment wizard stepper
 * @param {WizardStep} step
 * @param {ExperimentTypeShortname} type
 * @param {boolean} isCreate
 * @return {string}
 */
export function getStepStepperInfo({
    step,
    type,
    dataFileType,
    experiment,
}: {
    step: WizardStep;
    type?: ExperimentTypeShortname | null;
    isCreate?: boolean;
    dataFileType?: DataFileType;
    experiment?: Experiment | null;
}): { title: string; description: string } {
    const csvOnly = isSimpleExperimentType(type);
    switch (step) {
        case WizardStep.SHELL:
            return {
                title: `Create your experiment`,
                description: `Basic information to capture your experiment`,
            };
        case WizardStep.DATA_UPLOAD:
            if (csvOnly) {
                return {
                    title: 'Add your assay data',
                    description: 'Upload a CSV file containing your assay data',
                };
            }

            if (experiment?.fastqs_queued && !experiment.assay_data_uploaded) {
                return {
                    title: 'Assay data is importing',
                    description: 'Your FASTQ files are currently being imported',
                };
            }

            switch (dataFileType) {
                case 'csv':
                    return {
                        title: 'Add your assay data',
                        description:
                            ExperimentTypeShortname.rnaseq === type
                                ? `Upload a CSV containing your RNA-seq data`
                                : 'Upload your data files for processing',
                    };
                case 'fastq':
                    return {
                        title: 'Add your assay data',
                        description: 'Upload your FASTQ files for processing',
                    };
                case 'public':
                    return {
                        title: 'Add your assay data',
                        description: 'Submit a request to pull in data from a public repository',
                    };
                default:
                    return { title: 'Add your assay data', description: 'Upload your data files for processing' };
            }
        case WizardStep.SAMPLE_UPLOAD:
            return { title: 'Annotate your samples', description: 'Now, upload the annotations for each sample' };
        case WizardStep.PIPELINE_REVIEW:
            return {
                title: 'Configure pipeline',
                description: 'Lastly, confirm the settings for FASTQ file processing and QC',
            };
        default:
            return { title: '', description: '' };
    }
}
