import { usePlotContext } from '@contexts/PlotContext';
import ImagePlotView from '@components/analysisCategories/content/plots/ImagePlotView';
import SpreadsheetPlotView from '@components/analysisCategories/content/plots/SpreadsheetPlotView';
import PrismGraphsetView from '@/src/components/analysisCategories/content/plots/PrismGraphsetView';
import React from 'react';
import PlotNotSupportedView from '@components/analysisCategories/PlotNotSupportedView';
import TextPlotView from '@components/analysisCategories/content/plots/TextPlotView';

const ContentPlotDisplayView = () => {
    const { plot, experiment, setDragEnabled } = usePlotContext();
    switch (plot.display.display_type) {
        case 'image':
            return <ImagePlotView plot={plot} experiment={experiment} setPlotDragEnabled={setDragEnabled} />;
        case 'spreadsheet':
            return <SpreadsheetPlotView plot={plot} />;
        case 'prism_graphset':
            return <PrismGraphsetView plot={plot} experiment={experiment} setPlotDragEnabled={setDragEnabled} />;
        case 'text':
            return <TextPlotView />;
        default:
            return <PlotNotSupportedView />;
    }
};

export default ContentPlotDisplayView;
