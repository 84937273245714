import { ReactNode } from 'react';
import cn from 'classnames';

type Props = { children: ReactNode; className?: string };
/** Small utility function to add 'font-semibold' to and element */
const Bold = ({ children, className }: Props) => {
    return <span className={cn('font-semibold', className)}>{children}</span>;
};

export default Bold;
