import React from 'react';
import FieldError from '@components/forms/FieldError';
import useSWR from 'swr';
import { Program } from '@models/Program';
import Endpoints from '@services/Endpoints';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { FormControl, MenuItem, Select } from '@mui/material';
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';

type Props = {
    onChange?: SelectInputProps['onChange'];
    value?: string | null;
    label?: string;
    name: string;
    error?: string | null | boolean;
    noneValue?: string;
    showNone?: boolean;
    disabled?: boolean;
};

const ProgramSelect: React.FunctionComponent<Props> = ({
    onChange,
    value,
    label,
    name,
    error,
    noneValue = 'none',
    showNone,
    disabled,
}: Props) => {
    const { data: programs, error: apiError } = useSWR<Program[]>(Endpoints.lab.programs());
    const loading = !programs && !apiError;

    return (
        <label className="form-field">
            <span className="field-label">{label ?? 'Program'}</span>

            <FormControl variant="outlined" fullWidth error={!!error} disabled={disabled}>
                <Select
                    IconComponent={KeyboardHideIcon}
                    margin="dense"
                    name={name}
                    value={loading ? 'loading' : value}
                    onChange={onChange}
                    renderValue={(id: string) => programs?.find((s) => s.uuid === id)?.name ?? 'None'}
                >
                    {loading && (
                        <MenuItem disabled value="loading">
                            Loading...
                        </MenuItem>
                    )}
                    {!loading && showNone && <MenuItem value={noneValue}>None</MenuItem>}
                    {!loading &&
                        programs?.map((fundingSource) => (
                            <MenuItem key={fundingSource.uuid} value={fundingSource.uuid}>
                                <div>
                                    <span className="block ">{fundingSource.name}</span>
                                    {fundingSource.agency_name && (
                                        <span className="block text-sm text-gray-400">{fundingSource.agency_name}</span>
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                </Select>
                {error && <FieldError>{error}</FieldError>}
            </FormControl>
        </label>
    );
};

export default ProgramSelect;
