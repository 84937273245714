import TextInput from '@components/forms/TextInput';
import React from 'react';
import { useField } from 'formik';

type Props = { name?: string; placeholder?: string | null };
const MaxGeneSetsField = ({ name = 'max_gene_sets', placeholder }: Props) => {
    const [{ onChange }, { value }, { setValue }] = useField<string>(name);
    return (
        <TextInput
            label="Maximum gene sets to show"
            name={name}
            onChange={onChange}
            placeholder={placeholder ?? ''}
            type="number"
            useFormikError
            value={value ?? 0}
            onValueChange={(_, __, values) => setValue(String(values?.float ?? 1))}
            useNumericFormat
            min={1}
            decimalScale={0}
            allowNegativeValue={false}
        />
    );
};

export default MaxGeneSetsField;
