import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import React from 'react';
import { isDefined, isString } from '@util/TypeGuards';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import SummaryStatisticsMethodsSection from '@components/experiments/methods/displays/SummaryStatisticsMethodsSection';
import ScaleMethods from '@components/experiments/methods/displays/ScaleMethods';
import NormalizationCPMMethods from '@components/experiments/methods/displays/NormalizationCPMMethods';
import BoxPlotDisplayOption from '@models/plotDisplayOption/BoxPlotDisplayOption';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import ProseList from '@components/ProseList';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const BoxPlotMethods = ({ plot, analysisParameters }: Props) => {
    const { getTargetById, getVariableById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as AssaySummaryAnalysis;

    const display = plot.display as BoxPlotDisplayOption;
    const targetNames =
        analysis?.targets.map((id) => (isString(id) ? id : getTargetById(id)?.display_name)).filter(isDefined) ?? [];
    const $targets = <ProseList items={targetNames} />;
    const groupNames = analysis?.groups?.map((g) => g?.display_name).filter(isDefined) ?? [];
    const $groups = <ProseList items={groupNames} />;
    const $variables = (
        <ProseList items={analysis.variables.map((id) => getVariableById(id)?.display_name).filter(isDefined)} />
    );
    const isNormalized = analysis?.analysis_type === 'assay_summary_cpm_normalized';
    const normalizationMethod = isNormalized ? 'counts per million (CPM)-normalized ' : '';

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>Plot</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        Boxplot showing the {normalizationMethod}values for {$targets} on the{' '}
                        <span className="italic">y</span>-axis and {$groups} on the <span className="italic">x</span>
                        -axis. <NormalizationCPMMethods analysis_type={analysis?.analysis_type} /> Data are colored
                        according to {$variables}. Boxplots are drawn using the median to define interquartile ranges,
                        with outliers defined as <span className="font-mono">±(1.5 × the interquartile range)</span>{' '}
                        from the 3rd quartile or 1st quartile, respectively.{' '}
                        <ScaleMethods y_axis_scale={display?.y_axis_scale} />
                    </p>
                </div>
            </section>
            <SummaryStatisticsMethodsSection />
        </div>
    );
};

export default BoxPlotMethods;
