import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const BarPlotIcon = (props?: IconProps) => {
    const { height = 39, width = 40, className = defaultTextLightClass } = { ...props };
    return (
        <svg
            viewBox="0 0 40 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
        >
            <rect
                x="1"
                y="8.76904"
                width="10"
                height="29.2308"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="15"
                y="17.5381"
                width="10"
                height="20.4615"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line x1="6" y1="1" x2="6" y2="25.3077" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line
                x1="20.001"
                y1="9.76904"
                x2="20.001"
                y2="21.4101"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <line
                x1="33.999"
                y1="23.4102"
                x2="33.999"
                y2="28.2307"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <rect
                x="29"
                y="26.3081"
                width="10"
                height="11.6923"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
