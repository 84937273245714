import useSWR from 'swr';
import { Team } from '@models/Team';
import Endpoints from '@services/Endpoints';
import { useMemo } from 'react';
import { getTeamPermissions, TeamPermissions } from '@hooks/useTeamPermissions';
import { PaginationResponse } from '@services/EndpointUtil';
import useAuth from '@hooks/useAuth';

const useTeams = () => {
    const { user } = useAuth();
    const {
        data: teamPage,
        error,
        isValidating,
        mutate,
    } = useSWR<PaginationResponse<Team>>(() => (user ? Endpoints.teams() : null));
    const loading = !teamPage && !error;

    const teamsById = useMemo<Record<string, Team>>(() => {
        if (!teamPage) {
            return {};
        }
        return teamPage?.items.reduce<Record<string, Team>>((agg, team) => ({ ...agg, [team.uuid]: team }), {});
    }, [teamPage]);

    const getTeamPermissionsById = (teamId?: string | null): TeamPermissions | null => {
        if (!teamId) {
            return null;
        }
        const team = teamsById[teamId];
        return getTeamPermissions(team);
    };

    return {
        teams: teamPage?.items,
        count: teamPage?.count,
        loading,
        isValidating,
        mutate,
        teamsById,
        getTeamPermissionsById,
        getTeamPermissions,
    };
};

export default useTeams;
