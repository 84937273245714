import { MouseEvent, ReactNode, useState } from 'react';
import { OrganismID } from '@models/Organism';
import GeneInfoView from '@components/GeneInfoView';
import { Popover } from '@mui/material';

type Props = { children?: ReactNode; gene: string; offsetLeft?: boolean; organism?: OrganismID };
/**
 * Users [BioThings](https://biothings.io/) to fetch details on the gene
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {string} gene
 * @param {boolean | undefined} offsetLeft
 * @param {OrganismID | undefined} organism
 * @return {JSX.Element}
 * @constructor
 */
const GeneInfoPopover = ({ children, gene, organism }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = async (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? gene : undefined;
    return (
        <>
            <div className="text-medium flex items-center" onClick={handleClick}>
                {children ?? <span className="link">{gene}</span>}
            </div>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="p-4">
                    <GeneInfoView gene={gene} organism={organism} close={handleClose} />
                </div>
            </Popover>
        </>
    );
};

export default GeneInfoPopover;
