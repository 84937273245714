import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ReactNode, useState, useEffect, useMemo } from 'react';
import Logger from '@util/Logger';
import { useFormikContext } from 'formik';
import TextAreaField from '@components/forms/TextAreaField';
import { Alert, Button } from '@mui/material';
import { ExternalAnalysis } from '@models/analysis/ExternalAnalysis';
import ResumableFileUploader, { TotalProgressInfo } from '@/src/components/fileUpload/ResumableFileUploader';
import { pluralize } from '@/src/util/ObjectUtil';
import { TrashIcon } from '@heroicons/react/outline';
import { ExternalAnalysisFormValues } from '../AnalysisFormTypes';
import UploadSession from '@models/UploadSession';
import { humanFileSize } from '@/src/util/StringUtil';
import { Accept } from 'react-dropzone';

const logger = Logger.make('ExternalAnalysisFormFields');

type Props = { plot: Plot; experiment: Experiment };

const ExternalAnalysisFormFields = ({ plot, experiment }: Props) => {
    const form = useFormikContext<ExternalAnalysisFormValues>();
    const [fileError, setFileError] = useState<ReactNode | null>(null);
    const [pageTitle] = useState(document?.title);

    const analysis = plot.analysis as ExternalAnalysis | null;

    const fileFields = useMemo(() => {
        const uploadedDisplayFileNotSaved = form.values?.display_file_id !== analysis?.display_file?.uuid;
        const uploadedResultsFileNotSaved = form.values?.results_file_id !== analysis?.results_file?.uuid;
        const uploadedScriptFileNotSaved = form.values?.script_file_id !== analysis?.script_file?.uuid;
        return [
            {
                fieldId: 'display_file_id',
                fieldName: 'display_file',
                showUploader: !form.values?.display_file_id || uploadedDisplayFileNotSaved,
                label: 'Display',
                acceptFileTypes: {
                    'image/*': ['.gif', '.jpeg', '.jpg', '.png', '.tiff', '.svg', '.webp'],
                    'application/pdf': ['.pdf'],
                    'text/html': ['.html', '.htm'],
                } as Accept,
            },
            {
                fieldId: 'results_file_id',
                fieldName: 'results_file',
                showUploader: !form.values?.results_file_id || uploadedResultsFileNotSaved,
                label: 'Results',
                acceptFileTypes: { 'text/plain': ['.csv', '.text'], 'text/csv': ['.csv'] } as Accept,
            },
            {
                fieldId: 'script_file_id',
                fieldName: 'script_file',
                showUploader: !form.values?.script_file_id || uploadedScriptFileNotSaved,
                label: 'Script',
                acceptFileTypes: { 'application/octet-stream': ['.zip'] } as Accept,
            },
        ];
    }, [form.values, analysis]);

    const handleRemoveFile = (fieldVal: string) => form.setFieldValue(fieldVal, '');

    const handleDoneUploading = async (session: UploadSession, fieldValue: string) => {
        document.title = pageTitle;
        form.setFieldValue(fieldValue, session?.file?.uuid);
    };

    const handleResumableProgress = (progress: TotalProgressInfo | null) => {
        if (!progress || progress.percentLoaded === 1) {
            document.title = pageTitle;
            return;
        }

        const fileWord = pluralize(progress.numFiles, 'file', 'files');
        const percent = (progress.percentLoaded * 100).toFixed(2);
        document.title = `[${percent}%] Uploading ${progress.numFiles} ${fileWord} | ${pageTitle}`;
    };

    const renderUploader = (fieldId: string, label: string, uploadedFileId: string, acceptFileTypes: Accept) => (
        <>
            <ResumableFileUploader
                uploadHeader={
                    uploadedFileId ? `${label} file uploaded successfully` : `Drop ${label.toLowerCase()} file here`
                }
                experiment={experiment}
                dataType="external"
                onUploadComplete={(session: UploadSession) => handleDoneUploading(session, fieldId)}
                onProgress={handleResumableProgress}
                maxFiles={1}
                uploadSubheader={
                    uploadedFileId
                        ? `Click "Save" to process file${fieldId === 'display_file_id' ? ' for preview' : ''}`
                        : undefined
                }
                chooseFileText="Choose file"
                showSuccess={!!uploadedFileId && !fileError}
                acceptFileTypes={acceptFileTypes}
                dynamicHeight
            />
            {uploadedFileId && (
                <div className="text-error">
                    <Button
                        onClick={() => handleRemoveFile(fieldId)}
                        startIcon={<TrashIcon className="h-5 w-5" />}
                        variant="outlined"
                        color="inherit"
                        size="small"
                    >
                        Remove {label.toLowerCase()} file
                    </Button>
                </div>
            )}
        </>
    );

    const renderFileDetails = (fieldId: string, label: string, fieldName: string) => {
        const file = analysis?.[fieldName];
        if (!file) return null;

        return (
            <div>
                <div className="form-field !mb-3">
                    <div className="field-label">{label} file</div>
                    <span className="font-semibold">{file.filename}</span>
                    <br />
                    <span className="text-sm">{new Date(file.created_at).toLocaleString()}</span>
                    <span>&bull;</span>
                    <span className="text-sm">{humanFileSize(file.file_size)}</span>
                    <br />
                    <a href={file.signed_url} download>
                        Download
                    </a>
                </div>
                <div className="text-error">
                    <Button
                        onClick={() => handleRemoveFile(fieldId)}
                        startIcon={<TrashIcon className="h-5 w-5" />}
                        variant="outlined"
                        color="inherit"
                        size="small"
                    >
                        Update {label.toLowerCase()} file
                    </Button>
                </div>
            </div>
        );
    };

    const renderFields = () =>
        fileFields.map(({ fieldId, fieldName, showUploader, label, acceptFileTypes }) =>
            showUploader
                ? renderUploader(fieldId, label, form.values[fieldId] ?? analysis?.[fieldId], acceptFileTypes)
                : renderFileDetails(fieldId, label, fieldName),
        );

    useEffect(() => {
        logger.debug('rendering ExternalAnalysisFormFields');
    }, []);

    return (
        <div className="space-y-8">
            {fileError && (
                <Alert severity="error" onClose={() => setFileError(null)}>
                    {fileError}
                </Alert>
            )}
            <div className="space-y-4">{renderFields()}</div>
            <div>
                <TextAreaField name="methods" label="Methods" minRows={4} />
            </div>
        </div>
    );
};

export default ExternalAnalysisFormFields;
