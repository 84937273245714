import SelectableItem from '@components/forms/SelectableItem';
import React from 'react';
import { useFormikContext } from 'formik';
import { ErrorBarsConfig } from '@models/PlotConfigs';

const ErrorBarOptionField = () => {
    const { values, setFieldValue } = useFormikContext<ErrorBarsConfig>();
    return (
        <div className="form-field">
            <span className="field-label">Options</span>
            <div className="flex flex-row flex-wrap">
                <SelectableItem
                    selected={values.error_bars_option === 'sd'}
                    onSelect={() => setFieldValue('error_bars_option', 'sd')}
                    className="mr-2 flex flex-col items-center justify-center"
                >
                    <span>SD</span>
                </SelectableItem>
                <SelectableItem
                    selected={values.error_bars_option === 'sem'}
                    onSelect={() => setFieldValue('error_bars_option', 'sem')}
                    className="flex flex-col items-center justify-center"
                >
                    <span>SEM</span>
                </SelectableItem>
            </div>
        </div>
    );
};
export default ErrorBarOptionField;
