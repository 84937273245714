import Plot from '@models/Plot';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import { isNotBlank } from '@util/StringUtil';
import React from 'react';

type Props = { plot: Plot };
const PrismGraphsetMethods = ({ plot }: Props) => {
    const analysis = plot.analysis as PrismAnalysis | null;

    return (
        <div className="space-y-4">
            {analysis?.methods && isNotBlank(analysis.methods) ? (
                <div className="whitespace-pre-line">{analysis.methods}</div>
            ) : (
                <div>No methods have been provided.</div>
            )}
        </div>
    );
};

export default PrismGraphsetMethods;
