import { Team } from '@models/Team';
import TeamDialogForm from '@components/team/TeamDialogForm';
import { Dialog } from '@mui/material';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

type Props = {
    team?: Team | null;
    open: boolean;
    onClose: () => void;
    title: ReactNode;
    onSaved?: (team: Team | null) => void;
};
const EditTeamDialog = ({ team, open, onClose, title, onSaved }: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="sm">
            <DialogCloseButton onClose={onClose} />

            <PlutoDialogTitle title={title} />

            <TeamDialogForm onSaved={onSaved} team={team} />
        </Dialog>
    );
};

export default EditTeamDialog;
