import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import { SurvivalAnalysisParameters } from '@models/AnalysisParameters';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import React from 'react';
import TextInputField from '@components/forms/TextInputField';
import Logger from '@util/Logger';
import { SurvivalAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';
import { useFormikContext } from 'formik';
import SurvivalAnalysisVariablesPickerFieldGroup from '@components/experiments/analyses/fields/SurvivalAnalysisVariablesPickerFieldGroup';

const logger = Logger.make('SurvivalAnalysisFormFields');

type Props = { plot: Plot; experiment: Experiment; analysisParameters: SurvivalAnalysisParameters };
const SurvivalAnalysisFormFields = ({ experiment, plot, analysisParameters }: Props) => {
    const formik = useFormikContext<SurvivalAnalysisFormValues>();
    logger.info('values', formik.values);
    return (
        <div className="space-y-8">
            <SurvivalAnalysisVariablesPickerFieldGroup variables={analysisParameters.variables} />

            <TextInputField name="time_to_event_units" label="Time to event units" placeholder="Months, years, etc" />

            <AnalysisVariablesMultiSelectField variables={analysisParameters.variables} />
            <AnalysisGroupsField plot={plot} experiment={experiment} label="Groups to include" />
        </div>
    );
};

export default SurvivalAnalysisFormFields;
