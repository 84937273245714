import ThemeColorField from '@components/experiments/plotDisplay/fields/ThemeColorField';
import ThemeStyleField from '@components/experiments/plotDisplay/fields/ThemeStyleField';
import React, { ReactNode } from 'react';

type Props = { hideStyle?: boolean; colorsLabel?: ReactNode; styleLabel?: ReactNode };
const ThemeFieldGroup = ({ hideStyle = false, colorsLabel, styleLabel }: Props) => {
    return (
        <div className="mb-8 flex flex-col">
            <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Theme</h4>
            <div className="space-y-4">
                <ThemeColorField label={colorsLabel} />
                {!hideStyle && <ThemeStyleField label={styleLabel} />}
            </div>
        </div>
    );
};

export default ThemeFieldGroup;
