import React from 'react';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import { CreateSeuratObjectFormValues, PreprocessFormValues } from '../../preprocesses/PreprocessFormTypes';
import CodeBlockInline from '@/src/components/CodeBlockInline';
import { formatStringToNumberWithSeparator } from '@/src/util/StringUtil';

type Props = { preprocessFormData: PreprocessFormValues; displayName: string };
const CreateSeuratMethods = ({ preprocessFormData, displayName }: Props) => {
    const preprocess = preprocessFormData as CreateSeuratObjectFormValues;
    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>{displayName}</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        Data was processed using the single cell data analysis software Seurat. An initial Seurat object
                        was created using the <CodeBlockInline>CreateSeuratObject()</CodeBlockInline> function, where
                        the <CodeBlockInline>counts</CodeBlockInline> parameter was set to the merged transcript count
                        matrix obtained from the Prepare data step, using a <CodeBlockInline>min.cells</CodeBlockInline>{' '}
                        parameter of {formatStringToNumberWithSeparator(preprocess.min_cells)} and a{' '}
                        <CodeBlockInline>min.features</CodeBlockInline> parameter of{' '}
                        {formatStringToNumberWithSeparator(preprocess.min_features)}.
                    </p>
                    <p>
                        To assess the quality of individual cells, the percentage of reads that mapped to mitochondrial
                        genes in each cell was computed. A novelty score was also added to the data, which was derived
                        by taking the logarithm (base 10) of the gene counts divided by the logarithm (base 10) of the
                        UMI counts.
                    </p>
                    <p>
                        Sample-level information, cell-level statistics prepared by Seurat, and multiplet prediction
                        from scDblFinder were incorporated into the Seurat object&apos;s metadata.
                    </p>
                    <div>
                        <MethodsSectionTitle>Software versions</MethodsSectionTitle>
                        <p>
                            R v4.3.1
                            <br />
                            Seurat v4.3.0
                            <br />
                            scDblFinder v1.14.0
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CreateSeuratMethods;
