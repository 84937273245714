import { HeaderGroup, TableInstance, UseSortByColumnProps } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import React from 'react';
import { DataTableProps } from '@components/dataTable/DataTable';

export const DataTableHeader = ({
    tableInstance,
    tableColor,
}: { tableInstance: TableInstance<GenericCellData> } & Pick<DataTableProps, 'tableColor'>) => {
    const { headerGroups } = tableInstance;

    const renderColumnIcon = ({ isSorted, isSortedDesc }: UseSortByColumnProps<GenericCellData> & HeaderGroup) => {
        if (!isSorted) {
            return null;
        }
        if (isSortedDesc) {
            return <SortDescendingIcon className="ml-2 h-5 w-5" />;
        } else {
            return <SortAscendingIcon className="ml-2 h-5 w-5" />;
        }
    };

    return (
        <thead className={cn('border-0', tableColor)}>
            {
                // Loop over the header rows
                headerGroups.map((headerGroup) => {
                    const {
                        className: headerClasses,
                        key: headerKey,
                        ...headerGroupProps
                    } = headerGroup.getHeaderGroupProps();

                    return (
                        // Apply the header row props
                        <tr {...headerGroupProps} key={headerKey} className={cn(['rounded-t-xl', headerClasses])}>
                            {
                                // Loop over the headers in each row
                                headerGroup.headers.map((column) => (
                                    // Apply the header cell props
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps?.())}
                                        key={`${column.getHeaderProps().key}`}
                                        className={cn(
                                            'sticky top-0 z-10 px-4 py-4 text-left font-semibold last:-mr-1 last:rounded-tr-xl',
                                            tableColor,
                                        )}
                                    >
                                        <span className="flex items-center space-x-1">
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <span className="relative top-[3px]">{renderColumnIcon(column)}</span>
                                            )}
                                            {column.canSort && !column.isSorted && (
                                                <span className="inline-block w-5" />
                                            )}
                                        </span>
                                    </th>
                                ))
                            }
                        </tr>
                    );
                })
            }
        </thead>
    );
};

export default DataTableHeader;
