import React from 'react';
import cn from 'classnames';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { IconButton } from '@mui/material';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, useRef } from 'react';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { CheckIcon } from '@heroicons/react/outline';
import { CodeProps, ReactMarkdownProps } from 'react-markdown/lib/ast-to-react';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/react/style.css';
import ReactMarkdown from 'react-markdown';

const proseClasses = 'prose max-w-none prose-sm';

export const CustomPreTag = ({
    node: _node,
    className,
    children,
    ...props
}: PropsWithChildren<
    Pick<
        DetailedHTMLProps<HTMLAttributes<HTMLPreElement>, HTMLPreElement>,
        'key' | keyof HTMLAttributes<HTMLPreElement>
    > &
        ReactMarkdownProps
>) => {
    return (
        <pre className={`relative ${className} p-0`} {...props}>
            {children}
        </pre>
    );
};

export const CustomCodeTag = ({ node: _node, inline, className, children, ...props }: CodeProps) => {
    const ref = useRef<HTMLElement>(null);
    const { copyText, showCopySuccess } = useCopyToClipboard({ ref });

    if (inline) {
        return (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }

    return (
        <div className="max-w-full overflow-auto px-2 py-2">
            <div className="absolute right-1 top-1 text-white ">
                <IconButton
                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                    onClick={() => copyText()}
                    size="small"
                >
                    {showCopySuccess ? (
                        <CheckIcon width={18} className="text-success" />
                    ) : (
                        <CopyIcon className="text-white" width={18} />
                    )}
                </IconButton>
            </div>
            <code className={cn(className)} {...props} ref={ref}>
                {children}
            </code>
        </div>
    );
};

type Props = { content: string; className?: string };
/**
 * View that supports GitHub Flavored Markdown (GFM)
 * @param {string | null} content
 * @param {string | undefined} className
 * @return {JSX.Element}
 * @constructor
 */

const PlutoMarkdownContentView = ({ content, className }: Props) => {
    return (
        <div className={className}>
            <div className={proseClasses}>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        code: CustomCodeTag,
                        pre: CustomPreTag,
                    }}
                >
                    {content ?? ''}
                </ReactMarkdown>
            </div>
        </div>
    );
};

export default PlutoMarkdownContentView;
