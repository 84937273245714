import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const DotPlotIcon = (props?: IconProps) => {
    const { height = 40, width = 40, className = defaultTextLightClass } = { ...props };
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
        >
            <circle cx="5.2174" cy="6.95654" r="1.73913" fill="currentColor" />
            <circle cx="19.1304" cy="6.95653" r="3.47826" fill="currentColor" />
            <circle cx="33.0434" cy="6.95652" r="6.95652" fill="currentColor" />
            <circle cx="19.1305" cy="20.8696" r="1.73913" fill="currentColor" />
            <circle cx="33.0434" cy="20.8696" r="3.47826" fill="currentColor" />
            <circle cx="33.0434" cy="34.7826" r="3.47826" fill="currentColor" />
            <circle cx="5.21727" cy="34.7826" r="3.47826" fill="currentColor" />
            <circle cx="5.21739" cy="20.8696" r="5.21739" fill="currentColor" />
            <circle cx="19.1305" cy="34.7826" r="5.21739" fill="currentColor" />
        </svg>
    );
};
