import { defaultTextLightClass, IconProps } from '@components/icons/CustomIcons';

export const HeatmapChartIcon = ({ width = 39, height = 29, className = defaultTextLightClass }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 39 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect x="1" y="1" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect x="11" y="11" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect x="21" y="11" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect x="21" y="21" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect x="10.5" y="0.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="0.5" y="10.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="30.5" y="10.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="30.5" y="20.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="0.5" y="20.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="10.5" y="20.5" width="8" height="8" rx="1.5" fill="currentColor" stroke="currentColor" />
            <rect x="21" y="1" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
            <rect x="31" y="1" width="7" height="7" rx="1" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
};
