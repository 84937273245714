import { useField } from 'formik';
import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import GeneSet from '@models/GeneSet';
import SearchableGeneSetTable from '@components/experiments/plotDisplay/fields/SearchableGeneSetTable';

type Props = {
    name: string;
    experiment: Experiment;
    plot: Plot;
    onSelected?: (value: GeneSet) => void;
};
const GeneSetTableField = ({ name, plot, experiment, onSelected }: Props) => {
    const [, , { setValue }] = useField<string | null>(name);

    return (
        <SearchableGeneSetTable
            experiment={experiment}
            plot={plot}
            onSelected={(item) => {
                setValue(item.Gene_Set_Shortname || null);
                onSelected?.(item);
            }}
        />
    );
};

export default GeneSetTableField;
