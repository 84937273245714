import { PCAData, PCASample } from '@models/ExperimentData';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import { isDefined } from '@util/TypeGuards';

type AxisStats = {
    max: number;
    min: number;
};
export type DataPoint = { x: number; y: number } & PCASample;

const yValue = (d: PCASample, options: Pick<SampleScatterPlotDisplayOption, 'y_axis_column'>): number => {
    return d[options.y_axis_column] as number;
};
const xValue = (d: PCASample, options: Pick<SampleScatterPlotDisplayOption, 'x_axis_column'>): number => {
    return d[options.x_axis_column] as number;
};

export type PreparedScatterPlotData = {
    x: AxisStats;
    y: AxisStats;
    items: DataPoint[];
};

export const prepareSampleScatterPlotData = (
    data: PCAData,
    options: SampleScatterPlotDisplayOption,
): PreparedScatterPlotData | null => {
    const [first] = data.items;
    if (!first) {
        return null;
    }
    // initialize values. Will iterate through all items to update stats
    const xStats: AxisStats = { max: xValue(first, options), min: xValue(first, options) };
    const yStats: AxisStats = {
        max: yValue(first, options),
        min: yValue(first, options),
    };

    const items = data.items
        .filter((d) => {
            const x = xValue(d, options);
            const y = yValue(d, options);
            const xMinCheck = !isDefined(options.x_axis_start) || x >= options.x_axis_start;
            const xMaxCheck = !isDefined(options.x_axis_end) || x <= options.x_axis_end;

            const yMinCheck = !isDefined(options.y_axis_start) || y >= options.y_axis_start;
            const yMaxCheck = !isDefined(options.y_axis_end) || y <= options.y_axis_end;

            return xMinCheck && xMaxCheck && yMinCheck && yMaxCheck;
        })
        .map((d) => {
            const point = { ...d, x: xValue(d, options), y: yValue(d, options) };
            if (point.x > xStats.max) {
                xStats.max = point.x;
            }
            if (point.x < xStats.min) {
                xStats.min = point.x;
            }

            if (point.y > yStats.max) {
                yStats.max = point.y;
            }
            if (point.y < yStats.min) {
                yStats.min = point.y;
            }
            return point;
        });

    return { items, x: xStats, y: yStats };
};
