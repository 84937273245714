import React, { useMemo } from 'react';
import { PCAData } from '@models/ExperimentData';
import Plot from '@models/Plot';
import Logger from '@util/Logger';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import usePCASummary from '@hooks/usePCASummary';
import Experiment from '@models/Experiment';
import SampleScatterPlotBuilder from '@components/analysisCategories/dimensionalityReduction/plots/builders/SampleScatterPlotBuilder';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import DynamicPlotContainer, { DrawChartFn } from '@components/plots/DynamicPlotContainer';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';

const logger = Logger.make('SampleScatterPlotView');

export type Props = {
    plot: Plot<ExperimentAnalysis, SampleScatterPlotDisplayOption>;
    experiment: Experiment;
    customPlotStylingOptions: CustomPlotStylingOptions | null;
};

const SampleScatterPlotView = ({ plot, experiment, customPlotStylingOptions }: Props) => {
    const { getProportionOfVariance } = usePCASummary({
        experimentId: experiment.uuid,
        analysisId: plot.analysis?.uuid,
    });

    const drawChart = useMemo<DrawChartFn>(
        () =>
            ({ svgSelection: svg, size, context, tooltipId }) => {
                if (!context.plot || !context.plotData) {
                    logger.warn('either the plot or plot data was not found when attempting to draw the plot');
                    return;
                }
                const builder = SampleScatterPlotBuilder.make({
                    svg,
                    size,
                    experiment: context.experiment,
                    plot: context.plot as Plot<ExperimentAnalysis, SampleScatterPlotDisplayOption>,
                    data: context.plotData as PCAData,
                    getProportionOfVariance,
                    publicationMode: context.publicationMode,
                    tooltipId,
                    stylingOptions: customPlotStylingOptions ?? null,
                });
                builder.draw();
            },
        [getProportionOfVariance, customPlotStylingOptions],
    );

    return <DynamicPlotContainer drawChart={drawChart} />;
};

export default SampleScatterPlotView;
