import { DialogActions, DialogContent, SxProps, Theme } from '@mui/material';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import React, { MutableRefObject, ReactNode, useEffect, useState } from 'react';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { isDefined } from '@util/TypeGuards';
import { DocumentReportIcon } from '@heroicons/react/outline';
import { generatePlotShareUrl } from '@components/plots/PlotUtil';
import { defaultTextClass } from '@components/icons/CustomIcons';
import Button from '@components/Button';
import { Alert } from '@mui/material';
import useFileDownloader from '@hooks/useFileDownloader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInputField from '@components/forms/TextInputField';
import useApi from '@hooks/useApi';
import { generatePlotFileName } from '@util/ExperimentUtil';
import { useOptionalExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import PlotItem from './PlotItem';
import ClickableItem from './ClickableItem';
import copy from '@copy/Copy';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Tooltip } from '@mui/material';
import Experiment from '@models/Experiment';
import { StateSetter } from '@contexts/ContextTypes';
import { TimeoutValue } from '@util/ObjectUtil';
import {
    BenchlingPlotData,
    BenchlingExportParams,
} from '@components/experiments/benchling/BenchlingExperimentMultiExportDialog';
import Plot, { PlotListItem, PlotListItemsResponse } from '@/src/models/Plot';

const logger = Logger.make('SyncWithBenchlingView');
const schema = Yup.object({
    plots: Yup.array().of(Yup.object({ id: Yup.string(), dataType: Yup.string() })),
});

type PlotObject = { id: string; dataType: string; plot?: Plot };
type FormValues = { plots: PlotObject[] };
type ReportResponse = { url: string };

type SubmitResult = { success?: boolean; error?: null | ReactNode };
type Props = {
    actionsClassName?: SxProps<Theme>;
    benchlingExportData: BenchlingExportParams | null;
    contentClassName?: SxProps<Theme>;
    CustomTitle?: ReactNode;
    experiment: Experiment;
    handleClose: () => void;
    isDialog?: boolean;
    qcExported: boolean;
    setBenchlingExportData: StateSetter<BenchlingExportParams | null>;
    setQcExported: StateSetter<boolean>;
    setStepKey: StateSetter<string>;
    setSubmitResult: StateSetter<SubmitResult | null>;
    setSubmitting: StateSetter<boolean>;
    stepKey: string;
    submitResult: SubmitResult | null;
    submitting: boolean;
    successTimeoutRef: MutableRefObject<TimeoutValue | null>;
};
const SyncWithBenchlingView = ({
    actionsClassName,
    benchlingExportData,
    contentClassName,
    CustomTitle,
    experiment,
    handleClose,
    isDialog = true,
    qcExported,
    setBenchlingExportData,
    setQcExported,
    setStepKey,
    setSubmitResult,
    setSubmitting,
    stepKey,
    submitResult,
    submitting,
    successTimeoutRef,
}: Props) => {
    const [plotItems, setPlotItems] = useState<PlotListItem[] | null>(null);
    const experimentId = experiment.uuid;
    const optionalExperimentContext = useOptionalExperimentDetailViewContext();
    const plotsLoading = !isDefined(plotItems);
    const { createFileFromUrl, createPlutoEmbedHTMLFile } = useFileDownloader();
    const [initialValues, setInitialValues] = useState<FormValues>({ plots: [] });
    const api = useApi();
    const { data: qcSignedUrl } = useSWR<ReportResponse>(() =>
        experiment.qc_report_url ? Endpoints.lab.experiment.qcReportUrl({ experimentId }) : null,
    );

    const fetchPlots = async () => {
        try {
            const plotResponse = await api.get<PlotListItemsResponse>(
                Endpoints.lab.experiment.plotsV2({ experimentId }),
            );
            setPlotItems(plotResponse.items);
        } catch (error) {
            logger.error(error);
        }
    };

    const parseExportData = (exportData: BenchlingExportParams) => {
        if (!exportData.plots_exported.length) return initialValues;
        const newPlots: PlotObject[] = [];
        exportData.plots_exported.forEach((plot: BenchlingPlotData) => {
            if (plot.file_exported) newPlots.push({ id: plot.plot_id, dataType: 'html' });
            if (plot.dataset_exported) newPlots.push({ id: plot.plot_id, dataType: 'csv' });
        });
        return { ...initialValues, plots: newPlots };
    };

    const params = { step_key: stepKey };

    const fetchBenchlingData = async () => {
        try {
            const exportData: BenchlingExportParams = await api.post(
                Endpoints.lab.experiment.benchling.exports({ experimentId }),
                params,
            );
            setBenchlingExportData(exportData);
            setQcExported(exportData.qc_report_exported);
            setInitialValues(parseExportData(exportData));
            setSubmitResult(null);
        } catch (error) {
            logger.error(error);
            setSubmitResult({
                success: false,
                error: 'Your Benchling analysis key is invalid.',
            });
        }
    };

    useEffect(() => {
        if (!experiment) return;

        fetchPlots();
    }, [experiment]);

    useEffect(() => {
        if (!stepKey) return;

        fetchBenchlingData();
    }, [stepKey]);

    // reset states on unmount
    useEffect(
        () => () => {
            handleClose();
        },
        [],
    );

    const handleSubmit = async (values: FormValues) => {
        setSubmitResult(null);

        if (!qcExported && values.plots.length === 0) {
            setSubmitResult({ error: 'Please select at least one plot or the MultiQC report' });
            setSubmitting(false);
            return;
        }

        logger.debug('submitting', { values });

        setSubmitting(true);

        const files: File[] = [];
        const csvFiles: File[] = [];

        await Promise.all(
            values.plots.map(async (plotObject) => {
                const plot = plotItems?.find((p) => p.uuid === plotObject.id);
                if (!plot || !plotObject.plot) return;

                const overrides = optionalExperimentContext?.getPlotOverrides?.(plotObject.id);
                const publicationMode = optionalExperimentContext?.publicationMode ?? false;
                let plotFile: File;
                if (plotObject.dataType === 'csv') {
                    const response: { url: string } = await api.get(
                        Endpoints.lab.experiment.downloadPlotData({ experimentId, plotId: plotObject.id }),
                    );
                    plotFile = await createFileFromUrl({
                        url: response.url,
                        filename: `${generatePlotFileName({ plot: plotObject.plot, experiment })}.csv`,
                        metadata: { type: 'text/csv' },
                    });
                    csvFiles.push(plotFile);
                } else {
                    plotFile = await createPlutoEmbedHTMLFile({
                        filename: `${generatePlotFileName({ plot: plotObject.plot, experiment })}.html`,
                        title: `Pluto plot embed for Benchling`,
                        src: generatePlotShareUrl({ plot: plotObject.plot, experiment, overrides, publicationMode }),
                    });
                    files.push(plotFile);
                }
            }),
        );

        if (qcExported && qcSignedUrl?.url) {
            const qcFile = await createFileFromUrl({
                url: qcSignedUrl.url,
                filename: `${experiment.pluto_id}_qc_report.html`,
                metadata: { type: 'text/html' },
            });
            files.push(qcFile);
        }

        try {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('files', file);
            });
            csvFiles.forEach((file) => {
                formData.append('datasets', file);
            });

            formData.append('step_key', stepKey);
            const response = await api.apiService.$axios.post(
                Endpoints.lab.experiment.benchling.exportAnalysisReport({ experimentId }),
                formData,
                { headers: api.apiService.authHeaders },
            );
            logger.info('response', response);
            await fetchBenchlingData();
            setSubmitResult({ success: true });
        } catch (error) {
            logger.error(error);
            let errorMessage = 'Unable to export to Benchling. Please try again later';
            if (error?.response?.data?.details && error?.response?.data?.details.length) {
                errorMessage = error.response.data.details[0].message;
            }
            setSubmitResult({
                success: false,
                error: errorMessage,
            });
        } finally {
            setSubmitting(false);
        }

        // show success message for limited period of time
        // abstract this into a success message hook
        successTimeoutRef.current = setTimeout(() => {
            setSubmitResult(null);
        }, 5000);
    };

    if (!plotItems) {
        return (
            <>
                {CustomTitle ?? <PlutoDialogTitle>Sync with Benchling</PlutoDialogTitle>}
                <DialogContent sx={contentClassName}>
                    <div className="space-y-2">
                        <p className="text-lg font-semibold tracking-tight text-dark">Benchling analysis</p>
                        <p className="my-1 text-sm font-semibold leading-4">
                            Analysis key
                            <a href={copy.exportToBenchlingHelpUrl} target="_blank" rel="noreferrer">
                                <Tooltip className="cursor-pointer" title="Where do I find my analysis key?">
                                    <HelpOutlineOutlinedIcon fontSize="small" className="ml-1" />
                                </Tooltip>
                            </a>
                        </p>
                        <p>Loading...</p>
                    </div>
                </DialogContent>
            </>
        );
    }
    return (
        <>
            {CustomTitle ?? <PlutoDialogTitle>Sync with Benchling</PlutoDialogTitle>}
            {submitResult && (
                <DialogContent sx={contentClassName} className="mb-4">
                    {submitResult.error && <Alert severity="error">{submitResult.error}</Alert>}
                    {submitResult.success && <Alert severity="success">Submitted successfully</Alert>}
                </DialogContent>
            )}
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <>
                        <DialogContent sx={contentClassName}>
                            {!benchlingExportData ? (
                                <div className="space-y-2">
                                    <p className="text-lg font-semibold tracking-tight text-dark">Benchling analysis</p>
                                    <p className="my-1 text-sm font-semibold leading-4">
                                        Analysis key
                                        <a href={copy.exportToBenchlingHelpUrl} target="_blank" rel="noreferrer">
                                            <Tooltip
                                                className="cursor-pointer"
                                                title="Where do I find my analysis key?"
                                            >
                                                <HelpOutlineOutlinedIcon fontSize="small" className="ml-1" />
                                            </Tooltip>
                                        </a>
                                    </p>
                                    <TextInputField
                                        name="step_key"
                                        label=""
                                        placeholder="Paste your analysis key..."
                                        onChange={(e) => setStepKey(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <div className="space-y-8">
                                    <div className="space-y-2">
                                        <p className="text-lg font-semibold tracking-tight text-dark">
                                            Pluto results
                                            {values.plots.length > 0 && (
                                                <span className="ml-2 text-sm font-normal tracking-normal">
                                                    ({values.plots.length} selected)
                                                </span>
                                            )}
                                        </p>
                                        <p className="font-semibold">QC report</p>
                                        {experiment.qc_report_url ? (
                                            <ClickableItem
                                                disabled={submitting}
                                                onChange={(selected) => setQcExported(selected)}
                                                label={
                                                    <p className="font-semibold">
                                                        {experiment.pluto_id} MultiQC report
                                                    </p>
                                                }
                                                selected={qcExported}
                                                icon={<DocumentReportIcon width={22} className={defaultTextClass} />}
                                                dataCy="export-multi-qc"
                                            />
                                        ) : (
                                            <p>No MultiQC report available for this experiment</p>
                                        )}
                                    </div>

                                    <div className="space-y-2">
                                        <div className="flex flex-row justify-between">
                                            <p className="w-9/12">
                                                <span className="text-md font-semibold tracking-tight">Result</span>
                                            </p>
                                            <div className="mr-4 flex w-36 flex-row items-center justify-between">
                                                <p className="text-xs font-semibold">Interactive plot (HTML)</p>
                                                <p className="text-xs font-semibold">Plot data (CSV)</p>
                                            </div>
                                        </div>
                                        <div className="max-h-80 space-y-2 overflow-auto">
                                            {plotItems?.length ? (
                                                plotItems.map((plot, i) => {
                                                    const plotExportData = benchlingExportData.plots_exported.find(
                                                        (item) => item.plot_id === plot.uuid,
                                                    );
                                                    return (
                                                        <PlotItem
                                                            key={plot.uuid}
                                                            plotId={plot.uuid}
                                                            experimentId={experiment.uuid}
                                                            disabled={submitting}
                                                            onChange={(selected, label, plot) => {
                                                                let updated = [...values.plots];
                                                                if (
                                                                    selected &&
                                                                    !values.plots.find(
                                                                        (plotItem) =>
                                                                            plotItem.id === plot.uuid &&
                                                                            plotItem.dataType === label,
                                                                    )
                                                                ) {
                                                                    updated.push({
                                                                        id: plot.uuid,
                                                                        dataType: label,
                                                                        plot,
                                                                    });
                                                                } else if (!selected) {
                                                                    updated = updated.filter((plotItem) => {
                                                                        return (
                                                                            plotItem.id !== plot.uuid ||
                                                                            plotItem.dataType !== label
                                                                        );
                                                                    });
                                                                }
                                                                setFieldValue('plots', updated);
                                                            }}
                                                            dataCy={`export-plot-${i}`}
                                                            plotExportData={plotExportData}
                                                            analysisType={plot.analysis_type}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <div>{plotsLoading ? 'Loading plots...' : 'No plots available'}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions sx={actionsClassName}>
                            {!benchlingExportData && isDialog ? (
                                <Button color="primary" variant="contained" onClick={handleClose}>
                                    Cancel
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleSubmit(values)}
                                    disabled={!benchlingExportData || !!submitting}
                                    loading={!!submitting}
                                >
                                    Sync
                                </Button>
                            )}
                        </DialogActions>
                    </>
                )}
            </Formik>
        </>
    );
};

export default SyncWithBenchlingView;
