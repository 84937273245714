import cn from 'classnames';
import { Divider, FormControl, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MenuProps } from '@/src/theme';
import { LoadingIndicator } from '@components/LoadingButton';
import { isDefined } from '@util/TypeGuards';
import { RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD } from '@models/ExperimentConfig';
import { FormikFieldError } from '@components/forms/FieldError';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DifferentialExpressionAnalysisFormValues } from '@models/analysis/DifferentialExpressionAnalysis';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import { AnalysisGroup } from '@models/AnalysisParameters';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import useFormStyles from '@hooks/useFormStyles';
import { isNotBlank } from '@util/StringUtil';
import { pluralize } from '@util/ObjectUtil';

type Props = { plot: Plot; experiment: Experiment };
const DifferentialGroupsPickerFields = ({ plot, experiment }: Props) => {
    const classes = useFormStyles;
    const { values, errors, touched, handleChange } = useFormikContext<DifferentialExpressionAnalysisFormValues>();

    const variables = values.variables;

    const { loading, groups } = useExperimentPlotGroups<AnalysisGroup>({
        experiment,
        plot,
        variables,
    });

    const availableGroups = useMemo(() => {
        return [...(groups ?? [])].sort((g1, g2) => {
            const difference = g2.sample_id_count - g1.sample_id_count;
            if (difference !== 0) {
                return difference;
            }

            return g1.display_name.toLowerCase().localeCompare(g2.display_name.toLowerCase());
        });
    }, [variables, groups]);

    const selected_control_group_id = values.control_group_id;
    const selected_experimental_group_id = values.experimental_group_id;

    const controlGroupMatches = !!availableGroups.find((g) => g.id === selected_control_group_id);
    const experimentalGroupMatches = !!availableGroups.find((g) => g.id === selected_experimental_group_id);

    const showExperimentalError = isNotBlank(errors.experimental_group_id) && touched.experimental_group_id;
    const showControlError = isNotBlank(errors.control_group_id) && touched.control_group_id;

    const getGroupInfo = (groupId?: string | number | null) => {
        if (!isDefined(groupId)) {
            return null;
        }
        return availableGroups.find((g) => g.id === Number(groupId)) ?? null;
    };

    return (
        <div className="form-field rounded-lg bg-indigo-100 p-4" data-cy="groups-picker-field">
            <div
                className={cn('form-field !mb-2', {
                    'has-error': showExperimentalError,
                })}
            >
                <p className="field-label">Experimental group</p>
                <p className="mb-1">
                    Each group must have at least {RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD}{' '}
                    {pluralize(RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD, 'sample', 'samples')} for this analysis
                </p>
                <FormControl
                    fullWidth
                    variant="outlined"
                    error={showExperimentalError}
                    data-cy="experimental-group-input"
                >
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        value={selected_experimental_group_id}
                        name="experimental_group_id"
                        onChange={handleChange}
                        fullWidth
                        unselectable="on"
                        className="whitespace-normal"
                        error={showExperimentalError}
                        MenuProps={{
                            ...MenuProps,
                            sx: {
                                paper: classes.targetMenu,
                            },
                        }}
                        renderValue={(value: string | number | null) => {
                            if (loading) {
                                return (
                                    <span className="flex items-center space-x-2 opacity-50">
                                        <LoadingIndicator size={12} />
                                        <span>Loading groups...</span>
                                    </span>
                                );
                            }
                            const groupName = getGroupInfo(value)?.display_name;
                            if (isDefined(groupName)) {
                                return <span className="whitespace-normal">{groupName}</span>;
                            }
                            return <span className="opacity-50">Select a group...</span>;
                        }}
                    >
                        <MenuItem value={-1} disabled={loading || selected_experimental_group_id === -1}>
                            {loading ? (
                                <span className="flex items-center space-x-2 opacity-50">
                                    <LoadingIndicator size={12} />
                                    <span>Loading groups...</span>
                                </span>
                            ) : (
                                <span>
                                    {selected_experimental_group_id !== -1 ? 'Clear selection' : 'Select a group...'}
                                </span>
                            )}
                        </MenuItem>
                        {experimentalGroupMatches && <Divider />}
                        {availableGroups.map(({ display_name, id, sample_id_count }, i) => (
                            <MenuItem
                                sx={{
                                    root: classes.rootItem,
                                    selected: classes.selectedTarget,
                                }}
                                key={`target_${i}`}
                                value={id}
                                disabled={
                                    id === selected_control_group_id ||
                                    sample_id_count < RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD
                                }
                            >
                                <div className="flex flex-grow items-start justify-between space-x-2 whitespace-normal">
                                    <div className="flex flex-col">
                                        <span className="font-semibold">{display_name}</span>
                                        <span className="text-sm">
                                            {`${sample_id_count} ${sample_id_count === 1 ? 'sample' : 'samples'}`}
                                        </span>
                                    </div>
                                    {id === selected_control_group_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            Control
                                        </span>
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormikFieldError name="experimental_group_id" />
                </FormControl>
            </div>

            <span className="field-label no-margin mb-2 block">vs.</span>

            <div
                className={cn('form-field', {
                    'has-error': showControlError,
                })}
            >
                <span className="field-label">Control group</span>
                <FormControl fullWidth variant="outlined" error={showControlError} data-cy="control-group-input">
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        value={selected_control_group_id}
                        name="control_group_id"
                        onChange={handleChange}
                        error={showControlError}
                        MenuProps={{
                            ...MenuProps,
                            sx: {
                                paper: classes.targetMenu,
                            },
                        }}
                        className="whitespace-normal"
                        fullWidth
                        renderValue={(value: string | number | null) => {
                            if (loading) {
                                return (
                                    <span className="flex items-center space-x-2 opacity-50">
                                        <LoadingIndicator size={12} />
                                        <span>Loading groups...</span>
                                    </span>
                                );
                            }
                            const groupName = getGroupInfo(value)?.display_name;
                            if (isDefined(groupName)) {
                                return <span className="whitespace-normal">{groupName}</span>;
                            }
                            return <span className="opacity-50">Select a group...</span>;
                        }}
                    >
                        <MenuItem disabled={selected_control_group_id === -1} value={-1}>
                            {loading ? (
                                <span className="flex items-center space-x-2 opacity-50">
                                    <LoadingIndicator size={12} />
                                    <span>Loading groups...</span>
                                </span>
                            ) : (
                                <span>
                                    {selected_control_group_id !== -1 ? 'Clear selection' : 'Select a group...'}
                                </span>
                            )}
                        </MenuItem>
                        {controlGroupMatches && <Divider />}
                        {availableGroups.map(({ display_name, id, sample_id_count }, i) => (
                            <MenuItem
                                sx={{
                                    root: classes.rootItem,
                                    selected: classes.selectedTarget,
                                }}
                                key={`target_${i}`}
                                value={id}
                                disabled={
                                    id === selected_experimental_group_id ||
                                    sample_id_count < RNASEQ_GROUP_SAMPLE_COUNT_THRESHOLD
                                }
                            >
                                <div className="flex flex-grow items-start justify-between whitespace-normal">
                                    <div className="flex flex-col">
                                        <span className="font-semibold">{display_name}</span>
                                        <span className="text-sm">
                                            {sample_id_count === 1
                                                ? `${sample_id_count} sample`
                                                : `${sample_id_count} samples`}
                                        </span>
                                    </div>
                                    {id === selected_experimental_group_id && (
                                        <span className="flex grow-0 items-center justify-center rounded-lg bg-gray-200 px-2 py-1 text-sm">
                                            Experimental
                                        </span>
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormikFieldError name="control_group_id" />
                </FormControl>
            </div>
        </div>
    );
};

export default DifferentialGroupsPickerFields;
