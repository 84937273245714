import { AnalysisGroup } from '@models/AnalysisParameters';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';

export const MAX_TARGETS = 5;

export interface LongitudinalAnalysis extends AssaySummaryAnalysis<string> {
    analysis_type: 'longitudinal';
    variables: number[];
    groups: AnalysisGroup[];
    x_axis_variable_id: number;
}
