import CoverageAnalysisMethodsSection from '@components/experiments/methods/analyses/CoverageAnalysisMethodsSection';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';
import CodeBlockInline from '@components/CodeBlockInline';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { PlotStatistic } from '@models/analysis/CoverageAnalysis';
import { formattedStatisticsNames } from '@components/experiments/methods/displays/ProfilePlotMethods';
import TornadoPlotDisplayOption from '@models/plotDisplayOption/TornadoPlotDisplayOption';

type Props = { plot: Plot; experiment: Experiment };
const TornadoPlotMethods = ({ plot, experiment }: Props) => {
    const display = plot.display as TornadoPlotDisplayOption | null;
    const plotStatistic = display?.plot_statistic as PlotStatistic;
    const plotStatisticName = formattedStatisticsNames[plotStatistic];
    const plotsToShow = display?.plots_to_show;
    const showPlotStatisticInfo = plotsToShow === 'phc' || plotsToShow === 'ph';

    return (
        <>
            <CoverageAnalysisMethodsSection plot={plot} experiment={experiment} />
            <section>
                <MethodsSectionTitle>Tornado plot / heatmap</MethodsSectionTitle>
                <span>
                    Tornado plot (heatmap) was generated using the <CodeBlockInline>deepTools</CodeBlockInline>{' '}
                    <CodeBlockInline>plotHeatmap()</CodeBlockInline> function.
                </span>{' '}
                {showPlotStatisticInfo && (
                    <span>
                        The genome-wide signal profile <Bold>{plotStatisticName}</Bold> for each sample was used to
                        generate the profile plot.
                    </span>
                )}
            </section>
        </>
    );
};

export default TornadoPlotMethods;
