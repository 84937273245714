import DataTable from '@components/dataTable/DataTable';
import LoadingMessage from '@components/LoadingMessage';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import DownloadDataButton from '@components/DownloadDataButton';
import Experiment from '@models/Experiment';
import { generateResultDataFileName } from '@util/ExperimentUtil';
import { PipelineStatus } from '@models/ExperimentData';
import { geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import PreprocessStep, { ResultData } from '@models/PreprocessStep';
import cn from 'classnames';

const StatusMessage: FunctionComponent<{
    pipelineStatus?: PipelineStatus;
    title?: string | null;
    error?: { code?: string } | null;
}> = ({ error, pipelineStatus, title }) => {
    if (error) {
        return (
            <Alert severity="error" className="mx-auto max-w-lg">
                <div className="">Failed to fetch results</div>
            </Alert>
        );
    }

    if (pipelineStatus === 'in_progress') {
        return (
            <div>
                <LoadingMessage>
                    <div className="animate-pulse">
                        {title && (
                            <div className="text-center">
                                Results for <span className="font-semibold">{title}</span> is currently processing
                            </div>
                        )}
                        {!title && <div className="text-center">Results data is currently processing</div>}
                    </div>
                </LoadingMessage>
            </div>
        );
    }

    if (pipelineStatus === 'failed' || error) {
        return (
            <Alert severity="error" className="mx-auto max-w-lg">
                <div>{pipelineStatus === 'failed' ? 'Failed to process results' : 'Failed to fetch results'}</div>
            </Alert>
        );
    }

    if (title) {
        return (
            <div className="!mt-0 text-center">
                Results for <span className="font-semibold">{title}</span> will be available once your preprocess has
                been set up
            </div>
        );
    }

    return <div className="!mt-0 text-center">Results will be available here once the preprocess has been run</div>;
};

export type Props = {
    experiment: Experiment;
    selectedPreprocess: PreprocessStep | null;
    data: ResultData | null;
    loading: boolean;

    error: { code?: string } | null | undefined;
};
const PreprocessResultsDataTable = ({ experiment, selectedPreprocess, data, loading, error }: Props) => {
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortedData, setSortedData] = useState<ResultData | null>(null);
    const [sorting, setSorting] = useState<boolean>(false);
    const [sortDesc, setSortDesc] = useState(false);
    const downloadFilename = data ? generateResultDataFileName({ experiment, data }) : '';
    const downloadEnabled = Boolean(data?.download_url);
    const pipelineStatus = (selectedPreprocess?.pipeline_status ?? 'completed') as PipelineStatus;
    const title = data?.display_name;

    const handleResultSort = async (colId, desc: boolean) => {
        if (!sortedData || !sortedData.items) return;

        setSorting(true);
        const sortedItems = await [...sortedData.items].sort((a, b) => {
            const aVal = a[colId];
            const bVal = b[colId];
            if (typeof aVal === 'string' || typeof bVal === 'string')
                return desc
                    ? aVal.toString().localeCompare(bVal.toString())
                    : bVal.toString().localeCompare(aVal.toString());
            return desc ? Number(bVal) - Number(aVal) : Number(aVal) - Number(bVal);
        });
        const newData = { ...sortedData, items: sortedItems };

        setSorting(false);
        setSortedData(newData);
    };

    useEffect(() => {
        if (!data) return;
        setSortedData(data);
    }, [data]);

    useEffect(() => {
        handleResultSort(sortBy, sortDesc);
    }, [sortBy, sortDesc]);

    if (!!loading) {
        return <LoadingMessage message="Loading results..." size={24} />;
    }
    if (!sortedData) {
        return <StatusMessage pipelineStatus={pipelineStatus} title={title} error={error} />;
    }

    return (
        <div className="relative mb-4 space-y-4">
            <div className="absolute right-16 top-1">
                {downloadEnabled && (
                    <div className="rounded-full bg-white">
                        <DownloadDataButton
                            baseFilename={downloadFilename}
                            downloadUrl={sortedData.download_url}
                            color="secondary"
                        />
                    </div>
                )}
            </div>
            <div
                className={cn('w-full', {
                    'bg-white opacity-50': !!sorting,
                })}
            >
                <DataTable
                    isSorting={sorting}
                    data={sortedData}
                    tableColor="bg-cyan-100 text-cyan-900"
                    key={sortedData.uuid ?? 'no_data'}
                    cellRenderer={geneLinkCellRenderer({ experiment })}
                    onSortedChange={(header, desc) => {
                        setSortBy(header);
                        setSortDesc(desc ?? false);
                    }}
                    sortBy={sortBy ?? undefined}
                    manualSortBy
                    sortable
                    filterable
                    sortDesc={sortDesc}
                    pageSize={250}
                />
            </div>
        </div>
    );
};
export default PreprocessResultsDataTable;
