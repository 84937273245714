import Experiment from '@models/Experiment';
import { ExperimentTypeShortname } from '@models/ExperimentType';
import RNASeqPipelineMethods from '@components/experiments/pipelineMethods/types/RNASeqPipelineMethods';
import CutAndRunPipelineMethods from '@components/experiments/pipelineMethods/types/CutAndRunPipelineMethods';
import ChipSeqPipelineMethods from '@components/experiments/pipelineMethods/types/ChipSeqPipelineMethods';
import ATACSeqPipelineMethods from '@components/experiments/pipelineMethods/types/ATACSeqPipelineMethods';
import useExperimentPipelineRuns from '@hooks/useExperimentPipelineRuns';
import LoadingMessage from '@components/LoadingMessage';
import SCRNASeqPipelineMethods from './types/SCRNASeqPipelineMethods';

const supportedExperimentTypes: ExperimentTypeShortname[] = [
    ExperimentTypeShortname.rnaseq,
    ExperimentTypeShortname.cutandrun,
    ExperimentTypeShortname.chipseq,
    ExperimentTypeShortname.atacseq,
    ExperimentTypeShortname.scrnaseq,
];
export const isPipelineMethodsSupported = (experiment: Experiment) => {
    const type = experiment.type.shortname;
    return supportedExperimentTypes.includes(type);
};

type Props = { experiment: Experiment };
const PipelineMethodsView = ({ experiment }: Props) => {
    const runs = useExperimentPipelineRuns({ experiment });

    if (runs.loading) {
        return <LoadingMessage immediate message="Loading..." />;
    }

    const activeRun = runs.data?.find((r) => experiment.pipeline_run?.uuid === r.uuid);

    if (!activeRun) {
        return <div className="font-semibold">No active pipeline run found for this experiment</div>;
    }

    switch (experiment.type.shortname) {
        case ExperimentTypeShortname.rnaseq:
            return <RNASeqPipelineMethods experiment={experiment} pipelineRun={activeRun} />;
        case ExperimentTypeShortname.cutandrun:
            return <CutAndRunPipelineMethods experiment={experiment} pipelineRun={activeRun} />;
        case ExperimentTypeShortname.chipseq:
            return <ChipSeqPipelineMethods experiment={experiment} pipelineRun={activeRun} />;
        case ExperimentTypeShortname.atacseq:
            return <ATACSeqPipelineMethods experiment={experiment} pipelineRun={activeRun} />;
        case ExperimentTypeShortname.scrnaseq:
            return <SCRNASeqPipelineMethods experiment={experiment} pipelineRun={activeRun} />;
        default:
            return <div className="font-semibold">No pipeline methods found for this experiment</div>;
    }
};

export default PipelineMethodsView;
