import { IconProps } from '@components/icons/CustomIcons';

export const OtherIcon = (props?: IconProps) => {
    const { width = 18, height = 18, className } = { ...props };
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            strokeWidth={props?.strokeWidth ?? 2}
        >
            <path
                d="M16.812 11.745l-5-2.745 5-2.745a.844.844 0 00.325-1.162l-.685-1.186a.844.844 0 00-1.169-.3l-4.877 2.957.122-5.702A.844.844 0 009.685 0h-1.37a.844.844 0 00-.844.862l.123 5.702-4.878-2.957a.844.844 0 00-1.168.3L.863 5.093a.844.844 0 00.325 1.162l5 2.745-5 2.745a.844.844 0 00-.325 1.162l.685 1.186a.844.844 0 001.168.3l4.878-2.957-.123 5.702c-.01.473.37.862.844.862h1.37a.844.844 0 00.843-.862l-.122-5.702 4.877 2.957a.844.844 0 001.169-.3l.684-1.186a.844.844 0 00-.324-1.162z"
                fill="currentColor"
            />
        </svg>
    );
};
