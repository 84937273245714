import Plot from '@models/Plot';
import React, { Dispatch, SetStateAction } from 'react';
import MoreMenuIconButton, { isItem, MoreMenuListItem } from '@components/MoreMenuIconButton';
import Experiment from '@models/Experiment';
import { Menu } from '@mui/material';
import useCanvasMenuItems from '@hooks/useCanvasMenuItems';
import { MousePosition } from '@components/plots/PlotTypes';
import { AnyExperimentData } from '@models/ExperimentData';
import { usePlotContext } from '@contexts/PlotContext';

const initialMouseState: MousePosition = {
    mouseX: null,
    mouseY: null,
};

type Props = {
    plot: Plot;
    plotData?: AnyExperimentData | null;
    experiment: Experiment;
    setMethodsOpen: (open: boolean) => void;
    setResultsOpen: (open: boolean) => void;
    ready: boolean;
    mousePosition: MousePosition;
    setMousePosition: Dispatch<SetStateAction<MousePosition>>;
    hideEditButton?: boolean;
    showExperimentLink?: boolean;
    disableDownloadLinks?: boolean;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    setFullScreen?: (full: boolean) => void;
    publicKey?: string | null;
};
const PlotCardMenuButton = ({
    plot,
    plotData,
    experiment,
    setMethodsOpen,
    setResultsOpen,
    ready,
    mousePosition,
    setMousePosition,
    hideEditButton,
    disableDownloadLinks,
    showExperimentLink,
    onMenuOpen,
    onMenuClose,
    publicKey,
}: Props) => {
    const plotContext = usePlotContext();
    const { menuItems } = useCanvasMenuItems({
        plot,
        experiment,
        setMethodsOpen,
        setResultsOpen,
        ready,
        hideEditButton,
        showExperimentLink,
        disableDownloadLinks,
        plotData,
        setFullScreen: plotContext.setFullscreen,
        publicKey,
    });

    return (
        <>
            <div>
                <MoreMenuIconButton
                    items={menuItems}
                    className="z-10"
                    size="small"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                />
            </div>
            <Menu
                id="context-menu"
                keepMounted
                open={mousePosition.mouseY !== null}
                onClose={() => setMousePosition(initialMouseState)}
                anchorReference="anchorPosition"
                anchorPosition={
                    mousePosition.mouseY !== null && mousePosition.mouseX !== null
                        ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
                        : undefined
                }
            >
                {menuItems.map((item, i) =>
                    isItem(item) ? (
                        <MoreMenuListItem
                            key={`menu_item_${i}`}
                            item={item}
                            handleClose={() => setMousePosition(initialMouseState)}
                        />
                    ) : (
                        <div key={`menu_item_${i}_item`}>{item}</div>
                    ),
                )}
            </Menu>
        </>
    );
};

export default PlotCardMenuButton;
