import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { Organization } from '@models/Organization';
import { PublicKey } from '@models/PublicKey';
import { Dialog, DialogContent, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CopyTextInput from '@components/forms/CopyTextInput';
import copy from '@copy/Copy';
import { PlotOverrideSummary } from '@contexts/ExperimentDetailViewContext';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import Button from '@components/Button';
import useFileDownloader from '@hooks/useFileDownloader';
import { generatePlotFileName } from '@util/ExperimentUtil';
import BenchlingExportDialogForm from '@components/experiments/benchling/BenchlingExportDialogForm';
import BenchlingIcon from '@components/icons/custom/BenchlingIcon';
import { ArrowLeftIcon, ExclamationIcon } from '@heroicons/react/outline';
import { generatePlotShareUrl } from '@components/plots/PlotUtil';
import BenchlingExperimentMultiExportDialog from '@components/experiments/benchling/BenchlingExperimentMultiExportDialog';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import PlotItem from './experiments/benchling/PlotItem';
import useExperimentPermissions from '../hooks/useExperimentPermissions';
import useAuth from '../hooks/useAuth';
import useSWR from 'swr';
import cn from 'classnames';

// container to show either multiple plot selection ui or single plot
const PlotEmbedModalContainer = ({
    plot,
    open,
    onClose,
    experiment,
    publicationMode,
    overrides,
    refreshPlotById,
}: Props) => {
    const [showMultiExportModal, setShowMultiExportModal] = useState(false);
    const [multiModalOpen, setMultiModalOpen] = useState(true);

    const handleMultiClose = () => {
        // timeout included to smoothly close both popups without ui lag
        setTimeout(() => {
            setMultiModalOpen(false);
            setShowMultiExportModal(false);
        }, 200);
        onClose();
    };

    const handleMultiOpen = () => {
        setShowMultiExportModal(true);
        setMultiModalOpen(true);
    };

    return (
        <>
            <PlotEmbedModal
                plot={plot}
                open={open}
                onClose={onClose}
                experiment={experiment}
                publicationMode={publicationMode}
                overrides={overrides}
                showMultiModal={handleMultiOpen}
                refreshPlotById={refreshPlotById}
            />
            {showMultiExportModal ? (
                <BenchlingExperimentMultiExportDialog
                    experiment={experiment}
                    open={multiModalOpen}
                    onClose={handleMultiClose}
                />
            ) : null}
        </>
    );
};

type Props = {
    plot: Plot | null;
    experiment: Experiment;
    open: boolean;
    onClose: () => void;
    publicationMode?: boolean;
    overrides?: PlotOverrideSummary | null;
    showMultiModal?: () => void;
    refreshPlotById: (id: string) => Promise<void>;
};
const PlotEmbedModal = ({
    plot: initialPlot,
    open,
    onClose,
    experiment,
    publicationMode: initialPublicationMode,
    overrides,
    showMultiModal,
    refreshPlotById,
}: Props) => {
    const [publicationMode, setPublicationMode] = useState(initialPublicationMode);
    const [showBenchling, setShowBenchling] = useState(false);
    const [makeHtml, setMakeHtml] = useState(false);
    const [makeCsv, setMakeCsv] = useState(false);
    const [publicLoading, setPublicLoading] = useState(false);
    const [publicSuccess, setPublicSuccess] = useState(false);
    const [publicKey, setPublicKey] = useState<PublicKey>();
    const [plot, setPlot] = useState<Plot | null>(initialPlot);
    const { createPlutoEmbedHTMLFile, createFileFromUrl } = useFileDownloader();
    const { features, canEdit } = useExperimentPermissions(experiment);
    const { user, authContext } = useAuth();

    const { data: organization } = useSWR<Organization>(() =>
        user?.organization ? Endpoints.organization.base({ organizationId: user?.organization?.uuid }) : null,
    );
    const api = useApi();

    useEffect(() => {
        if (open) {
            setShowBenchling(false);
            setPlot(initialPlot);
        }
        setPublicLoading(false);
        setPublicSuccess(false);
    }, [open]);

    useEffect(() => {
        if (!plot || !experiment) return;

        const fetchPublicKey = async () => {
            const response: PublicKey = await api.post(
                Endpoints.lab.experiment.plot.publicKey({ experimentId: experiment.uuid, plotId: plot.uuid }),
            );
            setPublicKey(response);
        };

        fetchPublicKey();
    }, [plot, experiment]);

    const generateShareUrl = () => {
        if (!plot || !publicKey) {
            return '';
        }
        return generatePlotShareUrl({
            plot,
            experiment,
            overrides,
            publicationMode,
            publicKey: publicKey.key,
            orgSlug: authContext.orgSlug,
        });
    };

    const iframeString = `<iframe src="${generateShareUrl()}" frameborder="none" style="border: none" width="600" height="600"></iframe>`;

    const handleMakeFile = async () => {
        const files: File[] = [];
        if (makeCsv && !!plot) {
            const response: { url: string } = await api.get(
                Endpoints.lab.experiment.downloadPlotData({ experimentId: experiment.uuid, plotId: plot.uuid }),
            );
            const file = await createFileFromUrl({
                url: response.url,
                filename: `${generatePlotFileName({ plot, experiment })}.csv`,
                metadata: { type: 'text/csv' },
            });
            files.push(file);
        }
        if (makeHtml) {
            const file = await createPlutoEmbedHTMLFile({
                filename: `${generatePlotFileName({ plot, experiment })}.html`,
                title: 'Pluto plot embed for Benchling',
                src: generateShareUrl(),
            });
            files.push(file);
        }
        return files;
    };

    const renderPlotItem = () => {
        if (!plot) return <></>;
        return (
            <>
                <div className="flex flex-row justify-between">
                    <p className="text-md font-semibold">Pluto results to export</p>
                    <div className="mr-4 mt-2 flex w-80 flex-row justify-between">
                        <p className="mb-1 text-xs font-semibold">Interactive plot (HTML)</p>
                        <p className="mb-1 text-xs font-semibold">Plot data (CSV)</p>
                    </div>
                </div>
                <PlotItem
                    key={plot.uuid}
                    plotId={plot.uuid}
                    experimentId={experiment.uuid}
                    onChange={(selected, label) => {
                        if (label === 'html') return setMakeHtml(selected);
                        return setMakeCsv(selected);
                    }}
                    csvSelected={makeCsv}
                    htmlSelected={makeHtml}
                    analysisType={plot.analysis_type}
                />
                <p className="mt-2">
                    or<a onClick={showMultiModal}> export multiple results at once</a>
                </p>
            </>
        );
    };

    const renderHelpText = () => (
        <p className="mb-4 text-lg">
            Create an analysis in Benchling and export this result.{' '}
            <a href={copy.exportToBenchlingHelpUrl} target="_blank" rel="noreferrer nofollow noopen">
                Watch a video to learn more
            </a>
        </p>
    );

    const handleClose = () => {
        setMakeCsv(false);
        setMakeHtml(false);
        onClose();
    };

    const handlePublicChange = async (newPublicState: boolean) => {
        if (!plot || !!publicLoading) return;
        const newShareLevel = !!newPublicState ? 'public' : 'private';
        setPublicLoading(true);
        setPublicSuccess(false);
        try {
            const newPlot: Plot = await api.put(
                Endpoints.lab.experiment.plot.base({ experimentId: experiment.uuid, plotId: plot.uuid }),
                {
                    ...plot,
                    share_level: newShareLevel,
                },
            );
            await refreshPlotById(plot.uuid);
            setPlot(newPlot);
            setPublicSuccess(true);
            setTimeout(() => {
                setPublicSuccess(false);
            }, 5000);
        } catch (error) {
            return console.log('Failed to update public plot settings:', error);
        } finally {
            setPublicLoading(false);
        }
    };

    const isPublic = plot?.share_level === 'public';
    const isPrivate = plot?.share_level === 'private';
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" key="base">
            <DialogCloseButton onClose={handleClose} />
            <PlutoDialogTitle title={showBenchling ? 'Export to Benchling' : 'Embed plot'} />
            {showBenchling ? (
                <>
                    <DialogContent>
                        <Button
                            onClick={() => setShowBenchling(false)}
                            startIcon={<ArrowLeftIcon width={12} />}
                            color="primary"
                            variant="text"
                        >
                            Embed options
                        </Button>
                        <BenchlingExportDialogForm
                            key="benchling"
                            makeFile={handleMakeFile}
                            experiment={experiment}
                            renderPlotItem={renderPlotItem}
                            helpText={renderHelpText}
                        />
                    </DialogContent>
                </>
            ) : (
                <DialogContent>
                    <div className="space-y-6">
                        <p className="text-lg">
                            Embed this plot into a wide variety of other cloud-based tools and websites your team uses.{' '}
                            <a href={copy.embedPlotHelpUrl} target="_blank" rel="noreferrer">
                                Learn&nbsp;more
                            </a>
                        </p>

                        <div className="flex flex-col space-y-2">
                            <p className="font-semibold text-default">Plot style</p>
                            <div className="flex space-x-2">
                                <div
                                    className={cn('cursor-pointer rounded-md border border-indigo-200 px-3 py-2', {
                                        'bg-indigo-100': !publicationMode,
                                    })}
                                    onClick={() => setPublicationMode(false)}
                                >
                                    <p className="text-neutral-700">Pluto</p>
                                </div>
                                <div
                                    className={cn('cursor-pointer rounded-md border border-indigo-200 px-3 py-2', {
                                        'bg-indigo-100': !!publicationMode,
                                    })}
                                    onClick={() => setPublicationMode(true)}
                                >
                                    <p className="text-neutral-700">Publication</p>
                                </div>
                            </div>
                        </div>
                        {!!organization?.public_plots_enabled && !!canEdit ? (
                            <div className="flex flex-col space-y-2">
                                <div className="flex space-x-1">
                                    <p className="font-semibold text-default">Access</p>
                                    <Tooltip
                                        title="Be careful, turning on this setting allows anyone with the link to view this plot"
                                        arrow
                                        placement="right"
                                    >
                                        <ExclamationIcon width={22} className="ml-1 text-primary" />
                                    </Tooltip>
                                    {publicLoading ? <p className="font-semibold">Updating...</p> : null}
                                    {publicSuccess ? (
                                        <p className="font-semibold text-teal-500">Link settings updated!</p>
                                    ) : null}
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        className={cn('cursor-pointer rounded-md border border-indigo-200 px-3 py-2', {
                                            'bg-indigo-100': !!isPrivate,
                                        })}
                                        onClick={() => handlePublicChange(false)}
                                    >
                                        <p className="text-neutral-700">Restricted - login required</p>
                                    </div>
                                    <div
                                        className={cn('cursor-pointer rounded-md border border-indigo-200 px-3 py-2', {
                                            'bg-indigo-100': !!isPublic,
                                        })}
                                        onClick={() => handlePublicChange(true)}
                                    >
                                        <p className="text-neutral-700">Shareable with link</p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="space-y-2">
                            <p className="font-semibold text-default">Embed snippet</p>
                            <CopyTextInput value={iframeString} inputClasses="font-mono !text-xs" />
                        </div>

                        <div className="space-y-2">
                            <p className="font-semibold text-default">Direct link</p>
                            <CopyTextInput value={generateShareUrl()} inputClasses="font-mono !text-xs" />
                        </div>
                        {!!features?.benchling_enabled && (
                            <div className="space-y-2">
                                <p className="font-semibold text-default">Benchling</p>
                                <div className="flex flex-row items-center">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setShowBenchling(true)}
                                        startIcon={<BenchlingIcon width={18} height={18} className="text-primary" />}
                                    >
                                        Export to Benchling
                                    </Button>
                                    <p className="ml-4">
                                        or
                                        <a onClick={showMultiModal}> select multiple results </a>
                                        to export
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default PlotEmbedModalContainer;
