import PlutoMarkdownContentView from '@/src/components/PlutoMarkdownContentView';
import { SpreadsheetAnalysis } from '@/src/models/analysis/SpreadsheetAnalysis';
import Plot from '@models/Plot';
import { isNotBlank } from '@util/StringUtil';
import React from 'react';

type Props = { plot: Plot };
const SpreadsheetPlotMethods = ({ plot }: Props) => {
    const analysis = plot.analysis as SpreadsheetAnalysis | null;
    const methods: string | null = analysis?.methods ?? null;

    if (methods && isNotBlank(methods)) return <PlutoMarkdownContentView content={methods} />;
    return <div>No methods have been provided.</div>;
};

export default SpreadsheetPlotMethods;
