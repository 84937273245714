import { BaseExperimentAnalysis, PlotMetadata } from '@models/analysis/ExperimentAnalysis';
import { DifferentialExpressionAnalysisInfo } from '@models/analysis/DifferentialExpressionAnalysis';
import { GeneSetInfo } from '@models/GeneSet';
import { RankMethod } from '@models/analysis/RankMethod';

export const DefaultGeneSetSize = {
    min: 5,
    max: 1000,
};

export interface GeneSetEnrichmentAnalysis extends BaseExperimentAnalysis {
    differential_analysis: DifferentialExpressionAnalysisInfo | null;
    gene_set_collections: number[];
    gene_set: GeneSetInfo | null;
    min_gene_set_size: number;
    max_gene_set_size: number;
    rank_method: RankMethod;
    plot_metadata?: PlotMetadata;
}
