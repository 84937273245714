import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Logger from '@util/Logger';
import { BYTES_IN_MB } from '@/src/util/StringUtil';
import { defaultFileTypes } from './ResumableFileUploader';
import { CheckCircleIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import Button from '@components/Button';
import { DocumentAddIcon, ExclamationIcon } from '@heroicons/react/outline';
import useFileHandler from '@/src/hooks/useFileHandler';
import { XIcon } from '@heroicons/react/solid';
import { IconButton } from '@mui/material';

const logger = Logger.make('Dropzone');

const CustomDropzone = forwardRef(
    (
        {
            acceptFileTypes = defaultFileTypes,
            chooseFileText = 'Choose file(s)',
            existingFileNames,
            handleUploadProgrammatically,
            maxFiles,
            maxSize,
            noClick,
            noKeyboard,
            onFilesChanged,
            showSuccess,
            uploadDisabled,
            uploadHeader = 'Upload files',
            uploadSubheader,
        }: {
            acceptFileTypes?: Accept;
            chooseFileText?: string;
            existingFileNames?: string[];
            handleUploadProgrammatically?: (setFiles: React.Dispatch<React.SetStateAction<File[] | null>>) => void;
            maxFiles?: number;
            maxSize?: number;
            noClick?: boolean;
            noKeyboard?: boolean;
            onFilesChanged?: (files: File[] | null) => void;
            showSuccess?: boolean;
            uploadDisabled?: boolean;
            uploadHeader?: string;
            uploadSubheader?: string;
        },
        ref,
    ) => {
        const { files, fileErrors, hasFiles, removeFile, onDrop, clearErrors, setFiles } = useFileHandler({
            onFilesChanged,
        });
        useImperativeHandle(ref, () => () => {
            handleUploadProgrammatically?.(setFiles);
        });

        const dropzone = useDropzone({
            onDrop,
            noClick: noClick ?? false,
            noKeyboard: noKeyboard ?? true,
            disabled: false,
            accept: acceptFileTypes,
            maxFiles: maxFiles ?? 1,
            maxSize: maxSize ?? BYTES_IN_MB * 100,
            multiple: (maxFiles ?? 1) > 1 ? true : false,
        });

        const isDragActive = dropzone.isDragActive;

        const handleFileRemoved = async (file: File) => {
            removeFile(file);
            clearErrors();
            logger.info('File removed', file);
        };

        // Load existing files to state on mount
        useEffect(() => {
            if ((existingFileNames ?? [])?.length !== (files ?? []).length) {
                existingFileNames?.forEach((fileName) => {
                    const newFile = new File([''], fileName, { type: 'text/plain' });
                    setFiles((current) => {
                        return [...(current ?? []), newFile];
                    });
                });
            }
        }, []);

        const renderFileItem = (file: File, index: number) => (
            <div key={index} className="space-y-2 rounded-lg border border-indigo-100 bg-indigo-50 px-4 py-2">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col space-y-1 pr-4">
                        <p className="break-all font-semibold">{file.name}</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-start">
                            <IconButton
                                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                onClick={() => handleFileRemoved(file)}
                                size="small"
                            >
                                <XIcon width={18} className="text-error" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div
                {...dropzone.getRootProps()}
                className={cn(
                    'max-h-screen-75 w-full cursor-default overflow-auto rounded-lg border-2 border-indigo-600 p-4',
                    {
                        'border-solid bg-yellow-50': isDragActive,
                        'border-dashed': !isDragActive,
                        'border-dashed !border-gray-200': showSuccess,
                    },
                )}
            >
                <input {...dropzone.getInputProps()} />
                <div className="space-y-4">
                    {!hasFiles && (
                        <div className="flex min-h-[100px] items-center justify-center">
                            {!isDragActive && (
                                <div className=" space-y-4 text-center">
                                    <div>
                                        {showSuccess && (
                                            <div className=" m-auto inline-block rounded-full bg-success/75 p-2">
                                                <CheckCircleIcon width={24} className="text-success" />
                                            </div>
                                        )}
                                        <p className="text-lg font-semibold text-dark">
                                            {isDragActive ? 'Drop files here' : uploadHeader}
                                        </p>
                                        <p className={cn({ invisible: isDragActive })}>{uploadSubheader}</p>
                                    </div>
                                    {!showSuccess && (
                                        <div className="flex flex-col items-center">
                                            <div className="flex flex-col items-center space-y-2">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={uploadDisabled}
                                                    onClick={() => {
                                                        dropzone.open();
                                                    }}
                                                >
                                                    {chooseFileText}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isDragActive && (
                                <div>
                                    <div className="flex justify-center">
                                        <DocumentAddIcon width={32} className="text-dark" />
                                    </div>
                                    <p className="text-lg font-semibold text-dark">Drop files here</p>
                                </div>
                            )}
                        </div>
                    )}

                    {fileErrors && (
                        <div>
                            <div className="flex justify-end">
                                <Button onClick={() => clearErrors()} color="primary">
                                    Clear errors
                                </Button>
                            </div>
                            <div className="flex flex-col space-y-4">
                                {fileErrors.map(({ node }, i) => (
                                    <div
                                        key={i}
                                        className="flex space-x-2 rounded-lg border-error bg-error p-4 text-error"
                                    >
                                        <div className="pt-1">
                                            <ExclamationIcon width={18} />
                                        </div>
                                        {node}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {hasFiles && (
                        <div>
                            <p className="mb-1 break-all text-xs font-semibold">
                                Uploaded file{(files ?? []).length > 1 ? 's' : null}:
                            </p>
                            {files?.map((file: File, index) => renderFileItem(file, index))}
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

export default CustomDropzone;
