import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@components/Button';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';

export type Props = {
    onCancel: () => void;
    onConfirm: () => void;
    open?: boolean;
};
const ConfirmSaveAnnotationDialog = ({ onCancel, onConfirm, open = false }: Props) => {
    const { isSmAndDown } = useBreakpoints();
    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogCloseButton onClose={onCancel} />
            <PlutoDialogTitle title="Confirm edits" />
            <DialogContent className="mb-4 space-y-4">
                <div className="space-y-6">
                    <p>
                        If you&apos;ve run any analyses and created plots with these annotations on the{' '}
                        <span className="font-semibold">Analysis</span> tab, we&apos;ll need to regenerate those plots
                        to reflect your newest updates to the cluster annotations.
                    </p>
                    <p>
                        This process may take several minutes, but it will be performed automatically in the background
                        while you continue working in Pluto. The updated plots will re-appear in their original location
                        on the Analysis tab when finished.
                    </p>
                </div>
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button variant={isSmAndDown ? 'outlined' : 'text'} color="primary" onClick={onCancel}>
                    Return to editing
                </Button>
                <Button color="primary" onClick={onConfirm} variant="contained">
                    Apply & update plots
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmSaveAnnotationDialog;
