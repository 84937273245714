import React, { ReactNode } from 'react';
import SimpleSelectField from '@components/experiments/plotDisplay/fields/SimpleSelectField';
import TextInputField from '@components/forms/TextInputField';

type Props = { columnChoices?: string[] | null; loading?: boolean; labelName: ReactNode };
const SampleScatterPlotAxisSelectFieldGroup = ({ columnChoices = [], loading, labelName }: Props) => {
    const items = (columnChoices ?? []).map((opt) => ({ value: opt }));
    return (
        <div className="mb-8 space-y-4">
            <div className="space-y-4">
                <SimpleSelectField
                    name="x_axis_column"
                    loading={loading}
                    items={items}
                    label={
                        <span>
                            <i>x</i>-axis {labelName}
                        </span>
                    }
                />
                <div className="grid grid-cols-2 gap-2">
                    <TextInputField name="x_axis_start" label="Start" noMargin type="number" nullable />
                    <TextInputField name="x_axis_end" label="End" noMargin type="number" nullable />
                </div>
            </div>
            <div className="space-y-4">
                <SimpleSelectField
                    name="y_axis_column"
                    items={items}
                    label={
                        <span>
                            <i>y</i>-axis {labelName}
                        </span>
                    }
                />
                <div className="grid grid-cols-2 gap-2">
                    <TextInputField name="y_axis_start" label="Start" noMargin type="number" nullable />
                    <TextInputField name="y_axis_end" label="End" noMargin type="number" nullable />
                </div>
            </div>
        </div>
    );
};

export default SampleScatterPlotAxisSelectFieldGroup;
