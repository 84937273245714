import { ReactNode } from 'react';

export type RankMethod = 'fold_change' | 'p_value' | 'sign_p_value' | 'fold_change_p_value';

export type RankMethodInfo = {
    rank_method: RankMethod;
    displayName: string;
    displayNameCapitalized: string;
    formattedDisplayName: ReactNode;
    formattedDisplayNameCapitalized: ReactNode;
};
export const allRankMethods: RankMethod[] = ['fold_change', 'p_value', 'sign_p_value', 'fold_change_p_value'];

export const getRankMethodInfo = (rank_method: RankMethod): RankMethodInfo => {
    switch (rank_method) {
        case 'fold_change':
            return {
                rank_method,
                displayName: 'log₂ fold change',
                displayNameCapitalized: 'Log₂ fold change',
                formattedDisplayName: (
                    <span>
                        log<sub>2</sub> fold change
                    </span>
                ),
                formattedDisplayNameCapitalized: (
                    <span>
                        Log<sub>2</sub> fold change
                    </span>
                ),
            };
        case 'p_value':
            return {
                rank_method,
                displayName: '-log₁₀(p-value)',
                displayNameCapitalized: '-Log₁₀(p-value)',
                formattedDisplayName: (
                    <span>
                        -log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
                formattedDisplayNameCapitalized: (
                    <span>
                        -Log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
            };
        case 'sign_p_value':
            return {
                rank_method,
                displayName: 'sign(log₂ fold change) * -log₁₀(p-value)',
                displayNameCapitalized: 'Sign(log₂ fold change) * -log₁₀(p-value)',
                formattedDisplayName: (
                    <span>
                        sign(log<sub>2</sub> fold change) * -log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
                formattedDisplayNameCapitalized: (
                    <span>
                        Sign(log<sub>2</sub> fold change) * -log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
            };
        case 'fold_change_p_value':
            return {
                rank_method,
                displayName: 'log₂ fold change * -log₁₀(p-value)',
                displayNameCapitalized: 'Log₂ fold change * -log₁₀(p-value)',
                formattedDisplayName: (
                    <span>
                        log<sub>2</sub> fold change * -log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
                formattedDisplayNameCapitalized: (
                    <span>
                        Log<sub>2</sub> fold change * -log<sub>10</sub>(<span className="italic">p</span>-value)
                    </span>
                ),
            };
        default:
            return {
                rank_method,
                displayName: 'unknown method',
                displayNameCapitalized: 'Unknown method',
                formattedDisplayName: 'unknown method',
                formattedDisplayNameCapitalized: 'Unknown method',
            };
    }
};

export const allRankMethodInfos: RankMethodInfo[] = allRankMethods.map((rank_method) => getRankMethodInfo(rank_method));
