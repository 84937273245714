import Logger from '@util/Logger';
import { DEGData, DEGSample } from '@models/ExperimentData';
import VolcanoPlotDisplayOption from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { isDefined } from '@util/TypeGuards';

const logger = Logger.make('VolcanoPlotUtil');

export type AxisStats = {
    max: number;
    min: number;
};
export type DataPoint = { x: number; y: number } & DEGSample;
/**
 * For a given sample, calculate the Y Axis value using the formula `-log10(adjPValue)`.
 * To prevent Infinity from returning when the adjPValue is Zero, we can pass in an optional value to use instead.
 * This `valueIfZero` should be equal to the next-smallest value from the sample set that is non-zero, as it represents the next largest change in effect
 * @param {DEGSample} d the sample for which to perform the calculation
 * @param {number} [valueIfZero] the value to use when the Adjusted_P_Value is zero.
 * @return {number}
 */
const calculateSampleY = (d: DEGSample, valueIfZero?: number): number => {
    let adjPValue = d.Adj_P_Value;
    if (adjPValue === 0 && valueIfZero) {
        adjPValue = valueIfZero;
    } else if (adjPValue === 0) {
        logger.warn('No valueIfZero provided for calculating the max sample Y when the Adjusted P value is zero');
    }
    return -1 * Math.log10(adjPValue);
};

const calculateSampleX = (d: DEGSample): number => {
    return d.Log2_Fold_Change;
};

/**
 * When you’re ready to do the volcano plot, it will be a scatter plot with the Log2_Fold_Change values on the x axis.
 * For the y-axis, you’ll calculate -1*log10(Adj_P_Value column).
 * Points with Log2_Fold_Change > 0 should be one color, and < 0 a different color.
 * @param {DEGData} data
 * @param {VolcanoPlotDisplayOption} display
 * @return {DataPoint[]}
 */
export const prepareDEGData = (
    data: DEGData,
    display: VolcanoPlotDisplayOption,
): {
    x: AxisStats;
    y: AxisStats;
    items: DataPoint[];
} | null => {
    const [first] = data.items;
    if (!first) {
        return null;
    }

    /** Because the data is returned sorted, we can just use the first item in the list to get the largest value */
    const maxAdjustedPValue = data.items.find((d) => d.Adj_P_Value > 0)?.Adj_P_Value;

    /** only get the items in the range of the configured axis ranges */
    const filteredItems = data.items.filter((d) => {
        const xValue = calculateSampleX(d);
        const yValue = calculateSampleY(d, maxAdjustedPValue);
        return !(
            (isDefined(display.x_axis_start) && xValue < display.x_axis_start) ||
            (isDefined(display.x_axis_end) && xValue > display.x_axis_end) ||
            (isDefined(display.y_axis_start) && yValue < display.y_axis_start) ||
            (isDefined(display.y_axis_end) && yValue > display.y_axis_end)
        );
    });

    const xStats: AxisStats = {
        min: display?.x_axis_start ?? Math.min(0, calculateSampleX(first)),
        max: display.x_axis_end ?? Math.max(0, calculateSampleX(first)),
    };
    const yStats: AxisStats = {
        min: display?.y_axis_start ?? Math.min(0, calculateSampleY(first, maxAdjustedPValue)),
        max: display?.y_axis_end ?? Math.max(0, calculateSampleY(first, maxAdjustedPValue)),
    };

    const items = filteredItems.map((d) => {
        const point = { ...d, x: d.Log2_Fold_Change, y: calculateSampleY(d, maxAdjustedPValue) };
        if (point.x > xStats.max) {
            xStats.max = point.x;
        }
        if (point.x < xStats.min) {
            xStats.min = point.x;
        }

        if (point.y > yStats.max) {
            yStats.max = point.y;
        }
        if (point.y < yStats.min) {
            yStats.min = point.y;
        }
        return point;
    });

    return { items, x: xStats, y: yStats };
};
