import { useField } from 'formik';
import React, { ReactNode } from 'react';
import { Divider, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { blankToNull } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { FormikFieldError } from '@components/forms/FieldError';

const NONE_VALUE = 'NONE';

const styles = {
    wrap: {
        whiteSpace: 'normal',
    },
};

export type SelectItem<T extends string | number = string | number> = { value: T; label: ReactNode };

type Props = {
    className?: string;
    disabled?: boolean;
    label?: ReactNode;
    name: string;
    onChange?: (e: SelectChangeEvent<string | number>) => void;
    options: SelectItem[];
    showNone?: boolean;
    type?: 'string' | 'number';
    variant?: 'outlined' | 'standard' | 'filled' | undefined;
};
const SingleSelectField = ({
    className,
    disabled,
    label,
    name,
    onChange,
    options,
    showNone,
    type = 'string',
    variant = 'outlined',
}: Props) => {
    const [inputProps, meta, helpers] = useField<string | number | null>(name);
    const { error, touched } = meta;
    const value = type === 'number' && isDefined(inputProps.value) ? Number(inputProps.value) : inputProps.value;
    const hasError = !!error && touched;
    return (
        <label className={`block ${className}`}>
            {label && <span className="mb-2 block font-semibold tracking-tight">{label}</span>}
            <div className="-mx-1 flex flex-row flex-wrap">
                <FormControl variant="outlined" fullWidth error={hasError}>
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        name={name}
                        value={value ?? NONE_VALUE}
                        onChange={(e) => {
                            onChange?.(e);
                            if (e.target.value === NONE_VALUE) {
                                helpers.setValue(null);
                                return;
                            }
                            const updatedValue = blankToNull(e.target.value as string);
                            if (isDefined(updatedValue)) {
                                if (type === 'number') {
                                    helpers.setValue(Number(updatedValue));
                                } else {
                                    helpers.setValue(updatedValue);
                                }
                            } else {
                                helpers.setValue(null);
                            }
                        }}
                        disabled={disabled}
                        variant={variant}
                        sx={{ selectMenu: styles.wrap }}
                    >
                        {showNone && <MenuItem value={NONE_VALUE}>Any</MenuItem>}
                        {showNone && <Divider />}
                        {options.map((info) => (
                            <MenuItem key={info.value} value={info.value} sx={styles.wrap}>
                                <div className="flex max-w-sm flex-row items-center justify-start space-x-2 whitespace-normal sm:max-w-lg">
                                    {typeof info.label === 'string' ? (
                                        <span className="whitespace-normal">{info.label}</span>
                                    ) : (
                                        info.label
                                    )}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormikFieldError name={name} />
            </div>
        </label>
    );
};

export default SingleSelectField;
