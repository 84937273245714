import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import AnalysisVariablesMultiSelectField from '@components/experiments/analyses/assaySummary/AnalysisVariablesMultiSelectField';
import AnalysisGroupsField from '@components/experiments/analyses/assaySummary/AnalysisGroupsField';
import {
    BaseAnalysisParameters,
    ParameterOption,
    SampleCorrelationAnalysisParameters,
} from '@models/AnalysisParameters';
import { SampleCorrelationAnalysisFormValues } from '@components/experiments/analyses/AnalysisFormTypes';

type Props = {
    plot: Plot;
    analysisParameters: SampleCorrelationAnalysisParameters;
    experiment: Experiment;
};
const SampleCorrelationAnalysisFormFields = ({ experiment, plot, analysisParameters }: Props) => {
    const { values, ...form } = useFormikContext<SampleCorrelationAnalysisFormValues>();
    const [availableGroups, setAvailableGroups] = useState<ParameterOption[]>([]);
    const correlation_method = {
        correlation_method_types: [
            { type: 'spearman', display: 'Spearman (rank-based method)', default: true },
            { type: 'pearson', display: 'Pearson (linear relationships)' },
        ],
    };

    useEffect(() => {
        if (values.group_ids.length > 0 && availableGroups.length > 0) {
            const sampleCount = values.group_ids.reduce((sum, groupId) => {
                const selectedGroup = availableGroups.filter((g) => g.id === groupId);
                if (selectedGroup?.[0]?.sample_id_count) {
                    return sum + selectedGroup[0].sample_id_count;
                }
                return sum;
            }, 0);
            form.setFieldValue('name', `Sample correlation (${sampleCount} samples)`);
        }
    }, [values.group_ids]);

    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Analyze</p>
                <div className="space-y-2">
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            checked={values.include_all_samples}
                            onChange={() => {
                                form.setFieldValue('include_all_samples', true);
                                form.setFieldValue('variables', []);
                                form.setFieldValue('group_ids', []);
                                form.setFieldValue('name', 'Sample correlation (all samples)');
                            }}
                        />
                        <span className="ml-2">Include all samples</span>
                    </label>
                    <label className="block">
                        <input
                            type="radio"
                            className="cursor-pointer text-indigo-500"
                            name="include_all_samples"
                            onChange={() => {
                                form.setFieldValue('include_all_samples', false);
                            }}
                            checked={!values.include_all_samples}
                        />
                        <span className="ml-2">Specific groups of samples</span>
                    </label>
                </div>
                {!values.include_all_samples && (
                    <div className="mt-4">
                        <AnalysisVariablesMultiSelectField
                            variables={(analysisParameters as BaseAnalysisParameters).variables}
                        />
                        <AnalysisGroupsField
                            plot={plot}
                            experiment={experiment}
                            onGroupChange={(availableGroups) => setAvailableGroups(availableGroups)}
                        />
                    </div>
                )}
            </section>
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Correlation method</p>
                <div className="space-y-2">
                    {correlation_method.correlation_method_types.map((method) => (
                        <label key={method.type} className="block">
                            <input
                                type="radio"
                                className="cursor-pointer text-indigo-500"
                                name="method_type"
                                checked={values.method_type === method.type}
                                onChange={() => {
                                    form.setFieldValue('method_type', method.type);
                                }}
                            />
                            <span className="ml-2">{method.display}</span>
                        </label>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default SampleCorrelationAnalysisFormFields;
