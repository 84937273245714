import { useMemo, useRef } from 'react';
import useDebouncedResizeObserver, { ObservedSize } from '@hooks/useDebouncedResizeObserver';
import { isDefined } from '@util/TypeGuards';
import { RectSize } from '@contexts/PlotExportContext';

const DEFAULT_PLOT_SIZE = 500;

//TODO: maybe? don't use aspect ratio here, let the caller set the ratio - although this is working pretty well right now
export const useAspectRatioSizeObserver = (options?: {
    aspectRatio?: number | null;
    noTrailing?: boolean;
    noLeading?: boolean;
    initialSize?: ObservedSize;
    minHeight?: number;
    useDebounce?: boolean;
}) => {
    const { aspectRatio, noTrailing, initialSize, minHeight, useDebounce } = options ?? {};
    const aspectRef = useRef<number | null>(aspectRatio ?? null);
    aspectRef.current = aspectRatio ?? null;
    const svgRef = useRef<SVGSVGElement>(null);
    const { size, ref: resizeRef } = useDebouncedResizeObserver({
        noTrailing,
        noLeading: false,
        wait: 200,
        initialSize,
        useDebounce,
    });

    const aspectSize = useMemo<RectSize | undefined>(() => {
        const aspectRatio = aspectRef.current;
        if (!isDefined(size.height) || !isDefined(size.width)) {
            return undefined;
        }
        const width = size?.width ?? DEFAULT_PLOT_SIZE;
        let height = size?.height ?? DEFAULT_PLOT_SIZE;
        if (isDefined(aspectRatio)) {
            height = width / aspectRatio;
        }
        if (isDefined(minHeight) && height < minHeight) {
            height = minHeight;
        }
        return { height, width };
    }, [size, minHeight]);

    return { svgRef, containerRef: resizeRef, size: aspectSize, containerSize: size };
};
