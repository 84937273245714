import { usePlotContext } from '@contexts/PlotContext';
import LoadingMessage from '@components/LoadingMessage';
import dynamic from 'next/dynamic';
import { PlotParams } from 'react-plotly.js';
import { ArrowPlotData, PlotMapping, SeuratGeneSetSample } from '@models/ExperimentData';
import React, { useEffect, useMemo, useRef } from 'react';
import useOnScreen from '@hooks/useOnScreen';
import { useResizableContainerContext } from '@contexts/ResizableContainerContext';
import Mono from '@components/elements/Mono';
import { useFeatureToggleContext } from '@contexts/FeatureToggleContext';
import Logger from '@util/Logger';
import {
    buildPlotlyLayout,
    getHoverTemplate,
    IndexedDataPoint,
    prepareData,
} from '@components/analysisCategories/comparative/plots/dot/PlotlyDotPlotUtil';
import Button from '@components/Button';
import { TimeoutValue } from '@util/ObjectUtil';
import { ColorScale } from 'plotly.js';
import { DotPlotIcon } from '@components/icons/custom/DotPlotIcon';
import DotPlotDisplayOption from '@models/plotDisplayOption/DotPlotDisplayOption';
import { fractionStrToDecimal } from '@/src/util/StringUtil';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';

const logger = Logger.make('PlotlyDotPlot');
const Plotly = dynamic(() => import('react-plotly.js'), { ssr: false });

const ignoreDotValueAnalyses = ['sample_correlation'];
type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const PlotlyDotPlot = ({ customPlotStylingOptions = null }: Props) => {
    const { size, containerRef: resizeRef } = useResizableContainerContext();
    const timeoutRef = useRef<TimeoutValue | null>(null);
    const featureToggles = useFeatureToggleContext();
    const { onScreen, forceShow } = useOnScreen({ ref: resizeRef, initialOnScreen: true });
    const { plotData: pd, plotDataLoading, plot, publicationMode, isDragging, isExportMode } = usePlotContext();

    const plotData = pd as ArrowPlotData<SeuratGeneSetSample> | null | undefined;

    // this is a bit of a hack to ensure the plot stays visible after dragging has finished.
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            forceShow();
        }, 10);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isDragging]);

    if (plotDataLoading) {
        return <LoadingMessage immediate />;
    }

    if (!plotData) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>No plot data is available</div>
            </div>
        );
    }

    if (!plot || !plot.display) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>Unable to load plot: no plot was found</div>
            </div>
        );
    }

    const display = plot.display as DotPlotDisplayOption;
    const items = plotData.items as SeuratGeneSetSample[];
    const dataMap = plotData.plot_mapping as PlotMapping;
    const preparedItemsMemo = useMemo(() => {
        const { preparedItems } = prepareData({ items, dataMap });
        return preparedItems;
    }, [items]);

    const { data } = useMemo<{
        data: PlotParams['data'] | null;
    }>(() => {
        try {
            const minColor = display?.min_color ?? {};
            const maxColor = display?.max_color ?? {};

            const minMarkerSize = Math.min(
                ...preparedItemsMemo.map((item) => fractionStrToDecimal(item[dataMap.point_size] ?? '')),
            );
            const maxMarkerSize = Math.max(
                ...preparedItemsMemo.map((item) => fractionStrToDecimal(item[dataMap.point_size] ?? '')),
            );
            const maxSizeInPx = 20;
            const medianSizeInPx = 12;
            const minSizeInPx = 2;

            const getMarkerSize = (d: IndexedDataPoint) => {
                // Convert fraction string to decimal
                const decimal = fractionStrToDecimal(d[dataMap.point_size] ?? '');

                // Scale between 0 and 1 based on the original range
                const scaledValue = (decimal - minMarkerSize) / (maxMarkerSize - minMarkerSize);

                // If min and max are the same, return the default max size
                if (isNaN(scaledValue) || ignoreDotValueAnalyses.includes(plot.analysis?.analysis_type ?? '')) {
                    return medianSizeInPx;
                }

                // Scale between minSizeInPx and maxSizeInPx (px)
                const finalScaledValue = scaledValue * (maxSizeInPx - minSizeInPx) + minSizeInPx;

                // Round to whole pixels
                return Math.round(finalScaledValue);
            };
            const getMarkerColor = (d: IndexedDataPoint) => {
                return +d[dataMap.point_value];
            };

            const getColorScale = () => {
                switch (plot?.analysis?.analysis_type) {
                    case 'sample_correlation':
                        return [
                            [0, minColor],
                            [0.5, '#f3f4f6'],
                            [1, maxColor],
                        ];
                    case 'seurat_over_representation':
                    default:
                        return [
                            [0, minColor],
                            [1, maxColor],
                        ];
                }
            };
            const getColorScaleRange = () => {
                switch (plot?.analysis?.analysis_type) {
                    case 'sample_correlation':
                        return { cmin: -1, cmax: 1, cmid: 0 };
                    case 'seurat_over_representation':
                    default:
                        return undefined;
                }
            };

            // Define traces
            const data: PlotParams['data'] = [
                {
                    type: 'scatter',
                    name: '',
                    colorscale: getColorScale() as ColorScale,
                    x: preparedItemsMemo.map((d) => (display?.is_transposed ? d.y : d.x)),
                    y: preparedItemsMemo.map((d) => (display?.is_transposed ? d.x : d.y)),
                    hovertemplate: preparedItemsMemo.map((d) => getHoverTemplate(d, dataMap)),
                    hoverlabel: { align: 'left', bgcolor: '#ffffff' },
                    marker: {
                        symbol: 'circle',
                        color: preparedItemsMemo.map((d) => getMarkerColor(d)),
                        colorscale: getColorScale() as ColorScale,
                        size: preparedItemsMemo.map((d) => getMarkerSize(d)),
                        opacity: 100,
                        line: {
                            color: preparedItemsMemo.map((d) => getMarkerColor(d)),
                            width: 1,
                            colorscale: getColorScale() as ColorScale,
                            ...getColorScaleRange(),
                        },
                        ...getColorScaleRange(),
                    },
                    mode: 'markers',
                    ygap: 2,
                },
            ];

            return { data };
        } catch (error) {
            logger.error(error);
            return { data: null };
        }
    }, [display, publicationMode, plotData, preparedItemsMemo, dataMap]);

    if (!data) {
        logger.warn('no processed data found', data, plotData);
        return null;
    }

    const layout = useMemo(() => {
        const layoutCfg = buildPlotlyLayout({
            size,
            publicationMode,
            isTransposed: display?.is_transposed,
            items: preparedItemsMemo,
            stylingOptions: customPlotStylingOptions ?? undefined,
            analysisShortname: plot.analysis?.analysis_type as AnalysisShortname,
            isExportMode,
        });
        const layout = { ...layoutCfg };
        return layout;
    }, [display, preparedItemsMemo, publicationMode, size, plot.analysis, customPlotStylingOptions]);

    return (
        <>
            <div className="relative flex h-full w-full items-center justify-center">
                {onScreen || isDragging ? (
                    <Plotly
                        data={data}
                        layout={layout}
                        useResizeHandler={false}
                        config={{
                            displayModeBar: false, // TODO: use export mode to conditionally render this
                            autosizable: false,
                        }}
                    />
                ) : (
                    <div className="h-full w-full p-4">
                        <div className="flex h-full w-full flex-col items-center justify-center space-y-4 rounded-lg border border-indigo-100">
                            <div className="flex items-center">
                                <div className="rounded-full bg-indigo-100 p-3 text-indigo-600">
                                    <DotPlotIcon width={32} height={32} />
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => forceShow()} variant="outlined" size="small" color="primary">
                                    Reload plot
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {featureToggles.isEnabled('plot_size_debug') && (
                    <div className="pointer-events-none absolute z-50 flex h-full w-full flex-col items-start justify-start border-2 border-dashed border-emerald-300">
                        <div className="z-50 bg-emerald-300/50 p-2 text-xs">
                            <p>
                                <Mono>PlotlyDotPlot.tsx</Mono>
                            </p>
                            <p>height: {size?.height}px</p>
                            <p>width: {size?.width}px</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PlotlyDotPlot;
