import { Dialog } from '@mui/material';
import EditExperimentDialogForm from '@components/experiments/EditExperimentDialogForm';
import Experiment from '@models/Experiment';
import Button from '@components/Button';
import useExperimentPermissions from '@hooks/useExperimentPermissions';
import useArchiveExperiment from '@hooks/useArchiveExperiment';
import { useState } from 'react';
import ConfirmModal from '@components/ConfirmModal';
import { ArchiveIcon } from '@components/icons/custom/ArchiveIcon';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { StateSetter } from '@contexts/ContextTypes';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    initialProjectId?: string | null;
    experiment: Experiment;
    setHideTooltip?: StateSetter<boolean>;
};
const EditExperimentDialog = ({ open, setOpen, experiment, setHideTooltip }: Props) => {
    const permissions = useExperimentPermissions(experiment);
    const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
    const { archiveExperiment, unArchiveExperiment, archived } = useArchiveExperiment(experiment);
    const handleArchive = async (archived: boolean) => {
        if (!showArchiveConfirm) {
            setShowArchiveConfirm(true);
            return;
        }

        if (archived) {
            await archiveExperiment();
        } else {
            await unArchiveExperiment();
        }
    };

    const closeDialog = () => {
        setOpen(false);
        setHideTooltip?.(false);
    };

    return (
        <>
            <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
                <DialogCloseButton onClose={closeDialog} />

                <PlutoDialogTitle title="Edit experiment" />

                <EditExperimentDialogForm
                    experiment={experiment}
                    submitText="Save & Close"
                    onSaved={closeDialog}
                    actions={
                        <>
                            {permissions.canArchive && (
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => handleArchive(!archived)}
                                    startIcon={<ArchiveIcon />}
                                >
                                    {archived ? 'Un-archive' : 'Archive'}
                                </Button>
                            )}
                        </>
                    }
                />
            </Dialog>
            <ConfirmModal
                open={showArchiveConfirm}
                onConfirm={() => handleArchive(true)}
                onCancel={() => setShowArchiveConfirm(false)}
                title="Archive experiment"
                message={
                    <span>
                        Are you sure you want to archive <span className="font-semibold">{experiment.name}</span>?
                    </span>
                }
                confirmText="Yes, archive"
                cancelText="Cancel"
            />
        </>
    );
};

export default EditExperimentDialog;
