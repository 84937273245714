import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormikFieldError } from '@components/forms/FieldError';
import React from 'react';
import cn from 'classnames';

export type RadioChoice = { value: string; label: string; disabled?: boolean };
type Props = { name: string; label?: string; choices: RadioChoice[]; sectionLabel?: boolean };
const RadioField = ({ name, label, choices, sectionLabel }: Props) => {
    const [inputProps, , helpers] = useField(name);
    return (
        <FormControl className="form-field no-margin w-full" component="fieldset">
            <FormLabel component="legend">
                <p
                    className={cn({
                        'field-label text-sm': !sectionLabel,
                        'mb-2 text-lg font-semibold tracking-tight text-dark': sectionLabel,
                    })}
                >
                    {label}
                </p>
            </FormLabel>
            <FormikFieldError name={name} />

            <div className="mt-1 space-y-4">
                {choices.map(({ label, value, disabled }) => (
                    <div key={value} className="flex items-center">
                        <label
                            className={cn('block text-sm font-medium text-default', {
                                'text-gray-400/80': disabled,
                                'cursor-pointer': !disabled,
                            })}
                        >
                            <input
                                name={inputProps.name}
                                type="radio"
                                className={cn('mr-2 h-4 w-4 border-2 text-indigo-500', {
                                    'border-indigo-500': !disabled,
                                    'border-gray-400/80': disabled,
                                })}
                                checked={inputProps.value === value}
                                disabled={disabled}
                                onChange={() => {
                                    helpers.setValue(value);
                                }}
                            />
                            <span className={cn({ 'text-gray-400/80': disabled })}>{label}</span>
                        </label>
                    </div>
                ))}
            </div>
        </FormControl>
    );
};

export default RadioField;
