import Image from 'next/image';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import ImageDisplayOption from '@models/plotDisplayOption/ImageDisplayOption';
import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { isNotBlank } from '@util/StringUtil';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import Logger from '@util/Logger';
import cn from 'classnames';
import { XIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';

const logger = Logger.make('PlotImageView');

type Props = { plot: Plot; experiment: Experiment; setPlotDragEnabled?: (enabled: boolean) => void };
const ImagePlotView = ({ plot, setPlotDragEnabled }: Props) => {
    const analysis = plot.analysis as ImageAnalysis | null;
    const display = plot.display as ImageDisplayOption | null;
    const [imageError, setImageError] = useState<boolean>(false);
    const imageSource = display?.image_source;
    const imageSourceUrl = display?.image_source_url;
    const hasSource = isNotBlank(imageSource) || isNotBlank(imageSourceUrl);
    const sourceLabel = isNotBlank(imageSource) ? imageSource : imageSourceUrl;
    const [captionExpanded, setCaptionExpanded] = useState(false);
    const hasCaption = isNotBlank(display?.caption);

    useEffect(() => {
        if (captionExpanded) {
            setPlotDragEnabled?.(false);
        } else {
            setPlotDragEnabled?.(true);
        }
    }, [captionExpanded]);

    const handleImageLoadError = (error: unknown) => {
        logger.error(`Image failed to load for analysis_id=${analysis?.uuid}`, error);
        setImageError(true);
    };

    const $imgSourceLink = hasSource && (
        <span>
            Image source:&nbsp;
            {imageSourceUrl ? (
                <a href={imageSourceUrl} target="_blank" rel="nofollow noreferrer">
                    {sourceLabel}
                </a>
            ) : (
                <span>{sourceLabel}</span>
            )}
        </span>
    );

    const isSvg = /\.svg($|\?)/.test(analysis?.image_url || '');

    return (
        <div className="mt-4 flex h-full w-full flex-grow flex-col justify-center">
            {analysis?.image_url && !imageError && (
                <div className="relative mx-auto h-full w-full">
                    {isSvg ? (
                        <img
                            src={analysis.image_url}
                            alt={display?.plot_title ?? analysis?.name ?? 'Plot image'}
                            className="max-h-full max-w-full object-contain"
                            onError={handleImageLoadError}
                        />
                    ) : (
                        <Image
                            src={analysis.image_url}
                            alt={display?.plot_title ?? analysis?.name ?? 'Plot image'}
                            loading="lazy"
                            fill
                            className="object-contain object-center"
                            style={{ maxHeight: 'calc(100vh - 264px)' }}
                            onError={handleImageLoadError}
                        />
                    )}
                </div>
            )}
            {imageError && (
                <div>
                    <Alert severity="error">The image failed to load.</Alert>
                </div>
            )}
            {!analysis?.image_url && (
                <div className="h-full">
                    <p className="text-lg font-semibold">The image will appear here once it has been uploaded.</p>
                </div>
            )}
            {(hasSource || hasCaption) && (
                <div>
                    <div
                        className={cn('mx-auto mt-4 flex w-full max-w-3xl flex-col', {
                            'justify-end': !hasCaption && hasSource,
                            'justify-between': hasCaption && hasSource,
                        })}
                    >
                        {hasCaption && (
                            <div className="flex max-w-full" onClick={() => setCaptionExpanded(!captionExpanded)}>
                                <p className="overflow-hidden text-ellipsis whitespace-nowrap">{display?.caption}</p>
                                {display?.caption && display?.caption?.length > 100 && (
                                    <span className="link">more</span>
                                )}
                            </div>
                        )}
                        {$imgSourceLink}
                    </div>
                    {hasCaption && (
                        <Transition
                            show={captionExpanded}
                            enter="transition-all duration-125"
                            enterFrom="translate-y-full"
                            enterTo="h-full translate-y-0"
                            leave="transition-all duration-125"
                            leaveFrom="h-full translate-y-0"
                            leaveTo="translate-y-full"
                            as="div"
                            className="absolute bottom-0 left-4 right-4 mx-auto mt-4 h-full max-h-[65%] max-w-3xl bg-white"
                        >
                            <span className="absolute -top-5 right-2 z-10">
                                <span
                                    onClick={() => setCaptionExpanded(false)}
                                    className="inline-block flex cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white p-2 text-primary hover:bg-gray-50 active:bg-gray-100"
                                >
                                    <XIcon width={18} />
                                </span>
                            </span>
                            <div className=" h-full space-y-8 overflow-y-scroll rounded-t-xl border border-b-0 border-gray-200 bg-white px-4 py-4 text-dark backdrop-blur-2xl">
                                <p className="whitespace-pre-line">{display?.caption}</p>
                                <div>{$imgSourceLink}</div>
                            </div>
                        </Transition>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImagePlotView;
