import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { getAnalysisParametersHelpers } from '@hooks/useAnalysisParameters';
import React from 'react';
import { isDefined, isString } from '@util/TypeGuards';
import BarPlotDisplayOption from '@models/plotDisplayOption/BarPlotDisplayOption';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import SummaryStatisticsMethodsSection from '@components/experiments/methods/displays/SummaryStatisticsMethodsSection';
import NormalizationCPMMethods from '@components/experiments/methods/displays/NormalizationCPMMethods';
import ScaleMethods from '@components/experiments/methods/displays/ScaleMethods';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import ProseList from '@components/ProseList';
import { ErrorBarMethods } from '@components/experiments/methods/displays/ErrorBarMethods';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null };
const BarPlotMethods = ({ plot, analysisParameters }: Props) => {
    const { getTargetById } = getAnalysisParametersHelpers(analysisParameters);
    const analysis = plot.analysis as AssaySummaryAnalysis;
    const display = plot.display as BarPlotDisplayOption;

    const targetNames =
        analysis?.targets.map((id) => (isString(id) ? id : getTargetById(id)?.display_name)).filter(isDefined) ?? [];
    const $targets = <ProseList items={targetNames} />;
    const groupNames = analysis?.groups?.map((g) => g.display_name).filter(isDefined) ?? [];
    const $groups = <ProseList items={groupNames} />;
    const isNormalized = analysis?.analysis_type === 'assay_summary_cpm_normalized';
    const normalizationMethod = isNormalized ? 'counts per million (CPM)-normalized ' : '';

    return (
        <div className="space-y-6">
            <section>
                <MethodsSectionTitle>Plot</MethodsSectionTitle>

                <div className="space-y-4">
                    <p>
                        Barplot showing the {normalizationMethod}values for {$targets} on the y-axis and {$groups} on
                        the x-axis. <NormalizationCPMMethods analysis_type={analysis?.analysis_type} /> Bars are drawn
                        at the average value for each Group and are colored according to Group.
                        {display.show_error_bars && (
                            <>
                                {' '}
                                <ErrorBarMethods error_bar_option={display.error_bars_option} />
                            </>
                        )}{' '}
                        <ScaleMethods y_axis_scale={display.y_axis_scale} />
                    </p>
                </div>
            </section>
            <SummaryStatisticsMethodsSection />
        </div>
    );
};

export default BarPlotMethods;
