import React, { useCallback } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { getFormSetup } from './AnalysisInputFormUtil';
import Plot from '@/src/models/Plot';
import { ExperimentAnalysisInput } from '@/src/models/analysis/ExperimentAnalysisInput';
import Logger from '@util/Logger';
import { deepEqual, getChangedValues } from '@/src/util/ObjectUtil';
import { useExperimentDetailViewContext } from '@/src/contexts/ExperimentDetailViewContext';
import FormikListener from '@/src/components/forms/FormikListener';

const logger = Logger.make('ExperimentAnalysisInputForm');

// Define the types for the form values and the props
interface AnalysisInputFormProps {
    handleSubmit: (values: any, helpers: any) => void | Promise<any>;
    children: React.ReactNode | ((formikProps: FormikProps<any>) => React.ReactNode);
    plot: Plot;
    input: ExperimentAnalysisInput;
    index: number;
}

const AnalysisInputForm: React.FC<AnalysisInputFormProps> = ({ handleSubmit, children, plot, input, index }) => {
    const { setNestedCurrentChanges, nestedCurrentChanges } = useExperimentDetailViewContext();

    if (!input) {
        return <>{children}</>;
    }

    const setup = getFormSetup({ plot, input });
    const handleOnChange = useCallback(
        (values) => {
            const isEqual = deepEqual(values, setup?.initialValues);
            if (!isEqual) {
                const changedValues = getChangedValues(values, setup?.initialValues);
                if (changedValues.length > 0) {
                    setNestedCurrentChanges(input?.name ? input?.name : `List ${index + 1}`);
                }
            }
        },
        [nestedCurrentChanges, setup?.initialValues],
    );

    if (!setup) {
        logger.warn(`Can not generate a setup object for analysis_type "${plot.analysis_type}"`, {
            plot,
        });
        return <p className="px-8">This type of analysis is not yet supported.</p>;
    }

    return (
        <Formik
            key={input.uuid}
            initialValues={setup.initialValues}
            validationSchema={setup.schema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {(formikProps) => (
                <>
                    <Form className="flex flex-1">
                        {typeof children === 'function' ? children(formikProps) : children}
                    </Form>
                    <FormikListener values={formikProps.values} callback={handleOnChange} />
                </>
            )}
        </Formik>
    );
};

export default AnalysisInputForm;
