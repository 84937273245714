import cn from 'classnames';

type Props = {
    number: string | number;
    className?: any;
};
export const NumberIcon = ({ number, className }: Props) => {
    return (
        <div
            className={cn(
                'flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-400 text-gray-400',
                className,
            )}
        >
            <p className="text-xs font-bold">{number}</p>
        </div>
    );
};
