import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { PlotOverrideSummary } from '@contexts/ExperimentDetailViewContext';
import { AnyExperimentData, PipelineStatusPlotData } from '@models/ExperimentData';
import { AnalysisParameters } from '@models/AnalysisParameters';
import useUUID from '@hooks/useUUID';
import { ContextNotInitializedError } from '@contexts/ContextErrors';
import { KeyedMutator } from 'swr';

export type PlotContextType = {
    plot: Plot;
    experiment: Experiment;
    overrides: PlotOverrideSummary;
    analysisParameters: AnalysisParameters | null | undefined;
    plotData: AnyExperimentData | null | undefined;
    plotDataLoading: boolean;
    plotDataError: unknown | null | undefined;
    plotId: string;
    hideEditButton: boolean;
    isPlotDataValidating: boolean;
    pipelineProcessing: boolean;
    setDragEnabled: (enabled: boolean) => void;
    publicationMode: boolean;
    aspectRatio: number | undefined | null;
    /** If the plot should not be re-rendered on the leading edge of a resize */
    resizeNoLeading?: boolean;
    /** If the plot should not be re-rendered on the trailing edge of a resize */
    resizeNoTrailing?: boolean;
    /** If the plot should display in a full-screen modal */
    fullscreen: boolean;
    setFullscreen: Dispatch<SetStateAction<boolean>>;
    minHeight?: number;
    /** unique identifier for this plot instance on the page */
    idPrefix: string;
    isDragging: boolean;
    isExportMode: boolean;
    disableMaxHeight: boolean;
    mutatePlot: KeyedMutator<Plot | null>;
    /** TRUE if this plot is selected for editing in the experiment sidebar */
    isEditing: boolean;
};

// forcing undefined => PlotContextType so that we don't have to define defaults
export const PlotContext = createContext<PlotContextType>(undefined as unknown as PlotContextType);
PlotContext.displayName = 'PlotContext';
export const usePlotContext = () => {
    const context = useContext(PlotContext);
    if (!context) {
        throw new ContextNotInitializedError({ name: 'PlotContext' });
    }
    return context;
};

export type ProviderProps = PropsWithChildren<{
    plot: Plot;
    experiment: Experiment;
    analysisParameters: AnalysisParameters | null | undefined;
    plotData?: AnyExperimentData | null | undefined;
    plotDataLoading?: boolean;
    plotDataError?: unknown | null;
    plotId: string;
    hideEditButton?: boolean;
    isPlotDataValidating?: boolean;
    overrides: PlotOverrideSummary;
    setDragEnabled: (enabled: boolean) => void;
    publicationMode?: boolean;
    aspectRatio?: number | null;
    resizeNoLeading?: boolean;
    resizeNoTrailing?: boolean;
    minHeight?: number;
    isDragging?: boolean;
    isExportMode?: boolean;
    disableMaxHeight?: boolean;
    mutatePlot: KeyedMutator<Plot | null>;
    isEditing?: boolean;
}>;

export const PlotContextProvider = ({
    plot,
    experiment,
    analysisParameters,
    children,
    plotId,
    hideEditButton = false,
    plotData,
    plotDataLoading = false,
    plotDataError,
    isPlotDataValidating = false,
    overrides,
    setDragEnabled = () => undefined,
    publicationMode = false,
    aspectRatio = null,
    resizeNoLeading,
    resizeNoTrailing,
    minHeight,
    isDragging = false,
    isExportMode = false,
    disableMaxHeight = false,
    mutatePlot,
    isEditing = false,
}: ProviderProps) => {
    const [fullscreen, setFullscreen] = useState(false);
    const pipelineProcessing = (plotData as PipelineStatusPlotData)?.pipeline_status === 'in_progress';
    const { uuid } = useUUID();
    return (
        <PlotContext.Provider
            value={{
                plot,
                experiment,
                analysisParameters,
                overrides,
                plotId,
                hideEditButton,
                plotData,
                plotDataLoading,
                plotDataError,
                isPlotDataValidating,
                pipelineProcessing,
                setDragEnabled,
                publicationMode,
                aspectRatio,
                resizeNoLeading,
                resizeNoTrailing,
                fullscreen,
                setFullscreen,
                minHeight,
                idPrefix: uuid,
                isDragging,
                isExportMode,
                disableMaxHeight,
                mutatePlot,
                isEditing,
            }}
        >
            {children}
        </PlotContext.Provider>
    );
};
