import { SummaryStatisticalTestResult, UpdateStatisticalTestResultParams } from '@models/SummaryStatisticalTestResult';
import { IconButton } from '@mui/material';
import { DeleteOutlineRounded } from '@mui/icons-material';
import React from 'react';
import TestTypeDisplayName from '@components/experiments/TestTypeDisplayName';
import { formatSmallNumber, isNotBlank } from '@util/StringUtil';
import { ParameterOption } from '@models/AnalysisParameters';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

type Props = {
    result: SummaryStatisticalTestResult;
    onDelete?: (result: SummaryStatisticalTestResult) => void;
    onUpdate?: (values: UpdateStatisticalTestResultParams) => void;
    getGroupById: (groupId: number) => ParameterOption | null;
    getTargetById: (targetId: string | number) => ParameterOption | null;
};
const StatisticsResultView = ({ result, onDelete, onUpdate, getGroupById, getTargetById }: Props) => {
    const handleDelete = () => {
        onDelete?.(result);
    };

    const groupInfo = result.groups
        ?.map((groupId) => {
            return getGroupById(groupId)?.display_name ?? null;
        })
        .filter(isNotBlank)
        .join(', ');

    const formatTargetName = () => {
        const name = getTargetById(result.target)?.display_name ?? result.target.toString() ?? '';
        if (name.length > 25) return name.toString().substring(0, 25) + '...';
        return name;
    };

    return (
        <div className="relative flex flex-col rounded-xl border border-indigo-200 bg-white p-4 text-lg text-dark">
            {onDelete && (
                <div className="absolute right-3 top-3 flex items-center space-x-2 text-error">
                    {onUpdate && result.show_on_plot && (
                        <IconButton
                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                            onClick={() => onUpdate?.({ show_on_plot: false })}
                            size="small"
                            title="Currently visible on plot. Click to hide."
                        >
                            <EyeIcon className="text-default" width={20} />
                        </IconButton>
                    )}
                    {onUpdate && !result.show_on_plot && (
                        <IconButton
                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                            onClick={() => onUpdate?.({ show_on_plot: true })}
                            size="small"
                            title="Currently not visible on plot. Click to show."
                        >
                            <EyeOffIcon className="text-default" width={18} />
                        </IconButton>
                    )}

                    <IconButton
                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                        onClick={handleDelete}
                        size="small"
                    >
                        <DeleteOutlineRounded className="text-error" />
                    </IconButton>
                </div>
            )}
            <p className="font-semibold">
                <i>p</i>
                {`${formatSmallNumber({ value: result.value, defaultPrefix: '=' })}`}
            </p>
            <p>
                <span className="font-semibold">Target: </span>
                <span>{formatTargetName()}</span>
            </p>
            <p>
                <span className="font-semibold">Groups: </span>
                <span>{groupInfo}</span>
            </p>
            <p className="text-md text-default">
                <TestTypeDisplayName test_type={result.test_type} />
            </p>
        </div>
    );
};

export default StatisticsResultView;
