import TextInput from '@components/forms/TextInput';
import { formatSmallNumber, stringToNumber } from '@util/StringUtil';
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { FoldChangeThresholdsConfig } from '@models/PlotConfigs';
import { DefaultVolcanoPlotDisplayOption } from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import cn from 'classnames';

const FoldChangeFieldGroup: FC<{
    hideHints?: boolean;
    hideTitle?: boolean;
    lowerBoundDisabled?: boolean;
    lowerBoundField?: string;
    titleClassname?: string;
    upperBoundDisabled?: boolean;
    upperBoundField?: string;
}> = ({
    hideHints,
    hideTitle,
    lowerBoundDisabled,
    lowerBoundField = 'fold_change_fill_threshold_lower',
    titleClassname,
    upperBoundDisabled,
    upperBoundField = 'fold_change_fill_threshold_upper',
}) => {
    const { values, setFieldValue } = useFormikContext<FoldChangeThresholdsConfig>();
    const fold_change_fill_threshold_lower = Number(
        values[lowerBoundField] ?? DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_lower,
    );
    const fold_change_fill_threshold_upper = Number(
        values[upperBoundField] ?? DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_upper,
    );
    return (
        <>
            {hideTitle ? null : (
                <h4 className={cn('mb-2 text-lg font-semibold tracking-tight text-dark', titleClassname)}>
                    Fold change
                </h4>
            )}
            <div className="grid grid-cols-2 gap-2">
                <TextInput
                    name="lower"
                    placeholder={`${fold_change_fill_threshold_lower}`}
                    label="Lower bound"
                    type="number"
                    value={fold_change_fill_threshold_lower}
                    min={0}
                    max={1}
                    step={0.1}
                    hint={
                        hideHints ? undefined : (
                            <span className="pl-1">
                                log<sub>2</sub> FC{' '}
                                {formatSmallNumber({
                                    value: Math.log2(fold_change_fill_threshold_lower),
                                    defaultPrefix: ' = ',
                                    minThreshold: -1000,
                                })}
                            </span>
                        )
                    }
                    onChange={(e) =>
                        setFieldValue(
                            lowerBoundField,
                            stringToNumber(e.target.value) ??
                                DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_lower,
                        )
                    }
                    disabled={lowerBoundDisabled}
                />
                <TextInput
                    name="upper"
                    placeholder={`${fold_change_fill_threshold_upper}`}
                    label="Upper bound"
                    type="number"
                    min={1}
                    step={0.1}
                    value={fold_change_fill_threshold_upper}
                    hint={
                        hideHints ? undefined : (
                            <span className="pl-1">
                                log<sub>2</sub> FC{' '}
                                {formatSmallNumber({
                                    value: Math.log2(fold_change_fill_threshold_upper),
                                    defaultPrefix: ' = ',
                                    minThreshold: -1000,
                                })}
                            </span>
                        )
                    }
                    onChange={(e) =>
                        setFieldValue(
                            upperBoundField,
                            stringToNumber(e.target.value) ??
                                DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_upper,
                        )
                    }
                    disabled={upperBoundDisabled}
                />
            </div>
        </>
    );
};

export default FoldChangeFieldGroup;
