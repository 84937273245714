import { UnitShortname } from '@models/ExperimentType';
import { createContext } from 'react';
import { ProgressEventInfo } from '@services/ApiService';
import Experiment from '@models/Experiment';
import { DataFileType } from '@components/experiments/wizard/ExperimentWizardUtils';
import { StateSetter } from '@contexts/ContextTypes';

const voidFn = () => undefined;
type ContextType = {
    experiment: Experiment | null;
    assayUnits: UnitShortname | null;
    setAssayUnits: StateSetter<UnitShortname | null>;
    assayProgress: ProgressEventInfo | null;
    setAssayProgress: StateSetter<ProgressEventInfo | null>;
    assayDataError: string | null;
    setAssayDataError: StateSetter<string | null>;
    unitsError: string | null;
    setUnitsError: StateSetter<string | null>;
    showUploader: boolean;
    setShowUploader: StateSetter<boolean>;
    isFastqUploading: boolean;
    setIsFastqUploading: StateSetter<boolean>;
    assayUnitsDisplayName: string | null;
    setAssayUnitsDisplayName: StateSetter<string | null>;
    isFastqProcessing: boolean;
    fastqFilesAdded: boolean;
    needsUnits: boolean;
    dataFileType: DataFileType;
    setDataFileType: StateSetter<DataFileType>;
    hasFastqFiles: boolean;
    hasCSVFiles: boolean;
};

const defaultValue: ContextType = {
    experiment: null,
    assayUnits: null,
    setAssayUnits: voidFn,
    assayProgress: null,
    setAssayProgress: voidFn,
    assayDataError: null,
    setAssayDataError: voidFn,
    unitsError: null,
    setUnitsError: voidFn,
    showUploader: false,
    setShowUploader: voidFn,
    isFastqUploading: false,
    setIsFastqUploading: voidFn,
    assayUnitsDisplayName: null,
    setAssayUnitsDisplayName: voidFn,
    isFastqProcessing: false,
    fastqFilesAdded: false,
    needsUnits: false,
    dataFileType: 'csv',
    setDataFileType: voidFn,
    hasFastqFiles: false,
    hasCSVFiles: false,
};

export const AssayUploadContext = createContext<ContextType>(defaultValue);
AssayUploadContext.displayName = 'AssayUploadContext';
