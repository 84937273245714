import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import SummarizeValuesField from '@components/experiments/plotDisplay/fields/SummarizeValuesField';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import HeatmapColorScaleField from '@components/experiments/plotDisplay/fields/HeatmapColorScaleField';
import AssaySummaryLegendFieldGroup from '@components/experiments/plotDisplay/groups/AssaySummaryLegendFieldGroup';
import React, { useMemo } from 'react';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import CustomColorsFormDialogButton from '@components/profile/CustomColorsFormDialogButton';
import CustomLegendColorField, {
    CustomLegendColorItem,
} from '@components/experiments/plotDisplay/groups/CustomLegendColorField';
import { useFormikContext } from 'formik';
import { DisplayFormValue } from '@models/PlotDisplayOption';
import HeatmapDisplayOption from '@models/plotDisplayOption/HeatmapDisplayOption';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { ComparativeAnalysis } from '@models/analysis/ComparativeAnalysis';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';

type Props = { plot: Plot; experiment: Experiment };
const HeatmapFields = ({ plot, experiment }: Props) => {
    const { values } = useFormikContext<DisplayFormValue<HeatmapDisplayOption>>();
    const isComparativeAnalysis = plot.analysis?.category.shortname === 'comparative';
    const selectedGroupsIds = (plot.analysis as AssaySummaryAnalysis)?.groups?.map((g) => g.id);
    const legendItems = useMemo<CustomLegendColorItem[]>(() => {
        if (!isComparativeAnalysis) {
            return [];
        }
        const palette = getPlotPalette(values.theme_color).colors;

        const analysis = plot.analysis as ComparativeAnalysis;
        const controlGroup = analysis.control_group;
        const experimentalGroup = analysis.experimental_group;

        const legendItems: CustomLegendColorItem[] = [
            {
                id: `${experimentalGroup.id}`,
                label: experimentalGroup?.display_name ?? `Experimental group`,
                themeColor: palette[1].color,
            },
            {
                id: `${controlGroup.id}`,
                label: controlGroup?.display_name ?? `Control group`,
                themeColor: palette[0].color,
            },
        ];

        return legendItems;
    }, [values.theme_color, plot.analysis_type, isComparativeAnalysis, plot.analysis]);

    return (
        <div className="space-y-8">
            <section>
                <FullWidthToggleField />
                <ChartTitleField placeholder={plot.analysis?.name} />

                {isComparativeAnalysis && (
                    <div>
                        <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Thresholds</h4>
                        <PValueField name="adj_p_value_threshold" hideSectionLabel showHint />
                    </div>
                )}

                <SummarizeValuesField disableNone={isComparativeAnalysis} />
            </section>
            <section>
                <ThemeFieldGroup hideStyle colorsLabel="Group colors" />
                <HeatmapColorScaleField type={isComparativeAnalysis ? 'comparative' : 'summary'} />
            </section>
            {isComparativeAnalysis ? (
                <section>
                    <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                    <CustomColorsFormDialogButton color="primary" variant="text" size="small" marginLeft="-6px">
                        Manage favorite colors
                    </CustomColorsFormDialogButton>
                    <CustomLegendColorField items={legendItems} editable={true} />
                </section>
            ) : (
                <section>
                    <AssaySummaryLegendFieldGroup
                        plot={plot}
                        experiment={experiment}
                        selectedGroupIds={selectedGroupsIds}
                        isSortable
                    />
                </section>
            )}
        </div>
    );
};

export default HeatmapFields;
