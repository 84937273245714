import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ReactNode, useState } from 'react';
import Logger from '@util/Logger';
import { useFormikContext } from 'formik';
import TextAreaField from '@components/forms/TextAreaField';
import { Alert } from '@mui/material';
import { humanFileSize } from '@util/StringUtil';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import ResumableFileUploader, { TotalProgressInfo } from '@/src/components/fileUpload/ResumableFileUploader';
import { pluralize } from '@/src/util/ObjectUtil';
import { TrashIcon } from '@heroicons/react/outline';
import { Button } from '@mui/material';
import { PrismAnalysisFormValues } from '../AnalysisFormTypes';
import UploadSession from '@models/UploadSession';

const logger = Logger.make('PrismAnalysisFormFields');

type Props = { plot: Plot; experiment: Experiment };
const PrismAnalysisFormFields = ({ plot, experiment }: Props) => {
    const form = useFormikContext<PrismAnalysisFormValues>();
    const [fileError, setFileError] = useState<ReactNode | null>(null);
    const [pageTitle] = useState(document?.title);
    logger.debug('rendering PrismAnalysisFormFields');
    const handleRemoveFile = () => {
        form.setFieldValue('experiment_file_id', null);
    };
    const analysis = plot.analysis as PrismAnalysis | null;
    const uploadedFileId = form.values.experiment_file_id ?? analysis?.experiment_file_id;
    const [showUploader, setShowUploader] = useState(!analysis?.prism_file.signed_url);

    const handlePrismDoneUploading = async (session: UploadSession) => {
        document.title = pageTitle;
        const fileId = session?.file?.uuid;
        form.setFieldValue('experiment_file_id', fileId);
    };

    const handleResumableProgress = (progress: TotalProgressInfo | null) => {
        if (!progress || progress.percentLoaded === 1) {
            document.title = pageTitle;
            return;
        }

        const fileWord = pluralize(progress.numFiles, 'file', 'files');
        const percent = (progress.percentLoaded * 100).toFixed(2);

        document.title = `[${percent}%] Uploading ${progress.numFiles} ${fileWord} | ${pageTitle}`;
    };

    return (
        <div className="space-y-8">
            {fileError && (
                <Alert severity="error" onClose={() => setFileError(null)}>
                    {fileError}
                </Alert>
            )}

            {showUploader ? (
                <div className="space-y-2">
                    <ResumableFileUploader
                        uploadHeader={uploadedFileId ? 'Prism file uploaded successfully' : 'Drop Prism file here'}
                        experiment={experiment}
                        dataType="analysis_input"
                        onUploadComplete={handlePrismDoneUploading}
                        onFilesChanged={() => null}
                        acceptFileTypes={{ 'text/plain': ['.prism'] }}
                        onProgress={(progress) => handleResumableProgress(progress)}
                        maxFiles={1}
                        uploadSubheader={uploadedFileId ? 'Click Save to process file for preview' : undefined}
                        chooseFileText="Choose .prism file"
                        showSuccess={!!uploadedFileId && !fileError}
                    />
                    {uploadedFileId && (
                        <div className="text-error">
                            <Button
                                onClick={handleRemoveFile}
                                startIcon={<TrashIcon className="h-5 w-5" />}
                                variant="outlined"
                                color="inherit"
                                size="small"
                            >
                                Remove file
                            </Button>
                        </div>
                    )}
                </div>
            ) : (
                // This block will show the details of the uploaded file and a button to update it
                <div>
                    <div className="form-field">
                        <div className="field-label">Prism file</div>
                        <span className="font-semibold">{analysis?.prism_file.filename}</span>
                        <br />
                        <span className="text-sm">
                            {new Date(analysis?.prism_file.created_at ?? '').toLocaleString()}
                        </span>
                        <span>&bull;</span>
                        <span className="text-sm">{humanFileSize(analysis?.prism_file.file_size)}</span>
                        <br />
                        <a href={analysis?.prism_file.signed_url} download>
                            Download
                        </a>
                    </div>
                    <div className="text-error">
                        <Button
                            onClick={() => setShowUploader(true)}
                            startIcon={<TrashIcon className="h-5 w-5" />}
                            variant="outlined"
                            color="inherit"
                            size="small"
                        >
                            Update File
                        </Button>
                    </div>
                </div>
            )}

            <div className="">
                <TextAreaField name="methods" label="Methods" minRows={4} />
            </div>
        </div>
    );
};

export default PrismAnalysisFormFields;
