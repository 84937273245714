import React, { useEffect, useMemo } from 'react';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import SortableLegendField from '@components/experiments/plotDisplay/groups/SortableLegendField';
import { useField } from 'formik';
import { isDefined } from '@util/TypeGuards';
import usePlot from '@hooks/usePlot';

type Props = {
    plot: Plot;
    experiment: Experiment;
    orderedGroupsFieldName?: string;
    variables?: number[];
    ignoreEmpty?: boolean;
    description?: string;
    minSampleCount?: number;
    selectedGroupIds?: number[];
    isSortable?: boolean;
};
/**
 * Fields to sort the legend
 */
const AssaySummaryLegendFieldGroup = ({
    plot,
    experiment,
    orderedGroupsFieldName = 'legend.group_display_order',
    variables,
    ignoreEmpty = false,
    description,
    minSampleCount,
    selectedGroupIds,
    isSortable = false,
}: Props) => {
    const [inputProps, , helpers] = useField<number[] | null>(orderedGroupsFieldName);
    const { plotData } = usePlot({
        experiment,
        plot,
        plotId: plot.uuid,
    });

    const selectedVariables =
        variables ?? (plot.analysis as Exclude<ExperimentAnalysis, ImageAnalysis>)?.variables ?? [];

    const { groups, loading: groupsLoading } = useExperimentPlotGroups({
        experiment,
        plot,
        variables: selectedVariables,
    });

    const filteredGroups = useMemo(() => {
        let availableGroups = [...groups];
        if (isDefined(selectedGroupIds)) {
            availableGroups = availableGroups.filter((g) => selectedGroupIds.includes(g.id));
        }
        if (!isDefined(minSampleCount)) {
            return availableGroups;
        }

        return availableGroups.filter((g) => g.sample_id_count ?? 0 >= minSampleCount);
    }, [minSampleCount, groups, selectedGroupIds, plotData]);

    useEffect(() => {
        if (groupsLoading) {
            return;
        }
        const value = inputProps.value ?? [];
        const groupIds = filteredGroups.map((g) => g.id);
        if (value.length === 0 && ignoreEmpty && filteredGroups.length > 0) {
            helpers.setValue(groupIds);
        }

        if (value.length !== filteredGroups.length) {
            helpers.setValue(groupIds);
        }
    }, [ignoreEmpty, filteredGroups, groupsLoading, inputProps.value]);

    return (
        <SortableLegendField
            items={filteredGroups}
            name={orderedGroupsFieldName}
            title={'Legend'}
            description={description}
            ignoreEmpty={ignoreEmpty}
            loading={groupsLoading}
            isSortable={isSortable}
        />
    );
};

export default AssaySummaryLegendFieldGroup;
