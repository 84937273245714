import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { FormikFieldError } from '@components/forms/FieldError';
import cn from 'classnames';
import { FormHelperText, TextareaAutosize, TextareaAutosizeProps } from '@mui/material';

type Props = { name: string; label?: ReactNode; subLabel?: string; inputClassName?: string } & TextareaAutosizeProps;
const TextAreaField = ({
    name,
    label,
    subLabel,
    maxLength,
    className,
    inputClassName,
    onChange,
    value,
    ...textAreaProps
}: Props) => {
    const [inputProps] = useField(name);
    return (
        <div className={cn('form-field', className)}>
            {label && (
                <div className="field-label">
                    {label} {subLabel ? <span className="font-normal">{subLabel}</span> : null}
                </div>
            )}
            <TextareaAutosize
                className={cn('field-input w-full', inputClassName)}
                {...inputProps}
                maxLength={maxLength}
                {...textAreaProps}
                onChange={onChange ?? inputProps.onChange}
                value={value ?? inputProps.value}
            />
            {maxLength && (
                <FormHelperText>{`${(
                    value ??
                    inputProps.value ??
                    ''
                )?.length.toLocaleString()}/${maxLength.toLocaleString()}`}</FormHelperText>
            )}
            <FormikFieldError name={name} />
        </div>
    );
};

export default TextAreaField;
