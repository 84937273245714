import Experiment from '@models/Experiment';
import Plot from '@models/Plot';
import useSWR from 'swr';
import { ComparisonGroupConfig } from '@models/ExperimentConfig';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import { AnalysisGroupV2 } from '../models/AnalysisParameters';

type Props = {
    annotation_set_id: string;
    experiment: Experiment;
    latent_variable_id: string;
    plot: Plot;
    variable_ids: string[];
};
const useExperimentPlotGroupsV2 = ({
    annotation_set_id,
    experiment,
    latent_variable_id,
    plot,
    variable_ids = [],
}: Props) => {
    const experimentId = experiment.uuid;
    const plotId = plot.uuid;
    const fetchGroups = Boolean(latent_variable_id || annotation_set_id || variable_ids.length > 0);

    const api = useApi();
    // This uses a POST request, which is a little different from other places in the app.
    // The key is returned as a tuple (compound key) consisting of the URL and the variables passed in.
    // The variables are in a sorted list so that the cache key can be re-used no matter on the order a user selected the variables
    const { data, error } = useSWR<ComparisonGroupConfig<AnalysisGroupV2>>(
        () =>
            fetchGroups
                ? [
                      Endpoints.lab.experiment.plot.groupsV2({
                          experimentId,
                          plotId,
                      }),
                      {
                          ...(variable_ids.length > 0 ? { variable_ids: [...(variable_ids ?? [])].sort() } : undefined),
                          ...(annotation_set_id ? { annotation_set_id } : undefined),
                          ...(latent_variable_id ? { latent_variable_id } : undefined),
                      },
                  ]
                : null,
        { fetcher: (url, payload) => api.post(url, { ...payload }), revalidateOnMount: true },
    );

    const loading = !data && !error && fetchGroups;
    const groups = (data ?? []) as AnalysisGroupV2[];

    /**
     * Get group details for a given group ID.
     * @param {number} group_id
     * @return {AnalysisGroupV2 | null}
     */
    const getGroupById = (group_id: number): AnalysisGroupV2 | null => {
        return groups.find((g) => `${g.uuid}` === `${group_id}`) ?? null;
    };

    const analysis = plot?.analysis as AssaySummaryAnalysis | null;
    const selectedGroupIds = analysis?.groups?.map((g) => g.uuid) ?? [];
    /**
     * The groups that were selected in the plot's analysis
     */
    const selectedGroups = groups.filter((g) => selectedGroupIds.includes(g.uuid));
    return { data, error, loading, groups, getGroupById, selectedGroups };
};

export default useExperimentPlotGroupsV2;
