import FieldError from '@components/forms/FieldError';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import cn from 'classnames';
import { formatTableHeader } from '@util/StringUtil';
import React, { ReactNode, useEffect } from 'react';
import { useField } from 'formik';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { LoadingIndicator } from '@components/LoadingButton';
import useExperimentPlotGroups from '@hooks/useExperimentPlotGroups';
import { ParameterOption } from '@/src/models/AnalysisParameters';

type FieldValue = number[];
type Props = {
    plot: Plot;
    experiment: Experiment;
    label?: ReactNode;
    variablesName?: string;
    groupsName?: string;
    onGroupChange?: (availableGroups: ParameterOption[]) => void;
};
const AnalysisGroupsField = ({
    experiment,
    plot,
    label = 'Groups',
    variablesName = 'variables',
    groupsName = 'group_ids',
    onGroupChange,
}: Props) => {
    const [variablesProps] = useField<FieldValue>(variablesName);
    const [groupIdsProps, groupIdsMeta, { setValue: setGroupIds }] = useField<FieldValue>(groupsName);
    const variables = variablesProps.value ?? [];

    const { loading, groups: availableGroups } = useExperimentPlotGroups({
        experiment,
        plot,
        variables,
    });

    useEffect(() => {
        if (availableGroups.length) {
            onGroupChange?.(availableGroups);
        }
    }, [availableGroups]);

    if (loading) {
        return (
            <div className="form-field">
                <span className="field-label">{label}</span>
                <div>
                    <span className="flex items-center space-x-2">
                        <LoadingIndicator size={12} />
                        <span>Loading groups...</span>
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="form-field">
            <span className="field-label">{label}</span>
            {variables.length === 0 && (
                <div className={cn({ 'text-error': groupIdsMeta.touched && groupIdsMeta.error })}>
                    Please select one or more variables to show available Groups
                </div>
            )}
            {variables.length > 0 && availableGroups.length === 0 && (
                <div className={cn({ 'text-error': groupIdsMeta.touched && groupIdsMeta.error })}>
                    No groups found for the selected variables. Please try another combination.
                </div>
            )}
            {availableGroups.length > 0 && groupIdsMeta.touched && groupIdsMeta.error && (
                <FieldError>{groupIdsMeta.error}</FieldError>
            )}

            <div className="flex flex-row flex-wrap">
                {availableGroups.map((group, index) => {
                    return (
                        <SelectableItem
                            key={`group_${group.id})${index}`}
                            selected={groupIdsProps.value.includes(group.id)}
                            onSelect={() => setGroupIds(toggleValue(groupIdsProps.value, group.id))}
                            className="mb-2 mr-2 flex flex-col items-center justify-center"
                            cyId="analysis-group-item"
                        >
                            <span>{formatTableHeader(group.display_name)}</span>
                        </SelectableItem>
                    );
                })}
            </div>
        </div>
    );
};

export default AnalysisGroupsField;
