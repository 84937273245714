import { IconProps } from '../CustomIcons';

export const PlutoIntelligenceIcon = ({ height = 20, width = 20, className }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 292 284"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M266.144 25.856L252 0L237.856 25.856L212 40L237.856 54.144L252 80L266.144 54.144L292 40L266.144 25.856ZM152 10L194.424 87.576L272 130L194.424 172.424L152 250L109.576 172.424L32 130L109.576 87.576L152 10ZM56 172L75.8016 208.198L112 228L75.8016 247.802L56 284L36.1984 247.802L0 228L36.1984 208.198L56 172Z"
                fill="url(#paint0_linear_8933_380)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_8933_380"
                    x1="146"
                    y1="0"
                    x2="146"
                    y2="284"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AD06B1" />
                    <stop offset="1" stopColor="#4E1591" />
                </linearGradient>
            </defs>
        </svg>
    );
};
