import { defaultTextClass, IconProps } from '@components/icons/CustomIcons';
import cn from 'classnames';

const TextDisplayIcon = ({ className = defaultTextClass, height = 24, width = 24 }: IconProps) => {
    return (
        <svg
            viewBox="0 0 36 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...{
                height,
                width,
                className: cn(className),
            }}
        >
            <path
                d="M2 7.14286V2H29.4286V7.14286"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5714 29.4286H20.8571"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7143 2V29.4286"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34 20.2857H24.8571"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.4286 15.7143L29.4286 29.4286L34 29.4286"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default TextDisplayIcon;
