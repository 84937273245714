import SelectableItem from '@components/forms/SelectableItem';
import { HeatmapColorScaleItem } from '@components/ColorPalette';
import Button from '@components/Button';
import React, { ReactNode, useState } from 'react';
import { useField } from 'formik';
import { HeatmapTypes, allComparativeHeatmapColorScales, allSummaryHeatmapColorScales } from '@/src/models/PlotConfigs';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

type Props = { label?: ReactNode; name?: string; type?: HeatmapTypes };
const HeatmapColorScaleField = ({
    label = 'Heatmap colors',
    name = 'heatmap_scale_color',
    type = 'comparative',
}: Props) => {
    const [inputProps, , helpers] = useField(name);
    const [showAll, setShowAll] = useState(false);

    const getScales = () => {
        if (type === 'comparative') {
            return allComparativeHeatmapColorScales;
        }
        return allSummaryHeatmapColorScales;
    };

    const scales = getScales();

    return (
        <div className="form-field no-margin">
            <span className="field-label">{label}</span>
            <Collapse in={showAll} collapsedSize={161.9}>
                <div className="flex flex-col space-y-1 p-0.5">
                    {scales.map((color, index) => (
                        <SelectableItem
                            key={`color_theme_${index}`}
                            onSelect={() => helpers.setValue(color)}
                            selected={inputProps.value === color}
                            noBorder
                            className=""
                            activeClasses="ring-2 ring-indigo-500"
                        >
                            <HeatmapColorScaleItem heatmapColor={color} className="" type={type} />
                        </SelectableItem>
                    ))}
                </div>
            </Collapse>
            {scales.length > 3 && (
                <Button
                    className="mt-2 flex flex-col space-y-1"
                    color="primary"
                    variant="text"
                    size="small"
                    onClick={() => setShowAll(!showAll)}
                    style={{ marginLeft: -6 }}
                >
                    {showAll ? 'View less' : 'View all'}
                    {showAll ? <ExpandLess /> : <ExpandMore />}
                </Button>
            )}
        </div>
    );
};

export default HeatmapColorScaleField;
