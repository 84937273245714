import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import Button from '@components/Button';
import { ReactNode, useEffect, useState } from 'react';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import TextInput from '@components/forms/TextInput';
import { TrashIcon } from '@heroicons/react/outline';
import { useExperimentWorkflowContext } from '@/src/contexts/ExperimentWorkflowContext';

export type Props = {
    open?: boolean;
    onConfirm: ({ name }: { name: string }) => void;
    onCancel: () => void;
    confirmLoading?: boolean;
    icon?: ReactNode;
    error?: ReactNode;
};
const EditWorkflowModal = ({ open = false, onCancel, onConfirm, confirmLoading = false, icon, error }: Props) => {
    const { selectedWorkflow, setConfirmDeleteModalOpen } = useExperimentWorkflowContext();
    const [workflowName, setWorkflowName] = useState<string>(selectedWorkflow?.name ?? '');

    useEffect(() => {
        if (selectedWorkflow) {
            setWorkflowName(selectedWorkflow?.name);
        }
    }, [selectedWorkflow]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogCloseButton onClose={() => onCancel()} />
            <PlutoDialogTitle iconStart={icon} iconEnd={icon} title="Edit workflow" />
            <DialogContent className="mb-4 space-y-4">
                {error && <Alert severity="error">{error}</Alert>}
                <TextInput
                    label="Name"
                    value={workflowName}
                    name="workflow_name"
                    noMargin
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWorkflowName(e.target.value)}
                    disableFormik
                />
            </DialogContent>
            <DialogActions className="!justify-between">
                <Button variant="outlined" color="danger" onClick={() => setConfirmDeleteModalOpen(true)}>
                    <TrashIcon width={20} className="mr-1 text-error" /> Archive
                </Button>
                <Button
                    color="primary"
                    onClick={() =>
                        onConfirm({
                            name: workflowName,
                        })
                    }
                    variant="contained"
                    disabled={confirmLoading}
                >
                    {confirmLoading ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default EditWorkflowModal;
