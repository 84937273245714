import { IconProps } from '@components/icons/CustomIcons';

export const ArrowUturnLeftIcon = (props?: IconProps) => {
    const { width = 24, height = 24, className } = { ...props };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className}
            width={width}
            height={height}
        >
            <path
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
        </svg>
    );
};

export default ArrowUturnLeftIcon;
