import DialogCloseButton from '@components/DialogCloseButton';
import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { StateSetter } from '@contexts/ContextTypes';
import Experiment from '@models/Experiment';
import BenchlingExportDialogForm from '@components/experiments/benchling/BenchlingExportDialogForm';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import copy from '@copy/Copy';

type Props = {
    makeFile: () => Promise<File[] | null>;
    open: boolean;
    setOpen: StateSetter<boolean>;
    experiment: Experiment;
};
const BenchlingExportDialog = ({ makeFile, open, setOpen, experiment }: Props) => {
    const renderHelpText = () => (
        <p className="mb-4 text-lg">
            Export this QC report to a third-party analysis step in Benchling.{' '}
            <a href={copy.exportToBenchlingHelpUrl} target="_blank" rel="noreferrer nofollow noopen">
                Learn more
            </a>
        </p>
    );

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogCloseButton onClose={() => setOpen(false)} />
            <PlutoDialogTitle title="Export to Benchling" />
            <DialogContent>
                <BenchlingExportDialogForm experiment={experiment} makeFile={makeFile} helpText={renderHelpText} />
            </DialogContent>
        </Dialog>
    );
};

export default BenchlingExportDialog;
