import Experiment from '@models/Experiment';
import { useEffect, useMemo, useState } from 'react';
import Logger from '@util/Logger';
import UploadSession from '@models/UploadSession';
import { FileDataType } from '@models/PlutoFile';
import ChunkedFileUpload, { ChunkInfo } from '@services/fileUpload/ChunkedFileUpload';
import { ProgressEventInfo } from '@services/ApiService';
import useUploadSession from '@hooks/useUploadSession';
import { blankToNull } from '@util/StringUtil';
import { ApiError } from '@services/ApiError';

const logger = Logger.make('useResumableFile', 'file_upload');
const CHUNK_SIZE = 262144 * 32;
const MAX_RETRIES = 3;

type Props = {
    experiment: Experiment;
    file: File;
    data_type: FileDataType;
    onUploadComplete?: (session: UploadSession) => void;
    onProgress?: (progress: ProgressEventInfo) => void;
};

const useResumableFileUpload = ({ experiment, file, data_type, onUploadComplete, onProgress }: Props) => {
    const {
        fetchSession,
        fileId,
        loading: sessionLoading,
        completeSession,
        cancelSession,
    } = useUploadSession({ experiment, file, data_type });

    const [uploadSession, setUploadSession] = useState<UploadSession | null>(null);
    const [state, setState] = useState({
        pausePending: false,
        uploadError: null as Error | { message?: string } | ApiError | null,
        uploading: false,
        complete: false,
        resuming: false,
        finishedChunksProgress: null as ProgressEventInfo | null,
        currentChunkProgress: null as ProgressEventInfo | null,
        uploader: null as ChunkedFileUpload | null,
    });

    const handleProgressEvent = (progress: ProgressEventInfo) => {
        onProgress?.(progress);
    };

    const startSessionUpload = async (session: UploadSession) => {
        const upload_url = session.session_url;
        let retries = 0;

        const fileUploader = new ChunkedFileUpload({
            id: fileId,
            url: upload_url,
            file,
            contentType: blankToNull(file.type) ?? 'text/plain',
            chunkSize: CHUNK_SIZE,
            onResumeStarted: () => setState((prev) => ({ ...prev, resuming: true })),
            onResumeFinished: () => setState((prev) => ({ ...prev, resuming: false })),
            onChunkProgress: (e: ProgressEvent) => {
                const loaded = e.loaded;
                const total = e.total;
                const percentage = (loaded / total) * 100;
                setState((prev) => ({ ...prev, currentChunkProgress: { total, loaded, percentage } }));
            },
            onFailed: async (error) => {
                if (retries < MAX_RETRIES) {
                    retries++;
                    await fileUploader.start();
                } else {
                    const newError = error ? error : { message: 'Upload Failed!' };
                    return setState((prev) => ({ ...prev, uploadError: newError, complete: false }));
                }
            },
            onChunkUpload: (info: ChunkInfo) => {
                setState((prev) => ({
                    ...prev,
                    resuming: false,
                    pausePending: false,
                    currentChunkProgress: null,
                    finishedChunksProgress: {
                        total: info.totalBytes,
                        loaded: info.uploadedBytes,
                        percentage: (info.uploadedBytes / info.totalBytes) * 100,
                    },
                }));
            },
        });

        setState((prev) => ({ ...prev, uploader: fileUploader }));

        try {
            await fileUploader.start();
            if (fileUploader.finished && !fileUploader.canceled && !fileUploader.errored) {
                await completeSession(session);
                setState((prev) => ({ ...prev, complete: true, uploader: null }));
                onUploadComplete?.(session);
            }
        } catch (error) {
            logger.error(error);
            setState((prev) => ({ ...prev, uploadError: error }));
        } finally {
            setState((prev) => ({ ...prev, uploading: false }));
        }
    };

    const actions = {
        uploadFile: async () => {
            if (state.uploader) {
                await actions.resumeUpload();
                return;
            }
            setState({
                ...state,
                uploading: true,
                pausePending: false,
                complete: false,
                currentChunkProgress: null,
                uploadError: null,
            });
            try {
                const session = await fetchSession();
                setUploadSession(session);
                if (!session) return;
                await startSessionUpload(session);
            } catch (error) {
                setState((prev) => ({ ...prev, uploadError: error }));
            }
        },
        pauseUpload: () => {
            setState((prev) => ({ ...prev, pausePending: true, uploading: false }));
            state.uploader?.pause();
        },
        resumeUpload: () => {
            if (!state.uploader) return;
            setState((prev) => ({ ...prev, pausePending: false, uploadError: null, uploading: true }));
            state.uploader.unpause();
        },
        restartFileUpload: async () => {
            if (!uploadSession) return;
            setState((prev) => ({ ...prev, uploadError: null, resuming: true, uploading: true }));
            await startSessionUpload(uploadSession);
        },
        cancelUpload: async () => {
            state.uploader?.cancel();
            if (!uploadSession) return;
            await cancelSession(uploadSession);
            setState((prev) => ({ ...prev, pausePending: false, uploadSession: null }));
        },
    };

    useEffect(() => {
        return () => actions.pauseUpload();
    }, []);

    const progress = useMemo<ProgressEventInfo | null>(() => {
        const finished = state.finishedChunksProgress;
        const current = state.currentChunkProgress;
        if (!finished && !current) return null;
        const loaded = (finished?.loaded ?? 0) + (current?.loaded ?? 0);
        const total = finished?.total ?? file?.size;
        const percentage = (loaded / total) * 100;
        return { loaded, total, percentage };
    }, [state.finishedChunksProgress, state.currentChunkProgress]);

    useEffect(() => {
        if (progress) {
            handleProgressEvent(progress);
        }
    }, [progress]);

    return {
        ...actions,
        sessionLoading,
        ...state,
        progress,
    };
};

export default useResumableFileUpload;
