import React from 'react';
import { useFormikContext } from 'formik';
import { ShowFoldChangeConfig } from '@models/PlotConfigs';

const FoldChangeToggleField = () => {
    const { values, handleChange } = useFormikContext<ShowFoldChangeConfig>();
    return (
        <label className="-mt-4 mb-8 block">
            <input
                type="checkbox"
                className="rounded text-indigo-500"
                name="show_fold_change_lines"
                checked={values.show_fold_change_lines}
                onChange={handleChange}
            />
            <span className="ml-2">Show fold change threshold lines</span>
        </label>
    );
};

export default FoldChangeToggleField;
