import { CircularProgress, CircularProgressProps } from '@mui/material';
import { ProgressEventInfo } from '@services/ApiService';
import React from 'react';
import { isNotBlank } from '@util/StringUtil';

type Props = { progress: ProgressEventInfo; message?: string } & Omit<CircularProgressProps, 'variant' | 'value'>;
const LoadingProgress = ({ progress, message, size = 60, ...progressProps }: Props) => {
    return (
        <div className="flex flex-col space-y-4">
            <div className="relative inline-flex items-center justify-center rounded-full bg-indigo-100 p-2">
                <CircularProgress
                    variant="determinate"
                    value={(progress.percentage ?? 0) * 100}
                    size={size}
                    {...progressProps}
                />
                {progress && (
                    <div className="absolute ">
                        <span>{`${Math.round(progress.percentage * 100)}%`}</span>
                    </div>
                )}
            </div>
            {isNotBlank(message) && <div className="text-lg">{message}</div>}
        </div>
    );
};

export default LoadingProgress;
