import Experiment from '@models/Experiment';
import FieldError from '@components/forms/FieldError';
import SelectableItem from '@components/forms/SelectableItem';
import { toggleValue } from '@util/ObjectUtil';
import cn from 'classnames';
import { VariableIcon } from '@components/experiments/ExperimentIcons';
import { formatTableHeader } from '@util/StringUtil';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DifferentialExpressionAnalysisFormValues } from '@models/analysis/DifferentialExpressionAnalysis';
import Plot from '@models/Plot';
import { DifferentialExpressionAnalysisParameters } from '@models/AnalysisParameters';
import { Tooltip } from '@mui/material';

type Props = { plot: Plot; analysisParameters: DifferentialExpressionAnalysisParameters; experiment: Experiment };
const CovariatesVariablesField = ({ analysisParameters, plot }: Props) => {
    const { values, setFieldValue, errors, touched } = useFormikContext<DifferentialExpressionAnalysisFormValues>();
    const covariate_available_variables = analysisParameters.variables;
    const { allVariables } = useMemo(() => {
        return {
            allVariables: (covariate_available_variables ?? []).sort((o1, o2) => {
                return (o1.display_name ?? '').localeCompare(o2?.display_name ?? '');
            }),
        };
    }, [plot, analysisParameters]);

    return (
        <div className="form-field">
            {errors.variables && touched.variables && <FieldError>{errors.variables}</FieldError>}
            {values.variables.length < 1 && (
                <div className="text-md mb-4">Select at least one variable to group your samples by</div>
            )}
            <div className="flex flex-row flex-wrap" data-cy="variables-field">
                {allVariables.map((variable, index) => {
                    const isSelected = values.covariates ? values.covariates.includes(variable.id) : false;
                    const isGreyedOut = values.variables.includes(variable.id);

                    const itemClass = cn({
                        'mb-2 mr-2 items-center justify-center': true,
                        'opacity-50': isGreyedOut,
                    });
                    const variableIconStyle = !variable ? { display: 'none' } : {};

                    const tooltipTitle = isGreyedOut
                        ? 'Cannot select covariates that are already used as comparison variables'
                        : '';

                    return (
                        <Tooltip title={tooltipTitle} key={`variable_item_${index}`} arrow>
                            <div>
                                <SelectableItem
                                    key={`variable_item_${index}`}
                                    row
                                    selected={isSelected}
                                    onSelect={() => {
                                        if (!isGreyedOut) {
                                            setFieldValue(
                                                'covariates',
                                                toggleValue(values.covariates || [], variable.id),
                                            );
                                        }
                                    }}
                                    className={itemClass}
                                    cyId="variable-item"
                                >
                                    {variable && (
                                        <div className="mr-2 text-indigo-500" style={variableIconStyle}>
                                            <VariableIcon variable={variable} />
                                        </div>
                                    )}
                                    <span className="capitalize">{formatTableHeader(variable.display_name)}</span>
                                </SelectableItem>
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
};

export default CovariatesVariablesField;
