import { Divider, FormControl, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { blankToNull } from '@util/StringUtil';
import React from 'react';
import { DisplayTypeIcon } from '@components/experiments/ExperimentIcons';
import PlotDisplayType, { PlotDisplayShortname } from '@/src/models/PlotDisplayType';

const NONE_VALUE = 'NONE';

const styles = {
    wrap: {
        whiteSpace: 'normal',
    },
};

type Props = {
    disabled?: boolean;
    noLabel?: boolean;
    options: PlotDisplayType[];
    placeholder?: string;
    setValue: (value: PlotDisplayShortname | null) => void;
    showNone?: boolean;
    value: string | null;
};
const ControlledPlotTypePickerField = ({
    disabled,
    noLabel,
    options,
    placeholder,
    setValue,
    showNone,
    value,
}: Props) => {
    return (
        <label className="block">
            {!noLabel && <span className="mb-2 block text-base font-semibold tracking-tight">Plot Type</span>}
            <div className="-mx-1 flex flex-row flex-wrap">
                <FormControl variant="outlined" fullWidth>
                    <Select
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        margin="dense"
                        value={value ?? (showNone ? NONE_VALUE : '')}
                        onChange={(e) => {
                            if (e.target.value === NONE_VALUE || e.target.value === '') {
                                setValue(null);
                                return;
                            }
                            setValue(blankToNull(e.target.value as string) as PlotDisplayShortname | null);
                        }}
                        disabled={disabled}
                        sx={{ selectMenu: styles.wrap }}
                        placeholder={placeholder}
                        renderValue={(selected) => {
                            if (selected === NONE_VALUE) {
                                return <>Filter by plot type</>;
                            }

                            return selected;
                        }}
                        inputProps={{ 'aria-label': 'Filter by plot type' }}
                    >
                        {showNone && <MenuItem value={NONE_VALUE}>Any</MenuItem>}
                        {showNone && <Divider />}
                        {options.map((i) => (
                            <MenuItem key={i.shortname} value={i.display_name} sx={styles.wrap}>
                                <div className="flex max-w-sm flex-row items-center justify-start space-x-2 whitespace-normal sm:max-w-lg">
                                    <span className="h-4 w-4 text-indigo-500">
                                        <DisplayTypeIcon type={i.shortname} width={18} height={18} />
                                    </span>
                                    <span className="whitespace-normal">{i.display_name}</span>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </label>
    );
};

export default ControlledPlotTypePickerField;
