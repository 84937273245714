import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import { AnalysisParameters } from '@models/AnalysisParameters';
import {
    isGeneSetEnrichmentAnalysisParameters,
    isTranscriptionFactorEnrichmentAnalysisParameters,
} from '@models/AnalysisParametersHelpers';
import { TranscriptionFactorEnrichmentAnalysis } from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import GeneSetEnrichmentAnalysisMethodsSection from '@components/experiments/methods/analyses/GeneSetEnrichmentAnalysisMethodsSection';
import TranscriptionFactorEnrichmentAnalysisMethodsSection from '@components/experiments/methods/analyses/TranscriptionFactorEnrichmentAnalysisMethodsSection';
import React from 'react';
import ScoreBarPlotDisplayOption from '@models/plotDisplayOption/ScoreBarPlotDisplayOption';
import MethodsSectionTitle from '@components/experiments/methods/MethodsSectionTitle';
import Bold from '@components/elements/Bold';

type Props = { experiment: Experiment; plot: Plot; analysisParameters: AnalysisParameters | null };
const ScoreBarPlotMethods = ({ plot, experiment, analysisParameters }: Props) => {
    const display = plot.display as ScoreBarPlotDisplayOption;

    if (
        plot.analysis_type === 'transcription_factor_enrichment' &&
        isTranscriptionFactorEnrichmentAnalysisParameters(analysisParameters)
    ) {
        const analysis = plot.analysis as TranscriptionFactorEnrichmentAnalysis;
        const collectionShortName = analysis.tf_target_collection.shortname;
        const otherShortNames = [
            'coexpression_gtex',
            'coexpression_archs4',
            'chipseq_encode',
            'chipseq_remap',
            'chipseq_literature',
            'coocurrence_enrichr',
        ];

        return (
            <div className="space-y-6">
                <TranscriptionFactorEnrichmentAnalysisMethodsSection
                    plot={plot}
                    experiment={experiment}
                    analysisParameters={analysisParameters}
                />
                <section>
                    <MethodsSectionTitle>TF enrichment scores barplot</MethodsSectionTitle>
                    <p>
                        Barplot shows the TFEA results for each tested transcription factor with a{' '}
                        <span className="italic">p</span>-value ≤ <Bold>{display.adj_p_value_filter ?? 0.001}</Bold>.
                        The <span className="italic">y</span>-axis shows the score from ChEA3,{' '}
                        {collectionShortName === 'integrated_mean' ? (
                            <span>
                                which was calculated as the <Bold>mean rank</Bold> of each TF across all libraries
                                containing that TF.
                            </span>
                        ) : null}
                        {collectionShortName === 'integrated_top' ? (
                            <span>
                                which was calculated as the <Bold>best scaled rank</Bold> of each TF across all
                                libraries.
                            </span>
                        ) : null}
                        {collectionShortName != null && otherShortNames.includes(collectionShortName) ? (
                            <span>
                                which was calculated as the{' '}
                                <Bold>
                                    -log<sub>10</sub> adj <span className="italic">p</span>-value
                                </Bold>{' '}
                                of each TF across all libraries.
                            </span>
                        ) : null}
                    </p>
                </section>
            </div>
        );
    }

    if (plot.analysis_type === 'gene_set_enrichment' && isGeneSetEnrichmentAnalysisParameters(analysisParameters)) {
        return (
            <div className="space-y-6">
                <GeneSetEnrichmentAnalysisMethodsSection
                    plot={plot}
                    experiment={experiment}
                    analysisParameters={analysisParameters}
                />
                <section>
                    <MethodsSectionTitle>Gene set scores barplot</MethodsSectionTitle>
                    <p>
                        Barplot shows the GSEA results for each tested gene set with a p-value ≤{' '}
                        <Bold>{display.adj_p_value_filter ?? 0.001}</Bold>. The y-axis shows the normalized enrichment
                        score (NES) from GSEA, which represents the magnitude of enrichment as well as the direction. A
                        positive NES indicates more enrichment in the first group while a negative NES indicates more
                        enrichment in the second group. Gene sets are ordered along the x-axis by NES value.
                        {display.nes_filter !== 'both' && (
                            <span>
                                {' '}
                                Only gene sets with a <Bold>{display.nes_filter}</Bold> NES value are shown.
                            </span>
                        )}
                    </p>
                </section>
            </div>
        );
    }
    return <p>Unsupported analysis type for score barplot, we&apos;re unable to find any methods.</p>;
};

export default ScoreBarPlotMethods;
